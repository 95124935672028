import React from 'react';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_HOME } from '../../../../../../../../../constants/rails';

const EmailMissing = () => (
  <Notification
    element={<A18n rsx="component.header.notifications.email.m1" />}
    link="/account"
    onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_HOME })}
  />
);

export default EmailMissing;
