import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveSubscription, getLastSubscription } from '../../../../redux/slices/userContext/selectors';
import Burger from './Menu/Burger';
import Logo from './Logo';
import Menu from './Menu';
import Subscribe from './Subscribe';
import Search from './Search';

import './stylesheet.scss';

const Bottom = ({ simple }) => {
  const activeSubscription = useSelector(getActiveSubscription);
  const lastSubscription = useSelector(getLastSubscription);
  const [displaySubscribe, setDisplaySubscribe] = useState(true);

  useEffect(() => {
    if (activeSubscription) {
      setDisplaySubscribe(false);
    } else if (lastSubscription && lastSubscription?.attentionNeeded === 'stripe_need_action') {
      setDisplaySubscribe(false);
    }
  }, [lastSubscription, activeSubscription]);

  if (simple) {
    return (
      <div className="artp-Header-bottom artp-Header-remove-scroll-bar">
        <Logo />
        <Subscribe />
        <Search link />
      </div>
    );
  }

  return (
    <div className="artp-Header-bottom artp-Header-remove-scroll-bar">
      <Burger />
      <Logo />
      <Menu />
      {displaySubscribe && <Subscribe />}
      <Search />
    </div>
  );
};

export default Bottom;
