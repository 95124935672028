import React, { useEffect, useState, useRef } from 'react';
import Modal from './Modal';
import { aapiBeacon } from '../../services/analytics/aapi';
import './stylesheet.scss';

const ImageModalContainer = ({ className, open, onClose, lot, initalIndex }) => {
  const ref = useRef();
  const [currentImage, setCurrentImage] = useState(initalIndex);
  const [isInContainMode, setIsInContainMode] = useState(true);
  const [isContained, setIsContained] = useState(true);

  const updateContainMode = () => {
    const displayedWidth = ref.current?.clientWidth;
    const displayedHeight = ref.current?.clientHeight;
    if (displayedWidth && displayedHeight) {
      const realWidth = lot.imageUrls[currentImage].geometry?.[0];
      const realHeight = lot.imageUrls[currentImage].geometry?.[1];
      if (realWidth && realHeight) {
        setIsInContainMode(displayedWidth < realWidth || displayedHeight < realHeight);
      } else {
        setIsInContainMode(true);
      }
    } else {
      setIsInContainMode(true);
    }
  };

  useEffect(() => {
    setCurrentImage(initalIndex);
  }, [initalIndex]);

  useEffect(() => {
    // reset when the modal becomes open
    if (open) {
      aapiBeacon('lotimage', { idlot: lot.id, index: initalIndex, op: 'zoom' });
      setCurrentImage(initalIndex);
      setIsContained(true);
      updateContainMode();
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const nextImage = e => {
    e.stopPropagation();
    const newIndex = currentImage + 1 === lot.imageUrls.length ? 0 : currentImage + 1;
    aapiBeacon('lotimage', { idlot: lot.id, index: newIndex, op: 'zoom' });
    setCurrentImage(newIndex);
  };

  const previousImage = e => {
    e.stopPropagation();
    const newIndex = currentImage - 1 < 0 ? lot.imageUrls.length - 1 : currentImage - 1;
    aapiBeacon('lotimage', { idlot: lot.id, index: newIndex, op: 'zoom' });
    setCurrentImage(newIndex);
  };

  const classes = ['image-container'];
  if (className) {
    classes.push(className);
  }
  if (isInContainMode) {
    classes.push('contain-mode');
    if (isContained) {
      classes.push('contain');
    }
  }
  return (
    <Modal className="imagePopup" onClose={onClose}>
      <div className={classes.join(' ')} ref={ref} onClick={onClose}>
        <i role="presentation" className="fa fa-times fa-4x" onClick={onClose} />
        {lot.imageUrls.length > 1 && (
          <>
            <i role="presentation" className="fa fa-chevron-left fa-4x" onClick={previousImage} />
            <i role="presentation" className="fa fa-chevron-right fa-4x" onClick={nextImage} />
          </>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={lot.imageUrls[currentImage].url}
          alt={lot.title}
          onLoad={updateContainMode}
          onClick={e => {
            e.stopPropagation();
            setIsContained(!isContained);
          }}
        />
      </div>
    </Modal>
  );
};

export default ImageModalContainer;
