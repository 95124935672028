/* eslint-disable global-require */
module.exports = [
  ...require('./CountryDropdown/i18n'),
  ...require('./Date/i18n'),
  ...require('./Description/i18n'),
  ...require('./DropDown/i18n'),
  ...require('./Documents/i18n'),
  ...require('./PhoneInput/i18n'),
  ...require('./TitleDropdown/i18n'),
  'marketplace.reporting.m1',
  'marketplace.reporting.m2',
  'marketplace.reporting.m3',
  'marketplace.reporting.m4',
  'marketplace.reporting.m5',
  'marketplace.reporting.m6',
  'marketplace.reporting.m7',
  'marketplace.reporting.m8',
  'marketplace.reporting.m9',
  'marketplace.reporting.m10',
  'marketplace.reporting.m11',
  'marketplace.reporting.m12',
  'marketplace.reporting.m13',
  'marketplace.reporting.m14',
  'marketplace.reporting.m15',
  'marketplace.reporting.m16',
  'marketplace.reporting.m17',
  'marketplace.reporting.m18',
  'marketplace.reporting.m19',
  'marketplace.reporting.m20',
  'marketplace.reporting.m21',
  'marketplace.reporting.m22',
  'marketplace.reporting.m23',
  'marketplace.reporting.m24',
  'marketplace.reporting.m25',
  'marketplace.reporting.m26',
  'marketplace.reporting.m27',
  'marketplace.reporting.m28',
  'marketplace.reporting.m29',
  'marketplace.reporting.m30',
  'marketplace.reporting.m31',
  'marketplace.reporting.m32',
];
