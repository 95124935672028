import { Link } from 'react-router-dom';
import i18next from 'i18next';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentDefaultFacets } from '../../../../../../redux/selectors/search';
import QuickFilter from '../../../../../search/common/QuickFilter';
import './stylesheet.scss';
import { SHORTCUTS_LOTS, SHORTCUTS_LOTS_FOLLOW, VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../../../../constants/myartprice';
import { get as getUiDictionary } from '../../../../../../redux/selectors/ui/dictionary';

const FACET_NAME_CATEGORIES = 'idcategory';
const FACET_NAME_ARTISTS = 'idartist';

const MyartpriceQuickFilters = ({ onFilterClick, handleSummaryClick, displayCategory, displayArtist }) => {
  const defaultFacets = useSelector(getCurrentDefaultFacets);

  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  return (
    <div className="myartprice-common-quickfilter">
      {/* Category title */}
      {displayCategory && Object.entries(defaultFacets[FACET_NAME_CATEGORIES] || {}).length > 0 && (
        <div className="search-quick-filter">
          <div className="box-header">{i18next.t('components.myartprice.common.verticalbar.content.m12').toUpperCase()}</div>
          {/* Categories listing */}
          <div className="filter-and-facets" /* style={{ maxHeight: 46 + Object.entries(defaultFacets[FACET_NAME_CATEGORIES] || {}).length * 24, overflowY: 'auto' }} */>
            <QuickFilter facetName={FACET_NAME_CATEGORIES} itemsFilter={false} onFilterClick={onFilterClick} title={i18next.t('components.myartprice.common.verticalbar.content.m12')} />
          </div>
        </div>
      )}

      {/* Artist */}
      {((displayArtist && Object.entries(defaultFacets[FACET_NAME_ARTISTS] || {}).length > 0) || handleSummaryClick) && (
        <div className="search-quick-filter">
          <div className="box-header">{i18next.t('components.myartprice.common.verticalbar.content.m1').toUpperCase()}</div>
          {/* Link to artist summary table */}
          {handleSummaryClick && (
            <Link onClick={handleSummaryClick} to="/myartprice" className="array-artists-link">
              <i className="fa fa-table" aria-hidden="true" />
              {i18next.t('components.myartprice.common.verticalbar.content.m2')}
            </Link>
          )}

          {/* Artist listing */}
          {SHORTCUTS_LOTS.concat(SHORTCUTS_LOTS_FOLLOW).includes(currentElement) && (
            <div className="filter-and-facets" /* style={{ maxHeight: 86 + Object.entries(defaultFacets[FACET_NAME_ARTISTS] || {}).length * 24, overflowY: 'auto' }} */>
              <QuickFilter facetName={FACET_NAME_ARTISTS} itemsFilter onFilterClick={onFilterClick} title={i18next.t('components.myartprice.common.verticalbar.content.m1')} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyartpriceQuickFilters;
