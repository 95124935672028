import React from 'react';
import { useSelector } from 'react-redux';
import { getPromotion } from '../../../redux/slices/homepage';
import './stylesheet.scss';

const Promotion = () => {
  const promotion = useSelector(getPromotion);

  if (!promotion?.images) return null;

  return (
    <div id="gtm-promo" data-code={promotion.code} data-id={promotion.id} className="container homepage-promotion block">
      <a href={`/subscription${promotion.applied ? '' : `?promocode=${promotion.code}`}`}>
        {promotion.images.map(imageTag => (
          // eslint-disable-next-line react/no-danger
          <span key={`promo-${Math.random()}`} dangerouslySetInnerHTML={{ __html: imageTag }} />
        ))}
      </a>
    </div>
  );
};

export default Promotion;
