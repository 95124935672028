import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateViewport as updateViewportAction } from '../../../redux/actions/ui/viewport';

class EventListner extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const { updateViewport } = this.props;
    updateViewport(window.innerHeight, window.innerWidth);
  }

  render() {
    return null;
  }
}

EventListner.propTypes = {
  updateViewport: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    updateViewport: (height, witdh) => dispatch(updateViewportAction(height, witdh)),
  };
}

export default connect(null, mapDispatchToProps)(EventListner);
