/* eslint-disable global-require */
module.exports = [
  ...require('./Categories/i18n'),
  ...require('../Indexes/EstimateAd/i18n'),
  ...require('./Future/i18n'),
  ...require('./NoAccessDrawer/i18n'),
  ...require('./Past/i18n'),
  ...require('./HighlightOn/i18n'),
  ...require('./Reproduction/i18n'),
  'artists.artist.',
];
