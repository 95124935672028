import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ANALYTICS_FROM_ARTIST, ANALYTICS_CLICK_OPERATION_TO_ARTIST_FILTERS, ANALYTICS_CLICK_OPERATION_TO_ARTIST_HIDE_MENU } from '../../../../constants/rails';
import { getIsBot, isLogged } from '../../../../redux/slices/userContext/selectors';
import { getArtist, getCategories } from '../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { useViewport } from '../../../../hooks/useViewport';
import { on } from '../../../../redux/actions/ui/switches';
import { onClient } from '../../../../utils/ssr';
import { UiContext } from '../common/UiProvider';
import Drawer, { DRAWER_ID } from './Drawer';
import A18n from '../../../common/A18n';
import Display from './Display';
import './stylesheet.scss';

const Filters = () => {
  const artist = useSelector(getArtist);
  const categories = useSelector(getCategories);
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const { setHideLeftMenu, hideLeftMenu } = React.useContext(UiContext);
  const { viewportWidth } = useViewport();
  const [displayFilterMenu, setDisplayFilterMenu] = useState(false);
  const isbot = useSelector(getIsBot);

  useEffect(() => {
    if (viewportWidth >= 992) {
      setDisplayFilterMenu(true);
    } else {
      setDisplayFilterMenu(false);
    }
  }, [viewportWidth]);

  return (
    <div className="artists-artist-filters">
      <div className="container">
        <div
          className={classNames({ filter: true, 'filter-menu': true, 'filter-menu-visible': displayFilterMenu, 'next-show': hideLeftMenu, 'next-hide': !hideLeftMenu })}
          onClick={() => {
            aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_HIDE_MENU, idartist: artist.id });
            setHideLeftMenu(!hideLeftMenu);
          }}
        >
          {!hideLeftMenu && <A18n rsx="artists.artist.filters.m1" />}
          {hideLeftMenu && <A18n rsx="artists.artist.filters.m2" />}
        </div>
        {!isEmpty(categories.all) && (
          <div
            className="filter"
            onClick={() => {
              aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FILTERS, idartist: artist.id });
              dispatch(on(DRAWER_ID));
            }}
          >
            <A18n rsx="artists.artist.filters.m3" />
          </div>
        )}
        <div className="filler" />
        {logged && <Display />}
      </div>
      {onClient() && !isbot && <Drawer />}
    </div>
  );
};

export default Filters;
