/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.table.m3',
  'subscriptions.list.table.m4',
  'subscriptions.list.table.m5',
  'subscriptions.list.table.m6',
  'subscriptions.list.table.m7',
  'subscriptions.list.table.m8',
  'subscriptions.list.table.m9',
  'subscriptions.list.table.m10',
  'subscriptions.list.table.m11',
  'subscriptions.list.table.m12',
  'subscriptions.list.table.m13',
  'subscriptions.list.table.m14',
  'subscriptions.list.table.m15',
  'subscriptions.list.table.m16',
  'subscriptions.list.table.m17',
  'subscriptions.list.table.m18',
  'subscriptions.list.table.m19',
  'subscriptions.list.table.m20',
  'subscriptions.list.table.m21',
  'subscriptions.list.table.m22',
  'subscriptions.list.table.m23',
  'subscriptions.list.table.m25',
  'subscriptions.list.table.m26',
  ...require('./Module/i18n'),
];
