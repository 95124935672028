/* eslint-disable global-require */
module.exports = [
  ...require('../../../subscriptions/BannerNoAccess/i18n'),
  'lot.show.no_access.m2',
  'artprice.wallet.physicsale.m14',
  'title.description.lot.show',
  'lot.show.no_access.m1',
  'lot.show.no_access.m7',
  'lot.show.no_access.m1',
  'lot.show.no_access.m5',
  'lot.show.no_access.m1',
  'artprice.lib.psresultctrl.m19',
  'lot.show.no_access.m1',
  'artprice.lib.psresultctrl.m23',
  'lot.show.no_access.m1',
  'lots.show.m9',
  'lot.show.no_access.m1',
  'lots.show.m10',
  'lot.show.no_access.m1',
  'lots.show.m11',
  'lot.show.no_access.m1',
  'artprice.client.payment.billdetail.m12',
  'lot.show.no_access.m1',
  'lot.show.no_access.m6',
  'lot.show.no_access.m1',
  'artprice.lib.psresultctrl.m6',
  'lot.show.no_access.m1',
  'lots.small.m1',
  'lot.show.no_access.m1',
  'lots.small.m2',
  'lot.show.no_access.m1',
  'lots.show.m12',
  'lot.show.no_access.m1',
  'lot.show.no_access.m3',
];
