import React from 'react';
import i18Next from 'i18next';
import { isPlainObject } from 'lodash';
import { LOTSLISTS_MAXLOTS } from '../../../../../constants/rails';

const ListSelect = ({ lists, onChange, hiddenIds = [] }) => {
  if (isPlainObject(lists)) {
    return (
      <select onChange={onChange} defaultValue={0}>
        <option disabled value={0}>
          {`-- ${i18Next.t('components.lotslists.create.m1')} --`}
        </option>
        {Object.values(lists)
          .filter(list => !hiddenIds.includes(list.id))
          .map(list => (
            <option key={list.id} label={`${list.label} (${list.lotsCount})`} value={list.id} disabled={list.lotsCount > LOTSLISTS_MAXLOTS} />
          ))}
      </select>
    );
  }

  return null;
};

export default ListSelect;
