import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_PRICING, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_PAST_LOTS, ANALYTICS_SECTION_INDEXES_CARD, IMAGE_SOURCE, MODULE_STORE } from '../../../../../../constants/rails';
import { hasModuleAccess } from '../../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import DOMView from '../../../../../common/observers/DOMView';
import '../../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../../common/A18n';

const NoAccessCard = ({ artist, lotCount }) => {
  const hasStoreAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_STORE }));

  return (
    <div className="lot fake">
      <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_INDEXES_CARD} selector="#indexes-card" idartist={artist.id} />
      <img id="indexes-card" src={`${IMAGE_SOURCE}artist/auctions.png`} alt="indexes" />
      <div className="fake-container">
        <p className="title">
          <A18n rsx="artists.artist.lots.past.m16" trustHtml />
        </p>
        <p className="content">
          {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m20" trustHtml replace={[['%dt-year%', artist.firstLotYear]]} />}
          {lotCount > 1 && artist.firstLotYear === artist.lastLotYear && <A18n rsx="artists.artist.lots.past.m19" trustHtml replace={[['%dt-year%', artist.firstLotYear]]} />}
          {lotCount > 1 && artist.firstLotYear !== artist.lastLotYear && (
            <A18n
              rsx="artists.artist.lots.past.m17"
              trustHtml
              replace={[
                ['%dt-old-year%', artist.firstLotYear],
                ['%dt-recent-year%', artist.lastLotYear],
              ]}
            />
          )}
        </p>
        <a
          className="artp-btn artp-btn-artist"
          href={`/subscription${hasStoreAccess ? '/store/upgrade' : ''}`}
          onClick={() =>
            aapiBeacon('click', {
              from: ANALYTICS_FROM_ARTIST,
              op: ANALYTICS_CLICK_OPERATION_TO_PRICING,
              section: ANALYTICS_SECTION_ARTIST_PAST_LOTS,
              idartist: artist.id,
            })
          }
        >
          <A18n rsx="artists.artist.lots.past.m18" trustHtml />
        </a>
      </div>
    </div>
  );
};
export default NoAccessCard;
