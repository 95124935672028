import React from 'react';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Header = () => (
  <div className="ails-info-header">
    <div className="container">
      <h1>
        <A18n rsx="ails.info.m1" />
      </h1>
      <p>
        <A18n rsx="ails.info.m54" />
      </p>
    </div>
  </div>
);

export default Header;
