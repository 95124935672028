import React, { lazy, Suspense } from 'react';
import Spinner from '../../../../common/spinners/Spinner';

const LazyLink = lazy(() => import(/* webpackChunkName: "lazypdflink" */ '../Lazy/Link'));

const Link = props => (
  <Suspense fallback={<Spinner />}>
    <LazyLink {...props} />
  </Suspense>
);

export default Link;
