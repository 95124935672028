import React, { useEffect } from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getStats } from '../../../../redux/actions/auctioneers/stats';
import { stats as statsSelector } from '../../../../redux/selectors/auctioneers/stats';
import './stylesheet.scss';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT } from '../../../../constants/rails';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM } from '../../../../constants/auctioneers';

const HeaderList = ({ period, title, intl, hideMissingSaleLink = false }) => {
  const dispatch = useDispatch();
  const stats = useSelector(state => statsSelector(state));
  const from = useSelector(state => get(state, { id: ACTIVE_AAPI_BEACON_FFROM }));

  useEffect(() => {
    dispatch(getStats());
  }, []);

  return (
    <div className="auctioneers-common-header-list">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{title}</h1>
            {!stats.loading && !stats.unauthorized && (
              <div className="count-cards">
                <div className="count-card">
                  <div className="card-title">{i18next.t('auctioneers.common.headerlist.m1')}</div>
                  <div className="card-count">{intl.formatNumber(stats[period].sales)}</div>
                </div>
                <div className="count-card">
                  <div className="card-title">{i18next.t('auctioneers.common.headerlist.m2')}</div>
                  <div className="card-count">{intl.formatNumber(stats[period].lots)}</div>
                </div>
                {period === 'future' && (
                  <div className="count-card link-plus">
                    {!hideMissingSaleLink && (
                      <a href="/auctioneer/control-center/contact?key=m7" onClick={() => aapiBeacon('click', { from, section: 'auctioneer-center-header-list', op: ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT })}>
                        <i className="fa fa-plus-circle" />
                        <br />
                        {i18next.t('auctioneers.common.headerlist.m4')}
                      </a>
                    )}
                  </div>
                )}
                {period === 'past' && (
                  <div className="count-card">
                    <div className="card-title">
                      {i18next.t('auctioneers.common.headerlist.m5')}
                      <small className="hidden-xs" title={i18next.t('auctioneers.common.headerlist.m6')}>
                        <i className="fa fa-info-circle" />
                      </small>
                    </div>
                    <div className="card-count">{intl.formatNumber(stats.push)}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(HeaderList);
