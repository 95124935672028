import i18next from 'i18next';
import React from 'react';
import Item from '../../../Search/Item';
import Checkbox from '../../../inputs/Checkbox';

const Reproduction = () => (
  <Item name="reproduction">
    <Checkbox label={i18next.t('artprice.lib.fsresultctrl.m36')} />
  </Item>
);

export default Reproduction;
