/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { artistSearch } from '../../../../../services/search';
import { getSome } from '../../../../../services/dbEntities/artist';
import customStyles from '../../../common/reactSelect/style';
import A18n from '../../../../common/A18n';
import loadingMessageFn from './loadingMessage';

// factory to make a searchManager per instance of ArtistSearch (to deal with searchOnlyInFavorites for example)
// The cache is global (to deal with unplanned destroy of the component)
const cache = new Map();
function createSearchManager(searchOnlyInFavorites = false) {
  return {
    options: {
      searchOnlyInFavorites,
    },
    clear() {
      cache.clear();
    },
    processArtist(artist) {
      if (cache.has(artist.id)) {
        return cache.get(artist.id);
      }
      // label when coming from jsonSearch, artist when coming from api (fallback)
      const formattedArtist = { value: artist.id, label: artist.html || artist.artist };
      cache.set(artist.id, formattedArtist);
      return formattedArtist;
    },
    async search(term) {
      try {
        const artists = await artistSearch({ term, excludedIdArtist: 0, favorites: this.options.searchOnlyInFavorites });
        return artists.map(artist => this.processArtist(artist));
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFromApi(id) {
      try {
        return await getSome(id);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async getArtist(id) {
      if (!id) {
        return undefined;
      }
      const artistFromCache = cache.get(id);
      if (artistFromCache) {
        return artistFromCache;
      }
      const artistFromApi = await this.fetchFromApi(id);
      if (artistFromApi) {
        return this.processArtist(artistFromApi);
      }
      return { value: id, label: id };
    },
    async processValue(value) {
      if (Array.isArray(value)) {
        return Promise.all(value.map(v => this.getArtist(v)));
      }
      return this.getArtist(value);
    },
  };
}

const ArtistSearch = ({ className, value, onChange, searchOnlyInFavorites, disabled = false }) => {
  const searchManager = useRef(createSearchManager(searchOnlyInFavorites));
  const [loading, setLoading] = useState(false);
  const [processedValue, setProcessedValue] = useState();

  useEffect(() => {
    if (searchOnlyInFavorites !== searchManager.current.options.searchOnlyInFavorites) {
      searchManager.current = createSearchManager(searchOnlyInFavorites);
    }
  }, [searchOnlyInFavorites]);

  useEffect(() => {
    setLoading(true);
    searchManager.current
      .processValue(value)
      .then(v => {
        setProcessedValue(v);
      })
      .finally(() => setLoading(false));
  }, [value]);

  return (
    <AsyncSelect
      styles={customStyles(0)}
      classNamePrefix="react-select"
      className={`search-select search-artist ${className ?? ''}`.trim()}
      isClearable
      isMulti
      cacheOptions
      isDisabled={disabled}
      isLoading={loading}
      placeholder={<A18n rsx="marketplace.searchbars.common.artist.m2" />}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length > 0) {
          return <A18n rsx="marketplace.searchbars.common.artist.m3" />;
        }
        return <A18n rsx="marketplace.searchbars.common.artist.m4" />;
      }}
      value={processedValue}
      loadingMessage={loadingMessageFn}
      loadOptions={async term => searchManager.current.search(term)}
      onChange={values => {
        if (Array.isArray(values) && values.length > 0) {
          onChange(values.map(v => v.value));
        } else {
          onChange(undefined);
        }
      }}
    />
  );
};

export default ArtistSearch;
