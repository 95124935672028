import React from 'react';
import { useDispatch } from 'react-redux';
import { on as openDrawer } from '../../../../../redux/actions/ui/switches';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const AdvancedButton = ({ drawerId }) => {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      onClick={() => {
        dispatch(openDrawer(drawerId));
      }}
      className="search-helpers-advanced-button blue-btn"
    >
      <i className="fa fa-search" />
      <span className="search-helpers-advanced-button-label">
        <A18n rsx="marketplace.searchbars.classifieds.bar.m1" />
      </span>
    </button>
  );
};

export default AdvancedButton;
