import React from 'react';
import A18n from '../../../../common/A18n';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

import './stylesheet.scss';

const Favorites = () => (
  <a className="artp-Header-Favorites artp-Header-item artp-Header-top-item" href="/myartprice">
    <img src={`${IMAGE_SOURCE}menu/coeur.svg`} alt="favorites" />
    <A18n rsx="layouts.header.m45" />
  </a>
);

export default Favorites;
