import i18next from 'i18next';
import React from 'react';
import Item from '../../../Search/Item';
import Checkbox from '../../../inputs/Checkbox';

const Signed = () => (
  <Item name="signed">
    <Checkbox label={i18next.t('artprice.web.pages.classifieds.decorative.details.m18')} />
  </Item>
);

export default Signed;
