/* eslint-disable global-require */
module.exports = [
  ...require('./EmailStatus/i18n'),
  ...require('./Confirm/i18n'),
  ...require('./Messages/i18n'),
  ...require('./StripeCustomerPortal/i18n'),
  ...require('./Lotslists/i18n'),
  ...require('./Login/i18n'),
  ...require('./LangChangedModal/i18n'),
  ...require('./EmailValidationNeededModal/i18n'),
  ...require('./SigninConfirmation/i18n'),
];
