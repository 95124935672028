import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { getActiveSubscriptionName, isLogged } from '../../../redux/slices/userContext/selectors';
import { followLot, unfollowLot } from '../../../redux/actions/myartprice/datas';
import { getFavoriteLot } from '../../../redux/selectors/myartprice/datas';
import ModalLotFollowLimit from '../../MyArtprice/ModalLotFollowLimit';
import ModalSubscription from '../../account/LoginOrSubscribeModale';
import ButtonFollow from '../../MyArtprice/ButtonFollow';
import './stylesheet.scss';

const Follow = ({ idlot, size }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const favoriteLots = useSelector(getFavoriteLot);
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favoriteLots.includes(idlot));

  const activeSubscription = useSelector(getActiveSubscriptionName);
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const [openModalLotFollowLimit, setOpenModalLotFollowLimit] = useState(false);

  useEffect(() => {
    setIsFavorite(favoriteLots.includes(idlot));
  }, [favoriteLots]);

  const toggleFollowLot = async () => {
    if (!logged) {
      window.location = '/identity';
      return;
    }

    setIsLoading(true);
    setIsFavorite(isFavorite);

    try {
      const result = await dispatch(isFavorite ? unfollowLot(idlot) : followLot(idlot));
      setIsLoading(false);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const handleFollowClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (activeSubscription) {
      toggleFollowLot().then(res => {
        if (res && res.unauthorized) {
          setOpenModalLotFollowLimit(true);
        }
      });
    } else {
      setOpenModalSubscription(true);
    }
    return false;
  };

  return (
    <>
      <ModalSubscription open={openModalSubscription} setOpen={setOpenModalSubscription} />
      <ModalLotFollowLimit open={openModalLotFollowLimit} setOpen={setOpenModalLotFollowLimit} />
      {(isLoading && (
        <div className="button-follow">
          <Button color="default" size={size} disabled>
            <i className="fa fa-spinner fa-spin" />
          </Button>
        </div>
      )) || <ButtonFollow isFavorite={isFavorite} handleFollowClick={handleFollowClick} size={size} />}
    </>
  );
};

export default Follow;
