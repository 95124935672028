import React from 'react';
import { useSelector } from 'react-redux';
import EmailStatus from '../../../EmailStatus';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import A18n from '../../../../common/A18n';

const Default = ({ close }) => {
  const { email } = useSelector(getUserContext);

  return (
    <div className="section">
      <div className="section-title">
        <A18n rsx="tour.emailvalidationneeded.contents.locked.m1" />
      </div>
      <div className="section-content" style={{ padding: '0 10%' }}>
        <p>
          <A18n rsx="tour.emailvalidationneeded.contents.locked.m2" /> <b>{email}</b>.
        </p>
        <p>
          <A18n
            rsx="tour.emailvalidationneeded.contents.locked.m12"
            replace={[
              ['%a%', '<a href="/account">'],
              ['%/a%', '</a>'],
            ]}
            trustHtml
          />
        </p>
        <p>
          <A18n rsx="tour.emailvalidationneeded.contents.locked.m3" />
          <ul style={{ marginTop: 15 }}>
            <li>
              <A18n rsx="tour.emailvalidationneeded.contents.locked.m4" />
            </li>
            <li>
              <A18n rsx="tour.emailvalidationneeded.contents.locked.m5" />
              <br />
              <A18n rsx="tour.emailvalidationneeded.contents.locked.m6" />
            </li>
            <li>
              <A18n rsx="tour.emailvalidationneeded.contents.locked.m7" />
            </li>
          </ul>
        </p>
      </div>
      <div className="section-content" style={{ padding: '30px 10% 10px', textAlign: 'center' }}>
        <EmailStatus withWarning={false} backTo="home" onClick={close} />
      </div>
      <div className="section-content" style={{ padding: '30px 10% 10px' }}>
        <p>
          <A18n rsx="tour.emailvalidationneeded.contents.locked.m8" />
        </p>
        <p>
          <A18n rsx="tour.emailvalidationneeded.contents.locked.m9" />{' '}
          <a href="/contact?active=block8&id=690">
            <A18n rsx="tour.emailvalidationneeded.contents.locked.m10" />
          </a>
        </p>
        <p>
          <A18n rsx="tour.emailvalidationneeded.contents.locked.m11" />
        </p>
      </div>
    </div>
  );
};

export default Default;
