import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { MODULE_FUTURESALE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE, MODULE_EXPORT_PDF, MODULE_IMAGE, ANALYTICS_SECTION_SUBSCRIPTIONS_CARD } from '../../../../../../../constants/rails';
import { updateSettings } from '../../../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../../../redux/selectors/subscriptions/settings';
import FlipSwitch from '../../../../../../common/ui/FlipSwitch';
import A18n from '../../../../../../common/A18n';
import '../stylesheet.scss';
import useAnalytics from '../../../../common/useAnalytics';

const Data = ({ theme, physicproduct }) => {
  const dispatch = useDispatch();
  const { withoutImagePriceProduct } = useSelector(getSettings);
  const { toggleImage: toggleImageAnalytics } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_CARD });

  return (
    <div className="components">
      <ul className="subscriptions-list-cards-card-details-data">
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m1" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m2" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m16" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m3" />
        </li>
        {theme.theme_ref !== 'professional' && (
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m4" />
          </li>
        )}
        <li
          className={classNames({
            [theme.theme_ref]: true,
            active: theme.theme_ref === 'basics',
            [`mod-0`]: true,
          })}
        >
          <A18n rsx="subscriptions.list.cards.card.details.data.m5" />
        </li>
        {theme.module.includes(MODULE_PASTSALE) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.theme_ref === 'basics',
              [`mod-${MODULE_PASTSALE}`]: true,
            })}
          >
            <A18n rsx="subscriptions.list.cards.card.details.data.m6" />
          </li>
        )}
        {theme.module.includes(MODULE_FUTURESALE) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.theme_ref === 'basics',
              [`mod-${MODULE_FUTURESALE}`]: true,
            })}
          >
            <A18n rsx="subscriptions.list.cards.card.details.data.m7" />
          </li>
        )}
        <li
          className={classNames({
            [theme.theme_ref]: true,
            [`mod-${MODULE_IMAGE}`]: true,
            active: theme.theme_ref === 'basics',
            strike: withoutImagePriceProduct.includes(physicproduct?.idp),
          })}
        >
          <A18n rsx="subscriptions.list.cards.card.details.data.m8" />
          <br />
          <FlipSwitch
            className={classNames()}
            label={<A18n rsx="subscriptions.list.cards.card.details.data.m15" />}
            onChange={() => {
              if (physicproduct?.idp) {
                toggleImageAnalytics({ checked: !withoutImagePriceProduct.includes(physicproduct?.idp), slug: physicproduct.reference });

                dispatch(
                  updateSettings({
                    data: {
                      withoutImagePriceProduct: withoutImagePriceProduct.includes(physicproduct.idp) ? withoutImagePriceProduct.filter(id => id !== physicproduct.idp) : [...withoutImagePriceProduct, physicproduct.idp],
                    },
                  }),
                );
              }
            }}
            checked={!withoutImagePriceProduct.includes(physicproduct?.idp)}
          />
        </li>
        {theme.module.includes(MODULE_INDEXES) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              [`mod-${MODULE_INDEXES}`]: true,
              active: theme.theme_ref === 'premium',
            })}
          >
            <A18n rsx="subscriptions.list.cards.card.details.data.m9" />
          </li>
        )}
        {theme.module.includes(MODULE_INDICATOR) && (
          <li
            className={classNames({
              active: theme.theme_ref === 'premium',
              [theme.theme_ref]: true,
              [`mod-${MODULE_INDICATOR}`]: true,
            })}
          >
            <A18n rsx="subscriptions.list.cards.card.details.data.m10" />
          </li>
        )}
        {theme.module.includes(MODULE_EXPORT_PDF) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.theme_ref === 'premium',
              [`mod-${MODULE_EXPORT_PDF}`]: true,
            })}
          >
            <A18n rsx="subscriptions.list.cards.card.details.data.m11" />
            <i className="marg marg-l-5 fa fa-file-pdf-o" />
          </li>
        )}
        {theme.module.includes(MODULE_STORE) && (
          <>
            <li
              className={classNames({
                [theme.theme_ref]: true,
                active: theme.theme_ref === 'professional',
                [`mod-${MODULE_STORE}`]: true,
              })}
            >
              <A18n rsx="subscriptions.list.cards.card.details.data.m12" />
            </li>
            <li
              className={classNames({
                [theme.theme_ref]: true,
                active: theme.theme_ref === 'professional',
                [`mod-${MODULE_STORE}`]: true,
              })}
            >
              <A18n rsx="subscriptions.list.cards.card.details.data.m13" />
            </li>
            <li
              className={classNames({
                [theme.theme_ref]: true,
                [`mod-${MODULE_STORE}`]: true,
                active: theme.theme_ref === 'professional',
              })}
            >
              <A18n rsx="subscriptions.list.cards.card.details.data.m14" />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Data;
