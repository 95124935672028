/* eslint-disable global-require */
module.exports = [
  'search.helpers.items.keyword.select.m1',
  'search.helpers.items.country.select.m2',
  'artists.search.form.keyword.m3',
  'artists.search.form.keyword.m4',
  'artists.searchbar.m1',
  'artists.searchbar.m2',
  'artists.searchbar.m3',
  'artists.header_search.m10',
];
