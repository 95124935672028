import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../Dropdown';
import Content from './Content';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';
import { getMarketplaceMessageCount, getMessageCount } from '../../../../../../redux/slices/userContext/selectors';
import Pill from '../../Pill';

import './stylesheet.scss';
import { fetchMarketplaceContext } from '../../../../../../redux/slices/userContext';
import A18n from '../../../../../common/A18n';
import NoTranslate from '../../../../../common/NoTranslate';

const Messages = () => {
  const dispatch = useDispatch();
  const userMessages = useSelector(getMessageCount);
  const marketplaceMessages = useSelector(getMarketplaceMessageCount);

  useEffect(() => {
    dispatch(fetchMarketplaceContext());
  }, []);

  const hasMessages = userMessages + marketplaceMessages > 0;
  return (
    <Dropdown
      className="artp-Header-item artp-Header-top-item"
      button={
        <div className="artp-Header-Messages">
          <NoTranslate>
            {hasMessages ? (
              <Pill>
                <img className="logo-messages" src={`${IMAGE_SOURCE}menu/messages.svg`} alt="messages" />
              </Pill>
            ) : (
              <img className="logo-messages" src={`${IMAGE_SOURCE}menu/messages.svg`} alt="messages" />
            )}
          </NoTranslate>
          <A18n rsx="message.index.m1" />
        </div>
      }
      content={<Content />}
    />
  );
};

export default Messages;
