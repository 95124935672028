import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { fetchSettings } from '../../../redux/actions/myartprice/settings';
import Artists from './Artists';
import Categories from './Categories';
import Sections from './Sections';
import VerticalBar from '../common/VerticalBar';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { SHORTCUT_SETTINGS_SECTIONS, SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_ARTISTS } from '../../../constants/myartprice';
import { fetchArtists } from '../../../redux/actions/myartprice/datas';
import '../common/styles/myartpriceBar.scss';
import MainLayout from '../common/MainLayout';
import { getStyle } from '../../../utils/styles/horizontalBar';
import { style as horizontalBarStyle } from '../common/styles/horizontalBar';
import { on as openDrawer } from '../../../redux/actions/ui/switches';

const Settings = ({ activeTab = 0 }) => {
  const dispatch = useDispatch();
  useInitializeActiveShorcut({ activeShortcut: activeTab });

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchArtists());
  }, []);

  const horizontalBarChildren = () => (
    <div style={getStyle({ backgroundColor: horizontalBarStyle.backgroundColor, borderColor: horizontalBarStyle.borderColor, paddingBottom: 5, paddingTop: 5, borderTop: 'solid 1px #fff' })}>
      <div className="container">
        <div className="filters-container">
          <button id="filter" type="button" onClick={() => dispatch(openDrawer('myartprice-vertical-bar'))}>
            {i18next.t('component.myartprice.lot.m9')} &nbsp; <i className="fa fa-caret-down" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <MainLayout horizontalBarChildren={horizontalBarChildren()}>
      <VerticalBar />
      <div className="col-container">
        <div id="settings-data" className="data-container">
          {activeTab === SHORTCUT_SETTINGS_SECTIONS && <Sections />}
          {activeTab === SHORTCUT_SETTINGS_CATEGORIES && <Categories />}
          {activeTab === SHORTCUT_SETTINGS_ARTISTS && <Artists />}
        </div>
      </div>
    </MainLayout>
  );
};

export default Settings;
