export const Classifiedstatute = Object.freeze({
  UNPAYED: 1,
  WAITING_VALIDATION: 2,
  PUBLISHED: 3,
  CANCELLED_BY_CLIENT: 4,
  CANCELLED_BY_ARTPRICE: 5,
  WAITING: 6,
  OFFLINE: 7,
  REMOVED_AFTER_COPY_TO_AUCTION: 8,
  REMOVED_AFTER_COPY_TO_FREE: 9,
  MODIFICATION_RUNNING: 10,
  WAITING_CUSTOMER_VERIFICATION: 11,
  WAITING_CUSTOMER_VERIFICATION_THEN_PUBLISH: 12,
  WAITING_CUSTOMER_VERIFICATION_THEN_WAITING: 13,
  WAITING_CUSTOMER_VERIFICATION_THEN_OFFLINE: 14,
});

export const Classifiedauctionmode = Object.freeze({
  AUCTION: 1,
  FREE: 2,
});

export const Classifiedclass = Object.freeze({
  FINEART: 1,
  DECORATIVE: 2,
  DESIGN: 3,
});

export const Classifiedoriginaltype = Object.freeze({
  ORIGINAL: 1,
  MULTIPLE: 2,
  COPY: 3,
});

export const LengthUnit = Object.freeze({
  CM: 'cm',
  IN: 'in',
});
