import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { titlesForForms } from '../../../../services/wallet/title';
import BasicDropdown from '../BasicDropdown';

const TitleDropdown = ({ name, control, readOnly, rules = {}, width, placeholder }) => {
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    titlesForForms().then(datas => {
      setTitles(datas.map(({ id, label }) => ({ value: id, label })));
      setIsLoading(false);
    });
  }, []);

  return (
    <BasicDropdown
      control={control}
      name={name}
      width={width}
      rules={rules}
      options={titles}
      isLoading={isLoading}
      readOnly={isLoading || readOnly}
      isSearchable={false}
      placeholder={placeholder || i18next.t('components.account.verify.identity.m4')}
      loadingMessage={i18next.t('components.account.verify.identity.m5')}
    />
  );
};

export default TitleDropdown;
