/* eslint-disable global-require */
module.exports = [
  'payments.informations.form.cgv.m1',
  'payments.informations.form.cgv.m2',
  'payments.informations.form.cgv.m3',
  'payments.informations.form.cgv.m4',
  'payments.informations.form.cgv.m5',
  'payments.informations.form.cgv.m6',
  'payments.informations.form.cgv.m7',
  'payments.informations.form.cgv.m8',
];
