/* eslint-disable global-require */
module.exports = [
  ...require('./AddToListButton/i18n'),
  ...require('./Breadcrumb/i18n'),
  ...require('./List/i18n'),
  ...require('./Show/i18n'),
  ...require('./Sort/i18n'),
  ...require('./pdf/i18n'),
  ...require('./common/i18n'),
  ...require('./LandingPage/i18n'),
];
