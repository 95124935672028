import React from 'react';
import './stylesheet.scss';

const LinkButton = ({ className = '', onClick, children }) => (
  <button type="button" className={`artdbweb-lot-Indicator-LinkButton ${className}`} onClick={onClick}>
    {children}
  </button>
);

export default LinkButton;
