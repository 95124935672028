/* eslint-disable global-require */
module.exports = [
  ...require('./Fineart/i18n'),
  ...require('./DesignAndDecorative/i18n'),
  ...require('./Auctioneer/i18n'),
  ...require('./common/i18n'),
  ...require('./Auction/i18n'),
  'marketplace.show.html.m17',
  'marketplace.classified.details.m1',
  'marketplace.classified.details.m2',
];
