import React from 'react';
import A18n from '../../../common/A18n';

const Content = () => (
  <div className="section">
    <div className="section-title">
      <A18n rsx="tour.accountlang.section.title1" />
    </div>
    <div className="section-content" style={{ padding: '0 10%' }}>
      <A18n rsx="tour.accountlang.section.content1" trustHtml />
    </div>
    <div className="section-content" style={{ padding: '30px 10% 10px' }}>
      <a className="section-button" href="/ul?p=account">
        <A18n rsx="tour.accountlang.section.button1" />
      </a>
    </div>
  </div>
);

export default Content;
