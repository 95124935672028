import React from 'react';
import { useSelector } from 'react-redux';
import { getModificationRequiredCount } from '../../../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED } from '../../../../../../../../../constants/rails';
import { a18n } from '../../../../../../../../../utils/a18n';

const ClassifiedsWaitingForModification = () => {
  const modificationRequiredCount = useSelector(getModificationRequiredCount);

  if (modificationRequiredCount > 1) {
    return (
      <Notification
        element={<A18n rsx="component.header.notifications.marketplace.m2" replace={[['%count%', modificationRequiredCount]]} />}
        link={`/${a18n('routes.marketplace_manage')}/modification_required`}
        onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED })}
      />
    );
  }
  return (
    <Notification
      element={<A18n rsx="component.header.notifications.marketplace.m1" />}
      link={`/${a18n('routes.marketplace_manage')}/modification_required`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED })}
    />
  );
};

export default ClassifiedsWaitingForModification;
