/* eslint-disable react/no-danger */

import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSources } from '../../../redux/actions/auctioneers/medias';
import { fetchPhysicproducts } from '../../../redux/actions/subscriptions/physicproduct';
import { getSources } from '../../../redux/selectors/auctioneers/medias';
import { getThemes } from '../../../redux/selectors/subscriptions/physicproducts';
import { set } from '../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU, MENU_SUBSCRIPTIONS } from '../../../constants/auctioneers';
import './stylesheet.scss';
import { ANALYTICS_FROM_AUCTIONEERS_FUTURE_SALES } from '../../../constants/rails';

const Subscription = () => {
  const dispatch = useDispatch();
  const sources = useSelector(getSources);
  const themes = useSelector(getThemes);
  const lang = i18next.language;

  useEffect(() => {
    dispatch(fetchSources());
    dispatch(
      fetchPhysicproducts({
        filter: 'subscription',
        image: true,
        promocode: '',
        quantity: 1,
      }),
    );
    dispatch(set(ACTIVE_MENU, MENU_SUBSCRIPTIONS));
    dispatch(set(ACTIVE_AAPI_BEACON_FFROM, ANALYTICS_FROM_AUCTIONEERS_FUTURE_SALES));
  }, []);

  let basics;
  if (themes?.new) {
    const basicsProducts = themes.new.products.filter(product => product.idtheme === 19);
    if (basicsProducts.length > 0) {
      basics = { ...basicsProducts[0].physicproducts.filter(physicproduct => physicproduct.nbmonths >= 12)[0] };
    }
  }

  return (
    <div className="container auctioneers-subscription">
      <div className="row">
        <div className="col-xs-12">
          <h1>{i18next.t('auctioneers.subscription.m1')}</h1>
        </div>
        <div className="col-xs-12">
          <h2>{i18next.t('auctioneers.subscription.m9')}</h2>
          <p dangerouslySetInnerHTML={{ __html: i18next.t('auctioneers.subscription.m10') }} />
          <p>{i18next.t('auctioneers.subscription.m11')}</p>
          {basics && <p>{i18next.t('auctioneers.subscription.m13').replace('%eur%', basics?.price?.eur).replace('%usd%', basics?.price?.usd)}</p>}
          <p className="form">
            <a className="btn btn-primary" href="/subscription">
              {i18next.t('auctioneers.subscription.m12')}
            </a>
          </p>
        </div>
        <div className="col-xs-12">
          <h2>{i18next.t('auctioneers.subscription.m2')}</h2>
          <p>{i18next.t('auctioneers.subscription.m3')}</p>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: i18next.t('auctioneers.subscription.m4').replace('%a%', `<a href="${sources.ail}">`).replace('%/a%', '</a>'),
              }}
            />
            <li>{i18next.t('auctioneers.subscription.m5')}</li>
            <li dangerouslySetInnerHTML={{ __html: i18next.t('auctioneers.subscription.m6') }} />
          </ul>
          <p className="form">
            <a className="btn btn-primary" target="ail" href={sources.ail}>
              {i18next.t('auctioneers.subscription.m8')}
            </a>
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: i18next.t('auctioneers.subscription.m7').replace('%a%', '<a href="mailto:advertising@artprice.com">').replace('%/a%', '</a>'),
            }}
          />
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
            }}
          >
            <div style={{ width: '50%' }}>
              <p>
                {i18next.t('auctioneers.subscription.m14')}
                <br />
                {i18next.t('auctioneers.subscription.m15')}
                <br />
                <a href={`mailto:${i18next.t('auctioneers.subscription.m16')}`}>{i18next.t('auctioneers.subscription.m16')}</a>
              </p>
            </div>
            {lang === 'en' && (
              <div>
                <p>
                  {i18next.t('auctioneers.subscription.m17')}
                  <br />
                  {i18next.t('auctioneers.subscription.m18')}
                  <br />
                  <a href={`mailto:${i18next.t('auctioneers.subscription.m19')}`}>{i18next.t('auctioneers.subscription.m19')}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
