import React from 'react';
import CountHelper from '../../../../search/v2/helpers/Count';

const Count = ({ searchScope }) => (
  <CountHelper
    searchScope={searchScope}
    rsx={{
      oneResult: 'lots.searchbars.common.count.one-result',
      multipleResults: 'lots.searchbars.common.count.multiple-results',
    }}
  />
);

export default Count;
