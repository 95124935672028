/* eslint-disable react/style-prop-object */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { FormattedNumber } from 'react-intl';
import { getArtist, getClassified } from '../../../../redux/selectors/dbEntities';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import Category from '../../../common/dbEntitiesSmallViews/Category';
import { getCurrency } from '../../../../redux/selectors/preferences';
import ClassifiedFollowButton from '../../../marketplace/Classified/Follow';
import './stylesheet.scss';

const Classified = ({ id, linkToArtist = true, linkToClassified = true, displayTitle, displayArtist = true, displayPrice, ...otherProps }) => {
  const [imgLoading, setLoading] = useState(true);
  const classified = useSelector(state => getClassified(state, { id }));
  const artist = useSelector(state => getArtist(state, { id: classified ? classified.idartist : 0 }));
  const prefCurrencyIso3 = useSelector(getCurrency);

  const auctionDate = () =>
    (new Date() < new Date(classified.start_auction_at) && (
      <div>
        {i18next.t('marketplace.auction._dates.html.m6')} {classified.end_auction_at_localized}
      </div>
    )) || (
      <div>
        {i18next.t('marketplace.auction._dates.html.m7')} {classified.start_auction_at_localized}
      </div>
    );

  return (
    <div className={`myartprice-home-common-classified ${imgLoading ? 'load' : ''}`.trim()} {...otherProps}>
      {classified && (
        <>
          {/* Image */}
          <div className="classified-img">
            {!classified.imageUrl && (
              <div className="no-pict">
                <i className="fa fa-picture-o fa-3x" />
              </div>
            )}
            {classified.imageUrl && !linkToClassified && <img alt="" className="shadow" src={classified.imageUrl[0]} onLoad={() => setLoading(false)} />}
            {classified.imageUrl && linkToClassified && (
              <a href={classified.url}>
                <img alt="" className="shadow" src={classified.imageUrl[0]} onLoad={() => setLoading(false)} />
              </a>
            )}
          </div>

          {/* Follow classified icon */}
          <ClassifiedFollowButton idclassified={classified.id} noLabel />

          {/* Title */}
          {displayTitle && (
            <div className="classified-title">
              {linkToClassified && <a href={classified.url}>{classified.title}</a>}
              {!linkToClassified && classified.title}
            </div>
          )}

          {/* Artist */}
          {displayArtist && artist && (
            <div className="classified-artist">
              <Artist id={artist.id} withLink={linkToArtist} withDates={false} />
            </div>
          )}

          {/* Description and price */}
          <div className="classified-description">{classified.idcategory && <Category id={classified.idcategory} />}</div>
          {displayPrice && classified.prices && classified.prices.eur > 0 && (
            <div className="classified-price">
              <FormattedNumber value={classified.prices[classified.currencyIso3]} currency={classified.currencyIso3.toLowerCase()} style="currency" />
              {/* {classified.prices[classified.currencyIso3]} {CURRENCIES[classified.currencyIso3.toLowerCase()].iso1} */}
              {prefCurrencyIso3 !== classified.currencyIso3 && (
                <span style={{ marginLeft: 5 }}>
                  (<FormattedNumber value={classified.prices[prefCurrencyIso3]} currency={prefCurrencyIso3.toLowerCase()} style="currency" />)
                  {/* &nbsp; ({classified.prices[prefCurrencyIso3]} {CURRENCIES[prefCurrencyIso3.toLowerCase()].iso1}) */}
                </span>
              )}
            </div>
          )}

          {/* Auction date */}
          {(classified.idclassifiedauctionmode === 1 && auctionDate()) || (
            <div>
              <a href={classified.storeUrl}>{classified.storeName}</a>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Classified;
