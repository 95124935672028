import React from 'react';
import { FormattedDate } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE_SEARCH, ANALYTICS_FROM_SEARCH_UNIVERSAL } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import SectionHeader from '../SectionHeader';
import Skeleton from '../skeletons/Wide';
import A18n from '../../../common/A18n';
import { artmarketInsightSearchOnKeyword } from '../../../../services/search';
import { useUniversalSearch } from '../hooks/useUniversalSearch';
import useCancellable from '../hooks/useCancellable';

const Articles = ({ term }) => {
  const searchArticles = useCancellable((v, signal) =>
    artmarketInsightSearchOnKeyword({ term: v, signal }).then(data => ({
      data: data.items.map(({ title, image, highlights, slug, year_month_day: dt }) => ({ title, image, highlights, slug, dt })),
      totalCount: data.total_count,
      outOfBounds: false,
    })),
  );
  const { observeCallbackRef, loaded, entities: articles } = useUniversalSearch({ term, searchFunc: searchArticles });

  return (
    <div>
      <div ref={observeCallbackRef}>
        <SectionHeader
          count={articles.totalCount}
          title={<A18n rsx="search.universal.articles.m1" />}
          url={`/lots/search?keyword=${encodeURIComponent(term)}`}
          outOfBounds={articles.outOfBounds}
          op={ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE_SEARCH}
        />
        {!loaded && <Skeleton />}
        {loaded && (
          <div className="wide-items-container">
            <div className="wide-items">
              {articles.data.map(article => (
                <div className="wide-item" key={article.title}>
                  <div className="cover" style={{ backgroundImage: `url("${article.image}")` }} />

                  <div className="data-item">
                    <div className="date">
                      <FormattedDate value={article.dt} />
                    </div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="title-item" dangerouslySetInnerHTML={{ __html: article.title }} />

                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="excerpt" dangerouslySetInnerHTML={{ __html: `... ${article?.highlights?.[0]} ...` }} />

                    <div className="link">
                      <a
                        href={`/artmarketinsight/${article.slug}`}
                        onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op: ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, slug: article.slug })}
                      >
                        <A18n rsx="search.universal.articles.m2" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Articles;
