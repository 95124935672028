import React from 'react';
import i18next from 'i18next';

const FormatedDimensions = ({ dimentions, unit }) => (
  <>
    {dimentions.map(v => v.toLocaleString(i18next.language, { maximumFractionDigits: 2 })).join(' x ')}
    <span> {unit}</span>
  </>
);

export default FormatedDimensions;
