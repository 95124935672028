import i18next from 'i18next';
import RandExp from 'randexp';
import { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { boolean, number, object, setLocale, string } from 'yup';
import { getCart } from '../../../../redux/slices/payments';

export default function useSchemas() {
  let tvaIntraRegexp = '';
  const { main_products: mainProducts } = useSelector(getCart);
  const isRecurrentSubscription = get(mainProducts, '[0].product.isRecurrentSubscription');

  useEffect(() => {
    setLocale({
      mixed: {
        required: i18next.t('payments.informations.form.m3'),
      },
      string: {
        min: i18next.t('payments.informations.form.m3'),
        max: ({ max }) => i18next.t('payments.informations.form.m9').replace('%nb%', max),
      },
    });
  }, []);

  const billaddress = object({
    idtitle: number().required(),
    firstname: string().required().max(255),
    lastname: string().required().max(255),
    line1: string().required().max(100),
    postcode: string().required().max(30),
    city: string().required().max(50),
    state: string().max(60),
    firm: string().nullable().max(100),
    phone: string().required().min(6).max(20),
    tvaintra: string()
      .nullable()
      .test(
        'tvaFormatRequired',
        () => i18next.t('payments.informations.form.m3'),
        (value, { parent: { tvaformatregex, firm, noTvaintra } }) => {
          if (noTvaintra) return true;
          if (isEmpty(tvaformatregex)) return true;
          if (isEmpty(firm)) return true;

          return !isEmpty(value);
        },
      )
      .test(
        'tvaFormatFormat',
        () => i18next.t('payments.informations.form.fields.tvaintra.m2').replace('%format%', new RandExp(tvaIntraRegexp).gen()),
        (value, { parent: { tvaformatregex, noTvaintra } }) => {
          if (noTvaintra) return true;
          if (!isEmpty(tvaformatregex) && !isEmpty(value)) {
            tvaIntraRegexp = tvaformatregex;
            return !isEmpty(value.match(new RegExp(tvaformatregex)));
          }
          return true;
        },
      ),
    idcountry: number().required(),
  }).required();

  const getSchemaForNewCustomer = ({ idcustomer }) =>
    object({
      pwd: string()
        .required()
        .matches(idcustomer ? null : /^(?=.*[A-Za-z])(?=.*\d)(?=.*)[A-Za-z\d@$!%*#?&]{8,30}$/, {
          message: i18next.t('payments.informations.form.password.m1'),
          name: 'pwd',
        }),
      email: string().required().email().max(250),
      idtitle: number().required(),
      idactivity: number().required(),
      firstname: string().required().max(150),
      lastname: string().required().max(150),
      billaddress,
      checkMonthly: boolean().oneOf([isRecurrentSubscription], i18next.t('payments.informations.form.cgv.m2')),
      communications: boolean(),
      checkCgv: boolean().oneOf([true], i18next.t('payments.informations.form.cgv.m1')),
    }).required();

  const getSchemaForExistingCustomer = () =>
    object({
      billaddress,
      checkMonthly: boolean().oneOf([isRecurrentSubscription], i18next.t('payments.informations.form.cgv.m2')),
      checkCgv: boolean().oneOf([true], i18next.t('payments.informations.form.cgv.m1')),
    }).required();

  return { getSchemaForNewCustomer, getSchemaForExistingCustomer };
}
