import React, { useEffect, useState } from 'react';
import { FormattedNumber, injectIntl } from 'react-intl';
import { useViewport } from '../../../../../hooks/useViewport';
import '../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const MAXIMUM_CATEGORIES_COUNT = 50;
const MAXIMUM_CATEGORIES_COUNT_RESPONSIVE = 6;

const Categories = ({ clickOnCategories, clickOnNotSigned, categories = [], lots, intl, period }) => {
  const [all, setAll] = useState(false);
  const [maxCategoriesCount, setMaxCategoriesCount] = useState(MAXIMUM_CATEGORIES_COUNT);
  const [initialCategories, setInitialCategories] = useState();
  const [othersCategoriesCount, setOthersCategoriesCount] = useState(0);

  const { viewportWidth } = useViewport();

  useEffect(() => {
    const categoriesCount = viewportWidth < 768 ? MAXIMUM_CATEGORIES_COUNT_RESPONSIVE : MAXIMUM_CATEGORIES_COUNT;
    setMaxCategoriesCount(lots?.notSignedCount > 0 ? categoriesCount - 1 : categoriesCount);
  }, [viewportWidth, lots]);

  useEffect(() => {
    let categoriesCount = maxCategoriesCount;
    if (categories?.length > maxCategoriesCount) {
      categoriesCount = maxCategoriesCount - 1;
    }

    setInitialCategories(categories.slice(0, all ? 100 : categoriesCount));
    setOthersCategoriesCount(categories.slice(categoriesCount, 100).reduce((acc, c) => acc + c.count, 0));
  }, [categories?.length, maxCategoriesCount, all]);

  if (!initialCategories) return null;

  return (
    <div className="categories">
      {initialCategories.map(category => (
        <a href={category.urlWithFilter} onClick={e => clickOnCategories(e)} id={`pc${period[0]}${category.id}`} className={`pw${period[0]}c artp-btn artp-btn-artist`} key={`${category.count}-${category.label}`}>
          {category.label} (<FormattedNumber value={category.count} />)
        </a>
      ))}

      {!all && othersCategoriesCount > 0 && (
        <div className="artp-btn artp-btn-artist" key={`${othersCategoriesCount}-${'other'}`} onClick={() => setAll(true)}>
          <A18n rsx="artists.artist.lots.categories.m1" /> (<FormattedNumber value={othersCategoriesCount} />)
        </div>
      )}

      {lots.notSignedCount > 0 && (
        <a className="artp-btn artp-btn-artist" href={lots.notSignedUrl} onClick={e => clickOnNotSigned(e)} title={lots.notSignedTitle}>
          <span>
            <A18n rsx={lots.notSignedCount === 1 ? 'artists.artist.lots.categories.m3' : 'artists.artist.lots.categories.m2'} /> ({intl.formatNumber(lots.notSignedCount)})
          </span>
        </a>
      )}
    </div>
  );
};

export default injectIntl(Categories);
