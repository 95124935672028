import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import Input from '../../common/Input';
import countryCodes from './countryCodes';

const Phone = ({ locked, type, iso2Code }) => {
  const {
    setValue,
    getValues,
    formState: { errors },
    defaultValues,
  } = useFormContext();

  useEffect(() => {
    if (iso2Code) {
      const values = getValues();

      if (values[type].idcountry !== defaultValues[type].idcountry || values[type].phone !== defaultValues[type].phone) {
        const countryCode = countryCodes.find(({ code }) => code === iso2Code);
        setValue(`${type}.phone`, countryCode?.dialCode);
      }
    }
  }, [iso2Code]);

  return (
    <div className={classNames({ cell: true, warning: !isEmpty(get(errors, `${type}.phone`)) })}>
      <Input locked={locked} name={`${type}.phone`} placeholder={i18next.t('payments.informations.form.fields.phone.m1')} />
    </div>
  );
};

export default Phone;
