import React from 'react';
import { useSelector } from 'react-redux';
import { isLogged as isLoggedSelector } from '../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Contact = ({ store }) => {
  const isLogged = useSelector(isLoggedSelector);

  if (!store) return null;

  if (!isLogged) {
    return (
      <div className="actions-contact">
        <a href="/identity" className="btn btn-info marg marg-b-20">
          <i className="fa fa-address-card" /> <A18n rsx="marketplace.free.show.contact.m11" />
        </a>
        {store.contact.country && (
          <div className="actions-contact-coord">
            <div className="actions-contact-block actions-contact-block-inline">
              <div className="actions-contact-block-label">
                <i className="fa fa-map-marker font font-18" />{' '}
              </div>
              {store.contact.country}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="actions-contact">
      <div className="actions-contact-coord">
        {store.contact.email && (
          <div className="actions-contact-block actions-contact-block-inline">
            <div className="actions-contact-block-label">
              <i className="fa fa-envelope-open-o font font-18" />
            </div>
            <a href={`mailto:${store.contact.email}`}>{store.contact.email}</a>
          </div>
        )}
        {store.contact.phone && (
          <div className="actions-contact-block actions-contact-block-inline">
            <div className="actions-contact-block-label">
              <i className="fa fa-phone font font-18" />{' '}
            </div>
            {store.contact.phone}
          </div>
        )}
        {store.contact.country && (
          <div className="actions-contact-block actions-contact-block-inline">
            <div className="actions-contact-block-label">
              <i className="fa fa-map-marker font font-18" />{' '}
            </div>
            {store.contact.country} {`(${store.contact.city})`}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
