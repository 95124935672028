import TagManager from 'react-gtm-module';
import { TM_CONTAINER_ID, GTM_DATA_LAYER_NAME } from '../constants/rails';
import { onClient } from './ssr';

export default function initializeGtm({ pageCategory }) {
  const tagManagerArgs = {
    gtmId: TM_CONTAINER_ID,
    dataLayer: {
      pageCategory,
    },
    dataLayerName: GTM_DATA_LAYER_NAME,
  };

  if (onClient()) {
    TagManager.initialize(tagManagerArgs);
  }
}
