import React from 'react';

import Item from './Item';
import { EMPTY_ARRAY } from '../../../constants/utils';

const Events = ({ events: marketplaceEvents = EMPTY_ARRAY }) => {
  if (marketplaceEvents.length <= 0) return null;
  return marketplaceEvents.map(event => <Item key={`#${event.id}-${event.title}`} event={event} />);
};

export default Events;
