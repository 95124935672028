import React, { useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOMER_EMAIL_STATUS_INPROGRESS, CUSTOMER_EMAIL_STATUS_NONVERIFIED, CUSTOMER_EMAIL_STATUS_VALID } from '../../../constants/rails';
import { getEmailStatus, getEmail, isUndelivered } from '../../../redux/slices/userContext/selectors';
import { ACCOUNT_LOADING_KEY } from '../../../constants/account/confirm';
import { sendValidationEmail } from '../../../redux/actions/account';
import { get } from '../../../redux/selectors/ui/dictionary';

const UNDELIVERED = 'undelivered';
const statuses = {
  valid: {
    title: i18next.t('components.account.emailvalidbadge.badge.m1'),
    className: 'fa fa-check-circle text-success fa-fw',
  },
  nonverified: {
    title: i18next.t('components.account.emailvalidbadge.badge.m2'),
    className: 'fa fa-exclamation-circle text-warning fa-fw',
  },
  [UNDELIVERED]: {
    title: i18next.t('components.account.emailvalidbadge.badge.m2'),
    className: 'fa fa-exclamation-circle text-warning fa-fw',
  },
  inprogress: {
    title: i18next.t('components.account.emailvalidbadge.badge.m3'),
    className: 'fa fa-info-circle text-info fa-fw',
  },
  unknown: {
    title: i18next.t('components.account.emailvalidbadge.badge.m4'),
    className: 'fa fa-exclamation-circle text-danger fa-fw',
  },
};

// bta => back to account after validation
const EmailStatus = ({ backTo, withWarning = true, redirectAfterSending = false, onClick }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => get(state, { id: ACCOUNT_LOADING_KEY }));
  const emailStatus = useSelector(getEmailStatus);
  const email = useSelector(getEmail);
  const undelivered = useSelector(isUndelivered);
  const [waitingRedirect, setWaitingRedirect] = useState(false);

  if (!email) return null;
  if (loading || waitingRedirect) return <i className="fa fa-spinner fa-spin fa-fw" style={{ marginTop: 10 }} />;

  const status = undelivered && emailStatus === CUSTOMER_EMAIL_STATUS_VALID ? UNDELIVERED : emailStatus;
  return (
    <div className="artp-account-email-status">
      {withWarning && (
        <div className="email-status-badge">
          <i className={statuses[status].className} />
          <span>{statuses[status].title}</span>
        </div>
      )}
      {status === CUSTOMER_EMAIL_STATUS_INPROGRESS && withWarning && <div className="in-progress">{i18next.t('components.account.emailvalidbadge.m3')}</div>}
      {status !== CUSTOMER_EMAIL_STATUS_VALID && (
        <div>
          <a
            className="email-status-link btn btn-primary"
            href="#"
            onClick={async () => {
              await dispatch(sendValidationEmail({ backTo }));
              if (redirectAfterSending) {
                setWaitingRedirect(true);
                window.location = redirectAfterSending;
              } else {
                onClick?.();
              }
            }}
          >
            {status === CUSTOMER_EMAIL_STATUS_INPROGRESS && i18next.t('components.account.emailvalidbadge.m1')}
            {status === CUSTOMER_EMAIL_STATUS_NONVERIFIED && i18next.t('components.account.emailvalidbadge.m2')}
            {status === UNDELIVERED && i18next.t('components.account.emailvalidbadge.m2')}
          </a>
        </div>
      )}
    </div>
  );
};

export default EmailStatus;
