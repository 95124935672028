import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import Select from 'react-select';
import { debounce as debounceFunction } from 'lodash';
import { useSelector } from 'react-redux';
import customStyles from '../../../common/reactSelect/style';
import { getIntegerValue } from '../../../../../utils/search/inputs';
import { getDimension } from '../../../../../redux/selectors/preferences';
import './stylesheet.scss';

const OPTIONS = [
  { value: 'cm', label: 'cm' },
  { value: 'in', label: 'in' },
];

/*
value is an object : { height:, width:, length:, unit:, percent: }
 */
const Dimensions = ({ className, value = {}, onChange, debounce = 100 }) => {
  const [internalValue, setInternalValue] = useState(value);
  const debouncedOnChange = useMemo(() => debounceFunction(onChange, debounce), [onChange, debounce]);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const defaultDimension = useSelector(getDimension);
  const unit = useMemo(() => {
    const unitToFind = internalValue.unit || defaultDimension;
    return OPTIONS.find(o => o.value === unitToFind) || OPTIONS[0];
  }, [defaultDimension, internalValue.unit]);

  return (
    <div className={`search-dimension ${className ?? ''}`.trim()}>
      <div className="cell cell-1">
        <input
          type="number"
          className="form-control"
          value={internalValue?.height ?? ''}
          onChange={e => {
            const height = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, height }));
            debouncedOnChange({ height, unit: internalValue.unit, percent: internalValue.percent });
          }}
          placeholder={i18next.t('lots.search.form.size.m3')}
        />
      </div>
      <div className="cell cell-2">
        <input
          type="number"
          className="form-control"
          value={internalValue?.width ?? ''}
          onChange={e => {
            const width = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, width }));
            debouncedOnChange({ width, unit: internalValue.unit, percent: internalValue.percent });
          }}
          placeholder={i18next.t('lots.search.form.size.m1')}
        />
      </div>
      <div className="cell cell-3">
        <input
          type="number"
          className="form-control"
          value={internalValue?.length ?? ''}
          onChange={e => {
            const length = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, length }));
            debouncedOnChange({ length, unit: internalValue.unit, percent: internalValue.percent });
          }}
          placeholder={i18next.t('lots.search.form.size.m2')}
        />
      </div>
      <div className="cell cell-4">
        <Select
          placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m12')}
          styles={customStyles(0)}
          className="search-common-react-select"
          classNamePrefix="react-select"
          options={OPTIONS}
          value={unit}
          onChange={newUnit => {
            setInternalValue(prevValue => ({ ...prevValue, unit: newUnit }));
            // don't trigger onChange if there are no dimension set
            if (internalValue?.height || internalValue?.width || internalValue?.length) {
              debouncedOnChange({ unit: newUnit });
            }
          }}
        />
      </div>
      <div className="cell cell-5">
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            value={internalValue?.percent ?? ''}
            onChange={e => {
              const percent = getIntegerValue(e.target.value);
              setInternalValue(prevValue => ({ ...prevValue, percent }));
              // don't trigger onChange if there are no dimension set
              if (internalValue?.height || internalValue?.width || internalValue?.length) {
                debouncedOnChange({ percent });
              }
            }}
            placeholder="+/-"
            min="0"
            max="10"
          />
          <span className="input-group-addon" id="basic-addon1">
            %
          </span>
        </div>
      </div>
    </div>
  );
};

export default Dimensions;
