import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { legals as legalsService } from '../../../../../services/marketplace/howto';
import Spinner from '../../../../common/spinners/Spinner';
import A18n from '../../../../common/A18n';
import Modal from '../../../../common/ui/Modal';
import './stylesheet.scss';

const Legals = ({ open, setOpen }) => {
  const [legals, setLegals] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (open) {
      setLoading(true);
      legalsService().then(({ content }) => {
        setLegals(content);
        setLoading(false);
      });
    }
  }, [open]);

  return (
    <Modal className="modal-lg marketplace-classified-actions-legals" title={i18next.t('footer.marketplace_legals')} open={open} onClose={() => setOpen(false)}>
      <ModalBody>
        {loading && <Spinner faSize={3} />}

        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: legals }} />
      </ModalBody>

      <ModalFooter>
        <Button className="btn btn-primary" onClick={() => setOpen(false)} data-dismiss="modal">
          <A18n rsx="login._modal.html.m6" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Legals;
