import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL, ANALYTICS_FROM_SEARCH_UNIVERSAL, IMAGE_SOURCE } from '../../../../../constants/rails';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const Artist = ({ artist }) => {
  const [backgroundImage, setBackgroundImage] = useState(`url("${IMAGE_SOURCE}artist/lot-bg-${(artist.id % 5) + 1}.png")`);

  useEffect(() => {
    if (artist.bl) {
      setBackgroundImage(`url("${IMAGE_SOURCE}no-access/img-non-dispo.svg")`);
    } else if (artist.mainPicto) {
      setBackgroundImage(`url("${artist.mainPicto}")`);
    }
  }, [artist]);

  const handleClick = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL, idartist: artist.id });
  };

  return (
    <div className="item" title={artist.html}>
      <div className="r0">
        <a href={artist.url} className={classNames({ 'no-underline': !artist.mainPicto })} onClick={handleClick}>
          <div
            className={classNames({
              'no-image': artist.bl,
              picto: artist.mainPicto,
              'fake-picto': !artist.mainPicto,
            })}
            style={{ backgroundImage }}
          >
            {!artist.mainPicto && !artist.bl && <span>{artist.initial}</span>}
          </div>
        </a>
      </div>
      <div className="r1">
        <a className={`e2e-aa-${artist.idartistalias}`} href={artist.url} onClick={handleClick}>
          {artist.name}
        </a>
      </div>
      <div className="r2">
        <a href={artist.url} onClick={handleClick}>
          {artist.dates}
        </a>
      </div>
    </div>
  );
};

export default Artist;
