/* eslint-disable react/no-danger */

import React from 'react';
import i18next from 'i18next';
import { Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const Explain = () => {
  const navigate = useNavigate();
  const onDismiss = () => {
    navigate('/account/confirm');
  };

  return (
    <div className="container">
      <Alert fade={false} color="info" isOpen toggle={onDismiss}>
        <p>{i18next.t('components.account.confirm.explain.m1')}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: i18next.t('components.account.confirm.explain.m2', { interpolation: { escapeValue: false } }).replace('%url_marketplace_legals%', '/block/marketplace_legals?modal=true'),
          }}
        />
      </Alert>
    </div>
  );
};

export default Explain;
