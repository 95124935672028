import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { activitiesForForms } from '../../../../services/wallet/activity';
import BasicDropdown from '../BasicDropdown';

const ActivityDropdown = ({ name, control, readOnly, isSearchable = false, rules = {}, width, placeholder }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    activitiesForForms().then(datas => {
      setActivities(datas.map(({ id, label }) => ({ value: id, label })));
      setIsLoading(false);
    });
  }, []);

  return (
    <BasicDropdown
      control={control}
      name={name}
      width={width}
      rules={rules}
      options={activities}
      isLoading={isLoading}
      readOnly={isLoading || readOnly}
      isSearchable={isSearchable}
      placeholder={placeholder || i18next.t('components.account.verify.identity.m4')}
      loadingMessage={i18next.t('components.account.verify.identity.m5')}
    />
  );
};

export default ActivityDropdown;
