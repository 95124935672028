/* eslint-disable brace-style */
import React from 'react';
import i18next from 'i18next';
import { difference } from 'lodash';
import { addMonths } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSubscription, getLastSubscription } from '../../../../../redux/slices/userContext/selectors';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { NEW } from '../constants';
import { isCancelled } from '../../../../../utils/subscriptions/attentionNeeded';

import './stylesheet.scss';
import { setSessionStorage } from '../../../../../utils/storage';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_CART } from '../../../../../constants/rails';

const DISABLED = 'disabled';

const SubscribeButton = ({ theme, label, displayInWarningsModale = false }) => {
  const dispatch = useDispatch();
  const { attentionNeeded, recurrent = false } = useSelector(getActiveSubscription) || {};
  const lastSubscription = useSelector(getLastSubscription) || {};
  const { modules = [], dates } = lastSubscription;
  const { selectedIdphysicproduct, image, quantity, initialQuantity, view, promocode, physicproduct, from } = useSelector(getSettings);

  const physicproducts = theme?.physicproducts || [];

  const buttonBuyIsActive = () => physicproducts.filter(p => p?.idp === selectedIdphysicproduct).length > 0;

  const handleSubmit = () => {
    const missingModules = difference(modules || [], theme.module);
    const addedModules = difference(theme.module, modules || []);
    let idphysicproduct = selectedIdphysicproduct;

    // Selection direct de l'abo si il est le seul présent dans le theme et non encore sélectionné
    if (physicproducts.length === 1 && idphysicproduct !== physicproducts[0].idp) {
      dispatch(updateSettings({ data: { selectedIdtheme: theme.idtheme, selectedIdphysicproduct: physicproducts[0].idp } }));
      idphysicproduct = physicproducts[0].idp;
    }

    // On considère que le click sur un theme qui a un seul produit est possible sans selection dudit produit
    if (!buttonBuyIsActive() && physicproducts.length > 1) return null;

    // Test du cas du downgrade & display modale
    // il faut que la le dernier abo se soit terminer il y a plus de deux mois
    if ((missingModules.length > 0 || initialQuantity > quantity) && !displayInWarningsModale && new Date(dates?.dtExpire) > addMonths(new Date(), -12)) {
      dispatch(updateSettings({ data: { displayWarningsModale: true, missingModules, warningForRenewInPlaceOfUpgrade: false } }));
    }
    // Test si en renew le client prend un abo supérieur (durée module quantity)
    else if (!displayInWarningsModale && view === NEW && new Date(lastSubscription?.dates?.dtExpire) > addMonths(new Date(), 2) && (quantity > initialQuantity || addedModules.length > 0)) {
      dispatch(updateSettings({ data: { displayWarningsModale: true, missingModules: [], warningForRenewInPlaceOfUpgrade: true } }));
    }
    // Tout est passé on redirige vers le cart
    else {
      try {
        const product = {
          name: physicproduct?.reference,
          id: idphysicproduct,
          price: physicproduct?.price.eur,
          brand: 'Artprice.com',
          category: theme.theme,
          variant: `period-${physicproduct?.nbmonths}`,
          quantity,
        };

        setSessionStorage('ngStorage-gtmProduct', product);

        window.dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [product],
            },
          },
        });
      } catch (e) {
        console.error(e);
      }

      aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TO_CART, section: physicproduct?.reference });

      window.location = `/subscription/cart?image=${image}&idphysicproduct=${idphysicproduct}&type=${view}&promocode=${promocode}`;
    }
  };

  const buttonClassname = () => {
    if (buttonBuyIsActive()) return 'active sln-selected';

    if (physicproducts.length > 1) return DISABLED;

    return '';
  };

  return (
    <div className="subscriptions-list-theme-subscribe-button" title={buttonClassname() !== DISABLED ? '' : i18next.t('subscriptions.purchase.common.subscribe-button.m1')}>
      {(!recurrent || isCancelled(attentionNeeded)) && (
        <button className={buttonClassname()} type="button" onClick={handleSubmit}>
          {label}
        </button>
      )}
    </div>
  );
};

export default SubscribeButton;
