import React, { useMemo, useState } from 'react';
import i18Next from 'i18next';
import { createNextState } from '@reduxjs/toolkit';
import { isPlainObject, isEmpty, unset, mapValues } from 'lodash';
import { useSelector } from 'react-redux';
import { getAdvancedFacets, getAdvancedParams, getBaseFacets } from '../../../../redux/slices/search/scoped';

import { getConfigById, getIdByField, hasConfig } from './config';
import './stylesheet.scss';
import { EMPTY_OBJECT } from '../../../../constants/utils';

function removeHoles(object) {
  return mapValues(object, v => {
    if (isPlainObject(v)) {
      return removeHoles(v);
    }
    if (Array.isArray(v)) {
      const newArray = v.filter(item => item !== undefined);
      return newArray.length > 0 ? newArray : undefined;
    }
    return v;
  });
}

// returns itself too
function getAllRelatedParams(param, processedParams) {
  if (!param) {
    return [];
  }
  const result = [param];
  if (param.children) {
    result.push(...param.children.flatMap(childId => getAllRelatedParams(processedParams[childId])));
  }
  return result;
}

const Params = ({ searchScope, hiddenParams = {}, onChange, extras = [] }) => {
  const [hoveredParams, setHoveredParams] = useState([]);
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const advancedFacets = useSelector(state => getAdvancedFacets(state, { searchScope }));
  const baseFacets = useSelector(state => getBaseFacets(state, { searchScope }));

  const processedParams = useMemo(() => {
    const result = {};
    if (!isPlainObject(advancedParams)) {
      return {};
    }

    // a param can have :
    // - a specific config (custom display)
    // - a standard config (will use the values (labels) in the advanced|base facets as display)
    // - otherwise it will be shown as "loading", or a warn will be emitted in the console (should not happen)
    Object.keys(advancedParams)
      // removing hidden params
      .filter(key => !hiddenParams[key])
      .forEach(paramKey => {
        if (hasConfig(paramKey)) {
          // >>> has a specific config
          const groupId = getIdByField(paramKey);
          // if the group is not yet processed
          if (groupId && !result[groupId]) {
            const groupConfig = getConfigById(groupId);
            result[groupId] = {
              id: groupId,
              label: groupConfig.display(advancedParams),
              paths: groupConfig.paramsToDelete,
            };
          }
        } else if (advancedParams?.[paramKey]) {
          // >>> has a matching param
          const paramValues = Array.isArray(advancedParams[paramKey]) ? advancedParams[paramKey] : [advancedParams[paramKey]];
          paramValues.forEach((paramValue, paramIndex) => {
            const id = `${paramKey}-${paramValue}`;
            // finding the corresponding facets for the current param (will search in advanced then in the base facets)
            // searching in the base facets as backup to prevent problems when there is 0 results
            const matchingFacet = advancedFacets?.[paramKey]?.[paramValue] || baseFacets?.[paramKey]?.[paramValue];
            if (matchingFacet) {
              const processedValue = {
                id,
                label: matchingFacet.label,
                keysToDelete: [paramKey],
                paths: [`${paramKey}${Array.isArray(advancedParams[paramKey]) ? `[${paramIndex}]` : ''}`], // lodash paths
              };

              // populating the processedValue.children in order for the "hover" to work (hovering the parent will highlight the children too)
              if (matchingFacet.children) {
                const children = [];
                matchingFacet.children.forEach(childParamKey => {
                  if (advancedParams?.[childParamKey]) {
                    const childMatchingValues = Array.isArray(advancedParams[childParamKey]) ? advancedParams[childParamKey] : [advancedParams[childParamKey]];
                    childMatchingValues.forEach(childParamValue => {
                      children.push(`${childParamKey}-${childParamValue}`);
                    });
                  }
                });
                if (children.length > 0) {
                  processedValue.children = children;
                }
              }
              result[id] = processedValue;
            }
          });
        } else if (!advancedFacets || !baseFacets) {
          // >>> loading
          result[`loading-${paramKey}`] = {
            id: `loading-${paramKey}`,
            label: i18Next.t('javascript.application.js.m0'),
            paths: [paramKey],
          };
        } else {
          // >>> wtf ? someone messed up
          console.warn(`No facet or config for ${paramKey}`);
        }
      });

    return result;
  }, [advancedParams, advancedFacets, baseFacets]);

  if ((!isPlainObject(processedParams) || isEmpty(processedParams)) && isEmpty(extras)) {
    return null;
  }

  return (
    <div className="search-params">
      <div
        className="clear-all"
        onMouseEnter={() => {
          setHoveredParams(Object.keys(processedParams));
        }}
        onMouseLeave={() => {
          setHoveredParams([]);
        }}
        onClick={() => onChange(EMPTY_OBJECT)}
      >
        <i className="fa fa-trash-o" />
      </div>
      {Object.values(processedParams)
        .filter(processedParam => processedParam.label)
        .map(processedParam => (
          <div
            key={processedParam.id}
            className={`search-param ${hoveredParams.includes(processedParam.id) ? 'hovered' : ''}`}
            onMouseEnter={() => {
              setHoveredParams(getAllRelatedParams(processedParam, processedParams).map(({ id }) => id));
            }}
            onMouseLeave={() => {
              setHoveredParams([]);
            }}
            onClick={() => {
              // we sort and reverse to ensure that all indexes are starting from the max to the min
              // Ex: idtechnique[1], idtechnique[0], idcategory, etc
              // this way, we can delete them correctly
              const paths = getAllRelatedParams(processedParam, processedParams)
                .flatMap(p => p.paths)
                .sort()
                .reverse();
              const newParams = createNextState(advancedParams, draft => {
                paths.forEach(path => unset(draft, path));
              });

              // the unset method can leave "holes" in arrays
              onChange(removeHoles(newParams));
            }}
          >
            {processedParam.label} {hoveredParams.includes(processedParam.id) ? <i className="fa fa-trash-o" /> : <i className="fa fa-times" />}
          </div>
        ))}
      {extras.map(extra => (
        <span key={`extra-${Math.random()}`}>{extra}</span>
      ))}
    </div>
  );
};

export default Params;
