import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSCRIPTION, STORE } from '../../common/constants';
import { ID_FREE_ARTIST } from '../../../../../constants/rails';
import { getTheme } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { fetchFreeArtist } from '../../../../../redux/actions/dbEntities/artist';
import Marketplace from './Marketplace';
import Data from './Data';
import Modal from '../../../../common/ui/Modal';
import './stylesheet.scss';

const ThemeDetails = () => {
  const dispatch = useDispatch();
  const { filter, displayThemeDetailsModale = false, displayThemeDetailsModaleIdtheme } = useSelector(getSettings);
  const theme = useSelector(state => getTheme(state, { idtheme: displayThemeDetailsModaleIdtheme }));

  useEffect(() => {
    dispatch(fetchFreeArtist());
  }, [ID_FREE_ARTIST]);

  if (!theme) return null;

  return (
    <Modal
      title={i18next.t('subscriptions.purchase.list.theme-details-modale.m1').replace('%subscription%', theme.theme)}
      className={`subscriptions-purchase-list-theme-details lvl-${displayThemeDetailsModaleIdtheme}`}
      onClose={() => dispatch(updateSettings({ data: { displayThemeDetailsModale: false } }))}
      open={displayThemeDetailsModale}
    >
      <div className="content">
        {filter === SUBSCRIPTION && <Data theme={theme} />}
        {filter === STORE && <Marketplace theme={theme} />}
      </div>

      <div className="actions">
        <button type="button" className="active" onClick={() => dispatch(updateSettings({ data: { displayThemeDetailsModale: false } }))}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.m2')}
        </button>
      </div>
    </Modal>
  );
};

export default ThemeDetails;
