import React from 'react';
import { IMAGE_SOURCE } from '../../../../constants/rails';

const Evolution = ({ current, previous }) => {
  if (current === previous) {
    return <img alt="variation" style={{ transform: 'rotate(90deg)' }} src={`${IMAGE_SOURCE}indexes-up.svg`} />;
  }

  if (current < previous) {
    return <img alt="variation" src={`${IMAGE_SOURCE}indexes-up.svg`} />;
  }

  if (!Number.isInteger(previous)) {
    return <img alt="variation" src={`${IMAGE_SOURCE}indexes-up.svg`} />;
  }

  return <img alt="variation" src={`${IMAGE_SOURCE}indexes-down.svg`} />;
};

export default Evolution;
