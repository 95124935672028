import React, { useEffect, useState } from 'react';

const ScrollTo = ({ toId, toRef, onClick, childType, className, children }) => {
  const [element, setElement] = useState();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const headerElement = document.getElementById('artp-header');

    if (headerElement) {
      setHeaderHeight(headerElement.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    if (toRef) {
      setElement(toRef.current);
    } else if (toId) {
      setElement(document.getElementById(toId));
    }
  }, [toRef, toId]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (element) {
      window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY - headerHeight, behavior: 'smooth' });
    }
  };

  if (childType === 'button') {
    return (
      <button className={className} type="button" onClick={handleClick}>
        {children}
      </button>
    );
  }

  return (
    <a className={`pointer ${className || ''}`} onClick={handleClick}>
      {children}
    </a>
  );
};

export default ScrollTo;
