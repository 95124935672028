/* eslint-disable global-require */
module.exports = [
  ...require('./FreeArtist/i18n'),
  ...require('./FreeLot/i18n'),
  'lots.no_access.m10',
  'lots.no_access.m17',
  'lots.no_access.m12',
  'lots.no_access.m18',
  'lots.no_access.m16',
  'lot.no_access.show.m1',
  'lot.no_access.show.m2',
  'lot.no_access.show.m2',
];
