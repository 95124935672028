import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteLots } from '../../../../../redux/slices/account/lotslists';
import '../../stylesheet-common.scss';
import A18n from '../../../../common/A18n';

const MoveButton = ({ list, idlots = [], sort }) => {
  const dispatch = useDispatch();

  return (
    <button
      className="lots-lists-button"
      disabled={idlots.length === 0}
      type="button"
      onClick={() =>
        dispatch(
          deleteLots({
            id: list.id,
            idlots,
            sort,
          }),
        )
      }
    >
      <i className="fa fa-trash-o fa-lg" /> <A18n rsx="components.lotslists.delete.m1" />
    </button>
  );
};
export default MoveButton;
