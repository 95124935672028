import React from 'react';
import i18next from 'i18next';
import TabsTitle from '../TabsTitle';
import HorizontalBar from '../../../common/HorizontalBar';
import { style as horizontalBarStyle } from '../styles/horizontalBar';
import './stylesheet.scss';

const MainLayout = React.forwardRef(({ children, horizontalBarChildren }, ref) => (
  <div className="myartprice">
    <h1 id="title" className="text-center" ref={ref}>
      {i18next.t('layouts.header.m45')}
    </h1>
    <TabsTitle />
    {horizontalBarChildren && <HorizontalBar className={`visible-sm visible-xs ${horizontalBarStyle.className}`}>{horizontalBarChildren}</HorizontalBar>}

    <div className="container">
      <div className="myartprice-common">{children}</div>
    </div>
  </div>
));

export default MainLayout;
