import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import Separator from '../../common/Separator';
import A18n from '../../../../common/A18n';

const Auctioneer = ({ classified }) => {
  const { saletitle, saledate, lot } = classified;
  if (!saletitle && !saledate && !lot) return null;

  return (
    <div className="centered-on-mobile">
      <Separator />

      {classified.saletitle && (
        <div className="classified-details-block">
          <div className="classified-label">
            {' '}
            <A18n rsx="marketplace.free._show.html.m2" />
          </div>
          {saletitle}
        </div>
      )}
      {saledate && (
        <div className="classified-details-block">
          <div className="classified-label">
            {' '}
            <A18n rsx="marketplace.free._show.html.m3" />
          </div>
          <FormattedDate value={saledate} /> <FormattedTime value={saledate} />
        </div>
      )}
      {lot && (
        <div className="classified-details-block">
          <div className="classified-label">
            {' '}
            <A18n rsx="marketplace.free._show.html.m5" />
          </div>
          {lot}
        </div>
      )}
    </div>
  );
};

export default Auctioneer;
