import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Follow from '..';

const FollowConnected = ({ idlot, size }) => (
  <ReduxProvider>
    <Follow idlot={idlot} size={size} />
  </ReduxProvider>
);

export default FollowConnected;
