/* eslint-disable jsx-a11y/no-autofocus */
import React, { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { a18n } from '../../../../utils/a18n';
import A18n from '../../../common/A18n';
import { ARTISTS_OFFICIAL_COUNT, AUCTIONEERS_OFFICIAL_COUNT, IMAGE_SOURCE, LOTS_OFFICIAL_COUNT, LOTS_OFFICIAL_YEAR_COUNT, MAIN_VIEWPORT_DESKTOP_WIDTH } from '../../../../constants/rails';
import './stylesheet.scss';
import { useViewport } from '../../../../hooks/useViewport';

// autoFocus for this shitty os that is iOS
const Input = forwardRef(({ value, onChange, placeholder, readOnly = false, autoFocus = false }, ref) => {
  const intl = useIntl();
  const [redirecting, setRedirecting] = useState(false);
  const { viewportWidth } = useViewport();
  const [internalValue, setInternalValue] = useState(value);
  const [displayComment, setDisplayComment] = useState(true);

  useEffect(() => {
    if (viewportWidth < MAIN_VIEWPORT_DESKTOP_WIDTH && internalValue) {
      setDisplayComment(false);
    } else if (!displayComment) {
      setDisplayComment(true);
    }
  }, [internalValue, viewportWidth]);

  const handleChange = e => {
    setInternalValue(e.target.value);
    onChange?.(e.target.value);
  };

  return (
    <div className="search-universal-input">
      <div className="input">
        <div className="magnifying-glass">
          <img alt="Search" src={`${IMAGE_SOURCE}menu/loupe.svg`} className="magnifying-glass" />
        </div>
        <input
          autoFocus={autoFocus}
          spellCheck="false"
          alt={a18n('search.universal.m2')}
          autoComplete="off"
          readOnly={readOnly}
          ref={ref}
          placeholder={placeholder}
          id="universal-search-input"
          onChange={handleChange}
          value={value}
          className={classNames('e2e-universal-search-input', { bottom: value })}
          onKeyDown={e => {
            if (e.key === 'Enter' && value !== '') {
              e.preventDefault();
              setRedirecting(true);
              window.location = `/artists/search?keyword=${encodeURIComponent(value)}`;
            }
          }}
        />
        {redirecting && (
          <div className="icon">
            <i className="fa fa-spinner fa-spin fa-2x" />
          </div>
        )}
      </div>
      {displayComment && (
        <div className="comment">
          <A18n
            rsx="search.universal.m1"
            replace={[
              ['%ARTISTS_OFFICIAL_COUNT%', intl.formatNumber(ARTISTS_OFFICIAL_COUNT)],
              ['%LOTS_OFFICIAL_YEAR_COUNT%', intl.formatNumber(LOTS_OFFICIAL_YEAR_COUNT)],
              ['%LOTS_OFFICIAL_COUNT%', intl.formatNumber(LOTS_OFFICIAL_COUNT)],
              ['%AUCTIONEERS_OFFICIAL_COUNT%', intl.formatNumber(AUCTIONEERS_OFFICIAL_COUNT)],
            ]}
          />
        </div>
      )}
    </div>
  );
});

export default Input;
