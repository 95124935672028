import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import './stylesheet.scss';

const ButtonFollow = ({ isFavorite, handleFollowClick, rsxFavorited, rsxNotFavorited, size, title }) => (
  <div className="button-follow">
    <Button className="btn" color="default" size={size} onClick={handleFollowClick} title={title}>
      {i18next.t(isFavorite ? rsxFavorited : rsxNotFavorited)} <i className={isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'} />
    </Button>
  </div>
);

export default ButtonFollow;
