/* eslint-disable global-require */
module.exports = [
  'artprice.client.payment.billdetail.m12',
  'artprice.lib.psresultctrl.m6',
  'artprice.lib.psresultctrl.m18',
  'artprice.lib.psresultctrl.m23',
  'artprice.Lib.PSResultCtrl.m_NV',
  'artprice.Lib.PSResultCtrl.m_LIP',
  'lot.show.access.m2',
  'lots.small.m1',
  'lots.small.m2',
  'lots.show.m9',
  'lots.show.m10',
  'lots.show.m11',
  'lots.show.m12',
  'classifieds.all.auction.m2',
  'lot.show.access.m1',
  'artprice.lib.artitem.m9',
  'lot.show.access.m3',
  'lot.show.access.m4',
  'lot.show.access.m5',
];
