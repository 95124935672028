import i18next from 'i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import LoginModal, { LOGIN_MODAL_OPEN_CONSTANT } from '../../../../common/LoginModal';
import AvailablePayments from '../../AvailablePayments';
import { set } from '../../../../../redux/actions/ui/dictionary';
import A18n from '../../../../common/A18n';
import Input from '../common/Input';
import './stylesheet.scss';
import useActiveElement from '../useActiveElement';
import { useViewport } from '../../../../../hooks/useViewport';

const Password = () => {
  const dispatch = useDispatch();
  const { idcustomer } = useSelector(getUserContext);
  const [display, setDisplay] = useState(false);
  const { register, watch } = useFormContext();
  const [validation, setValidation] = useState({ min: false, maj: false, num: false, size: false, invalid: true, empty: true });
  const { activeElement } = useActiveElement();
  const { viewportWidth } = useViewport();

  // UseEffect to change state depending on form values
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === `pwd`) {
        const rules = {
          lowercase: values.pwd.match(/[a-z]+/),
          uppercase: values.pwd.match(/[A-Z]+/),
          number: values.pwd.match(/\d+/),
          size: values.pwd.length >= 8 && values.pwd.length <= 30,
        };
        setValidation({ empty: false, ...rules, invalid: Object.values(rules).some(rule => !rule) });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="payments-informations-form-password">
      <div className="group">
        <div className="cell">
          <Input locked={idcustomer} name="pwd">
            <div className={classNames({ 'input-button': !idcustomer })}>
              <input id="pwd" type={display ? 'text' : 'password'} {...register('pwd')} placeholder={i18next.t('payments.informations.form.password.m2')} disabled={idcustomer} />
              {!idcustomer && (
                <button type="button" onClick={() => setDisplay(!display)}>
                  <i
                    className={classNames({
                      fa: true,
                      'fa-eye': !display,
                      'fa-eye-slash': display,
                    })}
                  />
                </button>
              )}
            </div>
          </Input>
        </div>

        {viewportWidth > 768 && !validation.empty && activeElement?.name === 'pwd' && (
          <div className="validation">
            <p>
              <A18n rsx="payments.informations.form.password.m6" />
            </p>
            <ul>
              <li className={classNames({ verified: validation.lowercase })}>
                <A18n rsx="payments.informations.form.password.m7" />
              </li>
              <li className={classNames({ verified: validation.uppercase })}>
                <A18n rsx="payments.informations.form.password.m8" />
              </li>
              <li className={classNames({ verified: validation.number })}>
                <A18n rsx="payments.informations.form.password.m9" />
              </li>
              <li className={classNames({ verified: validation.size })}>
                <A18n rsx="payments.informations.form.password.m10" />
              </li>
            </ul>
          </div>
        )}
      </div>
      {!idcustomer && (
        <div className="group">
          <div className="cell links">
            <A18n rsx="payments.informations.form.password.m3" />{' '}
            <a href="#" className="registered" onClick={() => dispatch(set(LOGIN_MODAL_OPEN_CONSTANT, true))}>
              <A18n rsx="payments.informations.form.password.m4" />
            </a>
          </div>
          <LoginModal
            onSuccess={() => {
              window.location.reload();
            }}
            title={i18next.t('payments.informations.form.password.m5')}
          >
            <AvailablePayments />
          </LoginModal>
        </div>
      )}
    </div>
  );
};

export default Password;
