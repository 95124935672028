import YouTube from 'react-youtube';
import React from 'react';
import i18next from 'i18next';
import videoConfig from './config';
import { LOCALE, YOUTUBE } from '../../../constants/howto';
import './stylesheet.scss';
import { useViewport } from '../../../hooks/useViewport';

const Video = ({ section, lang }) => {
  const { viewportWidth } = useViewport();

  if (!videoConfig[section] || !videoConfig[section].videos[lang || i18next.language]) {
    return false;
  }
  const config = videoConfig[section].videos[lang || i18next.language];

  let height = Math.round((viewportWidth - 66) / 1.7777778);
  let width = viewportWidth - 30;
  if (viewportWidth > 992) {
    height = Math.round(866 / 1.7777778);
    width = 866;
  } else if (viewportWidth > 768) {
    height = Math.round(566 / 1.7777778);
    width = 566;
  }

  const youtubeOptions = {
    height: String(height),
    width: String(width),
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="indexes-howto-video">
      {config.format === LOCALE && (
        <video width="100%" height="100%" autoPlay controls loop>
          <source src={config.ref} type="video/mp4" />
        </video>
      )}
      {config.format === YOUTUBE && <YouTube videoId={config.ref} opts={youtubeOptions} />}
    </div>
  );
};

export default Video;
