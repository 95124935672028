import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getAgreement } from '../../../redux/selectors/agreements';
import { accept } from '../../../redux/actions/auctioneers/agreements';
import { fetch } from '../../../redux/actions/agreements';
import { set } from '../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU, MENU_CONTRACT } from '../../../constants/auctioneers';
import { incDisplayCount } from '../../../services/auctioneers/agreement';
import ContactModale from './ContactModale';
import OpenModale from './OpenModale';
import { fetchSources } from '../../../redux/actions/auctioneers/medias';
import './stylesheet.scss';
import { ANALYTICS_FROM_AUCTIONEERS_AGREEMENT } from '../../../constants/rails';

const Agreement = () => {
  const dispatch = useDispatch();
  const agreement = useSelector(state => getAgreement(state, { idagreementtype: 3 }));
  const { register, handleSubmit } = useForm();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetch({ idagreementtype: 3 }));
    dispatch(set(ACTIVE_MENU, MENU_CONTRACT));
    dispatch(set(ACTIVE_AAPI_BEACON_FFROM, ANALYTICS_FROM_AUCTIONEERS_AGREEMENT));
    incDisplayCount();
    dispatch(fetchSources());
  }, []);

  if (!agreement) {
    return null;
  }

  const onSubmit = data => {
    const { chkAgreement } = data;
    if (chkAgreement) dispatch(accept());
  };

  return (
    <div className="auctioneer-agreement">
      <ContactModale open={open} setOpen={setOpen} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{i18next.t('auctioneers.agreement.m5')}</h1>
          </div>
        </div>
      </div>
      {agreement.signedVersion === agreement.lastVersion && (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <p>
                {i18next.t('auctioneers.agreement.m6')} <a href="/auctioneer">{i18next.t('auctioneers.agreement.m7')}</a>.
              </p>
              {agreement.signedAt && (
                <p>
                  {i18next.t('auctioneers.agreement.m13').replace('%dt%', agreement.signedAt)},{' '}
                  <a target="pdf" href="/agreement/auctioneer.pdf">
                    {i18next.t('auctioneers.agreement.m14')} <i className="fa fa-file-pdf-o" />
                  </a>
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 content">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: agreement.content }} />
            </div>
          </div>
        </div>
      )}

      {agreement.signedVersion !== agreement.lastVersion && (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <p>
                {agreement.signedVersion > 0 && i18next.t('auctioneers.agreement.m15')}
                {agreement.signedVersion === 0 && i18next.t('auctioneers.agreement.m6')}
              </p>
              <div className="content">
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: agreement.content }} />
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register('policies')} defaultValue={agreement.lastVersion} />
                <div className="chk-agreement">
                  <input type="checkbox" {...register('chkAgreement')} id="chkAgreement" onClick={e => setChecked(e.target.checked)} />
                  <label htmlFor="chkAgreement">{i18next.t('auctioneers.agreement.m3')}</label>
                </div>
                <div className="col-xs-12 marg marg-b-25">
                  <button disabled={!checked} type="submit" className="btn btn-primary marg marg-r-15">
                    {i18next.t('auctioneers.agreement.m4')}
                  </button>
                  <OpenModale setOpen={setOpen} btn>
                    {i18next.t('auctioneers.agreement.m2')}
                  </OpenModale>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row contact">
          <div className="col-xs-12 marg marg-b-20">
            {/* eslint-disable-next-line react/no-danger */}
            <h2 dangerouslySetInnerHTML={{ __html: i18next.t('auctioneers.agreement.m1') }} />
            <p>
              <OpenModale setOpen={setOpen}>{i18next.t('auctioneers.agreement.m16')}</OpenModale>
            </p>
          </div>
          <div className="col-xs-12 marg marg-b-20">
            <div className="title">Mathilde Fiallos</div>
            <p>Tel: +33 472 421 737</p>
            <p>
              <a href="mailto:memberauctioneers@artprice.com">memberauctioneers@artprice.com</a>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-xs-12 contact">
            <div className="title">Artprice</div>
            <p> Department of Auction Promotion</p>
            <p>Domaine de la Source, B.P. 69 69270 Saint Romain au Mont d&apos;Or France</p>
            <p>Fax: +33 (0)478 220 606 </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreement;
