import React from 'react';
import { useSelector } from 'react-redux';
import { getSwitch } from '../../../../../redux/selectors/ui/switches';
import { mustCompleteSellerProfile } from '../../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';

const MenuInformation = () => {
  const activeTab = useSelector(state => getSwitch(state, { id: 'StoreManagmentMenuActiveTab' }));
  const sellerProfileIncomplete = useSelector(mustCompleteSellerProfile);
  return (
    activeTab === 8 &&
    sellerProfileIncomplete && (
      <div className="notification">
        <a href="/account/confirm">
          <img className="icon" src={`${IMAGE_SOURCE}menu/pastille.svg`} alt="icon" />
          <A18n rsx="marketplace.common.managmentmenu.notification.m1" />
        </a>
      </div>
    )
  );
};

export default MenuInformation;
