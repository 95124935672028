import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getPromotion } from '../../../../redux/slices/estimates';
import A18n from '../../../common/A18n';

const Promotion = ({ position, promocode }) => {
  const promotion = useSelector(getPromotion);
  const [displayBanner, setDisplayBanner] = useState(true);

  const scrollToDetails = () => {
    const link = document.getElementById('promotion-details');
    if (link) {
      window.scrollTo({
        top: link.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (promotion?.url_image_1100) setDisplayBanner(false);
  }, [promotion]);

  if (!promotion) {
    if (position === 'top') return <div className="header" />;
    return null;
  }

  if (position === 'top')
    return (
      <div className={classNames({ 'with-banner': displayBanner, 'estimates-info-promotion': true })} onClick={scrollToDetails}>
        {displayBanner && <div className="banner-data" />}
        {promotion.url_image_1100 && (
          <div className="img">
            <img src={promotion.url_image_1100} alt="promotion" />
          </div>
        )}
        <div className="applied">
          <A18n rsx="subscriptions.list.promotion.m2" replace={[['%promocode%', promocode]]} trustHtml />
        </div>
      </div>
    );

  return (
    <div className="subscriptions-list-promotion-description" id="promotion-details">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: promotion.description }} />
      </div>
    </div>
  );
};

export default Promotion;
