import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { TOTAL_ACCESS_CHRONO, TOTAL_ACCESS_GEO } from '../../../constants/search/scope';
import { getCurrency } from '../../../redux/selectors/preferences';
import Map from './Map';
import Bar from './Bar';

const Graphs = ({ searchScope, params, onGraphClick }) => {
  const currency = useSelector(getCurrency);

  return (
    <Row>
      <Col sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
        {searchScope === TOTAL_ACCESS_CHRONO && <Bar params={params} currency={currency} onClick={onGraphClick} />}
        {searchScope === TOTAL_ACCESS_GEO && <Map params={params} currency={currency} onClick={onGraphClick} />}
      </Col>
    </Row>
  );
};

export default Graphs;
