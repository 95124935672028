import i18Next from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLots, clear } from '../../../../../redux/slices/account/lotslists';
import Checkbox from '../../../../common/ui/Checkbox';
import './stylesheets.scss';

const SelectAll = ({ ids, style = {} }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);

  const handleClick = select => {
    if (select) {
      setSelected(true);
      dispatch(addLots({ ids }));
    } else {
      setSelected(false);
      dispatch(clear());
    }
  };

  return (
    <div className="account-lots-lists-select-all-lots-button" style={style}>
      {selected && (
        <div className="button-select" onClick={() => handleClick(false)} title={i18Next.t('components.lotslists.selectall.m1')}>
          <Checkbox style={{ '--artp-checkbox-size': '12px' }} checked onClick={() => handleClick(false)} /> <span>{i18Next.t('components.lotslists.selectall.m1')}</span>
        </div>
      )}
      {!selected && (
        <div className="button-select" onClick={() => handleClick(true)} title={i18Next.t('components.lotslists.selectall.m2')}>
          <Checkbox style={{ '--artp-checkbox-size': '12px' }} checked={false} onClick={() => handleClick(true)} /> <span>{i18Next.t('components.lotslists.selectall.m2')}</span>
        </div>
      )}
    </div>
  );
};

export default SelectAll;
