import React, { useEffect } from 'react';
import i18next from 'i18next';
import { get } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchTechnique } from '../../../../redux/actions/dbEntities/technique';
import { getTechnique } from '../../../../redux/selectors/dbEntities';

const TechniqueSmallView = ({ id }) => {
  const dispatch = useDispatch();
  const lang = i18next.language;
  const technique = useSelector(store => getTechnique(store, { id, lang }), shallowEqual);

  useEffect(() => {
    if (!technique) {
      dispatch(fetchTechnique({ id }));
    }
  }, []);

  return <span>{get(technique, 'label')}</span>;
};

export default TechniqueSmallView;
