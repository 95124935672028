import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getLists, fetchLists, deleteLists, getListsOrderedIds, getListsLoading } from '../../../../redux/slices/account/lotslists';
import { queryStringToObject } from '../../../../utils/http';
import Sort from '../Sort';
import './stylesheet.scss';
import '../stylesheet-common.scss';
import Item from './Item';
import Spinner from '../../../common/spinners/Spinner';
import LandingPage from '../LandingPage';
import A18n from '../../../common/A18n';
import { GRANTED, useLotslistsAccess } from '../../../../hooks/lotslists/useLotslistsAccess';
import Checkbox from '../../../common/ui/Checkbox';

const sortOptions = {
  'label-asc': 'Titre asc',
  'label-desc': 'Titre desc',
  'created_at-asc': 'Date asc',
  'created_at-desc': 'Date desc',
};

const List = () => {
  const dispatch = useDispatch();
  const lists = useSelector(getLists);
  const loading = useSelector(getListsLoading);
  const listsOrderedIds = useSelector(getListsOrderedIds);
  const queryStringParams = queryStringToObject();
  const [sort, setSort] = useState(queryStringParams.sort || 'created_at-asc');
  const [selectedIds, setSelectedIds] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const accessType = useLotslistsAccess();

  useEffect(() => {
    dispatch(fetchLists({ sort })).then(() => setLoaded(true));
  }, [sort]);

  const handleSelectList = ({ id, checked }) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    }
  };

  const handleSelectAllList = ({ checked }) => {
    if (checked) {
      setSelectedIds(Object.keys(lists).map(id => parseInt(id, 10)));
    } else {
      setSelectedIds([]);
    }
  };

  if (!loaded || loading) {
    return <Spinner />;
  }
  if (accessType !== GRANTED || isEmpty(lists)) {
    return <LandingPage />;
  }

  return (
    <div className="account-lots-lists-list container">
      <h1>
        <A18n rsx="components.lotslists.title" />
      </h1>
      <div className="actions">
        <div className="select-all">
          <Checkbox checked={selectedIds.length === Object.values(lists).length} onChange={checked => handleSelectAllList({ checked })} />
        </div>
        <button className="lots-lists-button" type="button" onClick={() => dispatch(deleteLists({ ids: selectedIds }))} disabled={selectedIds.length === 0}>
          <i className="fa fa-trash-o" /> <A18n rsx="components.lotslists.delete.m1" />
        </button>
        <div style={{ flex: 1 }} />
        <Sort sort={sort} onClick={key => setSort(key)} options={sortOptions} />
      </div>

      {listsOrderedIds.map(
        id =>
          lists[id] && (
            <div key={`list-${id}`} className="lots-list">
              <div className="lots-list-select">
                <Checkbox checked={selectedIds.includes(id)} onChange={checked => handleSelectList({ id, checked })} />
              </div>
              <Item list={lists[id]} />
            </div>
          ),
      )}
    </div>
  );
};

export default List;
