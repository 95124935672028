/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.list.theme.details.data.m1',
  'subscriptions.purchase.list.theme.details.data.m2',
  'subscriptions.purchase.list.theme.details.data.m3',
  'subscriptions.purchase.list.theme.details.data.m4',
  'subscriptions.purchase.list.theme.details.data.m5',
  'subscriptions.purchase.list.theme.details.data.m6',
  'subscriptions.purchase.list.theme.details.data.m7',
  'subscriptions.purchase.list.theme.details.data.m8',
];
