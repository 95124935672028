import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCurrentBillCurrency } from '../../../../../redux/slices/userContext/selectors';
import { getTheme } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { THEME_BASICS } from '../../../../../constants/rails';

export default function usePriceOneDay() {
  const intl = useIntl();
  const themeBasic = useSelector(state => getTheme(state, { idtheme: THEME_BASICS }));
  const currency = useSelector(getCurrentBillCurrency);
  const [priceOneDay, setPriceOneDay] = useState();

  useEffect(() => {
    if (themeBasic) {
      const physicproduct = themeBasic.physicproducts.filter(p => p.original_nbdays === 1)[0];

      if (physicproduct?.price[currency]) {
        setPriceOneDay(
          intl.formatNumber(physicproduct?.price[currency], {
            style: 'currency',
            currency,
          }),
        );
      } else {
        setPriceOneDay(null);
      }
    } else {
      setPriceOneDay(null);
    }
  }, [themeBasic]);

  return priceOneDay;
}
