import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from '../../services/contact';
import HighlightedTitle from '../common/HighlightedTitle';
import { useViewport } from '../../hooks/useViewport';
import A18n from '../common/A18n';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { getName } from '../../redux/slices/userContext/selectors';
import './stylesheet.scss';

const Faq = ({ faqData }) => {
  const { viewportWidth } = useViewport();
  const [faq, setFaq] = useState(faqData);
  const [dataloading, setDataloading] = useState(false);
  const name = useSelector(getName);

  useEffect(() => {
    if (!faqData) {
      setDataloading(true);
      get().then(data => {
        setFaq(data);
        setDataloading(false);
      });
    }
  }, []);

  return (
    <div className="contact">
      <HighlightedTitle title={<A18n rsx="artprice.contact.m103" />} tag="h1" />

      <p style={{ margin: '30px 0' }}>
        {name && <A18n rsx="artprice.phone.m8" replace={[['%name%', name]]} />}
        {!name && <A18n rsx="artprice.phone.m1" />}
      </p>

      {viewportWidth < 768 && <Mobile faq={faq} dataloading={dataloading} />}
      {viewportWidth >= 768 && <Desktop faq={faq} dataloading={dataloading} />}
    </div>
  );
};

export default Faq;
