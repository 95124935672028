import React, { useEffect, useState } from 'react';
import useSquareColumnsCount from './useSquareColumnsCount';

export default function useSquareHeight({ data, loading }) {
  const columnCount = useSquareColumnsCount();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const blockHeight = 250;
    const headerHeight = 125;

    if (loading) {
      setHeight(blockHeight + headerHeight);
    } else if (data) {
      if (data.length <= 0) {
        setHeight(headerHeight);
      } else {
        const lineCount = Math.ceil(data.length / columnCount);
        setHeight(lineCount * blockHeight + 25 * (lineCount - 1) + headerHeight);
      }
    }
  }, [data?.length, loading, columnCount]);

  return height;
}
