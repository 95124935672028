import i18next from 'i18next';
import ReactSelect from 'react-select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getIdartist } from '../../../../redux/selectors/indexes/artist';
import { fetchCountries } from '../../../../redux/actions/indexes/artist';
import './stylesheet.scss';

const CountriesOptions = ({ onChange, parentChart }) => {
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const countries = useSelector(getCountries);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [idartist]);

  const handleChange = country => {
    setValue(country);
    onChange({ idcountry: country ? country.id : undefined });
  };

  return (
    <ReactSelect
      id={`option-chart-countries-${parentChart}`}
      name="idcountry"
      isClearable
      value={value}
      classNamePrefix="react-select"
      className="indexes-countries-select"
      options={countries}
      placeholder={i18next.t('indexes._advanced.m7')}
      onChange={handleChange}
    />
  );
};

export default CountriesOptions;
