import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const SaletypeSelect = () => (
  <Item label={i18next.t('components.search.helpers.items.sale_type.m1')} name="sale_idsaletype">
    <Select facet="sale_idsaletype" placeholder={i18next.t('components.search.helpers.items.sale_type.m2')} />
  </Item>
);

export default SaletypeSelect;
