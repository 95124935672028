/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.m3',
  'subscriptions.list.m4',
  'subscriptions.list.m5',
  'subscriptions.list.m6',
  'subscriptions.list.m7',
  'subscriptions.list.m8',
  ...require('./Cards/i18n'),
  ...require('./common/i18n'),
  ...require('./Faq/i18n'),
  ...require('./Identification/i18n'),
  ...require('./Promotion/i18n'),
  ...require('./Table/i18n'),
  ...require('./Header/i18n'),
  ...require('./warnings/i18n'),
  ...require('./Contact/i18n'),
];
