import { useParams } from 'react-router';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set } from '../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU, MENU_CONTACT } from '../../../constants/auctioneers';
import { getEmail, getName } from '../../../redux/slices/userContext/selectors';
import { send } from '../../../redux/actions/auctioneers/contact';
import { isSending, getSendingStatus } from '../../../redux/selectors/auctioneers/contact';
import Subjects from './Subjects';
import MessageSentModale from './MessageSent';
import { ANALYTICS_FROM_AUCTIONEERS_CONTACT } from '../../../constants/rails';

const Contact = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const email = useSelector(getEmail);
  const name = useSelector(getName);
  const { idsale } = useParams();
  const sending = useSelector(isSending);
  const { sent = false } = useSelector(getSendingStatus);

  useEffect(() => {
    dispatch(set(ACTIVE_MENU, MENU_CONTACT));
    dispatch(set(ACTIVE_AAPI_BEACON_FFROM, ANALYTICS_FROM_AUCTIONEERS_CONTACT));
  }, []);

  const onSubmit = message => {
    dispatch(send(message));
  };

  return (
    <div className="auctioneers-past-sales container">
      <MessageSentModale open={sent} />
      <div className="row">
        <div className="col-xs-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register('idsale')} defaultValue={idsale} />
            <div className="form-group">
              <label htmlFor="name">{i18next.t('auctioneers.contact.m1')}</label>
              <input className="form-control" {...register('name', { required: true })} defaultValue={name} />
              {errors.name && 'Name is required'}
            </div>
            <div className="form-group">
              <label htmlFor="id">{i18next.t('auctioneers.contact.m2')}</label>
              <input className="form-control" {...register('email', { required: true })} defaultValue={email} />
              {errors.email && 'Email is required'}
            </div>
            <div className="form-group">
              <label htmlFor="subject">{i18next.t('auctioneers.contact.m3')}</label>
              <Subjects idsale={idsale} />
            </div>
            <div className="form-group">
              <label htmlFor="id">{i18next.t('auctioneers.contact.m4')}</label>
              <textarea className="form-control" rows={15} {...register('body', { required: true })} />
              {errors.body && 'Body is required'}
            </div>
            <div className="form-group">
              <button className="form-control btn btn-primary" type="submit" disabled={sending}>
                {!sending && i18next.t('auctioneers.contact.m5')}
                {sending && <i className="fa fa-spinner fa-spin" />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
