import { forEach } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { Navigate } from 'react-router-dom';
import { Col, FormGroup } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { PROFESSIONAL_TYPE_ID_PRIVATE, PROFESSIONAL_TYPE_ID_PROFESSIONAL, CUSTOMER_VERIFICATION_STATUS_INPROGRESS, CUSTOMER_VERIFICATION_STATUS_VALID } from '../../../../constants/rails';
import { getIdprofessionaltype, getCustomerVerificationStatus } from '../../../../redux/slices/userContext/selectors';
import { ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS } from '../../../../constants/account/confirm';
import { update as updateUserContext } from '../../../../redux/slices/userContext';
import FormPrivateValidationSchema from './FormPrivate/ValidationSchema';
import FormProValidationSchema from './FormPro/ValidationSchema';
import FormPrivate from './FormPrivate';
import FormPro from './FormPro';
import { internalAxios } from '../../../../utils/axios/internal';

import './stylesheet.scss';

const formatPhoneNumber = phone => {
  if (i18next.language !== 'fr') return phone;
  return phone?.replace(/^(0)(\d[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2})/, '+33 $2');
};

const formDataFromValues = values => {
  const formData = new FormData();
  forEach(values, (value, name) => {
    if (!['idDocument', 'firmDocument', 'idDocument2', 'firmDocument2'].includes(name)) {
      formData.append(name, value);
    }
  });

  if (values.idDocument) formData.append('idDocument', values.idDocument[0]);
  if (values.idDocument2) formData.append('idDocument2', values.idDocument2[0]);
  if (values.firmDocument) formData.append('firmDocument', values.firmDocument[0]);
  if (values.firmDocument2) formData.append('firmDocument2', values.firmDocument2[0]);
  if (values.birthDate) formData.set('birthDate', format(values.birthDate, 'yyyy-MM-dd'));

  return formData;
};

function onFormError(e) {
  Sentry.captureMessage('[form_confirm_identity] Validation failed', {
    extra: {
      errors: e,
    },
  });
}

const ConfirmIdentity = ({ initValues }) => {
  const dispatch = useDispatch();
  const [isASuccess, setIsASuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const idprofessionaltype = useSelector(getIdprofessionaltype);
  const customerVerificationStatus = useSelector(getCustomerVerificationStatus);
  const formIsReadOnly = customerVerificationStatus === CUSTOMER_VERIFICATION_STATUS_INPROGRESS || customerVerificationStatus === CUSTOMER_VERIFICATION_STATUS_VALID;

  const defaultValues = {
    title: initValues.title,
    firstName: initValues.firstName || '',
    lastName: initValues.lastName || '',
    birthDate: initValues.birthDate ? parseISO(initValues.birthDate) : null,
    line1: initValues.line1 || '',
    line2: initValues.line2 || '',
    line3: initValues.line3 || '',
    zipCode: initValues.zipCode || '',
    city: initValues.city || '',
    state: initValues.state || '',
    country: initValues.country,
    phone: formatPhoneNumber(initValues.phone) || '',
    company: initValues.company || '',
    siret: initValues.siret || '',
    vatin: initValues.vatin || '',
    vatinInitialString: initValues.vatinInitialString || '',
    vatinRegex: initValues.vatinRegex || undefined,
    firmDocument: '',
    firmDocumenttype: initValues.firmDocumenttype || null,
    firmDocumentUrl: initValues.firmDocumentUrl,
    firmDocument2: '',
    firmDocumentUrl2: initValues.firmDocumentUrl2,
    idDocument: '',
    idDocumentUrl: initValues.idDocumentUrl,
    idDocument2: '',
    idDocumentUrl2: initValues.idDocumentUrl2,
    idDocumenttype: initValues.idDocumenttype || null,
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: async (data, context, options) => {
      if (formIsReadOnly) return undefined;
      if (idprofessionaltype === PROFESSIONAL_TYPE_ID_PROFESSIONAL) return yupResolver(FormProValidationSchema)(data, context, options);
      return yupResolver(FormPrivateValidationSchema)(data, context, options);
    },
  });

  const onSubmit = values => {
    setIsSubmitting(true);
    const formData = formDataFromValues(values);

    internalAxios
      .post('/api/account/customer_verification/update_or_create', formData)
      .then(() => {
        dispatch(updateUserContext({ customerVerificationStatus: ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS }));
        setIsASuccess(true);
      })
      .catch(error => {
        setError('error', error);
        console.error(error);
      })
      .then(() => setIsSubmitting(false));
  };

  const handleResourceDelete = ({ idDocument }) => {
    if (idDocument) {
      const formData = new FormData();
      formData.append('id', idDocument);
      internalAxios.post('/api/account/customer_verification/delete_documentresource', formData);
    }
  };

  if (isASuccess) {
    return <Navigate to="/account/confirm" state={{ verifySuccess: true }} />;
  }

  return (
    <div className="account-verify-identity">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{i18next.t('components.account.verify.identity.personal.m1')}</h1>

            <form onSubmit={handleSubmit(onSubmit, onFormError)}>
              {idprofessionaltype === PROFESSIONAL_TYPE_ID_PRIVATE && (
                <FormPrivate initValues={initValues} readOnly={formIsReadOnly} control={control} errors={errors} register={register} handleResourceDelete={handleResourceDelete} setValue={setValue} watch={watch} />
              )}
              {idprofessionaltype === PROFESSIONAL_TYPE_ID_PROFESSIONAL && (
                <FormPro initValues={initValues} readOnly={formIsReadOnly} control={control} errors={errors} register={register} handleResourceDelete={handleResourceDelete} setValue={setValue} watch={watch} />
              )}
              {!formIsReadOnly && (
                <FormGroup row>
                  <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                    <button className="pull-right btn btn-primary" type="submit">
                      {isSubmitting && (
                        <>
                          <i className="fa fa-spin fa-spinner" style={{ marginRight: 5 }} />
                          {i18next.t('components.account.verify.identity.m9')}
                        </>
                      )}
                      {!isSubmitting && i18next.t('components.account.verify.identity.personal.m13')}
                    </button>
                  </Col>
                </FormGroup>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmIdentity;
