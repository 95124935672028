/* eslint-disable max-len */
import React from 'react';

const SvgIcon = ({ color = 'black' }) => (
  <svg id="Icon-copier" xmlns="http://www.w3.org/2000/svg" width="16.15" height="16.15" viewBox="0 0 16.15 16.15" className="copy-button-svg-icon">
    <path
      id="copier"
      fill={color}
      d="M268.8,36.019h-3.7v-.673A1.346,1.346,0,0,0,263.757,34H257.6a.673.673,0,0,0-.476.2l-2.93,2.93a.673.673,0,0,0-.2.476v9.183a1.346,1.346,0,0,0,1.346,1.346h3.7V48.8a1.346,1.346,0,0,0,1.346,1.346H268.8A1.346,1.346,0,0,0,270.15,48.8V37.365A1.346,1.346,0,0,0,268.8,36.019Zm-9.56,3.127a.673.673,0,0,0-.2.476v7.5h-3.7a.337.337,0,0,1-.336-.336V38.038h2.019a1.009,1.009,0,0,0,1.009-1.009V35.009h5.72a.337.337,0,0,1,.336.336v.673h-1.444a.673.673,0,0,0-.476.2Zm9.9,9.659a.337.337,0,0,1-.336.336h-8.411a.337.337,0,0,1-.336-.336V40.056h2.019a1.009,1.009,0,0,0,1.009-1.009V37.028h5.72a.337.337,0,0,1,.336.336Z"
      transform="translate(-254 -34)"
    />
  </svg>
);

export default SvgIcon;
