import React from 'react';
import Inquiries from './Inquiries';
import Activity from './Activity';

const YourStats = () => (
  <div className="row">
    <div className="col-xs-12">
      <Activity />
    </div>
    <div className="col-xs-12 marg marg-t-50">
      <Inquiries />
    </div>
  </div>
);

export default YourStats;
