import React, { useEffect, useState } from 'react';
import Consignment from './Consignment';
import Records from './Records';
import Agenda from './Agenda';
import Header from './Header';
import Sales from './Sales';
import './stylesheet.scss';
import Lots from './Lots';

const Landingpage = ({ data }) => {
  const { ails } = data;

  const [sales, setSales] = useState();
  const [salelots, setSalelots] = useState([]);
  const [lots, setLots] = useState();
  const [records, setRecords] = useState();
  const [consignment, setConsignment] = useState();

  useEffect(() => {
    if (ails) {
      setSales(ails[22]);
      setLots(ails[21]);
      setSalelots(ails[24]);
      setRecords(ails[23]);
      setConsignment(ails[25]?.[0]);
    }
  }, [ails]);

  return (
    <div className="ails-landingpage">
      <div className="container">
        <Header data={data} />
        <Sales sales={sales} salelots={salelots} />
        <Lots lots={lots} />
      </div>
      <Agenda data={data} />
      <div className="container">
        <Records records={records} />
        <Consignment consignment={consignment} />
      </div>
    </div>
  );
};

export default Landingpage;
