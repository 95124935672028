import React from 'react';
import { useSelector } from 'react-redux';
import { getAutomaticallyApplied, getBannerUrlSml, getCode } from '../../../../../redux/slices/promotion';
import { IMAGE_SOURCE, MODULE_INDEXES } from '../../../../../constants/rails';
import { getFree } from '../../../../../redux/slices/artist';
import '../../../../common/stylesheets/buttons.scss';
import CommonDrawer from '../../../../common/Drawer';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Drawer = ({ artist, id }) => {
  const freeArtist = useSelector(getFree);
  const promocodecode = useSelector(getCode);
  const artpriceBanners = useSelector(getBannerUrlSml);
  const automaticallyApplied = useSelector(getAutomaticallyApplied);

  return (
    <CommonDrawer
      id={id}
      side="right"
      className="artists-artist-indexes-drawer"
      render={() => (
        <>
          <p>
            <img alt="drawer-ca" style={{ width: 119 }} src={`${IMAGE_SOURCE}artist/indexes.svg`} />
          </p>
          <h3>
            <A18n rsx="artists.artist.indexes.m12" replace={[['%artist%', artist.name]]} trustHtml />
          </h3>
          <p className="args">
            {artist.index && <A18n rsx="artists.artist.indexes.m11" trustHtml replace={[['%artist%', artist.name]]} />}
            {!artist.index && <A18n rsx="artists.artist.indexes.m16" trustHtml replace={[['%artist%', artist.name]]} />}
          </p>
          <p>
            <A18n
              rsx="artists.artist.lots.noaccessdrawer.m12"
              trustHtml
              replace={[
                ['%a%', `<a style="text-decoration: underline" href="${freeArtist?.url}">`],
                ['%/a%', '</a>'],
              ]}
            />
          </p>
          {artpriceBanners && (
            <p>
              {/* eslint-disable-next-line react/no-danger */}
              <a href={`/subscription?idmodule=${MODULE_INDEXES}&promocode=${promocodecode}`}>
                <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
              </a>
            </p>
          )}

          <p className="links">
            <a className="artp-btn artp-btn-purple sln-link" href={`/subscription?idmodule=${MODULE_INDEXES}${promocodecode && !automaticallyApplied ? `&promocode=${promocodecode}` : ''}`}>
              <A18n rsx="artists.artist.indexes.m13" />
            </a>
            <a className="artp-btn" href="/identity/indexes" id="sln_login_lots">
              <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
            </a>
          </p>
        </>
      )}
    />
  );
};

export default Drawer;
