import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateChronologicalDisplayedChartTab, updateChronologicalOptions, fetchChronologicalData } from '../../../redux/actions/indexes/chronological';
import { TAB_CA, TAB_AVERAGE, TAB_SOLD, TAB_UNSOLD } from '../../../constants/indexes/chronological';
import { getChronologicalDisplayedChartTab } from '../../../redux/selectors/indexes/chronological';
import { getIdartist } from '../../../redux/selectors/indexes/artist';
import { getCurrency } from '../../../redux/selectors/preferences';
import SubscriptionNeeded from '../SubscriptionNeeded';
import CategoriesOptions from '../common/Categories';
import CountriesOptions from '../common/Countries';
import MinPriceOption from '../common/MinPrice';
import MaxPriceOption from '../common/MaxPrice';
import BarChart from './BarChart';
import A18n from '../../common/A18n';
import Filters from '../common/Filters';
import Copyright from '../common/Copyright';
import LineChart from './LineChart';

import './stylesheet.scss';

const ChronologicalChart = () => {
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);
  const chartTab = useSelector(getChronologicalDisplayedChartTab);

  const updateDisplayedChartTab = newChartTab => {
    dispatch(updateChronologicalDisplayedChartTab({ chartTab: newChartTab }));
    dispatch(fetchChronologicalData());
  };

  const updateOptions = newOptions => {
    dispatch(updateChronologicalOptions({ options: newOptions }));
    dispatch(fetchChronologicalData());
  };

  useEffect(() => {
    updateDisplayedChartTab(TAB_CA);
  }, []);

  useEffect(() => {
    dispatch(fetchChronologicalData());
  }, [idartist, currency]);

  return (
    <>
      <div className="chronological-chart" id="chrono">
        <h2>
          <A18n rsx="indexes.summary.m2" />
        </h2>

        <ul className="nav nav-pills">
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_CA)} className={chartTab === TAB_CA ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._first_chart.m3')}</a>
          </li>
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_SOLD)} className={chartTab === TAB_SOLD ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._first_chart.m4')}</a>
          </li>
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_UNSOLD)} className={chartTab === TAB_UNSOLD ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._first_chart.m5')}</a>
          </li>
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_AVERAGE)} className={chartTab === TAB_AVERAGE ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._first_chart.m8')}</a>
          </li>
        </ul>

        <div style={{ position: 'relative' }}>
          <SubscriptionNeeded section={i18next.t('indexes._first_chart.m7')} />

          <div className="tab-content">
            {chartTab === TAB_AVERAGE && <LineChart />}
            {chartTab !== TAB_AVERAGE && <BarChart />}
            <Filters>
              <div className="filter">
                <CategoriesOptions parentChart="chrono" onChange={updateOptions} />
              </div>
              <div className="filter">
                <CountriesOptions parentChart="chrono" onChange={updateOptions} />
              </div>
              <div className="filter">
                <MinPriceOption parentChart="chrono" disabled={chartTab === TAB_AVERAGE || chartTab === TAB_UNSOLD} onBlur={updateOptions} />
              </div>
              <div className="filter">
                <MaxPriceOption parentChart="chrono" disabled={chartTab === TAB_AVERAGE || chartTab === TAB_UNSOLD} onBlur={updateOptions} />
              </div>
            </Filters>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default ChronologicalChart;
