import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getFavoriteCount, getIdcustomer, getNewsletter, getPushEmail } from '../../../redux/slices/userContext/selectors';
import { getFocusOnAils } from '../../../redux/slices/homepage';
import AilsFocusOn from '../ails/FocusOn';
import Newsletter from './Newsletter';
import AlertEmail from './AlertEmail';
import Favorites from './Favorites';
import Estimate from './Estimate';
import Sell from './Sell';

import './stylesheetAutoPromo.scss';
import './stylesheet.scss';

const FocusOnOrAutoPromo = () => {
  const ail = useSelector(getFocusOnAils);
  const pushEmail = useSelector(getPushEmail);
  const favoriteCount = useSelector(getFavoriteCount);
  const newsletter = useSelector(getNewsletter);
  const idcustomer = useSelector(getIdcustomer);
  const [components, setComponents] = useState([]);

  useEffect(() => {
    const buff = [Estimate, Sell];

    let index = 0;

    if (favoriteCount === 0) {
      buff[index] = Favorites;
      index += 1;
    } else if (!idcustomer || !pushEmail) {
      buff[index] = AlertEmail;
      index += 1;
    }

    if (!newsletter || !idcustomer) buff[index] = Newsletter;

    if (ail) buff[1] = AilsFocusOn;

    setComponents(buff);
  }, []);

  return (
    <div className="container homepage-focuson-or-auto-promo block">
      <div className="two-cols">
        {components.map(Cmp => (
          <Cmp key={Math.random()} />
        ))}
      </div>
    </div>
  );
};
export default FocusOnOrAutoPromo;
