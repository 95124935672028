import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getIsAuctioneer, getIsNonPartnerAuctioneer } from '../../../../redux/slices/userContext/selectors';
import { getThemes } from '../../../../redux/selectors/subscriptions/physicproducts';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../common/A18n';

const Promotion = ({ position }) => {
  const data = useSelector(getThemes);
  const { promocode, view, hasLoyaltyDiscount, hasOnedayRefund, filter } = useSelector(getSettings);
  const [promotion, setPromotion] = useState({});
  const [displayBanner, setDisplayBanner] = useState(true);
  const isAuctioneer = useSelector(getIsAuctioneer);
  const isNonPartnerAuctioneer = useSelector(getIsNonPartnerAuctioneer);
  const [applyLoyaltyDiscount, setApplyLoyaltyDiscount] = useState(false);

  useEffect(() => {
    setApplyLoyaltyDiscount(view !== 'upgrade' && hasLoyaltyDiscount);
  }, [view, hasLoyaltyDiscount]);

  const scrollToDetails = () => {
    const link = document.getElementById('promotion-details');
    if (link) {
      window.scrollTo({
        top: link.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (data) {
      let innerPromotion = {};

      if (data[view]?.automatically_applied_promotion?.status === 1) innerPromotion = data[view].automatically_applied_promotion;
      if (data[view]?.promotion_from_promocode?.status === 1) innerPromotion = data[view].promotion_from_promocode;
      if (innerPromotion?.url_image_1100) setDisplayBanner(false);

      setPromotion(innerPromotion);
    }
  }, [data]);

  if (position === 'top')
    return (
      <div className={classNames({ 'with-banner': displayBanner, 'subscriptions-list-promotion': true })} onClick={scrollToDetails}>
        {displayBanner && <div className={classNames(!isAuctioneer && !isNonPartnerAuctioneer ? { 'banner-data': filter === 'subscription', 'banner-store': filter === 'store' } : { 'banner-auctioneer': true })} />}
        {isEmpty(promotion) && hasOnedayRefund && (
          <div className="applied">
            <A18n rsx="subscriptions.list.promotion.m6" />
          </div>
        )}

        {isEmpty(promotion) && applyLoyaltyDiscount && (
          <div className="applied">
            <A18n rsx="subscriptions.list.promotion.m1" />
          </div>
        )}

        {!isEmpty(promotion) && (
          <>
            {promotion.url_image_1100 && (
              <div className="img">
                <img src={promotion.url_image_1100} alt="promotion" />
              </div>
            )}
            <div className="applied">
              {!applyLoyaltyDiscount && (
                <>
                  {promocode && <A18n rsx="subscriptions.list.promotion.m2" replace={[['%promocode%', promocode]]} trustHtml />}
                  {!promocode && <A18n rsx="subscriptions.list.promotion.m3" />}
                </>
              )}
              {applyLoyaltyDiscount && (
                <>
                  {promocode && <A18n rsx="subscriptions.list.promotion.m4" replace={[['%promocode%', promocode]]} trustHtml />}
                  {!promocode && <A18n rsx="subscriptions.list.promotion.m5" />}
                </>
              )}
            </div>
          </>
        )}
      </div>
    );

  if (isEmpty(promotion) && !hasOnedayRefund) return null;

  return (
    <div className="subscriptions-list-promotion-description" id="promotion-details">
      <div className="container">
        {hasOnedayRefund && (
          <div>
            <p>
              <A18n rsx="subscriptions.list.promotion.m6" />
            </p>
            <p>
              <A18n rsx="subscriptions.list.promotion.m7" />
            </p>
          </div>
        )}
        {!isEmpty(promotion) && <div dangerouslySetInnerHTML={{ __html: promotion.description }} />}
      </div>
    </div>
  );
};

export default Promotion;
