import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_FROM_HOMEPAGE } from '../../../../constants/rails';
import { getFocusOnAils } from '../../../../redux/slices/homepage';

const FocusOn = () => {
  const ail = useSelector(getFocusOnAils);

  if (!ail) return null;

  return (
    <div className="homepage-ails-focus-on radius block">
      <a href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_HOMEPAGE}`} key={ail.id}>
        <img alt={ail.title} src={ail.image} />
      </a>
    </div>
  );
};

export default FocusOn;
