import React from 'react';
import Arrow from '../../Arrow';
import A18n from '../../A18n';
import './stylesheet.scss';

const Pagination = ({ current, total, onChange }) => (
  <div className="ModalTour-Navigation">
    {current !== 0 && (
      <div className="ModalTour-Navigation-btn btn-prev" onClick={() => onChange(current - 1)}>
        <span className="arrow">←</span> <A18n rsx="common.modaltour.navigation.m1" />
      </div>
    )}

    {current !== total - 1 && (
      <div className="ModalTour-Navigation-btn btn-next" onClick={() => onChange(current + 1)}>
        <A18n rsx="common.modaltour.navigation.m2" /> <Arrow />
      </div>
    )}
  </div>
);

export default Pagination;
