import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import A18n from '../../../common/A18n';
import Header from './Header';
import './stylesheet.scss';

const Summary = ({ data, baseUrl, slug }) => (
  <div className="report-summary reports-report-summary">
    <div className="title">
      <A18n rsx="artmarketinsight.report.m1" />
      <div className="separator" />
    </div>
    <div className="items">
      {data.map((section, sectionIndex) => (
        <div className="sections" key={section.title || uuidv4()}>
          {section.title && <Header index={sectionIndex} title={section.title} backgroundColor={section.backgroundColor} color={section.color} />}
          {section.items.map(item => (
            <div className="section" key={item.slug + item.title}>
              {slug === item.slug && (
                <span style={{ color: section.backgroundColor }}>
                  <i className="fa fa-caret-right" /> {item.title}
                </span>
              )}
              {slug !== item.slug && <a href={`${baseUrl}${item.slug}`}>{item.title}</a>}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default Summary;
