import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { areThemesLoading } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getActiveSubscription } from '../../../../../redux/slices/userContext/selectors';
import { ANALYTICS_CLICK_OPERATION_MORE_INFORMATIONS, ANALYTICS_CLICK_OPERATION_TOGGLE_IMAGES, THEME_STORE } from '../../../../../constants/rails';
import { isCancelled } from '../../../../../utils/subscriptions/attentionNeeded';
import SubscribeButton from '../../common/SubscribeButton';
import { NEW, UPGRADE } from '../../common/constants';
import Physicproduct from './Physicproduct';
import Subscription from './Subscription';
import Details from './Details';
import './stylesheet.scss';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const Theme = ({ theme, cardCount }) => {
  const dispatch = useDispatch();
  const { image, returningSubscriber, view, selectedIdtheme, from } = useSelector(getSettings);
  const { recurrent, attentionNeeded, hasImageAccess } = useSelector(getActiveSubscription) || {};
  const { idtheme: activeSubscriptionIdtheme } = useSelector(getActiveSubscription) || {};
  const loading = useSelector(areThemesLoading);
  const activeTheme = theme.active && (!recurrent || isCancelled(attentionNeeded));
  const className = ['theme'];

  let label = i18next.t('subscriptions.purchase.list.theme.m11');
  if (view === UPGRADE) {
    label = i18next.t('subscriptions.purchase.list.theme.m12');
  } else if (returningSubscriber) {
    label = i18next.t('subscriptions.purchase.list.theme.m13');
  }

  if (activeTheme) className.push('active');
  if (theme.idtheme === activeSubscriptionIdtheme && (!selectedIdtheme || selectedIdtheme === activeSubscriptionIdtheme)) className.push(`lvl-${theme.idtheme}`);
  if (theme.idtheme === selectedIdtheme) className.push(`lvl-${theme.idtheme}`);

  return (
    <div className={`subscriptions-purchase-list-theme count-${cardCount}`}>
      <div className={className.join(' ')}>
        <Subscription idtheme={theme.idtheme} cardCount={cardCount} />

        <div className={`block-1 ${activeTheme ? 'active' : undefined}`}>
          <h2 className={`lvl-${theme.idtheme}`}>{theme.theme}</h2>
          <div className="arguments">
            <Details theme={theme} />
          </div>
          <div className="filler" />
          {theme.active && (
            <div
              className="details"
              onClick={() => {
                aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_MORE_INFORMATIONS, section: theme.theme });
                dispatch(
                  updateSettings({
                    data: {
                      displayThemeDetailsModaleIdtheme: theme.idtheme,
                      displayThemeDetailsModale: true,
                    },
                  }),
                );
              }}
            >
              <span className="badge">
                <i className="fa fa-caret-right" />
              </span>
              <span className="text"> {i18next.t('subscriptions.purchase.list.theme.m8')}</span>
            </div>
          )}
        </div>
        {theme.active && (
          <div className="block-2">
            <div className="physicproducts">
              {theme.physicproducts.map(physicproduct => (
                <Physicproduct theme={theme} key={physicproduct.reference} physicproduct={physicproduct} width={`calc(100% / ${theme.physicproducts.length}`} />
              ))}
            </div>
            <SubscribeButton theme={theme} label={label} />
          </div>
        )}
      </div>
      {theme.idtheme !== THEME_STORE && (view === NEW || !hasImageAccess) && (
        <div
          className="images-option"
          onClick={() => {
            aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TOGGLE_IMAGES, section: !image });
            dispatch(updateSettings({ data: { image: !image } }));
          }}
        >
          {loading && <span>&nbsp;</span>}
          {!loading && activeTheme && (
            <>
              {image && <span> {i18next.t('subscriptions.purchase.list.theme.m9')}</span>}
              {!image && <span> {i18next.t('subscriptions.purchase.list.theme.m10')}</span>}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Theme;
