import React, { useState } from 'react';
import i18next from 'i18next';
import OneDayRefoundInfo from '../../../../common/OneDayRefoundInfo';
import './stylesheet.scss';

const OneDay = ({ selected }) => {
  const [displayAnnotate, setDisplayAnnotate] = useState(false);

  return (
    <div className="subscriptions-list-theme-physicproduct-oneday">
      <OneDayRefoundInfo visible={displayAnnotate} close={() => setDisplayAnnotate(false)} />
      <p>
        {selected && i18next.t('subscriptions.purchase.list.theme.physicproduct.oneday.m1')}
        {!selected && i18next.t('subscriptions.purchase.list.theme.physicproduct.oneday.m2')}
      </p>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <p className="annotate" onClick={() => setDisplayAnnotate(true)}>
        {i18next.t('subscriptions.purchase.list.theme.physicproduct.oneday.m3')} <i className="fa text-primary fa-question-circle" />
      </p>
    </div>
  );
};

export default OneDay;
