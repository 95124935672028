import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from '../../../common/LoginOrRegistrationModal/constants';
import { updateSettings } from '../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import LoginOrRegistrationModal from '../../../common/LoginOrRegistrationModal';
import { getUserContext } from '../../../../redux/selectors/userContext';
import { set } from '../../../../redux/actions/ui/dictionary';
import { resetSession } from '../../../../services/artprice';
import useHasWarnings from '../common/useHasWarnings';
import A18n from '../../../common/A18n';

const Identification = () => {
  const { image, quantity, view, promocode, selectedIdphysicproduct, theme } = useSelector(getSettings);
  const dispatch = useDispatch();
  const hasWarning = useHasWarnings({ theme, quantity });
  const { loaded, idcustomer } = useSelector(getUserContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (loaded && idcustomer && logged) {
      dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, false));

      if (hasWarning()) {
        dispatch(updateSettings({ data: { displayWarningsModale: true } }));
      } else {
        window.location = `/payments/informations?image=${image}&idphysicproducts[${selectedIdphysicproduct}]=${quantity}&type=${view}&promocode=${promocode}`;
      }
    }
  }, [loaded, idcustomer, logged]);

  const handleUserNotExists = async initialEmail => {
    if (idcustomer) {
      await resetSession();
    }

    window.location = `/payments/informations?image=${image}&idphysicproducts[${selectedIdphysicproduct}]=${quantity}&type=${view}&promocode=${promocode}${initialEmail ? `&initialEmail=${initialEmail}` : ''}`;
  };

  return (
    <LoginOrRegistrationModal
      onLogin={() => setLogged(true)}
      onUserNotExists={handleUserNotExists}
      titleLogin={<A18n rsx="subscriptions.list.identification.m1" />}
      titleCheckUser={<A18n rsx="subscriptions.list.identification.m2" />}
    />
  );
};

export default Identification;
