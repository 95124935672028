import React from 'react';
import HighlightedTitle from '../../../common/HighlightedTitle';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Header = () => (
  <div className="protection">
    <HighlightedTitle title={<A18n rsx="marketplace.howtobid.protection.m1" />} tag="h2" />
    <div className="steps">
      <div className="step step1">
        <p>
          <A18n rsx="marketplace.howtobid.protection.m2" />
        </p>
        <p>
          <A18n rsx="marketplace.howtobid.protection.m3" />
        </p>
      </div>
      <div className="step step2">
        <p>
          <A18n rsx="marketplace.howtobid.protection.m4" />
        </p>
        <p>
          <A18n rsx="marketplace.howtobid.protection.m5" />
        </p>
      </div>
    </div>
  </div>
);

export default Header;
