import React from 'react';
import './stylesheet.scss';

const ErrorBlock = ({ error, style = {} }) => {
  if (!error) return null;

  return (
    <div className="payments-informations-form-error-block" style={style}>
      {error.message}
    </div>
  );
};

export default ErrorBlock;
