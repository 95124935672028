import React from 'react';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import './stylesheet.scss';

const Information = ({ children, condition }) => {
  if (condition) return children;

  return (
    <div className="indexes-summary-information">
      <img alt="lock" src={`${IMAGE_SOURCE}no-access/cadenas-no-padding.svg`} />
    </div>
  );
};

export default Information;
