import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { getCurrentInfiniteScroll, getCurrentSort } from '../../../../redux/selectors/search/info';
import { updateCurrentDraft, applyCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentDraftParams } from '../../../../redux/selectors/search';
import ValidableDropDown from '../DropDownToValidate';
import { cleanPathname, objectToQueryString } from '../../../../utils/http';
import './stylesheet.scss';
import { set as setUiDictionary } from '../../../../redux/actions/ui/dictionary';

const LotSort = ({ options, redirect = true, renderDropdown = true, className, right = false }) => {
  const dispatch = useDispatch();
  const currentSort = useSelector(getCurrentSort);
  const params = useSelector(getCurrentDraftParams);
  const infiniteScroll = useSelector(getCurrentInfiniteScroll);

  const validateParams = () => {
    if (redirect && !infiniteScroll) {
      window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...params, p: 1 })}`;
    } else {
      dispatch(applyCurrentDraft({ force: true }));
    }
  };

  const handleClick = value => {
    dispatch(setUiDictionary('returnToIdlot', null)); // prevent auto scroll to last selected lot
    dispatch(updateCurrentDraft({ triggeredParam: 'sort', value }));
  };

  if (renderDropdown) {
    return (
      <ValidableDropDown
        title={i18next.t('marketplace.searchbars.classifieds.bar.m6')}
        onSubmit={() => validateParams()}
        className={className}
        icon="sort-amount-asc"
        displayIconDesktop
        right={right}
        render={() => (
          <div className="search-common-sort">
            <div className="radios-list marg marg-b-15">
              {Object.entries(options).map(([key, label]) => (
                <label htmlFor={`sort-radio-${key}`} key={key} className="option">
                  <input id={`sort-radio-${key}`} onClick={() => handleClick(key)} defaultChecked={key === currentSort} className="marg marg-r-5" type="radio" name="search-sort-radios" value={key} />
                  {label}
                </label>
              ))}
            </div>
          </div>
        )}
      />
    );
  }
  return (
    <div className="facets">
      {Object.entries(options).map(([key, label]) => (
        <div role="presentation" className="facet" key={key}>
          <div className="check">
            <input
              id={`check-${key}`}
              onClick={() => {
                handleClick(key);
                validateParams();
              }}
              defaultChecked={key === currentSort}
              className="marg marg-r-5"
              type="radio"
              name="search-sort-radios"
              value={key}
            />
          </div>
          <label htmlFor={`check-${key}`} className="name">
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default LotSort;
