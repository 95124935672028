import React, { useEffect, useRef } from 'react';
import './stylesheet.scss';
import { createPortal } from 'react-dom';
import Spinner from '../spinners/Spinner';

const LoadingOverlay = ({ visible = true, querySelector, faSize, style }) => {
  const rootElemRef = useRef();

  useEffect(() => {
    rootElemRef.current = undefined;
  }, [querySelector]);

  function getRootElem() {
    if (!rootElemRef.current) {
      try {
        rootElemRef.current = document.querySelector(querySelector) || document.body;
      } catch (e) {
        rootElemRef.current = document.body;
      }
    }
    return rootElemRef.current;
  }

  if (!visible || typeof window === 'undefined') {
    return null;
  }

  return createPortal(
    <div className={`LoadingOverlay ${getRootElem() === document.body ? 'on-body' : ''}`.trim()} style={style}>
      <Spinner className="LoadingOverlay-Spinner" faSize={faSize} />
    </div>,
    getRootElem(),
  );
};

export default LoadingOverlay;
