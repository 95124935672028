import i18Next from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { renameList } from '../../../../../redux/slices/account/lotslists';
import Modal from '../../../../common/ui/Modal';
import '../../stylesheet-common.scss';
import './stylesheet.scss';

const EditButton = ({ list, ...otherProps }) => {
  const { id } = list;
  const dispatch = useDispatch();
  const [label, setLabel] = useState(list.label);
  const [visibility, setVisibility] = useState(false);

  const handleSubmit = async () => {
    dispatch(renameList({ id, label }));

    setVisibility(false);
  };

  return (
    <div className="account-lots-lists-show-rename-button" {...otherProps}>
      <Modal open={visibility} onClose={() => setVisibility(false)} className="account-lots-lists-create-button-modale">
        <p className="title">{i18Next.t('components.lotslists.show.m7')}</p>
        <input onChange={e => setLabel(e.target.value)} defaultValue={label} />
        <div className="action-buttons">
          <button type="button" className="lots-lists-button" onClick={() => setVisibility(false)}>
            {i18Next.t('components.myartprice.settings.artists.m3')}
          </button>
          <button type="button" className="lots-lists-button primary" onClick={() => handleSubmit()}>
            {i18Next.t('components.lotslists.show.m6')}
          </button>
        </div>
      </Modal>

      <button className="lots-lists-button-rename" type="button" onClick={() => setVisibility(true)} title={i18Next.t('components.lotslists.show.m6')}>
        <i className="fa fa-pencil" />
      </button>
    </div>
  );
};
export default EditButton;
