import React from 'react';
import A18n from '../../../../common/A18n';

const NoEmail = () => (
  <div className="section">
    <div className="section-title">
      <A18n rsx="account.emailvalidationneeded.contents.noemail.m1" />
    </div>
    <div className="section-content" style={{ padding: '0 15px' }}>
      <p>
        <A18n rsx="account.emailvalidationneeded.contents.noemail.m2" />
      </p>
      <p>
        <A18n rsx="account.emailvalidationneeded.contents.noemail.m3" />
      </p>
      <p>
        <A18n rsx="account.emailvalidationneeded.contents.noemail.m4" />
      </p>
    </div>
    <div className="section-content" style={{ padding: '30px 15px', textAlign: 'center' }}>
      <a href="/account" className="btn btn-primary">
        <A18n rsx="account.emailvalidationneeded.contents.noemail.m5" />
      </a>
    </div>
    <div className="section-content" style={{ padding: '0 15px' }}>
      <p>
        <A18n rsx="account.emailvalidationneeded.contents.noemail.m6" />{' '}
        <a href="/contact?active=block8&id=690">
          <A18n rsx="account.emailvalidationneeded.contents.noemail.m7" />
        </a>
      </p>
    </div>
  </div>
);

export default NoEmail;
