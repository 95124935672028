module.exports = [
  'indexes.summary.m1',
  'indexes.summary.m2',
  'indexes.summary.m3',
  'indexes.summary.m4',
  'indexes.summary.m5',
  'indexes.summary.m6',
  'indexes.summary.m7',
  'indexes.summary.m8',
  'indexes.summary.m9',
  'indexes.summary.m10',
  'indexes.summary.m11',
  'indexes.summary.m12',
  'indexes.summary.m13',
];
