import i18next from 'i18next';
import React from 'react';
import Item from '../../../Search/Item';
import Checkbox from '../../../inputs/Checkbox';

const Indicator = () => (
  <Item name="indicator">
    <Checkbox label={i18next.t('subscription.index.m27')} />
  </Item>
);

export default Indicator;
