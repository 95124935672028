/* eslint-disable global-require */

module.exports = [
  'indexes._advanced.m64',
  'components.search.helpers.items.artist_birth_year.m1',
  'components.search.helpers.items.artist_country.m1',
  'lots.search.filters.artists.m1',
  'lots.header_search.m17',
  'components.total_access.m1',
  'components.total_access.m2',
  'components.total_access.m3',
  'components.total_access.m4',
  'sales.show.m4',
  'classifieds.classified_header.artist',
  ...require('./List/i18n'),
];
