import React, { forwardRef } from 'react';
import './stylesheet.scss';

const Overlapping = forwardRef(({ children, className = '', style = {} }, ref) => (
  <div className={`artists-common-overlapping ${className}`} style={style} ref={ref}>
    <div className="overlapping-content">{children}</div>
  </div>
));

export default Overlapping;
