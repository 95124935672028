import React, { useState } from 'react';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { FormattedNumber, injectIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { number as yupNumber, object as yupObject } from 'yup';
import { internalAxios } from '../../../../../utils/axios/internal';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

const schema = yupObject().shape({
  price: yupNumber().required(),
  currency: yupNumber().required(),
});

const Fees = ({ lang, intl }) => {
  const [fee, setFee] = useState();
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: '',
      currency: 48,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = values => {
    setLoading(true);
    internalAxios.get(`/api/marketplace/auctions/fees?currency=${values.currency}&price=${values.price}`).then(({ data }) => {
      setCurrency(data.currency);
      setFee(data.fee.seller);
      setLoading(false);
    });
  };

  return (
    <div className="marketplace-fees">
      <p>
        <A18n rsx="marketplace.howtobid.faq.fees.m1" />{' '}
        <strong>
          <A18n rsx="marketplace.howtobid.faq.fees.m2" />
        </strong>
      </p>
      <div className="split">
        <table className="table">
          <thead>
            <tr>
              <th>
                <A18n rsx="marketplace.howtobid.faq.fees.m3" />
              </th>
              <th>
                <A18n rsx="marketplace.howtobid.faq.fees.m4" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                0 - <FormattedNumber value={7500} />
              </td>
              <td>9%</td>
            </tr>
            <tr>
              <td>
                <FormattedNumber value={7500.01} /> - <FormattedNumber value={15000} />
              </td>
              <td>7%</td>
            </tr>
            <tr>
              <td>
                <A18n rsx="marketplace.howtobid.faq.fees.m13" replace={[['%nb%', intl.formatNumber(15000.01)]]} />
              </td>
              <td>5%</td>
            </tr>
          </tbody>
        </table>
        <div className="calculate">
          <p>
            <A18n rsx="marketplace.howtobid.faq.fees.m5" />
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="form-inline">
            <div className="form-group">
              <input type="number" {...register('price')} className="form-control" style={{ marginRight: 5 }} placeholder={i18next.t('marketplace.howtobid.faq.fees.m7')} />
              <select {...register('currency')} style={{ marginRight: 5 }} className="form-control">
                <option value="154">$</option>
                <option value="52">£</option>
                <option value="48">€</option>
              </select>
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {!loading && <span>OK</span>}
                {loading && <i className="fa fa-spinner fa-spin" />}
              </button>
              {errors.price?.message && <div className="user-submit-error-block text-danger">{errors.price.message}</div>}
            </div>
          </form>

          {!loading && fee && (
            <div className="result">
              <A18n rsx="marketplace.howtobid.faq.fees.m6" /> {fee} {currency}
            </div>
          )}
        </div>
      </div>

      <div className="example-container">
        <div className="example-title" dangerouslySetInnerHTML={{ __html: i18next.t('marketplace.howtobid.faq.fees.m8') }} />
        <div className="example">
          <ul>
            <li dangerouslySetInnerHTML={{ __html: i18next.t('marketplace.howtobid.faq.fees.m9') }} />
            <li dangerouslySetInnerHTML={{ __html: i18next.t('marketplace.howtobid.faq.fees.m10') }} />
            <li dangerouslySetInnerHTML={{ __html: i18next.t('marketplace.howtobid.faq.fees.m11') }} />
          </ul>
        </div>
        <div className="img">
          <img alt="example" src={`${IMAGE_SOURCE}marketplace/how-to-bid/fees-example-${lang}.png`} />
        </div>
        <div className="total">
          <p>{i18next.t('marketplace.howtobid.faq.fees.m12')}</p>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Fees);
