import React from 'react';
import { string } from 'prop-types';
import './stylesheets.scss';

const TopStore = ({ url, imageUrl, name }) => (
  <div className="marketplace-stores-top-store">
    <div className="store-img">
      <a href={url}>
        <img loading="lazy" className="shadow" src={imageUrl} alt={name} title={name} />
      </a>
    </div>
    <div className="store-link">
      <a href={url}>{name}</a>
    </div>
  </div>
);

TopStore.propTypes = {
  url: string.isRequired,
  imageUrl: string.isRequired,
  name: string.isRequired,
};

export default TopStore;
