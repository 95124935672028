/* eslint-disable global-require */
module.exports = [
  'components.lotslists.show.m2',
  'components.lotslists.show.m3',
  'components.lotslists.create.m1',
  'components.myartprice.settings.artists.m3',
  'components.lotslists.show.m4',
  'components.lotslists.show.m13',
  'components.lotslists.modal.m1',
];
