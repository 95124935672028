import React from 'react';

const Artists = ({ store }) => {
  if (store.artists) {
    return store.artists.map(artist => (
      <div key={artist.id}>
        <a href={artist.url} dangerouslySetInnerHTML={{ __html: artist.html }} />
      </div>
    ));
  }

  return '';
};

export default Artists;
