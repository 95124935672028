/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import AsyncSelect from 'react-select/async';
import i18next from 'i18next';
import { getStore as getStoreSelector } from '../../../../../redux/selectors/marketplace/stores';
import { removeArtist as removeArtistAction, addArtist as addArtistAction } from '../../../../../redux/actions/marketplace/stores';
import { artistSelectService } from '../../../../../services/indexes/priceIndex';
import './stylesheets.scss';
import { isOn as isOnSelector } from '../../../../../redux/selectors/ui/switches';

class Artists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: {},
    };
  }

  handleOnChange(artist) {
    const { store, addArtist } = this.props;

    addArtist(store.id, artist.value);
    this.setState({ select: { value: null } });
  }

  render() {
    const { select } = this.state;
    const { store, removeArtist, deleteInProgress, addInProgress } = this.props;

    if (store) {
      return (
        <div className="marketplace-store-edit-artists">
          <div className="artists">
            {addInProgress && (
              <div className="adding-artist">
                <i className="fa fa-spin fa-spinner fa-4x" />
              </div>
            )}
            <ol className={addInProgress ? 'processing' : undefined}>
              {store.artists.map(artist => (
                <li key={artist.id}>
                  <span dangerouslySetInnerHTML={{ __html: artist.html }} />
                  {deleteInProgress(artist.id) && <i className="fa fa-spin fa-spinner" />}
                  {!deleteInProgress(artist.id) && <i onClick={() => removeArtist(store.id, artist.id)} role="presentation" className="fa fa-trash-o" />}
                </li>
              ))}
            </ol>
          </div>
          <div className="form">
            <AsyncSelect
              value={select.value}
              cacheOptions
              placeholder={i18next.t('marketplace.stores.edit.form.artists.m1')}
              noOptionsMessage={({ inputValue }) => {
                if (inputValue.length > 0) {
                  return i18next.t('marketplace.stores.edit.form.artists.m2');
                }
                return i18next.t('marketplace.stores.edit.form.artists.m3');
              }}
              loadingMessage={() => i18next.t('marketplace.stores.edit.form.artists.m4')}
              loadOptions={async term => {
                const artists = await artistSelectService({ excludedIdArtist: 0, term });
                return artists.map(({ id, html }) => ({ value: id, label: html }));
              }}
              onChange={artist => this.handleOnChange(artist)}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

Artists.defaultProps = {
  store: undefined,
};

Artists.propTypes = {
  addArtist: func.isRequired,
  removeArtist: func.isRequired,
  store: object,
};

function mapStateToProps(state, ownProps) {
  const { idstore } = ownProps;

  return {
    deleteInProgress: idartist => isOnSelector(state, { id: `delete-store-artist-${idstore}-${idartist}` }),
    addInProgress: isOnSelector(state, { id: `add-store-artist-${idstore}` }),
    store: getStoreSelector(state, { idstore }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeArtist: (idstore, idartist) => dispatch(removeArtistAction(idstore, idartist)),
    addArtist: (idstore, idartist) => dispatch(addArtistAction(idstore, idartist)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
