import AsyncSelect from 'react-select/async';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { artistSelectService } from '../../../../../services/indexes/priceIndex';
import { followArtist } from '../../../../../redux/actions/myartprice/datas';

const ArtistAsyncSelect = () => {
  const dispatch = useDispatch();
  const [, setOpenModale] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState({ value: null });
  const [, setSelectedIdartist] = useState(null);

  const handleOnChange = artist => {
    setSelectedIdartist(artist.value);
    setSelectedArtist({ value: null });
    dispatch(followArtist({ idartist: artist.value }));
    setOpenModale(true);
  };

  return (
    <AsyncSelect
      className="search-artist"
      cacheOptions
      value={selectedArtist.value}
      placeholder={i18next.t('components.myartprice.artists.m3')}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length > 0) {
          return i18next.t('components.myartprice.artists.m4');
        }
        return i18next.t('components.myartprice.artists.m5');
      }}
      loadingMessage={() => i18next.t('components.myartprice.artists.m6')}
      loadOptions={async term => {
        const artists = await artistSelectService({
          excludedIdArtist: 0,
          term,
        });
        return artists.map(({ id, html, gtm, rank }) => ({
          value: id,
          label: html,
          gtm,
          rank,
        }));
      }}
      onChange={artist => handleOnChange(artist)}
    />
  );
};

export default ArtistAsyncSelect;
