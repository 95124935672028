import React from 'react';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Block = () => (
  <div className="ails-info-testimonials-block">
    <div className="testimonial">
      <div className="icon" />

      <div className="testimonial-info">
        <A18n rsx="ails.info.m60" />
      </div>
      <div className="testimonial-contact">
        Artcurial
        <br /> <A18n rsx="ails.info.m77" />
      </div>
    </div>
  </div>
);

export default Block;
