/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { isNil, isFunction, get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NONEXISTENTFORMID } from '../../../../constants/inputs';

import './stylesheet.scss';
import { EMPTY_OBJECT } from '../../../../constants/utils';

function getVariant(undetermined, checked, square) {
  if (undetermined) {
    return 'undetermined';
  }
  if (!checked) return 'unchecked';

  return square ? 'checked-square' : 'checked';
}

const Checkbox = ({ className, style = EMPTY_OBJECT, onChange, name, id = uuidv4(), disabled, checked, label, light = false, square, ...otherProps }) => {
  const [domId] = useState(id);
  const undetermined = isNil(checked);

  return (
    <div
      className={['artp-checkbox-container', light && 'light', square && 'square', className]
        .filter(e => !!e)
        .join(' ')
        .trim()}
      style={style}
    >
      <input
        form={undetermined ? NONEXISTENTFORMID : undefined}
        type="checkbox"
        name={name || domId}
        id={domId}
        checked={undetermined ? false : checked}
        disabled={disabled}
        onChange={e => {
          if (onChange) {
            onChange(get(e, 'target.checked'));
          } else {
            console.warn(`no OnChange defined for Checkbox #${domId}`);
          }
        }}
        {...otherProps}
      />
      <label htmlFor={domId} className={`check-box check-box-${getVariant(undetermined, checked, square)}`} />
      {label && (
        <label htmlFor={domId} className={`artp-input-label ${disabled ? 'disabled' : ''}`.trim()}>
          {isFunction(label)
            ? label({
                id,
                checked,
                disabled,
                undetermined,
              })
            : label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
