/* eslint-disable global-require */
module.exports = [
  'payments.platforms.offline.m1',
  'payments.platforms.offline.m2',
  'payments.platforms.offline.m3',
  'payments.platforms.offline.m4',
  'payments.platforms.offline.m5',
  'payments.platforms.offline.m6',
  'payments.platforms.offline.m7',
  'payments.platforms.offline.m8',
  'payments.platforms.offline.m9',
  'payments.platforms.offline.m10',
];
