import React from 'react';
import { useSelector } from 'react-redux';
import { getEstimateWaitingPaymentsCount } from '../../../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_ESTIMATE } from '../../../../../../../../../constants/rails';

const EstimatesWaitingForPayment = () => {
  const waitingPaymentsCount = useSelector(getEstimateWaitingPaymentsCount);
  if (waitingPaymentsCount < 1) {
    return null;
  }

  const rsx = waitingPaymentsCount === 1 ? 'component.header.notifications.estimate.m1' : 'component.header.notifications.estimate.m2';
  return (
    <Notification
      element={<A18n rsx={rsx} replace={[['%count%', waitingPaymentsCount]]} />}
      link="/account/estimate"
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_ESTIMATE })}
    />
  );
};

export default EstimatesWaitingForPayment;
