import React from 'react';
import i18next from 'i18next';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import SocialNetworkSharing from '../../../common/Share';
import Follow from '../../Follow';
import Location from './Location';
import './stylesheet.scss';

const loaderWidth = () => `${Math.floor(Math.random() * 96)}%`;

const NoAccess = ({ lot }) => (
  <div className="lots-lot-no-access">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="header">
            <h1>
              {lot.isPast && <>{lot.title}, </>}
              {!lot.isPast && lot.category} {lot.lotassociationtype === 1 && i18next.t('artprice.wallet.physicsale.m14')} {lot.artistOrAssociation} <Follow idlot={lot.id} className="marg marg-l-5" />{' '}
              <SocialNetworkSharing
                url={lot.url}
                title={i18next
                  .t('title.description.lot.show')
                  .replace('%title%', lot.title)
                  .replace('%artist%', `${(<Artist id={lot.idartist} />)} (${lot.id})`)}
              />
            </h1>
            <h2>
              {lot.techniqueAndPrecision}, {lot.numberAndEdition && <>{lot.numberAndEdition},</>} {lot.dimension}
            </h2>
          </div>
          <div className="datas">
            <div className="col-1">
              <div className="content-no-access">{i18next.t('lot.show.no_access.m2')}</div>

              <div className="visible-xs free">
                <Location lot={lot} />
              </div>
              {!lot.isPast && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lot.show.no_access.m7')} />
                </div>
              )}
              {lot.imageUrls && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lot.show.no_access.m5')}>
                    <i className="fa fa-camera marg marg-r-5" />
                  </div>
                </div>
              )}
              {(lot.distinctivesSigns || lot.signaturePosition) && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('artprice.lib.psresultctrl.m19')} />
                </div>
              )}
              {lot.descriptionAndLitterature && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('artprice.lib.psresultctrl.m23')} />
                </div>
              )}
              {lot.provenance && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.show.m9')} />
                </div>
              )}
              {lot.exposition && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.show.m10')} />
                </div>
              )}
              {lot.condition && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.show.m11')} />
                </div>
              )}
              {parseInt(lot.quantity, 10) > 1 && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('artprice.client.payment.billdetail.m12')} />
                </div>
              )}
            </div>
            <div className="col-2">
              <div className="hidden-xs free">
                <Location lot={lot} />
              </div>

              <div
                className="loader"
                title={i18next.t('lot.show.no_access.m1')}
                style={{
                  width: loaderWidth(),
                  marginRight: 10,
                }}
              >
                <div className="loader-content" data-txt={i18next.t('lot.show.no_access.m6')} />
              </div>

              {lot.isPast && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('artprice.lib.psresultctrl.m6')}>
                    <i className="fa fa-gavel marg marg-r-5" />
                  </div>
                </div>
              )}

              {lot.priceWithTaxes && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.small.m1')} />
                </div>
              )}

              {lot.price_starting && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.small.m2')} />
                </div>
              )}

              {lot.estimation && (
                <div className="loader" title={i18next.t('lot.show.no_access.m1')} style={{ width: loaderWidth() }}>
                  <div className="loader-content" data-txt={i18next.t('lots.show.m12')} />
                </div>
              )}

              <div
                className="loader"
                title={i18next.t('lot.show.no_access.m1')}
                style={{
                  width: 130,
                  marginRight: 10,
                }}
              >
                <div className="loader-content" data-txt={i18next.t('lot.show.no_access.m3')}>
                  <i className="fas fa-warehouse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NoAccess;
