import React from 'react';
import classNames from 'classnames';

import './stylesheet.scss';

const Item = ({ link, children, onClick, className }) => {
  if (link) {
    return (
      <a href={link} className={classNames('artp-Header-Menu-Folder-Item', className)} onClick={onClick}>
        {children}
      </a>
    );
  }
  return (
    <div className={classNames('artp-Header-Menu-Folder-Item', className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Item;
