import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFiltersResultCount } from '../../../../../../redux/slices/artist';
import { isLogged } from '../../../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';
import usePriceOneDay from '../../../common/usePriceOneDay';
import '../../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const NoAccess = ({ display, setDisplay, searchParams }) => {
  const intl = useIntl();
  const filtersResultCount = useSelector(getFiltersResultCount);
  const logged = useSelector(isLogged);
  const priceOneDay = usePriceOneDay();

  if (!display) return null;

  return (
    <div className="artists-artist-common-drawer-no-access">
      <div className="bg" onClick={() => setDisplay(false)} />
      <div className="box-no-access">
        <div className="close" onClick={() => setDisplay(false)}>
          <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
        </div>
        <div className="box-content">
          <p>
            {searchParams.only_in_future_sales === 1 && filtersResultCount > 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m1" replace={[['%nb%', intl.formatNumber(filtersResultCount)]]} />}
            {searchParams.only_in_future_sales !== 1 && filtersResultCount > 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m2" replace={[['%nb%', intl.formatNumber(filtersResultCount)]]} />}
            {searchParams.only_in_future_sales === 1 && filtersResultCount === 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m3" />}
            {searchParams.only_in_future_sales !== 1 && filtersResultCount === 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m4" />}
          </p>
          <p>
            {priceOneDay && <A18n rsx="artists.artist.filters.drawer.noaccess.m6" replace={[['%price%', priceOneDay]]} trustHtml />}
            {!priceOneDay && <A18n rsx="artists.artist.filters.drawer.noaccess.m7" trustHtml />}
          </p>
          <div className="actions">
            <button
              className="artp-btn artp-btn-purple"
              type="button"
              onClick={e => {
                e.stopPropagation();
                window.location.href = '/subscription';
              }}
            >
              <A18n rsx="artists.artist.filters.drawer.noaccess.m8" />
            </button>

            {!logged && (
              <button
                className="artp-btn"
                id="sln_login"
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  window.location.href = '/identity/sales';
                }}
              >
                <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
