import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import { reportMethodology } from '../../../../services/wordpress';
import Modal from '../../../common/ui/Modal';
import './stylesheet.scss';

const Methodology = ({ siteSlug }) => {
  const [methodology, setMethodology] = useState();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    reportMethodology({ siteSlug }).then(data => setMethodology(data.methodology));
  }, [siteSlug]);

  if (!methodology) return null;

  return (
    <div className="reports-report-methodology">
      <Modal open={opened} onClose={() => setOpened(false)} title={i18next.t('artprice.lib.menu10.m11')} className="artp-modal-methodology">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: methodology.unparsed_content }} className="text" />
        <div className="bottom">
          <Button onClick={() => setOpened(false)} className="btn btn-primary">
            Fermer
          </Button>
        </div>
      </Modal>

      <p>{i18next.t('artmarketinsight.reports.m9')} :</p>
      <div onClick={() => setOpened(true)} className="cta">
        {i18next.t('artprice.lib.menu10.m11')}
      </div>
    </div>
  );
};

export default Methodology;
