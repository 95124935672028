import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMessagetype, getMessage } from '../../../../../redux/selectors/dbEntities';
import { fetchMessage } from '../../../../../redux/actions/dbEntities/messages';
import New from '../../New';
import { fetchMessagetypes } from '../../../../../redux/actions/dbEntities/messagetypes';

const Message = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const message = useSelector(state => getMessage(state, { id }));
  const messagetypes = useSelector(getAllMessagetype);

  useEffect(() => {
    if (!message) {
      dispatch(fetchMessage({ id: [id] }));
    }

    if (Object.values(messagetypes).length === 0) {
      dispatch(fetchMessagetypes());
    }
  }, []);

  if (!message || Object.values(messagetypes).length === 0) {
    return null;
  }

  const defaultValues = {
    subject: `RE: ${message.subject}`,
    idmessagetype: { value: message.idprivatemessagetype, label: Object.values(messagetypes).filter(messagetype => messagetype.id === message.idprivatemessagetype)[0].label },
    idparent: message.id,
    message: `<div></div><br/><br/><hr/><table><tr><td style="padding-left: 25px;border-left: solid 3px #1da1f3">${message.message}</td></tr></table></div>`,
  };

  return <New defaultValues={defaultValues} />;
};

export default Message;
