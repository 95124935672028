/* eslint-disable global-require */
module.exports = [
  'indexes.header.demo.m1',
  'indexes.header.demo.m2',
  'indexes.header.demo.m3',
  'indexes.demo.or.lock.mode.m1',
  'indexes.demo.or.lock.mode.m2',
  'indexes.demo.or.lock.mode.m3',
  'indexes.demo.or.lock.mode.m4',
  'indexes.demo.or.lock.mode.m5',
];
