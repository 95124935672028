/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import i18next from 'i18next';
import Select from '../../common/FacetsSelect';

const component = ({ className }) => (
  <div className="artp-input-group">
    <span className="artp-input-label">{i18next.t('marketplace.searchbars.common.countries.m1')}</span>
    <Select isMulti className={className} placeholder={i18next.t('marketplace.searchbars.common.countries.m2')} facetName="idcountry" />
  </div>
);

export default component;
