import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLots, getAddAndCreateButtonsVisibility, isSelected, removeLot } from '../../../../redux/slices/account/lotslists';
import Checkbox from '../../../common/ui/Checkbox';
import './stylesheet.scss';

const STYLE = { '--artp-checkbox-size': '16px' };

const SelectLot = ({ id }) => {
  const dispatch = useDispatch();
  const addAndCreateButtonsVisibility = useSelector(getAddAndCreateButtonsVisibility);
  const selected = useSelector(state => isSelected(state, { id }));

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (selected) {
      dispatch(removeLot({ id }));
    } else {
      dispatch(addLots({ ids: [id] }));
    }
    return false;
  };

  if (!addAndCreateButtonsVisibility) return null;

  return (
    <div className="account-lots-lists-select-lot" onClick={handleClick}>
      <Checkbox checked={selected} onClick={handleClick} style={STYLE} />
    </div>
  );
};

export default SelectLot;
