import React from 'react';
import A18n from '../../../../../common/A18n';

const Display = ({ displayPart2, setDisplayPart2, terms }) => (
  <>
    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: terms.part1 }} />

    {terms?.part2 && (
      <>
        {/* eslint-disable-next-line react/no-danger */}
        {displayPart2 && <p dangerouslySetInnerHTML={{ __html: terms?.part2 }} />}

        <p>
          {!displayPart2 && (
            <a href="#" onClick={() => setDisplayPart2(true)}>
              + <A18n rsx="ails.landingpage.header.terms.m1" />
            </a>
          )}
          {displayPart2 && (
            <a href="#" onClick={() => setDisplayPart2(false)}>
              - <A18n rsx="ails.landingpage.header.terms.m2" />
            </a>
          )}
        </p>
      </>
    )}
  </>
);

export default Display;
