/* eslint-disable global-require */
module.exports = [
  'artists.artist.filters.drawer.noaccess.m1',
  'artists.artist.filters.drawer.noaccess.m2',
  'artists.artist.filters.drawer.noaccess.m3',
  'artists.artist.filters.drawer.noaccess.m4',
  'artists.artist.filters.drawer.noaccess.m5',
  'artists.artist.filters.drawer.noaccess.m6',
  'artists.artist.filters.drawer.noaccess.m7',
  'artists.artist.filters.drawer.noaccess.m8',
];
