/* eslint-disable global-require */
module.exports = [
  ...require('./Artist/i18n'),
  ...require('./Categories/i18n'),
  ...require('./City/i18n'),
  ...require('./Classifiedauctionmodes/i18n'),
  ...require('./Countries/i18n'),
  ...require('./Dimensions/i18n'),
  ...require('./DisplayedCurrencies/i18n'),
  ...require('./PerPage/i18n'),
  ...require('./Freshness/i18n'),
  ...require('./Estimations/i18n'),
  ...require('./Prices/i18n'),
  ...require('./Shapes/i18n'),
  ...require('./Sizes/i18n'),
  ...require('./State/i18n'),
  ...require('./Techniques/i18n'),
  ...require('./Dates/i18n'),
  ...require('./Storetypes/i18n'),
  ...require('./Auctioneer/i18n'),
  ...require('./Professionaltypes/i18n'),
  ...require('./Identifications/i18n'),
];
