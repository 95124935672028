/* eslint-disable global-require */
module.exports = [
  'marketplace.howtobid.transactionprocess.m1',
  'marketplace.howtobid.transactionprocess.m2',
  'marketplace.howtobid.transactionprocess.m3',
  'marketplace.howtobid.transactionprocess.m4',
  'marketplace.howtobid.transactionprocess.m5',
  'marketplace.howtobid.transactionprocess.m6',
  'marketplace.howtobid.transactionprocess.m7',
  'marketplace.howtobid.transactionprocess.m8',
  'marketplace.howtobid.transactionprocess.m9',
];
