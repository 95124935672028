/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.settings.m1',
  'subscriptions.purchase.settings.m2',
  'subscriptions.purchase.settings.m3',
  'subscriptions.purchase.settings.m4',
  'subscriptions.purchase.settings.m5',
  'subscriptions.purchase.settings.m6',
  'subscriptions.purchase.settings.m7',
  'subscriptions.purchase.settings.m8',
  'subscriptions.purchase.settings.m9',
  'subscriptions.purchase.settings.m10',
  'subscriptions.purchase.settings.m11',
  'subscriptions.purchase.settings.m12',
  'subscriptions.purchase.settings.m13',
  'subscriptions.purchase.settings.m14',
];
