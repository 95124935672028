import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { getPk, isLoading } from '../../../redux/selectors/subscriptions/stripe';
import Spinner from '../../common/spinners/Spinner';

const KEY_FOR_VISIBILITY = 'stripe_subscription';

// Sous composant car le UseStripe doit etre encapsulé par un composant Elements
const StripeRedirect = ({ checkoutSessionId }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (checkoutSessionId && stripe) {
      stripe.redirectToCheckout({ sessionId: checkoutSessionId });
    }
  }, [checkoutSessionId, stripe]);

  return null;
};

const StripeCheckout = ({ locale }) => {
  const [checkoutSessionId, setCheckoutSessionId] = useState();
  const pk = useSelector(getPk);
  const stripePromise = loadStripe(pk);
  const loading = useSelector(isLoading);

  useEffect(() => {
    const listenForGetPlatformsEvent = e => {
      const platform = e.detail.platforms.filter(p => p.id === KEY_FOR_VISIBILITY)[0];
      if (platform) {
        setCheckoutSessionId(platform.parameters.checkout_session.sessionId);
      }
    };

    window.addEventListener('getPlatforms', listenForGetPlatformsEvent);

    return () => {
      window.removeEventListener('getPlatforms', listenForGetPlatformsEvent);
    };
  }, []);

  if (!checkoutSessionId && !loading) return null;

  if (loading) return <Spinner />;

  return (
    <IntlProvider locale={locale}>
      <Elements stripe={stripePromise}>
        <StripeRedirect checkoutSessionId={checkoutSessionId} />
      </Elements>
    </IntlProvider>
  );
};

export default StripeCheckout;
