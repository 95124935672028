import i18next from 'i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Contact from './Contact';
import Artists from './Artists';
import './stylesheets.scss';

const ARTISTS = 1;
const NEWS = 2;
const PRESENTATION = 3;
const CONTACT = 4;

const Informations = ({ store, displayEmptyTabs }) => {
  const [activeTab, setActiveTab] = useState(store.idstoreview);
  const [displayTabs, setDisplayTabs] = useState(false);

  useEffect(() => {
    if ((!(store.news && store.news !== '') || displayEmptyTabs) && store.idstoreview === NEWS) {
      setActiveTab(CONTACT);
    }

    if ((!(store.presentation && store.presentation !== '') || displayEmptyTabs) && store.idstoreview === PRESENTATION) {
      setActiveTab(CONTACT);
    }

    if ((store.artists.length === 0 || displayEmptyTabs) && store.idstoreview === ARTISTS) {
      setActiveTab(CONTACT);
    }

    setDisplayTabs(displayEmptyTabs || (store.news || '').trim() !== '' || (store.presentation || '').trim() !== '' || store.artists.length > 0);
  }, [store, displayEmptyTabs]);

  return (
    <div className="marketplace-store-header-store-informations">
      {displayTabs && (
        <div className="tabs">
          <div role="presentation" className="tab" style={{ width: 15, flex: 'none' }} />
          {(store.news || displayEmptyTabs) && (
            <div role="presentation" className={activeTab === NEWS ? 'tab active' : 'tab'} onClick={() => setActiveTab(NEWS)}>
              {i18next.t('marketplace.store._showcase.html.m11')}
            </div>
          )}
          {(store.presentation || displayEmptyTabs) && (
            <div role="presentation" className={activeTab === PRESENTATION ? 'tab active' : 'tab'} onClick={() => setActiveTab(PRESENTATION)}>
              {i18next.t('marketplace.store._showcase.html.m12')}
            </div>
          )}
          {(store.artists.length > 0 || displayEmptyTabs) && (
            <div role="presentation" className={activeTab === ARTISTS ? 'tab active' : 'tab'} onClick={() => setActiveTab(ARTISTS)}>
              {i18next.t('marketplace.store._showcase.html.m13')}
            </div>
          )}

          <div role="presentation" className={activeTab === CONTACT ? 'tab active' : 'tab'} onClick={() => setActiveTab(CONTACT)}>
            {i18next.t('marketplace.store._showcase.html.m14')}
          </div>
          <div role="presentation" className="tab" style={{ width: 15, flex: 'none' }} />
        </div>
      )}
      <div className={classNames({ content: true })}>
        {activeTab === PRESENTATION && <div className="txt border" dangerouslySetInnerHTML={{ __html: store.presentation }} />}
        {activeTab === NEWS && <div className="txt border" dangerouslySetInnerHTML={{ __html: store.news }} />}
        {activeTab === ARTISTS && (
          <div className="txt border">
            <Artists store={store} />
          </div>
        )}
        {activeTab === CONTACT && (
          <div className={classNames({ txt: true, 'txt-contact': true, border: displayTabs })}>
            {store.contact && <Contact store={store} />}
            {!store.contact && <a href="/identity">{i18next.t('marketplace.free.show.contact.m10')}</a>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Informations;
