import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Pie, PieChart, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { getMediumData, isMediumDataLoading, getMediumDisplayedChartTab } from '../../../../redux/selectors/indexes/medium';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { getFormatOptions } from '../../../../utils/indexes/medium';
import { COLORS } from '../../../../constants/indexes/common';
import Spinner from '../../../common/spinners/Spinner/index';
import { useViewport } from '../../../../hooks/useViewport';

const MediumPieChart = ({ intl }) => {
  const loading = useSelector(isMediumDataLoading);
  const rawData = useSelector(getMediumData);
  const currency = useSelector(getCurrency);
  const tab = useSelector(getMediumDisplayedChartTab);
  const { viewportWidth } = useViewport();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (rawData) {
      const simplifiedData = [];
      const sumUp = (accumulator, currentEl) => accumulator + currentEl.value;
      const hundredPercent = rawData.reduce(sumUp, 0);
      let eightyPercent = hundredPercent * 0.8;
      const sortedData = [...rawData].sort((el, nextEl) => nextEl.value - el.value);
      const others = { name: i18next.t('indexes.others'), value: 0 };

      for (let i = 0; i < sortedData.length; i += 1) {
        const currentEl = sortedData[i];

        if (eightyPercent <= 0 && (i < sortedData.length - 1 || others.value > 0)) {
          others.value += currentEl.value;
        } else {
          simplifiedData.push(currentEl);
        }

        eightyPercent -= currentEl.value;
      }

      const toReturn = rawData.filter(item => simplifiedData.includes(item));

      if (others.value > 0) {
        toReturn.push(others);
      }

      setData(toReturn);
    }
  }, [rawData]);

  const renderLabel = props => {
    const { cx, cy, midAngle, innerRadius, outerRadius, fill, percent, value, name } = props;

    if (!percent > 0) return null;

    const RADIAN = Math.PI / 180;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 3) * cos;
    const sy = cy + (outerRadius + 3) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const percentRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const percentX = cx + -10 * Math.sign(Math.cos(-midAngle * RADIAN)) + percentRadius * Math.cos(-midAngle * RADIAN);
    const percentY = cy + percentRadius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        {viewportWidth >= 768 && (
          <>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={percent * 100 < 8 ? ey : ey + 5} textAnchor={textAnchor} fill="#333">
              {`${name}: ${intl.formatNumber(value, getFormatOptions({ tab, currency }))}`}
            </text>
          </>
        )}
        {percent * 100 < 8 && (
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#888">
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        )}
        {percent * 100 >= 8 && (
          <text x={percentX} y={percentY} fill="#fff" textAnchor={percentX > cx ? 'start' : 'end'} dominantBaseline="central" style={{ zIndex: 15 }}>
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        )}
      </g>
    );
  };

  if (loading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }

  const displayOnMobile = window.matchMedia('(max-width: 480px)').matches;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart width={800} height={400}>
        {viewportWidth < 768 && (
          <>
            <Legend verticalAlign="top" layout="horizontal" height={36} />
            <Tooltip />
          </>
        )}
        <Pie
          startAngle={90}
          endAngle={-270}
          data={data}
          dataKey="value"
          nameKey="name"
          outerRadius={145}
          fill="rgb(51, 102, 204)"
          labelLine={viewportWidth >= 768}
          legendType="square"
          animationDuration={250}
          label={renderLabel}
        >
          {data.map((entry, index) => (
            <Cell key={entry.name || JSON.stringify(entry)} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {displayOnMobile && <Legend />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(MediumPieChart);
