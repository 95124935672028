import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Dimensions from '../../../inputs/Dimensions';

const Dimension = () => (
  <Item label={i18next.t('lots.search.form.size.m4')} name={{ height: 'height', width: 'width', length: 'length', unit: 'unit', percent: 'percent' }}>
    <Dimensions />
  </Item>
);

export default Dimension;
