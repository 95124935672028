import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Classified from '../index';

const ClassifiedConnected = ({ id, lang }) => (
  <ReduxProvider>
    <Classified id={id} lang={lang} />
  </ReduxProvider>
);

export default ClassifiedConnected;
