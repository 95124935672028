import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import LangChangedModal from '..';

const Provided = ({ locale }) => (
  <IntlProvider locale={locale} suppressHydrationWarning>
    <ReduxProvider>
      <LangChangedModal />
    </ReduxProvider>
  </IntlProvider>
);

export default Provided;
