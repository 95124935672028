/* eslint-disable global-require */
module.exports = [
  'tour.emailvalidationneeded.contents.locked.m1',
  'tour.emailvalidationneeded.contents.locked.m2',
  'tour.emailvalidationneeded.contents.locked.m3',
  'tour.emailvalidationneeded.contents.locked.m4',
  'tour.emailvalidationneeded.contents.locked.m5',
  'tour.emailvalidationneeded.contents.locked.m6',
  'tour.emailvalidationneeded.contents.locked.m7',
  'tour.emailvalidationneeded.contents.locked.m8',
  'tour.emailvalidationneeded.contents.locked.m9',
  'tour.emailvalidationneeded.contents.locked.m10',
  'tour.emailvalidationneeded.contents.locked.m11',
  'tour.emailvalidationneeded.contents.locked.m12',
];
