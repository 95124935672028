import { HOWTO_INDEXES, HOWTO_SEARCHES, HOWTO_ARTPRICE } from './rails';

export const SWITCH_ID = 'howto-switch';

export const INDEXES = HOWTO_INDEXES;
export const SEARCHES = HOWTO_SEARCHES;
export const ARTPRICE = HOWTO_ARTPRICE;

export const LOCALE = 'locale';
export const YOUTUBE = 'youtube';
