import React, { Component } from 'react';
import i18next from 'i18next';
import { object, string, bool, number, func } from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { debounce, get } from 'lodash';
import {
  getCurrentDefaultFacets as getDefaultFacetsSelector,
  getCurrentDraftFacets as getDraftFacetsSelector,
  getCurrentUrlParams as getUrlParamsSelector,
  getCurrentLoadingFacets as getLoadingFacetsSelector,
} from '../../../../redux/selectors/search';
import { updateCurrentDraft as updateParamsAction } from '../../../../redux/actions/search';
import { width as viewportWidthSelector } from '../../../../redux/selectors/ui/viewport';
import customStyles from '../reactSelect/style';
import SelectedParam from '../SelectedParams/Facet';
import '../reactSelect/stylesheet.scss';
import './stylesheet.scss';

class Select extends Component {
  handleChange = debounce(selectedOption => {
    const { facetName, isMulti, updateParams } = this.props;

    if (selectedOption) {
      if (isMulti) {
        updateParams(
          facetName,
          selectedOption.map(option => option.value),
        );
      } else {
        updateParams(facetName, selectedOption.value);
      }
    } else {
      updateParams(facetName, null);
    }
  }, 200);

  render() {
    const { defaultFacets, draftFacets, loadingFacets, urlParams, facetName, isMulti, placeholder, className, viewportWidth } = this.props;

    if (loadingFacets && (urlParams === undefined || defaultFacets[facetName] === undefined)) {
      return null;
    }

    const options = Object.entries(draftFacets[facetName] || {})
      .map(([id, facet]) => {
        const count = get(draftFacets, `${facetName}.${id}.count`, 0);
        return {
          value: id,
          label: `${facet.label} [${count}]`,
          count,
        };
      })
      .filter(({ count }) => count > 0);
    options.sort((a, b) => a.label.localeCompare(b.label));

    const defaultValues = options.filter(({ value }) => {
      if (!urlParams[facetName]) {
        return undefined;
      }

      return Array.isArray(urlParams[facetName]) ? urlParams[facetName].map(Number).includes(parseInt(value, 10)) : urlParams[facetName] === value;
    });

    if ((!Array.isArray(defaultValues) && defaultValues !== undefined && defaultValues !== '') || (Array.isArray(defaultValues) && defaultValues.length > 0)) {
      if (Array.isArray(defaultValues)) {
        return (
          <div className="marketplace-searchbars-common-facets-select">
            {defaultValues.map(({ value }) => (
              <SelectedParam key={`${facetName}-${value}`} identifier={value} facetName={facetName} />
            ))}
          </div>
        );
      }

      return <div>{defaultValues}</div>;
    }

    return (
      <ReactSelect
        noOptionsMessage={() => i18next.t('marketplace.searchbars.common.facetsselect.m1')}
        styles={customStyles(viewportWidth)}
        classNamePrefix="react-select"
        className={`search-common-react-select search-bars-common-facets-select ${className}`}
        components={makeAnimated()}
        isMulti={isMulti}
        options={options}
        onChange={this.handleChange}
        placeholder={placeholder}
        defaultValue={defaultValues}
        isLoading={loadingFacets}
      />
    );
  }
}

Select.defaultProps = {
  defaultFacets: {},
  draftFacets: {},
  isMulti: false,
  loadingFacets: true,
  placeholder: '',
  className: 'search-bar-input',
  viewportWidth: undefined,
};

Select.propTypes = {
  defaultFacets: object,
  draftFacets: object,
  urlParams: object.isRequired,
  facetName: string.isRequired,
  loadingFacets: bool,
  isMulti: bool,
  placeholder: string,
  className: string,
  updateParams: func.isRequired,
  viewportWidth: number,
};

function mapStateToProps(state) {
  return {
    defaultFacets: getDefaultFacetsSelector(state),
    draftFacets: getDraftFacetsSelector(state),
    urlParams: getUrlParamsSelector(state),
    loadingFacets: getLoadingFacetsSelector(state),
    viewportWidth: viewportWidthSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateParams: (param, value) => dispatch(updateParamsAction({ triggeredParam: param, value })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Select);
