import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import SearchBar from '..';

const ProvidedSearchBar = ({ searchScope, baseParams, hiddenFields }) => (
  <ReduxProvider>
    <SearchBar searchScope={searchScope} baseParams={baseParams} hiddenFields={hiddenFields} />
  </ReduxProvider>
);

export default ProvidedSearchBar;
