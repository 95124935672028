import React from 'react';
import { IntlProvider } from 'react-intl';
import Reporting from '../index';

const ProvidedReporting = ({ locale = 'en', data = {} }) => (
  <IntlProvider locale={locale}>
    <Reporting data={data} />
  </IntlProvider>
);

export default ProvidedReporting;
