import React from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_SEARCH } from '../../../../constants/rails';
import SectionHeader from '../SectionHeader';
import Skeleton from '../skeletons/Square';
import A18n from '../../../common/A18n';
import Artist from './Artist';
import { artistSearchOnKeyword } from '../../../../services/search';
import { useUniversalSearch } from '../hooks/useUniversalSearch';
import useCancellable from '../hooks/useCancellable';

const Artists = ({ term }) => {
  const searchArtists = useCancellable((v, signal) => artistSearchOnKeyword({ term: v, signal }));
  const { observeCallbackRef, loaded, entities: artists } = useUniversalSearch({ term, searchFunc: searchArtists });

  return (
    <div ref={observeCallbackRef}>
      <SectionHeader
        count={artists.totalCount}
        title={<A18n rsx="search.universal.artists.m1" />}
        url={`/artists/search?keyword=${encodeURIComponent(term)}`}
        outOfBounds={artists.outOfBounds}
        op={ANALYTICS_CLICK_OPERATION_TO_ARTIST_SEARCH}
      />
      {!loaded && <Skeleton />}
      {loaded && (
        <div className="items-container artists">
          <div className="items">
            {artists.data.map(artist => (
              <Artist artist={artist} key={`${artist.id}${artist.rank}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Artists;
