import React, { Component } from 'react';

import { array, func, bool, number } from 'prop-types';
import { Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

import FiltersBar from '../FiltersBar';

import {
  getCurrents,
  getUpcomings,
  getNotPartners,
  getCountriesOptions,
  areFuturesLoading,
  areNotPartnersLoading,
  getPageFilter,
  getFuturesEventsPagesCount,
  getNotPartnersTotalCount,
} from '../../../../redux/selectors/events';

import { fetchFutureEventsAction, fetchNotPartnerEventsAction, selectPageFilterAction } from '../../../../redux/actions/events';

import Item from '../Item';
import NotPartnerItem from '../NotPartnerItem';
import Spinner from '../../../common/spinners/Spinner';
import Pagination from '../FiltersBar/Pagination';
import Gtm from '../../../gtm/DataLayer';

class Futures extends Component {
  componentDidMount() {
    const { fetchEvents } = this.props;
    fetchEvents();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderLinkToPast = () => {
    const { choosePage } = this.props;
    return (
      <Link
        to="/events/past"
        onClick={() => {
          choosePage(1);
        }}
      >
        {i18next.t('events.link.futures.to.past')}
      </Link>
    );
  };

  renderLists = () => {
    const {
      currents,
      upcomings,
      notPartners,

      choosePage,
      pageFilter,
      pagesCount,
      notPartnersCount,

      fetchNotPartners,

      futuresLoading,
      notPartnersLoading,
    } = this.props;

    return (
      <Container className="marg marg-b-25">
        {(currents.length > 0 || upcomings.length > 0) && (
          <Col sm={notPartners.length > 0 ? 9 : 12} className={notPartners.length > 0 ? 'pad pad-r-35' : ''}>
            <div className="text-center">
              <Pagination choose={choosePage} currentPage={pageFilter} pagesCount={pagesCount} />
            </div>

            {currents.length > 0 && (
              <>
                <h2 className="events-categories-title marg marg-t-20 marg-b-20">{i18next.t('events.list.futures.partners.title.current')}</h2>
                <div className="events-list">
                  {currents.map((event, position) => (
                    <Item key={`#${event.id}-${event.title}`} event={event} position={position + 1} />
                  ))}
                </div>
              </>
            )}
            {upcomings.length > 0 && (
              <>
                <h2 className="events-categories-title marg marg-t-20 marg-b-20">{i18next.t('events.list.futures.partners.title.upcoming')}</h2>
                <div className="events-list">
                  {upcomings.map((event, position) => (
                    <Item key={`#${event.id}-${event.title}`} event={event} position={position + 1 + currents.length} />
                  ))}
                </div>
              </>
            )}
            {futuresLoading && <Spinner />}

            <div className="text-center">
              <Pagination choose={choosePage} currentPage={pageFilter} pagesCount={pagesCount} />
            </div>
          </Col>
        )}

        {notPartners.length > 0 && (
          <Col sm={currents.length > 0 || upcomings.length > 0 ? 3 : 12} className={`not-partner-events${currents.length > 0 || upcomings.length > 0 ? '' : ' no-border'}`}>
            <div className="events-list">
              {notPartners.map(event => (
                <NotPartnerItem key={`#${event.id}-${event.title}`} noLink event={event} />
              ))}
              {notPartnersLoading && <Spinner />}
              {notPartners.length < notPartnersCount && (
                <button type="button" className="clickable-icon" onClick={() => fetchNotPartners({ offset: notPartners.length })}>
                  <i className="fa fa-arrow-circle-down fa-2x" />
                </button>
              )}
            </div>
          </Col>
        )}
      </Container>
    );
  };

  render() {
    const {
      currents,
      upcomings,
      notPartners,

      countriesOptions,
      fetchEvents,
    } = this.props;

    const hasReceivedData = ![currents, upcomings, notPartners].every(l => l === undefined);

    return (
      <>
        <Gtm />
        <Container>
          <div className="breadcrumb">
            <li>
              <a href="/">{i18next.t('artprice.lib.navigbarartprice.m_home')}</a>
            </li>
            <li className="active">{i18next.t('events.list.common.navigationbar.list')}</li>
          </div>
        </Container>
        <FiltersBar fetchEvents={fetchEvents} options={countriesOptions} linkToOtherList={<this.renderLinkToPast />} />
        {hasReceivedData ? this.renderLists() : <span>{i18next.t('events.list.futures.nonefound')}</span>}
      </>
    );
  }
}

Futures.defaultProps = {
  currents: undefined,
  upcomings: undefined,
  notPartners: undefined,

  futuresLoading: false,
  notPartnersLoading: false,

  countriesOptions: undefined,

  pageFilter: 1,
  pagesCount: 1,
  notPartnersCount: 0,
};

Futures.propTypes = {
  // eslint-disable-next-line
  currents: array,
  // eslint-disable-next-line
  upcomings: array,
  // eslint-disable-next-line
  notPartners: array,

  futuresLoading: bool,
  notPartnersLoading: bool,

  pageFilter: number,
  pagesCount: number,

  notPartnersCount: number,

  // eslint-disable-next-line
  countriesOptions: array,

  fetchEvents: func.isRequired,
  fetchNotPartners: func.isRequired,
  choosePage: func.isRequired,
};

function mapStateToProps(state) {
  return {
    currents: getCurrents(state),
    upcomings: getUpcomings(state),
    notPartners: getNotPartners(state),

    futuresLoading: areFuturesLoading(state),
    notPartnersLoading: areNotPartnersLoading(state),

    pageFilter: getPageFilter(state),
    pagesCount: getFuturesEventsPagesCount(state),
    notPartnersCount: getNotPartnersTotalCount(state),

    countriesOptions: getCountriesOptions(state, { isPastList: false }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEvents: () => {
      dispatch(fetchFutureEventsAction());
      dispatch(fetchNotPartnerEventsAction());
    },
    fetchNotPartners: ({ offset }) => {
      dispatch(fetchNotPartnerEventsAction({ offset }));
    },
    choosePage: page => {
      dispatch(selectPageFilterAction({ page }));
      dispatch(fetchFutureEventsAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Futures);
