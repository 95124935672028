import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';

const handleContactClick = () => {
  window.location = '/account/clientmessages?sub_tab=new';
};

const Warning = ({ label, buttonText, buttonAction, displayContact = false, absolute = true }) => (
  <div className={`subscriptions-purchase-list-warnings-warning ${absolute ? 'absolute' : undefined}`}>
    <p>{label}</p>

    {displayContact && (
      <button style={{ marginRight: 5 }} type="button" onClick={handleContactClick}>
        {i18next.t('subscriptions.purchase.warnings.purchase-not-available.m3')}
      </button>
    )}

    <button type="button" className="active" onClick={buttonAction}>
      {buttonText}
    </button>
  </div>
);

export default Warning;
