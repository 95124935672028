import React from 'react';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const AdvantagesEscrow = () => (
  <div className="advantages-escrow">
    <h2>
      <A18n rsx="marketplace.howtobid.advantagesescrow.m1" /> <img alt="logo-escrow" src={`${IMAGE_SOURCE}marketplace/how-to-bid/logo-escrow.svg`} />
    </h2>
    <p>
      <A18n rsx="marketplace.howtobid.advantagesescrow.m2" />
    </p>
    <div className="steps">
      <div className="step step1">
        <A18n rsx="marketplace.howtobid.advantagesescrow.m3" />
      </div>
      <div className="step step2">
        <A18n rsx="marketplace.howtobid.advantagesescrow.m4" />
      </div>
      <div className="step step3">
        <A18n rsx="marketplace.howtobid.advantagesescrow.m5" />
      </div>
      <div className="step step4">
        <A18n rsx="marketplace.howtobid.advantagesescrow.m6" />
      </div>
    </div>
  </div>
);

export default AdvantagesEscrow;
