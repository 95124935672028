import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ANALYTICS_FROM_HOMEPAGE } from '../../../../constants/rails';
import { getSaleAils } from '../../../../redux/slices/homepage';
import Arrow from '../../../common/Arrow';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Sales = () => {
  const ails = useSelector(getSaleAils);

  if (isEmpty(ails)) return null;

  return (
    <div className="container homepage-ails-sales block">
      <h2 className="title">
        <A18n rsx="homepage.ails.sales.m1" />
        <div className="all">
          <a href="/sales/future">
            <A18n rsx="homepage.ails.sales.m2" />
            <Arrow style={{ marginLeft: 5 }} />
          </a>
        </div>
      </h2>
      <div className="data-ails three-cols">
        {ails.map(ail => (
          <a key={ail.id || ail.discriminant} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_HOMEPAGE}`}>
            <div className={classNames({ ail: true, fake: ail.idailtype === 16 })}>
              <div className="l0" style={{ backgroundImage: `url("${ail.image}")` }} />
              <div className="l1">
                {ail.idailtype !== 20 && <span>{ail.title}</span>}
                {ail.idailtype === 20 && <div className="landingpage-title">{ail.hpTitle}</div>}
              </div>
              <div className="l2">{ail.auctioneer}</div>
              <div className="l3">
                <div className="l3-0">
                  {ail.idailtype !== 16 && ail.idailtype !== 20 && <A18n rsx="homepage.ails.sales.m3" />}
                  {ail.idailtype === 20 && (
                    <span>
                      <A18n rsx="homepage.ails.sales.m4" />
                    </span>
                  )}
                </div>
                <div className="l3-1">
                  <div className="l3-1-0">
                    {ail.city} {ail.country}
                  </div>
                  <div className="l3-1-1">{ail.dt}</div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sales;
