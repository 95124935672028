import { useSelector } from 'react-redux';
import { get, isEmpty, shuffle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AILTYPE_ARTIST, ANALYTICS_FROM_ARTIST } from '../../../../../constants/rails';
import { getAils, getArtist } from '../../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import Carousel from '../../../../common/Carousel';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const alreadySeenClassifieds = []; // works because we are not in a SPA

const HighlightOn = () => {
  const ails = useSelector(getAils);
  const artist = useSelector(getArtist);
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    if (!isEmpty(get(ails, AILTYPE_ARTIST))) {
      setHighlights(ails[AILTYPE_ARTIST]);
    }
  }, [ails]);

  const afterChange = slick => {
    if (!alreadySeenClassifieds.includes(ails[AILTYPE_ARTIST][slick].id)) {
      alreadySeenClassifieds.push(ails[AILTYPE_ARTIST][slick].id);
      aapiBeacon('ail_view', { idail: ails[AILTYPE_ARTIST][slick].id, from: ANALYTICS_FROM_ARTIST, idartist: artist.id });
    }
  };

  const settings = {
    dots: false,
    fade: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    afterChange,
  };

  if (isEmpty(highlights)) return null;

  return (
    <div className="artist-highlight-on">
      <div className="container">
        <div className="ads-legend">
          <A18n rsx="artists.artist.highlighton.m1" />
        </div>
        <Carousel
          settings={settings}
          slides={shuffle(highlights).map(highlight => (
            <div key={`highlight-${highlight.id}`}>
              <a href={`/redir?ia=${highlight.id}&f=${ANALYTICS_FROM_ARTIST}`}>
                <img alt={highlight.url} src={highlight.images.mobile} className="small" />
                <img alt={highlight.url} src={highlight.images.desktop} className="large" />
              </a>
            </div>
          ))}
        />
      </div>
    </div>
  );
};

export default HighlightOn;
