import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLotSimilar as getLotSimilarSelector } from '../../../redux/selectors/myartprice/datas';
import { getLotSimilar as getLotSimilarAction } from '../../../redux/actions/myartprice/datas';
import { fetchArtists } from '../../../redux/actions/dbEntities/artist';
import { getArtist } from '../../../redux/selectors/dbEntities';
import ClassifiedTile from './ClassifiedTile';
import LotTile from './LotTile';
import './stylesheet.scss';

const LotSimilar = ({ idlot, idartist }) => {
  const dispatch = useDispatch();
  const lotsSimilar = useSelector(state => getLotSimilarSelector(state, { id: idlot }));
  const artist = useSelector(state => getArtist(state, { id: idartist }));

  useEffect(() => {
    dispatch(getLotSimilarAction(idlot));
    if (!artist) {
      dispatch(fetchArtists({ id: idartist, withDates: false }));
    }
  }, [idlot]);

  if (!lotsSimilar || lotsSimilar.length === 0) return <div />;

  const { similar, futures, classifieds } = lotsSimilar;

  if (futures.length === 0 && similar.length === 0 && classifieds.length === 0) {
    return null;
  }

  return (
    <div className="similar">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2>{artist && i18next.t('lots.show.m16').replace('%artist%', artist.name ? artist.name : artist.artist)}</h2>
          </div>
          {futures && futures.map(l => <LotTile key={l.lot.id} future lot={l.lot} url={l.url} img={l.img} location={l.location} auctioneer={l.auctioneer} />)}
          {similar && similar.map(l => <LotTile key={l.lot.id} lot={l.lot} url={l.url} img={l.img} location={l.location} auctioneer={l.auctioneer} />)}
          {classifieds && (
            <>
              {classifieds.map(c => (
                <ClassifiedTile key={c.classified.id} classified={c.classified} url={c.url} img={c.img} location={c.location} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LotSimilar;
