import i18next from 'i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSubscription, getCurrentBillCurrency, getLastSubscription } from '../../../../../../redux/slices/userContext/selectors';
import { ANALYTICS_CLICK_OPERATION_SELECT_PRODUCT } from '../../../../../../constants/rails';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import { updateSettings } from '../../../../../../redux/actions/subscriptions/settings';
import { isCancelled } from '../../../../../../utils/subscriptions/attentionNeeded';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import Checkbox from '../../../../../common/ui/Checkbox';
import { NEW, UPGRADE } from '../../../common/constants';
import OneDay from './OneDay';
import './stylesheet.scss';
import Price from './Price';

const Physicproduct = ({ physicproduct, theme, width }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrentBillCurrency);
  const { selectedIdphysicproduct, view, quantity, filter, from } = useSelector(getSettings);
  const activeSubscription = useSelector(getActiveSubscription);
  const lastSubscription = useSelector(getLastSubscription);
  const [recurrent, setRecurrent] = useState(false);
  const [attentionNeeded, setAttentionNeeded] = useState();

  const {
    added_days: addedDays = 0,
    nbdays,
    price,
    is_recurrent_subscription: isRecurrentSubscription,
    original_nbdays: originalNbdays,
    original_prices: originalPrices,
    expires_on: expiresOn,
    idp: idphysicproduct,
    paypal_recurrent_subscription_type: paypalRecurrentSubscriptionType,
    original_shortened_nbdays: originalShortenedNbdays,
  } = physicproduct;

  const slnClassNames = [`sln-cart-${physicproduct.theme?.toLowerCase()}`, `sln-cart-${idphysicproduct}`, `sln-cart-${nbdays}-days`];
  if (isRecurrentSubscription) {
    slnClassNames.push(`sln-cart-recurrent`);
  }

  useEffect(() => {
    const subscription = activeSubscription || lastSubscription;

    if (subscription) {
      setRecurrent(subscription.recurrent);
      setAttentionNeeded(subscription.attentionNeeded);
    }
  }, [activeSubscription, lastSubscription]);

  return (
    <div
      className={classNames('subscriptions-list-theme-physicproduct', slnClassNames)}
      style={{ width }}
      onClick={() => {
        const list = filter === 'subscription' ? 'Subscriptions' : 'Store-Subscriptions';
        const product = {
          name: physicproduct.reference,
          id: idphysicproduct,
          price: physicproduct.price.eur,
          brand: 'Artprice.com',
          category: theme.theme,
          variant: `period-${physicproduct.nbmonths}`,
        };

        window.dataLayer.push({
          event: 'detail',
          ecommerce: {
            detail: {
              actionField: {
                list,
              },
              products: [product],
            },
          },
        });

        window.dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list },
              products: [{ ...product, position: physicproduct.position }],
            },
          },
        });

        aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_SELECT_PRODUCT, section: physicproduct?.reference });

        dispatch(
          updateSettings({
            data: {
              selectedIdtheme: theme.idtheme,
              selectedIdphysicproduct: idphysicproduct,
              physicproduct,
            },
          }),
        );
      }}
    >
      <div className="old-price">{price[currency] !== originalPrices[currency] && <Price price={originalPrices[currency]} currency={currency} />}</div>
      <Price className="price" price={price[currency]} currency={currency} />

      <div className="duration">
        {selectedIdphysicproduct === idphysicproduct && ![30, 1].includes(originalShortenedNbdays) && (
          <p>
            {i18next
              .t(`subscriptions.purchase.list.theme.physicproduct.m${quantity > 1 ? 1 : 2}`)
              .replace('%dt%', expiresOn)
              .replace('%quantity%', quantity)}
          </p>
        )}

        {/* mensuel */}
        {isRecurrentSubscription && (
          <>
            {/* paypal */}
            {paypalRecurrentSubscriptionType && (
              <p>
                {selectedIdphysicproduct === idphysicproduct && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m3')}</span>}
                {selectedIdphysicproduct !== idphysicproduct && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m4')}</span>}
                <i className="fa fa-paypal" title="paypal" style={{ fontSize: 18, color: '#27346a' }} />
              </p>
            )}

            {/* autre mensuel (=stripe) */}
            {!paypalRecurrentSubscriptionType && (
              <p>
                {selectedIdphysicproduct === idphysicproduct && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m9')}</span>}
                {selectedIdphysicproduct !== idphysicproduct && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m10')}</span>}
              </p>
            )}
          </>
        )}

        {/* Oneday */}
        {originalShortenedNbdays === 1 && <OneDay selected={selectedIdphysicproduct === idphysicproduct} />}

        {view !== UPGRADE && idphysicproduct !== selectedIdphysicproduct && (
          <>
            {/* 1 an non sélectionné */}
            {originalShortenedNbdays === 365 && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m5')}</span>}

            {/* 2ans non sélectionné */}
            {originalShortenedNbdays === 730 && <span>{i18next.t('subscriptions.purchase.list.theme.physicproduct.m6')}</span>}
          </>
        )}

        {/* des jours offers avec soit un oneday soit un produit non selectionné en view renew */}
        {nbdays > originalNbdays && (originalNbdays === 1 || (idphysicproduct !== selectedIdphysicproduct && view === NEW)) && (
          <span>
            + {nbdays - originalNbdays} {i18next.t(`subscriptions.purchase.list.theme.physicproduct.m${nbdays - originalNbdays > 1 ? '7' : '8'}`)}
          </span>
        )}
      </div>

      {/* Jours offers */}
      {addedDays > 0 && <p>{addedDays}</p>}

      {(!recurrent || isCancelled(attentionNeeded)) && (
        <div className="select">
          <Checkbox className="sln-price" checked={selectedIdphysicproduct === idphysicproduct} onClick={e => e.stopPropagation()} />
        </div>
      )}
    </div>
  );
};

export default Physicproduct;
