import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
/*
  format: lets you choose the format of [true, false] you want
 */
const DEFAUT_FORMAT = ['1', '0'];
const Checkbox = ({ className, value, onChange, label, format = DEFAUT_FORMAT }) => {
  const idRef = useRef(`search-input-checkbox-${uuidv4()}`);
  const checked = format.findIndex(option => option === value) === 0;

  const input = (
    <input
      id={idRef.current}
      className={className}
      type="checkbox"
      checked={checked}
      onChange={e => {
        onChange(e.target.checked ? format[0] : format[1]);
      }}
    />
  );

  if (label)
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {input}
        {label && (
          <label htmlFor={idRef.current} style={{ marginLeft: 5, cursor: 'pointer', fontWeight: 'normal' }}>
            {label}
          </label>
        )}
      </div>
    );

  return input;
};

export default Checkbox;
