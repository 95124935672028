import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveSubscription } from '../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE, MODULE_EXPORT_PDF } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import { useViewport } from '../../../../hooks/useViewport';
import { getLang } from '../../../../redux/selectors/preferences';

import './stylesheet.scss';

const LandingPage = () => {
  const activeSubscription = useSelector(getActiveSubscription);
  const { viewportWidth } = useViewport();
  const lang = useSelector(getLang);

  let buttonRsx;
  let buttonUrl;
  if (!activeSubscription) {
    buttonRsx = 'components.lotslists.landingpage.m1';
    buttonUrl = '/subscription';
  } else if (activeSubscription?.modules.includes(MODULE_EXPORT_PDF)) {
    buttonRsx = 'components.lotslists.landingpage.m2';
    buttonUrl = '/search';
  } else {
    buttonRsx = 'components.lotslists.landingpage.m12';
    buttonUrl = '/subscription';
  }

  const isMobile = viewportWidth < 720;
  const createImgUrl = step => `${IMAGE_SOURCE}pdflists/landingpage/step${step}_${isMobile ? 'mobile' : 'desktop'}_${lang}.jpg`;

  return (
    <div className="account-lots-lists-landing-page">
      <div className="header">
        <div className="side left" />
        <div className="side right" />
        <div className="center">
          <h1 className="highlight title">
            <span className="highlight-content">
              <A18n rsx="components.lotslists.landingpage.m3" />
            </span>
            <div className="highlight-color" />
          </h1>
          <div className="presentation">
            <A18n rsx="components.lotslists.landingpage.m4" />
          </div>
          <a href={buttonUrl} className="btn btn-default action" type="button">
            <A18n rsx={buttonRsx} />
          </a>
        </div>
      </div>
      <div className="container content">
        <div className="step">
          <span className="number">1.</span>
          <A18n rsx="components.lotslists.landingpage.m5" />
          <div className="image-container">
            <img src={createImgUrl(1)} alt="step 1" className="image" />
            <div className="border" />
          </div>
        </div>
        <div className="step">
          <div>
            <span className="number">2.</span>
            <A18n rsx="components.lotslists.landingpage.m6" />
          </div>
          <span className="number">3.</span>
          <A18n rsx="components.lotslists.landingpage.m7" />
          <div className="image-container">
            <img src={createImgUrl('2_3')} alt="step 1" className="image" />
            <div className="border" />
          </div>
        </div>
        <div className="step">
          <span className="number">4.</span>
          <A18n rsx="components.lotslists.landingpage.m8" />
          <div className="image-container">
            <img src={createImgUrl(4)} alt="step 1" className="image" />
            <div className="border" />
          </div>
        </div>
        <div className="step">
          <span className="number">5.</span>
          <A18n rsx="components.lotslists.landingpage.m9" />
          <div className="image-container">
            <img src={createImgUrl(5)} alt="step 1" className="image" />
            <div className="border" />
          </div>
        </div>
        <div className="step">
          <span className="number">6.</span>
          <A18n rsx="components.lotslists.landingpage.m10" />
          <div className="image-container">
            <img src={createImgUrl(6)} alt="step 1" className="image" />
            <div className="border" />
          </div>
        </div>

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <em>
            * <A18n rsx="components.lotslists.landingpage.m11" />
          </em>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
