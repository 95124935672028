import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import StripeCheckout from '..';

const ProvidedStripe = ({ locale, idphysicsale }) => (
  <ReduxProvider>
    <StripeCheckout locale={locale} idphysicsale={idphysicsale} />
  </ReduxProvider>
);

export default ProvidedStripe;
