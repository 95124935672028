import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import CookieProvider from '../../../providers/CookieProvider';
import Landingpage from '..';

const WithProvider = ({ lang, data }) => (
  <IntlProvider locale={lang} suppressHydrationWarning>
    <CookieProvider>
      <ReduxProvider>
        <Landingpage data={data} />
      </ReduxProvider>
    </CookieProvider>
  </IntlProvider>
);

export default WithProvider;
