import React from 'react';
import { isEmpty } from 'lodash';
import FormattedPrice from '../../Details/common/FormattedPrice';
import Currencies from '../Currencies';
import './stylesheet.scss';

const Price = ({ classified }) => (
  <div>
    <div className="marketplace-classified-actions-price">
      <div className="classified-details-price-block">
        <div className="classified-details-price-value" style={{ fontSize: 25 }}>
          <FormattedPrice prices={classified.prices} idcurrency={classified.idcurrency} />
        </div>
      </div>
    </div>
    {!isEmpty(classified.prices) && <Currencies suppressHydrationWarning />}
  </div>
);

export default Price;
