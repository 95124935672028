/* eslint-disable global-require */
module.exports = [
  'payments.informations.form.fields.m1',
  'payments.informations.form.fields.m2',
  'payments.informations.form.fields.m3',
  'payments.informations.form.fields.m4',
  'payments.informations.form.fields.m5',
  'payments.informations.form.fields.m6',
  'payments.informations.form.fields.m7',
  'payments.informations.form.fields.m8',
  'payments.informations.form.fields.m9',
  'payments.informations.form.fields.m10',
  'payments.informations.form.fields.m11',
  'payments.informations.form.fields.m12',
  'payments.informations.form.fields.m13',
  ...require('./Phone/i18n'),
  ...require('./Tvaintra/i18n'),
];
