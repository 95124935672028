import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIdartist, getVisibleYears, isVisibleYearsLoading } from '../../../../redux/selectors/indexes/artist';
import { fetchVisibleYearsInCharts } from '../../../../redux/actions/indexes/artist';
import Spinner from '../../../common/spinners/Spinner';

const MaxYearOption = ({ disabled, parentChart, onBlur }) => {
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const visibleYears = useSelector(getVisibleYears);
  const loading = useSelector(isVisibleYearsLoading);

  useEffect(() => {
    dispatch(fetchVisibleYearsInCharts());
  }, [idartist]);

  return (
    <div>
      {visibleYears?.maxYear && loading ? (
        <Spinner />
      ) : (
        <input
          onBlur={e => onBlur({ maxYear: e.target.value })}
          placeholder={i18next.t('indexes._advanced.m9')}
          name="maxYear"
          disabled={disabled}
          className="form-control"
          type="number"
          id={`option-max-year-${parentChart}`}
          min={visibleYears?.minYear}
          max={visibleYears?.maxYear}
        />
      )}
    </div>
  );
};

export default MaxYearOption;
