import React from 'react';

export const colors = ['#404395', '#3866C8', '#0388C8', '#0BABB8', '#73B847', '#EDB409', '#F18441', '#961E1C', '#C91618', '#EE79A1'];

export const renderLabel = properties => {
  const { cx, cy, midAngle, outerRadius, fill, percent, label } = properties;

  if (!percent > 0) {
    return null;
  }

  const RADIAN = Math.PI / 180;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 3) * cos;
  const sy = cy + (outerRadius + 3) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const fontFamily = 'Open Sans';
  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" className="hide-in-mobile" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" className="hide-in-mobile" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} className="hide-in-mobile" fontFamily={fontFamily}>
        {label}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} className="hide-in-mobile" fontFamily={fontFamily}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

export default {
  customTooltip: {
    backgroundColor: '#ccc',
    borderRadius: 5,
    borderColor: '#999',
    fontSize: 12,
    padding: 5,
  },
  customTooltipLabel: {
    color: '#333',
    fontWeight: 'bold',
  },
  customTooltipDesc: {
    color: '#000',
  },
  graphsTitle: {
    // color: '#999',
    fontSize: 18,
    fontStyle: 'italic',
    marginBottom: 0,
  },
  graphsColumnsTitle: {
    // color: '#999',
    fontSize: 18,
    fontStyle: 'italic',
    marginBottom: 15,
  },
  graph: {
    marginBottom: 45,
  },
};
