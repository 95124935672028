import React from 'react';
import { useIntl } from 'react-intl';
import A18n from '../../../../common/A18n';

const Count = ({ count, onClick, outOfBounds, url }) => {
  const intl = useIntl();

  return (
    <div className="count">
      {count > 0 && (
        <>
          {count > 1 && (
            <a href={url} className="no-underline" onClick={onClick}>
              (<A18n rsx={outOfBounds ? 'search.universal.sectionheader.m5' : 'search.universal.sectionheader.m1'} replace={[['%count%', intl.formatNumber(count)]]} />)
            </a>
          )}
          {count === 1 && (
            <a href={url} className="no-underline" onClick={onClick}>
              (<A18n rsx="search.universal.sectionheader.m2" />)
            </a>
          )}
        </>
      )}
      {count === 0 && <A18n rsx="search.universal.sectionheader.m3" />}
    </div>
  );
};

export default Count;
