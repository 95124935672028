import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { SUBSCRIPTION } from '../../common/constants';
import Marketplace from './details/Marketplace';
import RefundInfos from './RefundInfos';
import Data from './details/Data';
import Button from './Button';
import Header from './Header';
import Price from './Price';
import './stylesheet.scss';
import { useViewport } from '../../../../../hooks/useViewport';

const Card = ({ theme, iso3, children }) => {
  const [price, setPrice] = useState(0);
  const [refund, setRefund] = useState(0);
  const { view, filter, withoutImagePriceProduct, requiredIdmodule, quantity } = useSelector(getSettings);
  const [product, setProduct] = useState();
  const [disabled, setDisabled] = useState(false);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    setProduct(view === 'upgrade' ? theme.upgrade : theme.product);
  }, [view, theme]);

  useEffect(() => {
    let innerPrice = 0;

    if (product) {
      if (withoutImagePriceProduct.includes(product.idp)) {
        innerPrice = product.price_without_image[iso3] ? product.price_without_image[iso3] : 0;

        if (innerPrice < product.original_prices_without_image[iso3]) {
          setRefund(product.original_prices_without_image[iso3] - innerPrice);
        } else {
          setRefund(0);
        }
      } else {
        innerPrice = product.price[iso3];

        if (innerPrice < product.original_prices[iso3]) {
          setRefund(product.original_prices[iso3] - product.price[iso3]);
        } else {
          setRefund(0);
        }
      }
    }

    setDisabled(innerPrice === 0 || product.forcedDisable || (requiredIdmodule && !product.module.includes(requiredIdmodule)));
    setPrice(innerPrice);
  }, [product, withoutImagePriceProduct, requiredIdmodule, quantity]);

  if (viewportWidth < 768 && disabled) return null;

  return (
    <>
      {children}
      <div className={classNames('subscriptions-list-cards-card', theme.theme_ref, { disabled })}>
        <Header theme={theme} />
        <div className="infos">
          <Price price={price} iso3={iso3} refund={refund} disabled={disabled} />
          <RefundInfos theme={theme} price={price} iso3={iso3} refund={refund} product={product} disabled={disabled} />
          <Button theme={theme} product={product} disabled={disabled} />
        </div>

        {filter === SUBSCRIPTION && <Data theme={theme} physicproduct={product} />}
        {filter !== SUBSCRIPTION && <Marketplace theme={theme} physicproduct={product} />}
      </div>
    </>
  );
};

export default Card;
