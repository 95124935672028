import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import Form from '..';

const Edit = ({ idstore }) => (
  <ReduxProvider>
    <Form idstore={idstore} />
  </ReduxProvider>
);

export default Edit;
