import React from 'react';
import i18next from 'i18next';
import { MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE, MODULE_EXPORT_PDF } from '../../../../../../../constants/rails';

const Data = ({ theme }) => (
  <ul>
    {theme.module.includes(MODULE_PASTSALE) && <li>{i18next.t('subscriptions.purchase.list.theme.details.data.m3')}</li>}
    {theme.module.includes(MODULE_FUTURESALE) && <li>{i18next.t('subscriptions.purchase.list.theme.details.data.m4')}</li>}
    <li>{i18next.t('subscriptions.purchase.list.theme.details.data.m1')}</li>
    <li className={theme.module.includes(MODULE_IMAGE) || !theme.active ? undefined : 'strike'}>{i18next.t('subscriptions.purchase.list.theme.details.data.m2')}</li>
    {theme.module.includes(MODULE_INDEXES) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m5')}</li>}
    {theme.module.includes(MODULE_INDICATOR) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m6')}</li>}
    {theme.module.includes(MODULE_EXPORT_PDF) && (
      <li className={`lvl-${theme.idtheme}`}>
        {i18next.t('subscriptions.purchase.list.theme.details.data.m8')}
        <i className="marg marg-l-5 fa fa-file-pdf-o" />
      </li>
    )}
    {theme.module.includes(MODULE_STORE) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m7')}</li>}
  </ul>
);

export default Data;
