import React from 'react';
import { Link } from 'react-router-dom';

export const ShortcutLink = ({ text, onClick, to, className }) => (
  <Link onClick={onClick} to={to}>
    <div className={`shortcut ${className}`}>
      <i className="fa fa-circle-o" aria-hidden="true" />
      {text}
    </div>
  </Link>
);

export const Shortcut = ({ text, className }) => (
  <div className={`shortcut ${className}`}>
    <b>
      <i className="fa fa-dot-circle-o" aria-hidden="true" />
      {text}
    </b>
  </div>
);
