/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */

import React, { useEffect, useState, useRef } from 'react';
import BackToTopButton from '../../../../common/BackToTopButton';
import { queryStringToObject } from '../../../../../utils/http';
import Spinner from '../../../../common/spinners/Spinner';
import A18n from '../../../../common/A18n';
import BackButton from '../BackButton';
import Fees from '../Fees';
import './stylesheet.scss';

const faqScroll = id => {
  const e = document.getElementById(`answer-${id}`);

  if (e) {
    window.scroll({
      top: e.getBoundingClientRect().top + window.scrollY - 132, // 132px is the height of the header
      behavior: 'smooth',
    });
  }
};

const Desktop = ({ faq, lang, dataloading, fullFaq }) => {
  const ulRef = useRef();
  const queryStringParams = queryStringToObject(window.location.search);
  const [activeFaq, setActiveFaq] = useState(queryStringParams.active || 'seller');
  const [activeAnswer, setActiveAnswer] = useState(queryStringParams.id ? parseInt(queryStringParams?.id, 10) : null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      faqScroll(activeAnswer);
    });

    if (ulRef?.current) {
      resizeObserver.observe(ulRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ulRef?.current, activeAnswer]);

  useEffect(() => {
    if (activeFaq) {
      faqScroll(activeAnswer);
    }
  }, [dataloading]);

  const handleFaqClick = item => {
    setActiveFaq(item);

    if (faq[item]) {
      faqScroll(faq[item][0].id);

      if (faq[item].length === 1) {
        setActiveAnswer(faq[item][0].id);
      }
    }
  };

  return (
    <>
      <div className="marketplace-how-to-bid-desktop">
        {fullFaq && <BackButton />}
        {dataloading && <Spinner />}
        {!dataloading && (
          <>
            <div className="left">
              <p>
                <A18n rsx="marketplace.howtobid.faq.m2" />
              </p>
              <ul>
                <li className={activeFaq === 'seller' ? 'active' : ''} onClick={() => handleFaqClick('seller')}>
                  <A18n rsx="marketplace.howtobid.faq.m6" />
                </li>
                <li className={activeFaq === 'buyer' ? 'active' : ''} onClick={() => handleFaqClick('buyer')}>
                  <A18n rsx="marketplace.howtobid.faq.m5" />
                </li>
                <li className={activeFaq === 'escrow' ? 'active' : ''} onClick={() => handleFaqClick('escrow')}>
                  <A18n rsx="marketplace.howtobid.faq.m4" />
                </li>
                <li className={activeFaq === 'fees' ? 'active' : ''} onClick={() => handleFaqClick('fees')}>
                  <A18n rsx="marketplace.howtobid.faq.m7" />
                </li>
              </ul>
            </div>
            <div className="right">
              {activeFaq === 'fees' && <Fees lang={lang} />}
              {activeFaq !== 'fees' && (
                <ul ref={ulRef}>
                  {(faq[activeFaq] || []).map(faqItem => (
                    <li id={`answer-${faqItem.id}`} key={faqItem.id} className={activeAnswer === faqItem.id ? 'active' : ''} onClick={() => setActiveAnswer(activeAnswer === faqItem.id ? null : faqItem.id)}>
                      <p className="faq-title">{faqItem.title}</p>
                      <p onClick={e => e.stopPropagation()} className="faq-item" dangerouslySetInnerHTML={{ __html: faqItem.text }} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
      {fullFaq && (
        <div className="back-to-top-container">
          <BackToTopButton top="marketplace-how-to-bid-faq" forceHandleResize={!dataloading && activeFaq} />
        </div>
      )}
    </>
  );
};

export default Desktop;
