/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */

import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { queryStringToObject } from '../../../../utils/http';

const faqScroll = id => {
  const e = document.getElementById(`answer-${id}`);

  if (e) {
    window.scroll({
      top: e.getBoundingClientRect().top + window.scrollY - 62, // 132px is the height of the header
      behavior: 'smooth',
    });
  }
};

const Section = ({ faq, id }) => {
  const ulRef = useRef();
  const queryStringParams = queryStringToObject(window.location.search);

  const initializeActiveAnswer = () => {
    if (queryStringParams.id) return parseInt(queryStringParams?.id, 10);
    if (id) return id;
    return null;
  };

  const [activeAnswer, setActiveAnswer] = useState(initializeActiveAnswer());

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      faqScroll(activeAnswer);
    });

    if (ulRef?.current) {
      resizeObserver.observe(ulRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ulRef?.current, activeAnswer]);

  useEffect(() => {
    if (!isEmpty(faq)) {
      faqScroll(activeAnswer);
    }
  }, [faq]);

  const handleAnswerClick = item => {
    if (item.redirect) {
      window.open(item.redirect, '_blank');
    } else if (faq.items.length > 1) {
      setActiveAnswer(activeAnswer === item.id ? null : item.id);
    }
  };

  return (
    <ul className="faq-mobile-section" ref={ulRef}>
      {faq.items.map(faqItem => (
        <li key={faqItem.id || faqItem.redirect} id={`answer-${faqItem.id}`} className={activeAnswer === faqItem.id ? 'active' : ''} onClick={() => handleAnswerClick(faqItem)}>
          <p className="faq-title">{faqItem.title}</p>
          {activeAnswer === faqItem.id && faqItem.text && <p onClick={e => e.stopPropagation()} className="faq-item" dangerouslySetInnerHTML={{ __html: faqItem.text }} />}
        </li>
      ))}
    </ul>
  );
};

export default Section;
