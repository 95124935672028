/* eslint-disable global-require */
module.exports = [
  'marketplace.searchbars.classifieds.bar.m6',
  'artprice.artistsearch.m21',
  'artprice.artistsearch.m21',
  'artprice.ps.artitems.m11',
  'artprice.ps.artitems.m12',
  'artprice.ps.artitems.m17',
  'artprice.ps.artitems.m18',
  'lots.header_search.m8',
  'lots.header_search.m9',
  'lots.header_search.m14',
  'lots.header_search.m15',
  'artprice.ps.artitems.m13',
  'artprice.ps.artitems.m14',
  'sales.show.m1',
  'sales.show.m2',
  'artprice.ps.artitems.m9',
  'artprice.ps.artitems.m10',
];
