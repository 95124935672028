import React from 'react';
import ProvidedLoggedLink from '../LoggedLink/ProvidedLoggedLink';
import PdfButton from '../PdfButton';

const PdfPanel = ({ loginRequired, slug, reportType, imgUrl, target }) => (
  <>
    <div className="cover">
      <ProvidedLoggedLink slug={slug} reportType={reportType} target={target} loginRequired={loginRequired}>
        <img alt="reportcover" src={imgUrl} />
      </ProvidedLoggedLink>
    </div>
    <div className="link">
      <PdfButton loginRequired={loginRequired} slug={slug} reportType={reportType} target={target} />
    </div>
  </>
);

export default PdfPanel;
