import React from 'react';
import { parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteCount, getIdcustomer, getPushEmail, isLogged } from '../../../../redux/slices/userContext/selectors';
import { updateHideArtistFollowRecommendationsModaleUntil } from '../../../../redux/actions/preferences';
import { getHideArtistFollowRecommendationsModaleUntil } from '../../../../redux/selectors/preferences';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

import { getFollowRecomendationDisplay, updateFollowRecomendationDisplay } from '../../../../redux/slices/artist';

const RecommendationsModale = ({ uuid }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const favoriteCount = useSelector(getFavoriteCount);
  const hideArtistFollowRecommendationsModaleUntil = useSelector(getHideArtistFollowRecommendationsModaleUntil);
  const recommendationModaleUUID = useSelector(getFollowRecomendationDisplay);

  if (recommendationModaleUUID !== uuid || parseISO(hideArtistFollowRecommendationsModaleUntil) > new Date()) return null;

  const handleClose = () => {
    if (idcustomer && !isPushEmail) {
      dispatch(updateHideArtistFollowRecommendationsModaleUntil());
    }

    dispatch(updateFollowRecomendationDisplay(false));
  };

  return (
    <div className="artists-follow-recommandations">
      <div className="bg" onClick={handleClose} />
      <div className="box">
        <div className={`args ${logged ? 'log' : ''}`}>
          <div className="close" onClick={handleClose}>
            <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
          </div>
          {!idcustomer && (
            <div>
              <p>
                <A18n rsx="artists.follow.recommendations-modale.m1" />
              </p>
              <div className="artp-action">
                <a className="artp-btn artp-btn-purple" href="/subscription">
                  <A18n rsx="artists.follow.recommendations-modale.m2" />
                </a>
                <a href="/identity" id="sln_login" className="artp-btn">
                  <A18n rsx="artists.follow.recommendations-modale.m7" />
                </a>
              </div>
            </div>
          )}

          {idcustomer && (
            <div>
              {favoriteCount === 1 && (
                <A18n
                  rsx="artists.follow.recommendations-modale.m8"
                  trustHtml
                  replace={[
                    ['%/a%', '</a>'],
                    ['%a%', `<a href="/myartprice/settings/artists">`],
                  ]}
                />
              )}
              {favoriteCount !== 1 && !isPushEmail && (
                <>
                  <p>
                    <A18n rsx="artists.follow.recommendations-modale.m3" /> <A18n rsx="artists.follow.recommendations-modale.m4" />
                  </p>
                  <div className="artp-action">
                    <a className="artp-btn artp-btn-purple" href="/myartprice/settings/artists">
                      <A18n rsx="artists.follow.recommendations-modale.m5" />
                    </a>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsModale;
