import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../../providers/ReduxProvider';
import LandingPage from '../index';

const ProvidedLandingPage = ({ lang }) => (
  <ReduxProvider>
    <IntlProvider locale={lang}>
      <LandingPage />
    </IntlProvider>
  </ReduxProvider>
);

export default ProvidedLandingPage;
