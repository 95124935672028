import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { set as setUiDictionary } from '../../../../../redux/actions/ui/dictionary';
import { VERTICAL_BAR_CURRENT_ELEMENT_KEY, SHORTCUT_SUMMARY, SHORTCUTS_LOTS, SHORTCUTS_SETTINGS, SHORTCUTS_MARKETPLACE } from '../../../../../constants/myartprice';
import { off as closeDrawer } from '../../../../../redux/actions/ui/switches';
import MyartpriceQuickFilters from './QuickFilters';
import './stylesheet.scss';
import Datas from './Datas';
import Settings from './Settings';
import { get as getUiDictionary } from '../../../../../redux/selectors/ui/dictionary';

const Content = ({ drawerId, children }) => {
  const dispatch = useDispatch();
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  const handleFilterClick = () => {
    // prevent auto scroll to last selected lot
    dispatch(setUiDictionary('returnToIdlot', null));

    if (drawerId) {
      document.body.classList.remove('drawer-opened');
      dispatch(closeDrawer(drawerId));
    }
  };

  const handleSectionClick = element => {
    dispatch(setUiDictionary(VERTICAL_BAR_CURRENT_ELEMENT_KEY, element));
    handleFilterClick();
  };

  return (
    <div className="myartprice-lots-vertical-bar-content">
      {!SHORTCUTS_MARKETPLACE.includes(currentElement) && (
        <>
          <Datas handleSectionClick={handleSectionClick} />
          {!SHORTCUTS_SETTINGS.concat([SHORTCUT_SUMMARY]).includes(currentElement) && (
            <MyartpriceQuickFilters displayArtist displayCategory onFilterClick={handleFilterClick} handleSummaryClick={SHORTCUTS_LOTS.includes(currentElement) ? () => handleSectionClick(SHORTCUT_SUMMARY) : null} />
          )}
          {SHORTCUTS_SETTINGS.includes(currentElement) && <MyartpriceQuickFilters displayArtist handleSummaryClick={() => handleSectionClick(SHORTCUT_SUMMARY)} />}
        </>
      )}
      {children}
      {SHORTCUTS_LOTS.concat(SHORTCUTS_SETTINGS).includes(currentElement) && <Settings drawerId={drawerId} />}
    </div>
  );
};

export default Content;
