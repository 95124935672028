import React from 'react';
import { Container } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';

import CountriesDropdown from './CountriesDropdown';
import DatesDropdown from './DatesDropdown';

import SelectedParam from './SelectedParam';

import { getCountryFilter, getDatesFilter } from '../../../../redux/selectors/events';

import { selectCountryFilterAction, selectDatesFilterAction, selectPageFilterAction } from '../../../../redux/actions/events';
import { formatFns } from '../../../../utils/dates/format';

import './stylesheet.scss';
import { EMPTY_ARRAY } from '../../../../constants/utils';

const DEFAULT_DATES = {
  from: undefined,
  to: undefined,
};

const FiltersBar = ({ chooseCountry, chooseDates, options = EMPTY_ARRAY, loading = false, linkToOtherList, countryFilter, dates = DEFAULT_DATES, resetFilters }) => (
  <>
    <div className="events-filters-bar">
      <Container>
        <div className="flex-container">
          {linkToOtherList && <span className="other-events-list-link">{linkToOtherList}</span>}
          <span>
            {options.length > 0 && !loading && <CountriesDropdown choose={chooseCountry} options={options} selectedValue={countryFilter} />}
            <DatesDropdown choose={chooseDates} dates={dates} />
            {(countryFilter || get(dates, 'from') || get(dates, 'to')) && <i role="presentation" className="reset-btn fa fa-trash-o fa-2x" onClick={() => resetFilters()} />}
          </span>
        </div>
      </Container>
    </div>
    <div className="events-selected-params">
      <Container>
        {countryFilter && (
          <SelectedParam
            label={get(
              options.filter(({ value }) => value === countryFilter),
              '[0].label',
            )}
            onClick={() => chooseCountry(undefined)}
          />
        )}
        {dates && dates.from && <SelectedParam label={`${i18next.t('events.list.filters.dates.from')} : ${formatFns(dates.from, 'P')}`} onClick={() => chooseDates(undefined, dates.to)} />}
        {dates && dates.to && <SelectedParam label={`${i18next.t('events.list.filters.dates.to')}: ${formatFns(dates.to, 'P')}`} onClick={() => chooseDates(dates.from, undefined)} />}
      </Container>
    </div>
  </>
);

function mapStateToProps(state) {
  return {
    countryFilter: getCountryFilter(state),
    dates: getDatesFilter(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { fetchEvents } = ownProps;

  return {
    fetchEvents,
    chooseCountry: idcountry => {
      dispatch(selectCountryFilterAction({ idcountry }));
      fetchEvents();
    },
    chooseDates: (from, to) => {
      dispatch(selectDatesFilterAction({ from, to }));
      fetchEvents();
    },
    resetFilters: () => {
      dispatch(selectCountryFilterAction({ idcountry: undefined }));
      dispatch(selectDatesFilterAction({ from: undefined, to: undefined }));
      dispatch(selectPageFilterAction({ page: 1 }));
      fetchEvents();
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersBar);
