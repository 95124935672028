import { internalAxios } from '../utils/axios/internal';

export const reportMethodology = async ({ siteSlug }) => {
  const response = await internalAxios.get(`/api/wp/report/methodology/${siteSlug}`);
  return response.data;
};

export const cgv = async () => {
  const response = await internalAxios.get(`/api/wp/edito/cgv`);
  return response.data;
};

export const privacyPolicy = async () => {
  const response = await internalAxios.get(`/api/wp/edito/privacy_policy`);
  return response.data;
};

export const marketplaceLegals = async () => {
  const response = await internalAxios.get(`/api/wp/edito/marketplace_legals`);
  return response.data;
};
