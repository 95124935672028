import React from 'react';
import i18next from 'i18next';
import { ANALYTICS_FROM_MY_ARTPRICE, IMAGE_SOURCE } from '../../../../../constants/rails';
import LandingpageLinkForAuctioneer from '../../../../ails/LandingpageLinkForAuctioneer';
import { formatFns } from '../../../../../utils/dates/format';
import Add from '../../../../sales/AddToCalendar';
import './stylesheet.scss';

const Sale = ({ lot }) => (
  <div className="lots-lot-sale">
    <div className="sale-title">{lot.saleTitle && <span className="sale-title">{lot.saleTitle}</span>}</div>

    {lot.saleconditionstatus === 4 && (
      <div className="sale-charity">
        <img src={`${IMAGE_SOURCE}charity-black.svg`} alt={i18next.t('lot.show.access.m5')} /> {i18next.t('lot.show.access.m5')}
      </div>
    )}

    <div className="sale-date">
      {lot.saledatestatus !== 3 && lot.saledatestatus !== 2 && (
        <>
          {lot.saleDtStart} {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
          {lot.saleDtUnlocalized && !lot.isPast && (
            <Add
              icon={{ 'calendar-plus-o': 'middle' }}
              displayButtonLabel={false}
              event={{ title: lot.saleTitle, description: lot.saleUrl, location: `${lot.saleCity}, ${lot.saleCountry}`, startDatetime: lot.saleDtStartUnlocalized, endDatetime: lot.saleDtEndUnlocalized }}
            />
          )}
        </>
      )}
      {lot.saledatestatus === 2 && (
        <>
          {formatFns(new Date(lot.saleDtStart), 'MMMM yyyy')} {lot.saleDtStart !== lot.saleDtEnd && `- ${formatFns(new Date(lot.saleDtStart), 'MMMM yyyy')}`}
        </>
      )}
    </div>

    {lot.saleType === 3 && (
      <i className="sale-warning">
        <i className="fa fa-info-circle" /> {i18next.t('sales.tile.sale.m2')}
      </i>
    )}
    {lot.saleType === 2 && (
      <i className="sale-warning">
        <i className="fa fa-info-circle" /> {i18next.t('sales.tile.sale.m3')}
      </i>
    )}

    {lot.saledatestatus === 1 && (
      <i className="sale-warning">
        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: i18next.t('sale_helper.sale_date_status.m1').replace(' - ', '<br />') }} />
      </i>
    )}
    {lot.saledatestatus === 3 && (
      <i className="sale-warning">
        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: i18next.t('sale_helper.sale_date_status.m2').replace(' - ', '<br />') }} />
      </i>
    )}
    {lot.saledatestatus === 2 && (
      <i className="dispsale-warning">
        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: i18next.t('sales.tile.sale.m4').replace(' - ', '<br />') }} />
      </i>
    )}

    <div className="sale-auctioneer">
      <LandingpageLinkForAuctioneer from={ANALYTICS_FROM_MY_ARTPRICE} idauctioneer={lot.auctioneerId} title={lot.auctioneerName} fallbackTitle />
    </div>

    {(lot.auctionplaceName || lot.address) && lot.saleType !== 2 && (
      <div className="address">
        {lot.auctionplaceName && <div className="auctionplace">{lot.auctionplaceName}</div>}
        {/* eslint-disable-next-line react/no-danger */}
        {lot.address && <div dangerouslySetInnerHTML={{ __html: lot.address }} />}
      </div>
    )}

    {lot.saleType === 2 && <div className="address">{lot.saleCountry}</div>}

    {!lot.isPast && (
      <div className="sale-link">
        <a href={lot.saleUrl}>{i18next.t('lot.show.access.m3')}</a>
      </div>
    )}
  </div>
);

export default Sale;
