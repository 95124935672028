export function isInViewport(selector, offset = 0) {
  const element = document.querySelectorAll(selector)[0];

  if (!element) return false;
  const { top } = element.getBoundingClientRect();
  return top + offset >= 0 && top - offset <= window.innerHeight;
}

export function scrollToElement(ref, options = {}) {
  if (!ref.current) return false;
  ref.current.scrollIntoView({ behavior: 'smooth', ...options });
}
/**
 * Tests provided UserAgent against Known Mobile User Agents
 * @returns {bool} isMobileDevice
 */

export const isMobile = () => /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(window.navigator.userAgent || window.navigator.vendor || window.opera);
