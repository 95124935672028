/* eslint-disable global-require */
module.exports = [
  'auctioneers.subscription.m1',
  'auctioneers.subscription.m2',
  'auctioneers.subscription.m3',
  'auctioneers.subscription.m4',
  'auctioneers.subscription.m5',
  'auctioneers.subscription.m6',
  'auctioneers.subscription.m7',
  'auctioneers.subscription.m8',
  'auctioneers.subscription.m9',
  'auctioneers.subscription.m10',
  'auctioneers.subscription.m11',
  'auctioneers.subscription.m12',
  'auctioneers.subscription.m13',
  'auctioneers.subscription.m14',
  'auctioneers.subscription.m15',
  'auctioneers.subscription.m16',
  'auctioneers.subscription.m17',
  'auctioneers.subscription.m18',
  'auctioneers.subscription.m19',
];
