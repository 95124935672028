import axios from 'axios';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import React, { useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SaleIdauctioneerSelect from '../../../../search/v2/helpers/items/SaleIdauctioneerSelect';
import IdentificationsSelect from '../../../../search/v2/helpers/items/IdentificationsSelect';
import TechniqueSelect from '../../../../search/v2/helpers/items/TechniqueSelect';
import SaletypeSelect from '../../../../search/v2/helpers/items/SaletypeSelect';
import CategorySelect from '../../../../search/v2/helpers/items/CategorySelect';
import CountrySelect from '../../../../search/v2/helpers/items/CountrySelect';
import { AUCTIONEER_SALE_LOTS } from '../../../../../constants/search/scope';
import CreationYear from '../../../../search/v2/helpers/items/CreationYear';
import StateSelect from '../../../../search/v2/helpers/items/StateSelect';
import AdvancedButton from '../../../../search/v2/helpers/AdvancedButton';
import CitySelect from '../../../../search/v2/helpers/items/CitySelect';
import Dimension from '../../../../search/v2/helpers/items/Dimension';
import SaleDate from '../../../../search/v2/helpers/items/SaleDate';
import Keyword from '../../../../search/v2/helpers/items/Keyword';
import { update } from '../../../../../redux/actions/dbEntities';
import Signed from '../../../../search/v2/helpers/items/Signed';
import { queryStringToObject } from '../../../../../utils/http';
import Price from '../../../../search/v2/helpers/items/Price';
import { fetchResults } from '../../../../../services/search';
import Checkbox from '../../../../search/v2/inputs/Checkbox';
import { LOTS } from '../../../../../constants/search/sorts';
import Sold from '../../../../search/v2/helpers/items/Sold';
import Prices from '../../../../search/v2/inputs/Prices';
import Pagination from '../../../../common/Pagination';
import Sort from '../../../../search/v2/helpers/Sort';
import Item from '../../../../search/v2/Search/Item';
import Search from '../../../../search/v2/Search';
import Params from '../../../../search/v2/Params';
import A18n from '../../../../common/A18n';
import Lot from '../../common/Lot';
import './stylesheet.scss';

const List = ({ id }) => {
  const dispatch = useDispatch();
  const refTitle = useRef();
  const searchScope = AUCTIONEER_SALE_LOTS;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ p: 1, sort: 'number_asc', ...queryStringToObject(window.location.search) });

  useDeepCompareEffect(() => {
    setData({});
    setLoading(true);
    fetchResults(params, searchScope, true)
      .then(searchData => {
        const { lots, idlot, artists, auctioneers, categories, countries, lotartistassociationtypes, sales, techniques } = searchData.results;
        // update dataStore with entities
        dispatch(update({ lots, artists, auctioneers, categories, countries, lotartistassociationtypes, sales, techniques }));
        setData({ lots, idlot, page: searchData.page, perPage: searchData.perPage, totalPages: searchData.totalPages, totalCount: searchData.totalCount });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (!axios.isCancel(err)) {
          throw new Error(err);
        }
      });
  }, [params]);

  const handlePaginationClick = p => {
    setParams({ ...params, ...{ p } });
  };

  if (!loading && data?.idlot?.length === 0) return null;

  return (
    <div className="auctioneers-sale-lots-list">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 header" ref={refTitle}>
            <h2>{i18next.t('auctioneers.sale.lots.list.m1')}</h2>
          </div>
        </div>
      </div>

      <div className="container">
        <Search
          autoUpdateUrl
          searchScope={searchScope}
          advancedParams={params}
          onChange={({ searchParams }) => {
            setParams(searchParams);
          }}
          horizontalBar={({ drawerId }) => (
            <>
              <div className="container-filters lots-horizontal-bar">
                <div className="container">
                  <div className="filters-container">
                    <div className="grid-left">
                      <AdvancedButton drawerId={drawerId} />
                    </div>
                    <div className="grid-right">
                      <Sort
                        right
                        options={LOTS}
                        onChange={sortOption => {
                          setParams({
                            ...params,
                            sort: sortOption,
                            p: 1,
                          });
                        }}
                        value={params?.sort}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <Params
                  searchScope={searchScope}
                  onChange={p => {
                    setParams(p);
                  }}
                />
              </div>
            </>
          )}
          drawer={() => (
            <div>
              <IdentificationsSelect />
              <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
              <hr />
              <Keyword />
              <Item name="limit_keywords_on_title">
                <Checkbox label={<A18n rsx="lots.search.form.keyword.m2" />} />
              </Item>
              <Item name="exact_match">
                <Checkbox label={<A18n rsx="lots.search.form.exactmatch.m1" />} />
              </Item>
              <Signed />
              <hr />
              <CategorySelect />
              <TechniqueSelect />
              <hr />
              <CreationYear />
              <hr />
              <Dimension />
              <hr />
              <Item label={<A18n rsx="artprice.lib.psresultctrl.m7" />} name={{ estimation_from: 'from', estimation_to: 'to', estimation_idcurrency: 'currency' }}>
                <Prices />
              </Item>
              <Price />
              <Sold />
              <hr />
              <SaleIdauctioneerSelect />
              <SaletypeSelect />
              <SaleDate />
              <hr />
              <CountrySelect />
              <StateSelect />
              <CitySelect />
            </div>
          )}
        >
          {data.totalCount > data.perPage && (
            <div id="top-pagination" style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination className="pagination" perPage={data.perPage} pagePadding={1} loading={loading} totalCount={data.totalCount} activePage={data.page} onClick={p => handlePaginationClick(p)} />
            </div>
          )}
          {loading && (
            <div className="spinner">
              <i className="fa fa-5x fa-spinner fa-spin" />
            </div>
          )}
          {!loading && data.idlot && (
            <div className="data-container">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="list">
                      {data.idlot.map(idlot => (
                        <Lot key={`lot-${idlot}`} id={idlot} idsale={id} section="list" />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && data.totalCount > data.perPage && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination className="pagination" perPage={data.perPage} pagePadding={1} loading={loading} totalCount={data.totalCount} activePage={data.page} onClick={p => handlePaginationClick(p)} />
            </div>
          )}
        </Search>
      </div>
    </div>
  );
};

export default List;
