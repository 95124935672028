import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getChronologicalData, isChronologicalDataLoading, getChronologicalDisplayedChartTab } from '../../../../redux/selectors/indexes/chronological';
import { getFormatter, getFormatOptions, getBarName } from '../../../../utils/indexes/chronological';
import { recursiveReducer } from '../../../../utils/indexes/common/rechartsTickFormat';
import { TAB_CA, TAB_UNSOLD } from '../../../../constants/indexes/chronological';
import { getCurrency, getLang } from '../../../../redux/selectors/preferences';
import { CustomYAxisLabel } from '../../common/CustomYAxisLabel';
import { LANGUAGES } from '../../../../constants/preferences';
import Spinner from '../../../common/spinners/Spinner';

const ChronologicalBarChart = ({ intl }) => {
  const currency = useSelector(getCurrency);
  const lang = useSelector(getLang);
  const loading = useSelector(isChronologicalDataLoading);
  const data = useSelector(getChronologicalData);
  const tab = useSelector(getChronologicalDisplayedChartTab);

  if (loading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return <div style={{ height: 400 }} />;
  }

  const sortedData = [...data].sort((a, b) => b.value - a.value);
  const numberOfDigitsReduced = recursiveReducer(sortedData[0]?.value);
  const isBillionsAndLangChinese = numberOfDigitsReduced === 9 && lang === LANGUAGES.CHINESE ? 1 : 0;
  const divider = 10 ** (numberOfDigitsReduced - isBillionsAndLangChinese);
  const tickFormatter = numberOfDigitsReduced > 0 ? value => intl.formatNumber(value / divider, getFormatOptions({ tab })) : value => intl.formatNumber(value, getFormatOptions({ tab, currency }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: -35 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickSize={5}
          margin={{ bottom: 5 }}
          tickFormatter={tickFormatter}
          label={numberOfDigitsReduced > 0 ? <CustomYAxisLabel currency={[TAB_CA].includes(tab) ? currency : ''} numberOfDigitsReduced={numberOfDigitsReduced} /> : undefined}
        />
        <Tooltip formatter={getFormatter({ tab, currency })} />
        <Bar name={getBarName(tab)} dataKey="value" fill="rgb(51, 102, 204)" className={tab === TAB_UNSOLD ? '' : 'clickable'} animationDuration={400} animationBegin={35} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(ChronologicalBarChart);
