import 'intersection-observer'; // polyfill
import React, { memo, useEffect } from 'react';

const createObserver = (boxElement, payload, handleIntersect) => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: [0.75, 1],
  };

  const observer = new IntersectionObserver(entries => handleIntersect({ entries, payload }), options);
  observer.observe(boxElement);

  return observer;
};

/*
  selectors is an array of shape { selector:, payload: }
  It's better is the array is immutable and memoized (useMemo, useCallback)
 */
const Observer = ({ selectors, handleIntersect }) => {
  useEffect(() => {
    let observers;
    const handleOnload = () => {
      observers = selectors
        .map(selector => {
          const boxElement = document.querySelector(selector.selector);
          if (boxElement) {
            return createObserver(boxElement, selector.payload, handleIntersect);
          }

          return null;
        })
        .filter(Boolean);
    };

    if (document.readyState === 'complete') {
      handleOnload();
    } else {
      window.addEventListener('load', handleOnload, false);
    }

    return () => {
      if (observers) {
        observers.forEach(observer => observer.disconnect());
      }
      window.removeEventListener('load', handleOnload);
    };
  }, []);

  return null;
};

export default memo(Observer);
