import React from 'react';
import { IMAGE_SOURCE } from '../../../constants/rails';
import '../../common/stylesheets/buttons.scss';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Teams = () => (
  <div className="container homepage-teams block" style={{ backgroundImage: `url('${IMAGE_SOURCE}hp/team.png')` }}>
    <div className="l0">
      <A18n rsx="homepage.teams.m1" />
    </div>
    <div className="l1">
      <a href="" className="artp-btn">
        <A18n rsx="homepage.teams.m2" />
      </a>
    </div>
  </div>
);

export default Teams;
