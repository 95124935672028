import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import List from '..';

const WithReduxProvider = ({ idartist, stores, paginate }) => (
  <ReduxProvider>
    <List idartist={idartist} stores={stores} paginate={paginate} />
  </ReduxProvider>
);

export default WithReduxProvider;
