import React from 'react';
import ReduxProvider from '../../providers/ReduxProvider';
import EventListner from './EventListner';

const Search = () => (
  <ReduxProvider>
    <EventListner />
  </ReduxProvider>
);

export default Search;
