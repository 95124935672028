import React from 'react';
import './stylesheet.scss';
import { ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS, ACCOUNT_CONFIRM_STEP_STATUS_VALID, ACCOUNT_CONFIRM_STEP_STATUS_TODO } from '../../../../../constants/account/confirm';

const StepValue = ({ className, stepNumber, status }) => {
  switch (status) {
    case ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS:
    case ACCOUNT_CONFIRM_STEP_STATUS_TODO:
      return (
        <div className={`${className} in-progress`}>
          {status === ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS && <i className="fa fa-hourglass-half" />}
          {status === ACCOUNT_CONFIRM_STEP_STATUS_TODO && stepNumber}
        </div>
      );
    case ACCOUNT_CONFIRM_STEP_STATUS_VALID:
      return (
        <div className={`${className} valid`}>
          <i className="fa fa-check" />
        </div>
      );
    default:
      return <div className={`${className}`}>{stepNumber}</div>;
  }
};

const Step = ({ stepNumber, status }) => (
  <div className="account-confirm-account-step">
    <StepValue className="box text-center" stepNumber={stepNumber} status={status} />
  </div>
);

export default Step;
