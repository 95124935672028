/* eslint-disable global-require */
module.exports = [
  // Filters Bar
  ...require('./List/FiltersBar/i18n'),
  ...require('./List/Futures/i18n'),
  ...require('./List/Pasts/i18n'),
  ...require('./Show/i18n'),

  'artprice.lib.navigbarartprice.m_home',
  'events.list.common.navigationbar.list',
  'events.list.link.eventweb',
];
