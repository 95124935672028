import React, { createContext } from 'react';
import { noop } from 'lodash';

export const UiContext = createContext({
  hideLeftMenu: false,
  setHideLeftMenu: noop(),
  menuRef: undefined,
  lotsFutureRef: undefined,
  lotsPastRef: undefined,
  marketplaceRef: undefined,
  indexesRef: undefined,
  articlesRef: undefined,
  collaborationsRef: undefined,
  recommendationsRef: undefined,
});

const UiProvider = ({ children, value }) => <UiContext.Provider value={value}>{children}</UiContext.Provider>;
export default UiProvider;
