import React from 'react';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import ReduxProvider from '../../../providers/ReduxProvider';
import PromotionNotify from '..';

const WithProviders = ({ locale }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <PromotionNotify />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

export default WithProviders;
