import i18next from 'i18next';
import { TAB_CA, TAB_SOLD, TAB_UNSOLD, TAB_AVERAGE } from '../../constants/indexes/chronological';
import { getCurrencyFormatOptions, getCurrencyFormatter, getPercentFormatOptions, getPercentFormatter, getSimpleNumberFormatOptions, getSimpleNumberFormatter } from './index';

export function getBarName(tab) {
  switch (tab) {
    case TAB_AVERAGE:
      return 'records';
    case TAB_CA:
      return i18next.t('indexes._first_chart.m3');
    case TAB_SOLD:
      return i18next.t('indexes._first_chart.m4');
    case TAB_UNSOLD:
      return i18next.t('indexes._first_chart.m5');
    default:
      console.error(`Unknown tab : ${tab}`);
      return null;
  }
}

export function getFormatter({ tab, currency }) {
  switch (tab) {
    case TAB_CA:
    case TAB_AVERAGE:
      return getCurrencyFormatter({ currency });
    case TAB_SOLD:
      return getSimpleNumberFormatter();
    case TAB_UNSOLD:
      return getPercentFormatter();
    default:
      console.error(`Unknown tab : ${tab}`);
      return getSimpleNumberFormatter();
  }
}

export function getFormatOptions({ tab, currency }) {
  switch (tab) {
    case TAB_CA:
    case TAB_AVERAGE:
      if (currency) return getCurrencyFormatOptions({ currency });
      return getSimpleNumberFormatOptions();
    case TAB_SOLD:
      return getSimpleNumberFormatOptions();
    case TAB_UNSOLD:
      return getPercentFormatOptions();
    default:
      console.error(`Unknown tab : ${tab}`);
      return getSimpleNumberFormatOptions();
  }
}
