import React from 'react';

import './stylesheet.scss';

const SelectedParam = ({ label, onClick }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a href="#" className="selected-param" onClick={onClick}>
    {label}
    <i className="fa fa-trash-o appear-on-hover" />
    <i className="fa fa-times disappear-on-hover" />
  </a>
);

export default SelectedParam;
