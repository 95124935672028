import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const ShapeSelect = () => (
  <Item label={i18next.t('marketplace.searchbars.classifieds.bar.shapes.m1')} name="shape">
    <Select facet="shape" placeholder={i18next.t('marketplace.searchbars.classifieds.bar.shapes.m2')} />
  </Item>
);

export default ShapeSelect;
