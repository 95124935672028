import React from 'react';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const NoResults = ({ messageRsxKey, linkRsxKey, href }) => (
  <div id="no-results">
    <p className="text-center font font-16">
      <A18n rsx={messageRsxKey} />
    </p>
    <p className="text-center font font-16">
      <a href={href}>
        <A18n rsx={linkRsxKey} />
      </a>
    </p>
  </div>
);

export default NoResults;
