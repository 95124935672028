import i18next from 'i18next';
import { isUndefined } from 'lodash';
import { IntlProvider } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { internalAxios } from '../../../../utils/axios/internal';
import { localeFromUrl, queryStringToObject } from '../../../../utils/http';
import './stylesheet.scss';
import Tab from './Tab';

const GlobalSearchTabs = ({ activeTab, lotsCount, artistsCount, reportsCount, amisCount, lotsOutOfBound, artistsOutOfBound, classifiedsCount, artmarketinsightsOutOfBound, reportsOutOfBound, classifiedsOutOfBound }) => {
  const urlParams = queryStringToObject(window.location.search);

  const [data, setData] = useState({
    artists: { count: artistsCount || undefined, outOfBounds: artistsOutOfBound || false, loading: false },
    lots: { count: lotsCount || undefined, outOfBounds: lotsOutOfBound || false, loading: false },
    artmarketinsights: { count: amisCount || undefined, outOfBounds: artmarketinsightsOutOfBound || false, loading: false },
    reports: { count: reportsCount || undefined, outOfBounds: reportsOutOfBound || false, loading: false },
    classifieds: { count: classifiedsCount || undefined, outOfBounds: classifiedsOutOfBound || false, loading: false },
  });

  const getSectionCount = ({ section }) => {
    if (isUndefined(data[section].count)) {
      setData(prevValue => ({ ...prevValue, ...{ [section]: { loading: true } } }));
      internalAxios.get(`/api/es/${section}/search_total_count`, { params: { keyword: urlParams.keyword } }).then(response => {
        setData(prevValue => ({ ...prevValue, ...{ [section]: { count: response.data.total_count, outOfBounds: response.data.out_of_bound, loading: false } } }));
      });
    }
  };

  useEffect(() => {
    if (!urlParams?.keyword) return;

    ['lots', 'reports', 'artists', 'artmarketinsights', 'classifieds'].forEach(section => {
      if (isUndefined(data[section].count)) {
        getSectionCount({ section });
      }
    });
  }, [urlParams?.keyword]);

  return (
    <IntlProvider locale={localeFromUrl()}>
      <div className="search-common-global-search-tabs">
        <div className="container">
          <div className="tabs">
            <Tab
              count={data.artists.count}
              active={activeTab === 0}
              label={i18next.t('artistes')}
              url={`/artists/search?keyword=${urlParams.keyword}`}
              outOfBOund={data.artists.outOfBounds}
              loading={data.artists.loading}
            />
            <Tab count={data.lots.count} active={activeTab === 1} label={i18next.t('oeuvres')} url={`/lots/search?keyword=${urlParams.keyword}`} outOfBOund={data.lots.outOfBounds} loading={data.lots.loading} />
            <Tab
              count={data.classifieds.count}
              active={activeTab === 4}
              label="Marketplace"
              url={`/${i18next.t('routes.marketplace')}?sort=best_match&terms=${urlParams.keyword}`}
              outOfBOund={data.classifieds.outOfBounds}
              loading={data.classifieds.loading}
            />
            <Tab
              count={data.reports.count}
              active={activeTab === 2}
              label={i18next.t('search.index.m9')}
              url={`/reports/search?keyword=${urlParams.keyword}`}
              outOfBOund={data.reports.outOfBounds}
              loading={data.reports.loading}
            />
            <Tab
              count={data.artmarketinsights.count}
              active={activeTab === 3}
              label={i18next.t('search.universal.header.m4')}
              url={`/artmarketinsights/search?keyword=${urlParams.keyword}`}
              outOfBOund={data.artmarketinsights.outOfBounds}
              loading={data.artmarketinsights.loading}
            />
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

export default GlobalSearchTabs;
