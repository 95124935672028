import i18next from 'i18next';
import React, { useState } from 'react';
import IdentificationsSelect from '../../../search/v2/helpers/items/IdentificationsSelect';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../../utils/http';
import TechniqueSelect from '../../../search/v2/helpers/items/TechniqueSelect';
import CategorySelect from '../../../search/v2/helpers/items/CategorySelect';
import SaletypeSelect from '../../../search/v2/helpers/items/SaletypeSelect';
import CountrySelect from '../../../search/v2/helpers/items/CountrySelect';
import ArtistSelect from '../../../search/v2/helpers/items/ArtistSelect';
import Reproduction from '../../../search/v2/helpers/items/Reproduction';
import CreationYear from '../../../search/v2/helpers/items/CreationYear';
import AdvancedButton from '../../../search/v2/helpers/AdvancedButton';
import StateSelect from '../../../search/v2/helpers/items/StateSelect';
import CitySelect from '../../../search/v2/helpers/items/CitySelect';
import Indicator from '../../../search/v2/helpers/items/Indicator';
import Dimension from '../../../search/v2/helpers/items/Dimension';
import Preferences from '../../../search/v2/helpers/Preferences';
import SaleDate from '../../../search/v2/helpers/items/SaleDate';
import Signed from '../../../search/v2/helpers/items/Signed';
import LoadingOverlay from '../../../common/LoadingOverlay';
import Price from '../../../search/v2/helpers/items/Price';
import { LOTS } from '../../../../constants/search/sorts';
import Checkbox from '../../../search/v2/inputs/Checkbox';
import Sold from '../../../search/v2/helpers/items/Sold';
import Prices from '../../../search/v2/inputs/Prices';
import Sort from '../../../search/v2/helpers/Sort';
import Item from '../../../search/v2/Search/Item';
import Search from '../../../search/v2/Search';
import Params from '../../../search/v2/Params';
import A18n from '../../../common/A18n';
import Count from '../common/Count';
import '../stylesheet.scss';

const SearchBar = ({ searchScope, hiddenFields, baseParams }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [searching, setSearching] = useState(false);

  const handleChange = ({ params }) => {
    setSearching(true);

    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...{ sort }, ...baseParams, ...params, p: 1 })}`;
  };

  return (
    <>
      <Search
        searchScope={searchScope}
        baseParams={baseParams}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange({ params: searchParams });
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId, searchParams }) => (
          <>
            <div className="container-filters lots-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <div className="grid-left">
                    <AdvancedButton drawerId={drawerId} />
                  </div>
                  <div className="grid-right">
                    <Sort right options={LOTS} onChange={sortOption => handleChange({ params: { ...searchParams, sort: sortOption } })} value={urlParams?.sort} />
                    <Preferences withOptionalCurrencies right rsx={{ title: false }} onChange={() => handleChange({ params: searchParams })} hiddenFields={{ artistsViewFormat: true }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <Params hiddenParams={{ keyword: true }} searchScope={searchScope} onChange={p => handleChange({ params: p })} />
            </div>
          </>
        )}
        drawer={() => (
          <div>
            <ArtistSelect />
            <IdentificationsSelect />
            <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
            <hr />
            <Item label={i18next.t('search.helpers.items.keyword.select.m1')} name="keyword">
              <span style={{ fontWeight: 'bold', textTransform: 'uppercase', fontStyle: 'italic' }}>{baseParams.keyword}</span>
            </Item>
            <Item name="limit_keywords_on_title">
              <Checkbox label={<A18n rsx="lots.search.form.keyword.m2" />} />
            </Item>
            <Item name="exact_match">
              <Checkbox label={<A18n rsx="lots.search.form.exactmatch.m1" />} />
            </Item>
            <Reproduction />
            <Signed />
            <hr />
            <CategorySelect />
            <TechniqueSelect />
            <hr />
            <CreationYear />
            <hr />
            <Dimension />
            <hr />
            {!hiddenFields?.estimation && (
              <Item label={<A18n rsx="artprice.lib.psresultctrl.m7" />} name={{ estimation_from: 'from', estimation_to: 'to', estimation_idcurrency: 'currency' }}>
                <Prices withOptionalCurrencies />
              </Item>
            )}
            {!hiddenFields?.price && (
              <>
                <Price withOptionalCurrencies />
                <Sold />
              </>
            )}
            <Indicator />
            <hr />
            <SaletypeSelect />
            <SaleDate />
            <Item name="only_in_future_sales">
              <Checkbox label={<A18n rsx="lots.search.filters.inputs.saledate.m1" />} />
            </Item>
            <hr />
            <CountrySelect />
            <StateSelect />
            <CitySelect />
          </div>
        )}
      />
      <LoadingOverlay visible={searching} />

      <Count searchScope={searchScope} />
    </>
  );
};

export default SearchBar;
