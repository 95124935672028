import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getCurrentCount } from '../../../../redux/selectors/search/info';
import CommonHorizontalBar from '../../../common/HorizontalBar';
import Drawer from '../../drawers/Lot';
import FilterMobile from './Filters/Mobile';

const HorizontalBar = React.forwardRef(({ withOptionalCurrencies, style, resetIfNecessary, validateParams, className, hideTotalCount = false, currentScope, infiniteScroll, redirect, otherProps }, containerRef) => {
  const totalCount = useSelector(getCurrentCount);
  const drawerId = 'myartprice-lots-filter-advanced';

  return (
    <>
      <CommonHorizontalBar className={className} ref={containerRef} style={{ ...style }} {...otherProps}>
        <FilterMobile currentScope={currentScope} infiniteScroll={infiniteScroll} redirect={redirect} />
        <Drawer withOptionalCurrencies={withOptionalCurrencies} id={drawerId} resetIfNecessary={resetIfNecessary} validateParams={validateParams} />
      </CommonHorizontalBar>

      {!hideTotalCount && (
        <div className="container" style={{ marginBottom: 15, marginTop: 5 }}>
          <div className="row">
            <div className="col-xs-12 small text-center" style={{ color: '#999' }}>
              {totalCount && i18next.t('marketplace.home.index.m4').replace('%count%', totalCount)}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default HorizontalBar;
