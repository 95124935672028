import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const SizeSelect = () => (
  <Item label={i18next.t('marketplace.searchbars.classifieds.bar.sizes.m1')} name="size">
    <Select facet="size" placeholder={i18next.t('marketplace.searchbars.classifieds.bar.sizes.m2')} />
  </Item>
);

export default SizeSelect;
