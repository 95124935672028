import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import AddToListButton from '../index';

const ProvidedAddToListButton = ({ ids, newSelectionKey }) => (
  <ReduxProvider>
    <AddToListButton ids={ids} newSelectionKey={newSelectionKey} />
  </ReduxProvider>
);

export default ProvidedAddToListButton;
