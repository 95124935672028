import React from 'react';
import { func, string } from 'prop-types';
import './stylesheets.scss';
import i18next from 'i18next';

const Alert = ({ message, onClick }) => (
  <div className="components-common-alert" onClick={onClick}>
    <div className="message">
      {message}
      <div className="button">
        <button type="button" onClick={onClick}>
          {i18next.t('search.inputs.date.popup.m3')}
        </button>
      </div>
    </div>
  </div>
);

Alert.propTypes = {
  onClick: func.isRequired,
  message: string.isRequired,
};

export default Alert;
