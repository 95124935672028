import React from 'react';
import { formatFns } from '../../../../../utils/dates/format';

const CustomTick = props => {
  const { width, height, x, y, payload = {} } = props;
  const { value, isShow } = payload;

  if (!isShow) {
    return null;
  }

  let dt1 = value;
  let dt2 = '';
  try {
    dt1 = formatFns(value, 'dd MMM');
    dt2 = formatFns(value, 'yyyy');
  } catch (e) {
    console.error(e);
  }

  return (
    <text textAnchor="middle" width={width} height={height} x={x} y={y} stroke="none" fill="#666">
      <tspan x={x} dy="1.2em">
        {dt1}
      </tspan>
      <tspan x={x} dy="1.2em">
        {dt2}
      </tspan>
    </text>
  );
};

export default CustomTick;
