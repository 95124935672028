import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_SECTION_FUTURE_SALES_DRAWER,
  ANALYTICS_SECTION_PAST_SALES_DRAWER,
  ANALYTICS_FROM_ARTIST,
  ID_FREE_ARTIST,
  MODULE_FUTURESALE,
  MODULE_PASTSALE,
  RETURN_AFTER_LOGIN_COOKIE_KEY,
} from '../../../../constants/rails';
import { getArtist, getCategories, getMenuEntriesCount } from '../../../../redux/slices/artist';
import { hasModuleAccess, isLogged } from '../../../../redux/slices/userContext/selectors';
import { getArtistLotsSectionOrder } from '../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { set as setCookie } from '../../../../utils/cookie';
import { on } from '../../../../redux/actions/ui/switches';
import { useOverlap } from '../../../../hooks/useOverlap';
import { UiContext } from '../common/UiProvider';
import NoAccessDrawer from './NoAccessDrawer';
import HighlightOn from './HighlightOn';
import Future from './Future';
import Past from './Past';

const ID_DRAWER = 'id_drawer_lot_past';

const Lots = () => {
  const subscriptionsAdRef = useRef();
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const artist = useSelector(getArtist);
  const hasPastSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const hasFutureSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_FUTURESALE }));
  const categories = useSelector(getCategories);
  const menuEntriesCount = useSelector(getMenuEntriesCount);
  const artistLotsSectionOrder = useSelector(getArtistLotsSectionOrder);
  const [lotsPastCount, setLotsPastCount] = useState();
  const [lotsFutureCount, setLotsFutureCount] = useState();
  const [drawerSection, setDrawerSection] = useState();
  const [style, setStyle] = useState({ marginBottom: 0 });
  const { hideLeftMenu, menuRef } = useContext(UiContext);
  const [pastSalesStyle, setPastSalesStyle] = useState({ marginBottom: 40 });
  const [futureSalesStyle, setFutureSalesStyle] = useState({ marginBottom: 60 });

  useOverlap({ className: 'overlapped', overlappingElementRef: menuRef, overlappedElementRef: subscriptionsAdRef });

  useEffect(() => {
    if (artistLotsSectionOrder === 1) {
      setFutureSalesStyle({ marginBottom: 60 });
      setPastSalesStyle({ marginBottom: 40 });
    } else {
      setPastSalesStyle({ marginBottom: 60 });
      setFutureSalesStyle({ marginBottom: 40 });
    }
  }, [artistLotsSectionOrder]);

  // On affiche pas le lien vers l'estimation si il n'y a pas assez de lot
  useEffect(() => {
    setLotsPastCount(categories.past.reduce((nb, c) => nb + c.count, 0));
    setLotsFutureCount(categories.future.reduce((nb, c) => nb + c.count, 0));
  }, [categories]);

  // On fixe la hauteur minimum du block pour le cas ou il y a tout le menu complut mais aucune images (artistes BL)
  useEffect(() => {
    if (menuEntriesCount > 3) setStyle({ minHeight: 550 });
    if (menuEntriesCount <= 3) setStyle({ marginBottom: 'auto' });
  }, [menuEntriesCount]);

  const handleOpenDrawer = ({ section, e, op, period, lot }) => {
    let eventBlocked = false;
    let access = true;
    if (artist.id !== ID_FREE_ARTIST) {
      access = period === 'past' ? hasPastSaleAccess : hasFutureSaleAccess;
    }

    if (artist.id !== ID_FREE_ARTIST && !lot?.ail) {
      if (access && !logged) {
        e.stopPropagation();
        e.preventDefault();
        const aParent = e.target.closest('a');
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op, section, idartist: artist.id });
        setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, aParent ? aParent.href : window.location.href);

        window.location = '/identity/sales';
        eventBlocked = true;
      } else if (!access || !logged) {
        aapiBeacon('dom_view', { from: ANALYTICS_FROM_ARTIST, section: period === 'past' ? ANALYTICS_SECTION_PAST_SALES_DRAWER : ANALYTICS_SECTION_FUTURE_SALES_DRAWER, idartist: artist.id });

        setDrawerSection(period);
        e.stopPropagation();
        e.preventDefault();
        dispatch(on(ID_DRAWER));

        eventBlocked = true;
      }
    }

    if (!eventBlocked) {
      aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op, section, idartist: artist.id });
    }
  };

  return (
    <div className={`artists-artist-lots ${hideLeftMenu ? 'block-no-menu' : 'block first'}`} style={style}>
      <NoAccessDrawer drawerId={ID_DRAWER} section={drawerSection} lotCount={drawerSection === 'past' ? lotsPastCount : lotsFutureCount} />

      <div className={`artists-artist-lots-grid artists-artist-lots-grid-${artistLotsSectionOrder}`}>
        <Future onOpenDrawer={handleOpenDrawer} style={futureSalesStyle} />
        <HighlightOn />
        {/* <SubscriptionsAdBanner /> */}
        <Past onOpenDrawer={handleOpenDrawer} style={pastSalesStyle} />
      </div>
    </div>
  );
};

export default Lots;
