import React from 'react';
import { IntlProvider } from 'react-intl';
import Fees from '../index';

const ProvidedFees = ({ locale }) => (
  <IntlProvider locale={locale}>
    <Fees lang={locale} />
  </IntlProvider>
);

export default ProvidedFees;
