import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetch } from '../../../redux/actions/auctioneers/sales';
import Header from './Header';
import Top5 from './lots/Top5';
import List from './lots/List';
import Geo from './Geo';
import { getCurrentQueryObject } from '../../../utils/http';

const Sale = () => {
  const { id } = getCurrentQueryObject();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetch({ id }));
  }, [id]);

  return (
    <>
      <Header key={`header-${id}`} id={id} />
      <Top5 key={`top5-${id}`} id={id} />
      <Geo key={`geo-${id}`} id={id} />
      <List key={`list-${id}`} id={id} />
    </>
  );
};

export default Sale;
