import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isPlainObject } from 'lodash';
import { clear, setAddAndCreateButtonsVisibility } from '../../../../../../redux/slices/account/lotslists';
import '../../../stylesheet-common.scss';
import Creation from './Creation';
import Success from './Success';
import { EMPTY_OBJECT } from '../../../../../../constants/utils';
import Failure from './Failure';

const Content = ({ setVisibility }) => {
  const dispatch = useDispatch();
  const [level, setLevel] = useState(1);
  const [error, setError] = useState(EMPTY_OBJECT);

  const onAfterCreation = async ({ error: _error, errorDetails } = {}) => {
    if (_error) {
      setError({ error: _error, errorDetails });
      setLevel(2);
    } else {
      dispatch(clear());
      setLevel(2);
    }
  };

  const handleClose = () => {
    dispatch(setAddAndCreateButtonsVisibility(false));
    setVisibility(false);
  };

  if (isPlainObject(error) && !isEmpty(error)) return <Failure error={error.error} errorDetails={error.errorDetails} onClose={handleClose} />;
  if (level === 1) return <Creation setVisibility={setVisibility} onSubmit={onAfterCreation} />;

  return <Success onClose={handleClose} />;
};

export default Content;
