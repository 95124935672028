import React from 'react';

const CloseSvg = ({ className }) => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} viewBox="0 0 64 64" xmlSpace="preserve">
    <g>
      <g>
        <g id="cross">
          <g>
            <polygon points="41.191,24.222 39.777,22.808 32,30.586 24.222,22.808 22.808,24.222 30.586,32 22.808,39.777 24.222,41.191 32,33.414 39.777,41.191 41.191,39.777 33.414,32" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseSvg;
