import React from 'react';
import { useSelector } from 'react-redux';
import { getStore } from '../../../redux/selectors/dbEntities';
import './stylesheet.scss';
import StoreFollow from '../../marketplace/stores/Follow';

const StoreTile = ({ id }) => {
  const store = useSelector(state => getStore(state, { id }));
  const imgUrl = store !== undefined && store.images.length ? store.images[0].image_large_url : '/no_picture.svg';
  const favorite = true;

  return (
    <div className="col-xs-12 col-sm-6 col-lg-4 store-tile">
      <StoreFollow idstore={id} favorite={favorite} size="lg" noLabel />
      <div className="header">
        <h2>
          <a href={store.storePath}>{store.sitename}</a>
        </h2>
      </div>
      <div className="body">
        <a href={store.storePath}>
          <img src={imgUrl} alt="" style={{ maxWidth: '200px' }} />
        </a>
      </div>
      <div className="footer">
        {store.contact.country} {(store.contact.country && store.contact.city && ', ') || <br />} {store.contact.city}
      </div>
    </div>
  );
};

export default StoreTile;
