import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import LotSimilar from '..';

const ProvidedLotSimilar = ({ idlot, idartist }) => (
  <ReduxProvider>
    <LotSimilar idartist={idartist} idlot={idlot} />
  </ReduxProvider>
);

export default ProvidedLotSimilar;
