import { useEffect, useState } from 'react';
import useWideColumnsCount from './useWideColumnsCount';
import { useViewport } from '../../../../hooks/useViewport';

export default function useWideHeight({ data, loading }) {
  const columnCount = useWideColumnsCount();
  const [height, setHeight] = useState(0);
  const { viewportWidth } = useViewport();

  const [blockHeight, setBlockHeight] = useState(0);

  useEffect(() => {
    if (viewportWidth >= 768) {
      setBlockHeight(250);
    } else {
      setBlockHeight(195);
    }
  }, [viewportWidth]);

  useEffect(() => {
    const headerHeight = 125;

    if (loading) {
      setHeight(blockHeight + headerHeight);
    } else if (data) {
      if (data.length <= 0) {
        setHeight(headerHeight);
      } else {
        const lineCount = Math.ceil(data.length / columnCount);
        setHeight(lineCount * blockHeight + headerHeight);
      }
    }
  }, [data?.length, loading, columnCount]);

  return height;
}
