import { useEffect, useState } from 'react';
import { useViewport } from '../../../../hooks/useViewport';
import { MAIN_VIEWPORT_DESKTOP_WIDTH } from '../../../../constants/rails';

export default function useSquareColumnsCount() {
  const [columnCount, setColumnCount] = useState(6);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (viewportWidth >= 1200) {
      setColumnCount(6);
    } else if (viewportWidth >= 992) {
      setColumnCount(4);
    } else if (viewportWidth >= 600) {
      setColumnCount(3);
    } else if (viewportWidth >= MAIN_VIEWPORT_DESKTOP_WIDTH) {
      setColumnCount(2);
    } else {
      setColumnCount(3); // en fait en dessous de MAIN_VIEWPORT_DESKTOP_WIDTH c'est le nombre de ligne
    }
  }, [viewportWidth]);

  return columnCount;
}
