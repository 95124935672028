import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../providers/ReduxProvider';
import CookieProvider from '../../providers/CookieProvider';
import HomePage from '..';

const WithReduxProvider = ({ lang }) => (
  <IntlProvider locale={lang}>
    <CookieProvider>
      <ReduxProvider>
        <HomePage />
      </ReduxProvider>
    </CookieProvider>
  </IntlProvider>
);

export default WithReduxProvider;
