import React from 'react';
import { useSelector } from 'react-redux';
import { getLot } from '../../../../redux/selectors/dbEntities';

export const LotImg = ({ idlot }) => {
  const lot = useSelector(state => getLot(state, { id: idlot }));
  if (!lot) {
    return null;
  }

  return (
    <div className="lot-img">
      <a href={lot.url}>
        {!lot.imageUrls.sml[0] && (
          <div className="no-pict">
            <i className="fa fa-picture-o fa-3x" />
          </div>
        )}
        {lot.imageUrls.sml[0] && <img alt="" className="shadow" src={lot.imageUrls.sml[0].replace('/sml', '/xl')} />}
      </a>
    </div>
  );
};
