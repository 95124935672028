import { get } from 'lodash';
import { internalAxios } from '../../utils/axios/internal';

export const documenttypeForForms = async () => {
  const response = await internalAxios.get('/api/wallet/customerverificationdocumenttype/documenttype_for_forms');
  return get(response, 'data.items', []);
};

export const documenttypeForFormPrivate = async () => {
  const response = await internalAxios.get('/api/wallet/customerverificationdocumenttype/documenttype_for_forms?idcustomerverificationdocumentsection=2');
  return get(response, 'data.items', []);
};

export const documenttypeForFormPro = async () => {
  const response = await internalAxios.get('/api/wallet/customerverificationdocumenttype/documenttype_for_forms?idcustomerverificationdocumentsection=1');
  return get(response, 'data.items', []);
};
