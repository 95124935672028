import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isGeoLoading, geo } from '../../../../redux/selectors/auctioneers/sales';
import { fetchSaleGeo } from '../../../../redux/actions/auctioneers/sales';
import Map from '../../../common/graphs/Map';
import Pie from '../../../common/graphs/Pie';
import './stylesheet.scss';

const Geo = ({ id }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const data = useSelector(state => geo(state, { id }));
  const loading = useSelector(state => isGeoLoading(state, { id }));

  useEffect(() => {
    dispatch(fetchSaleGeo({ id }));
  }, []);

  if (!loading && data.length === 0) return null;

  return (
    <div className="auctioneers-sale-geo">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2>
              {i18next.t('auctioneers.sale.geo.m1')}
              <div className="controls pull-right">
                <div className="btn-group" role="group" aria-label="...">
                  <button type="button" className={`btn btn-${view === 0 ? 'primary active' : 'default'}`} onClick={() => setView(0)}>
                    <i className="fa fa-globe" />
                  </button>
                  <button type="button" className={`btn btn-${view === 1 ? 'primary active' : 'default'}`} onClick={() => setView(1)}>
                    <i className="fa fa-pie-chart" />
                  </button>
                </div>
              </div>
            </h2>
            <div className="map">
              {loading && (
                <div className="spinner">
                  <i className="fa fa-5x fa-spinner fa-spin" />
                </div>
              )}
              {!loading && view === 0 && <Map data={data} />}
              {!loading && view === 1 && <Pie data={data} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Geo;
