import i18next from 'i18next';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import customStyles from '../../../../utils/styles/reactSelectStyle';
import { allWalletCountries } from '../../../../services/wallet/country';
import { useViewport } from '../../../../hooks/useViewport';

const CountryDropdown = ({ name, control, readOnly, onChange, rules, width, placeholder }) => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    allWalletCountries().then(datas => {
      setCountries(datas);
      setIsLoading(false);
    });
  }, []);

  const handleChange = (country, onControllerChange) => {
    onControllerChange(country.value);

    if (onChange) {
      onChange(country);
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange: onControllerChange, value } }) => (
        <Select
          id={name}
          classNamePrefix="react-select"
          styles={customStyles({ viewportWidth, width })}
          options={countries}
          readOnly={isLoading}
          isLoading={isLoading}
          isSearchable
          isDisabled={readOnly}
          placeholder={placeholder || i18next.t('components.account.verify.identity.m4')}
          loadingMessage={() => i18next.t('components.account.verify.identity.m5')}
          value={countries.find(country => country.value === value)}
          onChange={v => handleChange(v, onControllerChange)}
        />
      )}
    />
  );
};

export default CountryDropdown;
