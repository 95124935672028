import React from 'react';
import i18Next from 'i18next';
import { isPlainObject } from 'lodash';
import { LOTSLISTS_MAXLISTS } from '../../../../../constants/rails';

const ListInput = ({ lists, onChange }) => {
  if (isPlainObject(lists) && Object.keys(lists).length >= LOTSLISTS_MAXLISTS) {
    return (
      <p style={{ color: '#900' }}>
        <i className="fa fa-warning" /> {i18Next.t('components.lotslists.error.m1').replace('%count%', LOTSLISTS_MAXLISTS)}
      </p>
    );
  }

  return <input onChange={onChange} placeholder={i18Next.t('components.lotslists.create.m4')} />;
};

export default ListInput;
