import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';

import './stylesheet.scss';

const Login = () => (
  <a className="e2e-login-btn artp-Header-Account-Login artp-Header-item artp-Header-top-item" href="/identity">
    <img src={`${IMAGE_SOURCE}menu/bonhomme-white.svg`} alt="silhouette" />
    <span>
      <A18n rsx="layouts.header.m3" />
    </span>
  </a>
);

export default Login;
