import React from 'react';
import i18next from 'i18next';
import Select from '../../common/FacetsSelect';

const component = ({ className }) => (
  <div className="artp-input-group">
    <span className="artp-input-label">{i18next.t('marketplace.searchbars.common.professionaltypes.m2')}</span>
    <Select isMulti className={className} placeholder={i18next.t('marketplace.searchbars.common.professionaltypes.m1')} facetName="idprofessionaltype" />
  </div>
);

export default component;
