import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import SelectLot from '../index';

const ProvidedSelectLot = ({ id }) => (
  <ReduxProvider>
    <SelectLot id={id} />
  </ReduxProvider>
);

export default ProvidedSelectLot;
