import React from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ArtistComponent from '../../../common/dbEntitiesSmallViews/Artist';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { CURRENCIES } from '../../../../constants/preferences';
import { LotImg } from '../LotImg';

const ARTISTS_WITH_PICTOS = [11225, 19177, 22796, 24376];

const PictoImg = ({ id }) => (
  <div className="picto-img">
    <img alt="" className="shadow" src={`https://imgpublic.artprice.com/img/artists/${id}.gif`} />
  </div>
);

const Artist = ({ id, value, idlot, unsoldRate, count, intl }) => {
  const currency = useSelector(getCurrency);

  return (
    <div className="total-access-artist total-access-common-list">
      <div className="image-container">{ARTISTS_WITH_PICTOS.includes(id) ? <PictoImg id={id} /> : <LotImg idlot={idlot} />}</div>
      <div className="info-container">
        <div className="title">
          <a className="artdb-link" href={`http://artdb/artdb/artist/show/${id}`} target="_blank" rel="noopener noreferrer" title="Artdb">
            <i className="fa fa-external-link" />
          </a>
          <ArtistComponent id={id} withLink withDates={false} />
        </div>
        <div className="info-rows">
          <div className="centering-div">
            <div className="info-row">
              <div>{i18next.t('indexes._advanced.m1')}</div>
              <div>{i18next.t('artprice.mystore.default.m21')}</div>
              <div>{i18next.t('indexes._second_chart.m6')}</div>
            </div>
            <div className="info-row bold">
              <div>{intl.formatNumber(value, { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
              <div>{count}</div>
              <div>{intl.formatNumber(unsoldRate, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Artist);
