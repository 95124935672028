import React from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_REGISTRATION, ARTISTS_OFFICIAL_COUNT, AUCTIONEERS_OFFICIAL_COUNT, IMAGE_SOURCE, LOTS_OFFICIAL_COUNT, LOTS_OFFICIAL_YEAR_COUNT } from '../../../../../constants/rails';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getIdcustomer } from '../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { STORE, SUBSCRIPTION } from '../../common/constants';
import './stylesheet.scss';

const Registration = ({ intl }) => {
  const idcustomer = useSelector(getIdcustomer);
  const { newcomer, filter, from } = useSelector(getSettings);

  const handleRegistration = () => {
    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TO_REGISTRATION });
    window.location = '/account/registration-free';
  };

  return (
    <div className={`subscriptions-free-services-registration ${newcomer ? 'newcomer' : ''}`} style={((idcustomer || newcomer) && {}) || { marginBottom: 30 }}>
      <div className="container">
        <div className="block">
          <div className="cell cell-arg-0">
            <div className="block-1">
              <div className="icon">
                <img alt="free-registration" src={`${IMAGE_SOURCE}icon-free-registration.svg`} />
              </div>
              <div className="title">{i18next.t('subscriptions.purchase.free-services.registration.m2')}</div>
            </div>

            {!idcustomer && (
              <div className="block-2">
                <button type="button" onClick={handleRegistration} className={newcomer ? 'primary' : ''}>
                  {i18next.t('subscriptions.purchase.free-services.registration.m3')}
                </button>
              </div>
            )}
          </div>
          <div className="cell cell-arg-1">
            <ul>
              <li>
                <span className="title">{i18next.t('subscriptions.purchase.free-services.registration.m4')}</span>
                <p>{i18next.t('subscriptions.purchase.free-services.registration.m5')}</p>
              </li>
              <li>
                <span className="title">{i18next.t('subscriptions.purchase.free-services.registration.m6')}</span>
                <p>{i18next.t('subscriptions.purchase.free-services.registration.m7')}</p>
              </li>
            </ul>
          </div>
          <div className="cell cell-arg-2">
            <ul>
              <li>
                <span className="title">{i18next.t('subscriptions.purchase.free-services.registration.m8')}</span>
                <p>{i18next.t('subscriptions.purchase.free-services.registration.m9')}</p>
              </li>
              <li>
                <span className="title">{i18next.t('subscriptions.purchase.free-services.registration.m10')}</span>
                <p>{i18next.t('subscriptions.purchase.free-services.registration.m11')}</p>
              </li>
            </ul>
          </div>
        </div>
        {newcomer && (
          <>
            {filter === STORE && (
              <div>
                <h2 style={{ fontWeight: 600, fontSize: 30 }} className="h2-newcomer">
                  {i18next.t('subscriptions.purchase.header.m7')}
                </h2>
                <p style={{ textAlign: 'center', fontSize: 18, marginTop: 25 }}>{i18next.t('subscriptions.purchase.header.m8')}</p>
              </div>
            )}
            {filter === SUBSCRIPTION && (
              <div>
                <h2 style={{ fontWeight: 600, fontSize: 30 }} className="h2-newcomer">
                  {i18next.t('subscriptions.purchase.header.m2')}
                </h2>
                <p style={{ textAlign: 'center', fontSize: 18, marginTop: 25 }}>
                  {i18next
                    .t('subscriptions.purchase.header.m11')
                    .replace('%nbadj%', intl.formatNumber(LOTS_OFFICIAL_COUNT))
                    .replace('%nbauctioneer%', intl.formatNumber(AUCTIONEERS_OFFICIAL_COUNT))
                    .replace('%nbartist%', intl.formatNumber(ARTISTS_OFFICIAL_COUNT))
                    .replace('%lots_year_count%', intl.formatNumber(LOTS_OFFICIAL_YEAR_COUNT))}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(Registration);
