import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getLot } from '../../../../redux/selectors/dbEntities';

const Lot = ({ id }) => {
  const lot = useSelector(state => getLot(state, { id }));

  if (!lot) return null;

  return (
    <a href={lot.url} target="sample">
      {i18next.t('lot.no_access.show.m3')}
    </a>
  );
};

export default Lot;
