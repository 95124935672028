import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Cell, Pie, PieChart } from 'recharts';
import { colors, renderLabel } from '../graphStyle';
import { internalAxios } from '../../../../../utils/axios/internal';

class Countries extends Component {
  constructor(props) {
    super(props);

    this.state = { graph: props.datas };
  }

  componentDidMount() {
    const { datas, idestimate } = this.props;
    if (datas.length === 0) {
      internalAxios.get(`/api/wallet/estimate/graph_countries_repartition/${idestimate}`).then(({ data }) => {
        this.setState({ graph: data });
      });
    }
  }

  render() {
    const { width, height, pdf } = this.props;
    const { graph } = this.state;

    if (!graph) {
      return <div>loading .....</div>;
    }

    return (
      <PieChart height={height || 380} width={width}>
        {pdf && <CartesianGrid fill="white" />}
        <Pie labelLine={false} data={graph} dataKey="value" outerRadius="70%" label={renderLabel}>
          {graph.map((entry, index) => (
            <Cell key={entry.name} fill={colors[index]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}

Countries.propTypes = {
  idestimate: PropTypes.number.isRequired,
  datas: PropTypes.array.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

Countries.defaultProps = {
  height: undefined,
  pdf: false,
};

export default Countries;
