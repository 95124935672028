import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { get as getUserContext } from '../../../../redux/slices/userContext/selectors';
import Modal from '../../../common/ui/Modal';
import './stylesheet.scss';

const ContactModale = ({ open, setOpen }) => {
  const { email, billaddress = [], phone = '', phone2 = '' } = useSelector(getUserContext);

  return (
    <Modal className="auctioneer-agreement-contact-modale" title="Confirmation de vos coordonnées" open={open} onClose={() => setOpen(false)}>
      {({ close }) => (
        <div>
          <p>{i18next.t('auctioneers.agreement.contactmodale.m2')}</p>
          <p>{i18next.t('auctioneers.agreement.contactmodale.m3')}</p>
          <p className="contact">
            <p className="marg marg-b-15">
              <i className="fa fa-envelope-o" /> {email}
            </p>
            {billaddress.length > 0 && (
              <p>
                {billaddress.map(e => (
                  <>
                    {e}
                    <br />
                  </>
                ))}
              </p>
            )}
            {phone !== '' && (
              <p>
                <i className=" fa fa-mobile" /> {phone}
              </p>
            )}
            {phone2 !== '' && (
              <p>
                <i className=" fa fa-phone" /> {phone2}
              </p>
            )}
          </p>
          <p>
            <a href="/account">{i18next.t('auctioneers.agreement.contactmodale.m4')}</a>
          </p>
          <p className="marg marg-t-25">
            <b>Mathilde Fiallos</b>
            <br />
            {i18next.t('auctioneers.agreement.contactmodale.m7')}
            <br />
            {i18next.t('auctioneers.agreement.contactmodale.m8')}
            <br />
            {i18next.t('auctioneers.agreement.contactmodale.m5')} : <a href="mailto:memberauctioneers@artprice.com">memberauctioneers@artprice.com</a>
            <br />
            {i18next.t('auctioneers.agreement.contactmodale.m6')} : <a href="tel:+33472421737">+ 33 4 72 42 17 37</a>
          </p>
          <div className="bottom">
            <button className="btn btn-primary" type="button" onClick={close}>
              {i18next.t('auctioneers.agreement.contactmodale.m9')}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ContactModale;
