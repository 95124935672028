import React from 'react';
import A18n from '../../../../../../common/A18n';
import './stylesheet.scss';

const OneDayRefoundInfo = ({ onClick, visible }) => {
  if (!visible) return null;

  return (
    <div className="subscription-purchase-common-one-day-refound-info" onClick={onClick}>
      <div className="details">
        <div className="close" onClick={onClick}>
          <i className="fa fa-close" />
        </div>
        <A18n rsx="subscriptions.purchase.common.one-day-refound-info.m1" />
      </div>
    </div>
  );
};

export default OneDayRefoundInfo;
