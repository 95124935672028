import { isEmpty } from 'lodash';
import { Button } from 'reactstrap';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_CLICK_OPERATION_SCROLL,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORE,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORES_LIST,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_STORE,
  IMAGE_SOURCE,
} from '../../../../../constants/rails';
import { useHorizontalScroll } from '../../../../../hooks/useHorizontalScroll';
import { getArtist, getStores } from '../../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { useViewport } from '../../../../../hooks/useViewport';
import { useOverlap } from '../../../../../hooks/useOverlap';
import { UiContext } from '../../common/UiProvider';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Stores = ({ menuRef }) => {
  const ref = useRef();
  const storeRef = useRef();
  const stores = useSelector(getStores);
  const { leftDisabled, rightDisabled, displayNav, handleScrollClick } = useHorizontalScroll({ ref });
  const artist = useSelector(getArtist);
  const { viewportWidth } = useViewport();
  const { hideLeftMenu } = useContext(UiContext);

  useOverlap({ className: 'overlapped', overlappingElementRef: hideLeftMenu ? null : menuRef, overlappedElementRef: storeRef, extraDeps: [isEmpty(stores?.items), hideLeftMenu] });

  const hScroll = direction => {
    handleScrollClick(direction);
    aapiBeacon('click', {
      op: ANALYTICS_CLICK_OPERATION_SCROLL,
      from: ANALYTICS_FROM_ARTIST,
      artistId: artist.id,
      section: ANALYTICS_SECTION_ARTIST_STORE,
    });
  };

  if (isEmpty(stores?.items)) return null;

  return (
    <div className="artists-artist-marketplace-stores">
      <div className="container">
        <div className="stores-left">
          <div ref={storeRef} className="overlap">
            <p>
              {stores.totalCount > 1 && (
                <A18n
                  rsx={isEmpty(artist.groupMembers) ? 'artists.artist.marketplace.stores.m1' : 'artists.artist.marketplace.stores.m4'}
                  replace={[
                    ['%nb%', stores.totalCount],
                    ['%artist%', artist.name],
                  ]}
                />
              )}
              {stores.totalCount === 1 && <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.marketplace.stores.m2' : 'artists.artist.marketplace.stores.m5'} replace={[['%artist%', artist.name]]} />}
            </p>
            {stores.totalCount > 1 && (
              <a href={stores.url} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORES_LIST, idartist: artist.id })}>
                <A18n rsx="artists.artist.marketplace.stores.m3" />
              </a>
            )}
          </div>
        </div>
        <div className="stores-navigation">
          {displayNav && viewportWidth >= 768 && (
            <Button className="btn-previous btn btn-default" onClick={() => hScroll('left')} disabled={leftDisabled}>
              <img src={`${IMAGE_SOURCE}artist/arrow-left.png`} alt="previous" />
            </Button>
          )}
          <div className="stores" ref={ref}>
            {stores.items.map(({ store, classifiedCount }) => (
              <a
                href={store.storeUrl}
                key={`${store.storeUrl}-${classifiedCount}`}
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORE, idartist: artist.id, idstore: store.id })}
              >
                <div className="store">{store.sitename}</div>
              </a>
            ))}
          </div>
          {displayNav && viewportWidth >= 768 && (
            <Button className="btn-next btn btn-default" onClick={() => hScroll('right')} disabled={rightDisabled}>
              <img src={`${IMAGE_SOURCE}artist/arrow-right.png`} alt="next" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stores;
