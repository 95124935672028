/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.promotion.m1',
  'subscriptions.list.promotion.m2',
  'subscriptions.list.promotion.m3',
  'subscriptions.list.promotion.m4',
  'subscriptions.list.promotion.m5',
  'subscriptions.list.promotion.m6',
  'subscriptions.list.promotion.m7',
];
