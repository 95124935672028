import React from 'react';
import './stylesheet.scss';

const Large = () => (
  <div className="search-universal-skeletons-large">
    {[...Array(2)].map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`SkeletonLarge${i}`} className="skeleton-block">
        <div className="picto animate" />
        <div className="text">
          <div className="animate sub-text sub-text-70" />
          <div className="animate sub-text sub-text-30" />
          <div className="animate sub-text sub-text-50" />
        </div>
      </div>
    ))}
  </div>
);

export default Large;
