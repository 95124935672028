import React from 'react';
import { string } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../providers/ReduxProvider';
import Show from './Show';
import Pasts from './List/Pasts';
import Futures from './List/Futures';
import initializeGtm from '../../utils/gtm';
import { GTM_FAIRS } from '../../constants/rails';
import RouterProvider from '../providers/RouterProvider';

initializeGtm({ pageCategory: GTM_FAIRS });

const ProvidedEvents = ({ locale = 'en' }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <RouterProvider
          routes={[
            {
              path: '/events',
              children: [
                {
                  path: '',
                  element: <Futures />,
                },
                {
                  path: ':id/:title',
                  element: <Show lang={locale} />,
                },
                {
                  path: 'past',
                  element: <Pasts />,
                },
              ],
            },
          ]}
        />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

ProvidedEvents.propTypes = {
  locale: string.isRequired,
};

export default ProvidedEvents;
