import React from 'react';
import { useSelector } from 'react-redux';
import { add, differenceInDays } from 'date-fns';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING, ANALYTICS_CLICK_OPERATION_TO_PRICING, MODULE_STORE } from '../../../../../../../../../constants/rails';
import { getLastSubscription } from '../../../../../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { formatFns } from '../../../../../../../../../utils/dates/format';
import { convert } from '../../../../../../../../../utils/dates';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';

const ExpiredSubscription = () => {
  const lastSubscription = useSelector(getLastSubscription);
  if (!lastSubscription) return null;
  if (differenceInDays(new Date(lastSubscription.dates.dtExpire), new Date(lastSubscription.dates.dtStart)) <= 30) return null;

  const rsx = lastSubscription.nbDays === 1 ? 'component.header.notifications.expired.m1' : 'component.header.notifications.expired.m2';
  const hadStore = (lastSubscription.modules || []).includes(MODULE_STORE);

  return (
    <Notification
      element={<A18n rsx={rsx} replace={[['%dt%', formatFns(add(convert(lastSubscription.dates.dtExpire), { days: 30 }), 'PPP')]]} />}
      link={`/subscription${hadStore ? '/store' : ''}`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: hadStore ? ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING : ANALYTICS_CLICK_OPERATION_TO_PRICING })}
    />
  );
};

export default ExpiredSubscription;
