import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getArtist, getCategory } from '../../../../redux/selectors/dbEntities';
import CommonShare from '../../../common/Share';
import './stylesheet.scss';

const Share = ({ classified }) => {
  const category = useSelector(state => getCategory(state, { id: classified.idcategory }), shallowEqual);
  const artist = useSelector(state => getArtist(state, { id: classified ? classified.idartist : 0 }), shallowEqual);

  const title = artist ? `${classified.title} by ${artist.name}` : classified.title;

  const hashtags = ['artprice'];

  if (category && !hashtags.includes(category?.label.toLowerCase())) {
    hashtags.push(category.label.toLowerCase());
  }

  return <CommonShare title={title} hashtagsAddons={hashtags} flat className="share" />;
};
export default Share;
