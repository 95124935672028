import React, { useMemo, useState } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { areDraftFacetsLoading, getDraftFacets } from '../../../../../redux/slices/search/scoped';

import './stylesheet.scss';
import Spinner from '../../../../common/spinners/Spinner';

function sortFunc([_, a], [__, b]) {
  return String(a.sort || a.label || a.id).localeCompare(String(b.sort || b.label || b.id));
}

const QuickListItem = ({ label, selected = false, onClick }) => (
  <div role="presentation" className={`search-quicklist-item ${selected ? 'selected' : ''}`.trim()} onClick={onClick}>
    <div className="item-check">
      <i className={`fa ${selected ? 'fa-dot-circle-o' : 'fa-circle-o'}`} />
    </div>
    <div className="item-label">{label}</div>
  </div>
);

const QuickList = ({ className, facet, searchScope, value, onChange, filterAbove = Infinity }) => {
  const [filterText, setFilterText] = useState('');
  const isLoading = useSelector(state => areDraftFacetsLoading(state, { searchScope }));
  const draftFacets = useSelector(state => getDraftFacets(state, { searchScope, facet }));

  const options = useMemo(
    () =>
      Object.entries(draftFacets ?? [])
        .sort(sortFunc)
        .map(([id, info]) => ({
          value: id,
          label: info.label,
          count: info.count,
        })),
    [draftFacets],
  );

  if (isLoading) {
    return <Spinner faSize={1} />;
  }

  return (
    <div className={`search-quicklist ${className ?? ''}`.trim()}>
      {filterAbove < options.length && (
        <div className="filter">
          <Input type="text" placeholder={i18next.t('components.myartprice.summary.m3')} value={filterText} onChange={e => setFilterText(e.target.value)} />
        </div>
      )}
      <div className="search-quicklist-items">
        {options.length > 1 && <QuickListItem label={i18next.t('components.search.common.quickfilter.m1')} selected={value === undefined} onClick={() => onChange(undefined)} />}
        {options
          .filter(({ label }) => filterText.length < 2 || label.match(new RegExp(filterText, 'gi')))
          .map(({ value: v, label }) => (
            <QuickListItem key={v} label={label} selected={String(value) === String(v)} onClick={() => onChange(v)} />
          ))}
      </div>
    </div>
  );
};

export default QuickList;
