import React from 'react';
import { useSelector } from 'react-redux';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../../../common/A18n';

const Title = ({ warningsCount }) => {
  const { warningForRenewInPlaceOfUpgrade } = useSelector(getSettings);

  if (warningForRenewInPlaceOfUpgrade && warningsCount === 0) return <A18n rsx="subscriptions.list.warnings.warningmodale.title.m1" />;

  return <A18n rsx="subscriptions.list.warnings.warningmodale.title.m2" />;
};

export default Title;
