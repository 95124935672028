import React from 'react';
import { useSelector } from 'react-redux';
import NoIndexAccessCard from '../NoIndexAccessCard';
import NoAccessCard from '../NoAccessCard';
import { getArtist } from '../../../../../../redux/slices/artist';

const Fake = ({ lotCount, noAccess, noIndexesAccess }) => {
  const artist = useSelector(getArtist);

  if (artist.macro && !noAccess && noIndexesAccess) return <NoIndexAccessCard artist={artist} />;
  if (noAccess) return <NoAccessCard artist={artist} lotCount={lotCount} />;

  return <div />;
};

export default Fake;
