import React, { Component } from 'react';
import { number, string } from 'prop-types';
import i18next from 'i18next';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../../utils/http';

class GoToPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: { ...queryStringToObject(window.location.search) },
    };
  }

  handleOnChange(page) {
    this.setState({ page });
  }

  changePage() {
    const { params, page } = this.state;
    const { pageParam, maxPage } = this.props;

    if (page <= maxPage) {
      window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...params, [pageParam]: page })}`;
    }
  }

  render() {
    const { maxPage } = this.props;

    return (
      <div className="input-group input-group-sm">
        <input type="number" min="1" max={maxPage} className="form-control" placeholder={i18next.t('marketplace.list.html.m4')} onChange={e => this.handleOnChange(e.target.value)} />
        <div className="input-group-btn">
          <button type="submit" className="btn btn-primary" onClick={() => this.changePage()}>
            {i18next.t('components.common.gotopage.m1')}
          </button>
        </div>
      </div>
    );
  }
}

GoToPage.defaultProps = {
  pageParam: 'p',
};

GoToPage.propTypes = {
  pageParam: string,
  maxPage: number.isRequired,
};

export default GoToPage;
