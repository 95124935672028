/* eslint-disable global-require */
module.exports = [
  ...require('./Sections/i18n'),
  ...require('./Categories/i18n'),
  ...require('./Artists/i18n'),
  'components.myartprice.settings.m1',
  'components.myartprice.settings.m2',
  'components.myartprice.settings.m3',
  'components.myartprice.settings.m4',
];
