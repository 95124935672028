import React, { useEffect, useState } from 'react';

const Navigation = ({ baseUrl, data, slug }) => {
  const [slugs, setSlugs] = useState([]);
  const [navigation, setNavigation] = useState({});

  useEffect(() => {
    if (data?.length > 0) {
      setSlugs(data.reduce((s, section) => [...s, ...section.items.map(item => item)], []));
    }
  }, [data]);

  useEffect(() => {
    if (slugs.length > 0) {
      const active = slugs.findIndex(s => s.slug === slug);

      setNavigation({
        prev: slugs[active - 1],
        next: slugs[active + 1],
      });
    }
  }, [slugs]);

  return (
    <div className="report-navigation">
      {navigation.prev && (
        <a className="btn-navigation" href={`${baseUrl}${navigation.prev.slug}`} title={navigation.prev.title}>
          <i className="marg marg-r-5 fa fa-caret-left fa-2x" aria-hidden="true" />
        </a>
      )}
      {navigation.next && (
        <a className="btn-navigation" href={`${baseUrl}${navigation.next.slug}`} title={navigation.next.title}>
          <i className="marg marg-l-5 fa fa-caret-right fa-2x" aria-hidden="true" />
        </a>
      )}
    </div>
  );
};

export default Navigation;
