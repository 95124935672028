import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE, THEME_STORE } from '../../../../../../constants/rails';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../../../common/A18n';
import { getLastSubscription } from '../../../../../../redux/slices/userContext/selectors';
import { formatFns } from '../../../../../../utils/dates/format';

const Content = ({ warningsCount }) => {
  const { theme, missingModules = [], quantity, initialQuantity, warningForRenewInPlaceOfUpgrade, image } = useSelector(getSettings);
  const lastSubscription = useSelector(getLastSubscription) || {};
  const [missingModulesRender, setMissingModulesRender] = useState([]);

  useEffect(() => {
    if (missingModules.length > 0) {
      const buff = [];
      if (missingModules.includes(MODULE_IMAGE)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m6" />);
      if (missingModules.includes(MODULE_PASTSALE)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m7" />);
      if (missingModules.includes(MODULE_FUTURESALE)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m8" />);
      if (missingModules.includes(MODULE_INDEXES)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m9" />);
      if (missingModules.includes(MODULE_INDICATOR)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m10" />);
      if (missingModules.includes(MODULE_STORE)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m11" />);
      if (missingModules.includes(MODULE_EXPORT_PDF)) buff.push(<A18n rsx="subscriptions.list.warnings.warningmodale.content.m12" />);

      setMissingModulesRender(buff);
    }
  }, [missingModules]);

  if (theme.idtheme === THEME_STORE) {
    return (
      <p>
        <A18n
          rsx="subscriptions.purchase.list.theme.details.marketplace.m2"
          trustHtml
          replace={[
            ['%strong%', '<strong>'],
            ['%/strong%', '</strong>'],
          ]}
        />
      </p>
    );
  }

  if (warningForRenewInPlaceOfUpgrade && warningsCount === 0) {
    return (
      <p className="normal">
        <A18n
          rsx="subscriptions.list.warnings.warningmodale.content.m1"
          replace={[
            ['%abo%', theme.product.title],
            ['%dt%', formatFns(lastSubscription.dates.dtExpire, 'P')],
          ]}
        />
      </p>
    );
  }

  return (
    <div className="subscription-list-warnings-warningmodale-content">
      {!image && (
        <p>
          <A18n rsx="subscriptions.list.warnings.warningmodale.content.m2" />
        </p>
      )}

      {quantity < initialQuantity && (
        <p>
          <A18n
            rsx={quantity === 1 ? 'subscriptions.list.warnings.warningmodale.content.m3' : 'subscriptions.list.warnings.warningmodale.content.m4'}
            replace={[
              ['%new_nbusers%', quantity],
              ['%current_nbusers%', initialQuantity],
            ]}
          />
        </p>
      )}

      {missingModulesRender.length > 0 && (
        <>
          <p>
            <A18n
              rsx="subscriptions.list.warnings.warningmodale.content.m5"
              replace={[
                ['%product%', theme.product.title],
                ['%abo%', lastSubscription.name],
              ]}
            />
          </p>
          <p>
            <A18n rsx="subscriptions.list.warnings.warningmodale.content.m13" trustHtml replace={[['%modules%', missingModulesRender.map(missingModuleToRender => renderToString(missingModuleToRender)).join(', ')]]} />
          </p>
        </>
      )}
    </div>
  );
};

export default Content;
