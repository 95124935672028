import React from 'react';
import AilsSales from './ails/Sales';
import AilsMegaban from './ails/Megaban';
import Search from './Search';
import './stylesheet.scss';
import Events from './Events';
import Classifieds from './Classifieds';
import Stores from './Stores';
import Reports from './Reports';
import Articles from './Articles';
import Marketplace from './Marketplace';
import FreeDemo from './FreeDemo';
import Studies from './Studies';
import Faq from './Faq';
import Myartprice from './Myartprice';
import Header from './Header';
import FocusOnOrAutoPromo from './FocusOnOrAutoPromo';
import BackToTopButton from '../common/BackToTopButton';
import Promotion from './Promotion';
import DOMView from '../common/observers/DOMView';
import { ANALYTICS_FROM_HOMEPAGE, ANALYTICS_SECTION_SCROLL_0, ANALYTICS_SECTION_SCROLL_100, ANALYTICS_SECTION_SCROLL_50 } from '../../constants/rails';

const HomePage = () => (
  <div className="homepage">
    <div id="homepage-0">
      <DOMView from={ANALYTICS_FROM_HOMEPAGE} section={ANALYTICS_SECTION_SCROLL_0} selector="#homepage-0" />
    </div>
    <Header />
    <Search />
    <Myartprice />
    <AilsSales />
    <Events />
    <AilsMegaban />
    <FreeDemo />
    <Promotion />
    <FocusOnOrAutoPromo />
    <div id="homepage-50">
      <DOMView from={ANALYTICS_FROM_HOMEPAGE} section={ANALYTICS_SECTION_SCROLL_50} selector="#homepage-50" />
    </div>
    <Marketplace />
    <Classifieds />
    <Stores />
    <Reports />
    <Articles />
    <Studies />
    <Faq />
    <div id="homepage-100">
      <DOMView from={ANALYTICS_FROM_HOMEPAGE} section={ANALYTICS_SECTION_SCROLL_100} selector="#homepage-100" />
    </div>
    <div className="back-to-top-container">
      <BackToTopButton bottom=".back-to-top-hp-anchor" />
    </div>
    <div className="back-to-top-hp-anchor" />
  </div>
);

export default HomePage;
