import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEstimateContext, fetchMarketplaceContext } from '../../../../../../redux/slices/userContext';
import { getNotificationsCount } from '../../../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';
import A18n from '../../../../../common/A18n';
import Dropdown from '../Dropdown';
import Content from './Content';
import Pill from '../../Pill';

import './stylesheet.scss';
import NoTranslate from '../../../../../common/NoTranslate';

const MyAccount = () => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getNotificationsCount);

  useEffect(() => {
    dispatch(fetchMarketplaceContext());
    dispatch(fetchEstimateContext());
  }, []);

  return (
    <Dropdown
      className="artp-Header-item artp-Header-top-item artp-Header-MyAccount-Dropdown"
      button={
        <div className="artp-Header-MyAccount-button e2e-myaccount-btn">
          <NoTranslate>
            {notificationsCount > 0 ? (
              <Pill>
                <img className="logo-silhouette" src={`${IMAGE_SOURCE}menu/bonhomme.svg`} alt="silhouette" />
              </Pill>
            ) : (
              <img className="logo-silhouette" src={`${IMAGE_SOURCE}menu/bonhomme.svg`} alt="silhouette" />
            )}
          </NoTranslate>
          <A18n rsx="layouts.header.m14" />
        </div>
      }
      content={<Content />}
    />
  );
};

export default MyAccount;
