import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotion } from '../../../../../redux/selectors/subscriptions/promotions';
import { fetchPromotion } from '../../../../../redux/actions/subscriptions/promotion';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';

const Banner = ({ idpromotion }) => {
  const dispatch = useDispatch();
  const promotion = useSelector(state => getPromotion(state, { id: idpromotion }));

  useEffect(() => {
    if (!promotion) {
      dispatch(fetchPromotion({ id: idpromotion }));
    }
  }, [idpromotion]);

  if (!promotion) return null;

  return (
    <a
      href={`/subscription?promocode=${promotion.code}`}
      onClick={e => {
        window.dataLayer.push({ event: 'promotionClick', ecommerce: { promoClick: { promotions: [{ id: idpromotion, name: promotion.code, position: 'subscription' }] } } });
        // Use redux loop instead of reload the page
        e.preventDefault();
        dispatch(updateSettings({ data: { promocode: promotion.code } }));
        return false;
      }}
    >
      <img
        alt={promotion.code}
        className="center-block"
        src={promotion.banners[1100].source}
        sizes="(max-width: 768px) 400px, (max-width: 992px) 720px, (max-width: 1200px) 940px, 1100px"
        srcSet={`${promotion.banners[400].source} 400w, ${promotion.banners[720].source} 720w, ${promotion.banners[940].source} 940w, ${promotion.banners[1100].source} 1100w`}
      />
    </a>
  );
};

export default Banner;
