import React from 'react';
import { ANALYTICS_FROM_AIL_LANDINGPAGE } from '../../../../../constants/rails';
import { ailIsMultiDates } from '../../../../../utils/ail';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Lot = ({ ail }) => (
  <div className="ails-landingpage-lot">
    <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_AIL_LANDINGPAGE}`}>
      <div className="lot">
        <div className="legend">
          <div className="auctioneer">{ail.auctioneer}</div>
          <div className="dt">
            <div className="block">
              <div className="part1">{ail.dt_start_part1}</div>
              <div className="part2">{ail.dt_start_part2}</div>
            </div>

            {ailIsMultiDates(ail) && (
              <div className="block">
                <div className="separator" />
                <div className="part1">{ail.dt_end_part1}</div>
                <div className="part2">{ail.dt_end_part2}</div>
              </div>
            )}
          </div>
          <div className="location">
            {ail.city}, {ail.country}
          </div>
          <div className="artist">{ail.artist_name}</div>
          <div className="title"> {ail.title}</div>
          <div className="technique">{ail.technique}</div>
          <div className="estimate">
            <span className="title">
              <A18n rsx="ails.landingpage.lots.lot.m1" />
            </span>
            <strong>{ail.estimate}</strong>
          </div>
        </div>
        <div className="repro-container">
          <div className="repro" style={{ backgroundImage: `url(${ail.images.square})` }} />
        </div>
      </div>
    </a>
  </div>
);

export default Lot;
