import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from '../../../../common/LoginOrRegistrationModal/constants';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_WARNING } from '../../../../../constants/rails';
import { getLastSubscription } from '../../../../../redux/slices/userContext/selectors';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { set } from '../../../../../redux/actions/ui/dictionary';
import '../../../../common/stylesheets/buttons.scss';
import { UPGRADE } from '../../common/constants';
import Validate from './buttonsTitles/Validate';
import Modal from '../../../../common/ui/Modal';
import Back from './buttonsTitles/Back';
import Content from './Content';
import Title from './Title';
import './stylesheet.scss';

const UpdateOrRenew = () => {
  const dispatch = useDispatch();
  const { theme, displayWarningsModale = false, quantity, warningForRenewInPlaceOfUpgrade, image, from, view, promocode, initialQuantity, missingModules = [] } = useSelector(getSettings);
  const lastSubscription = useSelector(getLastSubscription);
  const [warningsCount, setWarningsCount] = useState(0);
  const { logged } = useSelector(getUserContext);

  useEffect(() => {
    if (displayWarningsModale) {
      aapiBeacon('dom_view', { from, section: ANALYTICS_SECTION_SUBSCRIPTIONS_WARNING });
    }
  }, [displayWarningsModale]);

  useEffect(() => {
    setWarningsCount((quantity < initialQuantity ? 1 : 0) + (image ? 0 : 1) + (missingModules.length > 0 ? 1 : 0));
  }, [missingModules, quantity, initialQuantity, image]);

  const handleCloseClick = () => {
    let lastSubscriptionParams = {};
    if (lastSubscription) {
      lastSubscriptionParams = { image: lastSubscription.hasImageAccess, quantity: lastSubscription.usersCount };
    }

    if (warningForRenewInPlaceOfUpgrade && !warningsCount) {
      dispatch(updateSettings({ data: { ...lastSubscriptionParams, displayWarningsModale: false, view: UPGRADE } }));
    } else if (!image) {
      dispatch(updateSettings({ data: { ...lastSubscriptionParams, displayWarningsModale: false, withoutImagePriceProduct: [] } }));
    } else {
      dispatch(updateSettings({ data: { ...lastSubscriptionParams, displayWarningsModale: false } }));
    }

    window.scrollTo(0, 0);
  };

  const handleValidate = () => {
    if (logged) {
      window.location = `/payments/informations?image=${image}&idphysicproducts[${theme.product.idp}]=${quantity}&type=${view}&promocode=${promocode}`;
    } else {
      dispatch(updateSettings({ data: { displayWarningsModale: false } }));
      dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, true));
    }
  };

  return (
    <Modal className="subscriptions-list-warnings-update-or-renew" onClose={() => dispatch(updateSettings({ data: { displayWarningsModale: false } }))} open={displayWarningsModale}>
      <div className="content">
        <h1>
          <Title warningsCount={warningsCount} />
        </h1>
        <Content warningsCount={warningsCount} />
      </div>

      <div className="btn-actions">
        <button type="button" onClick={handleCloseClick} className="artp-btn">
          <Back warningsCount={warningsCount} />
        </button>

        <button type="button" onClick={handleValidate} className="close-action artp-btn artp-btn-purple">
          <Validate warningsCount={warningsCount} />
        </button>
      </div>
    </Modal>
  );
};

export default UpdateOrRenew;
