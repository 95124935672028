import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Contacts from '../index';

const ProvidedContacts = ({ id }) => (
  <ReduxProvider>
    <Contacts id={id} />
  </ReduxProvider>
);

export default ProvidedContacts;
