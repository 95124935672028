import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './stylesheet.scss';

const DevInfoContent = () => {
  const [devInfos, setDevInfos] = useState({});
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setDevInfos({
        railsEnv: document.querySelector('meta[name=dev-rails-env]')?.content?.trim(),
        gitBranch: document.querySelector('meta[name=dev-git-branch]')?.content?.trim(),
        nodeHostname: document.querySelector('meta[name=dev-node-hostname]')?.content?.trim(),
      });
    }
  }, []);

  return (
    <div className={classNames('DevInfo', { shown })} onMouseEnter={() => setShown(true)} onMouseLeave={() => setShown(false)}>
      {!shown && <div className="toggle">🛈</div>}
      {shown && (
        <>
          <div className="info host" title="Host">
            <i className="fa fa-server" />
            &nbsp; {devInfos.nodeHostname}
          </div>
          <div className="info env" title="Env">
            <span title="Rails Env">
              <i className="fa fa-diamond" />
              &nbsp; {devInfos.railsEnv}
            </span>
            {devInfos.railsEnv !== process.env.RAILS_ENV && (
              <span title="Build Env">
                &nbsp;&nbsp;[ <i className="fa fa-wrench" /> {process.env.RAILS_ENV} ]
              </span>
            )}
          </div>
          {devInfos.gitBranch && (
            <div className="info git" title="Branch">
              <i className="fa fa-gitlab" />
              &nbsp; {devInfos.gitBranch || '-'}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DevInfoContent;
