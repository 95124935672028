import React from 'react';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import ReduxProvider from '../../../providers/ReduxProvider';
import Header from '..';

const WithProviders = ({ locale, simple = false }) => (
  <CookiesProvider suppressHydrationWarning>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <Header locale={locale} simple={simple} />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

export default WithProviders;
