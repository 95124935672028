/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.faq.m1',
  'subscriptions.list.faq.m2',
  'subscriptions.list.faq.m3',
  'subscriptions.list.faq.m4',
  'subscriptions.list.faq.m5',
  'subscriptions.list.faq.m6',
  'subscriptions.list.faq.m7',
];
