import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParams, updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import AvailablePayments from '../common/AvailablePayments';
import CartSummary from '../common/CartSummary';
import A18n from '../../common/A18n';
import Help from '../common/Help';
import Form from '../common/Form';
import '../common/layout.scss';
import './stylesheet.scss';

const Informations = () => {
  const dispatch = useDispatch();
  const { logged, idcustomer } = useSelector(getUserContext);
  const { idphysicproducts, image, type, promocode, idestimates } = useSelector(getParams);

  useEffect(() => {
    dispatch(updateNavigationStep(1));

    if (idcustomer && !logged) {
      window.location = '/identity';
    }
  }, []);

  const handleSuccess = () => {
    const encodedIdphysicproductsParam = Object.keys(idphysicproducts)
      .map(idp => `idphysicproducts[${idp}]=${idphysicproducts[idp]}`)
      .join('&');

    const encodedIdestimatessParam = (idestimates || []).map(idestimate => `idestimates[]=${idestimate}`).join('&');

    window.location = `/payments/platforms?${image !== undefined ? `image=${image}&` : ''}${encodedIdphysicproductsParam}&type=${type}${promocode ? `&promocode=${promocode}` : ''}${
      encodedIdestimatessParam ? `&${encodedIdestimatessParam}` : ''
    }`;
  };

  return (
    <div className="payments-informations  payments-grid-layout">
      <div className="container">
        <div className="container-grid">
          <div className="left">
            <h1>
              {!idcustomer && <A18n rsx="payments.informations.m1" />}
              {idcustomer && <A18n rsx="payments.informations.m2" />}
            </h1>
            <Form onSuccess={handleSuccess} />
            <div className="help-on-mobile">
              <Help />
              <AvailablePayments />
            </div>
          </div>
          <div className="right">
            <CartSummary withPromocode />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informations;
