import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { DEBOUNCED_HANDLE_CHANGE_WAIT } from './constants';
import Classifieds from './Classifieds';
import Articles from './Articles';
import Reports from './Reports';
import Artists from './Artists';
import Input from './Input';
import './stylesheet.scss';
import Lots from './Lots';

const Universal = forwardRef(({ className, onChange, value = '', autoFocus = false }, ref) => {
  const [internalValue, setInternalValue] = useState(value);
  const [searchValue, setSearchValue] = useState(internalValue);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = useCallback(
    debounce(
      v => {
        setSearchValue(v);
        onChange?.(v);
      },
      DEBOUNCED_HANDLE_CHANGE_WAIT,
      { leading: false, trailing: true },
    ),
    [onChange],
  );

  useEffect(() => {
    handleChange(internalValue);
  }, [internalValue]);

  return (
    <div className={classNames('search-universal', className)}>
      <Input ref={ref} onChange={setInternalValue} value={internalValue} autoFocus={autoFocus} />
      <div className="search-universal-results">
        {internalValue?.trim() && (
          <>
            <Artists term={searchValue} />
            <Lots term={searchValue} />
            <Classifieds term={searchValue} />
            <Articles term={searchValue} />
            <Reports term={searchValue} />
          </>
        )}
      </div>
    </div>
  );
});

export default Universal;
