import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY, ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED } from '../../../../../../constants/rails';
import { getArtist, getCategoriesFuture, getLotsFuture } from '../../../../../../redux/slices/artist';
import CommonCategories from '../../Categories';
import A18n from '../../../../../common/A18n';
import Fake from '../Fake';

const Categories = ({ handleOpenDrawer, lotCount }) => {
  const intl = useIntl();
  const lots = useSelector(getLotsFuture);
  const categories = useSelector(getCategoriesFuture);
  const artist = useSelector(getArtist);

  if (!isEmpty(categories))
    return (
      <>
        <p className="sub-h2">
          {isEmpty(artist.groupMembers) && (
            <>
              {lots.notSignedCount === 0 && (
                <>
                  {lotCount > 1 && (
                    <A18n
                      rsx="artists.artist.lots.future.m2"
                      replace={[
                        ['%artist%', artist.name],
                        ['%nb%', lotCount],
                      ]}
                    />
                  )}
                  {lotCount === 1 && <A18n rsx="artists.artist.lots.future.m3" replace={[['%artist%', artist.name]]} />}
                </>
              )}
              {lots.notSignedCount > 0 && (
                <A18n
                  rsx="artists.artist.lots.future.m21"
                  replace={[
                    ['%nb%', intl.formatNumber(lotCount + lots.notSignedCount)],
                    ['%artist%', artist.name],
                  ]}
                />
              )}
            </>
          )}
          {!isEmpty(artist.groupMembers) && (
            <>
              {lotCount > 1 && (
                <A18n
                  rsx="artists.artist.lots.future.m15"
                  replace={[
                    ['%artist%', artist.name],
                    ['%nb%', lotCount],
                  ]}
                />
              )}
              {lotCount === 1 && <A18n rsx="artists.artist.lots.future.m16" replace={[['%artist%', artist.name]]} />}
            </>
          )}
        </p>
        <CommonCategories
          period="future"
          categories={categories}
          clickOnCategories={e =>
            handleOpenDrawer({
              op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY,
              e,
            })
          }
          clickOnNotSigned={e =>
            handleOpenDrawer({
              op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED,
              e,
            })
          }
          lots={lots}
        />
      </>
    );

  return (
    <>
      {lots.notSignedCount === 0 && (
        <p className="sub-h2">
          <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.lots.future.m4' : 'artists.artist.lots.future.m17'} replace={[['%artist%', artist.name]]} />
        </p>
      )}

      {lots.notSignedCount === 1 && (
        <p>
          <A18n rsx="artists.artist.lots.future.m5" replace={[['%artist%', artist.name]]} />
        </p>
      )}
      {lots.notSignedCount > 1 && (
        <p>
          <A18n
            rsx="artists.artist.lots.future.m6"
            replace={[
              ['%artist%', artist.name],
              ['%nb%', lots.notSignedCount],
            ]}
          />
        </p>
      )}

      {lots.notSignedCount === 0 && <Fake />}
      {lots.notSignedCount > 0 && (
        <CommonCategories
          period="future"
          categories={categories}
          clickOnCategories={e =>
            handleOpenDrawer({
              op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY,
              e,
            })
          }
          clickOnNotSigned={e =>
            handleOpenDrawer({
              op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED,
              e,
            })
          }
          lots={lots}
        />
      )}
    </>
  );
};

export default Categories;
