import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const ArtistGenderSelect = () => (
  <Item label={i18next.t('components.search.helpers.items.artist_gender.m1')} name="artist_gender">
    <Select facet="artist_gender" placeholder={i18next.t('components.search.helpers.items.artist_gender.m2')} />
  </Item>
);

export default ArtistGenderSelect;
