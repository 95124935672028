/* eslint-disable global-require */
module.exports = [
  ...require('./Agreement/i18n'),
  ...require('./common/i18n'),
  ...require('./Docs/i18n'),
  ...require('./Sales/i18n'),
  ...require('./Sale/i18n'),
  ...require('./Subscription/i18n'),
  ...require('./Contact/i18n'),
  'auctioneers.errors.m1',
  'auctioneers.errors.m2',
];
