import { isArray } from 'lodash';
import { fetchFacets as fetchFacetsService } from '../../../services/search';
import { getCurrentScope, getDraftParams, isDraftDirty, isSearchPerformed } from '../../selectors/search';
import { updateCurrencyPreference, updateDimensionPreference } from '../preferences';
import { clearResultsData, fetchResults } from './results';
import { updateCount, updateDraftCount, updateLoadingFacets, updateSearchScope, updateInfiniteScroll } from './info';
import { updateDraftParams } from './draftParams';
import { updateDraftFacets } from './draftFacets';
import { updateUrlParams } from './urlParams';
import { updateDefaultFacets } from './defaultFacets';
import { updateUrlQuery } from '../../../utils/http';
import { updateUrlFacets } from './urlFacets';

export function clearSearch({ searchScope }) {
  return dispatch => {
    dispatch(clearResultsData({ searchScope }));
    updateUrlQuery({});

    dispatch(updateDraftParams({ params: {}, searchScope }));
    dispatch(updateUrlParams({ params: {}, searchScope }));
    dispatch(fetchResults({ params: {}, searchScope, updateFacets: true }));
  };
}

export function clearCurrentSearch() {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    return dispatch(clearSearch({ searchScope }));
  };
}

function updateDraft(triggeredParams, searchScope) {
  return async (dispatch, getState) => {
    dispatch(updateLoadingFacets({ loadingFacets: true, searchScope }));

    const state = getState();
    const currentParams = getDraftParams(state, { searchScope });

    // On dispatch le stockage des params dans le redux
    const params = { ...currentParams };
    if (isArray(triggeredParams)) {
      triggeredParams.forEach(({ triggeredParam, value }) => {
        params[triggeredParam] = value;
      });
    } else {
      params[triggeredParams.triggeredParam] = triggeredParams.value;
    }
    dispatch(updateDraftParams({ params, searchScope }));

    // Récupération des facettes
    const searchData = await fetchFacetsService(params, searchScope);

    // Update du nombre total de résultats
    dispatch(updateDraftCount({ count: searchData.totalCount, searchScope }));

    // Stockage des facettes dans le store
    dispatch(updateDraftFacets({ facets: searchData.facets, params, searchScope }));

    dispatch(updateLoadingFacets({ loadingFacets: false, searchScope }));
  };
}

export function updateCurrentDraft(triggeredParams) {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    return dispatch(updateDraft(triggeredParams, searchScope));
  };
}

export function applyNewParams({ searchScope, params = {} }) {
  return async (dispatch, getState) => {
    dispatch(updateLoadingFacets({ loadingFacets: true, searchScope }));
    dispatch(updateDraftParams({ params, searchScope }));
    updateUrlQuery(params);
    dispatch(updateUrlParams({ params, searchScope }));

    const searchPerformed = isSearchPerformed(getState(), { searchScope });
    if (searchPerformed) {
      // // Récupération des facettes par défault
      const defaultFacetsFromSearch = await fetchFacetsService({}, searchScope);
      dispatch(updateDefaultFacets({ facets: defaultFacetsFromSearch.facets, params: {}, searchScope }));
    }
    // Récupération des facettes
    const searchData = await fetchFacetsService(params, searchScope);
    if (!searchPerformed) {
      dispatch(updateDefaultFacets({ facets: searchData.facets, params, searchScope }));
    }
    dispatch(updateUrlFacets({ facets: searchData.facets, params, searchScope }));
    dispatch(updateCount({ count: searchData.totalCount, searchScope }));
    dispatch(updateDraftFacets({ facets: searchData.facets, params, searchScope }));
    dispatch(updateDraftCount({ count: searchData.totalCount, searchScope }));

    dispatch(updateLoadingFacets({ loadingFacets: false, searchScope }));
  };
}

export function applyCurrentNewParams({ params = {} }) {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    return dispatch(applyNewParams({ searchScope, params }));
  };
}

function applyDraft({ searchScope, optionalParams = {} }) {
  return (dispatch, getState) => {
    const state = getState();
    const { force, updateDefaultFacets: _updateDefaultFacets, ...otherOptionalParams } = optionalParams;
    if (force || isDraftDirty(state, { searchScope })) {
      const draftParams = getDraftParams(state, { searchScope });
      dispatch(clearResultsData({ searchScope }));
      updateUrlQuery(draftParams);

      const params = { ...draftParams, ...otherOptionalParams };
      dispatch(updateUrlParams({ params, searchScope }));
      dispatch(fetchResults({ params, searchScope, updateFacets: true, updateDefaultFacets: _updateDefaultFacets ?? true }));
    }
  };
}

export function applyCurrentDraft(optionalParams) {
  return (dispatch, getState) => {
    const searchScope = getCurrentScope(getState());
    return dispatch(applyDraft({ optionalParams, searchScope }));
  };
}

export function initializeContext({ params, searchScope, infiniteScroll = false }) {
  return async (dispatch, getState) => {
    dispatch(updateSearchScope({ searchScope }));
    dispatch(updateLoadingFacets({ loadingFacets: true, searchScope }));
    dispatch(updateDraftParams({ params, searchScope }));
    updateUrlQuery(params);
    dispatch(updateUrlParams({ params, searchScope }));
    if (params.currency) {
      dispatch(updateCurrencyPreference({ currency: params.currency }));
    }
    if (params.dimension) {
      dispatch(updateDimensionPreference({ dimension: params.dimension }));
    }
    const searchPerformed = isSearchPerformed(getState(), { searchScope });
    if (searchPerformed) {
      // // Récupération des facettes par défault
      const defaultFacetsFromSearch = await fetchFacetsService({}, searchScope);
      dispatch(updateDefaultFacets({ facets: defaultFacetsFromSearch.facets, params: {}, searchScope }));
    }
    // Récupération des facettes
    const searchData = await fetchFacetsService(params, searchScope);
    if (!searchPerformed) {
      dispatch(updateDefaultFacets({ facets: searchData.facets, params, searchScope }));
    }
    dispatch(updateUrlFacets({ facets: searchData.facets, params, searchScope }));
    dispatch(updateCount({ count: searchData.totalCount, searchScope }));
    dispatch(updateDraftFacets({ facets: searchData.facets, params, searchScope }));
    dispatch(updateDraftCount({ count: searchData.totalCount, searchScope }));

    dispatch(updateLoadingFacets({ loadingFacets: false, searchScope }));
    dispatch(updateInfiniteScroll({ infiniteScroll, searchScope }));
  };
}
