import React from 'react';
import i18next from 'i18next';
import { formatFns } from '../../../../../utils/dates/format';

import './stylesheet.scss';

const CustomToolTip = ({ label, payload }) => {
  if (!payload || payload.length < 1) {
    return null;
  }

  const { start, end } = payload[0].payload;
  return (
    <div className="store-Stats-CustomToolTip">
      {start && end ? (
        <div>
          {`
            ${i18next.t('events.list.filters.dates.from')}
            ${formatFns(start, 'dd MMM')}
            ${i18next.t('marketplace.auction._show.html.m53')}
            ${formatFns(end, 'dd MMM')}
          `}
        </div>
      ) : (
        <div>{label}</div>
      )}
      {payload.map(({ color, value, name }) => (
        <div key={name} style={{ color }}>
          {name}: <strong>{value}</strong>
        </div>
      ))}
    </div>
  );
};

export default CustomToolTip;
