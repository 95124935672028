/* eslint-disable global-require */
module.exports = [
  'artprice.web.pages.classifieds.create.m31',
  'lot.show.access.m1',
  'marketplace._actions.html.m11',
  'marketplace._actions.html.m11',
  'marketplace._actions.html.m12',
  'marketplace._actions.html.m12',
  'marketplace.fineart._show.html.m10',
  'marketplace.fineart._show.html.m11',
  'marketplace.fineart._show.html.m12',
  'marketplace.fineart._show.html.m13',
  'marketplace.fineart._show.html.m21',
  'marketplace.fineart._show.html.m22',
  'marketplace.fineart._show.html.m23',
  'marketplace.fineart._show.html.m5',
  'marketplace.fineart.form._descriptive_details.html.m11',
  'marketplace.fineart.form._descriptive_details.html.m13',
  'marketplace.fineart.form._descriptive_details.html.m14',
  'marketplace.fineart.form._descriptive_details.html.m15',
  'marketplace.fineart.form._descriptive_details.html.m16',
  'marketplace.fineart.form._descriptive_details.html.m25',
  'marketplace.fineart.form._descriptive_details.html.m26',
  'marketplace.fineart.form._descriptive_details.html.m27',
  'marketplace.fineart.form._descriptive_details.html.m8',
  'marketplace.fineart.form._descriptive_details.html.m9',
  'marketplace.form._garantie.html.m10',
  'marketplace.form._garantie.html.m2',
  'marketplace.form._garantie.html.m2',
  'marketplace.form._garantie.html.m5',
  'marketplace.form._garantie.html.m5',
  'marketplace.form._garantie.html.m9',
  'marketplace.not_fineart._show.html.m17',
  'classifieds.fineart.form.html.m11',
  'marketplace.fineart.form._descriptive_details.html.m21',
  'marketplace.fineart.form._descriptive_details.html.m22',
  'indexes._advanced.m6',
  'marketplace._search.html.m8',

  'classifieds.fineart.form.html.m11',
];
