import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, ResponsiveContainer } from 'recharts';

const Graph = ({ data, currency }) => (
  <ResponsiveContainer width="100%" height={250}>
    <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="year" />
      <YAxis domain={['auto', 'auto']} />
      <Tooltip formatter={indicator => `${indicator} ${currency}`} />
      <Line type="monotone" dataKey="indicator" stroke="blue" />
    </LineChart>
  </ResponsiveContainer>
);

Graph.propTypes = {
  currency: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired,
      indicator: PropTypes.number,
    }),
  ).isRequired,
};

export default Graph;
