import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import SearchBar from '..';

const ProvidedSearchBarArtist = ({ searchScope, baseParams, hiddenFields, newSelectionKey, lotIds }) => (
  <ReduxProvider>
    <SearchBar searchScope={searchScope} baseParams={baseParams} hiddenFields={hiddenFields} newSelectionKey={newSelectionKey} lotIds={lotIds} />
  </ReduxProvider>
);

export default ProvidedSearchBarArtist;
