import React, { useRef } from 'react';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';
import { useHorizontalScroll } from '../../../../../hooks/useHorizontalScroll';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

const Testimonials = () => {
  const ref = useRef(null);
  const { pages, currentPage, goTo } = useHorizontalScroll({ ref });

  return (
    <div className="ails-info-testimonials-carousel">
      <h2>
        <A18n rsx="ails.info.m62" />
      </h2>

      <div className="testimonials" ref={ref}>
        <div className="testimonial">
          <div className="icon" />

          <div className="testimonial-info">
            <A18n rsx="ails.info.m61" />
          </div>
          <div className="testimonial-contact">
            Dr. Irene Lehr Kunstauktionen GmbH
            <br />
            <A18n rsx="ails.info.m70" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />

          <div className="testimonial-info">
            <A18n rsx="ails.info.m63" />
          </div>
          <div className="testimonial-contact">
            Pundoles
            <br />
            <A18n rsx="ails.info.m71" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />
          <div className="testimonial-info">
            <A18n rsx="ails.info.m65" />
          </div>
          <div className="testimonial-contact">
            Shapiro Auctions
            <br /> <A18n rsx="ails.info.m72" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />
          <div className="testimonial-info">
            <A18n rsx="ails.info.m66" />
          </div>
          <div className="testimonial-contact">
            Ravenel International Art Group
            <br /> <A18n rsx="ails.info.m73" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />
          <div className="testimonial-info">
            <A18n rsx="ails.info.m67" />
          </div>
          <div className="testimonial-contact">
            Palm Beach Auctions
            <br /> <A18n rsx="ails.info.m74" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />
          <div className="testimonial-info">
            <A18n rsx="ails.info.m68" />
          </div>
          <div className="testimonial-contact">
            Il Ponte Casa D’Aste
            <br /> <A18n rsx="ails.info.m75" />
          </div>
        </div>

        <div className="testimonial">
          <div className="icon" />
          <div className="testimonial-info">
            <A18n rsx="ails.info.m69" />
          </div>
          <div className="testimonial-contact">
            Martini Studio d’arte
            <br /> <A18n rsx="ails.info.m76" />
          </div>
        </div>
      </div>

      <div className="pages">
        <div className="filler" />
        {Array.from(Array(pages).keys()).map(p => (
          <div key={p} onClick={() => goTo(p + 1)} className="page">
            <img alt="page" src={`${IMAGE_SOURCE}ail/ail-ellipse-${p + 1 === currentPage ? 'bleue' : 'grise'}.svg`} />
          </div>
        ))}
        <div className="filler" />
      </div>
    </div>
  );
};

export default Testimonials;
