import React from 'react';
import A18n from '../../../../common/A18n';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

import './stylesheet.scss';

const Subscribe = () => (
  <a className="artp-Header-subscribe-link artp-Header-item" href="/subscription">
    <img src={`${IMAGE_SOURCE}menu/carte-credit.svg`} alt="card" />
    <span>
      <A18n rsx="layouts.header.m68" />
    </span>
  </a>
);

export default Subscribe;
