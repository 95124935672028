import { useCallback, useRef } from 'react';
import axios from 'axios';

export default function useCancellable(searchFunc, deps = []) {
  const abortControllerRef = useRef();

  return useCallback((...args) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return searchFunc(...args, abortController.signal).catch(err => {
      if (!axios.isCancel(err)) {
        throw new Error(err);
      }
    });
  }, deps);
}
