import React from 'react';
import { useSelector } from 'react-redux';
import { getLang } from '../../../../redux/selectors/preferences';
import { getArtist } from '../../../../redux/slices/artist';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Adagp = () => {
  const artist = useSelector(getArtist);
  const lang = useSelector(getLang);

  if (!artist.adagp) return null;

  return (
    <div className="artists-artist-adagp-or-copyright">
      <div className="container">
        <div className="adagp">
          <div className="picto">
            <a href={`https://www.adagp.fr${lang !== 'fr' ? '/en' : ''}`}>
              <img alt="adagp" src="https://www.adagp.fr/themes/adagp/logo-en.svg" />
            </a>
          </div>
          <div className="content">
            <p>
              <A18n rsx="artists.artist.adagp.m1" replace={[['%artist%', artist.name]]} />
            </p>
            <p>
              <a href={`https://www.adagp.fr${lang !== 'fr' ? '/en' : ''}`}>
                <A18n rsx="artists.artist.adagp.m2" />
              </a>
            </p>
          </div>
        </div>
        {artist.copyright && <div className="copyright">{artist.copyright}</div>}
      </div>
    </div>
  );
};

export default Adagp;
