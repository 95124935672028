/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isNil, isFunction } from 'lodash';
import { NONEXISTENTFORMID } from '../../../../constants/inputs';

import './stylesheet.css';
import { EMPTY_OBJECT } from '../../../../constants/utils';

const InnerFlipSwitch = ({ className, style = EMPTY_OBJECT, onChange, onClick, name, id = uuidv4(), disabled, checked, label, light, title, ...otherProps }) => {
  const [domId] = useState(id);
  const undetermined = isNil(checked);

  return (
    <div
      onClick={e => {
        if (onClick) {
          onClick(e);
        } else {
          console.warn(`no onClick defined for FlipSwitch #${domId}`);
        }
      }}
      className={['artp-flipswitch-container', light && 'light', className]
        .filter(e => !!e)
        .join(' ')
        .trim()}
      style={style}
    >
      <input
        form={undetermined ? NONEXISTENTFORMID : undefined}
        className="input-hidden"
        type="checkbox"
        name={name || domId}
        id={domId}
        checked={undetermined ? false : checked}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          } else {
            console.warn(`no OnChange defined for FlipSwitch #${domId}`);
          }
        }}
        disabled={disabled}
        {...otherProps}
      />
      <label htmlFor={domId} className={`artp-flipswitch ${disabled ? 'disabled' : ''} ${undetermined ? 'undetermined' : ''}`.trim()} title={title} />
      {label && (
        <label htmlFor={domId} className={`artp-input-label ${disabled ? 'disabled' : ''}`.trim()} title={title}>
          {isFunction(label) ? label({ id, checked, disabled, undetermined }) : label}
        </label>
      )}
    </div>
  );
};

/* Controlled FlipSwitch */

const FlipSwitch = ({ className, style, onChange, onClick, name, id = uuidv4(), disabled, checked, label, light, title, ...otherProps }) => {
  if (otherProps.hasOwnProperty('defaultChecked')) {
    throw new Error("You mustn't use the defaultChecked property on a controlled input. Please use UncontrolledFlipSwitch instead.");
  }
  Object.assign(otherProps, {
    className,
    style,
    onChange,
    onClick,
    name,
    id,
    disabled,
    checked,
    label,
    light,
    title,
  });
  return <InnerFlipSwitch {...otherProps} />;
};

/* Uncontrolled FlipSwitch */

export const UncontrolledFlipSwitch = ({ className, style, onChange, name, id = uuidv4(), disabled, defaultChecked, label, light, title, ...otherProps }) => {
  const [checked, setChecked] = useState(defaultChecked);
  const handleChange = newValue => {
    setChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  if (otherProps.hasOwnProperty('checked')) {
    throw new Error("You mustn't use the checked property on an uncontrolled input. Please use FlipSwitch instead.");
  }
  Object.assign(otherProps, {
    className,
    style,
    name,
    id,
    disabled,
    label,
    light,
    title,
  });
  return <InnerFlipSwitch checked={checked} onChange={handleChange} {...otherProps} />;
};

export default FlipSwitch;
