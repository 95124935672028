import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Prices from '../../../inputs/Prices';

const Price = ({ withOptionalCurrencies }) => (
  <Item label={i18next.t('marketplace.searchbars.classifieds.bar.m9')} name={{ price_from: 'from', price_to: 'to', price_idcurrency: 'currency' }}>
    <Prices withOptionalCurrencies={withOptionalCurrencies} />
  </Item>
);

export default Price;
