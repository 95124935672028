import i18Next from 'i18next';
import React, { useEffect, useState } from 'react';
import { isEmpty, isPlainObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { moveLots, createAndMoveLots, fetchLists, getLists } from '../../../../../redux/slices/account/lotslists';
import SvgIcon from './SvgIcon';
import ListSelect from '../../common/modal/ListSelect';
import Modal from '../../../../common/ui/Modal';

import '../../stylesheet-common.scss';
import './stylesheet.scss';

/*
  afterMove is a function
 */
const MoveButton = ({ list, idlots = [], sort, afterMove }) => {
  const dispatch = useDispatch();
  const [label, setLabel] = useState();
  const [selectedIdlist, setSelectedIdlist] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const lists = useSelector(getLists);

  useEffect(() => {
    if (Object.values(lists).length === 0) {
      dispatch(fetchLists());
    }
  }, []);

  const handleSubmit = async () => {
    if (selectedIdlist > 0) {
      dispatch(moveLots({ idfrom: list.id, idto: selectedIdlist, idlots, sort })).then(afterMove);
    } else {
      dispatch(createAndMoveLots({ label, idfrom: list.id, idlots, sort })).then(afterMove);
    }

    setVisibility(false);
  };

  return (
    <div className="account-lots-lists-create-button">
      <Modal open={visibility} onClose={() => setVisibility(false)} className="account-lots-lists-create-button-modale">
        <p className="title">{i18Next.t('components.lotslists.show.m2')}</p>
        <input onChange={e => setLabel(e.target.value)} placeholder={i18Next.t('components.lotslists.create.m4')} />
        {isPlainObject(lists) && !isEmpty(lists) && (
          <>
            <p>{i18Next.t('components.lotslists.modal.m1')}</p>
            <p className="title">{i18Next.t('components.lotslists.show.m12')}</p>
            <ListSelect lists={lists} onChange={e => setSelectedIdlist(parseInt(e.target.value, 10))} hiddenIds={[list.id]} />
          </>
        )}
        <div className="action-buttons">
          <button className="lots-lists-button" type="button" onClick={() => setVisibility(false)}>
            {i18Next.t('components.myartprice.settings.artists.m3')}
          </button>
          <button type="button" className="lots-lists-button primary" onClick={handleSubmit}>
            {i18Next.t('components.lotslists.show.m4')}
          </button>
        </div>
      </Modal>

      <button className="lots-lists-button lots-lists-move-button" disabled={idlots.length === 0} type="button" onClick={() => setVisibility(true)}>
        <SvgIcon color={idlots.length === 0 ? '#999' : '#000'} />
        {i18Next.t('components.lotslists.show.m13')}
      </button>
    </div>
  );
};
export default MoveButton;
