import React from 'react';
import { FormattedNumber } from 'react-intl';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import { typeformsMediaKit } from '../constants';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Spaces = ({ lang }) => (
  <div className="ails-info-spaces">
    <h2>
      <A18n rsx="ails.info.m55" />
    </h2>

    <p className="h2-subtitle">
      <A18n rsx="ails.info.m56" />
    </p>

    <div className="blocks">
      <div className="block a">
        <h3>
          <A18n rsx="ails.info.m4" />
        </h3>
        <div className="numbers">
          <div className="number">
            <div>
              <FormattedNumber value={180000} />
            </div>
            <div>
              <A18n rsx="ails.info.m5" />
            </div>
          </div>
          <div className="number">
            <div>
              <A18n rsx="ails.info.m6" />
            </div>
            <div>
              <A18n rsx="ails.info.m7" />
            </div>
          </div>
        </div>
        <div className="options">
          <div>
            <A18n rsx="ails.info.m8" />
          </div>
          <div>
            <A18n rsx="ails.info.m9" />
          </div>
          <div>
            <A18n rsx="ails.info.m10" />
          </div>
        </div>
      </div>
      <div className="block b">
        <h3>
          <A18n rsx="ails.info.m11" />
        </h3>
        <div className="numbers">
          <div className="number">
            <div>50 %</div>
            <div>
              <A18n rsx="ails.info.m12" />
            </div>
          </div>
        </div>
        <div className="options">
          <div>
            <A18n rsx="ails.info.m13" />
          </div>
        </div>
      </div>
      <div className="block c">
        <h3>
          <A18n rsx="ails.info.m17" />
        </h3>
        <div className="numbers">
          <div className="number">
            <div>
              <FormattedNumber value={700000} />
            </div>
            <div>
              <A18n rsx="ails.info.m7" />
            </div>
          </div>
        </div>
        <div className="options">
          <div>
            <A18n rsx="ails.info.m19" />
          </div>
        </div>
      </div>

      <div className="block d">
        <h3>
          <A18n rsx="ails.info.m29" />
        </h3>
        <div className="numbers">
          <div className="number">
            <div>
              <FormattedNumber value={50000} />
            </div>
            <div>
              <A18n rsx="ails.info.m30" />
            </div>
          </div>
        </div>
        <div className="options">
          <div>
            <A18n rsx="ails.info.m31" />
          </div>
        </div>
      </div>
      <div className="block e">
        <h3>
          <A18n rsx="ails.info.m20" />
        </h3>
        <div className="numbers">
          <div className="number">
            <img className="fb" src={`${IMAGE_SOURCE}social/facebook-logo.svg`} alt="facebook" />
            <div>
              <A18n rsx="ails.info.m21" />
            </div>
            <div>
              <A18n rsx="ails.info.m15" />
            </div>
          </div>
          <div className="number">
            <img className="x" src={`${IMAGE_SOURCE}social/x-logo.svg`} alt="facebook" />
            <div>
              <A18n rsx="ails.info.m23" />
            </div>
            <div>
              <A18n rsx="ails.info.m15" />
            </div>
          </div>
        </div>
        <div className="options social">
          <div>Facebook</div>
          <div>Twitter</div>
        </div>
      </div>
      <div className="block f">
        <h3>
          <A18n rsx="ails.info.m25" />
        </h3>
        <div className="numbers">
          <div className="number">
            <div>
              <A18n rsx="ails.info.m26" />
            </div>
            <div>
              <A18n rsx="ails.info.m27" />
            </div>
          </div>
        </div>
        <div className="options">
          <div>
            <A18n rsx="ails.info.m28" />
          </div>
        </div>
      </div>

      <div className="block g">
        <p>
          <A18n rsx="ails.info.m57" />
        </p>
        <p>
          <A18n rsx="ails.info.m58" />
        </p>
        <p>
          <A18n rsx="ails.info.m59" />
        </p>
        <p className="title">
          <A18n rsx="ails.info.m33" />
        </p>
        <div>
          <a className="btn btn-default" href={`https://artprice.typeform.com/to/${typeformsMediaKit[lang] || typeformsMediaKit.en}`} target="typeforms">
            <A18n rsx="ails.info.m34" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Spaces;
