import React from 'react';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_SALE_DETAIL, ANALYTICS_FROM_AIL_LANDINGPAGE, ANALYTICS_SECTION_LANDINGPAGE_CALENDAR } from '../../../../constants/rails';
import A18n from '../../../common/A18n';

const Agenda = ({ data }) => {
  const { agenda, salesUrl, salesCount, DisplayedSalesCount } = data;

  if (salesCount === 0) return null;

  return (
    <div className="ails-landingpage-agenda">
      <div className="agenda-container">
        <div className="container">
          <h2>
            <A18n rsx="ails.landingpage.agenda.m1" />
          </h2>
          <div className="agenda">
            {agenda.map(entry => (
              <p key={entry.id}>
                <span className="dt">
                  {(entry.dtStart !== entry.dtEnd && (
                    <A18n
                      rsx="ails.landingpage.agenda.m3"
                      replace={[
                        ['%dtstart%', entry.dtStart],
                        ['%dtend%', entry.dtEnd],
                      ]}
                    />
                  )) ||
                    entry.dtStart}
                </span>
                <span>
                  {entry.city} - {entry.country} -{' '}
                </span>
                <a
                  href={entry.url}
                  onClick={() => aapiBeacon('click', { idsale: entry.id, from: ANALYTICS_FROM_AIL_LANDINGPAGE, op: ANALYTICS_CLICK_OPERATION_TO_SALE_DETAIL, section: ANALYTICS_SECTION_LANDINGPAGE_CALENDAR })}
                >
                  {entry.title}&nbsp;→
                </a>
              </p>
            ))}
            {salesCount > DisplayedSalesCount && (
              <p className="all">
                <a href={salesUrl} className="btn btn-default">
                  <A18n rsx="ails.landingpage.agenda.m2" />
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
