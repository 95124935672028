import React, { Component } from 'react';
import { array, func, number, oneOfType, string } from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const capitalize = label => label && `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

export class CountriesDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { dropdownOpen } = this.state;
    const { options, choose, selectedValue } = this.props;
    if (options.length <= 0) return null;

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="country-filter">
        <DropdownToggle caret>Country</DropdownToggle>

        <DropdownMenu>
          <DropdownItem header>Choose a country</DropdownItem>
          <DropdownItem divider />
          {options.map(({ value, label }) => (
            <DropdownItem key={value} disabled={value === selectedValue} value={value} onClick={() => choose(value)}>
              {capitalize(label)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

CountriesDropdown.defaultProps = {
  choose: () => undefined,
  selectedValue: undefined,
};

CountriesDropdown.propTypes = {
  // eslint-disable-next-line
  options: array.isRequired,

  selectedValue: oneOfType([string, number]),
  choose: func,
};
