import React from 'react';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Reports = ({ mainReport }) => (
  <div className="account-signin-confirmation-report" style={{ backgroundImage: `url("${mainReport.image}")` }}>
    <div className="bg" />
    <div className="content">
      <h2>
        <A18n rsx="account.signinconfirmation.report.m1" />
      </h2>
      <p>
        <A18n rsx="account.signinconfirmation.report.m2" />
      </p>
      <a href={`/artprice-reports/${mainReport.url}`} className="artp-btn artp-btn-transparent">
        <A18n rsx="account.signinconfirmation.report.m3" />
      </a>
    </div>
  </div>
);

export default Reports;
