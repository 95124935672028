import React from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { on as openDrawer } from '../../../../../redux/actions/ui/switches';
import { MYARTPRICE_DRAWER_ID } from '../../../../../constants/myartprice';

const linkOpenDrawer = ({ className, title, carret = true }) => {
  const dispatch = useDispatch();

  return (
    <a className={`pointer ${className || ''}`} role="presentation" title={i18next.t('marketplace.searchbars.classifieds.bar.m3')} onClick={() => dispatch(openDrawer(MYARTPRICE_DRAWER_ID))}>
      {title}
      {carret && <i style={{ marginLeft: 10 }} className="fa fa-caret-down" />}
    </a>
  );
};

export default linkOpenDrawer;
