import React from 'react';
import { useDispatch } from 'react-redux';
import { IMAGE_SOURCE, RETURN_AFTER_LOGIN_COOKIE_KEY } from '../../../../../../constants/rails';
import { sendValidationEmail } from '../../../../../../redux/actions/account';
import { set as setCookie } from '../../../../../../utils/cookie';
import Drawer from '../../../../../common/Drawer';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const UnvalidatedEmailDrawer = ({ drawerId, onClose }) => {
  const dispatch = useDispatch();

  return (
    <Drawer
      id={drawerId}
      side="right"
      className="report-reports-noaccessdrawer-container"
      render={() => (
        <>
          <div className="close" onClick={onClose}>
            <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
          </div>
          <div className="report-reports-noaccessdrawer-content">
            <div>
              <p>
                <img alt="drawer-lots" src={`${IMAGE_SOURCE}reports/drawer-no-access-white.svg`} style={{ width: '119px' }} />
              </p>
              <h3>
                <A18n rsx="artmarketinsight.reports.m10" />
              </h3>
              <p>
                <A18n rsx="artmarketinsight.reports.m16" />
              </p>
              <p>
                <a
                  onClick={async e => {
                    e.preventDefault();

                    setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, window.location.href);
                    await dispatch(sendValidationEmail({ backTo: 'ral' }));
                    onClose();
                  }}
                  className="btn btn-default link sln-link report-reports-noaccessdrawer-connect"
                >
                  <A18n rsx="artmarketinsight.reports.m17" />
                </a>
              </p>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default UnvalidatedEmailDrawer;
