import i18Next from 'i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Details from '../../../../lots/Show';
import { areCurrentResultsLoading } from '../../../../../redux/selectors/search/results';
import CommonHorizontalBar from '../../../../common/HorizontalBar';
import ButtonBack from '../../../../common/ButtonBack';
import PdfLink from '../../pdf/Link';
import Preferences from '../../../../common/Preferences';

import './stylesheet.scss';

const PREFERENCES_HIDDEN_FIELDS = { perPage: true, lotsViewFormat: true, artistsViewFormat: true };

const Show = () => {
  const { idlot, id, crypto } = useParams();
  const loading = useSelector(areCurrentResultsLoading) || false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <i className="fa fa-spinner fa-spin fa-5x fa-fw" />
      </div>
    );
  }

  return (
    <div className="myartprice-lot container">
      <CommonHorizontalBar className="bar">
        <div className="container">
          <div className="filters-container">
            <ButtonBack />
            <PdfLink
              idlot={idlot}
              id={id}
              anchor={i18Next.t('components.lotslists.show.m1')}
              style={{
                marginLeft: 10,
                marginTop: 15,
                marginBottom: 15,
              }}
            />
            <div className="flex-filler" />
            <Preferences withOptionalCurrencies right hiddenFields={PREFERENCES_HIDDEN_FIELDS} />
          </div>
        </div>
      </CommonHorizontalBar>
      <Details id={idlot} crypto={crypto} />
    </div>
  );
};

export default Show;
