import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get as getUiDictionary } from '../../../redux/selectors/ui/dictionary';
import { VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../constants/myartprice';
import { set } from '../../../redux/actions/ui/dictionary';

export function useInitializeActiveShorcut({ activeShortcut }) {
  const dispatch = useDispatch();
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  useEffect(() => {
    dispatch(set(VERTICAL_BAR_CURRENT_ELEMENT_KEY, activeShortcut));
  }, [activeShortcut]);

  return { currentElement };
}
