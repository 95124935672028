import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Modal from '../../common/ui/Modal';
import { isLogged as isLoggedSelector } from '../../../redux/slices/userContext/selectors';

import './stylesheet.scss';

const LoginOrSubscribeModale = ({ open, setOpen }) => {
  const isLogged = useSelector(isLoggedSelector);

  function createMarkup() {
    return { __html: i18next.t('components.myartprice.followlot.m1') };
  }

  return (
    <Modal className="modal-subscription" title={i18next.t('components.myartprice.followlot.m2')} open={open} onClose={() => setOpen(false)}>
      <div className="text-center pad pad-t-25 col-xs-12 col-sm-12 pad pad-t-10 pad-b-10 font font-18">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
      <br />
      <div className="col-md-12 text-right pad pad-t-10 pad-b-10">
        {/* Return */}
        <Button onClick={() => setOpen(false)}>{i18next.t('component.myartprice.lots.detail.m1')}</Button>
        &nbsp;
        {/* Connection */}
        {!isLogged && (
          <Button
            onClick={() => {
              window.location = '/identity';
            }}
          >
            {i18next.t('layouts.header.m3')}
          </Button>
        )}
        &nbsp;
        {/* See subscriptions */}
        <a
          className="btn btn-primary"
          onClick={() => {
            window.location = '/subscription';
          }}
        >
          {i18next.t('indexes.subscription_needed.buy_btn')}
        </a>
      </div>
    </Modal>
  );
};

export default LoginOrSubscribeModale;
