import React from 'react';
import './stylesheet.scss';

const Spinner = ({ className = '', faSize = 5 }) => (
  <div className={`common-Spinner ${className}`}>
    <div className="spinner">
      <i className={`fa fa-spinner fa-spin fa-${faSize}x fa-fw`} />
    </div>
  </div>
);

export default Spinner;
