/* eslint-disable global-require */
module.exports = [
  'payments.common.cartsummary.promocode.m1',
  'payments.common.cartsummary.promocode.m2',
  'payments.common.cartsummary.promocode.m3',
  'payments.common.cartsummary.promocode.m4',
  'payments.common.cartsummary.promocode.m5',
  'payments.common.cartsummary.promocode.m6',
  'payments.common.cartsummary.promocode.m7',
];
