/* eslint-disable global-require */
module.exports = [
  'components.account.emailvalidbadge.badge.m1',
  'components.account.emailvalidbadge.badge.m2',
  'components.account.emailvalidbadge.badge.m3',
  'components.account.emailvalidbadge.badge.m4',
  'components.account.emailvalidbadge.m1',
  'components.account.emailvalidbadge.m2',
  'components.account.emailvalidbadge.m3',
];
