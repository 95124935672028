import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const CategorySelect = () => (
  <Item label={i18next.t('search.helpers.items.category.select.m2')} name="idcategory">
    <Select facet="idcategory" placeholder={i18next.t('search.helpers.items.category.select.m1')} />
  </Item>
);

export default CategorySelect;
