import React from 'react';
import Dropdown from '../Dropdown';
import Content from './Content';
import { FLAGS } from '../../../../../../constants/locales';

import './stylesheet.scss';

const Langs = ({ locale }) => (
  <Dropdown
    className="artp-Header-item artp-Header-top-item"
    button={
      <div className="artp-Header-Langs">
        <span className={`fi fi-${FLAGS[locale]} fis artp-Header-Langs-flag artp-Header-Langs-flag-button`} />
        {locale.toUpperCase()}
      </div>
    }
    content={<Content locale={locale} />}
  />
);

export default Langs;
