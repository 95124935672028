import React from 'react';
import i18next from 'i18next';

import './stylesheet.scss';

const SearchBar = ({ formClassName, inputClassName, placeholder, className, onSubmit, onChange, defaultValue, withoutButton = false, label, ...otherProps }) => {
  const handleChange = v => {
    onChange?.(v);
  };

  return (
    <form className={formClassName} onSubmit={onSubmit} {...otherProps}>
      {label && <div className="artp-input-label">{label}</div>}
      <div className={`artp-search-bar ${!withoutButton ? 'artp-input-group' : ''} ${className || ''}`.trim()}>
        <input className={inputClassName} type="text" defaultValue={defaultValue} placeholder={placeholder || i18next.t('layouts.menu.m2')} onChange={e => handleChange(e.target.value)} />
        {!withoutButton && (
          <button type="submit" className="btn">
            <i className="fa fa-search" />
          </button>
        )}
      </div>
    </form>
  );
};

export default SearchBar;
