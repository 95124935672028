import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBestClassifieds, getDisplayedPeriod, isBestClassifiedsLoading } from '../../../../../redux/selectors/marketplace/stores/stats';
import { fetchBestClassifieds } from '../../../../../redux/actions/marketplace/stores/stats';

import './stylesheet.scss';
import Spinner from '../../../../common/spinners/Spinner';

const BestClassifieds = () => {
  const dispatch = useDispatch();
  const period = useSelector(getDisplayedPeriod);

  const params = {
    startDate: period?.start,
    endDate: period?.end,
  };
  const loading = useSelector(state => isBestClassifiedsLoading(state, params));
  const list = useSelector(state => getBestClassifieds(state, params));

  useEffect(() => {
    if (period) {
      dispatch(
        fetchBestClassifieds({
          startDate: period.start,
          endDate: period.end,
        }),
      );
    }
  }, [period]);

  if (!period) {
    return null;
  }

  return (
    <div className="store-Stats-BestClassifieds">
      {loading && <Spinner />}
      {!loading && (
        <>
          {(!Array.isArray(list) || list.length < 1) && <small className="col-xs-12 text-center">{i18next.t('api.store_controller.m5')}</small>}
          {list?.map(({ classified }) => (
            <div key={classified.id} className="store-Stats-BestClassifieds-item">
              <div className="img">
                <a href={classified.url}>
                  <img className="shadow" title={classified.image_alt} alt={classified.image_alt} src={classified.image_url} />
                </a>
              </div>
              {classified.title && classified.title.trim() !== '' && (
                <div className="title">
                  <a href={classified.url}>{classified.title}</a>
                </div>
              )}
              {classified.artist && (
                <div className="artist">
                  <a href={classified.artist_url}>{classified.artist}</a>
                </div>
              )}
              <div className="category">{classified.category}</div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default BestClassifieds;
