import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

import './stylesheet.scss';

const Pill = ({ children }) => (
  <div className="artp-Header-Pill">
    <img className="pill-logo" src={`${IMAGE_SOURCE}menu/pastille.svg`} alt="pill" />
    {children}
  </div>
);

export default Pill;
