import React from 'react';

const Location = ({ lot }) => (
  <div className="lots-lot-no-access-location">
    <div>
      {lot.saleDtStart}, {lot.saleCountry}
    </div>
  </div>
);

export default Location;
