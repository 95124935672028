import i18next from 'i18next';

export const fileSize6M = {
  name: 'fileSize6M',
  message: i18next.t('javascripts.locales.m22'),
  test: value => {
    if (!Array.isArray(value)) return true;
    if (!value[0]) return true;
    return value[0].size <= 6000000;
  },
};

const fileRequired = url => ({
  name: 'exist',
  message: i18next.t('components.account.verify.identity.m1'),
  test: (value, form) => {
    // Doc supp apres chargement
    if (Array.isArray(value) && !value[0]) {
      return false;
    }

    return (form?.from || [])[0]?.value[url] || (value || [])[0]?.path;
  },
});

export const firmDocumentRequired = fileRequired('firmDocumentUrl');
export const idDocumentRequired = fileRequired('idDocumentUrl');

export const fileFormat = {
  name: 'fileFormat',
  message: i18next.t('javascripts.locales.m4'),
  test: value => {
    if (!Array.isArray(value)) return true;
    if (!value[0]) return true;
    return ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'].includes(value[0].type);
  },
};
