import React from 'react';
import '../../../common/stylesheets/buttons.scss';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const FreeDemo = () => (
  <div className="account-signin-confirmation-free-demo">
    <div className="content">
      <div className="l1">
        <A18n rsx="account.signinconfirmation.freedemo.m1" />
      </div>
      <div className="l2">
        <A18n rsx="account.signinconfirmation.freedemo.m2" />
      </div>
      <div className="l3">
        <a href="/search" className="artp-btn artp-btn-transparent">
          <A18n rsx="account.signinconfirmation.freedemo.m3" />
        </a>
      </div>
    </div>
  </div>
);

export default FreeDemo;
