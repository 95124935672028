import React from 'react';
import { LengthUnit } from '../../../../../../constants/marketplace';
import FormatedDimensions from './FormatedDimensions';
import './stylesheet.scss';

const convertSize = (size, unitFrom, unitTo) => {
  if (!size || unitFrom === unitTo) return size;
  if (unitTo === LengthUnit.CM) return size * 2.54;
  return size / 2.54;
};

const Dimensions = ({ classified, withFrame }) => {
  const unit = classified.idclassifieddimensionunit === 1 ? 'cm' : 'in';
  const unitConverted = unit === 'in' ? 'cm' : 'in';
  let dimensions;

  if (withFrame) {
    dimensions = [classified.heightwithframe, classified.widthwithframe, classified.lengthwithframe].filter(d => d > 0);
  } else {
    dimensions = [classified.height, classified.width, classified.length].filter(d => d > 0);
  }

  if (dimensions.length === 0) return null;

  const dimensionsConverted = dimensions.map(dim => convertSize(dim, unit, unitConverted));

  return (
    <div className="classified-common-dimensions">
      <FormatedDimensions dimentions={dimensions} unit={unit} />
      <i>
        (<FormatedDimensions dimentions={dimensionsConverted} unit={unitConverted} />)
      </i>
    </div>
  );
};

export default Dimensions;
