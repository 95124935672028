/* eslint-disable no-bitwise */
import React from 'react';

const Logo = ({ className, height = 77, width = 58 }) => (
  <svg xmlns="<http://www.w3.org/2000/svg>" xmlSpace="preserve" viewBox="0 0 68 77" height={height} width={width} className={className}>
    <style type="text/css">{`.st0{fill:#FFFFFF;}`}</style>
    <g id="Artprice">
      <rect x="0.02" y="0.14" width="68" height="68" fill="black" />
      <g>
        <path
          className="st0"
          d="M8.31,51.5l-2.63,2.63c-0.06,0.06-0.11,0.12,0.07,0.3l0.96,0.96l-0.76-0.05c-0.99-0.07-2.05,0.36-2.82,1.13
			c-2.22,2.22-2.03,5.89,0.43,8.34c2.64,2.64,6.03,2.81,8.43,0.41c0.67-0.67,1.35-1.77,1.28-2.88l-0.05-0.76l0.96,0.96
			c0.12,0.11,0.24,0.02,0.28-0.02l2.43-2.43c0.04-0.04,0.11-0.11-0.1-0.32l-8.24-8.24C8.5,51.5,8.4,51.42,8.31,51.5z M9.99,62.13
			c-1.01,1.01-2.53,1-3.55-0.02c-1.11-1.11-1.14-2.56-0.09-3.62c1.02-1.02,2.48-0.98,3.55,0.09C10.96,59.63,10.99,61.13,9.99,62.13z
			 M27.92,44.85c-0.04-0.03-0.16-0.11-0.27-0.09c-0.03,0.01-0.11,0.03-0.21,0.19c-0.38,0.59-0.82,0.92-1.28,0.97
			c-0.4,0.04-0.78-0.12-1.13-0.47l-3.18-3.18l1.48-1.44c0.08-0.08,0.15-0.18-0.05-0.38l-1.83-1.83c-0.08-0.08-0.16-0.06-0.26,0.03
			l-1.48,1.48l-2.07-2.14c-0.06-0.07-0.32-0.21-0.56,0.03l-2.43,2.43c-0.07,0.07-0.11,0.11,0.1,0.33c0,0,2.11,2.21,2.11,2.21
			l-0.77,0.78c-0.06,0.06-0.06,0.12,0,0.2l0.26,0.4l-0.47,0.04c-0.69,0.06-1.34,0.4-1.95,1.01c-0.63,0.63-1,1.61-0.93,2.51
			l0.06,0.76l-0.76-0.77c-0.13-0.13-0.18-0.15-0.19-0.15c0,0-0.04,0.01-0.11,0.08l-2.55,2.55c-0.08,0.08-0.09,0.11-0.09,0.11
			c0.01,0.04,0.1,0.13,0.15,0.18l8.19,8.19c0.21,0.21,0.22,0.21,0.33,0.1l2.63-2.63c0.06-0.06,0.11-0.11-0.05-0.27l-5.25-5.26
			l-0.02-0.08c-0.09-0.51-0.12-1.1,0.39-1.61c0.79-0.78,1.59-0.32,2.14,0.22c0.13-0.13,0.29-0.32,0.3-0.48
			c0.01-0.23-0.01-1.23-0.02-2.11c0,0-0.01-0.75-0.01-0.75l0.15-0.14l0.73-0.73L22,48.13c1.54,1.54,3.65,3.65,6.64,0.67
			c0.74-0.74,1.06-1.66,1.26-2.21c0.06-0.2,0.01-0.29-0.09-0.37C29.53,46.01,28.14,45,27.92,44.85z M57.28,14.84
			c-0.04,0.02-3.08,1.23-3.08,1.23c-0.1,0.04-0.16,0.08-0.18,0.12c-0.02,0.04-0.01,0.09,0.03,0.18c0.22,0.6,0.11,1.59-0.64,2.34
			c-1.06,1.06-2.54,1.06-3.6,0c-1.12-1.11-1.14-2.54-0.05-3.62c0.68-0.68,1.65-0.96,2.36-0.69c0.15,0.06,0.18,0.02,0.2-0.02
			c0.02-0.04,1.3-3.18,1.3-3.18c0.04-0.1,0.05-0.17-0.02-0.24c-0.03-0.03-0.06-0.06-0.12-0.08c-2.21-1.01-4.59-0.47-6.54,1.48
			c-2.62,2.62-2.57,6.47,0.12,9.16c2.68,2.68,6.32,2.66,9.04-0.07c1.86-1.86,2.38-4.03,1.53-6.46c-0.02-0.05-0.05-0.09-0.08-0.12
			C57.49,14.83,57.4,14.81,57.28,14.84z M55.7,3.67c-2.68,2.67-2.68,6.27,0,8.94c2.75,2.74,6.39,2.79,9.06,0.12
			c1.28-1.28,1.94-2.97,2.01-4.01c0.02-0.34-0.12-0.41-0.28-0.44c-0.9-0.19-2.08-0.4-2.89-0.53c-0.12-0.02-0.21,0-0.26,0.04
			c-0.02,0.02-0.03,0.05-0.03,0.07c0.09,1.07-0.18,1.91-0.79,2.52c-0.97,0.97-2.14,1.13-3.07,0.43l-0.26-0.2l5.15-5.15
			c0.24-0.24,0.26-0.31,0.13-0.5c-0.22-0.35-0.45-0.65-1.05-1.26C61.17,1.46,57.92,1.45,55.7,3.67z M57.73,9.16l-0.19-0.3
			c-0.63-0.97-0.57-1.95,0.17-2.68C58.5,5.4,59.48,5.33,60.42,6l0.27,0.2L57.73,9.16z M41.79,18.02l-2.61,2.61
			c-0.06,0.06-0.07,0.09-0.07,0.09c0.01,0.04,0.1,0.13,0.16,0.19l0.81,0.81l-0.85-0.12c-0.87-0.12-1.74,0.22-2.52,1
			c-0.63,0.63-1,1.62-0.93,2.51l0.06,0.77l-0.76-0.76c-0.13-0.13-0.18-0.15-0.19-0.15c0,0-0.04,0.01-0.11,0.08l-2.54,2.55
			c-0.08,0.08-0.08,0.11-0.08,0.11c0.01,0.04,0.1,0.13,0.15,0.18l8.19,8.19c0.21,0.21,0.22,0.21,0.33,0.1l2.63-2.63
			c0.07-0.07,0.12-0.11-0.05-0.28l-5.26-5.26l-0.02-0.08c-0.1-0.51-0.13-1.1,0.38-1.61c0.78-0.79,1.59-0.32,2.14,0.21
			c0.13-0.13,0.29-0.32,0.29-0.48c0.01-0.23,0-1.22-0.02-2.09c0-0.02-0.02-1.4-0.02-1.4l6.55,6.55c0.21,0.21,0.22,0.21,0.33,0.1
			l2.61-2.61c0.1-0.1,0.12-0.14-0.03-0.29l-8.24-8.24C41.94,17.88,41.93,17.89,41.79,18.02z M27.41,32.1
			c-0.79,0.79-1.32,1.89-1.33,2.75l-0.01,0.68l-0.71-0.71c-0.13-0.13-0.17-0.15-0.19-0.15c0,0-0.03,0.01-0.11,0.08l-2.5,2.5
			c-0.06,0.06-0.07,0.09-0.07,0.09c0.01,0.04,0.1,0.13,0.16,0.19l11.02,11.02c0.22,0.22,0.22,0.21,0.31,0.12l2.65-2.65
			c0.09-0.08,0.11-0.11-0.09-0.31l-3.42-3.42l0.63-0.04c0.78-0.05,1.73-0.49,2.4-1.11c1.24-1.14,1.91-2.5,1.95-3.93
			c0.03-1.59-0.74-3.23-2.24-4.72C32.61,29.21,29.28,30.23,27.41,32.1z M33.31,38.85c-0.89,0.89-2.43,1.17-3.63-0.03
			c-1.08-1.08-1.11-2.55-0.09-3.59c0.84-0.84,2.38-1.09,3.57,0.1C34.35,36.52,34.12,38.03,33.31,38.85z M38.6,14.83l-2.62,2.61
			c-0.04,0.04-0.1,0.1,0.1,0.29l1.77,1.78c0.2,0.2,0.28,0.12,0.32,0.08l2.61-2.61c0.1-0.1,0.12-0.14-0.03-0.29l-1.81-1.81
			C38.75,14.68,38.72,14.71,38.6,14.83z"
        />
        <path
          className="st0"
          d="M54.26,28.91c-1.09,1.09-1.53,2.45-1.17,3.63l0.17,0.54l-0.53-0.18c-1.23-0.44-2.38-0.07-3.61,1.16
			c-0.91,0.91-1.34,2.09-1.16,3.22l0.14,0.86L47,37.04c-0.13-0.13-0.18-0.14-0.18-0.14c0,0-0.05,0.01-0.13,0.09l-2.55,2.55
			c-0.06,0.06-0.07,0.09-0.07,0.09c0.01,0.04,0.1,0.13,0.16,0.18L52.42,48c0.21,0.21,0.22,0.21,0.33,0.1l2.65-2.65
			c0.07-0.07,0.1-0.1-0.07-0.26l-5.47-5.47l-0.02-0.03c-0.23-0.39-0.19-1.3,0.43-1.93c0.7-0.7,1.6-0.61,2.46,0.26l4.89,4.89
			c0.16,0.16,0.19,0.13,0.3,0.03l2.6-2.6c0.1-0.1,0.13-0.13-0.1-0.36l-5.41-5.4l-0.02-0.04c-0.23-0.48-0.16-1.32,0.44-1.91
			c0.91-0.91,1.84-0.36,2.46,0.26l4.89,4.89c0.17,0.16,0.19,0.13,0.3,0.03l2.6-2.6c0.1-0.1,0.13-0.13-0.1-0.36l-5.9-5.9
			C57.82,27.12,56.06,27.11,54.26,28.91z"
        />
        <path
          className="st0"
          d="M40.05,55.93c-0.04,0.02-3.07,1.23-3.07,1.23c-0.1,0.04-0.15,0.08-0.18,0.12c-0.02,0.04-0.01,0.09,0.02,0.18
			c0.22,0.6,0.11,1.59-0.64,2.34c-1.06,1.06-2.54,1.06-3.6,0c-1.12-1.11-1.13-2.53-0.05-3.62c0.68-0.68,1.64-0.96,2.36-0.69
			c0.15,0.06,0.17,0.02,0.19-0.02c0.02-0.04,1.3-3.18,1.3-3.18c0.05-0.11,0.04-0.18-0.02-0.24c-0.03-0.03-0.07-0.05-0.12-0.08
			c-2.21-1.01-4.59-0.47-6.54,1.48c-2.62,2.62-2.57,6.47,0.12,9.16c2.69,2.69,6.32,2.66,9.05-0.07c1.86-1.86,2.37-4.03,1.53-6.46
			c-0.02-0.05-0.04-0.09-0.07-0.12C40.26,55.92,40.16,55.9,40.05,55.93z"
        />
        <path
          className="st0"
          d="M32.84,68.14h-6.53l-0.45-0.45c-0.49-0.49-0.32-0.79-0.12-0.99l3.31-3.31c0.29-0.29,0.4-0.29,0.68,0
			l3.45,3.45c0.38,0.37,0.43,0.53,0.1,0.87L32.84,68.14"
        />
        <path
          className="st0"
          d="M47.61,44.7c-2.69-2.69-6.44-2.71-9.11-0.03c-2.63,2.63-2.62,6.46,0.03,9.11c2.68,2.68,6.42,2.69,9.09,0.02
			C50.31,51.12,50.3,47.38,47.61,44.7z M45.02,51.11c-1,1-2.62,0.95-3.69-0.12c-1.06-1.07-1.11-2.63-0.1-3.64
			c1-1,2.52-0.95,3.6,0.14C45.93,48.59,46.01,50.11,45.02,51.11z"
        />
      </g>
    </g>
    <g id="by_Artmarket">
      <g>
        <g>
          <path
            d="M1.59,71.6c0.48,0,0.85,0.15,1.1,0.46c0.25,0.31,0.37,0.75,0.37,1.34c0,0.58-0.13,1.02-0.39,1.34
				c-0.26,0.31-0.62,0.47-1.09,0.47c-0.25,0-0.47-0.05-0.67-0.15c-0.2-0.1-0.35-0.25-0.47-0.44H0.41l-0.09,0.52h-0.2v-4.97h0.32
				v1.25c0,0.19,0,0.36-0.01,0.52L0.42,72.2h0.02c0.13-0.21,0.29-0.36,0.48-0.46S1.33,71.6,1.59,71.6z M1.58,71.88
				c-0.41,0-0.7,0.12-0.88,0.35s-0.26,0.62-0.26,1.16v0.05c0,0.52,0.09,0.9,0.28,1.13c0.18,0.23,0.47,0.34,0.86,0.34
				c0.38,0,0.66-0.13,0.85-0.4c0.19-0.26,0.29-0.64,0.29-1.13C2.72,72.38,2.34,71.88,1.58,71.88z"
          />
          <path
            d="M4.57,71.66H4.9l0.73,1.95c0.22,0.6,0.37,1,0.42,1.21h0.02c0.09-0.27,0.24-0.68,0.44-1.23l0.73-1.93h0.32L6.02,75.7
				c-0.13,0.33-0.23,0.55-0.32,0.66c-0.08,0.11-0.18,0.2-0.3,0.26c-0.11,0.06-0.25,0.09-0.41,0.09c-0.12,0-0.26-0.02-0.4-0.07v-0.27
				c0.12,0.03,0.26,0.05,0.4,0.05c0.11,0,0.2-0.03,0.29-0.08c0.08-0.05,0.16-0.13,0.23-0.24c0.07-0.11,0.15-0.28,0.23-0.51
				c0.09-0.23,0.15-0.39,0.17-0.45L4.57,71.66z"
          />
          <path
            d="M13.4,75.13l-0.47-1.3h-1.79l-0.46,1.3H9.89l1.75-4.69h0.83l1.75,4.69H13.4z M12.74,73.18l-0.44-1.27
				c-0.03-0.08-0.08-0.22-0.13-0.4c-0.06-0.18-0.1-0.32-0.12-0.4c-0.06,0.26-0.14,0.55-0.25,0.86l-0.42,1.22L12.74,73.18
				L12.74,73.18z"
          />
          <path
            d="M18.08,73.27v1.87h-0.76v-4.67h1.32c0.6,0,1.05,0.11,1.34,0.34c0.29,0.23,0.44,0.57,0.44,1.02c0,0.58-0.3,1-0.91,1.24
				l1.32,2.06h-0.87l-1.12-1.87L18.08,73.27L18.08,73.27z M18.08,72.64h0.53c0.35,0,0.61-0.06,0.77-0.2
				c0.16-0.13,0.24-0.33,0.24-0.59c0-0.26-0.09-0.45-0.26-0.57c-0.17-0.11-0.43-0.17-0.78-0.17h-0.5L18.08,72.64L18.08,72.64z"
          />
          <path d="M25.59,75.13h-0.76v-4.01h-1.37v-0.65h3.51v0.65h-1.37V75.13z" />
          <path
            d="M32.18,75.13l-1.35-3.9H30.8c0.04,0.58,0.05,1.12,0.05,1.63v2.27h-0.69v-4.67h1.08l1.3,3.71h0.02l1.34-3.71h1.08v4.67
				h-0.73v-2.31c0-0.23,0.01-0.53,0.02-0.91c0.01-0.37,0.02-0.6,0.03-0.68h-0.03l-1.4,3.89H32.18z"
          />
          <path
            d="M41.58,75.13l-0.47-1.3h-1.79l-0.46,1.3h-0.81l1.75-4.69h0.83l1.75,4.69H41.58z M40.91,73.18l-0.44-1.27
				c-0.03-0.08-0.08-0.22-0.13-0.4c-0.06-0.18-0.1-0.32-0.12-0.4c-0.06,0.26-0.14,0.55-0.25,0.86l-0.42,1.22L40.91,73.18
				L40.91,73.18z"
          />
          <path
            d="M46.25,73.27v1.87h-0.76v-4.67h1.32c0.6,0,1.05,0.11,1.34,0.34c0.29,0.23,0.44,0.57,0.44,1.02c0,0.58-0.3,1-0.91,1.24
				l1.32,2.06h-0.87l-1.12-1.87L46.25,73.27L46.25,73.27z M46.25,72.64h0.53c0.35,0,0.61-0.06,0.77-0.2
				c0.16-0.13,0.24-0.33,0.24-0.59c0-0.26-0.09-0.45-0.26-0.57c-0.17-0.11-0.43-0.17-0.78-0.17h-0.5L46.25,72.64L46.25,72.64z"
          />
          <path
            d="M55.72,75.13h-0.89l-1.47-2.11l-0.45,0.37v1.75h-0.76v-4.67h0.76v2.23c0.21-0.26,0.42-0.5,0.62-0.74l1.26-1.49h0.87
				c-0.82,0.96-1.4,1.64-1.75,2.05L55.72,75.13z"
          />
          <path d="M61.46,75.13h-2.64v-4.67h2.64v0.65h-1.88v1.27h1.76v0.64h-1.76v1.47h1.88V75.13z" />
          <path d="M66.57,75.13H65.8v-4.01h-1.37v-0.65h3.51v0.65h-1.37V75.13z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
