import React from 'react';
import ReduxProvider from '../../providers/ReduxProvider';
import Indicator from '..';

const IndicatorWithProviders = ({ idlot, noAccess, linkToSubscription }) => (
  <ReduxProvider>
    <Indicator idlot={idlot} noAccess={noAccess} linkToSubscription={linkToSubscription} />
  </ReduxProvider>
);

export default IndicatorWithProviders;
