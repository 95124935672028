import React from 'react';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_CARD } from '../../../../constants/rails';
import Period from '../common/Period';
import Users from '../common/Users';
import './stylesheet.scss';

const Params = () => (
  <div className="subscriptions-list-params">
    <Users section={ANALYTICS_SECTION_SUBSCRIPTIONS_CARD} />
    <div className="split" />
    <Period style={{ width: 300 }} section={ANALYTICS_SECTION_SUBSCRIPTIONS_CARD} />
  </div>
);

export default Params;
