import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import EmailStatus from '..';

const Provided = ({ backTo, withWarning, redirectAfterSending }) => (
  <ReduxProvider>
    <EmailStatus backTo={backTo} withWarning={withWarning} redirectAfterSending={redirectAfterSending} />
  </ReduxProvider>
);

export default Provided;
