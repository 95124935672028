/* eslint-disable global-require */
module.exports = [
  'account.emailvalidationneeded.contents.noemail.m1',
  'account.emailvalidationneeded.contents.noemail.m2',
  'account.emailvalidationneeded.contents.noemail.m3',
  'account.emailvalidationneeded.contents.noemail.m4',
  'account.emailvalidationneeded.contents.noemail.m5',
  'account.emailvalidationneeded.contents.noemail.m6',
  'account.emailvalidationneeded.contents.noemail.m7',
];
