import React from 'react';
import TestimonialsCarrousel from './testimonials/Carrousel';
import TestimonialsBlock from './testimonials/Block';
import Auctioneers from './Auctioneers';
import Spaces from './Spaces';
import Header from './Header';
import Staff from './Staff';
import './stylesheet.scss';
import Contact from './Contact';

const Info = ({ lang }) => (
  <div className="ails-info">
    <Header />
    <div className="container">
      <Spaces lang={lang} />
      <TestimonialsCarrousel />
    </div>
    <Auctioneers lang={lang} />
    <div className="container">
      <Staff />
      <TestimonialsBlock />
      <Contact lang={lang} />
    </div>
  </div>
);

export default Info;
