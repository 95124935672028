import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { usePaginatedSearch } from '../../../hooks/usePaginatedSearch';
import { MYARTPRICE_STORE } from '../../../constants/search/scope';
import { areCurrentResultsLoading, getCurrentResultsData } from '../../../redux/selectors/search/results';
import { getItemsPerpage } from '../../../redux/selectors/preferences';
import { getCurrentCount } from '../../../redux/selectors/search/info';
import { isInViewport, scrollToElement } from '../../../utils/ui';
import StoreTile from './StoreTile';
import NoResults from '../common/NoResults';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { SHORTCUT_MARKETPLACE } from '../../../constants/myartprice';
import MainLayout from '../common/MainLayout';
import Pagination from '../../common/Pagination';

import './stylesheet.scss';

const Store = () => {
  const data = useSelector(getCurrentResultsData) || {};
  const loading = useSelector(areCurrentResultsLoading) || false;
  const perPage = useSelector(getItemsPerpage);
  const totalCount = useSelector(getCurrentCount);
  const dataContainerRef = useRef(null);
  const refTitle = useRef(null);
  const { page, setPage } = usePaginatedSearch({ scope: MYARTPRICE_STORE, options: { perPage, cancelPrevious: true } });

  useInitializeActiveShorcut({ activeShortcut: SHORTCUT_MARKETPLACE });

  const handlePaginationClick = p => {
    setPage(p);
    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  // Breakcrumb
  useEffect(() => {
    const breadcrumb = document.querySelector('.breadcrumb');
    breadcrumb.classList.add('hidden-xs', 'hidden-sm');
    breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
<li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
<li>${i18next.t('layouts.header.m44')}</li>`;
  }, []);

  return (
    <MainLayout>
      <div className="col-container">
        {(loading && (
          <div className="spinner">
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        )) || (
          <>
            {/* No Results / List of Store */}
            {((!data.idstore || data.idstore.length === 0) && <NoResults messageRsxKey="component.myartprice.marketplace.m4" linkRsxKey="component.myartprice.marketplace.m5" href="/marketplace/store" />) || (
              <div className="data-container" ref={dataContainerRef}>
                {data.idstore && data.idstore.map(idstore => <StoreTile id={idstore} key={idstore} />)}
              </div>
            )}

            {/* Pagination */}
            {totalCount / perPage > 1 && totalCount > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />
              </div>
            )}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Store;
