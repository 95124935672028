import { useRef, useEffect } from 'react';

// UseEffect qui n'est pas joué sur le componentDidMount mais sur tous les updates suivants
export function useDidUpdateEffect(fn, deps) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, deps);
}
