import React from 'react';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Contact = ({ aillandingpage }) => (
  <div className="ails-landingpage-header-contact">
    <h3>
      <A18n rsx="ails.landingpage.header.contact.m1" />
    </h3>
    <p>
      {aillandingpage.line}
      <br />
      {aillandingpage.postcode} {aillandingpage.city}
      <br />
      {aillandingpage.country}
    </p>
    <h3>
      <A18n rsx="ails.landingpage.header.contact.m2" />
    </h3>
    <p>
      {aillandingpage.phone}
      <br />
      {aillandingpage.email}
      <br />
      {aillandingpage.url}
    </p>
  </div>
);

export default Contact;
