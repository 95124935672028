import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../../redux/slices/artist';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Breadcrumb = () => {
  const artist = useSelector(getArtist);

  return (
    <div className="artists-artist-breadcrumb" id="artist-breadcrumb">
      <ul className="breadcrumb">
        <li>
          <a href="#">
            <A18n rsx="artists.artist.breadcrumb.m1" />
          </a>
        </li>
        <li>
          <a href="/artists/directory">
            <A18n rsx="artists.artist.breadcrumb.m2" />
          </a>
        </li>
        <li>{artist.name}</li>
      </ul>
    </div>
  );
};

export default Breadcrumb;
