/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { updateSettings } from '../../../../redux/actions/subscriptions/settings';
import { getActiveSubscription } from '../../../../redux/slices/userContext/selectors';
import { areThemesInitialized, areThemesLoading, areThemesUnavailable, getThemes } from '../../../../redux/selectors/subscriptions/physicproducts';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import OneDayRefoundInfo from '../common/OneDayRefoundInfo';
import { NEW, UPGRADE } from '../common/constants';
import {
  ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_RENEW,
  ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_UPGRADE,
  ANALYTICS_CLICK_OPERATION_NB_USERS,
  ANALYTICS_CLICK_OPERATION_NB_USERS_MORE_THAN_10,
  ANALYTICS_CLICK_OPERATION_PROMOCODE_VALIDATE,
  ANALYTICS_CLICK_OPERATION_TOGGLE_RENEW_UPGRADE,
} from '../../../../constants/rails';
import './stylesheet.scss';

const Settings = () => {
  const dispatch = useDispatch();
  const { view, quantity, promocode, displayPromocodeInput, upgradeAvailable, upgradeAvailableOnUsers, initialQuantity, from } = useSelector(getSettings);
  const [displayAnnotate, setDisplayAnnotate] = useState(false);
  const [innerPromocode, setInnerPromocode] = useState(promocode);
  const activeSubscription = useSelector(getActiveSubscription);
  const themes = useSelector(getThemes);
  const loading = useSelector(areThemesLoading);
  const themesInitialized = useSelector(areThemesInitialized);
  const themesUnavailable = useSelector(areThemesUnavailable);

  useEffect(() => {
    setInnerPromocode(promocode);
  }, [promocode]);

  const handleSettings = data => {
    if (!loading) {
      dispatch(updateSettings({ data }));
    }
  };

  const { status } = (themesInitialized && themes[view]?.promotion_from_promocode) || {};

  const maxQuantity = 10;
  let minQuantity = 1;
  if (view === UPGRADE) {
    if (upgradeAvailableOnUsers) {
      minQuantity = initialQuantity + 1;
    } else {
      minQuantity = initialQuantity;
    }
  }

  const displayViewControl = upgradeAvailable || themes?.has_oneday_refund || themes?.has_loyalty_discount;

  const handleUpgradeClick = () => {
    const data = { view: UPGRADE };
    if (upgradeAvailableOnUsers) {
      data.quantity = initialQuantity + 1;
    } else {
      data.quantity = initialQuantity;
    }

    handleSettings(data);
  };

  if (themesUnavailable) return null;

  return (
    <div className={`subscriptions-purchase-settings ${activeSubscription ? '' : 'no-subscription'}`}>
      {displayViewControl && (
        <div className="control-container view">
          {upgradeAvailable && (
            <div className="controls">
              <div className="button-group view">
                <button
                  className={view === NEW ? 'active' : undefined}
                  type="button"
                  onClick={() => {
                    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TOGGLE_RENEW_UPGRADE, section: ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_RENEW });
                    handleSettings({
                      view: NEW,
                      quantity: initialQuantity,
                    });
                  }}
                >
                  {i18next.t('subscriptions.purchase.settings.m1')}
                </button>
                <button
                  className={view === UPGRADE ? 'active' : undefined}
                  type="button"
                  onClick={() => {
                    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TOGGLE_RENEW_UPGRADE, section: ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_UPGRADE });
                    handleUpgradeClick();
                  }}
                >
                  {i18next.t('subscriptions.purchase.settings.m2')}
                </button>
              </div>
            </div>
          )}
          <div className={`info-users ${!upgradeAvailable ? 'no-controls' : undefined}`}>
            <OneDayRefoundInfo visible={displayAnnotate} close={() => setDisplayAnnotate(false)} style={{ right: 0 }} />
            {view === NEW && themesInitialized && (
              <>
                {themes.has_oneday_refund && (
                  <p className="refund" onClick={() => setDisplayAnnotate(true)}>
                    {i18next.t('subscriptions.purchase.settings.m3')} <i className="fa text-primary fa-question-circle" />
                  </p>
                )}
                {!themes.has_oneday_refund && themes.has_loyalty_discount && <p>{i18next.t('subscriptions.purchase.settings.m4')}</p>}
              </>
            )}
            {view === UPGRADE && (
              <>
                <p className="subtitle">{i18next.t('subscriptions.purchase.settings.m8')}</p>
                <p>{i18next.t('subscriptions.purchase.settings.m5')}</p>
              </>
            )}
          </div>
        </div>
      )}

      <div className="control-container users" style={(!displayPromocodeInput && displayViewControl && { flex: 'none' }) || {}}>
        <div className="controls">
          <div className="users">
            <span className="title">
              <i className="fa fa-user" />
              <span className="placeholder">{i18next.t('subscriptions.purchase.settings.m6')}</span>
            </span>
            <div
              className={`button-container ${quantity === minQuantity || loading ? 'disabled' : undefined}`}
              onClick={() => {
                aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_NB_USERS, section: 'DOWN' });
                handleSettings({ quantity: quantity - 1 >= minQuantity ? quantity - 1 : minQuantity });
              }}
            >
              {quantity > minQuantity && (
                <div className="button">
                  <i className="fa fa-minus" />
                </div>
              )}
            </div>
            <div className="quantity">{quantity}</div>
            <div
              className={`button-container ${quantity === maxQuantity || loading ? 'disabled' : undefined}`}
              onClick={() => {
                aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_NB_USERS, section: 'UP' });
                handleSettings({ quantity: quantity + 1 > maxQuantity ? maxQuantity : quantity + 1 });
              }}
            >
              {quantity < maxQuantity && (
                <div className="button">
                  <i className="fa fa-plus" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="info-users">
          <a href="/contact/message" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_NB_USERS_MORE_THAN_10 })}>
            {i18next.t('subscriptions.purchase.settings.m7')}
          </a>
        </div>
      </div>

      {displayPromocodeInput && (
        <div className="control-container promocode" style={displayViewControl ? {} : { flexFlow: 'row', justifyContent: 'center' }}>
          {view !== UPGRADE && (
            <>
              <div className="controls">
                <div className="code">
                  <form>
                    <input placeholder={i18next.t('subscriptions.purchase.settings.m14')} value={innerPromocode} onChange={e => setInnerPromocode(e.target.value)} type="text" />
                    <button
                      type="submit"
                      className="active"
                      onClick={e => {
                        e.preventDefault();
                        aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_PROMOCODE_VALIDATE, section: innerPromocode });
                        handleSettings({ promocode: innerPromocode });
                      }}
                    >
                      {i18next.t('subscriptions.purchase.settings.m13')}
                    </button>
                  </form>
                </div>
              </div>
              {status > 1 && (
                <div className="info-promocode">
                  <div className="message">
                    <i className="fa fa-exclamation-triangle" style={{ marginRight: 5 }} />
                    {status === 4 && i18next.t('subscriptions.purchase.settings.m9')}
                    {status === 5 && i18next.t('subscriptions.purchase.settings.m10')}
                    {status === 2 && i18next.t('subscriptions.purchase.settings.m11')}
                    {status === 1 && i18next.t('subscriptions.purchase.settings.m12')}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Settings;
