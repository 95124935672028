import React from 'react';
import './stylesheet.scss';

const Arrow = ({ title, first, last, comming }) => (
  <div className={`arrow-container ${first ? 'first' : ''} ${last ? 'last' : ''} ${comming ? 'comming' : ''}`}>
    <div className="left" />
    <div className="inner">
      {!comming && <i className="fa fa-check-circle-o" />}
      {comming && <i className="fa fa-hourglass-half" />}
      {title}
    </div>
    <div className="right" />
  </div>
);

export default Arrow;
