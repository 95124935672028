import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRankingData, isRankingDataLoading } from '../../../redux/selectors/indexes/ranking';
import { fetchRankingData } from '../../../redux/actions/indexes/ranking';
import { getIdartist } from '../../../redux/selectors/indexes/artist';
import { getCurrency } from '../../../redux/selectors/preferences';
import Spinner from '../../common/spinners/Spinner';
import './stylesheet.scss';
import Evolution from './Evolution';
import A18n from '../../common/A18n';
import Copyright from '../common/Copyright';

const Ranking = () => {
  const [sortedData, setSortedData] = useState();
  const dispatch = useDispatch();
  const loading = useSelector(isRankingDataLoading);
  const data = useSelector(getRankingData);
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);

  useEffect(() => {
    if (data) {
      setSortedData(data.sort(([a], [b]) => a - b));
    }
  }, [data]);

  useEffect(() => {
    if (idartist && currency) {
      dispatch(fetchRankingData());
    }
  }, [idartist, currency]);

  useEffect(() => {
    if (!loading && sortedData) {
      const element = document.querySelector('.rank-values');
      element.scrollLeft = element.scrollWidth;
    }
  }, [sortedData, loading]);

  return (
    <>
      <div className="ranking" id="rank">
        <h2>
          <A18n rsx="indexes.summary.m3" />
        </h2>

        <div className="ranking-body">
          {loading && (
            <div className="spinning">
              <Spinner />
            </div>
          )}
          {!loading && sortedData && (
            <div className="ranks">
              <div className="rank-labels">
                <div>{i18next.t('indexes.rank_artist.m1')}</div>
                <div>{i18next.t('indexes.rank_artist.m2')}</div>
                <div>{i18next.t('indexes.rank_artist.m5')}</div>
              </div>
              <div className="rank-values">
                {sortedData.map(([year, value], idx) => (
                  <div className="rank" key={year}>
                    <div className="year">{year}</div>
                    <div className="value">{value}</div>
                    <div className="evolution">
                      {idx > 0 && <Evolution previous={sortedData[idx - 1][1]} current={value} />}
                      {idx === 0 && '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default Ranking;
