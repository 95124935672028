import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Follow from '..';

const ProvidedSearchBar = ({ idartist, favorite, size, rsxFavorited, rsxNotFavorited, noLabel }) => (
  <ReduxProvider>
    <Follow idartist={idartist} favorite={favorite} size={size} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />
  </ReduxProvider>
);

export default ProvidedSearchBar;
