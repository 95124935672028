/* eslint-disable global-require */
module.exports = [
  ...require('./DropDownToValidate/i18n'),
  ...require('./ClosingDropDown/i18n'),
  ...require('./FacetsSelect/i18n'),
  ...require('./QuickFilter/i18n'),
  ...require('./GlobalSearchTabs/i18n'),
  ...require('./Sort/i18n'),
  ...require('./GoToPage/i18n'),
];
