import React from 'react';
import A18n from '../../../../../../common/A18n';
import { aapiBeacon } from '../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_LANGS } from '../../../../../../../constants/rails';
import { FLAGS, LANGUAGES_RSX_KEY, MANAGED_LOCALES } from '../../../../../../../constants/locales';
import { createNewUrlForLocale } from '../../../../../../../utils/links/locale';

// overlay over actual aapi to set type and from
function aapiWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_LANGS, op });
}

const Content = ({ locale }) => (
  <ul className="link-list">
    {MANAGED_LOCALES.filter(managedLocale => managedLocale !== locale).map(managedLocale => (
      <li key={managedLocale}>
        <a className="link-list-item" href={createNewUrlForLocale(locale, managedLocale)} onClick={() => aapiWrapper(managedLocale)}>
          <span className={`fi fi-${FLAGS[managedLocale]} artp-Header-Langs-flag`} /> <A18n rsx={LANGUAGES_RSX_KEY[managedLocale]} />
        </a>
      </li>
    ))}
  </ul>
);

export default Content;
