/* eslint-disable global-require */
module.exports = [
  'marketplace.stores.list.m1',
  'marketplace.stores.list.m2',
  'marketplace.stores.list.m3',
  'marketplace.stores.list.m4',
  'marketplace.stores.list.m5',
  'marketplace.stores.list.m6',
  'marketplace.stores.list.m7',
  'marketplace.stores.list.m8',
];
