export const scrollTo = ref => {
  const element = ref?.current;

  if (!element) return false;

  window.scroll({
    top: element.getBoundingClientRect().top + window.scrollY - 132, // 132px is the height of the header
    behavior: 'smooth',
  });
};
