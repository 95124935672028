import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { artistInfosAction, updateIdartist, shouldDisplayAction } from '../../redux/actions/indexes/artist';
import { getAllShouldDisplay, isDisplayLoading } from '../../redux/selectors/indexes/artist';
import { scrollTo } from '../artists/Artist/common/utils';
import BackToTopButton from '../common/BackToTopButton';
import { queryStringToObject } from '../../utils/http';
import ReduxProvider from '../providers/ReduxProvider';
import Spinner from '../common/spinners/Spinner';
import DemoOrLockMode from './DemoOrLockMode';
import PriceSegments from './PriceSegments';
import Chronological from './Chronologic';
import Geographical from './Geographic';
import PriceIndex from './PriceIndex';
import Ranking from './Ranking';
import Summary from './Summary';
import Header from './Header';
import Medium from './Medium';
import './stylesheet.scss';

const Indexes = ({ idartist, locale }) => {
  const dispatch = useDispatch();
  const shouldDisplay = useSelector(getAllShouldDisplay);
  const displayLoading = useSelector(isDisplayLoading);
  const [params] = useState(() => queryStringToObject(window.location.search));

  useEffect(() => {
    if (params?.st) scrollTo(params?.st);
  }, [params?.st, shouldDisplay?.medium, shouldDisplay?.geographical, shouldDisplay?.priceIndex]);

  useEffect(() => {
    dispatch(updateIdartist({ idartist }));
    dispatch(artistInfosAction());
    dispatch(shouldDisplayAction());
  }, []);

  if (displayLoading) {
    return <Spinner />;
  }

  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className="indexes-artist">
      <Header locale={locale} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <DemoOrLockMode />
            <Summary />
            {shouldDisplay?.chronological && <Chronological />}
            <Ranking />
            {shouldDisplay?.priceIndex && <PriceIndex />}
            {shouldDisplay?.geographical && <Geographical />}
            {shouldDisplay?.priceSegment && <PriceSegments />}
            {shouldDisplay?.medium && <Medium />}
          </div>
        </div>
      </div>
      <div className="back-to-top">
        <BackToTopButton top="indexes-artist" />
      </div>
    </div>
  );
};

// Provider
const ProvidedIndexes = ({ idartist, locale = 'en' }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <Indexes idartist={idartist} locale={locale} />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

export default ProvidedIndexes;
