import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import Footer from '..';

const WithIntlProvider = ({ locale }) => (
  <IntlProvider locale={locale} suppressHydrationWarning>
    <ReduxProvider>
      <Footer />
    </ReduxProvider>
  </IntlProvider>
);

export default WithIntlProvider;
