import React from 'react';
import { useSelector } from 'react-redux';
import { getSale } from '../../../../../redux/selectors/dbEntities';
import AuctioneerComponent from '../../../../common/dbEntitiesSmallViews/Auctioneer';

const Sale = ({ id, withCity = false, withCountry = false }) => {
  const sale = useSelector(state => getSale(state, { id }));
  if (!sale) {
    return null;
  }

  return (
    <>
      <AuctioneerComponent id={sale.idauctioneer} />
      <div>{sale.dt}</div>
      {(withCity || withCountry) && (
        <div>
          {withCity && <span>{sale.city}</span>}
          {withCity && withCountry && <span>{' - '}</span>}
          {withCountry && <span>{sale.country}</span>}
        </div>
      )}
    </>
  );
};

export default Sale;
