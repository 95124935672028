import React from 'react';
import i18next from 'i18next';
import ReactPhoneInput from 'react-phone-input-2';
import { Controller } from 'react-hook-form';
import countryFr from 'react-phone-input-2/lang/fr.json';
import countryDe from 'react-phone-input-2/lang/de.json';
import countryZh from 'react-phone-input-2/lang/cn.json';
import countryIt from 'react-phone-input-2/lang/it.json';
import countryEs from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';
import './stylesheet.scss';

const PhoneInput = ({ name, control, country, readOnly, rules }) => {
  const countryLocales = { fr: countryFr, en: {}, de: countryDe, zh: countryZh, it: countryIt, es: countryEs };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => <ReactPhoneInput {...field} enableSearch disableSearchIcon localization={countryLocales[i18next.language]} country={country} disabled={readOnly} />}
    />
  );
};

export default PhoneInput;
