import React from 'react';
import { useSelector } from 'react-redux';
import { getLastSubscription } from '../../../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { formatFns } from '../../../../../../../../../utils/dates/format';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING, ANALYTICS_CLICK_OPERATION_TO_PRICING } from '../../../../../../../../../constants/rails';

const AlmostExpiredSubscription = () => {
  const lastSubscription = useSelector(getLastSubscription);

  const hasStore = (lastSubscription?.modules || []).includes(13);
  return (
    <Notification
      element={<A18n rsx="component.header.notifications.almost_expired.m1" replace={[['%dt%', formatFns(lastSubscription?.dates?.dtExpire, 'PPP')]]} />}
      link={`/subscription${hasStore ? '/store' : ''}`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: hasStore ? ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING : ANALYTICS_CLICK_OPERATION_TO_PRICING })}
    />
  );
};

export default AlmostExpiredSubscription;
