import React from 'react';
import { number } from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { getAuctioneer } from '../../../../redux/selectors/dbEntities';

const Auctioneer = ({ id }) => {
  const auctioneer = useSelector(state => getAuctioneer(state, { id }), shallowEqual) || [];

  return <span>{auctioneer.name}</span>;
};

Auctioneer.propTypes = {
  id: number.isRequired,
};

export default Auctioneer;
