import React from 'react';
import Observer from '../../../common/Observer';

const intersections = [];

const handleIntersect = ({ entries, payload }) => {
  entries.forEach(entry => {
    if (entry.isIntersecting && !intersections.includes(payload)) {
      if (payload === 'promo') {
        window.dataLayer.push({
          event: 'promoView',
          ecommerce: {
            promoView: {
              promotions: [
                {
                  id: entry.target.dataset.id,
                  name: entry.target.dataset.code,
                  position: 'home',
                  creative: 'banner',
                },
              ],
            },
          },
        });
      }

      intersections.push(payload);
    }
  });
};

const GtmHomeIntersectionObserver = ({ selectors }) => <Observer selectors={selectors} handleIntersect={handleIntersect} />;

export default GtmHomeIntersectionObserver;
