import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { indicatorLoadingSelector, lotDataSelector } from '../../redux/selectors/indicator';
import { getActiveSubscription } from '../../redux/slices/userContext/selectors';
import { getCurrency } from '../../redux/selectors/preferences';
import { indicatorAction } from '../../redux/actions/indicator';
import { CURRENCIES } from '../../constants/preferences';
import Spinner from '../common/spinners/Spinner';
import LinkButton from './LinkButton';
import Graph from './Graph';
import Table from './Table';
import './stylesheet.scss';
import { MODULE_INDICATOR } from '../../constants/rails';

const Indicator = ({ idlot, noAccess, linkToSubscription }) => {
  const dispatch = useDispatch();
  const [showChartData, setShowChartData] = useState(false);
  const currency = useSelector(getCurrency);
  const loading = useSelector(state => indicatorLoadingSelector(state, { idlot }));
  const lotdata = useSelector(state => lotDataSelector(state, { idlot, currency }));
  const currentSubscription = useSelector(getActiveSubscription);
  const [data, setData] = useState();
  const [isDataMeaningful, setIsDataMeaningful] = useState(false);

  useEffect(() => {
    dispatch(
      indicatorAction({
        idlot,
        currency,
      }),
    );
  }, [idlot, currency]);

  useEffect(() => {
    if (lotdata?.result) {
      const innerValue = Object.keys(lotdata.result).map(year => ({
        year,
        indicator: lotdata.result[year] && Math.round(lotdata.result[year] * 100) / 100,
      }));

      setData(innerValue);
      setIsDataMeaningful(innerValue.some(({ value }) => value !== null));
    } else {
      setData([]);
    }
  }, [lotdata]);

  if (loading) {
    return <Spinner />;
  }

  if (!isDataMeaningful) {
    return null;
  }

  return (
    <div className="artdbweb-lot-Indicator row">
      <div className="col-sm-3 col-xs-12">
        <div className="title">
          <span className="strong">{i18next.t('lots.show.m19')}</span>
          <br />
          <i>{i18next.t('lots.show.m15')}</i>
        </div>
      </div>
      <div className="col-xs-12 col-sm-9">
        <div className="row">
          <div className="col-sm-12">
            {noAccess && !linkToSubscription && <div className="no-access absolute">{i18next.t('lots.indicator.m1')}</div>}
            {noAccess && linkToSubscription && (
              <div className="no-access absolute">
                <p>{i18next.t('lots.indicator.m2')}</p>
                <p>
                  <a
                    href={`/subscription${currentSubscription && !currentSubscription?.modules.includes(MODULE_INDICATOR) ? `/upgrade?idmodule=${MODULE_INDICATOR}` : ''}`}
                    id="sln_subscription"
                    className="btn btn-primary"
                  >
                    {i18next.t('lots.indicator.m3')}
                  </a>
                </p>
              </div>
            )}
            <Graph currency={CURRENCIES[currency].iso1} data={data} />
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-offset-3 col-sm-9 text-right">
        <span className="hidden-xs pull-left">
          <LinkButton onClick={() => setShowChartData(!showChartData)}>
            {showChartData ? i18next.t('indexes.indexes_chart.m6') : i18next.t('indexes.indexes_chart.m5')}
            <i className={`caret ${showChartData ? 'inverse' : ''}`} />
          </LinkButton>
        </span>
        <a href="/indicator/howto">{i18next.t('artprice.lib.result.indicatorresult.m7')}</a>
        {' - '}
        <a href="/indexes/legals">{i18next.t('artprice.index.legals.m1')}</a>
      </div>
      {showChartData && (
        <div className="col-xs-12 col-sm-offset-3 col-sm-9">
          <Table currency={CURRENCIES[currency].iso1} data={data} />
        </div>
      )}
    </div>
  );
};

export default Indicator;
