/* eslint-disable global-require */
module.exports = [
  // header
  'indexes.show.m10',

  // Demo
  'layouts.bs.m4',
  'layouts.bs.m5',
  'lots.no_access.m16',
  'indexes.show.m3',
  'lots.show.m3',

  // Common
  'artprice.lib.result.indexresult.m56',
  'indexes.show.m1',
  'indexes.show.m2',
  'indexes.show.m3',
  'indexes.show.m4',
  'indexes.show.m5',
  'indexes.show.m6',
  'indexes.show.m7',
  'artists._indices.m3',
  'artists._indices.m7',
  'artprice.lib.shop.physicproductshortctrl.m1',
  'indexes.others',

  // Charts
  ...require('./common/i18n'),
  ...require('./PriceIndex/i18n'),
  ...require('./Chronologic/i18n'),
  ...require('./Geographic/i18n'),
  ...require('./PriceSegments/i18n'),
  ...require('./DemoOrLockMode/i18n'),
  ...require('./Summary/i18n'),

  'indexes._medium_chart.m1',

  ...require('./Ranking/i18n'),
  ...require('./Header/i18n'),

  // Subscription Needed
  ...require('./SubscriptionNeeded/i18n'),
];
