import React from 'react';
import { useSelector } from 'react-redux';
import NotCookedCard from '../backCards/NotCookedCard';
import NotPushCard from '../backCards/NoPushCard';
import NoFavoriteCard from '../backCards/NoFavoriteCard';
import { getFavoriteCount, getIdcustomer, getPushEmail } from '../../../../../../redux/slices/userContext/selectors';
import { getFollowRecomendationDisplay } from '../../../../../../redux/slices/artist';

const Fake = () => {
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const favoriteCount = useSelector(getFavoriteCount);
  const followRecomendationDisplay = useSelector(getFollowRecomendationDisplay);

  if (!idcustomer) return <NotCookedCard />;
  if (!isPushEmail) return <NotPushCard />;
  if (favoriteCount === 0 || followRecomendationDisplay) return <NoFavoriteCard />;

  return <div />;
};

export default Fake;
