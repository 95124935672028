import React from 'react';
import TechniqueSmallView from '../../../../common/dbEntitiesSmallViews/Technique';
import CategorySmallView from '../../../../common/dbEntitiesSmallViews/Category';
import { Classifiedoriginaltype } from '../../../../../constants/marketplace';
import Category from '../../../../../constants/category';
import Dimensions from '../common/Dimensions';
import Separator from '../../common/Separator';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Fineart = ({ classified }) => (
  <div className="classified-details-info-fineart">
    <div className="main-info">
      {classified.idcategory > 0 && (
        <div className="classified-details-block">
          <div className="classified-label">
            <A18n rsx="indexes._advanced.m6" />
          </div>

          <CategorySmallView id={classified.idcategory} />
        </div>
      )}

      {classified.idtechnique > 0 && (
        <div className="classified-details-block">
          <div className="classified-label">
            <A18n rsx="marketplace._search.html.m8" />
          </div>

          <TechniqueSmallView id={classified.idtechnique} />
        </div>
      )}

      {classified.idclassifiedoriginaltype > 0 && (
        <div className="classified-details-block">
          <div className="classified-label">
            <A18n rsx="classifieds.fineart.form.html.m11" />
          </div>

          {classified.idclassifiedoriginaltype === Classifiedoriginaltype.ORIGINAL && <A18n rsx="marketplace.fineart._show.html.m11" />}
          {classified.idclassifiedoriginaltype === Classifiedoriginaltype.MULTIPLE && <A18n rsx="marketplace.fineart._show.html.m12" />}
          {classified.idclassifiedoriginaltype === Classifiedoriginaltype.COPY && <A18n rsx="marketplace.fineart._show.html.m13" />}

          {classified.edition && (classified.idcategory === Category.SCULPTURE_VOLUME || classified.idcategory === Category.PRINT || classified.idcategory === Category.PHOTOGRAPHY) && (
            <span> ({classified.edition})</span>
          )}
        </div>
      )}
    </div>

    <Separator className="hidden-xs" />

    {classified.certif_by && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.form._garantie.html.m10" />
        </div>
        {classified.certif_by}
      </div>
    )}

    {classified.signatureposition && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart.form._descriptive_details.html.m11" />
        </div>
        {classified.signatureposition && classified.signatureposition}
        {!classified.signatureposition && <A18n rsx="artprice.web.pages.classifieds.create.m31" />}
      </div>
    )}

    <div className="classified-details-block">
      <div className="classified-label">
        <A18n rsx="marketplace.fineart.form._descriptive_details.html.m21" />
      </div>
      <Dimensions classified={classified} />
    </div>

    {(classified.heightwithframe > 0 || classified.widthwithframe > 0 || classified.lengthwithframe > 0) && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart.form._descriptive_details.html.m22" />
        </div>
        <Dimensions classified={classified} withFrame />
      </div>
    )}

    {classified.billed_by && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.form._garantie.html.m9" />
        </div>
        {classified.billed_by}
      </div>
    )}

    {classified.classifieddecorativecondition && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m17" />
        </div>
        {classified.classifieddecorativecondition}
      </div>
    )}

    {classified.stamp_by && (classified.idcategory === Category.PHOTOGRAPHY || classified.idcategory === Category.SCULPTURE_VOLUME || classified.idcategory === Category.CERAMIC) && (
      <div className="classified-details-block">
        <div className="classified-label">
          {(classified.idcategory === Category.SCULPTURE_VOLUME || classified.idcategory === Category.CERAMIC) && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m15" />}
          {classified.idcategory === Category.PHOTOGRAPHY && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m16" />}
        </div>
        {classified.stamp_by}
      </div>
    )}

    {classified.found_or_printing_date && (classified.idcategory === Category.SCULPTURE_VOLUME || classified.idcategory === Category.PHOTOGRAPHY) && (
      <div className="classified-details-block">
        <div className="classified-label">
          {classified.idcategory === Category.SCULPTURE_VOLUME && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m26" />}
          {classified.idcategory === Category.PHOTOGRAPHY && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m27" />}
        </div>
        {classified.found_or_printing_date}
      </div>
    )}

    {classified.editor && (classified.idcategory === Category.SCULPTURE_VOLUME || classified.idcategory === Category.PRINT) && (
      <div className="classified-details-block">
        <div className="classified-label">
          {classified.idcategory === Category.SCULPTURE_VOLUME && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m8" />}
          {classified.idcategory === Category.PRINT && <A18n rsx="marketplace.fineart.form._descriptive_details.html.m9" />}
        </div>
        {classified.editor}
      </div>
    )}

    {classified.workshop && classified.idcategory === Category.TAPESTRY && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m5" />
        </div>
        {classified.workshop}
      </div>
    )}

    {classified.ref_catalogue_raisonne && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m10" />
        </div>
        {classified.ref_catalogue_raisonne}
      </div>
    )}

    {classified.theme && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m21" />
        </div>
        {classified.theme}
      </div>
    )}

    {classified.mouvment && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m22" />
        </div>
        {classified.mouvment}
      </div>
    )}
  </div>
);

export default Fineart;
