import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserContext } from '../../../redux/slices/userContext';
import Container from './Container';
import DevInfo from './DevInfo';
import Bottom from './Bottom';
import './stylesheet.scss';
import Top from './Top';

/*
The simple props will simplify the header for the errors pages
 */
const Header = ({ locale, simple = false }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserContext());
  }, []);

  return (
    <Container id="artp-header" className="artp-Header-container" suppressHydrationWarning>
      <DevInfo />
      <Top locale={locale} simple={simple} />
      <Bottom locale={locale} simple={simple} />
    </Container>
  );
};

export default Header;
