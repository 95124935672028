import React from 'react';
import { ANALYTICS_FROM_AIL_LANDINGPAGE } from '../../../../../constants/rails';
import './stylesheet.scss';

const Record = ({ ail }) => (
  <div className="ails-landingpage-record">
    <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_AIL_LANDINGPAGE}`}>
      <div className="record">
        <div className="repro">
          <img src={ail.images.square} alt={`${ail.artist_name} - ${ail.title}`} title={`${ail.artist_name} - ${ail.title}`} />
        </div>
        <div className="legend">
          <p className="price">{ail.price}</p>
          <p>
            <strong>{ail.artist_name}</strong>
          </p>
          <p className="lot-title">{ail.title}</p>
          <p>{ail.technique}</p>
          <p className="date-and-location">
            <span>{ail.dt}</span>
            <span>{`${ail.city ? `${ail.city} - ` : ''}${ail.country}`}</span>
          </p>
        </div>
      </div>
    </a>
  </div>
);

export default Record;
