import React, { useEffect } from 'react';
import i18next from 'i18next';
import { get } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchLotartistassociationtype } from '../../../../redux/actions/dbEntities/lotartistassociationtype';
import { getLotartistassociationtype } from '../../../../redux/selectors/dbEntities';

const LotartistassociationtypeSmallView = ({ id }) => {
  const dispatch = useDispatch();
  const lang = i18next.language;
  const lotartistassociationtype = useSelector(store => getLotartistassociationtype(store, { id, lang }), shallowEqual);

  useEffect(() => {
    if (!lotartistassociationtype) {
      dispatch(fetchLotartistassociationtype({ id }));
    }
  }, []);

  return <span>{get(lotartistassociationtype, 'label')}</span>;
};

export default LotartistassociationtypeSmallView;
