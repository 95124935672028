/* eslint-disable global-require */
module.exports = [
  ...require('./Offline/i18n'),
  ...require('./Stripe/i18n'),
  ...require('./Paypal/i18n'),
  'payments.platforms.m1',
  'payments.platforms.m2',
  'payments.platforms.m3',
  'payments.platforms.m4',
  'payments.platforms.m5',
  'payments.platforms.m6',
];
