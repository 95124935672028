/* eslint-disable react/no-danger */

import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_LOT } from '../../../../constants/rails';
import { getArtist, getAuctioneer, getSale } from '../../../../redux/selectors/dbEntities';
import { getCurrency, getDimension } from '../../../../redux/selectors/preferences';
import { getIsFavoritedLot } from '../../../../redux/selectors/myartprice/datas';
import Square from './Square';
import Wide from './Wide';
import { cleanPathname } from '../../../../utils/http';

const Access = ({ lot, position, analyticClickFrom, format, withSelectLot }) => {
  const currency = useSelector(getCurrency);
  const dimension = useSelector(getDimension);
  const isFavoritedLot = useSelector(state => getIsFavoritedLot(state, lot.id));
  const artist = useSelector(state => getArtist(state, { id: lot ? lot.idartist : 0 }));
  const sale = useSelector(state => getSale(state, { id: lot ? lot.idsale : 0 }));
  const auctioneer = useSelector(state => getAuctioneer(state, { id: sale ? sale.idauctioneer : 0 }));

  const urlParam = encodeURIComponent(`${cleanPathname(window.location.pathname)}${window.location.search}`);
  const urlTarget = `${lot.id}/${lot.crypto}?back=${urlParam}`;

  let { title } = lot;
  if (lot.creationDate) {
    title = `${title} (${lot.creationDate})`;
  }

  let auctioneerUrl = null;
  if (lot.auctioneerUrl && !lot.past) {
    auctioneerUrl = `/redir?il=${lot.id}&f=${analyticClickFrom}&o=${ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_LOT}`;
  }

  if (format === 'wide')
    return (
      <Wide
        withSelectLot={withSelectLot}
        isFavoritedLot={isFavoritedLot}
        lot={lot}
        position={position}
        artist={artist}
        sale={sale}
        auctioneer={auctioneer}
        auctioneerUrl={auctioneerUrl}
        currency={currency}
        dimension={dimension}
        title={title}
        urlTarget={urlTarget}
      />
    );

  return (
    <Square
      withSelectLot={withSelectLot}
      isFavoritedLot={isFavoritedLot}
      lot={lot}
      position={position}
      artist={artist}
      sale={sale}
      auctioneer={auctioneer}
      auctioneerUrl={auctioneerUrl}
      currency={currency}
      dimension={dimension}
      title={title}
      urlTarget={urlTarget}
    />
  );
};

export default Access;
