import { onClient } from './ssr';

export const MS_EDGE = 'MS Edge';
export const EDGE = 'Edge ( chromium based)';
export const OPERA = 'Opera';
export const CHROME = 'Chrome';
export const MS_IE = 'MS IE';
export const FIREFOX = 'Firefox';
export const SAFARI = 'Safari';
export const UNKNOWN_BROWSER = 'Other';

export const getBrowser = () => {
  if (onClient()) {
    const agent = window.navigator.userAgent.toLowerCase();

    switch (true) {
      case agent.includes('edge'):
        return MS_EDGE;
      case agent.includes('edg/'):
        return EDGE;
      case agent.includes('opr') && !!window.opr:
        return OPERA;
      case agent.includes('chrome') && !!window.chrome:
        return CHROME;
      case agent.includes('trident'):
        return MS_IE;
      case agent.includes('firefox'):
        return FIREFOX;
      case agent.includes('safari'):
        return SAFARI;
      default:
        return UNKNOWN_BROWSER;
    }
  }

  return UNKNOWN_BROWSER;
};
