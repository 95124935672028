import React, { forwardRef, lazy, Suspense } from 'react';
import Spinner from '../spinners/Spinner';

const LazyEditor = lazy(() => import(/* webpackChunkName: "lazywysiwyg" */ './Lazy'));
const HtmlEditor = forwardRef(({ ...props }, ref) => (
  <Suspense fallback={<Spinner />}>
    <LazyEditor {...props} editorRef={ref} />
  </Suspense>
));

export default HtmlEditor;
