/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import Modal from '../../../../common/ui/Modal';
import './stylesheet.scss';

const Zoom = ({ url, isOpen, onClose }) => (
  <Modal open={isOpen} onClose={onClose} className="marketplace-classified-images-zoom">
    <img src={url} />
  </Modal>
);

export default Zoom;
