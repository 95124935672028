import React from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentUrlParams } from '../../../../redux/selectors/search';
import './stylesheet.scss';
import '../../common/reactSelect/stylesheet.scss';

const CreationDate = () => {
  const dispatch = useDispatch();
  const urlParams = useSelector(getCurrentUrlParams);

  const handleUpdateParams = (triggeredParam, value) => {
    const triggeredParams = [{ triggeredParam, value }];

    dispatch(updateCurrentDraft(triggeredParams));
  };

  return (
    <div className="common-search-filters-creation-date artp-input-group search-common-react-select">
      <div className="artp-input-label">Année de création</div>
      <div className="search-bar-input">
        <div className="cell first" style={{ width: '50%' }}>
          <input
            className="form-control"
            type="number"
            min="0"
            max="2020"
            defaultValue={urlParams.year_from}
            onBlur={e => e.target.value && handleUpdateParams('year_from', e.target.value)}
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
          />
        </div>
        <div className="cell flex">
          <input
            className="form-control"
            type="number"
            min="0"
            max="2020"
            defaultValue={urlParams.year_to}
            onBlur={e => e.target.value && handleUpdateParams('year_to', e.target.value)}
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
          />
        </div>
      </div>
    </div>
  );
};

export default CreationDate;
