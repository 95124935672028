import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getActiveSubscription, isLogged } from '../../../../redux/slices/userContext/selectors';
import { getArtist, getCa, getCategories } from '../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { set as setCookie } from '../../../../utils/cookie';
import { on } from '../../../../redux/actions/ui/switches';
import { useOverlap } from '../../../../hooks/useOverlap';
import '../../../common/stylesheets/buttons.scss';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import EstimateAd from './EstimateAd';
import {
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_INDEXES_BUTTON,
  ID_FREE_ARTIST,
  MODULE_INDEXES,
  ANALYTICS_SECTION_INDEXES_DRAWER,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES,
  RETURN_AFTER_LOGIN_COOKIE_KEY,
} from '../../../../constants/rails';
import Lock from '../common/Lock';
import Drawer from './Drawer';
import Graph from './Graph';
import './stylesheet.scss';
import Tabs from './Tabs';

const ID_DRAWER_INDEXES = 'id_drawer_indexes';

const Indexes = () => {
  const dispatch = useDispatch();
  const estimateRef = useRef();
  const { indexesRef, menuRef, hideLeftMenu } = useContext(UiContext);
  const ca = useSelector(getCa);
  const logged = useSelector(isLogged);
  const artist = useSelector(getArtist);
  const activeSubscription = useSelector(getActiveSubscription);
  const [estimate, setEstimate] = useState(false);
  const categories = useSelector(getCategories);
  const [noSubscriptionAccess, setNoSubscriptionAccess] = useState(false);
  const [displayLock, setDisplayLock] = useState(false);

  // On affiche pas le lien vers l'estimation si il n'y a pas assez de lot
  useEffect(() => {
    setEstimate(categories.past.reduce((total, category) => category.count + total, 0) >= 30);
  }, [categories]);

  useEffect(() => setNoSubscriptionAccess(isEmpty(activeSubscription?.modules.filter(m => m === MODULE_INDEXES))), [activeSubscription]);

  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(noSubscriptionAccess || !logged);
    }
  }, [artist?.id, logged, noSubscriptionAccess]);

  useOverlap({ className: 'overlapped', overlappingElementRef: menuRef, overlappedElementRef: estimateRef });

  if (!artist.macro && !artist.index) return null;

  if (isEmpty(ca?.data)) return null;

  const handleSeeAllClick = ({ e, section }) => {
    let eventBlocked = false;

    if (artist.id !== ID_FREE_ARTIST) {
      if (!noSubscriptionAccess && !logged) {
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES, section, idartist: artist.id });

        e.stopPropagation();
        e.preventDefault();
        const aParent = e.target.closest('a');
        eventBlocked = true;
        setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, aParent ? aParent.href : window.location.href);
        window.location = '/identity/indexes';
      } else if (!logged) {
        e.preventDefault();
        e.stopPropagation();

        aapiBeacon('dom_view', { from: ANALYTICS_FROM_ARTIST, section: ANALYTICS_SECTION_INDEXES_DRAWER, idartist: artist.id });
        eventBlocked = true;

        dispatch(on(ID_DRAWER_INDEXES));
      }
    }

    if (!eventBlocked) aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES, section, idartist: artist.id });
  };

  return (
    <div className={hideLeftMenu ? '' : 'block'} ref={indexesRef}>
      <Drawer id={ID_DRAWER_INDEXES} artist={artist} />
      <div className="container">
        <div className="artists-artist-indexes">
          <h2>
            <A18n rsx="artists.artist.indexes.m1" />
          </h2>
          <p className="sub-h2">
            {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m2" replace={[['%artist%', artist.name]]} />}
            {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m17" replace={[['%artist%', artist.name]]} />}
          </p>
          <Tabs artist={artist} handleSeeAllClick={handleSeeAllClick} displayLock={displayLock} />
          <Graph artist={artist} handleSeeAllClick={handleSeeAllClick} displayLock={displayLock} ca={ca} />
          <div className="actions">
            <a href={artist.urlIndexes} className="artp-btn artp-btn-artist link" onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_BUTTON })} style={{ paddingRight: 40 }}>
              {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m10" />}
              {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m18" />}
              {displayLock && <Lock stroke="#000" style={{ position: 'absolute' }} />}
            </a>
          </div>
        </div>
      </div>
      {estimate && !hideLeftMenu && (
        <div className="container">
          <EstimateAd ref={estimateRef} menuRef={menuRef} />
        </div>
      )}
    </div>
  );
};

export default Indexes;
