/* eslint-disable global-require */
module.exports = [
  ...require('./MainIllustration/i18n'),
  ...require('./Signatures/i18n'),
  ...require('./Breadcrumb/i18n'),
  'artists.artist.header.m1',
  'artists.artist.header.m2',
  'artists.artist.header.m3',
  'artists.artist.header.m4',
  'artists.artist.header.m5',
  'artists.artist.header.m6',
  'artists.artist.header.m7',
  'artists.artist.header.m8',
  'artists.artist.header.m9',
];
