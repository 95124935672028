import React, { memo } from 'react';
import Map from '../../../../common/graphs/Map';
import { EMPTY_ARRAY } from '../../../../../constants/utils';

function formatter(value) {
  return `${(value * 100).toFixed(2)}%`;
}

const StatsMap = ({ data = EMPTY_ARRAY }) => {
  const processedData = data.filter(({ value }) => value > 0.01);

  return <Map data={processedData} formatter={formatter} />;
};

export default memo(StatsMap);
