/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.m1',
  'subscriptions.list.cards.m2',
  'subscriptions.list.cards.m3',
  'subscriptions.list.cards.m4',
  'subscriptions.list.cards.m5',
  'subscriptions.list.cards.m6',
  'subscriptions.list.cards.m7',
  'subscriptions.list.cards.m8',
  'subscriptions.list.cards.m9',
  'subscriptions.list.cards.m10',
  'subscriptions.list.cards.m11',
  ...require('./Card/i18n'),
];
