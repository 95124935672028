import React from 'react';
import MaskedInput from 'react-text-mask';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // See app/assets/stylesheets/fix/_react_datepicker.scss
import '../../../../../assets/stylesheets/fix/_react_datepicker.scss';
import { Controller } from 'react-hook-form';
import dateFr from 'date-fns/locale/fr';
import dateEn from 'date-fns/locale/en-US';
import dateDe from 'date-fns/locale/de';
import dateZh from 'date-fns/locale/zh-CN';
import dateIt from 'date-fns/locale/it';
import dateEs from 'date-fns/locale/es';
import i18next from 'i18next';
import './stylesheet.scss';

const dateLocales = { fr: dateFr, en: dateEn, de: dateDe, zh: dateZh, it: dateIt, es: dateEs };
const MaskedDateInput = React.forwardRef(({ ...props }, ref) => {
  let mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  if (i18next.language === 'zh') {
    mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  }
  if (i18next.language === 'de') {
    mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  }
  return <MaskedInput ref={ref} className="form-control" mask={mask} {...props} />;
});

const DateInput = ({ name, control, readOnly, rules }) => {
  registerLocale(i18next.language, dateLocales[i18next.language]);

  return (
    <div className="DateInput">
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            showMonthDropdown
            showYearDropdown
            locale={i18next.language}
            customInput={<MaskedDateInput />}
            dateFormat="P"
            maxDate={new Date()}
            autoComplete="off"
            calendarClassName="account-verify-identity-date-input"
            className="form-control"
            dropdownMode="select"
          />
        )}
      />
    </div>
  );
};

export default DateInput;
