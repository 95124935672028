import i18Next from 'i18next';
import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import './stylesheet.scss';

const NoPict = () => (
  <div className="no-pict">
    <img alt={i18Next.t('lots.small.no_access.m1')} src={`${IMAGE_SOURCE}/no-access/img-non-dispo.svg`} />
    <p>{i18Next.t('lots.small.no_access.m1')}</p>
  </div>
);

export default NoPict;
