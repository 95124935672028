import React from 'react';

const Arrow = ({ order, index }) => {
  const { on, direction } = order;

  if (on !== index) return <i className="fa fa-sort" style={{ color: '#ccc' }} />;
  if (direction === 'asc') return <i className="fa fa-caret-up" />;
  return <i className="fa fa-caret-down" />;
};

export default Arrow;
