/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import AsyncSelect from 'react-select/async';
import { auctioneerSearch } from '../../../../../services/search';
import { fetch } from '../../../../../services/dbEntities/auctioneer';
import customStyles from '../../../common/reactSelect/style';

// factory to make a searchManager per instance of ArtistSearch (to deal with searchOnlyInFavorites for example)
// The cache is global (to deal with unplanned destroy of the component)
const cache = new Map();
const searchManager = {
  clear() {
    cache.clear();
  },
  processAuctioneer(auctioneer) {
    if (cache.has(auctioneer.id)) {
      return cache.get(auctioneer.id);
    }
    // name + city
    const formattedAuctioneer = { value: auctioneer.id, label: `${auctioneer.name} - ${auctioneer.city}` };
    cache.set(auctioneer.id, formattedAuctioneer);
    return formattedAuctioneer;
  },
  async search(term) {
    try {
      const auctioneers = await auctioneerSearch({ term, perPage: 25 });
      return auctioneers.map(auctioneer => this.processAuctioneer(auctioneer));
    } catch (e) {
      console.error(e);
    }
  },
  async fetchFromApi(id) {
    try {
      return fetch(id);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
  async getAuctioneer(id) {
    if (!id) {
      return undefined;
    }
    const auctioneerFromCache = cache.get(id);
    if (auctioneerFromCache) {
      return auctioneerFromCache;
    }
    const auctioneerFromApi = await this.fetchFromApi(id);
    if (auctioneerFromApi) {
      return this.processAuctioneer(auctioneerFromApi);
    }
    return { value: id, label: id };
  },
  async processValue(value) {
    if (Array.isArray(value)) {
      return Promise.all(value.map(v => this.getAuctioneer(v)));
    }
    return this.getAuctioneer(value);
  },
};

const AuctioneerSearch = ({ className, value, onChange, disabled = false }) => {
  const [loading, setLoading] = useState(undefined);
  const [processedValue, setProcessedValue] = useState();

  useEffect(() => {
    setLoading(true);
    searchManager.processValue(value).then(v => {
      setProcessedValue(v);
      setLoading(undefined);
    });
  }, [value]);

  return (
    <AsyncSelect
      styles={customStyles(0)}
      classNamePrefix="react-select"
      className={`search-select search-auctioneer ${className ?? ''}`.trim()}
      isClearable
      isMulti
      cacheOptions
      isDisabled={disabled}
      isLoading={loading}
      placeholder={i18next.t('components.search.inputs.auctioneer_search.m1')}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length > 0) {
          return i18next.t('components.search.inputs.auctioneer_search.m2');
        }
        return i18next.t('components.search.inputs.auctioneer_search.m3');
      }}
      value={processedValue}
      loadingMessage={() => i18next.t('components.search.inputs.auctioneer_search.m4')}
      loadOptions={async term => searchManager.search(term)}
      onChange={values => {
        if (Array.isArray(values) && values.length > 0) {
          onChange(values.map(v => v.value));
        } else {
          onChange(undefined);
        }
      }}
    />
  );
};

export default AuctioneerSearch;
