/* eslint-disable global-require */
module.exports = [
  ...require('./Cgv/i18n'),
  ...require('./Password/i18n'),
  ...require('./AddressFields/i18n'),
  'payments.informations.form.m1',
  'payments.informations.form.m2',
  'payments.informations.form.m3',
  'payments.informations.form.m4',
  'payments.informations.form.m5',
  'payments.informations.form.m6',
  'payments.informations.form.m7',
  'payments.informations.form.m8',
  'payments.informations.form.m9',
];
