import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { getAll, getDefaults } from '../../../../services/marketplace/howto';
import HighlightedTitle from '../../../common/HighlightedTitle';
import { useViewport } from '../../../../hooks/useViewport';
import A18n from '../../../common/A18n';
import Desktop from './Desktop';
import Mobile from './Mobile';
import './stylesheet.scss';

const Faq = ({ lang, fullFaq = false }) => {
  const { viewportWidth } = useViewport();
  const [faq, setFaq] = useState({});
  const [dataloading, setDataloading] = useState(false);

  useEffect(() => {
    setDataloading(true);
    if (fullFaq) {
      getAll().then(data => {
        setFaq(data);
        setDataloading(false);
      });
    } else {
      getDefaults().then(data => {
        setFaq(data);
        setDataloading(false);
      });
    }
  }, []);

  return (
    <div className={`marketplace-how-to-bid-faq ${fullFaq ? 'marketplace-how-to-bid-full-faq' : ''}`}>
      <HighlightedTitle title={<A18n rsx={fullFaq ? 'marketplace.how_to.html.m1' : 'marketplace.howtobid.faq.m1'} />} tag="h1" />
      {viewportWidth < 768 && <Mobile faq={faq} lang={lang} dataloading={dataloading} fullFaq={fullFaq} />}
      {viewportWidth >= 768 && <Desktop fullFaq={fullFaq} faq={faq} lang={lang} dataloading={dataloading} />}
      {!fullFaq && (
        <div className="faq-actions">
          <a href={`/${i18next.t('routes.marketplace_howto')}`} className="btn btn-primary">
            <A18n rsx="marketplace.howtobid.faq.m3" />
          </a>
        </div>
      )}
    </div>
  );
};

export default Faq;
