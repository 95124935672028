import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOnlyLotsSignedByArtistInPush, getPushEmail } from '../../../../../redux/slices/userContext/selectors';
import { setNewslettersSubscription } from '../../../../../redux/actions/account';
import Checkbox from '../../../../common/ui/Checkbox';
import './stylesheet.scss';

const Config = () => {
  const dispatch = useDispatch();
  const hasPushMail = useSelector(getPushEmail);
  const onlyLotsSignedByArtistInPush = useSelector(getOnlyLotsSignedByArtistInPush);

  return (
    <div className="myartprice-settings-artists-configflipswitches">
      <div className=" col-sm-12 font font-normal" style={{ marginBottom: 15 }}>
        <Checkbox checked={hasPushMail} onChange={value => dispatch(setNewslettersSubscription({ section: 'push_email', value }))} label={i18next.t('myartprice.settings.artists.configflipswitches.m1')} />
      </div>
      <div className=" col-sm-12 font font-normal" style={{ marginBottom: 15 }}>
        <Checkbox
          checked={onlyLotsSignedByArtistInPush}
          onChange={value => dispatch(setNewslettersSubscription({ section: 'only_lots_signed_by_artist_in_push', value }))}
          label={i18next.t('myartprice.settings.artists.configflipswitches.m2')}
        />
      </div>
    </div>
  );
};

export default Config;
