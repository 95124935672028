import React from 'react';
import './stylesheet.scss';
import A18n from '../../../../../../common/A18n';
import usePriceOneDay from '../../../../common/usePriceOneDay';

const Price = () => {
  const priceOneDay = usePriceOneDay();

  if (!priceOneDay) return null;

  return (
    <div className="artists-artist-lots-no-access-drawer-price">
      <div>
        <A18n rsx="artists.artist.lots.noaccessdrawer.price.m1" />
      </div>
      <div className="price">{priceOneDay}</div>
    </div>
  );
};
export default Price;
