import React from 'react';
import Faq from '..';
import ReduxProvider from '../../providers/ReduxProvider';

const FaqProvided = ({ faqData }) => (
  <ReduxProvider>
    <Faq faqData={faqData} />
  </ReduxProvider>
);

export default FaqProvided;
