import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

const NoAccess = () => (
  <div className="marketplace-stores-common-no-access">
    <div className="image">
      <img alt="store-no-access" src={`${IMAGE_SOURCE}store-no-access.svg`} />
    </div>
    <div className="content">
      <div className="title">{i18next.t('marketplace.stores.common.noaccess.m1')}</div>
      <div className="text">{i18next.t('marketplace.stores.common.noaccess.m2')}</div>
      <div className="action">
        <a className="btn btn-primary" href="/subscription/store">
          {i18next.t('marketplace.stores.common.noaccess.m3')}
        </a>
      </div>
    </div>
  </div>
);

export default NoAccess;
