import i18next from 'i18next';
import { CURRENCIES } from '../../../../../constants/preferences';
import { formatFns } from '../../../../../utils/dates/format';

const intervalDisplay = ({ from, to, i18n, addOn }) => {
  const values = [];
  if (from) values.push(`${from}${addOn || ''} <=`);
  if (i18n) values.push(i18next.t(i18n));
  if (to) values.push(`<= ${to}${addOn || ''}`);

  return values.join(' ');
};

export default [
  {
    id: 'terms',
    fields: ['terms'],
    paramsToDelete: ['terms'],
    display: params => params.terms,
  },
  {
    id: 'keyword',
    fields: ['keyword'],
    paramsToDelete: ['keyword'],
    display: params => params.keyword,
  },
  {
    id: 'size',
    fields: ['length', 'width', 'height', 'percent'],
    paramsToDelete: ['length', 'width', 'height', 'percent'],
    display: params => {
      const values = [];
      if (params.length > 0) values.push(`${i18next.t('lots.search.form.size.m2')} ${params.length}`);
      if (params.width > 0) values.push(`${i18next.t('lots.search.form.size.m1')} ${params.width}`);
      if (params.height > 0) values.push(`${i18next.t('lots.search.form.size.m3')} ${params.height}`);
      if (params.percent > 0) values.push(`+/- ${params.percent}%`);

      return values.join(', ');
    },
  },
  {
    id: 'price',
    fields: ['price_min', 'price_max'],
    paramsToDelete: ['price_min', 'price_max', 'price_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.price_min,
        to: params.price_max,
        i18n: 'marketplace.searchbars.classifieds.bar.m9',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.price_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'price',
    fields: ['price_from', 'price_to'],
    paramsToDelete: ['price_from', 'price_to', 'price_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.price_from,
        to: params.price_to,
        i18n: 'marketplace.searchbars.classifieds.bar.m9',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.price_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'estimation',
    fields: ['estimation_from', 'estimation_to'],
    paramsToDelete: ['estimation_from', 'estimation_to', 'estimation_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.estimation_from,
        to: params.estimation_to,
        i18n: 'lots.show.m12',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.estimation_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'year',
    fields: ['year_from', 'year_to'],
    paramsToDelete: ['year_from', 'year_to'],
    i18next: 'search.drawers.lot.m1',
    display: params =>
      intervalDisplay({
        from: params.year_from,
        to: params.year_to,
        i18n: 'search.drawers.lot.m1',
      }),
  },
  {
    id: 'dates',
    fields: ['dt_from', 'dt_to'],
    paramsToDelete: ['dt_from', 'dt_to'],
    display: params =>
      intervalDisplay({
        from: formatFns(new Date(params.dt_from), 'P'),
        to: formatFns(new Date(params.dt_to), 'P'),
        i18n: 'filters.dates.m1',
      }),
  },
  {
    id: 'birth',
    fields: ['birth_from', 'birth_to'],
    paramsToDelete: ['birth_from', 'birth_to'],
    display: params =>
      intervalDisplay({
        from: params.birth_from,
        to: params.birth_to,
      }),
  },
];
