import i18next from 'i18next';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get as getUiDictionary } from '../../../../../../redux/selectors/ui/dictionary';
import { SHORTCUT_SETTINGS_ARTISTS, SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_SECTIONS, VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../../../../constants/myartprice';
import { set as setUiDictionary } from '../../../../../../redux/actions/ui/dictionary';
import { off as closeDrawer } from '../../../../../../redux/actions/ui/switches';

const Settings = ({ drawerId }) => {
  const dispatch = useDispatch();
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  const handleSectionClick = element => {
    dispatch(setUiDictionary(VERTICAL_BAR_CURRENT_ELEMENT_KEY, element));

    if (drawerId) {
      document.body.classList.remove('drawer-opened');
      dispatch(closeDrawer(drawerId));
    }
  };

  return (
    <div className="shortcuts">
      <div className="header-settings">{i18next.t('components.myartprice.common.verticalbar.content.m6').toUpperCase()}</div>

      <div className="links" style={{ maxHeight: 22 + 3 * 24 }}>
        {currentElement !== SHORTCUT_SETTINGS_ARTISTS && (
          <Link to="/myartprice/settings/artists" onClick={() => handleSectionClick(SHORTCUT_SETTINGS_ARTISTS)}>
            <div className="shortcut">{i18next.t('components.myartprice.common.verticalbar.content.m9')}</div>
          </Link>
        )}
        {currentElement === SHORTCUT_SETTINGS_ARTISTS && <div className="shortcut active">{i18next.t('components.myartprice.common.verticalbar.content.m9')}</div>}

        {currentElement !== SHORTCUT_SETTINGS_CATEGORIES && (
          <Link to="/myartprice/settings/categories" onClick={() => handleSectionClick(SHORTCUT_SETTINGS_CATEGORIES)}>
            <div className="shortcut">{i18next.t('components.myartprice.common.verticalbar.content.m8')}</div>
          </Link>
        )}
        {currentElement === SHORTCUT_SETTINGS_CATEGORIES && <div className="shortcut active">{i18next.t('components.myartprice.common.verticalbar.content.m8')}</div>}

        {currentElement !== SHORTCUT_SETTINGS_SECTIONS && (
          <Link to="/myartprice/settings/sections" onClick={() => handleSectionClick(SHORTCUT_SETTINGS_SECTIONS)}>
            <div className="shortcut">{i18next.t('components.myartprice.common.verticalbar.content.m7')}</div>
          </Link>
        )}
        {currentElement === SHORTCUT_SETTINGS_SECTIONS && <div className="shortcut active">{i18next.t('components.myartprice.common.verticalbar.content.m7')}</div>}
      </div>
    </div>
  );
};

export default Settings;
