import React from 'react';
import HighlightedTitle from '../../../common/HighlightedTitle';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Header = () => (
  <div className="header">
    <HighlightedTitle title={<A18n rsx="marketplace.howtobid.header.m1" />} tag="h1" />
    <p>
      <A18n rsx="marketplace.howtobid.header.m2" />
    </p>
    <p>
      <A18n rsx="marketplace.howtobid.header.m3" />
    </p>
  </div>
);

export default Header;
