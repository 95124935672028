import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './stylesheets.scss';

const Carousel = ({ containerStyle = {}, slides, settings }) => (
  <div className="common-carousel" style={containerStyle}>
    <Slider {...settings}>{slides}</Slider>
  </div>
);

export default Carousel;
