import React, { useState } from 'react';
import i18next from 'i18next';
import { Col, Container, Row, Button } from 'reactstrap';
import { useCookies } from 'react-cookie';
import CookieProvider from '../../providers/CookieProvider';
import { COOKIE_CONSENT_ACCEPTED, COOKIE_CONSENT_NAME, COOKIE_CONSENT_REJECTED } from '../../../constants/rails';
import { ALL_REJECTED, getCookiePreferences, hasChosenConsent, setConsent } from '../../../utils/cookieConsent';
import FlipSwitch from '../../common/ui/FlipSwitch';

import './stylesheet.scss';

const CookieConsentManagement = () => {
  useCookies([COOKIE_CONSENT_NAME]); // rerender component when cookie changes
  const [preferences, setPreferences] = useState(hasChosenConsent() ? getCookiePreferences() : ALL_REJECTED);

  return (
    <Container className="CookieConsentManagement">
      <h1>{i18next.t('component.cookie.management.m1')}</h1>
      <Row>
        <Col sm={12}>{i18next.t('component.cookie.management.m2')}</Col>
      </Row>
      <Row>
        <Col sm={12} className="col">
          <h3>
            {i18next.t('component.cookie.management.m3')}
            <div className="flex-filler" />
          </h3>
          <div className="details">
            <div>{i18next.t('component.cookie.management.m4')}</div>
            <ul style={{ marginTop: 10 }}>
              <li>
                <a href="https://www.paypal.com/fr/webapps/mpp/ua/cookie-full">Paypal</a>
              </li>
              <li>
                <a href="https://stripe.com/cookies-policy/legal#translation">Stripe</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="col">
          <h3>
            {i18next.t('component.cookie.management.m5')}
            <div className="flex-filler" />
            <FlipSwitch
              checked={preferences.analytics === COOKIE_CONSENT_ACCEPTED}
              onChange={() => setPreferences({ ...preferences, analytics: preferences.analytics === COOKIE_CONSENT_ACCEPTED ? COOKIE_CONSENT_REJECTED : COOKIE_CONSENT_ACCEPTED })}
            />
          </h3>
          <div className="details">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: i18next.t('component.cookie.management.m6') }} />
            <ul style={{ marginTop: 10 }}>
              <li>
                <a href="https://policies.google.com/technologies/cookies">Google Analytics</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="col">
          <h3>
            {i18next.t('component.cookie.management.m8')}
            <div className="flex-filler" />
            <FlipSwitch
              checked={preferences.social === COOKIE_CONSENT_ACCEPTED}
              onChange={() => setPreferences({ ...preferences, social: preferences.social === COOKIE_CONSENT_ACCEPTED ? COOKIE_CONSENT_REJECTED : COOKIE_CONSENT_ACCEPTED })}
            />
          </h3>
          <div className="details">
            <div>{i18next.t('component.cookie.management.m9')}</div>
            <ul style={{ marginTop: 10 }}>
              <li>
                <a href="https://help.twitter.com/rules-and-policies/twitter-cookies">Twitter</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 3, offset: 9 }} className="col-sm-offset-9" style={{ textAlign: 'right' }}>
          <Button
            className="btn btn-primary"
            onClick={() => {
              setConsent(preferences);
              window.history.back();
            }}
          >
            {i18next.t('component.cookie.management.m7')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const ProvidedCookieConsentManagement = () => (
  <CookieProvider>
    <CookieConsentManagement />
  </CookieProvider>
);

export default ProvidedCookieConsentManagement;
