import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const SameToSellAnnounce = ({ className }) => {
  const [routeMarketplace, setRouteMarketplace] = useState('marketplace');

  useEffect(() => {
    setRouteMarketplace(i18next.t('routes.marketplace'));
  }, [i18next.t('routes.marketplace')]);

  return (
    <div className={`marketplace-classified-same-to-sell ${className || ''}`}>
      <div className="label">
        <A18n rsx="marketplace.classified.m3" />
      </div>
      <div className="link">
        <a rel="noreferrer" target="_blank" href={`/${routeMarketplace}/how-to-sell`}>
          <A18n rsx="marketplace.classified.m4" />
        </a>
      </div>
    </div>
  );
};

export default SameToSellAnnounce;
