import React from 'react';
import { isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMouseHoverSelectedParam } from '../../../../redux/actions/marketplace/searchbars/ui';
import { getCurrentDefaultFacets as getFacetsSelector, getCurrentUrlParams as getInitialParamsSelector } from '../../../../redux/selectors/search';
import { clearCurrentSearch } from '../../../../redux/actions/search';
import Facet from './Facet';
import Param from './Param';
import paramsConfig from './Param/config';

import { EMPTY_ARRAY } from '../../../../constants/utils';
import './stylesheet.scss';

const ALWAYS_HIDDEN_FACETS = ['ipp', 'p', 'nbd'];

const SelectedParams = ({ redirect = true, hiddenFacets = EMPTY_ARRAY }) => {
  const dispatch = useDispatch();
  const facets = useSelector(getFacetsSelector);
  const initialParams = useSelector(getInitialParamsSelector);
  const processedFields = [];
  const mergedHiddenFacets = [...ALWAYS_HIDDEN_FACETS, ...hiddenFacets];

  if (Object.keys(initialParams).filter(key => !mergedHiddenFacets.includes(key)).length === 0) return null;

  const activeParams = Object.entries(initialParams).filter(([facetName, identifiers]) => identifiers && identifiers.length > 0 && !mergedHiddenFacets.includes(facetName));
  const paramsCount = activeParams.reduce((count, [_, identifiers]) => {
    if (Array.isArray(identifiers)) {
      return count + identifiers.length;
    }
    return count + 1;
  }, 0);

  return (
    <div className="search-bar-selection">
      <div className="selection-group" onMouseLeave={() => dispatch(endOfMouseHoverSelectedParam())}>
        {activeParams.map(([facetName, identifiers]) => {
          if (facets[facetName]) {
            return (
              <div key={`${facetName}-${identifiers}-selection`} className="selection">
                {isArray(identifiers) && identifiers.map(identifier => <Facet redirect={redirect} key={`${facetName}-${identifier}`} facetName={facetName} identifier={identifier} />)}
                {!isArray(identifiers) && <Facet key={`${facetName}`} redirect={redirect} facetName={facetName} identifier={identifiers} />}
              </div>
            );
          }

          const groupParams = paramsConfig.filter(groupIdentifiers => groupIdentifiers.fields.includes(facetName));
          if (groupParams.length === 0 || processedFields.includes(facetName)) return null;

          processedFields.push(...groupParams[0].fields);
          return (
            <div key={`${facetName}-${identifiers}-selection`} className="selection">
              <Param redirect={redirect} key={`${facetName}-${identifiers}`} params={groupParams[0]} />
            </div>
          );
        })}
        {paramsCount > 1 && (
          <div style={{ marginRight: '10px', cursor: 'pointer' }}>
            <i className="fa fa-trash-o" onClick={() => dispatch(clearCurrentSearch())} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedParams;
