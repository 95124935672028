/* eslint-disable global-require */
module.exports = [
  'artprice.storetype.m1',
  'artprice.storetype.m2',
  'artprice.storetype.m3',
  'artprice.storetype.m4',
  'artprice.storetype.m5',
  'artprice.storetype.m6',
  'artprice.storetype.m7',
  'artprice.storetype.m8',
  'artprice.storetype.m9',
  'artprice.storetype.m10',
  'artprice.storetype.m11',
  'marketplace.store._contact_store.html.m1',
];
