import React, { Component } from 'react';
import { array, func, number, oneOfType, string } from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import i18next from 'i18next';

export const capitalize = label => label && `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

class CountriesDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { dropdownOpen } = this.state;
    const { options, choose, selectedValue } = this.props;
    if (options.length <= 0) return null;

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="country-filter">
        <DropdownToggle caret>
          <span>{i18next.t('events.list.filters.countries.title')}</span>
          <i className={`fa fa-caret-down${dropdownOpen ? ' is-open' : ''}`} />
        </DropdownToggle>

        <DropdownMenu right>
          <DropdownItem header>{i18next.t('events.list.filters.countries.dropdownheader')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem disabled={!selectedValue} value={undefined} onClick={() => choose(undefined)}>
            {i18next.t('events.list.filters.countries.options.all')}
          </DropdownItem>

          {options.map(({ value, label }) => (
            <DropdownItem key={value} disabled={parseInt(value, 10) === parseInt(selectedValue, 10)} value={value} onClick={() => choose(value)}>
              {capitalize(label)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

CountriesDropdown.defaultProps = {
  choose: () => undefined,
  selectedValue: undefined,
};

CountriesDropdown.propTypes = {
  // eslint-disable-next-line
  options: array.isRequired,

  selectedValue: oneOfType([string, number]),
  choose: func,
};

export default CountriesDropdown;
