import i18next from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSale } from '../../../redux/selectors/dbEntities';
import './stylesheets.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_FROM_SALE, ANALYTICS_CLICK_OPERATION_VIEWING_TIMES } from '../../../constants/rails';
import Modal from '../../common/ui/Modal';

const Expositions = ({ id }) => {
  const sale = useSelector(state => getSale(state, { id }));
  const [open, setOpen] = useState(false);

  if (!sale || sale.expositions.length === 0) return null;

  return (
    <div className="sales-expositions">
      <button
        type="button"
        className="btn btn-default"
        onClick={() => {
          setOpen(true);
          aapiBeacon('click', { from: ANALYTICS_FROM_SALE, op: ANALYTICS_CLICK_OPERATION_VIEWING_TIMES, idsale: id });
        }}
      >
        <i className="fa fa-thumb-tack" />
        {i18next.t('artprice.lib.tour.tour4.m63')}
      </button>

      <Modal open={open} onClose={() => setOpen(false)} title={i18next.t('artprice.lib.tour.tour4.m63')} className="sales-contacts-modale">
        {sale.expositions.map(exposition => (
          <p key={exposition}>{exposition}</p>
        ))}

        <p style={{ textAlign: 'right', marginTop: 15, marginBottom: 0 }}>
          <button type="button" className="btn btn-default" onClick={() => setOpen(false)}>
            {i18next.t('auctioneers.contact.messagesent.m3')}
          </button>
        </p>
      </Modal>
    </div>
  );
};

export default Expositions;
