/* eslint-disable jsx-a11y/no-autofocus */

import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import GoogleRecaptcha from 'react-google-invisible-recaptcha';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { fetchUserContext } from '../../../../redux/slices/userContext';
import { login } from '../../../../services/wallet/customer';
import ErrorBlock from '../../ErrorBlock';
import '../../stylesheets/form.scss';
import A18n from '../../A18n';

function onFormError(e) {
  Sentry.captureMessage('[form_confirm_identity] Validation failed', {
    extra: {
      errors: e,
    },
  });
}

const Identification = ({ email, onSuccess }) => {
  const intl = useIntl();
  const refRecaptcha = useRef();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(null);
  const [display, setDisplay] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const onCaptchaResolved = async () => {
    const values = getValues();
    const captchaResponse = await refRecaptcha.current.callbacks.getResponse();
    const formData = new FormData();

    formData.append('g-recaptcha-response', captchaResponse);
    formData.append('login', values.email);
    formData.append('pass', values.password);

    login(formData).then(data => {
      const { error } = data;
      if (error) {
        console.error('error', data);
        setLoginError({ message: data.error });
      } else {
        dispatch(fetchUserContext(true));
        onSuccess();
      }
    });
  };

  useEffect(() => {
    setValue('email', email);
  }, [email]);

  const onSubmit = async () => {
    refRecaptcha.current.callbacks.execute();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onFormError)}>
      <GoogleRecaptcha onResolved={() => onCaptchaResolved()} ref={refRecaptcha} sitekey="6LeTtBgUAAAAAGRkfCoKrDm_o5tKEMBnnJ3OAVqK" locale={intl.locale} />
      <div className="group">
        <div className="cell">
          <input id="customer_login" className="form-control" disabled type="text" {...register('email')} />
          <ErrorBlock error={errors.email} />
        </div>
      </div>
      <div className="group">
        <div className="cell">
          <div className="input-button">
            <input
              id="customer_password"
              className="form-control"
              type={display ? 'text' : 'password'}
              {...register('password')}
              placeholder={i18next.t('common.loginorregistrationmodal.login.m2')}
              autoFocus
              style={{ borderRight: 0 }}
            />
            <button type="button" onClick={() => setDisplay(!display)}>
              <i
                className={classNames({
                  fa: true,
                  'fa-eye': !display,
                  'fa-eye-slash': display,
                })}
              />
            </button>
            <button type="submit" id="sln_submit">
              <A18n rsx="common.loginorregistrationmodal.login.m4" />
            </button>
          </div>

          <ErrorBlock error={errors.password || loginError} />
        </div>
      </div>
      <div className="group">
        <div className="cell" style={{ paddingLeft: 25 }}>
          <a href="/account/forgot_password">
            <A18n rsx="common.loginorregistrationmodal.login.m3" />
          </a>
        </div>
      </div>
    </form>
  );
};

export default Identification;
