import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSubscriptionName, getDisplayedPromotionId, getHasImageAccess, isLogged } from '../../../redux/slices/userContext/selectors';
import { getIdFreeArtist, getIdFreeLot } from '../../../redux/selectors/dbEntities';
import { fetchFreeArtist } from '../../../redux/actions/dbEntities/artist';
import Promotion from './Promotion';
import Artist from './FreeArtist';
import './stylesheet.scss';

const Subscription = ({ idlot }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const hasImageAccess = useSelector(getHasImageAccess);
  const activeSubscription = useSelector(getActiveSubscriptionName);
  const displayedPromotionId = useSelector(getDisplayedPromotionId);
  const freeIdArtist = useSelector(getIdFreeArtist);
  const freeIdLot = useSelector(getIdFreeLot);

  useEffect(() => {
    if (!freeIdArtist) {
      dispatch(fetchFreeArtist());
    }
  }, [freeIdArtist]);

  useEffect(() => {
    if (!freeIdLot) {
      // dispatch(fetchFreeLot());  // FIXME do not always fetch a lot in dbEntities.lots.data
    }
  }, [freeIdLot]);

  if (!idlot) return null;

  if (activeSubscription && logged && hasImageAccess) return null;

  if (activeSubscription && logged && !hasImageAccess) {
    return (
      <div className="lots-show-no-access-subscription">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 content">
              <div className="info">
                <p>{i18next.t('lots.no_access.m10')}</p>
                <p>
                  <a href="/subscription" id="sln_upgrade" className="btn btn-primary">
                    {i18next.t('lots.no_access.m17')}
                  </a>
                </p>
                <p className="small">
                  <Artist id={freeIdArtist} />
                </p>
              </div>
              {displayedPromotionId && (
                <div className="promotion">
                  <Promotion id={displayedPromotionId} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (activeSubscription && !logged) {
    return (
      <div className="lots-show-no-access-subscription">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 content">
              <a href="/identity/sales">
                <i className="fa fa-lock" />
                {i18next.t('lots.no_access.m16')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="lots-show-no-access-subscription">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 content">
            <div className="info">
              <p>{i18next.t('lot.no_access.show.m1')}</p>
              <p>
                <a className="btn btn-primary" href="/subscription" id="sln_subscription">
                  {i18next.t('lot.no_access.show.m2')}
                </a>
              </p>
              <div className="free-sample">{/* <Lot id={freeIdLot}/> */}</div>
            </div>
            {displayedPromotionId && (
              <div className="promotion">
                <Promotion id={displayedPromotionId} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
