/* eslint-disable global-require */
module.exports = [
  'components.lotslists.show.m9',
  'components.lotslists.show.m10',
  'components.lotslists.create.m1',
  'components.myartprice.settings.artists.m3',
  'components.lotslists.show.m11',
  'components.lotslists.show.m12',
  'components.lotslists.modal.m1',
];
