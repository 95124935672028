import React from 'react';
import { useIntl } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLIC_API_KEY } from '../../../../constants/rails';
import StripeCheckoutForm from './StripeCheckoutForm';
import StripeRedirect from './StripeRedirect';
import A18n from '../../../common/A18n';

const Stripe = ({ options = {}, idpayment, displayedPaymentMethod, onError }) => {
  const intl = useIntl();
  const { checkoutFormOptions, stripeSubscriptionCheckoutSessionId } = options;
  const stripePromise = loadStripe(STRIPE_PUBLIC_API_KEY);

  if (!checkoutFormOptions && !stripeSubscriptionCheckoutSessionId) return null;
  return (
    <div>
      <p>
        <A18n rsx="payments.platforms.stripe.m1" />
      </p>
      <Elements
        stripe={stripePromise}
        options={{
          ...checkoutFormOptions,
          locale: intl.locale,
          fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i' }],
          appearance: {
            variables: {
              fontFamily: '"open sans", serif',
              colorPrimary: '#233a84',
              tabIconSelectedColor: '#233a84',
            },
          },
        }}
      >
        {checkoutFormOptions && <StripeCheckoutForm idpayment={idpayment} displayedPaymentMethod={displayedPaymentMethod} onError={onError} />}
        {stripeSubscriptionCheckoutSessionId && <StripeRedirect checkoutSessionId={stripeSubscriptionCheckoutSessionId} />}
      </Elements>
    </div>
  );
};

export default Stripe;
