import React from 'react';
import { useSelector } from 'react-redux';
import { aapiBeacon } from '../../../../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_PROMOTION_NOTIFY, ANALYTICS_SECTION_PROMO_NOTIFIER_MENU } from '../../../../../../../../constants/rails';
import { getLocaleStorage } from '../../../../../../../../utils/storage';
import { usePromotionNotify } from '../../../../../../../../hooks/usePromotionNotify';
import { getHasStore } from '../../../../../../../../redux/slices/userContext/selectors';

const PromotionNotifier = () => {
  const { promotionNotify, displayCountLocaleStorageKey } = usePromotionNotify();
  const hasStore = useSelector(getHasStore);
  const displayCount = getLocaleStorage(displayCountLocaleStorageKey);

  const onClick = () => {
    aapiBeacon('click', { op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, from: ANALYTICS_FROM_PROMOTION_NOTIFY, section: ANALYTICS_SECTION_PROMO_NOTIFIER_MENU, slug: `display-${displayCount}` });
    if (hasStore) {
      window.location = `/subscription/store?promocode=${promotionNotify.promocode}&type=new`;
    } else {
      window.location = `/subscription?promocode=${promotionNotify.promocode}&type=new`;
    }
  };

  if (promotionNotify?.status !== 1) return null;

  return (
    <div className="artp-Header-MyAccount-promotion-notifier" onClick={onClick}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: promotionNotify.description }} />
    </div>
  );
};

export default PromotionNotifier;
