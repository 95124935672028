import React, { useState } from 'react';
import i18Next from 'i18next';
import { isPlainObject, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { appendToList, createAndAppendToList, getLists } from '../../../../../../../redux/slices/account/lotslists';
import './stylesheet.scss';
import ListInput from '../../../../common/modal/ListInput';
import ListSelect from '../../../../common/modal/ListSelect';

const Creation = ({ onSubmit, setVisibility }) => {
  const dispatch = useDispatch();
  const lists = useSelector(getLists);
  const [label, setLabel] = useState('');
  const [selectedIdlist, setSelectedIdlist] = useState(0);

  const handleSubmit = async () => {
    try {
      if (selectedIdlist > 0) {
        await dispatch(appendToList({ idlist: selectedIdlist }));
      } else {
        await dispatch(createAndAppendToList({ label }));
      }
      onSubmit({ error: false });
    } catch (e) {
      if (e.isAxiosError) {
        if (isPlainObject(e.response.data)) {
          onSubmit(e.response.data); // contains error and errorDetails
        } else {
          onSubmit({
            error: i18Next.t('customerlotslist.error.m1'),
            errorDetails: `Error ${e.response.status}`,
          });
        }
      } else {
        throw e;
      }
    }
  };

  if (!lists) return null;

  return (
    <div className="account-lots-lists-add-to-list-button-create-content-creation">
      <p className="title">{i18Next.t('components.lotslists.create.m5')}</p>
      <ListInput lists={lists} onChange={e => setLabel(e.target.value)} />
      {isPlainObject(lists) && !isEmpty(lists) && (
        <>
          <p>{i18Next.t('components.lotslists.modal.m1')}</p>
          <p className="title">{i18Next.t('components.lotslists.create.m6')}</p>
          <ListSelect lists={lists} onChange={e => setSelectedIdlist(parseInt(e.target.value, 10))} />
        </>
      )}
      <div className="action-buttons">
        <button className="lots-lists-button" type="button" onClick={() => setVisibility(false)}>
          {i18Next.t('sales.search.filters.m2')}
        </button>
        <button disabled={selectedIdlist === 0 && label === ''} type="button" onClick={() => handleSubmit()} className={`lots-lists-button ${(selectedIdlist > 0 || label !== '') && 'primary'}`}>
          {selectedIdlist > 0 && <span>{i18Next.t('components.lotslists.create.m2')}</span>}
          {selectedIdlist === 0 && <span>{i18Next.t('components.lotslists.create.m3')}</span>}
        </button>
      </div>
    </div>
  );
};

export default Creation;
