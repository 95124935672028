import React from 'react';

const ErrorBlock = ({ error, style }) => {
  if (!error) return null;

  return (
    <div
      className="user-submit-error-block text-danger"
      style={{
        color: 'red',
        fontSize: 16,
        fontWeight: 600,
        paddingTop: 5,
        ...style,
      }}
    >
      {error.message}
    </div>
  );
};

export default ErrorBlock;
