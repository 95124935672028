import React from 'react';

import './stylesheet.scss';

const SmallSection = ({ title, value, isHtml = false, condition = true }) => {
  if (!title || !value || !condition) return null;
  return (
    <section className="small-section pad pad-t-5 pad-b-5">
      <strong>
        {title}
        {': '}
      </strong>
      {/* eslint-disable-next-line react/no-danger */}
      {isHtml ? <span className="inner-html" dangerouslySetInnerHTML={{ __html: value }} /> : <span>{value}</span>}
    </section>
  );
};

export default SmallSection;
