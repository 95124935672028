/* eslint-disable global-require */
module.exports = [
  ...require('./Adagp/i18n'),
  ...require('./Articles/i18n'),
  ...require('./Collaboration/i18n'),
  ...require('./Header/i18n'),
  ...require('./Indexes/i18n'),
  ...require('./Lots/i18n'),
  ...require('./Marketplace/i18n'),
  ...require('./Menu/i18n'),
  ...require('./Filters/i18n'),
  ...require('./Recommendations/i18n'),
  'artists.artist.m1',
  'artists.artist.m2',
  'artists.artist.m3',
  'artists.artist.m4',
  'artists.artist.m5',
  'artists.artist.m6',
];
