import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_CHANGE_DISPLAY_ORDER, ANALYTICS_FROM_ARTIST } from '../../../../../constants/rails';
import { updateArtistLotsSectionOrder } from '../../../../../redux/actions/preferences';
import { getArtistLotsSectionOrder } from '../../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { useViewport } from '../../../../../hooks/useViewport';
import { getArtist } from '../../../../../redux/slices/artist';
import Checkbox from '../../../../common/ui/Checkbox';
import DropDown from '../../../../common/DropDown';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';
import Icon from './Icon';

const Display = () => {
  const dispatch = useDispatch();
  const artist = useSelector(getArtist);
  const artistLotsSectionOrder = useSelector(getArtistLotsSectionOrder);
  const [internalState, setInternalState] = useState();
  const { viewportWidth } = useViewport();

  useEffect(() => {
    setInternalState(artistLotsSectionOrder);
  }, [artistLotsSectionOrder]);

  return (
    <DropDown
      className="filter artists-artist-filters-display"
      right
      render={({ close }) => (
        <div style={{ color: '#000', width: 410, padding: '15px 36px' }}>
          <strong>
            <A18n rsx="artists.artist.filters.display.m1" />
          </strong>
          <p style={{ marginTop: 15 }}>
            <A18n rsx="artists.artist.filters.display.m2" />
          </p>
          <div style={{ marginTop: 25 }}>
            <Checkbox
              style={{ marginBottom: 25 }}
              className="search-item-control-check square-check-box"
              square
              light
              label={<A18n rsx="artists.artist.filters.display.m4" />}
              checked={internalState === 1}
              onChange={() => setInternalState(1)}
            />
            <Checkbox className="search-item-control-check square-check-box" square light label={<A18n rsx="artists.artist.filters.display.m3" />} checked={internalState === 2} onChange={() => setInternalState(2)} />
          </div>

          <div className="actions">
            <button
              type="button"
              className="artp-btn artp-btn-artist cancel"
              onClick={() => {
                setInternalState(artistLotsSectionOrder);
                close();
              }}
            >
              <A18n rsx="artists.artist.filters.display.m5" />
            </button>
            <button
              type="button"
              className="artp-btn artp-btn-artist ok"
              onClick={() => {
                aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_CHANGE_DISPLAY_ORDER, idartist: artist.id });
                dispatch(updateArtistLotsSectionOrder({ artistLotsSectionOrder: internalState }));
                close();
              }}
            >
              <A18n rsx="artists.artist.filters.display.m6" />
            </button>
          </div>
        </div>
      )}
      title={
        viewportWidth > 768 ? (
          <>
            <A18n rsx="artists.artist.filters.display.m7" />
            <Icon />
          </>
        ) : (
          <Icon />
        )
      }
    />
  );
};

export default Display;
