import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { omit } from 'lodash';
import { CURRENCIES } from '../../../../constants/preferences';
import Map from '../../../common/graphs/Map';
import { useSearchResults } from '../../../../hooks/search/useSearchResults';
import { TOTAL_ACCESS_GEO } from '../../../../constants/search/scope';
import GraphSpinner from '../Spinner';

const MapGraph = ({ intl, params, currency, onClick }) => {
  const { loading, data = {} } = useSearchResults({ searchScope: TOTAL_ACCESS_GEO, params: omit(params, ['p', 'idcountry']), options: { cancelPrevious: true } });

  const getIdcountry = useCallback(
    geography => {
      const countryData = Object.entries(data).find(([_, { iso2 }]) => geography.properties.ISO_A2 === iso2);
      if (Array.isArray(countryData)) {
        return countryData[0];
      }
    },
    [data],
  );

  const getIso2 = useCallback(
    idCountry => {
      const countryData = Object.entries(data).find(([id]) => id === idCountry);
      if (Array.isArray(countryData)) {
        return countryData[1].iso2;
      }
    },
    [data],
  );

  const selectedCountries = useMemo(() => {
    if (params?.idcountry) {
      if (Array.isArray(params.idcountry)) {
        return params.idcountry.map(getIso2);
      }
      return [getIso2(params.idcountry)];
    }
    return [];
  }, [params, getIso2]);

  if (loading) {
    return <GraphSpinner />;
  }

  return (
    <Map
      data={Object.values(data)}
      formatter={(value, payload) => {
        const nbLot = payload?.count ?? 0;
        return (
          <>
            <div>{intl.formatNumber(value, { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
            <div>{nbLot} lots vendus</div>
          </>
        );
      }}
      onClick={(_, geography) => {
        const idcountry = getIdcountry(geography);
        if (idcountry) {
          onClick({
            idcountry,
          });
        }
      }}
      selectedCountries={selectedCountries}
    />
  );
};

export default injectIntl(MapGraph);
