import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';

const OneDayRefoundInfo = ({ close, visible, style = {} }) => {
  if (!visible) return null;

  return (
    <div className="subscription-purchase-common-one-day-refound-info" onClick={close} style={style}>
      <div className="details">
        <div className="close">
          <i className="fa fa-close" />
        </div>
        {i18next.t('subscriptions.purchase.common.one-day-refound-info.m1')}
      </div>
    </div>
  );
};

export default OneDayRefoundInfo;
