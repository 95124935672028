/* eslint-disable global-require */
module.exports = [
  'marketplace.searchbars.classifieds.bar.m13',
  'marketplace.searchbars.stores.bar.m5',
  'layouts.menu.m2',
  'marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m1',
  'marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m2',
  'marketplace.searchbars.common.professionaltypes.m2',
  'marketplace.searchbars.common.professionaltypes.m1',
  'marketplace.searchbars.common.storetypes.m1',
  'marketplace.searchbars.common.storetypes.m2',
  'marketplace.classifieds.searchbar.count.m1',
  'marketplace.classifieds.searchbar.count.m2',
  'marketplace.classifieds.searchbar.count.m3',
  'marketplace.classifieds.searchbar.count.m4',
  'marketplace.searchbars.classifieds.bar.m9',
  'marketplace.searchbars.common.artist.m1',
  'components.classifieds.searchbar.m1',
];
