import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

const SettingsArtists = () => (
  <div className="myartprice-lots-vertical-bar-content">
    <div className="shortcuts">
      <div className="header">{i18next.t('components.myartprice.summary.m1')} settings artistes </div>
      <div className="links marg marg-t-3 marg-b-3">
        <div className="shortcut">
          <Link to="/myartprice">
            <i className="fa fa-table" aria-hidden="true" />
            {i18next.t('components.myartprice.common.verticalbar.content.m2')}
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default SettingsArtists;
