/* eslint-disable react/no-danger */

import React from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ARTISTS_OFFICIAL_COUNT, AUCTIONEERS_OFFICIAL_COUNT, LOTS_OFFICIAL_COUNT, LOTS_OFFICIAL_YEAR_COUNT } from '../../../../../constants/rails';
import { getActiveSubscription } from '../../../../../redux/slices/userContext/selectors';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { STORE, SUBSCRIPTION } from '../../common/constants';

const Others = ({ intl }) => {
  const activeSubscription = useSelector(getActiveSubscription);
  const { filter } = useSelector(getSettings);
  const { newcomer } = useSelector(getSettings);

  const rsxForH1 = newcomer ? 'subscriptions.purchase.header.m12' : 'subscriptions.purchase.header.m2';
  const rsxForParagraph = newcomer ? 'subscriptions.purchase.header.m11' : 'subscriptions.purchase.header.m3';

  return (
    <div className={`header ${activeSubscription ? '' : 'no-subscription'}`}>
      <div className="container">
        {filter === STORE && (
          <>
            <h1 style={{ marginTop: 60 }}>{i18next.t('subscriptions.purchase.header.m7')}</h1>
            <p>{i18next.t('subscriptions.purchase.header.m8')}</p>
          </>
        )}

        {filter === SUBSCRIPTION && (
          <>
            <h1>{i18next.t(rsxForH1)}</h1>
            <p>
              {i18next
                .t(rsxForParagraph)
                .replace('%nbadj%', intl.formatNumber(LOTS_OFFICIAL_COUNT))
                .replace('%nbauctioneer%', intl.formatNumber(AUCTIONEERS_OFFICIAL_COUNT))
                .replace('%nbartist%', intl.formatNumber(ARTISTS_OFFICIAL_COUNT))
                .replace('%lots_year_count%', intl.formatNumber(LOTS_OFFICIAL_YEAR_COUNT))}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(Others);
