/* eslint-disable global-require */
module.exports = [
  ...require('./Lot/i18n'),
  ...require('./MoveButton/i18n'),
  ...require('./Pdf/i18n'),
  ...require('./RenameButton/i18n'),
  ...require('./CopyButton/i18n'),
  ...require('./DeleteButton/i18n'),
  'components.lotslists.list.item.m1',
  'components.lotslists.create.m8',
  'components.lotslists.create.m7',
  'components.lotslists.show.m8',
  'components.lotslists.selectall.m2',
];
