import React from 'react';
import Spinner from '../../../common/spinners/Spinner';

const GraphSpinner = () => (
  <div style={{ height: 400 }}>
    <Spinner />
  </div>
);

export default GraphSpinner;
