/* eslint-disable global-require */
module.exports = [
  'artists.artist.',
  'artists.artist.lots.past.m1',
  'artists.artist.lots.past.m2',
  'artists.artist.lots.past.m3',
  'artists.artist.lots.past.m4',
  'artists.artist.lots.past.m5',
  'artists.artist.lots.past.m6',
  'artists.artist.lots.past.m7',
  'artists.artist.lots.past.m8',
  'artists.artist.lots.past.m9',
  'artists.artist.lots.past.m10',
  'artists.artist.lots.past.m11',
  'artists.artist.lots.past.m12',
  'artists.artist.lots.past.m13',
  'artists.artist.lots.past.m14',
  'artists.artist.lots.past.m15',
  'artists.artist.lots.past.m16',
  'artists.artist.lots.past.m17',
  'artists.artist.lots.past.m18',
  'artists.artist.lots.past.m19',
  'artists.artist.lots.past.m20',
];
