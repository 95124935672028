import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get as getUiDictionary } from '../../../../redux/selectors/ui/dictionary';
import { set as setUiDictionary } from '../../../../redux/actions/ui/dictionary';
import { SHORTCUTS_LOTS_FOLLOW, VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../../constants/myartprice';
import { getIsFavoritedLot } from '../../../../redux/selectors/myartprice/datas';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import './stylesheet.scss';
import { cleanPathname } from '../../../../utils/http';

const NoAccess = ({ lot }) => {
  const dispatch = useDispatch();
  const isFavoritedLot = useSelector(state => getIsFavoritedLot(state, lot.id));
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  const urlParam = encodeURIComponent(`${cleanPathname(window.location.pathname)}${window.location.search}`);
  const urlTarget = `${lot.id}/${lot.crypto}?back=${urlParam}`;

  const handleClick = () => {
    dispatch(setUiDictionary('returnToIdlot', lot.id));
  };

  return (
    <div id={`lot-${lot.id}`} className="myartprice-lots-tile-no-access">
      <Link to={urlTarget} onClick={() => handleClick()} id={`lot-${lot.id}`}>
        <div id={`lot-${lot.id}`} className={`${SHORTCUTS_LOTS_FOLLOW.includes(currentElement) && !isFavoritedLot ? 'unfollowed' : ''}`}>
          <div className="lot-img" style={{ cursor: 'cursor' }}>
            <div className="no-pict">
              <img alt="no access" src={`${IMAGE_SOURCE}no-access/cadenas-gris.svg`} />
              <p>{i18next.t('lot.show.no_access.m9')}</p>
            </div>
          </div>

          {lot.isPast && <div className="lot-title">{lot.title}</div>}

          <div className="lot-artist">{lot.artistOrAssociation}</div>

          <div className="lot-category">{lot.category}</div>

          <div className="lot-auctioneer">
            {lot.saleCountry}
            <br />
            <span style={{ marginLeft: 5 }}>
              {lot.saledatestatus !== 3 && lot.saledatestatus !== 2 && (
                <>
                  {lot.saleDtStart} {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
                </>
              )}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NoAccess;
