import { formatFns } from '../../../utils/dates/format';

export const getYear = date => formatFns(date, 'yyyy');
export const getMonth = date => formatFns(date, 'LLL');
export const getDay = date => formatFns(date, 'dd');

export const mergeTwoDatesToString = (date1, date2) => {
  if (!date1 || !date2) return null;

  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  const date1Year = getYear(dt1);
  const date2Year = getYear(dt2);
  const date1Month = getMonth(dt1);
  const date2Month = getMonth(dt2);
  const date1Day = getDay(dt1);
  const date2Day = getDay(dt2);

  if (date1Year === date2Year) {
    if (date1Month === date2Month) {
      if (date1Day === date2Day) return `${date1Day} ${date1Month} ${date1Year}`;
      if (date1Day < date2Day) return `${date1Day}-${date2Day} ${date1Month} ${date1Year}`;
      return `${date2Day}-${date1Day} ${date1Month} ${date1Year}`;
    }
    if (date1 < date2) return `${date1Day} ${date1Month} - ${date2Day} ${date2Month} ${date1Year}`;
    return `${date2Day} ${date2Month} - ${date1Day} ${date1Month} ${date1Year}`;
  }
  if (date1Year < date2Year) return `${date1Day} ${date1Month} ${date1Year} - ${date2Day} ${date2Month} ${date2Year}`;
  return `${date2Day} ${date2Month} ${date2Year} - ${date1Day} ${date1Month} ${date1Year}`;
};
