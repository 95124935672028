import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React from 'react';
import { getAutomaticallyApplied, getBannerUrlSml, getCode } from '../../../../../redux/slices/promotion';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE, MODULE_STORE } from '../../../../../constants/rails';
import '../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../common/A18n';
import Drawer from '../../../../common/Drawer';
import FreeDemo from './FreeDemo';
import Future from './Future';
import Past from './Past';
import './stylesheet.scss';

const NoAccessDrawer = ({ drawerId, section, lotCount }) => {
  const artpriceBanners = useSelector(getBannerUrlSml);
  const promocodecode = useSelector(getCode);
  const automaticallyApplied = useSelector(getAutomaticallyApplied);
  const logged = useSelector(isLogged);
  const hasStoreAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_STORE }));

  return (
    <Drawer
      id={drawerId}
      className="artists-artist-lots-no-access-drawer"
      side="right"
      render={() => (
        <>
          <p>
            <img alt="drawer-lots" src={`${IMAGE_SOURCE}artist/drawer-lots.png`} />
          </p>

          {section === 'past' && <Past lotCount={lotCount} />}
          {section === 'future' && <Future />}

          {artpriceBanners && (
            <p className="marg marg-t-30">
              {/* eslint-disable-next-line react/no-danger */}
              <a href={`/subscription${promocodecode && !automaticallyApplied ? `?promocode=${promocodecode}` : ''}`}>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
              </a>
            </p>
          )}

          <p className="links">
            <a className="artp-btn artp-btn-purple sln-link" href={`/subscription${hasStoreAccess ? '/store/upgrade' : ''}${promocodecode && !automaticallyApplied ? `?promocode=${promocodecode}` : ''}`}>
              <A18n rsx="artists.artist.lots.noaccessdrawer.m18" />
            </a>
            {!logged && (
              <a className="artp-btn" href="/identity/sales" id="sln_login_lots">
                <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
              </a>
            )}
          </p>

          <FreeDemo />
        </>
      )}
    />
  );
};

export default injectIntl(NoAccessDrawer);
