import { useParams } from 'react-router';
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { noop } from 'lodash';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import HtmlEditor from '../../../common/HtmlEditor';
import { set } from '../../../../redux/actions/ui/dictionary';
import { ACTIVE_MENU, NEW } from '../../../../constants/account/messages';
import { fetchMessagetypes } from '../../../../redux/actions/dbEntities/messagetypes';
import { getAllMessagetype } from '../../../../redux/selectors/dbEntities';
import { create } from '../../../../redux/actions/account/messages';
import { fetchMessage } from '../../../../redux/actions/dbEntities/messages';
import { queryStringToObject } from '../../../../utils/http';

const New = ({ defaultValues = {} }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { subject, idmessagetype } = queryStringToObject(window.location.search);
  const formDefaultValues = defaultValues;
  if (idmessagetype) {
    formDefaultValues.idmessagetype = idmessagetype;
  }
  if (subject) {
    formDefaultValues.subject = subject;
  }

  const { register, handleSubmit, control } = useForm({
    defaultValues: formDefaultValues,
  });
  const messagetypes = useSelector(getAllMessagetype);

  const onSubmit = data => {
    dispatch(create({ message: data }));
    window.history.back();
  };

  useEffect(() => {
    dispatch(fetchMessagetypes());
    dispatch(set(ACTIVE_MENU, NEW));
    if (id) {
      dispatch(fetchMessage({ id: [id] }));
    }
  }, []);

  return (
    <div className="account-messages">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" className="form-control" {...register('idparent')} />
        {defaultValues.idmessagetype && <input type="hidden" className="form-control" {...register('idmessagetype')} />}
        {!defaultValues.idmessagetype && (
          <div className="form-group">
            <label htmlFor="idmessagetype">{i18next.t('account.messages.new.m1')}</label>
            {messagetypes && (
              <Controller
                as={<Select options={Object.values(messagetypes).map(m => ({ value: m.id, label: m.label }))} />}
                name="idmessagetype"
                control={control}
                onChange={([selected]) => ({
                  value: selected,
                })}
              />
            )}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="subject">{i18next.t('account.messages.new.m2')} *</label>
          <input className="form-control" {...register('subject')} required />
        </div>
        <div className="form-group">
          <label htmlFor="message">{i18next.t('account.messages.new.m3')}</label>
          <Controller render={({ field }) => <HtmlEditor onChange={noop} {...field} />} name="message" control={control} />
        </div>
        <button type="submit" className="btn btn-primary">
          <i className="fa fa-send-o" /> {i18next.t('account.messages.new.m4')}
        </button>
      </form>
    </div>
  );
};

export default New;
