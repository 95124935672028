/* eslint-disable global-require */
module.exports = [
  'ails.info.m1',
  'ails.info.m3',
  'ails.info.m4',
  'ails.info.m5',
  'ails.info.m6',
  'ails.info.m7',
  'ails.info.m8',
  'ails.info.m9',
  'ails.info.m10',
  'ails.info.m11',
  'ails.info.m12',
  'ails.info.m13',
  'ails.info.m14',
  'ails.info.m15',
  'ails.info.m16',
  'ails.info.m17',
  'ails.info.m18',
  'ails.info.m19',
  'ails.info.m20',
  'ails.info.m21',
  'ails.info.m23',
  'ails.info.m25',
  'ails.info.m26',
  'ails.info.m27',
  'ails.info.m28',
  'ails.info.m29',
  'ails.info.m30',
  'ails.info.m31',
  'ails.info.m32',
  'ails.info.m33',
  'ails.info.m34',
  'ails.info.m35',
  'ails.info.m38',
  'ails.info.m41',
  'ails.info.m44',
  'ails.info.m45',
  'ails.info.m46',
  'ails.info.m47',
  'ails.info.m48',
  'ails.info.m49',
  'ails.info.m50',
  'ails.info.m51',
  'ails.info.m52',
  'ails.info.m53',
  'ails.info.m54',
  'ails.info.m55',
  'ails.info.m56',
  'ails.info.m57',
  'ails.info.m58',
  'ails.info.m59',
  'ails.info.m60',
  'ails.info.m61',
  'ails.info.m62',
  'ails.info.m63',
  'ails.info.m65',
  'ails.info.m66',
  'ails.info.m67',
  'ails.info.m68',
  'ails.info.m69',
  'ails.info.m70',
  'ails.info.m71',
  'ails.info.m72',
  'ails.info.m73',
  'ails.info.m74',
  'ails.info.m75',
  'ails.info.m76',
  'ails.info.m77',
  'ails.info.m78',
];
