module.exports = [
  'marketplace.auction._dates.html.m2',
  'marketplace.auction._dates.html.m3',
  'marketplace.auction._dates.html.m4',
  'marketplace.auction._dates.html.m5',
  'marketplace.auction._countdown.html.m1',
  'marketplace.auction._countdown.html.m2',
  'marketplace.auction._countdown.html.m3',
  'marketplace.auction._countdown.html.m4',
  'marketplace.auction._countdown.html.m5',
  'marketplace.auction._countdown.html.m6',
  'marketplace.auction._countdown.html.m7',
  'marketplace.auction._countdown.html.m8',
];
