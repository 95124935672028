/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.warnings.purchasenotavailable.m1',
  'subscriptions.list.warnings.purchasenotavailable.m2',
  'subscriptions.list.warnings.purchasenotavailable.m3',
  'subscriptions.list.warnings.purchasenotavailable.m4',
  'subscriptions.list.warnings.purchasenotavailable.m5',
  'subscriptions.list.warnings.purchasenotavailable.m6',
  'subscriptions.list.warnings.purchasenotavailable.m7',
  'subscriptions.list.warnings.purchasenotavailable.m8',
  'subscriptions.list.warnings.purchasenotavailable.m9',
  ...require('./Warning/i18n'),
];
