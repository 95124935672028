import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../../../redux/slices/artist';
import A18n from '../../../../../common/A18n';
import Price from '../common/Price';
import './stylesheet.scss';

const Future = () => {
  const artist = useSelector(getArtist);

  return (
    <div className="artists-artist-lots-no-access-drawer-future">
      <h3>
        <A18n rsx="artists.artist.lots.noaccessdrawer.m8" replace={[['%artist%', artist.name]]} />
      </h3>
      <div className="details">
        <p className="args">
          <A18n rsx="artists.artist.lots.noaccessdrawer.m19" trustHtml />
        </p>
        <Price />
      </div>
    </div>
  );
};
export default Future;
