import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';
import { useSelector } from 'react-redux';
import { get } from '../../../../../redux/selectors/ui/dictionary';
import { ACTIVE_MENU, TRASH } from '../../../../../constants/account/messages';

const TopBar = ({ onReply, onDelete }) => {
  const activeMenu = useSelector(state => get(state, { id: ACTIVE_MENU }));

  return (
    <div className="account-top-bar">
      <div className="actions">
        <div className="left">
          <button className="btn btn-default" type="button" onClick={() => window.history.back()}>
            <i className="fa fa-caret-left" /> {i18next.t('account.messages.message.topbar.m1')}
          </button>
        </div>
        {activeMenu !== TRASH && (
          <div className="right">
            {onReply && (
              <button type="button" className="btn btn-default" onClick={onReply}>
                <i className="fa fa-reply" /> {i18next.t('account.messages.message.topbar.m2')}
              </button>
            )}
            {onDelete && (
              <button type="button" className="btn btn-default" onClick={onDelete}>
                <i className="fa fa-trash" /> {i18next.t('account.messages.message.topbar.m3')}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
