import React from 'react';
import i18next from 'i18next';
import Drawer from '../../../../../common/Drawer';
import './stylesheet.scss';
import A18n from '../../../../../common/A18n';
import { IMAGE_SOURCE, RETURN_AFTER_LOGIN_COOKIE_KEY, SOURCE_ON_REGISTRATION_COOKIE_KEY } from '../../../../../../constants/rails';
import { set as setCookie } from '../../../../../../utils/cookie';

const identity = () => {
  setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, window.location.href);
  setCookie(SOURCE_ON_REGISTRATION_COOKIE_KEY, 'reports');
  window.location = '/identity';
};

const registrationFree = () => {
  setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, window.location.href);
  setCookie(SOURCE_ON_REGISTRATION_COOKIE_KEY, 'reports');
  window.location = '/account/registration-free';
};

const NoAccessDrawer = ({ drawerId, onClose }) => (
  <Drawer
    id={drawerId}
    side="right"
    className="report-reports-noaccessdrawer-container"
    render={() => (
      <>
        <div className="close" onClick={onClose}>
          <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
        </div>
        <div className="report-reports-noaccessdrawer-content">
          <div>
            <p>
              <img alt="drawer-lots" src={`${IMAGE_SOURCE}reports/drawer-no-access-white.svg`} style={{ width: '119px' }} />
            </p>
            <h3>
              <A18n rsx="artmarketinsight.reports.m10" />
            </h3>
            <p>
              <span dangerouslySetInnerHTML={{ __html: i18next.t('artmarketinsight.reports.m11') }} />
            </p>
            <p>
              <a onClick={registrationFree} className="btn btn-default link sln-link report-reports-noaccessdrawer-registration">
                <A18n rsx="artmarketinsight.reports.m12" />
              </a>
              <a onClick={identity} className="btn btn-default link sln-link report-reports-noaccessdrawer-connect">
                <A18n rsx="artmarketinsight.reports.m15" />
              </a>
            </p>
          </div>
        </div>
      </>
    )}
  />
);

export default NoAccessDrawer;
