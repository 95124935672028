import React from 'react';
import { Outlet } from 'react-router-dom';
import TopMenu from '../TopMenu';
import Account from './Account';
import Identity from './Identity';
import ReduxProvider from '../../providers/ReduxProvider';
import RouterProvider from '../../providers/RouterProvider';
import Explain from './Explain';

const ConfirmRouter = ({ initValues }) => (
  <ReduxProvider>
    <RouterProvider
      routes={[
        {
          path: '/account/confirm',
          element: (
            <>
              <TopMenu activeTab={5} />
              <Outlet />
            </>
          ),
          children: [
            {
              path: 'identity',
              element: <Identity initValues={initValues} />,
            },
            {
              path: 'explain',
              element: (
                <>
                  <Explain />
                  <Account />
                </>
              ),
            },
            {
              path: '',
              element: <Account />,
            },
          ],
        },
      ]}
    />
  </ReduxProvider>
);

export default ConfirmRouter;
