/* eslint-disable global-require */
module.exports = [
  ...require('./OneDay/i18n'),
  'subscriptions.purchase.list.theme.physicproduct.m5',
  'subscriptions.purchase.list.theme.physicproduct.m6',
  'subscriptions.purchase.list.theme.physicproduct.m7',
  'subscriptions.purchase.list.theme.physicproduct.m8',
  'subscriptions.purchase.list.theme.physicproduct.m1',
  'subscriptions.purchase.list.theme.physicproduct.m2',
  'subscriptions.purchase.list.theme.physicproduct.m3',
  'subscriptions.purchase.list.theme.physicproduct.m4',
  'subscriptions.purchase.list.theme.physicproduct.m9',
  'subscriptions.purchase.list.theme.physicproduct.m10',
];
