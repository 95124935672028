import React, { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { areCurrentResultsLoading, getCurrentResultsData } from '../../../redux/selectors/search/results';
import VerticalBar from '../common/VerticalBar';
import Classified from '../common/Classified';
import { usePaginatedSearch } from '../../../hooks/usePaginatedSearch';
import { MYARTPRICE_LOTS, MYARTPRICE_LOTS_FOLLOW, MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, MYARTPRICE_LOTS_MARKETPLACE } from '../../../constants/search/scope';
import { SHORTCUT_LOTS_FOLLOW_MARKETPLACE, SHORTCUT_LOTS_MARKETPLACE } from '../../../constants/myartprice';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { getCurrentCount, getCurrentInfiniteScroll } from '../../../redux/selectors/search/info';
import { getFreshness, getItemsPerpage } from '../../../redux/selectors/preferences';
import { isInViewport, scrollToElement } from '../../../utils/ui';
import '../common/styles/myartpriceBar.scss';
import '../common/styles/myartprice.scss';
import './stylesheet.scss';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import Filters from '../../search/bars/Lot/Filters';
import { getCurrentDraftParams, getCurrentScope, getCurrentUrlParams, isCurrentDraftDirty } from '../../../redux/selectors/search';
import { cleanPathname, objectToQueryString } from '../../../utils/http';
import { applyCurrentDraft } from '../../../redux/actions/search';
import { resetCurrentDraftParams } from '../../../redux/actions/search/draftParams';
import NoResults from '../common/NoResults';
import Drawer from '../../search/drawers/Marketplace';
import SelectedParams from '../../search/common/SelectedParams';
import BackToTopButton from '../../common/BackToTopButton';
import { style as horizontalBarStyle } from '../common/styles/horizontalBar';
import { getStyle } from '../../../utils/styles/horizontalBar';
import LotHorizontalBar from '../../search/bars/Lot';
import OpenDrawer from '../common/VerticalBar/OpenDrawer';
import MainLayout from '../common/MainLayout';
import Spinner from '../../common/spinners/Spinner';
import Pagination from '../../common/Pagination';

const Marketplace = ({ searchScope }) => {
  const data = useSelector(getCurrentResultsData) || {};
  const loading = useSelector(areCurrentResultsLoading) || false;
  const perPage = useSelector(getItemsPerpage);
  const totalCount = useSelector(getCurrentCount);
  const freshness = useSelector(getFreshness);

  const drawerId = 'myartprice-lots-filter-advanced';

  const refTitle = useRef();
  const { page, setPage, fetch } = usePaginatedSearch({ scope: searchScope, options: { cancelPrevious: true } });

  useDidUpdateEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      fetch({ updateFacets: true, updateDefaultFacets: true });
    }
  }, [freshness]);

  let activeShortcut;
  let rsxNoData;
  if (searchScope === MYARTPRICE_LOTS_MARKETPLACE) {
    activeShortcut = SHORTCUT_LOTS_MARKETPLACE;
    rsxNoData = 'component.myartprice.marketplace.m7';
  } else if (searchScope === MYARTPRICE_LOTS_FOLLOW_MARKETPLACE) {
    activeShortcut = SHORTCUT_LOTS_FOLLOW_MARKETPLACE;
    rsxNoData = 'component.myartprice.marketplace.m6';
  }
  useInitializeActiveShorcut({ activeShortcut });

  const handlePaginationClick = p => {
    setPage(p);
    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  const dispatch = useDispatch();
  const currentScope = useSelector(getCurrentScope);
  const isDraftDirty = useSelector(isCurrentDraftDirty);
  const params = useSelector(getCurrentDraftParams);
  const initialParams = useSelector(getCurrentUrlParams);
  const infiniteScroll = useSelector(getCurrentInfiniteScroll);
  const redirect = false;
  const ipp = useSelector(getItemsPerpage);

  const validateParams = force => {
    if (force || isDraftDirty) {
      if (redirect && !infiniteScroll) {
        window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...params, p: 1 })}`;
      } else {
        dispatch(applyCurrentDraft({ p: 1, ipp, force }));
      }
    }
  };

  const resetIfNecessary = () => {
    if (!isEqual(initialParams, params)) {
      dispatch(resetCurrentDraftParams());
    }
  };

  // Breakcrumb
  const mapScopeToTitleRsx = () => {
    if (MYARTPRICE_LOTS.includes(searchScope)) return 'layouts.header.m16';
    if (MYARTPRICE_LOTS_FOLLOW.includes(searchScope)) return 'layouts.header.m43';
  };

  useEffect(() => {
    const titleRsx = mapScopeToTitleRsx();
    const breadcrumb = document.querySelector('.breadcrumb');
    breadcrumb.classList.add('hidden-xs', 'hidden-sm');
    breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
<li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
<li>${i18next.t(titleRsx)}</li> <li>${i18next.t('components.myartprice.common.verticalbar.content.m5')}</li>`;
  }, [searchScope]);

  const horizontalBarChildren = () => (
    <div style={getStyle({ backgroundColor: horizontalBarStyle.backgroundColor, borderColor: horizontalBarStyle.borderColor, paddingBottom: 5, paddingTop: 5, borderTop: 'solid 1px #fff' })}>
      <div className="container">
        <div className="filters-container">
          <LotHorizontalBar
            currentScope={currentScope}
            infiniteScroll={infiniteScroll}
            redirect={redirect}
            hiddenFacets={['idartist', 'idcategory']}
            className={horizontalBarStyle.className}
            backgroundColor={horizontalBarStyle.backgroundColor}
            borderColor={horizontalBarStyle.borderColor}
            hideTotalCount
            resetIfNecessary={resetIfNecessary}
            validateParams={validateParams}
            otherFiltersPosition="first"
            otherFilters={<OpenDrawer carret={false} title={<i className="fa fa-bars" aria-hidden="true" />} className="marg marg-r-15 visible-sm visible-xs title" />}
          />
        </div>
      </div>
    </div>
  );

  return (
    <MainLayout horizontalBarChildren={horizontalBarChildren()} ref={refTitle}>
      <VerticalBar />
      <Drawer id={drawerId} searchInStore searchInSellerClassified resetIfNecessary={resetIfNecessary} validateParams={validateParams} />
      <div className="col-container">
        <div className="hidden-sm hidden-xs filters" style={{ marginTop: '-15px' }}>
          <Filters currentScope={currentScope} drawerId={drawerId} resetIfNecessary={resetIfNecessary} validateParams={validateParams} redirect={redirect} />
          <SelectedParams redirect={redirect} hiddenFacets={['idartist']} />
        </div>
        <div className="data-container">
          {data.idclassified && (
            <>
              {data.idclassified.map((idclassified, idx) => (
                <Classified position={idx + 1 + (page - 1) * perPage} key={`marketplace-${idclassified}`} linkToArtist={false} id={idclassified} displayPrice displayTitle={false} />
              ))}
            </>
          )}
        </div>

        {(loading && <Spinner />) || (
          <>
            {/* display a 'no results' message when results are empty */}
            {(!data.idclassified || data.idclassified.length === 0) && <NoResults messageRsxKey={rsxNoData} linkRsxKey="component.myartprice.marketplace.m3" href="/marketplace" />}

            {/* Pagination */}
            {totalCount / perPage > 1 && totalCount > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />
              </div>
            )}

            {/* Go back to top */}
            <div style={{ position: 'sticky', bottom: '25px', float: 'right', transform: 'translateX(10px)' }}>
              <BackToTopButton top={refTitle.current} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Marketplace;
