import { useDispatch } from 'react-redux';
import React from 'react';
import { on as openDrawer } from '../../../../../../redux/actions/ui/switches';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const AdvancedSearchButton = ({ drawerId }) => {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      onClick={() => {
        dispatch(openDrawer(drawerId));
      }}
      className="marketplace-stores-list-search-bar-advanced-search-button"
    >
      <A18n rsx="marketplace.searchbars.classifieds.bar.m1" />
      <i className="fa fa-search" />
    </button>
  );
};

export default AdvancedSearchButton;
