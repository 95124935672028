import React from 'react';
import { useSelector } from 'react-redux';
import { getReturnAfterLogin } from '../../../../redux/slices/artprice';
import A18n from '../../../common/A18n';

const Background = () => <div style={{ position: 'fixed', height: '100vh', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, zIndex: 1 }} />;

const PurchaseNotAvailable = () => {
  const returnAfterLogin = useSelector(getReturnAfterLogin);

  const handleBackToList = () => {
    window.location = returnAfterLogin || '/home';
  };

  return (
    <>
      <Background />
      <div className="subscriptions-list-warnings-warning">
        <h1>
          <A18n rsx="estimates.info.purchasenotavailable.m1" />
        </h1>

        <p>
          <A18n rsx="estimates.info.purchasenotavailable.m2" />
        </p>

        <div className="actions">
          <button type="button" className="active" onClick={handleBackToList}>
            <A18n rsx="estimates.info.purchasenotavailable.m3" />
          </button>
        </div>
      </div>
    </>
  );
};

export default PurchaseNotAvailable;
