import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, ANALYTICS_FROM_ARTIST } from '../../../../../../constants/rails';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';
import { getAilsSeen, getArtist, updateAilsSeen } from '../../../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import Observer from '../../../../../common/Observer';
import { ailIsMultiDates } from '../../../../../../utils/ail';

const Ail = ({ ail }) => {
  const artist = useSelector(getArtist);
  const dispatch = useDispatch();
  const ailsSeen = useSelector(getAilsSeen);

  const handleIntersect = useCallback(
    ({ entries }) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !ailsSeen.includes(ail.id)) {
          aapiBeacon('ail_view', { from: ANALYTICS_FROM_ARTIST, idail: ail.id, idartist: artist.id });
          dispatch(updateAilsSeen([ail.id]));
        }
      });
    },
    [ail.id],
  );

  return (
    <div className="lot ail">
      <Observer selectors={[{ selector: `#ail${ail.id}`, payload: ail.id }]} handleIntersect={handleIntersect} />
      <a href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_ARTIST}&s=${ANALYTICS_SECTION_ARTIST_FUTURE_LOTS}`} id={`ail${ail.id}`}>
        <div className="block-title">
          <A18n rsx="artists.artist.lots.future.ail.m1" />
        </div>

        <div className="tag">
          <div className={classNames('block-date-ail', { 'two-part': ailIsMultiDates(ail) })}>
            <div className="subblock-date-ail">
              <span className="day">{ail.dt_start_part1}</span>
              <span className="other">{ail.dt_start_part2}</span>
            </div>
            {ailIsMultiDates(ail) && (
              <div className="subblock-date-ail">
                <div className="separator" />
                <div className="day">{ail.dt_end_part1}</div>
                <div className="other">{ail.dt_end_part2}</div>
              </div>
            )}
          </div>
          <div className="country">
            {ail.city} ({ail.country})
          </div>
        </div>

        <div className="data">
          <div className="info">
            <p className="title">{ail.title}</p>
            <p className="auctioneer">{ail.auctioneer}</p>
            <p className="details">
              {ail.category}
              <br /> {ail.technique}
            </p>
          </div>
          <div className="img">
            <img src={ail.images.square} alt={ail.title} />
          </div>
        </div>
      </a>
    </div>
  );
};

export default Ail;
