import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Dates from '../../../inputs/Dates';

const SaleDate = () => (
  <Item label={i18next.t('filters.dates.m1')} name={{ dt_from: 'from', dt_to: 'to' }}>
    <Dates />
  </Item>
);

export default SaleDate;
