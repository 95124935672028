import React, { useLayoutEffect, useState, memo } from 'react';
import Observer from '../../../common/Observer';

const intersections = [];

const handleIntersect = ({ entries }) => {
  const promotions = [];
  entries.forEach(entry => {
    if (entry.isIntersecting && !intersections.includes(entry.target.id)) {
      intersections.push(entry.target.id);
      promotions.push({
        ...JSON.parse(entry.target.dataset.gtm),
      });
    }
  });

  if (promotions.length > 0) {
    window.dataLayer.push({
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions,
        },
      },
    });
  }
};

const selectElements = selector => document.querySelectorAll(selector);

const GtmGenericIntersectionObserver = memo(({ selector }) => {
  const [observe, setObserve] = useState(false);

  useLayoutEffect(() => {
    const intervalID = setInterval(() => {
      if (selectElements(selector).length > 0) {
        setObserve(true);
        clearInterval(intervalID);
      }
    }, 200);

    return () => clearInterval(intervalID);
  }, []);

  if (observe) {
    const nodes = selectElements(selector);
    const selectors = [];

    nodes.forEach(node => {
      selectors.push({ selector: `#${node.id}`, payload: { name: node.dataset.name } });
    });

    return <Observer selectors={selectors} handleIntersect={handleIntersect} />;
  }

  return null;
});

export default GtmGenericIntersectionObserver;
