import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentUrlParams } from '../../../../redux/selectors/search';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { DEFAULT_CURRENCIES } from '../../../../constants/preferences';
import customStyles from '../../common/reactSelect/style';
import './stylesheet.scss';
import '../../common/reactSelect/stylesheet.scss';

const currencies = Object.values(DEFAULT_CURRENCIES).map(({ id, iso1 }) => ({ value: `${id}`, label: iso1 }));

/*
FIXME
Pour les lots on cherche avec price_min and price_max
Pour les classifieds on utilise price_from et price_to
Ce component est un quick-fix avant l'uniformisations des params de prix
 */
const PricesClassified = () => {
  const dispatch = useDispatch();
  const defaultCurrency = useSelector(getCurrency);
  const urlParams = useSelector(getCurrentUrlParams);

  let currentCurrency = currencies.filter(c => c.value === urlParams.price_idcurrency);
  if (!currentCurrency[0]) {
    currentCurrency = Object.values(DEFAULT_CURRENCIES)
      .filter(c => c.iso3 === defaultCurrency)
      .map(({ id, iso1 }) => ({ value: `${id}`, label: iso1 }));
  }
  currentCurrency = currentCurrency[0];

  const handleUpdateParams = (triggeredParam, value) => {
    const triggeredParams = [{ triggeredParam, value }];

    if ((triggeredParam === 'price_min' || triggeredParam === 'price_to') && !urlParams.price_idcurrency) {
      triggeredParams.push({ triggeredParam: 'price_idcurrency', value: currentCurrency.value });
    }

    dispatch(updateCurrentDraft(triggeredParams));
  };

  return (
    <div className="common-search-filters-prices artp-input-group search-common-react-select">
      <div className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.m9')}</div>
      <div className="search-bar-input">
        <div className="cell first" style={{ width: '35%' }}>
          <input
            type="number"
            className="form-control"
            defaultValue={urlParams.price_min}
            key={`price-min-${urlParams.price_min}`}
            onBlur={e => e.target.value && handleUpdateParams('price_min', e.target.value)}
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
          />
        </div>
        <div className="cell" style={{ width: '35%' }}>
          <input
            type="number"
            className="form-control"
            defaultValue={urlParams.price_max}
            key={`price-max-${urlParams.price_max}`}
            onBlur={e => e.target.value && handleUpdateParams('price_max', e.target.value)}
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
          />
        </div>
        <div className="cell" style={{ width: '30%' }}>
          <Select
            components={makeAnimated()}
            isSearchable={false}
            styles={customStyles(0)}
            options={currencies}
            className="search-common-react-select"
            classNamePrefix="react-select"
            defaultValue={currentCurrency}
            onChange={selectedOption => handleUpdateParams('price_idcurrency', selectedOption.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default PricesClassified;
