import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArtist } from '../../../../../../redux/selectors/dbEntities';
import { ANALYTICS_CLICK_OPERATION_TO_DEMO, ANALYTICS_FROM_HOMEPAGE, ID_FREE_ARTIST } from '../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';
import { fetchFreeArtist } from '../../../../../../redux/actions/dbEntities/artist';

const FreeDemo = () => {
  const dispatch = useDispatch();
  const freeArtist = useSelector(state => getArtist(state, { id: ID_FREE_ARTIST }));

  useEffect(() => {
    if (!freeArtist) {
      dispatch(fetchFreeArtist());
    }
  }, [freeArtist]);

  return (
    <div className="artists-artist-lots-no-access-drawer-free-demo">
      <div className="l1">
        <A18n rsx="homepage.advantages.m3" />
      </div>
      <div className="l2">{freeArtist?.name}</div>
      <div className="l3">
        <a href={freeArtist?.url} className="btn btn-animated" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_DEMO })}>
          <A18n rsx="homepage.advantages.m4" />
        </a>
      </div>
    </div>
  );
};

export default FreeDemo;
