import i18next from 'i18next';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentArtistDataAction, stockIndexDataAction, categoriesDataAction, comparedArtistDataAction, updatePriceIndexOptions } from '../../../redux/actions/indexes/priceIndex';
import { getIdartist, getArtistOptions, getArtistName, hasIndexesSubscription } from '../../../redux/selectors/indexes/artist';
import { getIndexedCategories, shouldDisplayComparedArtist } from '../../../redux/selectors/indexes/priceIndex';
import { artistOptionsAction } from '../../../redux/actions/indexes/artist';
import { artistSelectService } from '../../../services/indexes/priceIndex';
import { getCurrency } from '../../../redux/selectors/preferences';
import SubscriptionNeeded from '../SubscriptionNeeded';
import PriceIndexLineChart from './LineChart';
import './stylesheet.scss';
import A18n from '../../common/A18n';
import Filters from '../common/Filters';
import Copyright from '../common/Copyright';

const stockIndexes = [
  { value: 'globalIndex', label: 'Artprice Global Index' },
  { value: 'sp500', label: 'S&P 500' },
  { value: 'cac40', label: 'CAC 40' },
  { value: 'dax', label: 'DAX' },
];

const PriceIndex = ({ displayHeader = true, displayArtistName }) => {
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);
  const indexedCategories = useSelector(getIndexedCategories);
  const artistOptions = useSelector(getArtistOptions);
  const artistName = useSelector(getArtistName);
  const hasSubscription = useSelector(hasIndexesSubscription);
  const displayComparedArtist = useSelector(shouldDisplayComparedArtist);
  const [years, setYears] = useState([]);

  const updateOptions = (options = {}) => {
    dispatch(updatePriceIndexOptions({ options }));
    dispatch(currentArtistDataAction());
    dispatch(stockIndexDataAction());
    dispatch(categoriesDataAction());
    dispatch(comparedArtistDataAction());
  };

  useEffect(() => {
    dispatch(artistOptionsAction());
    dispatch(currentArtistDataAction());
    dispatch(stockIndexDataAction());
    dispatch(categoriesDataAction());
  }, [currency, idartist]);

  useEffect(() => {
    const arrayOfYears = [];
    if (artistOptions?.minYearIndex && artistOptions?.maxYearVisible) {
      for (let i = parseInt(artistOptions?.minYearIndex, 10) + 1; i < artistOptions?.maxYearVisible; i += 1) {
        arrayOfYears.push({ value: i, label: i });
      }
    }

    setYears(arrayOfYears);
  }, [artistOptions?.minYearIndex, artistOptions?.maxYearVisible]);

  return (
    <>
      <div className="price-index-chart" id="indexes">
        {displayHeader && (
          <h2>
            <A18n rsx="indexes.summary.m4" />
          </h2>
        )}

        <div style={{ position: 'relative' }}>
          <SubscriptionNeeded section={i18next.t('artprice.lib.result.indexresult.m7')} othersDisplayConditions={[displayComparedArtist]} />

          <div className="tab-content" style={{ borderTopColor: '#ddd' }}>
            {displayArtistName && artistName && (
              <div className="row">
                <h4 className="pull-left">{artistName}</h4>
              </div>
            )}

            <PriceIndexLineChart />
            <Filters>
              {years.length > 0 && (
                <div className="filter">
                  <ReactSelect
                    classNamePrefix="react-select"
                    className="price-index-select"
                    options={years}
                    defaultValue={years[0]}
                    formatOptionLabel={({ value }) => <span> {`Base 100 en ${value}`} </span>}
                    onChange={e => updateOptions({ minYear: e ? e.value : undefined })}
                  />
                </div>
              )}
              {indexedCategories && indexedCategories.length > 0 && (
                <div className="filter">
                  <ReactSelect
                    isClearable
                    classNamePrefix="react-select"
                    className="price-index-select"
                    options={indexedCategories}
                    placeholder={i18next.t('indexes.categories')}
                    onChange={e => updateOptions({ idcategory: e ? e.id : undefined })}
                  />
                </div>
              )}

              <div className="filter">
                <AsyncSelect
                  isDisabled={!hasSubscription}
                  isClearable
                  cacheOptions
                  placeholder={i18next.t('indexes.priceindex.m2')}
                  noOptionsMessage={({ inputValue }) => {
                    if (inputValue.length > 0) {
                      return i18next.t('indexes.no_artist_found');
                    }
                    return i18next.t('indexes.looking_for');
                  }}
                  loadingMessage={() => i18next.t('indexes.loading_artist')}
                  loadOptions={async term => {
                    const artists = await artistSelectService({ excludedIdArtist: idartist, term });
                    return artists.map(({ id, html }) => ({ value: id, label: html }));
                  }}
                  classNamePrefix="react-select"
                  className="price-index-select"
                  onChange={option => updateOptions({ idartist: option && option.value })}
                />
              </div>

              <div className="filter">
                <ReactSelect
                  isClearable
                  classNamePrefix="react-select"
                  className="price-index-select"
                  options={stockIndexes}
                  placeholder={i18next.t('indexes.priceindex.m2')}
                  onChange={e => updateOptions({ stockIndex: e ? e.value : undefined })}
                />
              </div>
            </Filters>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default PriceIndex;
