/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.warnings.warningmodale.content.m1',
  'subscriptions.list.warnings.warningmodale.content.m2',
  'subscriptions.list.warnings.warningmodale.content.m3',
  'subscriptions.list.warnings.warningmodale.content.m4',
  'subscriptions.list.warnings.warningmodale.content.m5',
  'subscriptions.list.warnings.warningmodale.content.m6',
  'subscriptions.list.warnings.warningmodale.content.m7',
  'subscriptions.list.warnings.warningmodale.content.m8',
  'subscriptions.list.warnings.warningmodale.content.m9',
  'subscriptions.list.warnings.warningmodale.content.m10',
  'subscriptions.list.warnings.warningmodale.content.m11',
  'subscriptions.list.warnings.warningmodale.content.m12',
  'subscriptions.list.warnings.warningmodale.content.m13',
];
