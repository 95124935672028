import React from 'react';
import './stylesheet.scss';
import useSquareColumnsCount from '../../hooks/useSquareColumnsCount';

const Skeleton = () => {
  const columnsCount = useSquareColumnsCount();

  return (
    <div className="search-universal-skeletons-small">
      {[...Array(columnsCount)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`SkeletonSmall${i}`} className="skeleton-block">
          <div className="picto" />
          <div className="txt" />
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
