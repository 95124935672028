import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { DEFAULT_CURRENCIES } from '../../../../../../constants/preferences';
import { getCurrency } from '../../../../../../redux/selectors/preferences';
import A18n from '../../../../../common/A18n';

const FormattedPrice = ({ prices, idcurrency }) => {
  const currency = useSelector(getCurrency);

  const classifiedCurrency = Object.values(DEFAULT_CURRENCIES).filter(c => idcurrency === c.id)[0];
  const formattedNumberOptions = { style: 'currency', currencyDisplay: 'symbol', minimumFractionDigits: 0, maximumFractionDigits: 3 };

  if (!prices || !classifiedCurrency || prices[classifiedCurrency.iso3] === 0) {
    return <A18n rsx="marketplace.classified.details.common.formattedprice.m1" />;
  }

  return (
    <span>
      <FormattedNumber value={prices[classifiedCurrency.iso3]} currency={classifiedCurrency.iso3} {...formattedNumberOptions} />
      {currency !== classifiedCurrency.iso3 && currency in prices && (
        <span style={{ marginLeft: 5 }}>
          (<FormattedNumber value={prices[currency]} currency={currency} {...formattedNumberOptions} />)
        </span>
      )}
    </span>
  );
};

export default FormattedPrice;
