import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLayoutContainerOpen, openLayoutContainer, removeTransitionOrigin, setTransitionOrigin } from '../../../redux/slices/search/universal';
import { ANALYTICS_CLICK_OPERATION_DISPLAY_SEARCH_BAR, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import { get } from '../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../services/analytics/aapi';
import Input from '../../search/Universal/Input';
import { a18n } from '../../../utils/a18n';
import './stylesheet.scss';

const Search = () => {
  const inputRef = useRef();
  const { idcustomer } = useSelector(get);
  const universalSearchVisible = useSelector(isLayoutContainerOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTransitionOrigin({ selector: '.homepage-search-container' }));

    return () => dispatch(removeTransitionOrigin());
  }, []);

  const openSearchContainer = useCallback(() => {
    dispatch(openLayoutContainer());
    aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_DISPLAY_SEARCH_BAR });
  }, [dispatch]);

  return (
    <div className={classNames({ 'homepage-search': true, co: idcustomer, 'opacity-0': universalSearchVisible })} onClick={openSearchContainer}>
      <div className={classNames('homepage-search-container')}>
        <Input readOnly ref={inputRef} placeholder={a18n('search.universal.m2')} />
      </div>
    </div>
  );
};
export default Search;
