import { internalAxios } from '../utils/axios/internal';

export const status = async ({ idpayment = '', idphysicsale = '' }) => {
  let url = '/api/payment/status';
  if (idpayment) {
    // idpayment takes priority
    url += `?idpayment=${idpayment}`;
  } else if (idphysicsale) {
    url += `?idphysicsale=${idphysicsale}`;
  }

  const response = await internalAxios.get(url);
  return response;
};
