import React, { lazy, Suspense } from 'react';
import Spinner from '../../../../common/spinners/Spinner';

const LazyPreview = lazy(() => import(/* webpackChunkName: "lazypdfpreview" */ '../Lazy/Preview'));

const Preview = ({ id }) => (
  <Suspense fallback={<Spinner />}>
    <LazyPreview id={id} />
  </Suspense>
);

export default Preview;
