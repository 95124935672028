import React from 'react';
import AvailablePayments from '../AvailablePayments';
import Promocode from './Promocode';
import Help from '../Help';
import Cart from './Cart';

import './stylesheet.scss';

const CartSummary = ({ withPromocode }) => (
  <div className="payments-common-cart-summary">
    <Cart />
    {withPromocode && <Promocode />}

    <div className="help-on-desktop">
      <Help />
      <AvailablePayments />
    </div>
  </div>
);

export default CartSummary;
