import React from 'react';
import './stylesheet.scss';

const VerticalBar = ({ children, className, ...otherProps }) => (
  <div className={`vertical-bar ${className || ''}`.trim()} {...otherProps}>
    {children}
  </div>
);

export default VerticalBar;
