import React from 'react';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_SITUATION } from '../../../../../../../../../constants/rails';

const AlmostExpiredSubscription = () => (
  <Notification
    element={<A18n rsx="component.header.notifications.subscriptions.m1" />}
    link="/account/situation"
    onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_SITUATION })}
  />
);

export default AlmostExpiredSubscription;
