import React from 'react';
import { useSelector } from 'react-redux';
import Baseline from './Baseline';
import VerticalSeparator from './VerticalSeparator';
import Login from './Login';
import Langs from './dropdowns/Langs';
import Pricing from './dropdowns/Pricing';
import Messages from './dropdowns/Messages';
import MyAccount from './dropdowns/MyAccount';
import Favorites from './Favorites';
import { isLogged } from '../../../../redux/slices/userContext/selectors';

import './stylesheet.scss';

const Top = ({ locale, simple }) => {
  const logged = useSelector(isLogged);

  if (simple) {
    return (
      <div className="artp-Header-top">
        <Baseline />
        <VerticalSeparator />
        <Login />
      </div>
    );
  }
  return (
    <div className="artp-Header-top">
      <Baseline />
      <VerticalSeparator />
      {!logged && (
        <>
          <Langs locale={locale} />
          <VerticalSeparator />
        </>
      )}
      <Pricing />
      <VerticalSeparator />
      {logged && (
        <>
          <Favorites />
          <VerticalSeparator />
          <Messages />
          <VerticalSeparator />
          <MyAccount />
        </>
      )}
      {!logged && <Login />}
    </div>
  );
};

export default Top;
