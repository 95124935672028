import React from 'react';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const EscrowChecks = () => (
  <div className="escrow-checks">
    <div className="steps">
      <div className="check">
        <img alt="logo-escrow" src={`${IMAGE_SOURCE}marketplace/how-to-bid/escrow-check.svg`} />
      </div>
      <div className="step">
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m1" />
        </p>
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m2" />
        </p>
      </div>
      <div className="check">
        <img alt="logo-escrow" src={`${IMAGE_SOURCE}marketplace/how-to-bid/escrow-check.svg`} />
      </div>
      <div className="step">
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m3" />
        </p>
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m4" />
        </p>
      </div>
      <div className="check">
        <img alt="logo-escrow" src={`${IMAGE_SOURCE}marketplace/how-to-bid/escrow-check.svg`} />
      </div>
      <div className="step">
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m5" />
        </p>
        <p>
          <A18n rsx="marketplace.howtobid.escrowchecks.m6" />
        </p>
      </div>
    </div>
  </div>
);

export default EscrowChecks;
