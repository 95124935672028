import React from 'react';
import './stylesheet.scss';
import Spinner from '../../../../common/spinners/Spinner';

const Fake = () => (
  <div className="subscriptions-list-fake">
    <div className="card">
      <div className="block-1">
        {/* eslint-disable jsx-a11y/heading-has-content */}
        <h2 />
        <div className="arguments">
          <ul>
            <Spinner faSize={3} />
          </ul>
          <div className="details" />
        </div>
        <div className="filler" />
      </div>
      <div className="block-2">
        <div className="physicproducts" />
        <div className="buy" />
      </div>
    </div>
    <div className="images-option" />
  </div>
);

export default Fake;
