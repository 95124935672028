import { internalAxios } from '../../utils/axios/internal';

export async function getTerm(rsx, lang = undefined) {
  const response = await internalAxios.get('/api/admin/translate/term', { params: { rsx, lang } });
  return response.data;
}

export async function getTerms(rsxs, lang = undefined) {
  const response = await internalAxios.get('/api/admin/translate/terms', { params: { rsxs, lang } });
  return response.data;
}
