import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetch } from '../../../../redux/actions/account/messages';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { set } from '../../../../redux/actions/ui/dictionary';
import { ACTIVE_MENU, INBOX, SENT, TRASH, KEY_CURRENT_PAGE, KEY_SHOW_MESSAGE_TOOLS } from '../../../../constants/account/messages';
import { count as countSelector, ids as idsSelector, perPage as perPageSelector, loading as loadingSelector } from '../../../../redux/selectors/account/messages';
import Message from './Message';
import { queryStringToObject } from '../../../../utils/http';
import Pagination from '../../../common/Pagination';
import './stylesheet.scss';

const List = ({ folder }) => {
  const dispatch = useDispatch();
  const { p: urlPage } = queryStringToObject(window.location.search);
  const ids = useSelector(idsSelector);
  const count = useSelector(countSelector);
  const perPage = useSelector(perPageSelector);
  const loading = useSelector(loadingSelector);
  const page = useSelector(state => get(state, { id: KEY_CURRENT_PAGE }));

  useEffect(() => {
    switch (folder) {
      case 'trash':
        dispatch(set(ACTIVE_MENU, TRASH));
        break;
      case 'sent':
        dispatch(set(ACTIVE_MENU, SENT));
        break;
      default:
        dispatch(set(ACTIVE_MENU, INBOX));
    }

    let p = 1;
    if (urlPage) {
      p = parseInt(urlPage, 10);
    }

    dispatch(set(KEY_CURRENT_PAGE, p));
    dispatch(set(KEY_SHOW_MESSAGE_TOOLS, false));
    dispatch(fetch({ folder, page: p }));
  }, []);

  const handlePagination = p => {
    dispatch(set(KEY_CURRENT_PAGE, p));
    dispatch(fetch({ folder, page: p }));
  };

  return (
    <div className="account-messages-list">
      <div className="header">
        <div className="title">{count > 1 ? i18next.t('account.messages.list.m1').replace('%count%', count) : i18next.t('account.messages.list.m1')}</div>
        <div className="pagination">{count > perPage && <Pagination perPage={perPage} totalCount={count} activePage={page} pagePadding={1} loading={loading} onClick={p => handlePagination(p)} />}</div>
      </div>
      {ids.map(id => (
        <Message key={`message#${id}`} id={id} />
      ))}
    </div>
  );
};

export default List;
