import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import A18n from '../../../../common/A18n';
import { ANALYTICS_CLICK_OPERATION_DISPLAY_SEARCH_BAR, ANALYTICS_FROM_HEADER_SEARCHBAR, IMAGE_SOURCE } from '../../../../../constants/rails';
import { closeLayoutContainer, isLayoutContainerOpen, openLayoutContainer } from '../../../../../redux/slices/search/universal';

import './stylesheet.scss';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const Search = ({ link }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isLayoutContainerOpen);

  if (link) {
    return (
      <a href="/search" className="artp-Header-search-button artp-Header-item e2e-search-btn">
        <button type="button" className="artp-Header-search-button">
          <img src={`${IMAGE_SOURCE}menu/loupe.svg`} alt="card" />
          <span>
            <A18n rsx="layouts.header.m69" />
          </span>
        </button>
      </a>
    );
  }

  return (
    <button
      type="button"
      className="artp-Header-search-button artp-Header-item e2e-search-btn"
      onClick={() => {
        if (isOpen) {
          dispatch(closeLayoutContainer());
        } else {
          aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_SEARCHBAR, op: ANALYTICS_CLICK_OPERATION_DISPLAY_SEARCH_BAR });
          dispatch(openLayoutContainer());
        }
      }}
    >
      <img src={`${IMAGE_SOURCE}menu/loupe.svg`} alt="card" />
      <span>
        <A18n rsx="layouts.header.m69" />
      </span>
    </button>
  );
};

export default Search;
