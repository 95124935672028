import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Years from '../../../inputs/Years';

const ArtistDeathYear = () => (
  <Item label={i18next.t('components.search.helpers.items.artist_death_year.m1')} name={{ death_from: 'from', death_to: 'to' }}>
    <Years />
  </Item>
);

export default ArtistDeathYear;
