import { useDispatch, useSelector } from 'react-redux';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { closeLayoutContainer, getTransitionOriginSelector, isLayoutContainerOpen } from '../../../../redux/slices/search/universal';
import CloseSvg from '../../../common/CloseSvg';
import Universal from '../index';
import Input from '../Input';
import './stylesheet.scss';

// It's important to note that the top is relative to the parent (so top: 0 is just under the header)
function getOriginCoordinates(selector) {
  // if we have a selector, we take that as origin
  if (selector) {
    const el = document.querySelector(selector);
    if (el) {
      const { top, width } = el.getBoundingClientRect();
      return {
        top: `calc(${top}px - var(--header-height))`,
        width: `${width}px`,
      };
    }
  }

  // we try to check if the layoutContainer itself is visible
  // This way we can completely hide it on close
  const layoutContainerElement = document.querySelector('.layout-header-search-bar-container');
  if (layoutContainerElement) {
    const { height } = layoutContainerElement.getBoundingClientRect();
    return {
      top: `-${height}px`,
      width: '',
    };
  }

  // Else we juste go to -1 * header-height
  return {
    top: 'calc(-1 * var(--header-height))',
    width: '',
  };
}

const SearchBarContainer = () => {
  const dispatch = useDispatch();
  const open = useSelector(isLayoutContainerOpen);
  const selector = useSelector(getTransitionOriginSelector);
  const [displayed, setDisplayed] = useState(!!open);
  const bgRef = useRef();
  const containerRef = useRef();
  const universalRef = useRef();

  useLayoutEffect(() => {
    if (open) {
      setDisplayed(true);
    }
  }, [open]);

  useLayoutEffect(() => {
    const { top, width } = getOriginCoordinates(selector);

    if (displayed && !open) {
      containerRef.current.style.top = top;
      containerRef.current.style.width = width;
      bgRef.current.style.backgroundColor = 'transparent';
    } else if (displayed && open) {
      containerRef.current.style.transition = `top 0s, width 0s`;
      containerRef.current.style.top = top;
      containerRef.current.style.width = width;
      bgRef.current.style.transition = `background-color 0s`;
      bgRef.current.style.backgroundColor = 'transparent';

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          // two requestAnimationFrame because of a bug in firefox
          containerRef.current.style.transition = `top var(--universal-search-timing), width var(--universal-search-timing)`;
          containerRef.current.style.top = '';
          containerRef.current.style.width = '';
          bgRef.current.style.transition = `background-color var(--universal-search-timing)`;
          bgRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
        });
      });
    }
  }, [selector, open, displayed]);

  if (!displayed) return null;

  return (
    <div
      ref={bgRef}
      className="layout-header-search-bar-container-bg"
      onClick={e => {
        if (!containerRef.current.contains(e.target)) {
          dispatch(closeLayoutContainer());
        }
      }}
    >
      <RemoveScrollBar />
      <div
        ref={containerRef}
        className="layout-header-search-bar-container"
        onTransitionEnd={() => {
          if (!open) {
            setDisplayed(false);
          } else {
            universalRef.current.focus();
          }
        }}
      >
        {open ? <Universal ref={universalRef} autoFocus /> : <Input ref={universalRef} />}
        <div
          className="layout-header-search-bar-container-close"
          onClick={() => {
            dispatch(closeLayoutContainer());
          }}
        >
          <CloseSvg className="close-svg" />
        </div>
      </div>
    </div>
  );
};

export default SearchBarContainer;
