/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.table.module.m1',
  'subscriptions.list.table.module.m2',
  'subscriptions.list.table.module.m3',
  'subscriptions.list.table.module.m4',
  'subscriptions.list.table.module.m5',
  'subscriptions.list.table.module.m6',
  'subscriptions.list.table.module.m7',
];
