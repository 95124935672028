import React, { memo } from 'react';
import { Pie, PieChart, Cell, Legend, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../../../constants/indexes/common';
import { simplifyData } from '../../../../../utils/marketplace/stores/stats';
import { EMPTY_ARRAY } from '../../../../../constants/utils';

const RADIAN = Math.PI / 180;
function renderMobileLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent }) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
}

function renderDesktopLabel({ name, percent }) {
  return `${name}: ${(percent * 100).toFixed(2)}%`;
}

const StatsPie = ({ data = EMPTY_ARRAY }) => {
  const simplifiedData = simplifyData(data, 0.1);
  const isMobile = window.matchMedia('(max-width: 480px)').matches;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart width={300} height={400}>
        <Pie
          startAngle={90}
          endAngle={-270}
          data={simplifiedData}
          dataKey="value"
          nameKey="name"
          outerRadius={145}
          fill="rgb(51, 102, 204)"
          animationDuration={250}
          labelLine={false}
          label={isMobile ? renderMobileLabel : renderDesktopLabel}
        >
          {simplifiedData.map((entry, index) => (
            <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {isMobile && <Legend />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default memo(StatsPie);
