import i18next from 'i18next';
import ReactSelect from 'react-select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getIdartist } from '../../../../redux/selectors/indexes/artist';
import { artistOptionsAction } from '../../../../redux/actions/indexes/artist';
import { getCurrency } from '../../../../redux/selectors/preferences';
import './stylesheet.scss';

const CategoriesOptions = ({ parentChart, onChange }) => {
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);
  const categories = useSelector(getCategories);

  useEffect(() => {
    dispatch(artistOptionsAction());
  }, [idartist, currency]);

  const handleChange = category => {
    setValue(category);
    onChange({ idcategory: category ? category.id : undefined });
  };

  return (
    <ReactSelect
      id={`option-chart-category-${parentChart}`}
      name="idcategory"
      isClearable
      value={value}
      classNamePrefix="react-select"
      className="indexes-common-categories"
      options={categories}
      placeholder={i18next.t('indexes._advanced.m6')}
      onChange={handleChange}
    />
  );
};

export default CategoriesOptions;
