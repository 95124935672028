import React from 'react';
import i18next from 'i18next';
import HighlightedTitle from '../../../common/HighlightedTitle';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Protection = () => (
  <div className="transaction-process">
    <HighlightedTitle title={<A18n rsx="marketplace.howtobid.transactionprocess.m1" />} tag="h2" />
    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: i18next.t('marketplace.howtobid.transactionprocess.m2') }} />
    <div className="steps">
      <div className="step step1">
        <A18n rsx="marketplace.howtobid.transactionprocess.m3" />
      </div>
      <div className="step step2">
        <A18n rsx="marketplace.howtobid.transactionprocess.m4" />
      </div>
      <div className="step step3">
        <A18n rsx="marketplace.howtobid.transactionprocess.m5" />
      </div>
      <div className="step step4">
        <A18n rsx="marketplace.howtobid.transactionprocess.m6" />
      </div>
      <div className="step step5">
        <A18n rsx="marketplace.howtobid.transactionprocess.m7" />
      </div>
    </div>
    <div className="actions">
      <a href={`/${i18next.t('routes.marketplace_info')}`} className="btn btn-primary">
        <A18n rsx="marketplace.howtobid.transactionprocess.m8" />
      </a>
      <a href={`/${i18next.t('routes.marketplace')}?idclassifiedauctionmode=1&p=1`} className="btn btn-primary">
        <A18n rsx="marketplace.howtobid.transactionprocess.m9" />
      </a>
    </div>
  </div>
);

export default Protection;
