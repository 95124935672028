import React, { useEffect, useRef, useState } from 'react';
import { get, isEqual } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { isInViewport, scrollToElement } from '../../../../utils/ui';
import { cleanPathname, objectToQueryString } from '../../../../utils/http';

import { get as getUiDictionary } from '../../../../redux/selectors/ui/dictionary';
import { getCurrentCount, getCurrentInfiniteScroll } from '../../../../redux/selectors/search/info';
import { areCurrentResultsLoading, getCurrentResultsData } from '../../../../redux/selectors/search/results';
import { getCurrency, getFreshness, getItemsPerpage } from '../../../../redux/selectors/preferences';
import { getCurrentDraftParams, getCurrentScope, getCurrentUrlParams, isCurrentDraftDirty } from '../../../../redux/selectors/search';

import { applyCurrentDraft } from '../../../../redux/actions/search';
import { resetCurrentDraftParams } from '../../../../redux/actions/search/draftParams';

import useGtm from '../../../../hooks/useGtm';
import { useDidUpdateEffect } from '../../../../hooks/useDidUpdateEffect';
import { useInitializeActiveShorcut } from '../../../../hooks/myartprice/useInitializeActiveShorcut';
import { usePaginatedSearch } from '../../../../hooks/usePaginatedSearch';

import Spinner from '../../../common/spinners/Spinner';
import LotHorizontalBar from '../../../search/bars/Lot';
import VerticalBar from '../../common/VerticalBar';
import Lot from '../../../lots/Tile';
import OpenDrawer from '../../common/VerticalBar/OpenDrawer';
import NoResults from '../../common/NoResults';
import BackToTopButton from '../../../common/BackToTopButton';
import Filters from '../../../search/bars/Lot/Filters';
import SelectedParams from '../../../search/common/SelectedParams';
import MainLayout from '../../common/MainLayout';

import '../../common/styles/myartpriceBar.scss';
import '../../common/styles/myartprice.scss';
import './stylesheet.scss';
import { style as horizontalBarStyle } from '../../common/styles/horizontalBar';
import { getStyle } from '../../../../utils/styles/horizontalBar';

import { SHORTCUT_LOTS_AUCTIONED_OFF, SHORTCUT_LOTS_FUTURE_ALL, SHORTCUT_LOTS_FUTURE_NEW, SHORTCUT_LOTS_FOLLOW_FUTURE, SHORTCUT_LOTS_FOLLOW_PAST } from '../../../../constants/myartprice';
import {
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS,
  MYARTPRICE_LOTS_FOLLOW,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
} from '../../../../constants/search/scope';
import { ANALYTICS_FROM_MY_ARTPRICE } from '../../../../constants/rails';
import Pagination from '../../../common/Pagination';

// Change scope until results are found
export const ResolveLots = () => {
  const totalCount = useSelector(getCurrentCount);
  const [scopeIndex, setScopeIndex] = useState(0);

  // Sorted by priority
  const scopesUrls = [
    { scope: MYARTPRICE_LOTS_FOLLOW_FUTURE, url: '/myartprice/lots/futures' },
    { scope: MYARTPRICE_LOTS_FOLLOW_PAST, url: '/myartprice/lots/auctioned-off' },
    { scope: MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, url: '/myartprice/lots/marketplace' },
  ];

  const outOfRange = scopeIndex >= scopesUrls.length;
  const scopeUrl = scopesUrls[outOfRange ? 0 : scopeIndex];

  useEffect(() => {
    if (totalCount === 0) {
      setScopeIndex(scopeIndex + 1);
    } else if (totalCount > 0) {
      window.location.href = scopeUrl.url;
    }
  }, [totalCount]);

  usePaginatedSearch({ scope: scopeUrl.scope, options: { cancelPrevious: true } });

  if (outOfRange) {
    window.location.href = scopeUrl.url;
  }

  return <Spinner />;
};

const Lots = ({ searchScope }) => {
  const refTitle = useRef();
  const perPage = useSelector(getItemsPerpage);
  const totalCount = useSelector(getCurrentCount);
  const freshness = useSelector(getFreshness);
  const currency = useSelector(getCurrency);
  const returnToIdlot = useSelector(state => getUiDictionary(state, { id: 'returnToIdlot' }), shallowEqual);
  const data = useSelector(getCurrentResultsData) || {};
  const loading = useSelector(areCurrentResultsLoading) || false;
  const { page, setPage, fetch } = usePaginatedSearch({ scope: searchScope, options: { cancelPrevious: true } });
  const currentScope = useSelector(getCurrentScope);
  const infiniteScroll = useSelector(getCurrentInfiniteScroll);
  const drawerId = 'myartprice-lots-filter-advanced';
  const dispatch = useDispatch();
  const isDraftDirty = useSelector(isCurrentDraftDirty);
  const params = useSelector(getCurrentDraftParams);
  const initialParams = useSelector(getCurrentUrlParams);
  const ipp = useSelector(getItemsPerpage);
  const redirect = false;

  const mapScopeToSchortcut = () => {
    switch (searchScope) {
      case MYARTPRICE_LOTS_FUTURE_ALL:
        return SHORTCUT_LOTS_FUTURE_ALL;
      case MYARTPRICE_LOTS_FUTURE_NEW:
        return SHORTCUT_LOTS_FUTURE_NEW;
      case MYARTPRICE_LOTS_FOLLOW_FUTURE:
        return SHORTCUT_LOTS_FOLLOW_FUTURE;
      case MYARTPRICE_LOTS_FOLLOW_PAST:
        return SHORTCUT_LOTS_FOLLOW_PAST;
      case MYARTPRICE_LOTS_AUCTIONED_OFF:
        return SHORTCUT_LOTS_AUCTIONED_OFF;
      default:
        return null;
    }
  };

  const activeShortcut = mapScopeToSchortcut();
  useInitializeActiveShorcut({ activeShortcut });

  // Breakcrumb
  const mapScopeToTitleRsx = () => {
    if (MYARTPRICE_LOTS.includes(searchScope)) return 'layouts.header.m16';
    if (MYARTPRICE_LOTS_FOLLOW.includes(searchScope)) return 'layouts.header.m43';
  };

  const mapScopeToSubTitleRsx = () => {
    switch (searchScope) {
      case MYARTPRICE_LOTS_FUTURE_ALL:
      case MYARTPRICE_LOTS_FUTURE_NEW:
      case MYARTPRICE_LOTS_FOLLOW_FUTURE:
        return 'components.myartprice.common.verticalbar.content.m3';
      case MYARTPRICE_LOTS_FOLLOW_PAST:
      case MYARTPRICE_LOTS_AUCTIONED_OFF:
        return 'components.myartprice.common.verticalbar.content.m4';
      default:
        return null;
    }
  };

  useEffect(() => {
    const breadcrumb = document.querySelector('.breadcrumb');

    breadcrumb.classList.add('hidden-xs', 'hidden-sm');
    breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
                            <li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
                            <li>${i18next.t(mapScopeToTitleRsx())}</li> 
                            <li>${i18next.t(mapScopeToSubTitleRsx())}</li>`;
  }, [searchScope]);

  useDidUpdateEffect(() => {
    if ([...MYARTPRICE_LOTS_FOLLOW, ...MYARTPRICE_LOTS].includes(searchScope)) {
      if (page > 1) {
        setPage(1);
      } else {
        fetch({ forceUpdate: true, updateFacets: true, updateDefaultFacets: true });
      }
    }
  }, [freshness, currency]);

  useEffect(() => {
    if (returnToIdlot && !loading) {
      const tile = document.querySelector(`#lot-${returnToIdlot}`);
      if (tile) {
        window.scrollTo(0, tile.offsetTop);
      }
    }
  }, [loading]);

  const handlePaginationClick = p => {
    setPage(p);
    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  const validateParams = force => {
    if (force || isDraftDirty) {
      if (redirect && !infiniteScroll) {
        window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...params, p: 1 })}`;
      } else {
        dispatch(applyCurrentDraft({ p: 1, ipp, force }));
      }
    }
  };

  const resetIfNecessary = () => {
    if (!isEqual(initialParams, params)) {
      dispatch(resetCurrentDraftParams());
    }
  };

  useGtm({ section: 'lots' });

  const horizontalBarChildren = () => (
    <div style={getStyle({ backgroundColor: horizontalBarStyle.backgroundColor, borderColor: horizontalBarStyle.borderColor, paddingBottom: 5, paddingTop: 5, borderTop: 'solid 1px #fff' })}>
      <div className="container">
        <div className="filters-container">
          <LotHorizontalBar
            withOptionalCurrencies
            currentScope={currentScope}
            infiniteScroll={infiniteScroll}
            redirect={redirect}
            hiddenFacets={['idartist', 'idcategory']}
            className={horizontalBarStyle.className}
            backgroundColor={horizontalBarStyle.backgroundColor}
            borderColor={horizontalBarStyle.borderColor}
            hideTotalCount
            resetIfNecessary={resetIfNecessary}
            validateParams={validateParams}
            otherFiltersPosition="first"
            otherFilters={<OpenDrawer carret={false} title={<i className="fa fa-bars" aria-hidden="true" />} className="marg marg-r-15 visible-sm visible-xs title" />}
          />
        </div>
      </div>
    </div>
  );

  return (
    <MainLayout horizontalBarChildren={horizontalBarChildren()} ref={refTitle}>
      <VerticalBar />
      <div className="col-container">
        <div className="hidden-sm hidden-xs" style={{ marginTop: '-15px' }}>
          <Filters
            withAddTolistButton={[MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_AUCTIONED_OFF].includes(currentScope)}
            currentScope={currentScope}
            infiniteScroll={infiniteScroll}
            drawerId={drawerId}
            otherFiltersPosition="first"
            otherFilters={<OpenDrawer carret={false} title={<i className="fa fa-bars" aria-hidden="true" />} className="marg marg-r-15 visible-sm visible-xs title" />}
            resetIfNecessary={resetIfNecessary}
            validateParams={validateParams}
            redirect={redirect}
          />
          <SelectedParams redirect={redirect} />
        </div>
        {(loading && (
          <div className="spinner">
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        )) || (
          <>
            {/* Lot tiles */}
            <div className="data-container">
              {get(data, 'idlot', []).map((idlot, idx) => (
                <Lot
                  id={idlot}
                  position={idx + 1 + (page - 1) * perPage}
                  key={`lot-${idlot}`}
                  analyticClickFrom={ANALYTICS_FROM_MY_ARTPRICE}
                  withSelectLot={[MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_AUCTIONED_OFF].includes(currentScope)}
                />
              ))}
              {/* display a 'no results' message */}
              {get(data, 'idlot', []).length === 0 && (
                <>
                  {searchScope === MYARTPRICE_LOTS_AUCTIONED_OFF && <NoResults messageRsxKey="component.myartprice.lot.m3" linkRsxKey="component.myartprice.lot.m4" href="/search" />}
                  {(searchScope === MYARTPRICE_LOTS_FUTURE_NEW || searchScope === MYARTPRICE_LOTS_FUTURE_ALL) && (
                    <NoResults messageRsxKey="component.myartprice.lot.m5" linkRsxKey="component.myartprice.lot.m6" href="/sales/futures" />
                  )}
                  {searchScope === MYARTPRICE_LOTS_FOLLOW_PAST && <NoResults messageRsxKey="component.myartprice.lot.m7" linkRsxKey="component.myartprice.lot.m4" href="/search" />}
                  {searchScope === MYARTPRICE_LOTS_FOLLOW_FUTURE && <NoResults messageRsxKey="component.myartprice.lot.m8" linkRsxKey="component.myartprice.lot.m4" href="/sales/futures" />}
                </>
              )}
            </div>

            {/* pagination */}
            {totalCount > 0 && totalCount / perPage > 1 && (
              <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />
            )}

            {/* Go back to top */}
            <div style={{ position: 'sticky', bottom: '25px', float: 'right', transform: 'translateX(10px)' }}>
              <BackToTopButton top={refTitle.current} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Lots;
