/* eslint-disable global-require */
module.exports = [
  ...require('./Physicproduct/i18n'),
  ...require('./Subscription/i18n'),
  ...require('./Details/i18n'),
  'subscriptions.purchase.list.theme.m8',
  'subscriptions.purchase.list.theme.m9',
  'subscriptions.purchase.list.theme.m10',
  'subscriptions.purchase.list.theme.m11',
  'subscriptions.purchase.list.theme.m12',
  'subscriptions.purchase.list.theme.m13',
];
