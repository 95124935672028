/* eslint-disable global-require */
module.exports = [
  ...require('./Content/i18n'),
  'components.myartprice.common.verticalbar.m1',
  'components.myartprice.common.verticalbar.m2',
  'components.myartprice.common.verticalbar.m3',
  'components.myartprice.common.verticalbar.m4',
  'components.myartprice.common.verticalbar.m5',
  'component.myartprice.lot.m9',
];
