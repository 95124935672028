/* eslint-disable */
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer as getStripeCustomer } from '../../../redux/actions/subscriptions/stripe';
import { getCustomer, isLoading } from '../../../redux/selectors/subscriptions/stripe';
import { getIdcustomer } from '../../../redux/slices/userContext/selectors';
import Spinner from '../../common/spinners/Spinner';
import Button from './Button';

const StripeCustomerPortal = ({ locale }) => {
  const dispatch = useDispatch();
  const idcustomer = useSelector(getIdcustomer);
  const stripeCustomer = useSelector(getCustomer);
  const loading = useSelector(isLoading);

  useEffect(() => {
    if (idcustomer) {
      dispatch(getStripeCustomer());
    }
  }, [idcustomer]);

  if (!idcustomer) return null;

  if (loading || !stripeCustomer) return <Spinner />;

  return (
    <IntlProvider locale={locale}>
        <Button stripIdcustomer={stripeCustomer.id} />
    </IntlProvider>
  );
};

export default StripeCustomerPortal;
