/* eslint-disable global-require */
module.exports = [
  'components.myartprice.settings.artists.m1',
  'components.myartprice.settings.artists.m2',
  'components.myartprice.settings.artists.m3',
  'components.myartprice.artists.m11',
  'components.myartprice.artists.m8',
  'components.myartprice.artists.m9',
  'components.myartprice.artists.m10',
  ...require('./ArtistAsyncSelect/i18n'),
  ...require('./Config/i18n'),
];
