import { get } from 'lodash';
import { getCurrentScope } from './index';

export const areResultsLoading = (state, { searchScope }) => get(state.search, `results.${searchScope}.loading`);
export const getResultsData = (state, { searchScope }) => get(state.search, `results.${searchScope}.data`);

export const areCurrentResultsLoading = state => {
  const searchScope = getCurrentScope(state);
  return areResultsLoading(state, { searchScope });
};
export const getCurrentResultsData = state => {
  const searchScope = getCurrentScope(state);
  return getResultsData(state, { searchScope });
};
