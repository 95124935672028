import i18Next from 'i18next';
import React from 'react';
import { updateUrlQuery } from '../../../../utils/http';
import DropDown from '../../../common/DropDown';
import './stylesheet.scss';

const Sort = ({ onClick, sort, options }) => {
  const handleClick = key => {
    onClick(key);
    updateUrlQuery({ sort: key, p: 1 });
  };

  return (
    <DropDown
      right
      className="account-lots-lists-sort"
      displayIconDesktop
      icon="sort-amount-asc"
      title={i18Next.t('search.drawers.lotmobile.m6')}
      render={() => (
        <div className="radios-list">
          {Object.entries(options).map(([key, label]) => (
            <label htmlFor={`sort-input-${key}`} key={key} className="option">
              <input id={`sort-input-${key}`} onClick={() => handleClick(key)} defaultChecked={key === sort} className="marg marg-r-5" type="radio" name="search-sort-radios" value={key} />
              {label}
            </label>
          ))}
        </div>
      )}
    />
  );
};
export default Sort;
