/* eslint-disable global-require */
module.exports = [
  'artists.follow.recommendations-modale.m1',
  'artists.follow.recommendations-modale.m2',
  'artists.follow.recommendations-modale.m3',
  'artists.follow.recommendations-modale.m4',
  'artists.follow.recommendations-modale.m5',
  'artists.follow.recommendations-modale.m6',
  'artists.follow.recommendations-modale.m7',
  'artists.follow.recommendations-modale.m8',
];
