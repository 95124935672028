import React from 'react';
import { Outlet } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../providers/ReduxProvider';
import RouterProvider from '../../providers/RouterProvider';
import LotDetail from './Show/Lot';
import Pdf from './Show/Pdf';
import Breadcrumb from './Breadcrumb';
import LandingPage from './LandingPage';
import Show from './Show';
import List from './List';

import './stylesheet.scss';

const Lotslists = ({ lang }) => (
  <ReduxProvider>
    <IntlProvider locale={lang}>
      <RouterProvider
        routes={[
          {
            path: '/account/lots-lists',
            element: (
              <>
                <div className="container">
                  <Breadcrumb />
                </div>
                <Outlet />
              </>
            ),
            children: [
              {
                path: ':id/:idlot/:crypto',
                element: <LotDetail />,
              },
              {
                path: ':id/pdf',
                element: <Pdf />,
              },
              {
                path: ':id',
                element: <Show />,
              },
              {
                path: 'explanation',
                element: <LandingPage />,
              },
              {
                path: '',
                element: <List />,
              },
            ],
          },
        ]}
      />
    </IntlProvider>
  </ReduxProvider>
);

export default Lotslists;
