import i18next from 'i18next';
import { forEach } from 'lodash';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Col, FormGroup, Label } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import GoogleRecaptcha from 'react-google-invisible-recaptcha';
import { internalAxios } from '../../../utils/axios/internal';
import CountryDropdown from './CountryDropdown';
import Document from './Documents/Document';
import TitleDropdown from './TitleDropdown';
import { a18n } from '../../../utils/a18n';
import Description from './Description';
import PhoneInput from './PhoneInput';
import A18n from '../../common/A18n';
import Documents from './Documents';
import TextField from './TextField';
import DropDown from './DropDown';
import DateField from './Date';
import './stylesheet.scss';

const Reporting = ({ data }) => {
  const intl = useIntl();
  const { reportingTypes, rightTypes, reporterTypes, gSiteKey } = data;
  const [reporterType, setReporterType] = useState();
  const [reportingtype, setReportingtype] = useState();
  const [rights, setRights] = useState();
  const [isSexualOffence, setIsSexualOffence] = useState(false);
  const [countryIso2, setCountryIso2] = useState(i18next.language);
  const [step, setStep] = useState(1);
  const refRecaptcha = useRef();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm();

  const onCaptchaResolved = async () => {
    const values = getValues();
    const captchaResponse = await refRecaptcha.current.callbacks.getResponse();
    const formData = new FormData();

    formData.append('g-recaptcha-response', captchaResponse);
    forEach(values, (value, name) => {
      if (name !== 'first_report' && !name.startsWith('document')) {
        formData.append(name, value);
      } else {
        formData.append(name, value?.[0]);
      }
    });

    internalAxios
      .post('/api/marketplace/report', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setStep(2);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSubmit = async () => {
    refRecaptcha.current.callbacks.execute();
  };

  const handleCountryChange = country => {
    setCountryIso2(country.iso2.toLowerCase());
  };

  // UseEffect to change state depending on form values
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'idreportingtype') {
        setIsSexualOffence(value.idreportingtype === 5);
        setReportingtype(value.idreportingtype);
      }
      if (name === 'idreportertype') setReporterType(value.idreportertype);
      if (name === 'idrights') setRights(value.idrights);

      setSubmitDisabled(!value.check_rights || !value.check_cgu);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  if (step === 2)
    return (
      <div className="marketplace-reporting step-2">
        <div className="container">
          <h1>
            <A18n rsx="marketplace.reporting.m30" replace={[['%dt%', intl.formatDate(new Date())]]} />
          </h1>
          <p>
            <A18n rsx="marketplace.reporting.m26" />
          </p>
          <p>
            <A18n rsx="marketplace.reporting.m25" replace={[['%dt%', intl.formatDate(new Date())]]} />
          </p>
        </div>
      </div>
    );

  return (
    <div className="marketplace-reporting">
      <div className="container">
        <h1>
          <A18n rsx="marketplace.reporting.m1" />
        </h1>
        <p className="legend">
          <A18n rsx="marketplace.reporting.m2" />
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            <A18n rsx="marketplace.reporting.m3" />
          </h2>
          <DateField errors={errors} control={control} />
          <DropDown errors={errors} control={control} label={<A18n rsx="marketplace.reporting.m4" />} name="idreportingtype" options={reportingTypes} requiredValue />
          {reportingtype > 0 && reportingtype === 7 && <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m5" />} name="idreportingtypecomplement" />}
          <TextField
            errors={errors}
            register={register}
            requiredValue
            label={<A18n rsx="marketplace.reporting.m6" />}
            name="url"
            pattern={{
              value: /^https:\/\/(fr|www|de|es|it|zh)\.artprice\.com.*/gi,
              message: a18n('marketplace.reporting.m28'),
            }}
          />
          <h2>
            <A18n rsx="marketplace.reporting.m32" />
          </h2>
          <DropDown errors={errors} control={control} label={<A18n rsx="marketplace.reporting.m8" />} name="idreportertype" options={reporterTypes} requiredValue={!isSexualOffence} />
          {reporterType === 1 && (
            <>
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m9" />} name="firm" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m13" />} name="line1" />
              <TextField errors={errors} register={register} requiredValue={false} label="" name="line2" />
              <TextField errors={errors} register={register} requiredValue={false} label="" name="line3" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m14" />} name="postcode" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m15" />} name="city" />
              <CountryDropdown control={control} errors={errors} requiredValue={!isSexualOffence} handleCountryChange={handleCountryChange} />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m10" />} name="legalRepresentative" />
              <TitleDropdown control={control} requiredValue={!isSexualOffence} errors={errors} />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m11" />} name="firstname" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m12" />} name="lastname" />
              <PhoneInput control={control} requiredValue={!isSexualOffence} errors={errors} countryIso2={countryIso2} />
              <TextField
                errors={errors}
                register={register}
                requiredValue={!isSexualOffence}
                label={<A18n rsx="marketplace.reporting.m7" />}
                name="email"
                pattern={{
                  value: /^[a-z]+[a-z0-9-._]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,5}$/gim,
                  message: a18n('marketplace.reporting.m31'),
                }}
              />
            </>
          )}
          {reporterType !== 1 && (
            <>
              <TitleDropdown control={control} requiredValue={!isSexualOffence} errors={errors} />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m11" />} name="firstname" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m12" />} name="lastname" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m13" />} name="line1" />
              <TextField errors={errors} register={register} requiredValue={false} label="" name="line2" />
              <TextField errors={errors} register={register} requiredValue={false} label="" name="line3" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m14" />} name="postcode" />
              <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m15" />} name="city" />
              <CountryDropdown control={control} errors={errors} requiredValue={!isSexualOffence} handleCountryChange={handleCountryChange} />
              <PhoneInput control={control} requiredValue={!isSexualOffence} errors={errors} countryIso2={countryIso2} />
              <TextField
                errors={errors}
                register={register}
                requiredValue={!isSexualOffence}
                label={<A18n rsx="marketplace.reporting.m7" />}
                name="email"
                pattern={{
                  value: /^[a-z]+[a-z0-9-._]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,5}$/gim,
                  message: a18n('marketplace.reporting.m31'),
                }}
              />
            </>
          )}

          <Documents control={control} errors={errors} requiredValue={!isSexualOffence} reporterType={reporterType} />

          <FormGroup row className="top">
            <Label sm={4}>
              <A18n rsx="marketplace.reporting.m18" />
            </Label>
            <Col sm={5}>
              <Document control={control} errors={errors} name="first_report" requiredValue={false} />
            </Col>
          </FormGroup>

          <h2>
            <A18n rsx="marketplace.reporting.m16" />
          </h2>
          <DropDown errors={errors} control={control} label={<A18n rsx="marketplace.reporting.m17" />} name="idrights" options={rightTypes} requiredValue={!isSexualOffence} />
          {rights > 0 && rights === 2 && <TextField errors={errors} register={register} requiredValue={!isSexualOffence} label={<A18n rsx="marketplace.reporting.m5" />} name="idrightscomplement" />}
          <Description register={register} errors={errors} />

          <div className="checkboxes">
            <div>
              <input type="checkbox" {...register('check_transfer_data')} />
            </div>
            <div className="label" onClick={() => setValue('check_transfer_data', !getValues('check_transfer_data'))}>
              <A18n rsx="marketplace.reporting.m19" />
            </div>
            <div>
              <input type="checkbox" {...register('check_rights')} />
            </div>
            <div className="label" onClick={() => setValue('check_rights', !getValues('check_rights'))}>
              <A18n rsx="marketplace.reporting.m20" />
            </div>
            <div>
              <input type="checkbox" {...register('check_cgu')} />
            </div>
            <div className="label" onClick={() => setValue('check_cgu', !getValues('check_cgu'))}>
              <A18n rsx="marketplace.reporting.m21" />
              <a href="/cgv#ads" target="cgv">
                <A18n rsx="marketplace.reporting.m22" />
              </a>
              ,{' '}
              <a href="/cgv#reporting" target="reporting">
                <A18n rsx="marketplace.reporting.m23" />
              </a>
            </div>
          </div>

          <GoogleRecaptcha onResolved={onCaptchaResolved} ref={refRecaptcha} sitekey={gSiteKey} />

          <div className="validate">
            <button type="submit" className="btn btn-primary" disabled={submitDisabled}>
              <A18n rsx="marketplace.reporting.m24" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reporting;
