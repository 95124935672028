// Change scope until results are found
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_MARKETPLACE } from '../../../constants/search/scope';
import { usePaginatedSearch } from '../../../hooks/usePaginatedSearch';
import Spinner from '../../common/spinners/Spinner';
import { getCurrentCount } from '../../../redux/selectors/search/info';

// Taken as is from ResolveLots
const ResolveArtists = () => {
  const totalCount = useSelector(getCurrentCount);
  const [scopeIndex, setScopeIndex] = useState(0);

  // Sorted by priority
  const scopesUrls = [
    { scope: MYARTPRICE_LOTS_FUTURE_NEW, url: '/myartprice/artists/lots-futures/new' },
    { scope: MYARTPRICE_LOTS_FUTURE_ALL, url: '/myartprice/artists/lots-futures/all' },
    { scope: MYARTPRICE_LOTS_AUCTIONED_OFF, url: '/myartprice/artists/lots-auctioned-off' },
    { scope: MYARTPRICE_LOTS_MARKETPLACE, url: '/myartprice/artists/marketplace' },
  ];

  const outOfRange = scopeIndex >= scopesUrls.length;
  const scopeUrl = scopesUrls[outOfRange ? 0 : scopeIndex];
  useEffect(() => {
    if (totalCount === 0) {
      setScopeIndex(scopeIndex + 1);
    } else if (totalCount > 0) {
      window.location.href = scopeUrl.url;
    }
  }, [totalCount]);

  usePaginatedSearch({ scope: scopeUrl.scope, options: { cancelPrevious: true } });

  if (outOfRange) {
    window.location.href = '/myartprice/artists';
  }

  return <Spinner />;
};

export default ResolveArtists;
