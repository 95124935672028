import React from 'react';
import i18next from 'i18next';

const Newcomer = () => (
  <div className="header no-subscription">
    <div className="container">
      <h1 style={{ marginTop: 25 }}>{i18next.t('subscriptions.purchase.header.newcomer.m1')}</h1>
    </div>
  </div>
);

export default Newcomer;
