/* eslint-disable global-require */
module.exports = [
  'components.myartprice.summary.m1',
  'components.myartprice.summary.m2',
  'components.myartprice.summary.m3',
  'components.myartprice.summary.m4',
  'components.myartprice.home.m2',
  'components.myartprice.home.m6',
  'components.myartprice.home.m7',
  'components.myartprice.home.m8',
  'components.myartprice.home.m9',
  'components.myartprice.home.m10',
  'components.myartprice.home.m11',
  'components.myartprice.home.m12',
  'components.myartprice.home.m13',
  'components.myartprice.home.m14',
  'components.myartprice.home.m15',
  'component.myartprice.lot.m9',
  'myartprice.index.m13',
  'marketplace.classified.follow.m2',
  'home.artists.m3',
];
