import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getArtist, getCategories } from '../../../../../redux/slices/artist';
import Share from '../../../../common/Share';

const ArtistShare = () => {
  const artist = useSelector(getArtist);
  const categories = useSelector(getCategories);
  const [hashtags, setHashtags] = useState([]);

  useEffect(() => {
    if (categories?.all) {
      setHashtags([...hashtags, ...categories.all.map(category => category.label?.toLowerCase())]);
    }
  }, [categories]);

  return <Share title={artist.name} hashtagsAddons={hashtags} style={{ paddingTop: 7 }} />;
};

export default ArtistShare;
