import React from 'react';
import { Classifiedclass, Classifiedoriginaltype } from '../../../../../constants/marketplace';
import Dimensions from '../common/Dimensions';
import A18n from '../../../../common/A18n';

const DataDesignAndDecorative = ({ classified }) => (
  <div className="section details">
    <div className="classified-details-block">
      <div className="classified-label">
        <A18n rsx="marketplace._search.html.m7" />
      </div>
      {classified.classifieddecorativematerial && classified.classifieddecorativedirectory && `${classified.classifieddecorativematerial} / ${classified.classifieddecorativedirectory}`}
      {classified.classifieddecorativematerial && !classified.classifieddecorativedirectory && classified.classifieddecorativematerial}
      {!classified.classifieddecorativematerial && classified.classifieddecorativedirectory && classified.classifieddecorativedirectory}
    </div>

    {classified.idclassifieddecorativeperiod > 0 && classified.idclassifiedclass === Classifiedclass.DECORATIVE && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m5" />
        </div>
        {classified.classifieddecorativeperiod}
      </div>
    )}

    {classified.idclassifieddecorativestyle > 0 && classified.idclassifiedclass === Classifiedclass.DECORATIVE && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m4" />
        </div>
        {classified.classifieddecorativestyle}
      </div>
    )}

    {classified.classifieddecorativeorigin && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m6" />
        </div>
        {classified.classifieddecorativeorigin}
      </div>
    )}

    {/* Type of edition */}
    <div className="classified-details-block">
      <div className="classified-label">
        {classified.idclassifiedclass === Classifiedclass.DESIGN && <A18n rsx="marketplace.form._original_encart.html.m2" />}
        {classified.idclassifiedclass === Classifiedclass.DECORATIVE && <A18n rsx="marketplace.form._original_encart.html.m8" />}
      </div>
      {classified.idclassifiedoriginaltype === Classifiedoriginaltype.ORIGINAL && (
        <>
          {classified.idclassifiedclass === Classifiedclass.DESIGN && <A18n rsx="marketplace.form._original_encart.html.m9" />}
          {classified.idclassifiedclass === Classifiedclass.DECORATIVE && <A18n rsx="marketplace.form._original_encart.html.m10" />}
        </>
      )}
      {classified.idclassifiedoriginaltype === Classifiedoriginaltype.MULTIPLE && (
        <>
          {classified.idclassifiedclass === Classifiedclass.DESIGN && <A18n rsx="marketplace.form._original_encart.html.m7" />}
          {classified.idclassifiedclass === Classifiedclass.DECORATIVE && <A18n rsx="marketplace.form._original_encart.html.m11" />}
        </>
      )}
      {classified.idclassifiedoriginaltype === Classifiedoriginaltype.COPY && (
        <>
          {classified.idclassifiedclass === Classifiedclass.DESIGN && <A18n rsx="marketplace.form._original_encart.html.m12" />}
          {classified.idclassifiedclass === Classifiedclass.DECORATIVE && <A18n rsx="marketplace.form._original_encart.html.m13" />}
        </>
      )}
    </div>

    <div className="classified-details-block">
      <div className="classified-label">
        <A18n rsx="marketplace.fineart.form._descriptive_details.html.m28" />
      </div>
      <Dimensions classified={classified} />
    </div>

    {classified.editor && classified.idclassifiedclass === Classifiedclass.DESIGN && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart.form._descriptive_details.html.m9" />
        </div>
        {classified.editor}
      </div>
    )}

    {classified.brand && !(classified.idclassifiedclass === Classifiedclass.DECORATIVE) && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m15" />
        </div>
        {classified.brand}
      </div>
    )}

    {classified.stamp_by && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart.form._descriptive_details.html.m20" />
        </div>
        {classified.stamp_by}
      </div>
    )}

    {classified.piececount > 0 && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m16" />
        </div>
        {classified.piececount === 1 && <>{classified.piececount} pièce</>}
        {classified.piececount > 1 && <>{classified.piececount} pièces</>}
      </div>
    )}

    {classified.estampille && classified.idclassifiedclass === Classifiedclass.DECORATIVE && [1, 3].includes(classified.idclassifieddecorativedirectory) && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart.form._descriptive_details.html.m17" />
        </div>
        {classified.estampille}
      </div>
    )}

    {classified.porcelain_makers_mark && classified.idclassifiedclass === Classifiedclass.DECORATIVE && classified.idclassifieddecorativedirectory === 4 && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m24" />
        </div>
        {classified.porcelain_makers_mark}
      </div>
    )}

    {classified.classifieddecorativecondition && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.not_fineart._show.html.m17" />
        </div>
        {classified.classifieddecorativecondition}
      </div>
    )}

    {classified.certif_by && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.form._garantie.html.m10" />
        </div>
        {classified.certif_by}
      </div>
    )}

    {classified.billed_by && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.form._garantie.html.m9" />
        </div>
        {classified.billed_by}
      </div>
    )}

    {classified.ref_catalogue_raisonne && (
      <div className="classified-details-block">
        <div className="classified-label">
          <A18n rsx="marketplace.fineart._show.html.m10" />
        </div>
        {classified.ref_catalogue_raisonne}
      </div>
    )}
  </div>
);

export default DataDesignAndDecorative;
