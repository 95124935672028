/* eslint-disable global-require */
module.exports = [
  'component.indexes.average',
  'components.amci.m8',
  'components.amci.m19',
  'components.amci.m21',
  'components.amci.m24',
  'components.amci.m26',
  'components.amci.m65',
  'components.amci.m67',
  'components.amci.m68',
  'components.amci.m69',
  'components.amci.m70',
];
