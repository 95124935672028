import React from 'react';
import { useSelector } from 'react-redux';
import { getAuctioneerAgreementLastVersion } from '../../../../../../../../../redux/slices/userContext/selectors';
import Notification from '../Notification';
import A18n from '../../../../../../../../common/A18n';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_AGREEMENT } from '../../../../../../../../../constants/rails';

const AuctioneerAgreementNotSigned = () => {
  const auctioneerAgreementVersion = useSelector(getAuctioneerAgreementLastVersion);

  return (
    <Notification
      element={<A18n rsx="component.header.notifications.auctioneer.m1" />}
      link={`/auctioneer/control-center/agreement?v=${auctioneerAgreementVersion}`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_AGREEMENT })}
    />
  );
};

export default AuctioneerAgreementNotSigned;
