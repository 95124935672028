import { useEffect } from 'react';
import { OVERLAP_TOP } from '../../components/artists/Artist/common/constants';

export function useOverlap({ className, overlappingElementRef, overlappedElementRef, extraDeps = [] }) {
  useEffect(() => {
    const element = overlappedElementRef?.current;
    if (!element) return;

    const overlappingElement = overlappingElementRef?.current;
    if (!overlappingElement) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (window.innerWidth >= 992) {
            if (entry.isIntersecting) {
              entry.target.classList.remove(className);
            } else {
              entry.target.classList.add(className);
            }
          } else {
            entry.target.classList.remove(className);
          }
        });
      },
      {
        ...{
          rootMargin: `${(OVERLAP_TOP + (overlappingElement?.offsetHeight || 0) + element.offsetHeight + 10) * -1}px 0px 0px 0px`,
        },
      },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [overlappedElementRef?.current?.offsetHeight, overlappingElementRef?.current, ...extraDeps]);
}
