import React from 'react';
import classNames from 'classnames';
import { Col, FormGroup, Label } from 'reactstrap';
import ErrorBlock from '../../../common/ErrorBlock';
import A18n from '../../../common/A18n';

const TextField = ({ register, requiredValue, errors, name, label, pattern }) => (
  <FormGroup row className="marketplace-reporting-textfield">
    <Label for={name} sm={4} className={classNames({ required: requiredValue })}>
      {label}
    </Label>
    <Col sm={5}>
      <input
        className="form-control"
        type="text"
        {...register(name, {
          pattern,
          required: {
            value: requiredValue,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
        })}
      />
      <ErrorBlock error={errors[name]} />
    </Col>
  </FormGroup>
);

export default TextField;
