/* eslint-disable global-require */
module.exports = [
  'auctioneers.futuresales.sale.progressbar.m1',
  'auctioneers.futuresales.sale.progressbar.m2',
  'auctioneers.futuresales.sale.progressbar.m3',
  'auctioneers.futuresales.sale.progressbar.m4',
  'auctioneers.futuresales.sale.progressbar.m5',
  'auctioneers.futuresales.sale.progressbar.m6',
  'auctioneers.futuresales.sale.progressbar.m7',
];
