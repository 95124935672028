import RandExp from 'randexp';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import ErrorBlock from '../../common/ErrorBlock';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Tvaintra = ({ tvaFormat, locked }) => {
  const {
    setValue,
    defaultValues,
    register,
    formState: { errors },
  } = useFormContext();

  const [sample, setSample] = useState('');
  const [disabled, setDisabled] = useState(defaultValues.billaddress?.noTvaintra);

  useEffect(() => {
    if (tvaFormat) {
      setSample(new RandExp(tvaFormat).gen());
    } else {
      setSample('');
    }
  }, [tvaFormat]);

  const handleNoTvaintraClick = e => {
    if (!locked) {
      if (e.target.checked) {
        setValue('billaddress.tvaintra', '');
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  return (
    <div
      className={classNames({
        'payments-informations-form-tvaintra': true,
        cell: true,
        locked,
        warning: !isEmpty(errors.billaddress?.tvaintra),
      })}
    >
      <div className="tva-input">
        <input type="text" {...register('billaddress.tvaintra')} disabled={disabled || locked} placeholder={`${i18next.t('payments.informations.form.fields.tvaintra.m3').replace('%sample%', sample)}`} />
        <ErrorBlock error={errors.billaddress?.tvaintra} />
      </div>
      <div className="no-tva">
        <input type="checkbox" disabled={locked} {...register('billaddress.noTvaintra')} id="noTvaintra" onClick={handleNoTvaintraClick} />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="billaddress.noTvaintra">
          <A18n rsx="payments.informations.form.fields.tvaintra.m4" />
        </label>
      </div>
    </div>
  );
};

export default Tvaintra;
