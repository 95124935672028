import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import A18n from '../A18n';
import './stylesheet.scss';

const ButtonFollow = ({ isFavorite, handleFollowClick, rsxFavorited, rsxNotFavorited, size, title, noLabel }) => (
  <div className="button-follow" title={noLabel ? '' : i18next.t(isFavorite ? rsxFavorited : rsxNotFavorited)}>
    <Button style={{ outline: 'none' }} className="btn" color="default" size={size} onClick={handleFollowClick} title={title}>
      {!noLabel && <A18n rsx={isFavorite ? rsxFavorited : rsxNotFavorited} />} <i className={isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'} />
    </Button>
  </div>
);

export default ButtonFollow;
