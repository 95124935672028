import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { omit, range } from 'lodash';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CURRENCIES } from '../../../../constants/preferences';
import { useSearchResults } from '../../../../hooks/search/useSearchResults';
import { TOTAL_ACCESS_CHRONO } from '../../../../constants/search/scope';
import GraphSpinner from '../Spinner';

const BarGraph = ({ intl, params, currency, onClick }) => {
  const { loading, data = {} } = useSearchResults({ searchScope: TOTAL_ACCESS_CHRONO, params: omit(params, ['p', 'dt_from', 'dt_to']), options: { cancelPrevious: true } });
  const selectedYears = useMemo(() => {
    if (params?.dt_from) {
      if (params?.dt_to) {
        return range(new Date(params.dt_from).getFullYear(), new Date(params.dt_to).getFullYear() + 1);
      }
      return range(new Date(params.dt_from).getFullYear(), new Date().getFullYear() + 1);
    }
    if (params?.dt_to) {
      return range(2000, new Date(params.dt_to).getFullYear() + 1);
    }
    return [];
  }, [params]);

  if (loading) {
    return <GraphSpinner />;
  }

  const dataAsArray = [];
  for (let i = 2000; i <= new Date().getFullYear(); i += 1) {
    const yearData = data[i];
    dataAsArray.push({
      name: i,
      value: yearData?.value ?? 0,
      count: yearData?.count ?? 0,
      selected: selectedYears.includes(i),
    });
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={dataAsArray}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickSize={5}
          tickFormatter={value => intl.formatNumber(value, { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          margin={{ bottom: 5 }}
          width={125}
        />
        <Tooltip
          formatter={(value, _, props) => {
            const nbLot = props.payload.count;
            return [
              <>
                <div>{intl.formatNumber(value, { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                <div>{nbLot} lots vendus</div>
              </>,
              undefined,
            ];
          }}
        />
        {/* <Bar dataKey="value" fill="rgb(51, 102, 204)" cursor="pointer" className='clickable' animationDuration={400} animationBegin={35} onClick={handleClick}/> */}
        <Bar
          dataKey="value"
          onClick={({ name: year }) => {
            onClick({
              dt_from: `${year}-01-01`,
              dt_to: `${year}-12-31`,
            });
          }}
        >
          {dataAsArray.map(entry => (
            <Cell cursor="pointer" fill={entry.selected ? 'rgb(27, 32, 73)' : 'rgb(51, 102, 204)'} key={entry.name} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(BarGraph);
