import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTop5Lots } from '../../../../../redux/actions/auctioneers/sales';
import { isTop5Loading, top5 } from '../../../../../redux/selectors/auctioneers/sales';
import Lot from '../../common/Lot';

import './stylesheet.scss';

const Top5 = ({ id }) => {
  const dispatch = useDispatch();
  const ids = useSelector(state => top5(state, { id }));
  const loading = useSelector(state => isTop5Loading(state, { id }));
  useEffect(() => {
    dispatch(fetchTop5Lots({ id }));
  }, [id]);

  if (!loading && ids.length === 0) return null;

  return (
    <div className="auctioneers-sale-lots-top5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2>{i18next.t('auctioneers.sale.lots.top5.m1')}</h2>
            <div className="top5-lots">
              {loading && (
                <div className="spinner">
                  <i className="fa fa-5x fa-spinner fa-spin" />
                </div>
              )}
              {!loading && (
                <>
                  {ids.map(idlot => (
                    <div key={`lot-${idlot}`} className="top5-lot">
                      <Lot hideNumber push id={idlot} section="top5" />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top5;
