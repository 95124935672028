import i18next from 'i18next';
import React from 'react';
import Spinner from '../../../../common/spinners/Spinner';
import { CUSTOMER_VERIFICATION_STATUS_INPROGRESS, CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED, CUSTOMER_VERIFICATION_STATUS_NONVERIFIED, CUSTOMER_VERIFICATION_STATUS_VALID } from '../../../../../constants/rails';

const VerificationStatus = ({ loading, idprofessionaltype, customerVerificationStatus }) => {
  if (loading) return <Spinner faSize={2} />;
  if (!idprofessionaltype) return <span>{i18next.t('components.account.confirm.m1')}</span>;

  switch (customerVerificationStatus) {
    case CUSTOMER_VERIFICATION_STATUS_INPROGRESS:
      return i18next.t('components.account.confirm.m2');
    case CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED:
      return (
        <>
          <i className="fa fa-pencil" /> {i18next.t('components.account.confirm.m9')}
        </>
      );
    case CUSTOMER_VERIFICATION_STATUS_VALID:
      return (
        <>
          <i className="fa fa-check" /> {i18next.t('components.account.confirm.m3')}
        </>
      );
    case CUSTOMER_VERIFICATION_STATUS_NONVERIFIED:
    default:
      return (
        <>
          <i className="fa fa-info-circle" /> {i18next.t('components.account.confirm.m8')}
        </>
      );
  }
};

export default VerificationStatus;
