import React from 'react';
import './stylesheet.scss';

const Icon = () => (
  <div className="artists-artist-filters-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.444" height="18.5" viewBox="0 0 16.444 18.5">
      <g id="parametrage" transform="translate(-1475.5 -562.75)">
        <g id="Groupe_18" data-name="Groupe 18" transform="translate(5.5 -3)">
          <line id="Ligne_2" data-name="Ligne 2" x2="15.444" transform="translate(1470.5 569)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1" />
          <line id="Ligne_3" data-name="Ligne 3" x2="15.444" transform="translate(1470.5 575)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1" />
          <line id="Ligne_4" data-name="Ligne 4" x2="15.444" transform="translate(1470.5 581)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1" />
        </g>
        <line id="Ligne_5" data-name="Ligne 5" y2="5" transform="translate(1480.5 563.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
        <line id="Ligne_6" data-name="Ligne 6" y2="5" transform="translate(1487.5 569.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
        <line id="Ligne_7" data-name="Ligne 7" y2="5" transform="translate(1480.5 575.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
      </g>
    </svg>
  </div>
);

export default Icon;
