import React, { useEffect, useRef, memo } from 'react';
import { createPortal } from 'react-dom';

function createRootElement(id) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem) {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
}

const ROOT_ELEMENT = 'modal-root';
const stopPropagation = e => e.stopPropagation();

const DEFAULT_CHILD = <div>No children nor content given</div>;

const Modal = ({ children = DEFAULT_CHILD, className, onClose }) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${ROOT_ELEMENT}`);
    const parentElem = existingParent || createRootElement(ROOT_ELEMENT);
    if (!existingParent) {
      addRootElement(parentElem);
    }
    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      // do not remove parent if it is already existing
      if (!existingParent && !parentElem.hasChildNodes()) {
        parentElem.remove();
      }
    };
  }, []);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  const Overlay = () => (
    <div role="presentation" className="imagePopup-bg modal-black-bg" onClick={onClose}>
      <div role="presentation" className={`artp-modal ${className}`.trim()} onClick={stopPropagation}>
        {children}
      </div>
    </div>
  );

  return createPortal(<Overlay />, getRootElem());
};

export default memo(Modal);
