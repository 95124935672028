import React from 'react';
import { string } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider, injectIntl } from 'react-intl';
import ReduxDrawer from '../../common/Drawer';
import ReduxProvider from '../../providers/ReduxProvider';
import { MAIN_DRAWER_ID } from '../../../constants/layout';

import './stylesheet.scss';

const Drawer = () => <ReduxDrawer side="right" id={MAIN_DRAWER_ID} render={() => <div>OKOKOK</div>} />;

const IntlDrawer = injectIntl(Drawer);

// Provider
const ProvidedDrawer = ({ locale = 'en' }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <IntlDrawer locale={locale} />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

ProvidedDrawer.propTypes = {
  locale: string.isRequired,
};

export default ProvidedDrawer;
