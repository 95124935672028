import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Spinner from '../../../../../common/spinners/Spinner';
import { getPublishedClassifieds, getReceivedMessages } from '../../../../../../services/stats/marketplace';
import CustomToolTip from '../../CustomToolTip';
import CustomTick from '../../CustomTick';

import './stylesheet.scss';

const Activity = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();
  useEffect(() => {
    setLoading(true);
    Promise.all([getPublishedClassifieds(), getReceivedMessages()]).then(_stats => {
      setStats(
        _stats[0].map(({ start, end, count }, index) => ({
          start,
          end,
          published: count,
          received: _stats[1][index].count,
        })),
      );
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }
  if (!stats) {
    return null;
  }

  return (
    <ResponsiveContainer height={300} className="Activity">
      <LineChart data={stats} margin={{ top: 8 }}>
        <XAxis dataKey="end" height={50} tick={CustomTick} />
        <YAxis yAxisId="left" axisLine={{ stroke: 'var(--color2)' }} tick={{ fill: 'var(--color2)' }} />
        <YAxis yAxisId="right" axisLine={{ stroke: 'var(--color1)' }} tick={{ fill: 'var(--color1)' }} orientation="right" allowDecimals={false} />
        <Tooltip content={CustomToolTip} />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="published"
          name={i18next.t('component.store.stats.activity.m1')}
          stroke="var(--color2)"
          strokeWidth={3}
          dot={{ stroke: 'var(--color2)', strokeWidth: '3', r: 5 }}
          activeDot={{ stroke: 'var(--color2)', strokeWidth: '3', fill: 'var(--color2)', r: 5 }}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="received"
          name={i18next.t('component.store.stats.activity.m2')}
          stroke="var(--color1)"
          strokeWidth={3}
          dot={{ stroke: 'var(--color1)', strokeWidth: '3', r: 5 }}
          activeDot={{ stroke: 'var(--color1)', strokeWidth: '3', fill: 'var(--color1)', r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Activity;
