import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import A18n from '../../../../../common/A18n';
import DependsOnSelect from '../../../inputs/Select/DependsOnSelect';

const DEPENDS_ON_CONFIG = {
  facet: 'idcountry',
  placeholder: <A18n rsx="lots.search.form.state.m1" />,
};

const StateSelect = () => (
  <Item label={i18next.t('search.helpers.items.state.select.m1')} name="idstate">
    <DependsOnSelect facet="idstate" placeholder={i18next.t('marketplace.searchbars.common.state.m2')} dependsOn={DEPENDS_ON_CONFIG} />
  </Item>
);

export default StateSelect;
