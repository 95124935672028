/* eslint-disable global-require */
module.exports = [
  'component.myartprice.lot.m1',
  'component.myartprice.lot.m2',
  'component.myartprice.lot.m3',
  'component.myartprice.lot.m4',
  'component.myartprice.lot.m5',
  'component.myartprice.lot.m6',
  'component.myartprice.lot.m7',
  'component.myartprice.lot.m8',
  'common._marketplace_menu.html.m39',
  'artprice.lib.myartprice.artists.m13',
];
