import React, { useContext, useMemo } from 'react';
import { isPlainObject, isEmpty, transform } from 'lodash';
import SearchContext from '../Context';

import './stylesheet.scss';

/*
  name can be a string, or an object
  if it is a string, it will forward the corresponding value of the search context to the child component
  if it is an object: { k1: v1, k2: v2, ... }, it will take all the object keys in the context values and expose them via the keys as an object { v1: value1, v2: value2, ... }
  The object can be seen as the mapping between the context values (= from the search context), and the values as accepted by the child component
  Example: { sale_from: 'from', sale_to: 'to' } if we imagine our date component takes two props : from & to
  if the object is empty, it means the values are passed as they are
  Example: Instead of doing { dt_from: 'dt_from', dt_end: 'dt_end' }, you can pass an empty object {}
 */

function getValueFromContext(name, values) {
  if (isPlainObject(name)) {
    if (isEmpty(name)) {
      return values;
    }
    return transform(
      name,
      (result, componentName, contextName) => {
        // eslint-disable-next-line no-param-reassign
        result[componentName] = values?.[contextName];
      },
      {},
    );
  }
  return values?.[name];
}

function getValueFromComponent(name, value) {
  if (isPlainObject(name)) {
    if (isEmpty(name)) {
      return value;
    }
    return transform(
      name,
      (result, componentName, contextName) => {
        if (value.hasOwnProperty(componentName)) {
          // eslint-disable-next-line no-param-reassign
          result[contextName] = value[componentName];
        }
      },
      {},
    );
  }
  return { [name]: value };
}

const Item = ({ children, name, label }) => {
  const { values, onChange, searchScope } = useContext(SearchContext);

  // memoized props to prevent to recreate them each time the "children" prop changes
  // we could optimize even further by deep checking the value (useDeepMemo or something)
  const props = useMemo(
    () => ({
      value: getValueFromContext(name, values),
      onChange: value => {
        onChange(getValueFromComponent(name, value));
      },
      searchScope,
    }),
    [searchScope, values],
  );

  const Child = React.cloneElement(React.Children.only(children), props);

  return (
    <div className="search-item">
      {label !== false && <div className="search-item-label">{label}</div>}
      <div className="search-item-control">{Child}</div>
    </div>
  );
};

export default Item;
