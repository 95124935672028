/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m1',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m2',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m3',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m4',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m5',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m6',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m7',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m8',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m9',
  'subscriptions.purchase.list.theme-details-modale.marketplace.store.m10',
];
