import React from 'react';
import { FormGroup } from 'reactstrap';
import DocumentSelector from '../../../../account/Confirm/Identity/fieldComponents/DocumentSelector';
import ErrorBlock from '../../../../common/ErrorBlock';
import A18n from '../../../../common/A18n';

const Document = ({ control, errors, name, requiredValue }) => (
  <div>
    <FormGroup className="file-group">
      <DocumentSelector
        onDelete={() => console.warn('deleting')}
        control={control}
        className="form-control"
        name={name}
        rules={{ required: { value: requiredValue, message: <A18n rsx="marketplace.reporting.m27" /> } }}
      />
    </FormGroup>
    <ErrorBlock error={errors[name]} style={{ marginBottom: 15, paddingTop: 0, marginTop: -25 }} />
  </div>
);

export default Document;
