import React from 'react';
import { useSelector } from 'react-redux';
import Select from '../index';
import { getEffectiveDraftParams } from '../../../../../../redux/slices/search/scoped';
import { EMPTY_OBJECT } from '../../../../../../constants/utils';

/*

dependsOn must be an object of the following shape :
{
  facet: facet on which your Select depends on,
  placeholder: custom placeholder to indicate why the Select is disabled (waiting for the other facet)
}

Tips: Use a const object as dependsOn (prevents unwanted re-render)

 */
const DependsOnSelect = ({ className, facet, searchScope, value, onChange, placeholder, isMulti = true, hideCount = true, disabled = false, dependsOn = EMPTY_OBJECT }) => {
  const draftParams = useSelector(state => getEffectiveDraftParams(state, { searchScope }));
  const isDisabled = dependsOn.facet && !draftParams?.[dependsOn.facet];

  return (
    <Select
      className={className}
      facet={facet}
      searchScope={searchScope}
      value={value}
      onChange={onChange}
      placeholder={isDisabled ? dependsOn.placeholder : placeholder}
      isMulti={isMulti}
      hideCount={hideCount}
      disabled={disabled || isDisabled}
    />
  );
};

export default DependsOnSelect;
