import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../redux/selectors/dbEntities';

const Artist = ({ id }) => {
  const artist = useSelector(state => getArtist(state, { id }));

  if (!artist) return null;

  return (
    <a href={artist.url} target="sample">
      {i18next.t('lots.no_access.m9').replace('%artist%', artist.name)}
    </a>
  );
};

export default Artist;
