import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import Menu from '../index';
import { get } from '../../../../../../redux/selectors/ui/dictionary';
import { DISPLAY_HEADER_MENU } from '../../../../../../constants/layout';
import { updateDictionary } from '../../../../../../redux/actions/ui/dictionary';

import './stylesheet.scss';

const MobileContainer = ({ locale, height100, setHeight100 }) => {
  const dispatch = useDispatch();
  const open = useSelector(state => get(state, { id: DISPLAY_HEADER_MENU }));
  const handlers = useSwipeable({
    onSwipedUp: () => dispatch(updateDictionary(DISPLAY_HEADER_MENU, false)),
    preventDefaultTouchmoveEvent: true,
  });

  useLayoutEffect(() => {
    if (open) {
      setHeight100(true);
    }
  }, [open, setHeight100]);

  return (
    <div
      className={classNames('mobile-menu-container', 'flex-filler', { open })}
      onTransitionEnd={e => {
        if (!open && e.target === e.currentTarget) {
          setHeight100(false);
        }
      }}
      {...handlers}
    >
      {(open || height100) && <Menu locale={locale} mobile />}
    </div>
  );
};

export default MobileContainer;
