import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_DEMO, ANALYTICS_FROM_HOMEPAGE, ID_FREE_ARTIST } from '../../../constants/rails';
import { getSubscriptionLevel } from '../../../redux/slices/userContext/selectors';
import { getArtist } from '../../../redux/selectors/dbEntities';
import { aapiBeacon } from '../../../services/analytics/aapi';
import '../../common/stylesheets/buttons.scss';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const FreeDemo = () => {
  const freeArtist = useSelector(state => getArtist(state, { id: ID_FREE_ARTIST }));
  const subscriptionLevel = useSelector(getSubscriptionLevel);

  if (subscriptionLevel) return null;

  return (
    <div className="homepage-advantages block">
      <div className="container">
        <h2 className="title">
          <A18n rsx="homepage.advantages.m1" />
          <div className="sub-h2">
            <A18n rsx="homepage.advantages.m2" replace={[['%artist%', freeArtist?.name]]} />
          </div>
        </h2>

        <div className="radius advantage-block">
          <div className="l1">
            <A18n rsx="homepage.advantages.m3" />
          </div>
          <div className="l2">{freeArtist?.name}</div>
          <div className="l3">
            <a href={freeArtist?.url} className="artp-btn artp-btn-transparent" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_DEMO })}>
              <A18n rsx="homepage.advantages.m4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeDemo;
