import React from 'react';
import i18next from 'i18next';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../../redux/selectors/ui/dictionary';
import { set } from '../../../redux/actions/ui/dictionary';
import { login } from '../../../services/wallet/customer';
import ErrorBlock from '../ErrorBlock';
import '../stylesheets/form.scss';
import Modal from '../ui/Modal';
import './stylesheet.scss';
import A18n from '../A18n';

function onFormError(e) {
  Sentry.captureMessage('[form_confirm_identity] Validation failed', {
    extra: {
      errors: e,
    },
  });
}

export const LOGIN_MODAL_OPEN_CONSTANT = 'LOGIN_MODAL_OPEN_CONSTANT';

const LoginModal = ({ onSuccess, onError, title, children }) => {
  const openIdentification = useSelector(state => get(state, { id: LOGIN_MODAL_OPEN_CONSTANT }));
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = values => {
    login({ login: values.email, pass: values.password }).then(data => {
      const { error } = data;
      if (error) {
        // eslint-disable-next-line no-console
        console.log('error', error, values);
        onError?.();
      } else {
        onSuccess?.();
        dispatch(set(LOGIN_MODAL_OPEN_CONSTANT, false));
      }
    });
  };

  if (!openIdentification) return null;

  return (
    <Modal className="common-login-modal common-stylesheets-forms" onClose={() => dispatch(set(LOGIN_MODAL_OPEN_CONSTANT, false))} open={openIdentification}>
      <div className="left">
        <h1>{title}</h1>
        <form onSubmit={handleSubmit(onSubmit, onFormError)}>
          <div className="group">
            <div className="cell">
              <input className="form-control" type="text" {...register('email')} placeholder={i18next.t('common.loginmodal.m1')} />
              <ErrorBlock error={errors.email} />
            </div>
          </div>

          <div className="group">
            <div className="cell">
              <input className="form-control" type="password" {...register('password')} placeholder={i18next.t('common.loginmodal.m2')} />
              <ErrorBlock error={errors.password} />
            </div>
          </div>

          <div className="group">
            <div className="cell right">
              <a href="/account/forgot_password">
                <A18n rsx="common.loginmodal.m3" />
              </a>
            </div>
          </div>

          <button type="submit">
            <A18n rsx="common.loginmodal.m4" />
          </button>
        </form>
      </div>
      {children && <div className="right">{children}</div>}
    </Modal>
  );
};

export default LoginModal;
