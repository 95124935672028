import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { DEFAULT_CURRENCIES, OPTIONAL_CURRENCIES } from '../../../../constants/preferences';
import './stylesheet.scss';

const Currencies = ({ value, onChange, withOptionalCurrencies }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [currencies, setCurrencies] = useState(DEFAULT_CURRENCIES);

  useEffect(() => {
    setCurrencies({ ...DEFAULT_CURRENCIES, ...(withOptionalCurrencies ? OPTIONAL_CURRENCIES : {}) });
  }, [withOptionalCurrencies]);

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleClick = curr => {
    setInternalValue(curr.iso3);
    onChange(curr.iso3);
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.display.m1')}</span>
      <div className="btn-group">
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          {!currencies[internalValue]?.src && currencies[internalValue]?.iso1}
          {currencies[internalValue]?.src && <img style={{ height: 15, marginTop: -4 }} src={currencies[internalValue]?.src} alt={currencies[internalValue]?.iso1} />}
          <span className="caret" style={{ marginLeft: 5 }} />
        </button>
        <ul className="dropdown-menu">
          {Object.values(currencies).map(c => (
            <li key={c.iso1}>
              <a onClick={() => handleClick(c)}>
                {!c.src && c.iso1}
                {c.src && <img style={{ height: 15, marginTop: -4 }} src={c.src} alt={c.iso1} />}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Currencies;
