import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Years from '../../../inputs/Years';

const CreationYear = () => (
  <Item label={i18next.t('components.search.helpers.items.creation_year.m1')} name={{ year_from: 'from', year_to: 'to' }}>
    <Years />
  </Item>
);

export default CreationYear;
