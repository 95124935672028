const category = Object.freeze({
  PAINTING: 1,
  PRINT: 2,
  SCULPTURE_VOLUME: 5,
  PHOTOGRAPHY: 6,
  DRAWING_WATERCOLOR: 7,
  TAPESTRY: 8,
  MINIATURE: 9,
  AUDIOVISUAL_MULTIMEDIA: 10,
  PERFORMING_ART: 11,
  DESIGN: 12,
  CERAMIC: 13,
  FURNITURE: 14,
  LIGHTINGS: 15,
  CERAMIC_GLASS: 16,
  ARCHITECTURAL_ITEMS: 17,
  OBJECTS: 18,
  DECORATIVE: 19,
});

export default category;
