import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME, LOTS_OFFICIAL_COUNT } from '../../../../constants/rails';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { useViewport } from '../../../../hooks/useViewport';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Estimate = () => {
  const intl = useIntl();
  const { from } = useSelector(getSettings);
  const { viewportWidth } = useViewport();
  return (
    <div className="subscriptions-list-estimate">
      <div className="container">
        <h2>
          <A18n rsx="subscriptions.list.m5" />
        </h2>
        <p>
          <A18n rsx="subscriptions.list.m6" trustHtml />
        </p>
        {viewportWidth >= 768 && (
          <p>
            <A18n rsx="subscriptions.list.m7" replace={[['%LOTS_OFFICIAL_COUNT%', intl.formatNumber(LOTS_OFFICIAL_COUNT)]]} />
          </p>
        )}
        <button
          type="button"
          className="artp-btn"
          onClick={() => {
            aapiBeacon('click', {
              from,
              op: ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME,
            });

            window.location = '/estimate';
          }}
        >
          <A18n rsx="subscriptions.list.m8" />
        </button>
      </div>
    </div>
  );
};
export default Estimate;
