import { internalAxios } from '../../utils/axios/internal';

export const accept = async () => {
  const response = await internalAxios.get('/api/auctioneers/agreement/accept');
  return response.data;
};

export const contact = async () => {
  const response = await internalAxios.get('/api/auctioneers/agreement/contact');
  return response.data;
};

export const incDisplayCount = async () => {
  const response = await internalAxios.get('/api/auctioneers/agreement/display_count');
  return response.data;
};
