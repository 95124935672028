import React from 'react';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import { a18n } from '../../../../utils/a18n';
import A18n from '../../../common/A18n';

const Marketplace = () => (
  <div className="account-signin-confirmation-marketplace">
    <div className="collect" style={{ backgroundImage: `url('${IMAGE_SOURCE}account/registration-buy.jpg')` }}>
      <h2>
        <A18n rsx="account.signinconfirmation.marketplace.m1" />
      </h2>
      <p>
        <A18n rsx="account.signinconfirmation.marketplace.m2" />
      </p>

      <div className="marketplace-action">
        <a href={`/${a18n('routes.marketplace')}`} className="artp-btn artp-btn-transparent">
          <A18n rsx="account.signinconfirmation.marketplace.m3" />
        </a>
      </div>
    </div>
    <div className="sell" style={{ backgroundImage: `url('${IMAGE_SOURCE}account/registration-sell.jpg?t=s')` }}>
      <h2>
        <A18n rsx="account.signinconfirmation.marketplace.m4" />
      </h2>
      <p>
        <A18n rsx="account.signinconfirmation.marketplace.m5" />
      </p>
      <div className="marketplace-action">
        <a href={`/${a18n('routes.marketplace')}/how-to-sell`} className="artp-btn artp-btn-transparent">
          <A18n rsx="account.signinconfirmation.marketplace.m6" />
        </a>
      </div>
    </div>
  </div>
);

export default Marketplace;
