/* eslint-disable global-require */
module.exports = [
  ...require('./Reporting/i18n'),
  ...require('./HowToBid/Faq/Fees/i18n'),
  ...require('./stores/i18n'),
  ...require('./common/i18n'),
  ...require('./SearchBar/i18n'),
  ...require('./Classified/i18n'),
  ...require('./HowToBid/i18n'),
];
