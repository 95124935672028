import React from 'react';
import './stylesheet.scss';
import A18n from '../../../../../../common/A18n';

const Failure = ({ error, errorDetails, onClose }) => (
  <div className="account-lots-lists-add-to-list-button-create-content-failure">
    <p className="marg marg-t-5">
      <i className="fa fa-exclamation-triangle" />
    </p>
    <p className="marg marg-t-15" style={{ color: '#900' }}>
      {error}
      {errorDetails && ` (${errorDetails})`}
    </p>
    <p>
      <i className="fa fa-arrow-left" />
      <a href="#" onClick={onClose}>
        <A18n rsx="components.lotslists.create.success.m3" />
      </a>
    </p>
  </div>
);

export default Failure;
