import React, { useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import i18next from 'i18next';

const CountDown = ({ from = Date.now(), to, shortFormat = false }) => {
  const [d, setDuration] = useState(
    intervalToDuration({
      start: new Date(from),
      end: new Date(to),
    }),
  );

  useEffect(() => {
    const timer = setInterval(
      () =>
        setDuration(
          intervalToDuration({
            start: new Date(from),
            end: new Date(to),
          }),
        ),
      1000,
    );
    return () => clearInterval(timer);
  });

  if (shortFormat) {
    return (
      <span>
        {d.days > 0 && `${d.days} ${i18next.t('marketplace.auction._dates.html.m2')} `}
        {(d.days > 0 || d.hours > 0) && `${d.hours} ${i18next.t('marketplace.auction._dates.html.m3')} `}
        {(d.days > 0 || d.hours > 0 || d.minutes > 0) && `${d.minutes} ${i18next.t('marketplace.auction._dates.html.m4')} `}
        {`${d.seconds} ${i18next.t('marketplace.auction._dates.html.m5')}`}
      </span>
    );
  }

  return (
    <span>
      {d.days > 1 && `${d.days} ${i18next.t('marketplace.auction._countdown.html.m1')}, `}
      {d.days === 1 && `${d.days} ${i18next.t('marketplace.auction._countdown.html.m2')}, `}

      {d.hours > 1 && `${d.hours} ${i18next.t('marketplace.auction._countdown.html.m3')}, `}
      {d.hours === 1 && `${d.hours} ${i18next.t('marketplace.auction._countdown.html.m4')}, `}

      {d.minutes > 1 && `${d.minutes} ${i18next.t('marketplace.auction._countdown.html.m5')}, `}
      {d.minutes === 1 && `${d.minutes} ${i18next.t('marketplace.auction._countdown.html.m6')}, `}

      {d.seconds > 1 && `${d.seconds} ${i18next.t('marketplace.auction._countdown.html.m7')}`}
      {d.seconds <= 1 && `${d.seconds} ${i18next.t('marketplace.auction._countdown.html.m8')}`}
    </span>
  );
};

export default CountDown;
