import React, { memo, useMemo } from 'react';
import Observer from '../../../common/Observer';
import { aapiBeacon } from '../../../../services/analytics/aapi';

const intersections = []; // works because we are not in a SPA
const handleIntersect = ({ entries, payload }) => {
  entries.forEach(entry => {
    const { id } = payload;
    if (entry.isIntersecting && !intersections.includes(id)) {
      aapiBeacon('classified_view', { idclassified: id, view: 'homepage' });
      intersections.push(id);
    }
  });
};

const TopObserver = ({ ids }) => {
  const selectors = useMemo(
    () =>
      ids.map(id => ({
        selector: `.classified-${id}`,
        payload: {
          id,
        },
      })),
    [ids],
  );

  if (ids.length < 1) {
    return null;
  }
  return <Observer selectors={selectors} handleIntersect={handleIntersect} />;
};

export default memo(TopObserver);
