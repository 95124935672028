import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTIST_ARTICLES, ANALYTICS_FROM_ARTIST } from '../../../../constants/rails';
import { getArticles, getArtist } from '../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Articles = () => {
  const articles = useSelector(getArticles);
  const artist = useSelector(getArtist);
  const { articlesRef, hideLeftMenu } = useContext(UiContext);

  if (isEmpty(articles?.items)) return null;

  return (
    <div className={`artists-artist-articles ${hideLeftMenu ? 'block-no-menu' : 'block'}`} ref={articlesRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.articles.m1" />
        </h2>
        <p className="sub-title">
          {articles.items.length === 1 && <A18n rsx="artists.artist.articles.m1" replace={[['%artist%', artist.name]]} />}
          {articles.items.length > 1 && (
            <A18n
              rsx="artists.artist.articles.m2"
              replace={[
                ['%nbarticles%', articles.totalCount],
                ['%artist%', artist.name],
              ]}
            />
          )}
        </p>
        <div className="articles">
          {articles.items.slice(0, 3).map(({ slug, title, featuredImage, content }) => (
            <a href={`/artmarketinsight/${slug}`} key={slug} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, idartist: artist.id, slug })}>
              <div className="article">
                <div className="picto">
                  <img src={featuredImage} alt={title} />
                </div>
                <div className="content">
                  <h3 dangerouslySetInnerHTML={{ __html: title }} />
                  <div className="txt" dangerouslySetInnerHTML={{ __html: content }} />
                  <div className="more">
                    <A18n rsx="artists.artist.articles.m4" />
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
        <div className="actions">
          <a
            href={articles.url}
            className="artp-btn artp-btn-artist"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTIST_ARTICLES, idartist: artist.id })}
          >
            <A18n rsx="artists.artist.articles.m3" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Articles;
