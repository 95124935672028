import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getLot } from '../../../../redux/selectors/dbEntities';
import ArtistComponent from '../../../common/dbEntitiesSmallViews/Artist';
import './stylesheet.scss';
import { getCurrency } from '../../../../redux/selectors/preferences';
import Sale from './Sale';
import { CURRENCIES } from '../../../../constants/preferences';

const Lot = ({ intl, id, ...otherProps }) => {
  const [imgLoading, setLoading] = useState(true);
  const currency = useSelector(getCurrency);
  const lot = useSelector(state => getLot(state, { id }));

  return (
    <div className={`total-access-lot ${imgLoading ? 'load' : ''}`.trim()} {...otherProps}>
      {lot && (
        <>
          <div className="lot-img">
            <a href={lot.url}>
              {!lot.imageUrls.sml[0] && (
                <div className="no-pict">
                  <i className="fa fa-picture-o fa-3x" />
                </div>
              )}
              {lot.imageUrls.sml[0] && <img alt="" className="shadow" src={lot.imageUrls.sml[0].replace('/sml', '/xl')} onLoad={() => setLoading(false)} />}
            </a>
          </div>
          {lot.totalAccessPriceWithTaxes && (
            <div className="lot-price">
              <a className="artdb-link" href={`http://artdb/artdb/lot/show/${id}`} target="_blank" rel="noopener noreferrer" title="Artdb">
                <i className="fa fa-external-link" />
              </a>
              {intl.formatNumber(lot.totalAccessPriceWithTaxes[currency], { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </div>
          )}
          <div className="lot-title">
            <a href={lot.url}>{lot.title}</a>
            <span>
              {lot.creationDate && String(lot.creationDate).trim() !== '' && ' - '}
              {lot.creationDate}
            </span>
          </div>
          {lot?.idartist && (
            <div className="lot-artist">
              <ArtistComponent id={lot.idartist} withLink withDates={false} />
            </div>
          )}
          {lot?.idsale && <Sale id={lot.idsale} withCity withCountry />}
        </>
      )}
    </div>
  );
};

export default injectIntl(Lot);
