import React from 'react';
import i18next from 'i18next';
import { Col, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPageFilter, getPasts, getPastsEventsPagesCount, getPastsNotPartners, getPastsNotPartnersTotalCount } from '../../../../../redux/selectors/events';
import { fetchPastNotPartnerEventsAction } from '../../../../../redux/actions/events';
import Pagination from '../../FiltersBar/Pagination';
import NotPartnerItem from '../../NotPartnerItem';
import Item from '../../Item';

const List = ({ choosePage }) => {
  const dispatch = useDispatch();
  const pasts = useSelector(getPasts);
  const pastsNotPartners = useSelector(getPastsNotPartners);
  const pageFilter = useSelector(getPageFilter);
  const pagesCount = useSelector(getPastsEventsPagesCount);
  const pastsNotPartnersTotalCount = useSelector(getPastsNotPartnersTotalCount);

  return (
    <Container className="marg marg-b-25">
      {pasts.length > 0 && (
        <Col sm={pastsNotPartners.length > 0 ? 9 : 12} className={pastsNotPartners.length > 0 ? 'pad pad-r-35' : ''}>
          <Pagination choose={choosePage} currentPage={pageFilter} pagesCount={pagesCount} />

          <>
            <h2 className="events-categories-title marg marg-t-20 marg-b-20">
              <span>{i18next.t('events.list.pasts.partners.title.past')}</span>
            </h2>

            <div className="events-list">
              {pasts.map((event, position) => (
                <Item key={`#${event.id}-${event.title}`} event={event} position={position + 1} />
              ))}
            </div>
          </>

          <div className="text-center">
            <Pagination choose={choosePage} currentPage={pageFilter} pagesCount={pagesCount} />
          </div>

          <h3>
            <small>
              <a href="/eventweb/past">{i18next.t('events.list.link.eventweb')}</a>
            </small>
          </h3>
        </Col>
      )}

      {pastsNotPartners.length > 0 && (
        <Col sm={pasts.length > 0 ? 3 : 12} className={`not-partner-events${pasts.length <= 0 ? ' no-border' : ''}`}>
          <div className="events-list">
            {pastsNotPartners.map(event => (
              <NotPartnerItem key={`#${event.id}-${event.title}`} noLink event={event} />
            ))}
            {pastsNotPartners.length < pastsNotPartnersTotalCount && (
              <button type="button" className="clickable-icon" onClick={() => dispatch(fetchPastNotPartnerEventsAction({ offset: pastsNotPartners.length }))}>
                <i className="fa fa-arrow-circle-down fa-2x" />
              </button>
            )}
          </div>
        </Col>
      )}
    </Container>
  );
};

export default List;
