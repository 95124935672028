/* eslint-disable global-require */
import React from 'react';
import Root from './Root';

function getAbsoluteRoutePath(parentPath, currentPath) {
  if (currentPath.startsWith('/')) {
    return currentPath;
  }
  return `${parentPath}/${currentPath}`.replaceAll('//', '/');
}

function mapHandles(routes, parentPath) {
  return routes.map(route => {
    const newRoute = {
      ...route,
      handle: {
        ...route.handle,
        relativeRoutePath: route.path,
        absoluteRoutePath: getAbsoluteRoutePath(parentPath, route.path),
      },
    };

    if (route.children) {
      newRoute.children = mapHandles(route.children, getAbsoluteRoutePath(parentPath, route.path));
    }

    return newRoute;
  });
}

export default function createRouter(routes) {
  if (window.onServer) {
    // Do it only if you need it (complicated)
    // Check https://reactrouter.com/en/6.22.1/guides/ssr if you ever want to do it
    throw new Error("createRouter method can't work on server (for now)");
  } else {
    const { createBrowserRouter } = require('react-router-dom');

    return createBrowserRouter(
      [
        {
          path: '/',
          element: <Root />,
          children: mapHandles(routes, '/'),
          handle: {
            relativeRoutePath: '/',
            absoluteRoutePath: '/',
            root: true,
          },
        },
      ],
      {
        future: {
          v7_fetcherPersist: true,
          v7_normalizeFormMethod: true,
          v7_partialHydration: true,
          v7_relativeSplatPath: true,
        },
      },
    );
  }
}
