/* eslint-disable */
import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { getGeographicalDisplayedChartTab } from '../../../../../redux/selectors/indexes/geographical';
import { getCurrency } from '../../../../../redux/selectors/preferences';
import { TAB_CA } from '../../../../../constants/indexes/geographical';
import { CURRENCIES } from '../../../../../constants/preferences';
import './stylesheet.scss';

const CustomToolTip = ({ payload }) => {
  const currency = useSelector(getCurrency);
  const chartTab = useSelector(getGeographicalDisplayedChartTab);

  if (!payload || payload.length < 1) return null;

  const { fullName, value } = payload[0].payload;
  return (
    <div className="indexes-geographic-barchart-customtooltip">
      <div>{fullName}</div>

      <div className="value">
        {chartTab === TAB_CA ? i18next.t('indexes._first_chart.m3') : i18next.t('indexes._first_chart.m4')}:{' '}
        {chartTab === TAB_CA ? <FormattedNumber value={value} style="currency" currency={CURRENCIES[currency].iso3ForIntl} minimumFractionDigits={0} /> : <FormattedNumber value={value} />}
      </div>
    </div>
  );
};

export default CustomToolTip;
