import { injectIntl } from 'react-intl';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSale } from '../../../../redux/selectors/dbEntities';
import { progress, push, show } from '../../../../redux/selectors/auctioneers/sales';
import { getSalesProgress, getSalesPush, getSalesShow } from '../../../../redux/actions/auctioneers/sales';
import { set } from '../../../../redux/actions/ui/dictionary';
import { ACTIVE_MENU } from '../../../../constants/auctioneers';
import './stylesheet.scss';
import { ANALYTICS_FROM_AUCTIONEERS_SALE, ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT, ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';

const from = ANALYTICS_FROM_AUCTIONEERS_SALE;

const Header = ({ id, intl }) => {
  const dispatch = useDispatch();
  const sale = useSelector(state => getSale(state, { id }));
  const pushCount = useSelector(state => push(state, { id }));
  const showCount = useSelector(state => show(state, { id }));
  const { datas } = useSelector(state => progress(state, { id }));

  useEffect(() => {
    if (sale) {
      dispatch(set(ACTIVE_MENU, 0));
      dispatch(getSalesPush({ ids: [sale.id] }));
      dispatch(getSalesShow({ ids: [sale.id] }));
      dispatch(getSalesProgress({ ids: [sale.id] }));
    }
  }, [sale]);

  if (!sale) {
    return (
      <div className="auctioneers-sale-header">
        <div className="container">
          <div className="spinner container">
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="auctioneers-sale-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="header">
              <h1>{sale.title}</h1>
            </div>
            <div className="content">
              {sale.cover !== '' && (
                <div className="cover">
                  <img className="shadow" src={sale.cover} alt={sale.title} />
                </div>
              )}
              <div className="infos">
                <div className="date">{sale.dt}</div>
                <div className="location">
                  {sale.city}
                  {sale.country && sale.city && ', '}
                  {sale.country}
                </div>
                <div className="filler" />
                {pushCount > 0 && <div className="push">{i18next.t('auctioneers.sale.header.m1').replace('%pushCount%', intl.formatNumber(pushCount))}</div>}
                {showCount > 0 && <div className="show">{i18next.t('auctioneers.sale.header.m2').replace('%showCount%', intl.formatNumber(showCount))}</div>}
              </div>
              <div className="contact">
                <div>
                  <Link to={`/auctioneer/control-center/contact/${sale.id}`} onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT, idsale: sale.id })}>
                    <i className="fa fa-envelope-o marg marg-r-5" />
                    {i18next.t('auctioneers.sale.header.m3')}
                  </Link>
                </div>
                {!datas && (
                  <div className="marg marg-t-10">
                    <Link to="/auctioneer/control-center/docs" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA, idsale: sale.id })}>
                      <i className="fa fa-exclamation-triangle marg marg-r-5" />
                      {i18next.t('auctioneers.futuresales.sale.m4')}
                    </Link>
                  </div>
                )}
                {sale.isPast && !sale.resultsProcessed && (
                  <div className="marg marg-t-10">
                    <Link to="/auctioneer/control-center/docs" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA, idsale: sale.id })}>
                      <i className="fa fa-exclamation-triangle marg marg-r-5" />
                      {i18next.t('auctioneers.sale.header.m4')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Header);
