import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_SECTION_SUBSCRIPTIONS_CARD,
  MODULE_EXPORT_PDF,
  MODULE_FUTURESALE,
  MODULE_IMAGE,
  MODULE_INDEXES,
  MODULE_INDICATOR,
  MODULE_PASTSALE,
  MODULE_STORE,
  THEME_BASICS_STORE,
  THEME_PROFESSIONAL,
  THEME_STORE,
} from '../../../../../../../constants/rails';
import { updateSettings } from '../../../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../../../redux/selectors/subscriptions/settings';
import FlipSwitch from '../../../../../../common/ui/FlipSwitch';
import useAnalytics from '../../../../common/useAnalytics';
import A18n from '../../../../../../common/A18n';
import '../stylesheet.scss';

const Marketplace = ({ theme, physicproduct }) => {
  const dispatch = useDispatch();
  const { withoutImagePriceProduct } = useSelector(getSettings);
  const { toggleImage: toggleImageAnalytics } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_CARD });

  if (theme.idtheme === THEME_STORE) {
    return (
      <div className="components">
        <ul>
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m1" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m2" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m16" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m3" />
          </li>
          <li
            className={classNames({
              artpricestore: true,
              [`mod-${MODULE_STORE}`]: true,
              active: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m1')}
          </li>
          <li
            className={classNames({
              artpricestore: true,
              [`mod-${MODULE_STORE}`]: true,
              active: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m2')}
          </li>
          <li
            className={classNames({
              artpricestore: true,
              [`mod-${MODULE_STORE}`]: true,
              active: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m4')}
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="components">
      <ul className="subscriptions-list-cards-card-details-store">
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m1" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m2" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m16" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.card.details.data.m3" />
        </li>
        <li>{i18next.t('subscriptions.list.cards.card.details.marketplace.m1')}</li>
        <li>{i18next.t('subscriptions.list.cards.card.details.marketplace.m2')}</li>
        <li>{i18next.t('subscriptions.list.cards.card.details.marketplace.m4')}</li>
        <li
          className={classNames({
            [theme.theme_ref]: true,
            active: theme.idtheme === THEME_BASICS_STORE,
            [`mod-${MODULE_FUTURESALE}`]: true,
          })}
        >
          {i18next.t('subscriptions.list.cards.card.details.marketplace.m7')}
        </li>
        {theme.module.includes(MODULE_PASTSALE) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.idtheme === THEME_BASICS_STORE,
              [`mod-${MODULE_PASTSALE}`]: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m5')}
          </li>
        )}
        {theme.module.includes(MODULE_FUTURESALE) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.idtheme === THEME_BASICS_STORE,
              [`mod-${MODULE_FUTURESALE}`]: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m6')}
          </li>
        )}
        <li
          className={classNames({
            [theme.theme_ref]: true,
            active: theme.idtheme === THEME_BASICS_STORE && !withoutImagePriceProduct.includes(physicproduct?.idp),
            [`mod-${MODULE_IMAGE}`]: true,
            strike: withoutImagePriceProduct.includes(physicproduct?.idp),
          })}
        >
          {i18next.t('subscriptions.list.cards.card.details.marketplace.m8')}
          <br />
          <FlipSwitch
            className={classNames()}
            label={<A18n rsx="subscriptions.list.cards.card.details.data.m15" />}
            onChange={() => {
              if (physicproduct?.idp) {
                toggleImageAnalytics({ checked: !withoutImagePriceProduct.includes(physicproduct?.idp), slug: physicproduct.reference });

                dispatch(
                  updateSettings({
                    data: {
                      withoutImagePriceProduct: withoutImagePriceProduct.includes(physicproduct.idp) ? withoutImagePriceProduct.filter(id => id !== physicproduct.idp) : [...withoutImagePriceProduct, physicproduct.idp],
                    },
                  }),
                );
              }
            }}
            checked={!withoutImagePriceProduct.includes(physicproduct?.idp)}
          />
        </li>

        {theme.module.includes(MODULE_INDEXES) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.idtheme === THEME_PROFESSIONAL,
              [`mod-${MODULE_INDEXES}`]: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m9')}
          </li>
        )}
        {theme.module.includes(MODULE_INDICATOR) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.idtheme === THEME_PROFESSIONAL,
              [`mod-${MODULE_INDICATOR}`]: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m10')}
          </li>
        )}
        {theme.module.includes(MODULE_EXPORT_PDF) && (
          <li
            className={classNames({
              [theme.theme_ref]: true,
              active: theme.idtheme === THEME_PROFESSIONAL,
              [`mod-${MODULE_EXPORT_PDF}`]: true,
            })}
          >
            {i18next.t('subscriptions.list.cards.card.details.marketplace.m11')}
            <i className="marg marg-l-5 fa fa-file-pdf-o" />
          </li>
        )}
      </ul>
    </div>
  );
};

export default Marketplace;
