import React, { useState } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { ID_FREE_ARTIST, MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE } from '../../../../../../constants/rails';
import { getArtist } from '../../../../../../redux/selectors/dbEntities';

const styleVisible = { maxHeight: 150, paddingTop: 5 };
const styleHidden = { maxHeight: 0, paddingTop: 0 };

const Data = ({ theme }) => {
  const [visibleSection, setVisibleSection] = useState();
  const freeArtist = useSelector(state => getArtist(state, { id: ID_FREE_ARTIST }));
  const modules = theme.module.concat(theme.module_additional);

  const handleVisibleSection = id => {
    if (visibleSection === id) {
      setVisibleSection(null);
    } else {
      setVisibleSection(id);
    }
  };

  return (
    <>
      <div className="module-container">
        <div className="module no-border">
          <div className="module-label" onClick={() => handleVisibleSection(0)}>
            {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m1')}
            {visibleSection === 0 && <i className="fa fa-angle-down" />}
            {visibleSection !== 0 && <i className="fa fa-angle-right" />}
          </div>
          <div className="module-details" style={visibleSection === 0 ? styleVisible : styleHidden}>
            {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m2')}{' '}
            <a target="sample" href="/how-to-search">
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m3')}
            </a>
          </div>
        </div>
      </div>
      {modules.includes(MODULE_IMAGE) && (
        <div className="module-container">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_IMAGE)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m4')}
              {visibleSection === MODULE_IMAGE && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_IMAGE && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_IMAGE ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m5')}
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_PASTSALE) && (
        <div className="module-container">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_PASTSALE)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m6')}
              {visibleSection === MODULE_PASTSALE && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_PASTSALE && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_PASTSALE ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m7')}{' '}
              <a target="sample" href={freeArtist?.urlPast}>
                {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m8')}
              </a>
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_FUTURESALE) && (
        <div className="module-container">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_FUTURESALE)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m9')}
              {visibleSection === MODULE_FUTURESALE && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_FUTURESALE && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_FUTURESALE ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m10')}{' '}
              <a target="sample" href={freeArtist?.urlFuture}>
                {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m8')}
              </a>
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_INDEXES) && (
        <div className="module-container active">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_INDEXES)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m11')}
              {visibleSection === MODULE_INDEXES && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_INDEXES && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_INDEXES ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m12')}{' '}
              <a target="sample" href={freeArtist?.urlIndexes}>
                {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m8')}
              </a>
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_INDICATOR) && (
        <div className="module-container active">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_INDICATOR)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m13')}
              {visibleSection === MODULE_INDICATOR && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_INDICATOR && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_INDICATOR ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m14')}{' '}
              <a target="sample" href={`${freeArtist?.urlIndicator}#anchor`}>
                {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m8')}
              </a>
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_EXPORT_PDF) && (
        <div className="module-container active">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_EXPORT_PDF)}>
              {i18next.t('subscriptions.purchase.list.theme.details.data.m8')}
              {visibleSection === MODULE_EXPORT_PDF && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_EXPORT_PDF && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_EXPORT_PDF ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m17')}{' '}
              <a target="sample" href="/tutorials/lotslists">
                {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m8')}
              </a>
            </div>
          </div>
        </div>
      )}
      {modules.includes(MODULE_STORE) && (
        <div className="module-container active">
          <div className="module">
            <div className="module-label" onClick={() => handleVisibleSection(MODULE_STORE)}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m15')}
              {visibleSection === MODULE_STORE && <i className="fa fa-angle-down" />}
              {visibleSection !== MODULE_STORE && <i className="fa fa-angle-right" />}
            </div>
            <div className="module-details" style={visibleSection === MODULE_STORE ? styleVisible : styleHidden}>
              {i18next.t('subscriptions.purchase.list.theme-details-modale.data.m16')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Data;
