import { IntlProvider } from 'react-intl';
import React from 'react';
import Faq from '..';

const FaqProvided = ({ lang = 'en', fullFaq }) => (
  <IntlProvider locale={lang}>
    <Faq lang={lang} fullFaq={fullFaq} />
  </IntlProvider>
);

export default FaqProvided;
