import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const ArtistCountrySelect = () => (
  <Item label={i18next.t('components.search.helpers.items.artist_country.m1')} name="artist_idcountry">
    <Select facet="artist_idcountry" placeholder={i18next.t('components.search.helpers.items.artist_country.m1')} />
  </Item>
);

export default ArtistCountrySelect;
