import React from 'react';
import './stylesheet.scss';
import Spinner from '../../../../common/spinners/Spinner';

const Skeleton = () =>
  Array(3)
    .fill(0)
    .map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="subscriptions-list-cards-skeleton" key={`skeleton-${index}`}>
        <Spinner className="spin" />
      </div>
    ));

export default Skeleton;
