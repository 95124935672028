import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';
import { get } from '../../../../../../redux/selectors/ui/dictionary';
import { updateDictionary } from '../../../../../../redux/actions/ui/dictionary';
import { DISPLAY_HEADER_MENU } from '../../../../../../constants/layout';

import './stylesheet.scss';

const Burger = () => {
  const dispatch = useDispatch();
  const displayHeaderMenu = useSelector(state => get(state, { id: DISPLAY_HEADER_MENU }));

  return (
    <button type="button" className="artp-Header-item artp-Header-Burger" onClick={() => dispatch(updateDictionary(DISPLAY_HEADER_MENU, !displayHeaderMenu))}>
      {displayHeaderMenu ? <img src={`${IMAGE_SOURCE}menu/cross_black.svg`} alt="closeMenu" /> : <img src={`${IMAGE_SOURCE}menu/burger_black.svg`} alt="openMenu" />}
    </button>
  );
};

export default Burger;
