import React from 'react';

import ScrollTo from '..';

const FromRails = ({ toId, title, beacon, childType, className }) => {
  const handleClick = () => {
    if (beacon) {
      window.artprice.aapiBeacon(beacon);
    }
  };

  return (
    <ScrollTo onClick={handleClick} className={className} childType={childType} toId={toId}>
      {title}
    </ScrollTo>
  );
};

export default FromRails;
