import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import List from '..';

const ProvidedList = ({ locale }) => (
  <ReduxProvider>
    <List locale={locale} />
  </ReduxProvider>
);

export default ProvidedList;
