import React, { Suspense, lazy } from 'react';
import Spinner from '../../../common/spinners/Spinner';
import A18n from '../../../common/A18n';

const LazyPaypal = lazy(() => import(/* webpackChunkName: "lazypaypal" */ './LazyPaypal'));

const Paypal = ({ options, idphysicsale }) => (
  <div>
    <p>
      <A18n rsx="payments.platforms.paypal.m1" />
    </p>
    <Suspense fallback={<Spinner />}>
      <LazyPaypal options={options} idphysicsale={idphysicsale} />
    </Suspense>
  </div>
);

export default Paypal;
