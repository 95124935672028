import { useSelector } from 'react-redux';
import { getIdcustomer } from '../../redux/slices/userContext/selectors';
import { getPromotionNotify } from '../../redux/selectors/userContext';

export function usePromotionNotify() {
  const idcustomer = useSelector(getIdcustomer);
  const promotionNotify = useSelector(getPromotionNotify);
  const displayCountSessionstorageKey = `promotionNotifyDisplayCount-${promotionNotify?.promocode}-${idcustomer}`;
  const displayCountLocaleStorageKey = `promotionNotifyDisplayCount-${promotionNotify?.promocode}-${idcustomer}`;

  return { promotionNotify, displayCountSessionstorageKey, displayCountLocaleStorageKey };
}
