/* eslint-disable global-require */
module.exports = [
  'components.myartprice.common.verticalbar.content.m10',
  'components.myartprice.common.verticalbar.content.m11',
  'components.myartprice.common.verticalbar.content.m3',
  'components.myartprice.common.verticalbar.content.m13',
  'components.myartprice.common.verticalbar.content.m14',
  'components.myartprice.common.verticalbar.content.m4',
  'components.myartprice.common.verticalbar.content.m5',
  'components.myartprice.common.verticalbar.content.m15',
  'components.myartprice.common.verticalbar.content.datas.m1',
  'components.myartprice.common.verticalbar.content.datas.m2',
];
