import React from 'react';
import { Outlet } from 'react-router-dom';

/* eslint-disable arrow-body-style */
const Root = () => {
  // const location = useLocation();
  // const matches = useMatches();
  // const navigation = useNavigation();

  // TODO plug in kactivities
  // console.log({
  //   navigation,
  //   location,
  //   matches,
  // });

  return <Outlet />;
};

export default Root;
