import React, { useEffect, useState } from 'react';
import { isElement, debounce } from 'lodash';

import './stylesheet.scss';

const UP = 'UP';
const DOWN = 'DOWN';

function getElement(selector) {
  if (typeof document !== 'undefined') {
    return document.querySelector(selector);
  }
}
function getScrollTop(containerElem) {
  return containerElem?.scrollTop || 0;
}

function getClientHeight(containerElem) {
  return containerElem?.clientHeight || 0;
}

function getScrollHeight(containerElem) {
  return containerElem?.scrollHeight || 0;
}

const BackToTopButton = ({ switchDirectionAt, top, bottom = '.bottom-bar', forceHandleResize, container = 'html' }) => {
  const [containerElem, setContainerElem] = useState(() => (isElement(container) ? container : getElement(container)));
  const [topElem, setTopElem] = useState(() => (isElement(top) ? top : undefined));
  const [bottomElem, setBottomElem] = useState(() => (isElement(bottom) ? bottom : undefined));
  const [visible, setVisible] = useState(false);
  const [direction, setDirection] = useState(DOWN);

  useEffect(() => {
    setContainerElem(isElement(container) ? container : getElement(container));
  }, [container]);

  useEffect(() => {
    setTopElem(isElement(top) ? top : getElement(top));
    setBottomElem(isElement(bottom) ? bottom : getElement(bottom));
  }, [top, bottom]);

  useEffect(() => {
    const handleResize = debounce(() => {
      const absoluteTopX = (topElem?.getBoundingClientRect()?.top || -getScrollTop(containerElem)) + getScrollTop(containerElem);
      const absoluteBottomX = (bottomElem?.getBoundingClientRect()?.top || getScrollHeight(containerElem) - getScrollTop(containerElem)) + getScrollTop(containerElem);
      setVisible(absoluteBottomX - absoluteTopX > getClientHeight(containerElem) + 30);
    }, 60);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [containerElem, topElem, bottomElem, forceHandleResize, setVisible]);

  useEffect(() => {
    const handleScrollEvent = debounce(
      () => {
        const topX = topElem?.getBoundingClientRect()?.top || -getScrollTop(containerElem);
        const bottomX = switchDirectionAt || (bottomElem?.getBoundingClientRect()?.top || getScrollHeight(containerElem) - getScrollTop(containerElem)) - getClientHeight(containerElem);

        if (Math.abs(topX) > bottomX) {
          setDirection(UP);
        } else {
          setDirection(DOWN);
        }
      },
      60,
      { maxWait: 180 },
    );

    const elementToAttachListener = container === 'html' ? window : containerElem;
    elementToAttachListener.addEventListener('scroll', handleScrollEvent);
    return () => elementToAttachListener.removeEventListener('scroll', handleScrollEvent);
  }, [containerElem, topElem, bottomElem, setDirection, switchDirectionAt]);

  if (!visible) return null;

  return (
    <div
      className="back-to-top-button"
      onClick={() => {
        if (direction === UP) {
          if (topElem) {
            topElem.scrollIntoView({ behavior: 'smooth' });
          } else {
            containerElem.scrollTo({ top: 0, behavior: 'smooth' });
          }
        } else if (bottomElem) {
          containerElem.scrollTo({ top: bottomElem.getBoundingClientRect().top + getScrollTop(containerElem) - getClientHeight(containerElem), behavior: 'smooth' });
        } else {
          containerElem.scrollTo({ top: getScrollHeight(containerElem) - getClientHeight(containerElem), behavior: 'smooth' });
        }
      }}
    >
      <i className={`fa fa-arrow-up ${direction === UP ? 'to-top' : 'to-bottom'}`} />
    </div>
  );
};

export default BackToTopButton;
