/* eslint-disable global-require */
module.exports = [
  'artists.artist.filters.display.m1',
  'artists.artist.filters.display.m2',
  'artists.artist.filters.display.m3',
  'artists.artist.filters.display.m4',
  'artists.artist.filters.display.m5',
  'artists.artist.filters.display.m6',
  'artists.artist.filters.display.m7',
];
