import React from 'react';
import './stylesheet.scss';
import A18n from '../../../../../../common/A18n';

const Success = ({ onClose }) => (
  <div className="account-lots-lists-add-to-list-button-create-content-success">
    <p className="marg marg-t-5">
      <i className="fa fa-check-circle" />
    </p>
    <p className="marg marg-t-15" style={{ color: '#555555' }}>
      <A18n rsx="components.lotslists.create.success.m1" />
    </p>
    <p>
      <a href="/account/lots-lists" className="btn btn-primary">
        <A18n rsx="components.lotslists.create.success.m2" />
      </a>
    </p>
    <p>
      <i className="fa fa-arrow-left" />
      <a href="#" onClick={onClose}>
        <A18n rsx="components.lotslists.create.success.m3" />
      </a>
    </p>
  </div>
);

export default Success;
