/* eslint-disable global-require */
module.exports = [
  /* Recherche un prix */
  'layouts.header.m50',
  'layouts.header.m51',
  'layouts.header.m52',
  'layouts.header.m53',
  'layouts.header.m54',
  'layouts.menu.m28',
  /* Consulter les ventes */
  'layouts.header.m55',
  'layouts.header.m56',
  'layouts.header.m57',
  /* Suivre l'actualité */
  'layouts.header.m59',
  'layouts.menu.m13',
  'layouts.menu.m14',
  'layouts.header.m60',
  'layouts.header.m61',
  'layouts.menu.nft',
  /* Marketplace */
  'layouts.menu.m6',
  'layouts.menu.m7',
  'layouts.menu.m8',
  'marketplace.index.m9',
  'layouts.header.m62',
  /* En coulisses */
  'layouts.header.m63',
  'layouts.header.m64',
  'layouts.header.m65',
  'layouts.header.m66',
  'layouts.header.m67',
  /* Autre */
  'routes.marketplace',
  'routes.sales_futures',
  'routes.marketplace_info',
];
