import React from 'react';
import classNames from 'classnames';
import './stylesheet.scss';

const Flip = ({ style, children, rotate = true }) => (
  <div className="flip-container" style={style}>
    <div className={classNames({ card: true, rotate })}>{children}</div>
  </div>
);

export default Flip;
