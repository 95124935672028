import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import zh from 'date-fns/locale/zh-CN';
import en from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { updateCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentDraftParams } from '../../../../redux/selectors/search';
import './stylesheet.scss';

const locales = { fr, en, de, zh, it, es };

const Dates = ({ inline = false }) => {
  registerLocale(i18next.language, locales[i18next.language]);
  const dispatch = useDispatch();
  const draftParams = useSelector(getCurrentDraftParams);

  const from = (
    <DatePicker
      maxDate={draftParams.dt_to && new Date(draftParams.dt_to)}
      showYearDropdown
      fixedHeight
      inline={inline}
      locale={i18next.language}
      onChange={date => dispatch(updateCurrentDraft({ triggeredParam: 'dt_from', value: format(date, 'yyyy-MM-dd') }))}
      selected={draftParams.dt_from && new Date(draftParams.dt_from)}
    />
  );

  const to = (
    <DatePicker
      minDate={draftParams.dt_from && new Date(draftParams.dt_from)}
      inline={inline}
      showYearDropdown
      fixedHeight
      locale={i18next.language}
      onChange={date => dispatch(updateCurrentDraft({ triggeredParam: 'dt_to', value: format(date, 'yyyy-MM-dd') }))}
      selected={draftParams.dt_to && new Date(draftParams.dt_to)}
    />
  );

  if (inline) {
    return (
      <div className="common-search-dates">
        <div className="artp-input-label">{i18next.t('filters.dates.m1')}</div>
        <div className="dates-container">
          <div className="from">{from}</div>
          <div className="to">{to}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="common-search-dates artp-input-group">
      <span className="artp-input-label">{i18next.t('filters.dates.m1')}</span>
      <div className="search-bar-input">
        <div className="cell first" style={{ width: '50%' }}>
          {from}
        </div>
        <div className="cell" style={{ width: '50%' }}>
          {to}
        </div>
      </div>
    </div>
  );
};

export default Dates;
