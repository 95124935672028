import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoAccess from './NoAccess';
import Access from './Access';
import { fetch } from '../../../services/dbEntities/lot';
import { getCurrency } from '../../../redux/selectors/preferences';
import Spinner from '../../common/spinners/Spinner';

const Show = ({ id, crypto }) => {
  const [lot, setLot] = useState();
  const [loading, setLoading] = useState(false);
  const currency = useSelector(getCurrency);

  useEffect(() => {
    setLoading(true);
    fetch(id, crypto).then(l => {
      setLot(l);
      setLoading(false);
    });
  }, [id, crypto, currency]);

  if (!lot) return null;

  if (loading) return <Spinner faSize={5} />;
  if (lot.hasAccess) return <Access lot={lot} currency={currency} />;

  return <NoAccess lot={lot} />;
};

export default Show;
