import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_CONTACT } from '../../../../constants/rails';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';
import { useViewport } from '../../../../hooks/useViewport';

const Contact = () => {
  const { from } = useSelector(getSettings);
  const { viewportWidth } = useViewport();

  return (
    <div className="subscriptions-list-contact">
      {viewportWidth < 768 && (
        <p>
          <A18n rsx="subscriptions.list.contact.m1" />
        </p>
      )}

      <button
        className="artp-btn"
        type="button"
        onClick={() => {
          aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_CONTACT });
          window.open('/contact?active=block8&id=690', '_blank');
        }}
      >
        {viewportWidth >= 768 && (
          <>
            <A18n rsx="subscriptions.list.contact.m1" />{' '}
          </>
        )}
        <A18n rsx="subscriptions.list.contact.m2" />
      </button>
    </div>
  );
};

export default Contact;
