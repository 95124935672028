import React from 'react';

import './stylesheet.scss';

const Notification = ({ element, link, onClick }) => (
  <li className="artp-Header-Notification">
    <a className="link-list-item" href={link} onClick={onClick}>
      <span className="text">{element}</span>
    </a>
  </li>
);

export default Notification;
