import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import {
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_KNOWLEDGE,
  ANALYTICS_SECTION_ARTIST_TOP10,
  ANALYTICS_SECTION_ARTIST_RECOMMENDATIONS,
} from '../../../../constants/rails';
import { getArtist, getKnowledge, getTop10 } from '../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import Scroll from '../common/Scroll';
import './stylesheet.scss';

const Recommendations = () => {
  const top10 = useSelector(getTop10);
  const knowledge = useSelector(getKnowledge);
  const artist = useSelector(getArtist);
  const { recommendationsRef, hideLeftMenu } = useContext(UiContext);

  if (isEmpty(knowledge) && isEmpty(top10)) return null;

  return (
    <div className={`artists-artist-recommendations ${hideLeftMenu ? 'block-no-menu' : 'block'}`} ref={recommendationsRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.recommendations.m1" />
        </h2>
        <p className="sub-h2">
          {!isEmpty(knowledge) && <A18n rsx="artists.artist.recommendations.m4" replace={[['%artist%', artist.name]]} />}
          {isEmpty(knowledge) && <A18n rsx="artists.artist.recommendations.m2" replace={[['%artist%', artist.name]]} />}
        </p>
      </div>
      <Scroll className="recommendations" aapiBeaconSection={ANALYTICS_SECTION_ARTIST_RECOMMENDATIONS}>
        {(isEmpty(knowledge) ? top10 : knowledge).slice(0, 10).map(recommendation => (
          <a
            href={recommendation.url}
            key={`reco-${recommendation.url}`}
            onClick={() =>
              aapiBeacon('click', {
                from: ANALYTICS_FROM_ARTIST,
                op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL,
                section: isEmpty(knowledge) ? ANALYTICS_SECTION_ARTIST_TOP10 : ANALYTICS_SECTION_ARTIST_KNOWLEDGE,
                idartist: artist.id,
              })
            }
          >
            <div className="recommendation" itemProp="creator" itemScope itemType="http://schema.org/Person">
              <meta itemProp="name" content={recommendation.name} />
              <link id="schemaurl" href={`${window.location.origin}${recommendation.url}`} itemProp="url" />
              {recommendation.illustration && (
                <div className="img-container">
                  <img src={recommendation.illustration} alt={recommendation.name} />
                </div>
              )}
              <p className="artist">{recommendation.name}</p>
              <p className="page">
                <A18n rsx="artists.artist.recommendations.m3" />
              </p>
            </div>
          </a>
        ))}
      </Scroll>
    </div>
  );
};

export default Recommendations;
