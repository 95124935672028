import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../../spinners/Spinner';
import { getArtist } from '../../../../redux/selectors/dbEntities';
import { fetchArtists } from '../../../../redux/actions/dbEntities/artist';

const ArtistSmallView = ({ id, withLink = false, withDates = false, onClick, anchor }) => {
  const artist = useSelector(state => getArtist(state, { id }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!artist) {
      dispatch(fetchArtists({ id, withDates }));
    }
  }, []);

  if (!artist) {
    return <Spinner faSize={1} />;
  }

  const { artist: artistName, name = artistName, url } = artist;

  if (withLink) {
    return (
      <a onClick={onClick} href={url}>
        {anchor && anchor}
        {!anchor && name}
      </a>
    );
  }

  if (anchor) return anchor;

  return name || null;
};

export default ArtistSmallView;
