module.exports = [
  'artprice.lib.result.indicatorresult.m7',
  'artprice.lib.result.indexresult.m7',
  'artprice.lib.result.indexresult.m56',
  'artprice.lib.result.indexresult.m12',
  'artprice.index.legals.m1',
  'artprice.lib.index.method.m1',
  'indexes._indexes_chart.m1',
  'indexes._indexes_chart.m2',
  'indexes._indexes_chart.m3',
  'indexes._indexes_chart.m4',
  'indexes._indexes_chart.m5',
  'indexes._indexes_chart.m6',
  'indexes.advanced_options',
  'indexes.categories',
  'indexes.index',
  'indexes.select_one',
  'indexes.table.date',
  // select compared artist
  'indexes.compare_to',
  'indexes.select_artist',
  'indexes.no_artist_found',
  'indexes.loading_artist',
  'indexes.looking_for',
  'artprice.lib.result.indicatorresult.m7',
  'indexes.priceindex.m1',
  'indexes.priceindex.m2',
];
