/* eslint-disable max-len */
import React from 'react';

const SvgIcon = ({ color = 'black' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.137" height="16.146" viewBox="0 0 19.137 16.146" className="move-button-svg-icon">
    <g id="Groupe_31" transform="translate(4)" fill={color}>
      <path
        d="M169.635,37.848l-3.7-3.7a.5.5,0,0,0-.357-.148h-7.232A1.347,1.347,0,0,0,157,35.346v4.709h1.009V35.346a.337.337,0,0,1,.336-.336h6.728v2.355a1.347,1.347,0,0,0,1.346,1.346h2.355V48.8a.337.337,0,0,1-.336.336H158.346a.337.337,0,0,1-.336-.336V44.092H157V48.8a1.347,1.347,0,0,0,1.346,1.346h10.091a1.347,1.347,0,0,0,1.346-1.346V38.2A.5.5,0,0,0,169.635,37.848Z"
        transform="translate(-154.645 -34)"
      />
      <path
        d="M159.814,50.819l-3.7-2.691a.673.673,0,0,0-1.068.544v1.682h-4.373a.673.673,0,0,0-.673.673V51.7a.673.673,0,0,0,.673.673h4.373v1.682a.673.673,0,0,0,1.068.544l3.7-2.691a.673.673,0,0,0,0-1.089Z"
        transform="translate(156.092 59.437) rotate(180)"
      />
    </g>
  </svg>
);

export default SvgIcon;
