import React from 'react';

import { Card, CardImg } from 'reactstrap';
import './stylesheet.scss';

const EventItem = ({ event }) => {
  if (!event) return null;
  const { id, title, slug, thumbnail } = event;

  return (
    <div className="marketplace-exposition-block hidden-xs" style={{ overflowY: 'auto' }}>
      <a href={`/events/${id}/${title.replace(/[/#.\s]/gi, '-')}/`}>
        <Card>
          {slug && thumbnail.url && (
            <div className="justify-content-center event-thumbnail-container">
              <CardImg className="event-thumbnail img-responsive" top src={thumbnail.url} alt="Event Thumbnail" />
            </div>
          )}
        </Card>
      </a>
    </div>
  );
};

export default EventItem;
