import addYears from 'date-fns/addYears';
import addWeeks from 'date-fns/addWeeks';
import { isPlainObject } from 'lodash';
import { get, set } from '../cookie';
import { COOKIE_CONSENT_NAME, COOKIE_CONSENT_ACCEPTED, COOKIE_CONSENT_REJECTED } from '../../constants/rails';

export const ALL_REJECTED = {
  analytics: COOKIE_CONSENT_REJECTED,
  social: COOKIE_CONSENT_REJECTED,
};

export const ALL_ACCEPTED = {
  analytics: COOKIE_CONSENT_ACCEPTED,
  social: COOKIE_CONSENT_ACCEPTED,
};

const getCookieValue = () => {
  const cookieValue = get(COOKIE_CONSENT_NAME);
  if (cookieValue === undefined) {
    return undefined;
  }

  try {
    if (isPlainObject(cookieValue)) {
      // cookieValue can be directly parsed by "get" (from cookie)
      return cookieValue;
    }
    const parsedCookie = JSON.parse(cookieValue);
    if (isPlainObject(parsedCookie)) {
      return parsedCookie;
    }
  } catch (_e) {
    /* empty */
  }

  return undefined;
};

export const getCookiePreferences = () => {
  const cookieValue = getCookieValue();
  if (cookieValue !== undefined) {
    return cookieValue;
  }

  // opt out by default
  return ALL_REJECTED;
};

export const hasChosenConsent = () => getCookieValue() !== undefined;

export const hasConsent = cookieType => {
  const cookiePrefs = getCookiePreferences();
  return cookiePrefs[cookieType] === COOKIE_CONSENT_ACCEPTED;
};

// consent is an object with { <type>: "accepted"|"rejected" }
// you can specify multiple types
// We begin by combining ALL_REJECTED if getCookiePreferences() or consent is not "whole" (if there is any category missing)
export const setConsent = consent =>
  set(
    COOKIE_CONSENT_NAME,
    { ...ALL_REJECTED, ...getCookiePreferences(), ...consent },
    {
      expires: consent.analytics === 'accepted' ? addYears(new Date(), 1) : addWeeks(new Date(), 2),
    },
  );
