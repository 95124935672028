import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import A18n from '../../../../common/A18n';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';

const Members = ({ iso3 }) => {
  const { idcustomer } = useSelector(getUserContext);
  const { requiredIdmodule } = useSelector(getSettings);

  if (idcustomer || requiredIdmodule) return null;

  return (
    <div className="card-container">
      <div className="subscriptions-list-cards-card essential">
        <div className="subscriptions-list-cards-card-header">
          <h2>
            <A18n rsx="subscriptions.list.cards.m1" />
          </h2>
          <p>
            <A18n rsx="subscriptions.list.cards.m2" />
          </p>
        </div>
        <div className="infos">
          <div className="subscriptions-list-cards-card-price">
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber style="currency" value={0} currency={iso3.toUpperCase()} />
            <span className="duration">
              <A18n rsx="subscriptions.list.cards.m3" />
            </span>
          </div>

          <button
            type="button"
            className="subscriptions-list-cards-card-button artp-btn"
            onClick={() => {
              window.location = '/account/registration';
            }}
          >
            <A18n rsx="subscriptions.list.cards.m4" />
          </button>
        </div>
        <div className="components">
          <ul>
            <li>
              <A18n rsx="subscriptions.list.cards.m5" />
            </li>
            <li>
              <A18n rsx="subscriptions.list.cards.m6" />
            </li>
            <li>
              <A18n rsx="subscriptions.list.cards.m11" />
            </li>
            <li>
              <A18n rsx="subscriptions.list.cards.m7" />
            </li>
            <li>
              <A18n rsx="subscriptions.list.cards.m8" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Members;
