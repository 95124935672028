/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import './stylesheet.scss';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const isNotAChild = this.wrapperRef && !this.wrapperRef.contains(event.target);
    const isNotAReactSelectOption = isUndefined([...event.target.classList].find(elem => elem.search(/^css-/) === 0 || elem.search(/^react-select__/) === 0));

    if (isNotAChild && isNotAReactSelectOption) {
      const { onClose } = this.props;
      onClose();
      this.close();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  close() {
    document.removeEventListener('click', this.handleClickOutside);
    this.setState({
      open: false,
    });
  }

  render() {
    const { title, icon, displayIconDesktop, render, right, className, onClose } = this.props;
    const { open } = this.state;

    const toggle = () => {
      const newOpenState = !open;
      this.setState({
        open: newOpenState,
      });

      if (newOpenState) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        onClose();
        document.removeEventListener('click', this.handleClickOutside);
      }
    };

    return (
      <div className={`common-dropdown${right ? ' right' : ''} ${open ? 'open' : ''} ${className || ''}`.trim()} ref={this.setWrapperRef}>
        <div className={open ? 'title open' : 'title'}>
          <button type="button" onClick={toggle} className={icon && 'hidden-xs'}>
            {displayIconDesktop && <i className={`fa fa-${icon}`} />}
            <span className="title-text">{title}</span>
            <i className="fa fa-caret-down" />
          </button>
          {icon && (
            <button type="button" onClick={toggle} className="visible-xs">
              <i className={`fa fa-${icon}`} />
            </button>
          )}
        </div>
        <div className={`dropdown${open ? ' visible' : ''}`}>{open && render({ close: () => this.close() })}</div>
      </div>
    );
  }
}

DropDown.defaultProps = {
  title: '',
  right: false,
  className: '',
  icon: '',
  onClose: () => undefined,
};

DropDown.propTypes = {
  right: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  icon: PropTypes.string,
  render: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default DropDown;
