import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import CommonDrawer from '../../../common/Drawer';
import VerticalBar from '../../../search/common/VerticalBar';
import Content from './Content';
import { MYARTPRICE_DRAWER_ID } from '../../../../constants/myartprice';
import { off as closeDrawer } from '../../../../redux/actions/ui/switches';
import './stylesheet.scss';
import { getCurrentScope } from '../../../../redux/selectors/search';
import { MYARTPRICE_LOTS, MYARTPRICE_LOTS_FOLLOW } from '../../../../constants/search/scope';

const Bar = ({ className, children, ...otherProps }) => {
  const dispatch = useDispatch();
  const currentScope = useSelector(getCurrentScope);

  const mapScopeToTitleRsx = () => {
    if (MYARTPRICE_LOTS.includes(currentScope)) return 'layouts.header.m16';
    if (MYARTPRICE_LOTS_FOLLOW.includes(currentScope)) return 'layouts.header.m43';
    return 'layouts.header.m16';
  };

  return (
    <>
      <CommonDrawer
        id={MYARTPRICE_DRAWER_ID}
        render={() => (
          <div className="drawer-content">
            <div className="drawer-title">
              <span>
                {i18next.t('component.myartprice.lot.m9')} - {i18next.t(mapScopeToTitleRsx())}
              </span>
              <i role="presentation" className="fa fa-times text-right" onClick={() => dispatch(closeDrawer(MYARTPRICE_DRAWER_ID))} />
            </div>
            <div className="pad pad-5">
              <Content drawerId={MYARTPRICE_DRAWER_ID}>{children}</Content>
            </div>
          </div>
        )}
      />

      <VerticalBar className={`hidden-sm hidden-xs myartprice-lots-vertical-bar ${className || ''}`} {...otherProps}>
        <Content>{children}</Content>
      </VerticalBar>
    </>
  );
};

export default Bar;
