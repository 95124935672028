/* eslint-disable global-require,import/no-mutable-exports */
import React from 'react';
import { onServer } from '../../../utils/ssr';

let RouterProvider;
if (onServer()) {
  const { StaticRouter } = require('react-router-dom/server');
  const { Routes, Route } = require('react-router-dom');

  const createChildren = routes =>
    routes.map(route => {
      const props = { ...route };
      if (route.children) {
        props.children = createChildren(route.children);
      }

      return <Route key={route.key || route.path} {...props} />;
    });

  RouterProvider = function ({ routes }) {
    const children = createChildren(routes);
    return (
      <StaticRouter location={`${window.location.pathname}${window.location.search || ''}`}>
        <Routes>{children}</Routes>
      </StaticRouter>
    );
  };
} else {
  const { RouterProvider: BaseRouterProvider } = require('react-router-dom');
  const createRouter = require('./createRouter').default;

  RouterProvider = function ({ routes, ...props }) {
    const router = createRouter(routes);

    return <BaseRouterProvider router={router} {...props} />;
  };
}

export default RouterProvider;
