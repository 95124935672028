import React from 'react';
import i18next from 'i18next';
import DebouncedInput from '../../../inputs/DebouncedInput';
import Item from '../../../Search/Item';

const Keyword = () => (
  <Item label={i18next.t('search.helpers.items.keyword.select.m1')} name="keyword">
    <DebouncedInput debounce={250} placeholder={i18next.t('search.helpers.items.keyword.select.m2')} />
  </Item>
);

export default Keyword;
