import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from '../ui/Modal';
import Navigation from './Navigation';

import './stylesheet.scss';
import A18n from '../A18n';

const ModalTour = ({ className, sections = [], onClose, onCloseDefinitively }) => {
  const [open, setOpen] = useState(false);
  const [currentSectionIdx, setCurrentSectionIdx] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const close = useCallback(() => {
    if (onClose) {
      setOpen(false);
      onClose();
    }
  }, [onClose]);

  return (
    <Modal className={classNames('ModalTour', className)} open={open} onClose={close}>
      {sections.length > 1 && (
        <div className="ModalTour-pagination">
          {currentSectionIdx + 1}/{sections.length}
        </div>
      )}
      <div className="ModalTour-content">{React.cloneElement(sections[currentSectionIdx], { close })}</div>
      <div className="btn-container">
        {onClose && (
          <button type="button" className="later" onClick={close}>
            <A18n rsx="common.modaltour.btn1" />
          </button>
        )}
        <Navigation current={currentSectionIdx} total={sections.length} onChange={setCurrentSectionIdx} />
        {onCloseDefinitively && (
          <button
            type="button"
            className="close-definitively"
            onClick={() => {
              setOpen(false);
              onCloseDefinitively();
            }}
          >
            <A18n rsx="common.modaltour.btn2" />
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ModalTour;
