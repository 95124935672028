import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Years from '../../../inputs/Years';

const ArtistBirthYear = () => (
  <Item label={i18next.t('components.search.helpers.items.artist_birth_year.m1')} name={{ birth_from: 'from', birth_to: 'to' }}>
    <Years />
  </Item>
);

export default ArtistBirthYear;
