import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../../../redux/slices/artist';
import A18n from '../../../../../common/A18n';
import Price from '../common/Price';
import './stylesheet.scss';

const Past = ({ lotCount }) => {
  const artist = useSelector(getArtist);

  return (
    <div className="artists-artist-lots-no-access-drawer-past">
      <h3>
        {lotCount === 1 && <A18n rsx="artists.artist.lots.noaccessdrawer.m6" replace={[['%artist%', artist.name]]} />}
        {lotCount > 1 && <A18n rsx="artists.artist.lots.noaccessdrawer.m1" replace={[['%artist%', artist.name]]} />}
      </h3>
      <div className="details">
        <p className="args">
          {lotCount === 1 && <A18n rsx="artists.artist.lots.noaccessdrawer.m17" replace={[['%dt-year%', artist.firstLotYear]]} trustHtml />}
          {lotCount > 1 && (
            <>
              {artist.lastLotYear === artist.firstLotYear && (
                <>
                  <A18n rsx="artists.artist.lots.noaccessdrawer.m16" replace={[['%dt-year%', artist.firstLotYear]]} trustHtml />{' '}
                </>
              )}
              {artist.lastLotYear !== artist.firstLotYear && (
                <A18n
                  rsx="artists.artist.lots.noaccessdrawer.m15"
                  replace={[
                    ['%dt-old-year%', artist.firstLotYear],
                    ['%dt-recent-year%', artist.lastLotYear],
                  ]}
                  trustHtml
                />
              )}
            </>
          )}
        </p>
        <Price />
      </div>
    </div>
  );
};
export default Past;
