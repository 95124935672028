import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { getArtist, getCa } from '../../../../redux/slices/artist';
import { UiContext } from '../common/UiProvider';
import Classifieds from './Classifieds';
import Stores from './Stores';
import './stylesheet.scss';

const Marketplace = () => {
  const ca = useSelector(getCa);
  const artist = useSelector(getArtist);
  const { marketplaceRef, menuRef, hideLeftMenu } = useContext(UiContext);

  return (
    <div className={`artists-artist-marketplace ${hideLeftMenu ? 'block-no-menu' : 'block'}`} ref={marketplaceRef} style={(!artist.macro && !artist.index) || isEmpty(ca?.data) ? { marginTop: 30 } : {}}>
      <Classifieds menuRef={menuRef} />
      <Stores menuRef={menuRef} />
    </div>
  );
};
export default Marketplace;
