import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Artist from '../../filters/Artist';
import Categories from '../../filters/Categories';
import Techniques from '../../filters/Techniques';
import Countries from '../../filters/Countries';
import State from '../../filters/State';
import Dates from '../../filters/Dates';
import City from '../../filters/City';
import CommonDrawer from '../../../common/Drawer';
import { off as closeDrawer } from '../../../../redux/actions/ui/switches';
import { updateCurrentDraft as updateParams } from '../../../../redux/actions/search';
import { getCurrentScope as getCurrentScopeSelector, getCurrentUrlParams as initialParamsSelector, getCurrentLoadingFacets as loadingFacetsSelector } from '../../../../redux/selectors/search';
import { getCurrentCount as initialTotalCountSelector, getCurrentDraftCount as totalCountSelector } from '../../../../redux/selectors/search/info';
import { MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW, AUCTIONEER_SALE_LOTS, MYARTPRICE_LOTS_FOLLOW_PAST } from '../../../../constants/search/scope';
import Estimation from '../../filters/Estimations';
import Dimensions from '../../filters/Dimensions';
import Prices from '../../filters/Prices';
import Identifications from '../../filters/Identifications';
import './stylesheet.scss';

const Drawer = ({ withOptionalCurrencies, validateParams, id, resetIfNecessary }) => {
  const dispatch = useDispatch();
  const totalCount = useSelector(totalCountSelector);
  const initialTotalCount = useSelector(initialTotalCountSelector);
  const loadingFacets = useSelector(loadingFacetsSelector);
  const initialParams = useSelector(initialParamsSelector);
  const currentScope = useSelector(getCurrentScopeSelector);

  const handleUpdateParams = (param, value) => {
    dispatch(updateParams({ triggeredParam: param, value }));
  };

  const handleFavoritesFilter = checked => {
    if (checked) {
      handleUpdateParams('idartist', []);
    }

    handleUpdateParams('in_fav', checked ? '1' : '0');
  };

  const handleValidateParams = () => {
    validateParams();
    dispatch(closeDrawer(id));
  };

  return (
    <CommonDrawer
      id={id}
      onClose={resetIfNecessary}
      render={() => (
        <div className="common-search-drawers-lots drawer-content">
          <div className="drawer-title">
            {/* Advanced search */}
            <span>{i18next.t('marketplace.searchbars.classifieds.bar.m1')}</span>
            <i
              role="presentation"
              className="fa fa-times text-right"
              onClick={() => {
                resetIfNecessary();
                dispatch(closeDrawer(id));
              }}
            />
          </div>
          <section>
            <Identifications />

            <div className="artp-input-group">
              <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.m8')}</span>

              {/* Search input */}
              <div className="search-bar-input">
                <input
                  key={`keyword-${initialParams.keyword}`}
                  className="form-control input-sm"
                  type="text"
                  defaultValue={initialParams.keyword}
                  placeholder={i18next.t('lots.search.form.m1')}
                  onChange={e => handleUpdateParams('keyword', e.target.value)}
                />
              </div>
            </div>

            {/* Search titles only */}
            <div className="artp-input-group">
              <span className="artp-input-label" />
              <div className="checkbox" style={{ marginBottom: 0, marginTop: 0 }}>
                <label htmlFor="checkbox-lkot">
                  <input id="checkbox-lkot" type="checkbox" onChange={e => handleUpdateParams('limit_keywords_on_title', e.target.value)} />
                  {i18next.t('lots.search.form.keyword.m2')}
                </label>
              </div>
            </div>

            {/* Exact match */}
            <div className="artp-input-group">
              <span className="artp-input-label" />
              <div className="checkbox" style={{ marginBottom: 0, marginTop: 0 }}>
                <label htmlFor="checkbox-em">
                  <input id="checkbox-em" type="checkbox" onClick={e => handleUpdateParams('exact_match', e.target.value)} />
                  {i18next.t('lots.search.form.exactmatch.m1')}
                </label>
              </div>
            </div>
          </section>

          <section>
            {[AUCTIONEER_SALE_LOTS].includes(currentScope) && <Artist withFacets className="search-bar-input" />}
            {![MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_FUTURE_ALL, AUCTIONEER_SALE_LOTS, MYARTPRICE_LOTS_FOLLOW_PAST].includes(currentScope) && (
              <>
                <Artist withSearch className="search-bar-input" />
                <div className="artp-input-group">
                  <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.m13')}</span>
                  <div className="checkbox" style={{ marginBottom: 0, marginTop: 9 }}>
                    <label htmlFor="checkbox-fav">
                      <input id="checkbox-fav" type="checkbox" onClick={e => handleFavoritesFilter(e.target.checked)} />
                    </label>
                  </div>
                </div>
              </>
            )}
            <Categories className="search-bar-input" />
            <Techniques className="search-bar-input" />
          </section>

          <section>
            <div className="artp-input-group">
              <span className="artp-input-label">{i18next.t('search.drawers.lot.m1')}</span>
              <div className="search-bar-input">
                <div className="cell first flex">
                  <input
                    key={`year-from-${initialParams.year_from}`}
                    className="form-control input-sm"
                    type="number"
                    min="0"
                    defaultValue={initialParams.year_from}
                    onBlur={e => handleUpdateParams('year_from', e.target.value)}
                    placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
                  />
                </div>
                <div className="cell flex">
                  <input
                    className="form-control input-sm"
                    type="number"
                    min="0"
                    key={`year-to-${initialParams.year_to}`}
                    defaultValue={initialParams.year_to}
                    onBlur={e => handleUpdateParams('year_to', e.target.value)}
                    placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
                  />
                </div>
              </div>
            </div>
          </section>

          <section>
            <Dimensions />
          </section>

          <section>
            <Estimation withOptionalCurrencies={withOptionalCurrencies} />
            {[MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FOLLOW_PAST, AUCTIONEER_SALE_LOTS].includes(currentScope) && <Prices withOptionalCurrencies={withOptionalCurrencies} />}
          </section>

          <section>
            <Dates />
          </section>

          <section>
            <Dates />
          </section>

          {currentScope !== AUCTIONEER_SALE_LOTS && (
            <section>
              <Countries />
              <State />
              <City />
            </section>
          )}

          <div className="flex-filler" />
          {(loadingFacets || totalCount !== initialTotalCount) && (
            <section className="results">
              {totalCount !== initialTotalCount && !loadingFacets && <i className="font font-16">{i18next.t('marketplace.home.index.m4').replace('%count%', totalCount)}</i>}
              {loadingFacets && <i className="fa fa-spinner fa-spin" />}
            </section>
          )}

          <section className="apply">
            <button type="button" className="submit-button" onClick={() => handleValidateParams()}>
              {i18next.t('marketplace.searchbars.classifieds.bar.m2')}
            </button>
          </section>
        </div>
      )}
    />
  );
};

export default Drawer;
