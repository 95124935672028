/* eslint-disable global-require */
module.exports = [
  ...require('./Table/i18n'),
  'lots.show.m19',
  'lots.indicator.m1',
  'lots.indicator.m2',
  'lots.indicator.m3',
  'lots.show.m15',
  'artprice.lib.result.indicatorresult.m7',
  'artprice.index.legals.m1',
  'indexes.indexes_chart.m5',
  'indexes.indexes_chart.m6',
];
