import React from 'react';
import Sale from './Sale';
import A18n from '../../../common/A18n';

const Sales = ({ sales, salelots }) => {
  if (!sales) return null;

  return (
    <div className="ails-landingpage-sales">
      <h2>
        <A18n rsx="ails.landingpage.sales.m1" />
      </h2>

      {sales?.map(sale => (
        <Sale key={sale.id} ail={sale} lots={salelots?.filter(lot => lot.idsale === sale.discriminant)} />
      ))}
    </div>
  );
};

export default Sales;
