import React from 'react';
import i18next from 'i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector, useDispatch } from 'react-redux';
import { getDimension } from '../../../../redux/selectors/preferences';
import { updateCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentUrlParams } from '../../../../redux/selectors/search';
import customStyles from '../../common/reactSelect/style';
import '../../common/reactSelect/stylesheet.scss';

const Dimensions = () => {
  const dispatch = useDispatch();
  const defaultDimension = useSelector(getDimension);
  const urlParams = useSelector(getCurrentUrlParams);
  const dimensionUnitOptions = [
    { value: 'cm', label: 'cm' },
    { value: 'in', label: 'in' },
  ];

  return (
    <div className="artp-input-group">
      <span className="artp-input-label">{i18next.t('lots.search.form.size.m4')}</span>
      <div className="search-bar-input">
        <div className="cell first" style={{ width: '26%' }}>
          <input
            className="form-control"
            placeholder={i18next.t('lots.search.form.size.m3')}
            type="number"
            key={`height-${urlParams.height}`}
            defaultValue={urlParams.height}
            onBlur={e => dispatch(updateCurrentDraft({ triggeredParam: 'height', value: e.target.value }))}
          />
        </div>
        <div className="cell" style={{ width: '26%' }}>
          <input
            className="form-control"
            placeholder={i18next.t('lots.search.form.size.m1')}
            type="number"
            defaultValue={urlParams.width}
            key={`width-${urlParams.width}`}
            onBlur={e => dispatch(updateCurrentDraft({ triggeredParam: 'width', value: e.target.value }))}
          />
        </div>
        <div className="cell" style={{ width: '26%' }}>
          <input
            className="form-control"
            placeholder={i18next.t('lots.search.form.size.m2')}
            type="number"
            defaultValue={urlParams.length}
            key={`length-${urlParams.length}`}
            onBlur={e => dispatch(updateCurrentDraft({ triggeredParam: 'length', value: e.target.value }))}
          />
        </div>
        <div className="cell" style={{ width: '22%' }}>
          <Select
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m12')}
            styles={customStyles(0)}
            components={makeAnimated()}
            classNamePrefix="react-select"
            className="search-common-react-select"
            options={dimensionUnitOptions}
            defaultValue={dimensionUnitOptions.find(item => item.value === defaultDimension)}
            onChange={selectedOption => dispatch(updateCurrentDraft({ triggeredParam: 'unit', value: selectedOption.value }))}
          />
        </div>
        <div className="cell first marg marg-t-5" style={{ width: '26%' }}>
          <div className="input-group">
            <input
              className="form-control"
              placeholder="+/-"
              type="number"
              min="0"
              max="10"
              key={`percent-${urlParams.percent}`}
              defaultValue={urlParams.percent}
              onBlur={e => dispatch(updateCurrentDraft({ triggeredParam: 'percent', value: e.target.value }))}
            />
            <span className="input-group-addon" id="basic-addon1">
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dimensions;
