import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_FOLLOW_ARTIST, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, IMAGE_SOURCE } from '../../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../../../redux/slices/artist';
import '../../../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../../../common/A18n';
import Follow from '../../../../../Follow';

const NoFavoriteCard = ({ direction = 'vertical' }) => {
  const artist = useSelector(getArtist);

  return (
    <div className={`lot fake ${direction}`}>
      <div className="fake-bg" />
      {direction === 'vertical' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email.png`} alt="email-alert" />}
      {direction === 'horizontal' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email-no-fs.png`} alt="email-alert" />}
      <div className="fake-container">
        <p className="title">
          <A18n rsx="artists.artist.lots.future.m20" trustHtml replace={[['%artist%', artist.name]]} />
        </p>
        <p className="content">
          <A18n rsx="artists.artist.lots.future.m18" trustHtml />
        </p>

        <Follow
          idartist={artist.id}
          render={({ handleFollowClick }) => (
            <a
              className="artp-btn artp-btn-artist"
              onClick={() => {
                handleFollowClick();
                aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_FOLLOW_ARTIST, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, idartist: artist.id });
              }}
            >
              <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
                <div>
                  <A18n rsx="artists.artist.lots.future.m19" />
                </div>
                <div style={{ border: 'solid 1px #000', borderRadius: 15, fontSize: 16, height: 30, marginLeft: 10, paddingTop: 3, width: 30 }}>
                  <i className="fa fa-heart-o" />
                </div>
              </div>
            </a>
          )}
        />
      </div>
    </div>
  );
};

export default NoFavoriteCard;
