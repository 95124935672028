export function getAuctioneerLink({ isAuctioneer, isNonPartnerAuctioneer, auctioneerMapped, signedAt }) {
  if (isAuctioneer || (isNonPartnerAuctioneer && auctioneerMapped)) {
    if (signedAt) {
      return '/auctioneer/control-center/future-sales?p=1&sort=datesale_asc';
    }
    return '/auctioneer/control-center/agreement';
  }

  return '/auctioneer';
}
