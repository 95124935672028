/* eslint-disable global-require */
module.exports = [
  ...require('./ContactModale/i18n'),
  'auctioneers.agreement.m1',
  'auctioneers.agreement.m2',
  'auctioneers.agreement.m3',
  'auctioneers.agreement.m4',
  'auctioneers.agreement.m5',
  'auctioneers.agreement.m6',
  'auctioneers.agreement.m7',
  'auctioneers.agreement.m13',
  'auctioneers.agreement.m14',
  'auctioneers.agreement.m15',
  'auctioneers.agreement.m16',
];
