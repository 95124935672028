import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, func, string, number, bool } from 'prop-types';
import i18next from 'i18next';
import AsyncSelect from 'react-select/async';
import { artistSearch } from '../../../../../services/search';
import { width as viewportWidthSelector } from '../../../../../redux/selectors/ui/viewport';
import { updateCurrentDraft as updateParamsAction } from '../../../../../redux/actions/search';
import { getCurrentDraftFacets as draftFacetsSelector, getCurrentUrlParams as urlParamsSelector } from '../../../../../redux/selectors/search';
import customStyles from '../../../common/reactSelect/style';
import '../../../common/reactSelect/stylesheet.scss';

class Artist extends Component {
  defaultValues() {
    const { draftFacets, urlParams } = this.props;

    if (draftFacets.idartist && urlParams.idartist) {
      const urlIdArtist = Array.isArray(urlParams.idartist) ? urlParams.idartist : [urlParams.idartist];

      return Object.entries(draftFacets.idartist)
        .filter(([idartist]) => urlIdArtist.includes(idartist))
        .map(([idartist, artist]) => ({ label: artist.label, value: idartist }));
    }
    return [];
  }

  render() {
    const { className, updateParams, viewportWidth, searchOnlyInFavorites } = this.props;

    return (
      <AsyncSelect
        styles={customStyles(viewportWidth)}
        classNamePrefix="react-select"
        className={`search-common-react-select ${className}`}
        isClearable
        isMulti
        cacheOptions
        placeholder={i18next.t('marketplace.searchbars.common.artist.m2')}
        noOptionsMessage={({ inputValue }) => {
          if (inputValue.length > 0) {
            return i18next.t('marketplace.searchbars.common.artist.m3');
          }
          return i18next.t('marketplace.searchbars.common.artist.m4');
        }}
        loadingMessage={() => i18next.t('marketplace.searchbars.common.artist.m5')}
        loadOptions={async term => {
          const artists = await artistSearch({ excludedIdArtist: 0, term, favorites: searchOnlyInFavorites });
          return artists.map(({ id, html }) => ({ value: id, label: html }));
        }}
        defaultValue={this.defaultValues()}
        onChange={option =>
          updateParams(
            'idartist',
            option.map(artist => artist.value),
          )
        }
      />
    );
  }
}

Artist.defaultProps = {
  className: undefined,
  searchOnlyInFavorites: false,
  viewportWidth: undefined,
};

Artist.propTypes = {
  draftFacets: object.isRequired,
  urlParams: object.isRequired,
  className: string,
  searchOnlyInFavorites: bool,
  updateParams: func.isRequired,
  viewportWidth: number,
};

function mapStateToProps(state) {
  return {
    draftFacets: draftFacetsSelector(state),
    urlParams: urlParamsSelector(state),
    viewportWidth: viewportWidthSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateParams: (param, value) => dispatch(updateParamsAction({ triggeredParam: param, value })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
