/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { updateItemsPerPage } from '../../../../redux/actions/preferences';
import { getItemsPerpage } from '../../../../redux/selectors/preferences';
import './stylesheet.scss';
import { SEARCH_ITEMS_PER_PAGE } from '../../../../constants/rails';

export default () => {
  const dispatch = useDispatch();
  const itemsPerPage = useSelector(getItemsPerpage);

  const handleClick = itemsCount => {
    dispatch(updateItemsPerPage({ itemsPerPage: itemsCount }));
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('lots.search.filters.display.itemsperpage.m1')}</span>
      <div className="search-bar-input btn-group btn-group-sm">
        {SEARCH_ITEMS_PER_PAGE.map(itemsCount => (
          <a key={itemsCount} className={`btn btn-default ${parseInt(itemsPerPage, 10) === parseInt(itemsCount, 10) ? 'active' : ''}`} onClick={() => handleClick(itemsCount)}>
            {itemsCount}
          </a>
        ))}
      </div>
    </div>
  );
};
