// eslint-disable-next-line
export const recursiveReducer = (valueToReduce, pow = 3) => {
  const parsedValueToReduce = parseFloat(valueToReduce);
  if (Number.isNaN(parsedValueToReduce)) {
    return 0;
  }

  const reducedValue = parsedValueToReduce / 10 ** pow;
  if (Math.floor(reducedValue) > 0) {
    return pow + recursiveReducer(reducedValue, pow);
  }
  return 0;
};
