import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIdartist, getArtistOptions, isArtistOptionsLoading } from '../../../../redux/selectors/indexes/artist';
import { artistOptionsAction } from '../../../../redux/actions/indexes/artist';
import { getCurrency } from '../../../../redux/selectors/preferences';
import Spinner from '../../../common/spinners/Spinner';

const MaxPriceOption = ({ disabled, parentChart, onBlur }) => {
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);
  const artistOptions = useSelector(getArtistOptions);
  const loading = useSelector(isArtistOptionsLoading);

  useEffect(() => {
    dispatch(artistOptionsAction());
  }, [idartist, currency]);

  return (
    <div>
      {artistOptions?.maxPrice && loading ? (
        <Spinner />
      ) : (
        <input
          name="maxPrice"
          onBlur={e => onBlur({ maxPrice: e.target.value })}
          disabled={disabled}
          placeholder={i18next.t('indexes._advanced.m11')}
          className="form-control"
          type="number"
          id={`option-max-price-${parentChart}`}
          min={artistOptions?.minPrice}
          max={artistOptions?.maxPrice}
        />
      )}
    </div>
  );
};

export default MaxPriceOption;
