import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import ErrorBlock from '../../../common/ErrorBlock';
import { a18n } from '../../../../utils/a18n';
import A18n from '../../../common/A18n';

const Description = ({ errors, register }) => (
  <FormGroup row>
    <Label sm={4} className="required">
      <A18n rsx="marketplace.reporting.description.m1" />
    </Label>
    <Col sm={8}>
      <textarea
        rows={10}
        className="form-control"
        {...register('description', {
          required: {
            value: true,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
          pattern: {
            value: /\w{5,}/gi,
            message: a18n('marketplace.reporting.m29'),
          },
        })}
      />
      <ErrorBlock error={errors.description} />
    </Col>
  </FormGroup>
);

export default Description;
