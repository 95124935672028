import React, { Component } from 'react';
import { bool, func, string, shape, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { CookiesProvider, withCookies } from 'react-cookie';
import ReduxProvider from '../../../providers/ReduxProvider';
import { fetchTopStores as fetchTopStoresAction } from '../../../../redux/actions/marketplace/stores/top';
import { getTopStores } from '../../../../redux/selectors/marketplace/stores';
import TopStore from './Store';
import './stylesheets.scss';

class Top extends Component {
  componentDidMount() {
    const { fetchTopStores } = this.props;
    fetchTopStores();
  }

  render() {
    const { stores, displayH2 } = this.props;
    if (!stores) {
      return <div>Waiting</div>;
    }
    return (
      <div className="marketplace-stores-top">
        {displayH2 && (
          <h2>
            {i18next.t('marketplace.home.index.m2')}
            <span className="pull-right small store-link hidden-xs">
              <a href="/marketplace/store" style={{ verticalAlign: 'middle' }}>
                <span className="badge">
                  <i className="fa fa-chevron-right" />
                </span>
                {i18next.t('marketplace.home.index.m5')}
              </a>
            </span>
          </h2>
        )}
        <div className="list">
          {stores.map(({ id, url, imageUrl, sitename }) => (
            <div className={`view-container store-${id}`} key={id}>
              <TopStore url={url} imageUrl={imageUrl} name={sitename} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Top.defaultProps = {
  stores: undefined,
  displayH2: true,
};

Top.propTypes = {
  displayH2: bool,
  fetchTopStores: func.isRequired,
  stores: arrayOf(
    shape({
      sitename: string.isRequired,
      url: string.isRequired,
      imageUrl: string.isRequired,
    }),
  ),
};

function mapStateToProps(state) {
  return {
    stores: getTopStores(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTopStores: () => dispatch(fetchTopStoresAction()),
  };
}

const TopStores = withCookies(connect(mapStateToProps, mapDispatchToProps)(Top));

const ProvidedTopStores = ({ displayH2 }) => (
  <CookiesProvider>
    <ReduxProvider>
      <TopStores displayH2={displayH2} />
    </ReduxProvider>
  </CookiesProvider>
);

ProvidedTopStores.defaultProps = {
  displayH2: true,
};

ProvidedTopStores.propTypes = {
  displayH2: bool,
};

export default ProvidedTopStores;
