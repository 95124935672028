import React from 'react';
import { Link } from 'react-router-dom';
import './stylesheet.scss';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const LinkComponent = ({ to, active, children, aapiBeaconParams }) => (
  <Link onClick={() => aapiBeacon('click', aapiBeaconParams)} className={`auctioneer-top-bar-link link ${active ? 'active' : ''}`} to={to}>
    {children}
  </Link>
);

export default LinkComponent;
