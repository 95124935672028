import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getIdcustomer, isLogged as isLoggedSelector } from '../../../../../redux/slices/userContext/selectors';
import ModaleForm from './ModaleForm';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Message = ({ classified, setOpenModalLegals }) => {
  const [open, setOpen] = useState(false);
  const isLogged = useSelector(isLoggedSelector);
  const idcustomer = useSelector(getIdcustomer);

  return (
    <div className="actions-conversation">
      <ModaleForm classified={classified} idcustomer={idcustomer} open={open} setOpen={setOpen} setOpenLegals={setOpenModalLegals} />
      {isLogged && (
        <button type="button" className="btn btn-info" onClick={() => setOpen(true)}>
          <i className="fa fa-paper-plane" /> <A18n rsx="marketplace.classified.actions.message.m1" />
        </button>
      )}
      {!isLogged && (
        <a href="/identity" className="btn btn-info">
          <i className="fa fa-paper-plane" /> <A18n rsx="marketplace.classified.actions.message.m1" />
        </a>
      )}
    </div>
  );
};

export default Message;
