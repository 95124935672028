/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */

import React, { useState } from 'react';
import A18n from '../../../../common/A18n';
import Section from './Section';
import Fees from '../Fees';
import './stylesheet.scss';
import BackButton from '../BackButton';
import Spinner from '../../../../common/spinners/Spinner';
import BackToTopButton from '../../../../common/BackToTopButton';
import { queryStringToObject } from '../../../../../utils/http';

const Mobile = ({ faq, lang, fullFaq, dataloading }) => {
  const queryStringParams = queryStringToObject(window.location.search);
  const [activeFaq, setActiveFaq] = useState(queryStringParams.active || 'seller');

  return (
    <div className={`marketplace-how-to-bid-faq-mobile ${fullFaq ? 'marketplace-how-to-bid-faq-mobile-full' : ''}`}>
      {fullFaq && <BackButton />}
      {dataloading && <Spinner />}
      {!dataloading && (
        <div className="groups">
          <div className="group">
            <div className={activeFaq === 'seller' ? 'active' : ''} onClick={() => setActiveFaq(activeFaq === 'seller' ? null : 'seller')}>
              <A18n rsx="marketplace.howtobid.faq.m6" />
              <i className="fa fa-caret-right" />
            </div>
            {activeFaq === 'seller' && <Section faq={faq.seller || []} />}
          </div>
          <div className="group">
            <div className={activeFaq === 'buyer' ? 'active' : ''} onClick={() => setActiveFaq(activeFaq === 'buyer' ? null : 'buyer')}>
              <A18n rsx="marketplace.howtobid.faq.m5" />
              <i className="fa fa-caret-right" />
            </div>
            {activeFaq === 'buyer' && <Section faq={faq.buyer || []} />}
          </div>
          <div className="group">
            <div className={activeFaq === 'escrow' ? 'active' : ''} onClick={() => setActiveFaq(activeFaq === 'escrow' ? null : 'escrow')}>
              <A18n rsx="marketplace.howtobid.faq.m4" />
              <i className="fa fa-caret-right" />
            </div>
            {activeFaq === 'escrow' && <Section faq={faq.escrow || []} />}
          </div>
          <div className="group">
            <div className={activeFaq === 'fees' ? 'active' : ''} onClick={() => setActiveFaq(activeFaq === 'fees' ? null : 'fees')}>
              <A18n rsx="marketplace.howtobid.faq.m7" />
              <i className="fa fa-caret-right" />
            </div>
            {activeFaq === 'fees' && <Fees lang={lang} />}
          </div>
        </div>
      )}

      {fullFaq && (
        <div className="back-to-top-container">
          <BackToTopButton top="marketplace-how-to-bid-faq" forceHandleResize={!dataloading && activeFaq} />
        </div>
      )}
    </div>
  );
};

export default Mobile;
