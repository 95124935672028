import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import { getClassifieds } from '../../../redux/slices/homepage';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { a18n } from '../../../utils/a18n';
import Arrow from '../../common/Arrow';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Classifieds = () => {
  const classifieds = useSelector(getClassifieds);

  if (isEmpty(classifieds)) return null;

  return (
    <div className="homepage-classifieds block">
      <div className="container">
        <h2 className="title">
          <A18n rsx="homepage.classifieds.m1" />
          <div className="all">
            <a className="marketplace" href={`/${a18n('routes.marketplace')}`} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL })}>
              <A18n rsx="homepage.classifieds.m2" />
              <Arrow style={{ marginLeft: 5 }} />
            </a>
          </div>
        </h2>

        <div className="data three-cols">
          {classifieds.map(classified => (
            <a href={classified.url} key={classified.id} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, idclassified: classified.id })}>
              <div className="classified">
                <div className="l0">
                  <div style={{ backgroundImage: `url("${classified.image}")` }} />
                </div>
                <div className="l1-1">
                  {/* eslint-disable-next-line react/no-danger */}
                  <span dangerouslySetInnerHTML={{ __html: classified.title }} />
                </div>
                <div className="l1-2">{classified.technique}</div>
                {/* eslint-disable-next-line react/no-danger */}
                <div className="l2-1" dangerouslySetInnerHTML={{ __html: classified.artist }} />
                <div className="l2-2">{classified.price}</div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Classifieds;
