/* eslint-disable react/no-danger */

import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import { getActiveSubscription, getIsAuctioneer, getIsNonPartnerAuctioneer } from '../../../../../redux/slices/userContext/selectors';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { STORE, SUBSCRIPTION } from '../../common/constants';

const Auctioneers = () => {
  const activeSubscription = useSelector(getActiveSubscription);
  const isAuctioneer = useSelector(getIsAuctioneer);
  const { filter } = useSelector(getSettings);
  const isNonPartnerAuctioneer = useSelector(getIsNonPartnerAuctioneer);

  return (
    <div className={`header ${activeSubscription ? '' : 'no-subscription'}`}>
      <div className="container auctioneer">
        {filter === STORE && (
          <>
            {isAuctioneer && (
              <>
                <h1>
                  <img alt="Partners" src={`${IMAGE_SOURCE}auctioneers/partners_fr.png`} /> {i18next.t('subscriptions.purchase.header.m1')}
                </h1>
                <p>{i18next.t('subscriptions.purchase.header.m9')}</p>
              </>
            )}
            {isNonPartnerAuctioneer && (
              <>
                <h1>{i18next.t('subscriptions.purchase.header.m7')}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('subscriptions.purchase.header.m10').replace('%a%', '<a target="_blank" href="/auctioneer">').replace('%/a%', '</a>'),
                  }}
                />
              </>
            )}
          </>
        )}

        {filter === SUBSCRIPTION && (
          <>
            {isAuctioneer && (
              <>
                <h1>
                  <img alt="Partners" src={`${IMAGE_SOURCE}auctioneers/partners_fr.png`} /> {i18next.t('subscriptions.purchase.header.m1')}
                </h1>
                <p>{i18next.t('subscriptions.purchase.header.m4')}</p>
              </>
            )}
            {isNonPartnerAuctioneer && (
              <>
                <h1>{i18next.t('subscriptions.purchase.header.m2')}</h1>
                <p>{i18next.t('subscriptions.purchase.header.m5')}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('subscriptions.purchase.header.m6').replace('%a%', '<a target="_blank" href="/auctioneer">').replace('%/a%', '</a>'),
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Auctioneers;
