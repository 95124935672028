import React, { useEffect, useRef, useState } from 'react';
import { Classifiedclass } from '../../../../constants/marketplace';
import DesignAndDecorative from './DesignAndDecorative';
import Fineart from './Fineart';
import './stylesheet.scss';
import Auctioneer from './Auctioneer';
import Separator from '../common/Separator';
import A18n from '../../../common/A18n';

const Details = ({ classified }) => {
  const commentRef = useRef();
  const [displayFullComment, setDisplayFullComment] = useState();
  const [readMoreVisible, setReadMoreVisible] = useState(false);

  useEffect(() => {
    if (commentRef.current?.scrollHeight > 64) {
      setReadMoreVisible(true);
    }
  }, [commentRef.current]);

  return (
    <div className="classified-details ">
      {classified.idclassifiedclass === Classifiedclass.FINEART && <Fineart classified={classified} />}
      {classified.idclassifiedclass !== Classifiedclass.FINEART && <DesignAndDecorative classified={classified} />}
      <Auctioneer classified={classified} />

      {classified.comment && (
        <>
          <Separator />
          <div className="classified-details-block">
            <div
              className="classified-label"
              style={{
                fontWeight: 'bold',
                fontSize: 16,
                marginBottom: 15,
              }}
            >
              <A18n rsx="marketplace.classified.details.m1" />
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div ref={commentRef} className={`classified-comment ${displayFullComment ? 'classified-comment-visible' : null}`} dangerouslySetInnerHTML={{ __html: classified.comment }} />
            {readMoreVisible && (
              <div onClick={() => setDisplayFullComment(!displayFullComment)} className="read-more">
                <A18n rsx="marketplace.classified.details.m2" />
                {displayFullComment && <i className="fa fa-caret-up" />}
                {!displayFullComment && <i className="fa fa-caret-down" />}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Details;
