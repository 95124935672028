import _ from 'lodash';
import { sendValidationEmail as sendValidationEmailService, setNewsletterSubscription as setNewsletterSubscriptionService } from '../../../services/account';
import { CUSTOMER_EMAIL_STATUS_INPROGRESS } from '../../../constants/rails';
import { update as updateUserContext } from '../../slices/userContext';
import { set } from '../ui/dictionary';
import { ACCOUNT_LOADING_KEY } from '../../../constants/account/confirm';

export function sendValidationEmail({ backTo }) {
  return async dispatch => {
    dispatch(set(ACCOUNT_LOADING_KEY, true));

    try {
      await sendValidationEmailService({ backTo });
      dispatch(updateUserContext({ emailStatus: CUSTOMER_EMAIL_STATUS_INPROGRESS }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(set(ACCOUNT_LOADING_KEY, false));
    }
  };
}

export function setNewslettersSubscription({ section, value }) {
  return async dispatch => {
    try {
      dispatch(updateUserContext({ [_.camelCase(section)]: value }));
      await setNewsletterSubscriptionService({ section, value });
    } catch (error) {
      console.error(error);
    }
  };
}
