import i18Next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentSelection, fetchLists, getLists, getListsLoading } from '../../../../../redux/slices/account/lotslists';
import { EMPTY_OBJECT } from '../../../../../constants/utils';
import A18n from '../../../../common/A18n';
import ContentCreation from './Content';
import './stylesheets.scss';
import AddIcon from '../common/AddIcon';
import Modal from '../../../../common/ui/Modal';

function findIconColor(disabled, className) {
  if (disabled) {
    return '#999';
  }
  return className?.includes('standalone') ? '#000' : '#fff';
}

const CreateButton = ({ className, style = EMPTY_OBJECT, label = i18Next.t('components.lotslists.create.m2'), disabled = false }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getListsLoading);
  const lists = useSelector(getLists);
  const [visibility, setVisibility] = useState(false);
  const currentSelection = useSelector(getCurrentSelection);

  useEffect(() => {
    if (Object.values(lists).length === 0) {
      dispatch(fetchLists());
    }
  }, []);

  return (
    <div style={style} className="account-lots-lists-add-to-list-button-create">
      <Modal open={visibility} onClose={() => setVisibility(false)} className="account-lots-lists-create-button-modale">
        <ContentCreation setVisibility={setVisibility} />
      </Modal>

      <button disabled={loading || disabled} type="button" className={`lots-lists-button ${currentSelection?.length === 0 ? '' : 'primary'} ${className ?? ''}`.trim()} onClick={() => setVisibility(true)}>
        {loading && <i className="fa fa-spin fa-spinner" />}
        {!loading && <AddIcon color={findIconColor(disabled, className)} />}
        <span className="title">
          {label}
          {currentSelection?.length > 1 && (
            <span className="count">
              {' '}
              (<A18n rsx="components.lotslists.create.m8" replace={[['%count%', currentSelection.length]]} />)
            </span>
          )}
          {currentSelection?.length === 1 && (
            <span className="count">
              {' '}
              (<A18n rsx="components.lotslists.create.m7" />)
            </span>
          )}
        </span>
      </button>
    </div>
  );
};
export default CreateButton;
