import React from 'react';
import { useDispatch } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { prepareScope } from '../../redux/slices/search/scoped';

const defaultOptions = {
  cancelPrevious: true,
};

const parseOptions = options => ({
  ...defaultOptions,
  ...options,
});

export function useSearch({ searchScope, baseParams, advancedParams, options }) {
  const allOptions = parseOptions(options);

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(prepareScope({ searchScope, baseParams, advancedParams, options: allOptions }));
  }, [searchScope, baseParams, advancedParams]);
}
