import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import List from '..';

const ProvidedList = ({ locale }) => (
  <ReduxProvider>
    <IntlProvider locale={locale}>
      <List />
    </IntlProvider>
  </ReduxProvider>
);

export default ProvidedList;
