import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';
import { useSelector } from 'react-redux';
import { getMarketplace } from '../../../../redux/slices/userContext/selectors';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import { getSwitch } from '../../../../redux/selectors/ui/switches';
import { getCounts } from '../../../../redux/selectors/marketplace/manage';
import Notification from './Notification';

const ManagmentMenu = () => {
  const activeTab = useSelector(state => getSwitch(state, { id: 'StoreManagmentMenuActiveTab' }));
  const { hasStore, messageCount } = useSelector(getMarketplace);
  const {
    waiting: waitingCount = 0,
    modificationRequired: modificationRequiredCount = 0,
    expired: expiredCount = 0,
    withOffers: withOffersCount = 0,
    myOffers: myOffersCount = 0,
    winningAuctions: winningAuctionsCount = 0,
  } = useSelector(getCounts);

  return (
    <div className="marketplace-stores-common-store-managment-menu">
      <div className="container">
        <div className="items">
          <div className="seller-menu">
            <div className="title first">
              {hasStore && i18next.t('marketplace.sellermenu.m9')}
              {!hasStore && i18next.t('layouts.header.m21')}
            </div>
            <div className="entries">
              <div className={`svg item icon ${activeTab === 10 && 'active'}`}>
                <a href={`/${i18next.t('routes.marketplace')}/store/stats`} title={i18next.t('component.store.stats.index.m1')}>
                  <img alt="store-stats" src={`${IMAGE_SOURCE}store-stats.svg`} />
                </a>
              </div>
              <div className={`item icon ${activeTab === 1 && 'active'}`} title={i18next.t('marketplace.stores.edit.form.m1')}>
                <a href={`/${i18next.t('routes.marketplace')}/store/edit`}>
                  <i className="fa fa-pencil fa-2x" />
                </a>
              </div>
              <div className={`item icon ${activeTab === 11 && 'active'}`} title={i18next.t('marketplace.common.managmentmenu.m1')}>
                <a href={`/${i18next.t('routes.marketplace')}/message/index`}>
                  <i className="fa fa fa-envelope-o fa-2x" />
                </a>
                {messageCount > 0 && <span className="badge">{messageCount}</span>}
              </div>
              <div className={`item ${activeTab === 9 && 'active'}`} title={i18next.t('common._marketplace_menu.html.m46')}>
                <a href={`/${i18next.t('routes.marketplace_info')}`}>{i18next.t('common._marketplace_menu.html.m46')}</a>
              </div>
            </div>
          </div>
          <div className="seller-menu">
            <div className="title">{i18next.t('marketplace.sellermenu.m10')}</div>
            <div className="entries separators">
              <div className={`item ${activeTab === 2 && 'active'}`}>
                <a className="sln-my-space-online" href={`/${i18next.t('routes.marketplace_manage')}/online`}>
                  {i18next.t('marketplace.sellermenu.m2')}
                </a>
              </div>

              <div className={`item ${waitingCount === 0 && 'disabled'} ${activeTab === 8 && 'active'}`}>
                {waitingCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/waiting`}>{i18next.t('marketplace.sellermenu.m14')}</a>
                    <span className="badge">{waitingCount}</span>
                  </>
                )}
                {waitingCount === 0 && <span>{i18next.t('marketplace.sellermenu.m14')}</span>}
              </div>

              <div className={`item ${modificationRequiredCount === 0 && 'disabled'} ${activeTab === 3 && 'active'}`}>
                {modificationRequiredCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/modification_required`}>{i18next.t('marketplace.sellermenu.m3')}</a>
                    <span className="badge">{modificationRequiredCount}</span>
                  </>
                )}
                {modificationRequiredCount === 0 && <span>{i18next.t('marketplace.sellermenu.m3')}</span>}
              </div>

              <div className={`item ${expiredCount === 0 && 'disabled'} ${activeTab === 5 && 'active'}`}>
                {expiredCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/offline`}>{i18next.t('marketplace.sellermenu.m4')}</a>
                    <span className="badge">{expiredCount}</span>
                  </>
                )}
                {expiredCount === 0 && <span>{i18next.t('marketplace.sellermenu.m4')}</span>}
              </div>
            </div>
          </div>
          <div className="seller-menu">
            <div className="title">{i18next.t('marketplace.sellermenu.m11')}</div>
            <div className="entries">
              <div className={`item ${withOffersCount === 0 && 'disabled'} ${activeTab === 4 && 'active'}`}>
                {withOffersCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/with_offers`}>{i18next.t('marketplace.sellermenu.m5')}</a>
                    <span className="badge">{withOffersCount}</span>
                  </>
                )}
                {withOffersCount === 0 && <span>{i18next.t('marketplace.sellermenu.m5')}</span>}
              </div>

              <div className={`item ${myOffersCount === 0 && 'disabled'} ${activeTab === 6 && 'active'}`}>
                {myOffersCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/my_offers`}>{i18next.t('marketplace.sellermenu.m6')}</a>
                    <span className="badge">{myOffersCount}</span>
                  </>
                )}
                {myOffersCount === 0 && <span>{i18next.t('marketplace.sellermenu.m6')}</span>}
              </div>
              <div className={`item ${winningAuctionsCount === 0 && 'disabled'} ${activeTab === 7 && 'active'}`}>
                {winningAuctionsCount > 0 && (
                  <>
                    <a href={`/${i18next.t('routes.marketplace_manage')}/winning_auctions`}>{i18next.t('marketplace.sellermenu.m7')}</a>
                    <span className="badge">{winningAuctionsCount}</span>
                  </>
                )}
                {winningAuctionsCount === 0 && <span>{i18next.t('marketplace.sellermenu.m7')}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="items">
          <Notification />
        </div>
      </div>
    </div>
  );
};

export default ManagmentMenu;
