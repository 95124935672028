/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.warnings.purchase-not-available.m1',
  'subscriptions.purchase.warnings.purchase-not-available.m2',
  'subscriptions.purchase.warnings.purchase-not-available.m3',
  'subscriptions.purchase.warnings.purchase-not-available.m4',
  'subscriptions.purchase.warnings.purchase-not-available.m5',
  'subscriptions.purchase.warnings.purchase-not-available.m6',
  'subscriptions.purchase.warnings.purchase-not-available.m7',
  'subscriptions.purchase.warnings.purchase-not-available.m8',
  'subscriptions.purchase.warnings.purchase-not-available.m9',
];
