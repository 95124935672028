import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FlipSwitch from '../../../../common/ui/FlipSwitch';
/*
  format: lets you choose the format of [true, false] you want
 */
const DEFAUT_FORMAT = ['1', '0'];
const UndeterminedSwitch = ({ className, value, onChange, label, format = DEFAUT_FORMAT }) => {
  const idRef = useRef(`search-input-undetermined-switch-${uuidv4()}`);
  const checked = value === undefined ? undefined : value === format[0];

  return (
    <FlipSwitch
      id={idRef.current}
      className={className}
      label={label}
      checked={checked}
      onChange={newBooleanValue => {
        if (value === format[1] && newBooleanValue) {
          onChange(undefined);
        } else {
          onChange(newBooleanValue ? format[0] : format[1]);
        }
      }}
    />
  );
};

export default UndeterminedSwitch;
