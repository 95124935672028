export const shouldRender = (subscriptionNeeded, othersDisplayConditions) => {
  if (subscriptionNeeded) {
    return true;
  }
  for (let i = 0; i < othersDisplayConditions.length; i += 1) {
    if (othersDisplayConditions[i]) {
      return true;
    }
  }
};
