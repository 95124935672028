/* eslint-disable global-require */
module.exports = [
  ...require('./common/i18n'),
  'marketplace.searchbars.classifieds.bar.m13',
  'marketplace.searchbars.stores.bar.m5',
  'layouts.menu.m2',
  'marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m1',
  'marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m2',
  'marketplace.searchbars.common.professionaltypes.m2',
  'marketplace.searchbars.common.professionaltypes.m1',
  'marketplace.searchbars.common.storetypes.m1',
  'marketplace.searchbars.common.storetypes.m2',
  'marketplace.searchbars.classifieds.bar.m9',
  'marketplace.searchbars.common.artist.m1',
  'lots.search.filters.inputs.saledate.m1',
  'lots.search.filters.others.m2',
  'lots.search.form.artist.m2',
  'artprice.lib.psresultctrl.m7',
];
