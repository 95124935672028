/* eslint-disable global-require */
module.exports = [
  'artists.artist.indexes.m1',
  'artists.artist.indexes.m2',
  'artists.artist.indexes.m3',
  'artists.artist.indexes.m4',
  // 'artists.artist.indexes.m5',
  // 'artists.artist.indexes.m6',
  // 'artists.artist.indexes.m7',
  'artists.artist.indexes.m8',
  'artists.artist.indexes.m9',
  'artists.artist.indexes.m10',
  'artists.artist.indexes.m11',
  'artists.artist.indexes.m12',
  'artists.artist.indexes.m13',
  'artists.artist.indexes.m14',
  'artists.artist.indexes.m15',
  'artists.artist.indexes.m16',
  'artists.artist.indexes.m17',
  'artists.artist.indexes.m18',
];
