import React from 'react';
import { IntlProvider } from 'react-intl';
import Summary from '../index';

const SummaryWithProviders = ({ baseUrl, locale = 'en', data = [], slug }) => (
  <IntlProvider locale={locale}>
    <Summary data={data} baseUrl={baseUrl} slug={slug} />
  </IntlProvider>
);

export default SummaryWithProviders;
