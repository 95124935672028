import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import Follow from '..';

const ClassifiedConnected = ({ idclassified, size, rsxFavorited, rsxNotFavorited, noLabel }) => (
  <ReduxProvider>
    <Follow idclassified={idclassified} size={size} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />
  </ReduxProvider>
);

export default ClassifiedConnected;
