/* eslint-disable global-require */
module.exports = [
  'account.login.m1',
  'account.login.m2',
  'account.login.m3',
  'account.login.m4',
  'account.login.m5',
  'account.login.m7',
  'account.login.m10',
  'account.login.m11',
  'account.login.m15',
  'account.login.m16',
];
