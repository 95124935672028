/* eslint-disable global-require */
module.exports = [
  'artprice.lib.psresultctrl.m18',
  'artprice.lib.psresultctrl.m6',
  'lots.small.m1',
  'lots.small.m12',
  'lots.small.m2',
  'sales.tile.sale.m2',
  'sales.tile.sale.m3',
  'sale_helper.sale_date_status.m1',
  'sale_helper.sale_date_status.m2',
  'sales.tile.sale.m4',
  'lot.show.access.m1',
  'lot.show.access.m4',
  'artprice.lib.psresultctrl.m23',
  'lots.show.m9',
  'lots.show.m10',
  'lots.show.m11',
  'artprice.client.payment.billdetail.m12',
  'lot.show.access.m2',
  'lots.show.m21',
  'lots.show.m22',
  'lots.show.m23',
  'lots.show.m24',
  'lots.show.m25',
  'lots.show.m26',
  'lots.show.m27',
];
