import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

import './stylesheet.scss';

const Logo = () => (
  <a className="artp-Header-Logo artp-Header-item" id="sln_home" href="/">
    <img src={`${IMAGE_SOURCE}logo.svg`} alt="artprice" />
  </a>
);

export default Logo;
