/* eslint-disable global-require */
module.exports = [
  ...require('./FreeDemo/i18n'),
  ...require('./ails/i18n'),
  ...require('./Articles/i18n'),
  ...require('./Classifieds/i18n'),
  ...require('./Events/i18n'),
  ...require('./Faq/i18n'),
  ...require('./FocusOnOrAutoPromo/i18n'),
  ...require('./Header/i18n'),
  ...require('./Marketplace/i18n'),
  ...require('./Myartprice/i18n'),
  ...require('./Reports/i18n'),
  ...require('./Stores/i18n'),
  ...require('./Studies/i18n'),
  ...require('./Teams/i18n'),
];
