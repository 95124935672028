import React from 'react';
import { useSelector } from 'react-redux';
import { getIsAuctioneer, getIsNonPartnerAuctioneer } from '../../../../redux/slices/userContext/selectors';
import Auctioneers from './Auctioneers';
import Others from './Others';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import Newcomer from './Newcomer';

import './stylesheet.scss';

const Header = () => {
  const isAuctioneer = useSelector(getIsAuctioneer);
  const isNonPartnerAuctioneer = useSelector(getIsNonPartnerAuctioneer);
  const { newcomer } = useSelector(getSettings);

  return (
    <div className={`subscriptions-purchase-header ${newcomer ? 'newcomer' : ''}`}>
      <div className="bg-left" />
      <div className="bg-right" />

      {newcomer && <Newcomer />}
      {!newcomer && (
        <>
          {(isAuctioneer || isNonPartnerAuctioneer) && <Auctioneers />}
          {!isAuctioneer && !isNonPartnerAuctioneer && <Others />}
        </>
      )}
    </div>
  );
};

export default Header;
