import React from 'react';
import i18next from 'i18next';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { updateCurrentDraft } from '../../../../redux/actions/search';
import { getCurrentUrlParams } from '../../../../redux/selectors/search';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { DEFAULT_CURRENCIES, OPTIONAL_CURRENCIES } from '../../../../constants/preferences';
import customStyles from '../../common/reactSelect/style';
import '../../common/reactSelect/stylesheet.scss';
import './stylesheet.scss';

const OPTIONS = Object.values(DEFAULT_CURRENCIES).map(({ id, iso1 }) => ({ value: `${id}`, label: iso1 }));
const OPTIONAL_OPTIONS = Object.values(OPTIONAL_CURRENCIES).map(({ id, iso1, src }) => ({ value: `${id}`, label: src ? <img alt={iso1} style={{ color: '#ccc', width: 10 }} src={src} /> : iso1 }));

const Estimation = ({ withOptionalCurrencies }) => {
  const currencies = [...OPTIONS, ...(withOptionalCurrencies ? OPTIONAL_OPTIONS : [])];
  const dispatch = useDispatch();
  const urlParams = useSelector(getCurrentUrlParams);
  const defaultCurrency = useSelector(getCurrency);

  let currentCurrency = currencies.filter(c => c.value === urlParams.estimation_idcurrency);
  if (!currentCurrency[0]) {
    currentCurrency = Object.values(DEFAULT_CURRENCIES)
      .filter(c => c.iso3 === defaultCurrency)
      .map(({ id, iso1 }) => ({ value: `${id}`, label: iso1 }));
  }
  currentCurrency = currentCurrency[0];

  const handleUpdateParams = (triggeredParam, value) => {
    const triggeredParams = [{ triggeredParam, value }];

    if ((triggeredParam === 'estimation_from' || triggeredParam === 'estimation_to') && !urlParams.estimation_idcurrency) {
      triggeredParams.push({ triggeredParam: 'estimation_idcurrency', value: currentCurrency.value });
    }

    dispatch(updateCurrentDraft(triggeredParams));
  };

  return (
    <div className="common-search-filters-estimation artp-input-group">
      <div className="artp-input-label">{i18next.t('lots.show.m12')}</div>
      <div className="search-bar-input" style={{ width: '275px' }}>
        <div className="cell first" style={{ width: '35%' }}>
          <Input
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
            className="form-control"
            type="text"
            key={`estimation-from-${urlParams.estimation_from}`}
            defaultValue={urlParams.estimation_from ? urlParams.estimation_from : ''}
            onBlur={e => handleUpdateParams('estimation_from', e.target.value)}
          />
        </div>
        <div className="cell" style={{ width: '35%' }}>
          <Input
            placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
            className="form-control"
            type="text"
            key={`estimation-to-${urlParams.estimation_to}`}
            defaultValue={urlParams.estimation_to ? urlParams.estimation_to : ''}
            onBlur={e => handleUpdateParams('estimation_to', e.target.value)}
          />
        </div>
        <div className="cell" style={{ width: '30%' }}>
          <Select
            components={makeAnimated()}
            styles={customStyles(0)}
            classNamePrefix="react-select"
            className="search-common-react-select"
            options={currencies}
            defaultValue={currentCurrency}
            onChange={selectedOption => handleUpdateParams('estimation_idcurrency', selectedOption.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Estimation;
