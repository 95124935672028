import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FileSelector } from '@artprice/react-upload';
import UploadPlaceholder from './UploadPlaceholder';
import './stylesheet.scss';

const DocumentSelector = ({ control, name, documentUrl, placeholder, readOnly, onDelete, rules }) => {
  const [url, setUrl] = useState(documentUrl);
  const [mimeType, setMimeType] = useState();

  useEffect(
    () => () => {
      URL.revokeObjectURL(url);
    },
    [url],
  );

  const handleChange = ([file], onChange) => {
    if (!readOnly) {
      if (file) {
        setMimeType(file.type);
        setUrl(URL.createObjectURL(file));
      } else {
        setMimeType(undefined);
        setUrl(undefined);
        onDelete();
      }

      onChange([file]);
    }
  };

  return (
    <div className="account-confirm-identity-field-component-document-selector">
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange } }) => (
          <>
            {!readOnly && url && (
              <div onClick={() => handleChange([], onChange)} className="delete">
                <i className="fa fa-trash-o" />
              </div>
            )}
            <FileSelector
              disabled={readOnly}
              key="FileSelector"
              accept={['image/jpeg', 'image/png', 'application/pdf']}
              onChange={v => handleChange(v, onChange)}
              name={`${name}-file-selector`}
              placeholder={props => (
                <UploadPlaceholder disabled={readOnly} isImageUploaded={url !== documentUrl} url={url} mimeType={mimeType} {...props} text={placeholder || i18next.t('components.account.verify.identity.m3')} />
              )}
            />
          </>
        )}
      />
    </div>
  );
};

export default DocumentSelector;
