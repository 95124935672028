import React from 'react';
import A18n from '../../../common/A18n';
import { typeformsMediaKit } from '../constants';
import './stylesheet.scss';

const Auctioneers = ({ lang }) => (
  <div className="ails-info-auctioneers">
    <div className="container">
      <h2>
        <A18n rsx="ails.info.m35" />
      </h2>
      <div className="blocks">
        <div className="block">
          <h3>
            <A18n rsx="ails.info.m49" />
          </h3>
          <div className="options">
            <A18n rsx="ails.info.m46" />
          </div>
        </div>
        <div className="block">
          <h3>
            <A18n rsx="ails.info.m50" />
          </h3>
          <div className="options">
            <A18n rsx="ails.info.m47" />
          </div>
        </div>
        <div className="block">
          <h3>
            <A18n rsx="ails.info.m51" />
          </h3>
          <div className="options">
            <A18n rsx="ails.info.m48" />
          </div>
        </div>
      </div>
      <div className="action">
        <a className="btn btn-default" href={`https://artprice.typeform.com/to/${typeformsMediaKit[lang] || typeformsMediaKit.en}`} target="typeforms">
          <A18n rsx="ails.info.m45" />
        </a>
      </div>
    </div>
  </div>
);

export default Auctioneers;
