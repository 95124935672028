import React from 'react';
import { useSelector } from 'react-redux';
import { getLastSubscription, getActiveSubscription } from '../../../../../../redux/slices/userContext/selectors';
import Block from './Block';
import './stylesheet.scss';

const Subscription = ({ idtheme, cardCount }) => {
  const lastSubscription = useSelector(getLastSubscription) || {};
  const activeSubscription = useSelector(getActiveSubscription) || {};

  if (!activeSubscription) return null;

  if (idtheme === activeSubscription.idtheme) {
    if (lastSubscription?.idtheme === activeSubscription.idtheme) {
      return (
        <Block
          idtheme={idtheme}
          cardCount={cardCount}
          started={activeSubscription.started}
          usersCount={lastSubscription.usersCount}
          dtStart={activeSubscription.dates.dtStart}
          dtExpire={lastSubscription.dates.dtExpire}
          duration={lastSubscription.dates.duration}
        />
      );
    }

    return (
      <Block
        idtheme={idtheme}
        cardCount={cardCount}
        usersCount={activeSubscription.usersCount}
        started={activeSubscription.started}
        dtStart={activeSubscription.dates.dtStart}
        dtExpire={activeSubscription.dates.dtExpire}
        duration={activeSubscription.dates.duration}
      />
    );
  }

  if (idtheme === lastSubscription?.idtheme && lastSubscription?.idtheme !== activeSubscription.idtheme) {
    return (
      <Block
        idtheme={idtheme}
        cardCount={cardCount}
        usersCount={lastSubscription.usersCount}
        started={lastSubscription.started}
        dtStart={lastSubscription.dates.dtStart}
        dtExpire={lastSubscription.dates.dtExpire}
        duration={lastSubscription.dates.duration}
      />
    );
  }

  return null;
};

export default Subscription;
