import React from 'react';
import { useSelector } from 'react-redux';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { getMarketplaceAuctionsWithOngoingTransactions } from '../../../../../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_WINNING_AUCTIONS } from '../../../../../../../../../constants/rails';
import { a18n } from '../../../../../../../../../utils/a18n';

const AuctionsWithOngoingTransactions = () => {
  const auctionsWithOngoingTransactions = useSelector(getMarketplaceAuctionsWithOngoingTransactions);

  return auctionsWithOngoingTransactions.map(idclassified => (
    <Notification
      key={idclassified}
      element={<A18n rsx="component.header.notifications.marketplace.m14" replace={[['%idclassified%', idclassified]]} />}
      link={`/${a18n('routes.marketplace_manage')}/winning_auctions`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_WINNING_AUCTIONS })}
    />
  ));
};

export default AuctionsWithOngoingTransactions;
