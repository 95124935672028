/* eslint-disable global-require */
module.exports = [
  'payments.informations.form.password.m1',
  'payments.informations.form.password.m2',
  'payments.informations.form.password.m3',
  'payments.informations.form.password.m4',
  'payments.informations.form.password.m5',
  'payments.informations.form.password.m6',
  'payments.informations.form.password.m7',
  'payments.informations.form.password.m8',
  'payments.informations.form.password.m9',
  'payments.informations.form.password.m10',
];
