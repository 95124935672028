import React from 'react';
import './stylesheet.scss';
import FreeDemo from './FreeDemo';
import Reports from './Reports';
import Marketplace from './Marketplace';
import A18n from '../../common/A18n';
import { IMAGE_SOURCE } from '../../../constants/rails';

const SigninConfirmation = ({ mainReport, returnUrl }) => (
  <div className="account-signin-confirmation">
    <div className="container">
      <div className="block">
        <h1>
          <A18n rsx="account.signinconfirmation.m1" />
        </h1>
        <div className="content">
          <img src={`${IMAGE_SOURCE}account/registration-ok.svg`} alt="FreeDemo" />
          <p>
            <strong>
              <A18n rsx="account.signinconfirmation.m2" />
            </strong>
          </p>

          <p>
            <A18n rsx="account.signinconfirmation.m3" trustHtml />
          </p>
        </div>
      </div>
      <div className="infos">
        <A18n rsx="account.signinconfirmation.m4" />
      </div>
      <div className="back">
        <a id="sln-return" href={returnUrl} className="artp-btn artp-btn-blue">
          <A18n rsx="account.signinconfirmation.m5" />
        </a>
      </div>
      <FreeDemo />
      <Marketplace />
      <Reports mainReport={mainReport} />
    </div>
  </div>
);
export default SigninConfirmation;
