import React from 'react';
import { useSelector } from 'react-redux';
import '../stylesheet.scss';
import Badge from '../../../../common/badges/Badge';
import { getMarketplaceMessageCount, getMessageCount } from '../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../common/A18n';
import { aapiBeacon } from '../../../../../../../services/analytics/aapi';
import {
  ANALYTICS_FROM_HEADER_MESSAGES,
  ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_MESSAGES,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MESSAGES,
  ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME,
} from '../../../../../../../constants/rails';
import { a18n } from '../../../../../../../utils/a18n';

import './stylesheet.scss';

// overlay over actual aapi to set type and from
function aapiWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_MESSAGES, op });
}

const Content = () => {
  const userMessages = useSelector(getMessageCount);
  const marketplaceMessages = useSelector(getMarketplaceMessageCount);

  return (
    <ul className="link-list artp-Header-Messages-Content">
      <li>
        <a className="link-list-item" href="/contact" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME)}>
          <A18n rsx="layout.footer.m6" />
        </a>
      </li>
      <li>
        <a className="link-list-item" href="/account/clientmessages/inbox" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_MESSAGES)}>
          <A18n rsx="message.index.m4" /> <Badge count={userMessages} />
        </a>
      </li>
      <li>
        <a className="link-list-item" href={`/${a18n('routes.marketplace_message_action')}/index`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MESSAGES)}>
          <A18n rsx="message.index.m2" /> <Badge count={marketplaceMessages} />
        </a>
      </li>
    </ul>
  );
};

export default Content;
