/* eslint-disable global-require */
module.exports = [
  ...require('./CategorySelect/i18n'),
  ...require('./CountrySelect/i18n'),
  ...require('./Price/i18n'),
  ...require('./ShapeSelect/i18n'),
  ...require('./SizeSelect/i18n'),
  ...require('./TechniqueSelect/i18n'),
  ...require('./SaleDate/i18n'),
  ...require('./ArtistDeathYear/i18n'),
  ...require('./ArtistBirthYear/i18n'),
  ...require('./ArtistCountrySelect/i18n'),
  ...require('./ArtistGenderSelect/i18n'),
  ...require('./CreationYear/i18n'),
  ...require('./Dimension/i18n'),
  ...require('./SaletypeSelect/i18n'),
  ...require('./IdentificationsSelect/i18n'),
  ...require('./SaleIdauctioneerSelect/i18n'),
  ...require('./Indicator/i18n'),
  ...require('./Signed/i18n'),
  ...require('./Reproduction/i18n'),
  ...require('./Sold/i18n'),
  ...require('./ArtistSelect/i18n'),
  ...require('./FavoriteArtists/i18n'),
  ...require('./Keyword/i18n'),
  ...require('./StateSelect/i18n'),
  ...require('./CitySelect/i18n'),
];
