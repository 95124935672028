import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_WARNING, MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE } from '../../../../../constants/rails';
import { getSelectedPhysicproduct, getSelectedTheme } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { getLastSubscription } from '../../../../../redux/slices/userContext/selectors';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { formatFns } from '../../../../../utils/dates/format';
import SubscribeButton from '../../common/SubscribeButton';
import { UPGRADE } from '../../common/constants';
import Modal from '../../../../common/ui/Modal';
import './stylesheet.scss';

const UpdateOrRenew = () => {
  const dispatch = useDispatch();
  const lastSubscription = useSelector(getLastSubscription) || {};
  const { displayWarningsModale = false, missingModules = [], quantity, initialQuantity, warningForRenewInPlaceOfUpgrade, from } = useSelector(getSettings);
  const selectedTheme = useSelector(getSelectedTheme);
  const selectedPhysicproduct = useSelector(getSelectedPhysicproduct);

  useEffect(() => {
    if (displayWarningsModale) {
      aapiBeacon('dom_view', { from, section: ANALYTICS_SECTION_SUBSCRIPTIONS_WARNING });
    }
  }, [displayWarningsModale]);

  const title = warningForRenewInPlaceOfUpgrade
    ? i18next.t('subscriptions.purchase.warnings.update-or-renew.m14').replace('%dt%', formatFns(lastSubscription.dates.dtExpire, 'P'))
    : i18next.t('subscriptions.purchase.warnings.update-or-renew.m12');

  const handleCloseClick = () => {
    if (warningForRenewInPlaceOfUpgrade) {
      dispatch(updateSettings({ data: { displayWarningsModale: false, view: UPGRADE } }));
    } else {
      dispatch(updateSettings({ data: { displayWarningsModale: false } }));
    }
    window.scrollTo(0, 0);
  };

  return (
    <Modal title={title} className={`subscriptions-purchase-downgrade lvl-${selectedTheme?.idtheme}`} onClose={() => dispatch(updateSettings({ data: { displayWarningsModale: false } }))} open={displayWarningsModale}>
      <div className="content">
        {quantity < initialQuantity && (
          <p>
            {i18next
              .t(quantity === 1 ? 'subscriptions.purchase.warnings.update-or-renew.m2' : 'subscriptions.purchase.warnings.update-or-renew.m1')
              .replace('%new_nbusers%', quantity)
              .replace('%current_nbusers%', initialQuantity)}
          </p>
        )}
        {missingModules.length > 0 && (
          <>
            <p>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m3').replace('%product%', selectedPhysicproduct?.title)}</p>

            <ul>
              {missingModules?.includes(MODULE_IMAGE) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m4')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_PASTSALE) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m5')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_FUTURESALE) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m6')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_INDEXES) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m7')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_INDICATOR) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m8')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_STORE) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m9')}</strike>
                </li>
              )}
              {missingModules.includes(MODULE_EXPORT_PDF) && (
                <li>
                  <strike>{i18next.t('subscriptions.purchase.warnings.update-or-renew.m18')}</strike>
                </li>
              )}
            </ul>
          </>
        )}
        {warningForRenewInPlaceOfUpgrade && (
          <>
            <p className="normal">{i18next.t('subscriptions.purchase.warnings.update-or-renew.m15')}</p>
            <p className="normal">{i18next.t('subscriptions.purchase.warnings.update-or-renew.m16').replace('%abo%', selectedPhysicproduct?.title)}</p>
          </>
        )}
      </div>

      <div className="actions">
        <div className="subscriptions-list-theme-subscribe-button">
          <button type="button" className="close-action" onClick={handleCloseClick}>
            {warningForRenewInPlaceOfUpgrade && i18next.t('subscriptions.purchase.warnings.update-or-renew.m13')}
            {!warningForRenewInPlaceOfUpgrade && i18next.t('subscriptions.purchase.warnings.update-or-renew.m10')}
          </button>
        </div>
        <SubscribeButton
          displayInWarningsModale
          theme={selectedTheme}
          label={i18next.t(warningForRenewInPlaceOfUpgrade ? 'subscriptions.purchase.warnings.update-or-renew.m17' : 'subscriptions.purchase.warnings.update-or-renew.m11')}
        />
      </div>
    </Modal>
  );
};

export default UpdateOrRenew;
