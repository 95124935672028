import { useEffect, useState } from 'react';
import { useViewport } from '../../../../hooks/useViewport';

export default function useSquareColumnsCount() {
  const [columnCount, setColumnCount] = useState(6);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (viewportWidth >= 768) {
      setColumnCount(2);
    } else {
      setColumnCount(1);
    }
  }, [viewportWidth]);

  return columnCount;
}
