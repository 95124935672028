import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_CLICK_OPERATION_TO_AIL_HOME,
  ANALYTICS_CLICK_OPERATION_TO_AMCI,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEX,
  ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_HOME,
  ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME,
  ANALYTICS_CLICK_OPERATION_TO_DDC,
  ANALYTICS_CLICK_OPERATION_TO_FB,
  ANALYTICS_CLICK_OPERATION_TO_INTERPOL,
  ANALYTICS_CLICK_OPERATION_TO_LINKEDIN,
  ANALYTICS_CLICK_OPERATION_TO_PRESS_RELEASE,
  ANALYTICS_CLICK_OPERATION_TO_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_RECRUITMENT,
  ANALYTICS_CLICK_OPERATION_TO_X,
  ANALYTICS_FROM_FOOTER,
  ANALYTICS_FROM_HEADER_LANGS,
  IMAGE_SOURCE,
  PDF_SOURCE,
  ANALYTICS_CLICK_OPERATION_TO_CGV,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_OBLIGATIONS,
  ANALYTICS_CLICK_OPERATION_TO_RETRACT,
  ANALYTICS_CLICK_OPERATION_TO_PRIVACY,
  ANALYTICS_CLICK_OPERATION_TO_COOKIES,
  ANALYTICS_CLICK_OPERATION_TO_VIDEO,
} from '../../../constants/rails';
import { FLAGS, MANAGED_LOCALES } from '../../../constants/locales';
import { getIdcustomer } from '../../../redux/slices/userContext/selectors';
import Legals, { CGV, MARKETPLACE_LEGALS, PRIVACY } from '../../Legals';
import { createNewUrlForLocale } from '../../../utils/links/locale';
import { getAmciLastValue } from '../../../redux/slices/footer';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { a18n } from '../../../utils/a18n';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const routes = {
  fr: '/editorial/artprice-recrute',
  de: '/editorial/artprice-stellenangebote',
  es: '/editorial/artprice-busca',
  it: '/editorial/artprice-assume',
  en: '/editorial/artprice-is-hiring',
};

// overlay over actual aapi to set type and from
function aapiLangWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_LANGS, op });
}

// overlay over actual aapi to set type and from
function aapiWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_FOOTER, op });
}

const Footer = () => {
  const intl = useIntl();
  const idcustomer = useSelector(getIdcustomer);
  const amciLastValue = useSelector(getAmciLastValue);

  return (
    <div className="artp-footer bottom-bar" suppressHydrationWarning>
      <div className="l1">
        <div className="l1-0">
          <div className="section">
            <Legals legal={CGV} anchorText={<A18n rsx="layout.footer.m1" />} from={ANALYTICS_FROM_FOOTER} op={ANALYTICS_CLICK_OPERATION_TO_CGV} />
          </div>
          <div className="section">
            <Legals legal={PRIVACY} anchorText={<A18n rsx="layout.footer.m2" />} from={ANALYTICS_FROM_FOOTER} op={ANALYTICS_CLICK_OPERATION_TO_PRIVACY} />
          </div>
          <div className="section">
            <a target="pdf" href={`${PDF_SOURCE}retract_${intl.locale}.pdf`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_RETRACT)}>
              <A18n rsx="layout.footer.m3" />
            </a>
          </div>
          <div className="section">
            <Legals legal={MARKETPLACE_LEGALS} anchorText={<A18n rsx="layout.footer.m4" />} from={ANALYTICS_FROM_FOOTER} op={ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_OBLIGATIONS} />
          </div>
          <div className="section">
            <a href="/cookies-management" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_COOKIES)}>
              <A18n rsx="layout.footer.m5" />
            </a>
          </div>
        </div>
        <div className="l1-1">
          <div className="section">
            <a href="/video" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_VIDEO)}>
              <A18n rsx="layout.footer.m18" />
            </a>
          </div>
          <div className="section">
            <a href="/contact" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME)}>
              <A18n rsx="layout.footer.m6" />
            </a>
          </div>
          <div className="section">
            <a href="/subscriptions" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_PRICING)}>
              <A18n rsx="layout.footer.m7" />
            </a>
          </div>
          <div className="section">
            <a href="/auctioneer" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_HOME)}>
              <A18n rsx="layout.footer.m8" />
            </a>
          </div>
          <div className="section">
            <a href="/ail" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AIL_HOME)}>
              <A18n rsx="layout.footer.m9" />
            </a>
          </div>
          {Object.keys(routes).includes(intl.locale) && (
            <div className="section">
              <a href={routes[intl.locale]} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_RECRUITMENT)}>
                <A18n rsx="layout.footer.m10" />
              </a>
            </div>
          )}
        </div>
        <div className="l1-2">
          <div className="section">
            <a href={`/${a18n('routes.artists_directory')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEX)}>
              <A18n rsx="layout.footer.m11" />
            </a>
          </div>
          <div className="section">
            <a href="/amci" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AMCI)}>
              Artmarket Confidence Index by Artprice <img alt="variation" src={`${IMAGE_SOURCE}amci${amciLastValue >= 0 ? 'up' : 'down'}.gif`} /> {amciLastValue}
            </a>
          </div>
          <div className="section" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_DDC)}>
            <a target="out" href="https://www.demeureduchaos.com/">
              <A18n rsx="layout.footer.m12" />
            </a>
          </div>
          <div className="section" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_PRESS_RELEASE)}>
            <a target="out" href={`https://serveur.serveur.com/artmarket/press-release/${intl.locale === 'fr' || intl.locale === 'en' ? `/${intl.locale}` : ''}`}>
              <A18n rsx="layout.footer.m13" />
            </a>
          </div>
          <div className="section" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_INTERPOL)}>
            <a target="out" href="https://www.interpol.int/fr/Infractions/Atteintes-au-patrimoine-culturel/Base-de-donnees-sur-les-aeuvres-d-art-volees">
              <A18n rsx="layout.footer.m14" />
            </a>
          </div>
        </div>
      </div>
      <div className="l-social">
        <div className="twitter-x">
          <a target="social" href={`https://twitter.com/artpricedotcom?lang=${intl.locale}`} rel="noreferrer" style={{ color: '#1DA1F2' }} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_X)}>
            <img alt="linkedin" src={`${IMAGE_SOURCE}social/x-logo.svg`} />
          </a>
        </div>
        <div>
          <a target="social" href="https://www.facebook.com/artpricedotcom" style={{ color: '#4267B2' }} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_FB)}>
            <i className="fa fa-2x fa-facebook" aria-hidden="true" />
          </a>
        </div>
        <div className="linkedin">
          <a target="social" href="https://www.linkedin.com/company/artprice-com/mycompany/" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_LINKEDIN)}>
            <img alt="linkedin" src={`${IMAGE_SOURCE}linkedin.png`} />
          </a>
        </div>
      </div>

      <div className="l-localisation">
        <div className="l-address">
          <A18n rsx="layout.footer.m17" />
        </div>
        <div className="l-flags">
          {idcustomer &&
            MANAGED_LOCALES.filter(managedLocale => managedLocale !== intl.locale).map(managedLocale => (
              <a
                key={managedLocale}
                className="link-list-item"
                href={createNewUrlForLocale(intl.locale, managedLocale)}
                onClick={() => {
                  aapiLangWrapper(managedLocale);
                }}
              >
                <span className={`fi fi-${FLAGS[managedLocale]} artp-Header-Langs-flag`} />
              </a>
            ))}
        </div>
      </div>

      <div className="l-ddc">
        <A18n rsx="layout.footer.m19" />
      </div>

      <div className="l2">
        <div className="l2-0">
          <A18n rsx="layout.footer.m15" />
        </div>
        <div className="l2-1">
          <A18n rsx="layout.footer.m16" replace={[['%current_year%', new Date().getFullYear()]]} trustHtml />
        </div>
      </div>
    </div>
  );
};

export default Footer;
