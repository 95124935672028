import React from 'react';
import { FormattedNumber } from 'react-intl';
import { CURRENCIES } from '../../constants/preferences';

export const getCurrencyFormatOptions = ({ currency, minimumFractionDigits = 0 }) => ({ style: 'currency', currency: CURRENCIES[currency?.toLowerCase()].iso3ForIntl, minimumFractionDigits });
export const getCurrencyFormatter = ({ currency, minimumFractionDigits = 0 }) =>
  // eslint-disable-next-line func-names
  function (value) {
    return <FormattedNumber value={value} {...getCurrencyFormatOptions({ currency, minimumFractionDigits })} />;
  };

export const getPercentFormatOptions = ({ maximumFractionDigits = 1 } = {}) => ({ style: 'percent', maximumFractionDigits });
export const getPercentFormatter = ({ maximumFractionDigits = 1 } = {}) =>
  // eslint-disable-next-line func-names
  function (value) {
    return <FormattedNumber value={value} {...getPercentFormatOptions({ maximumFractionDigits })} />;
  };

export const getSimpleNumberFormatOptions = ({ minimumFractionDigits = 0 } = {}) => ({ minimumFractionDigits });
export const getSimpleNumberFormatter = ({ minimumFractionDigits = 0 } = {}) =>
  // eslint-disable-next-line func-names
  function (value) {
    return <FormattedNumber value={value} {...getSimpleNumberFormatOptions({ minimumFractionDigits })} />;
  };
