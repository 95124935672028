import { object, string } from 'yup';

const schema = object().shape({
  firm: string(),
  email: string(),
  addressline1: string(),
  addressline2: string(),
  country: object().shape({
    value: string(),
    label: string(),
  }),
  city: string(),
  state: string(),
  cp: string(),
  phone: string(),
  fax: string(),
});

export default schema;
