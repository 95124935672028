import { useCallback, useEffect, useState } from 'react';

// we NEED to initalize it to the same value in both client AND server (ssr)
// otherwise each call to useViewport will trigger an hydration error
const INITIAL_VIEWPORT = {
  viewportWidth: 0,
  viewportHeight: 0,
};

function getViewport() {
  return {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  };
}

export function useViewport() {
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  const handleResize = useCallback(() => {
    setViewport(getViewport());
  }, [setViewport]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return viewport;
}
