import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchCategory } from '../../../../redux/actions/dbEntities/category';
import { getCategory } from '../../../../redux/selectors/dbEntities';

const CategorySmallView = ({ id }) => {
  const dispatch = useDispatch();
  const category = useSelector(state => getCategory(state, { id }), shallowEqual);

  useEffect(() => {
    dispatch(fetchCategory({ id }));
  }, []);

  if (!category) {
    return null;
  }

  return <span>{category.label}</span>;
};

export default CategorySmallView;
