import React from 'react';
import { useSelector } from 'react-redux';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import { SUBSCRIPTION } from '../../../common/constants';
import Data from './Data';
import Marketplace from './Marketplace';

const Details = ({ theme }) => {
  const { filter } = useSelector(getSettings);

  if (filter === SUBSCRIPTION) return <Data theme={theme} />;

  return <Marketplace theme={theme} />;
};

export default Details;
