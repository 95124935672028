/* eslint-disable global-require */
module.exports = [
  'components.lotslists.create.m1',
  'components.lotslists.create.m2',
  'components.lotslists.create.m3',
  'sales.search.filters.m2',
  'components.lotslists.create.m5',
  'components.lotslists.create.m6',
  'customerlotslist.error.m1',
  'components.lotslists.modal.m1',
];
