/* eslint-disable global-require */
module.exports = [
  ...require('./StoreNameMissing/i18n'),
  ...require('./EmailMissing/i18n'),
  ...require('./Undelivered/i18n'),
  ...require('./AlmostExpiredSubscription/i18n'),
  ...require('./AuctioneerAgreementNotSigned/i18n'),
  ...require('./ExpiredSubscription/i18n'),
  ...require('./SellerProfileIncomplete/i18n'),
  ...require('./ClassifiedsWaitingForModification/i18n'),
  ...require('./ExpiredClassifieds/i18n'),
  ...require('./StoreInformationIncomplete/i18n'),
  ...require('./EstimatesWaitingForPayment/i18n'),
  ...require('./AuctionsWithOngoingTransactions/i18n'),
  ...require('./AwaitingOfferSelection/i18n'),
  ...require('./SubscriptionNeedsAction/i18n'),
  ...require('./EmailNotVerified/i18n'),
];
