import React from 'react';
import { noop } from 'lodash';
import i18next from 'i18next';
import { connect } from 'react-redux';
import DropDown from '../../../common/DropDown';
import { isCurrentDraftDirty } from '../../../../redux/selectors/search';
import { getCurrentDraftCount } from '../../../../redux/selectors/search/info';
import './stylesheet.scss';

const ClosingDropDown = ({ title, icon, displayIconDesktop = false, render, onCancel, draftCount, isDraftDirty = false, className, onClose = noop, ...otherProps }) => (
  <DropDown
    className={`common-drop-down-to-validate ${className || ''}`}
    title={title}
    icon={icon}
    displayIconDesktop={displayIconDesktop}
    onClose={onCancel}
    render={({ close }) => (
      <>
        {render({ close })}
        {isDraftDirty && <div className="total-count">{i18next.t('common.dropdowntovalidate.m1').replace('%count%', draftCount)}</div>}
        <div className="submit-and-cancel">
          <button
            type="button"
            className="btn btn-default btn-flat pull-right"
            onClick={() => {
              close();
              if (onClose) {
                onClose();
              }
            }}
          >
            {i18next.t('search.bars.common.closingdropdown.m1')}
          </button>
        </div>
      </>
    )}
    {...otherProps}
  />
);

function mapStateToProps(state) {
  return {
    draftCount: getCurrentDraftCount(state),
    isDraftDirty: isCurrentDraftDirty(state),
  };
}

export default connect(mapStateToProps, null)(ClosingDropDown);
