import RandExp from 'randexp';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Col, FormGroup, Label, FormText } from 'reactstrap';
import { documenttypeForFormPrivate } from '../../../../../services/wallet/customerverificationdocumenttype';
import DocumenttypeDropdown from '../fieldComponents/DocumenttypeDropdown';
import DocumentSelector from '../fieldComponents/DocumentSelector';
import CountryDropdown from '../../../../common/inputs/CountryDropdown';
import TitleDropdown from '../../../../common/inputs/TitleDropdown';
import ErrorBlock from '../../../../common/ErrorBlock';
import PhoneInput from '../../../../common/ui/PhoneInput';
import DateInput from '../../../../common/inputs/DateInput';
import '../stylesheet.scss';

const FormPro = ({ initValues, errors = {}, readOnly, register, handleResourceDelete, control, setValue, watch }) => {
  const [countryIso2, setCountryIso2] = useState(initValues.countryIso2?.toLowerCase() || i18next.language);
  const [vatinConfig, setVatinConfig] = useState({ sample: initValues.vatinRegex ? new RandExp(initValues.vatinRegex).gen() : null, regex: initValues.vatinRegex });
  const addAnotherDoc = i18next.t('components.account.verify.identity.personal.m16');

  const handleCountryOnChange = country => {
    setCountryIso2(country.iso2.toLowerCase());

    if (country.vatinRegex) {
      setVatinConfig({ sample: new RandExp(country.vatinRegex).gen(), regex: country.vatinRegex });
      setValue('vatinRegex', country.vatinRegex);
    } else {
      setVatinConfig(null);
      setValue('vatinRegex', undefined);
    }
  };

  return (
    <>
      <input type="hidden" {...register('idDocumentUrl')} />
      <input type="hidden" {...register('idDocumentUrl')} />
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h2>{i18next.t('components.account.verify.identity.pro.m6')}</h2>

              <p>{i18next.t('components.account.verify.identity.pro.m8')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <FormGroup row>
              <Label for="company" md={4}>
                {i18next.t('components.account.verify.identity.pro.m1')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('company')} readOnly={readOnly} />
                <ErrorBlock error={errors.company} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="line1" md={4}>
                {i18next.t('components.account.verify.identity.personal.m6')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('line1')} readOnly={readOnly} />
                <ErrorBlock error={errors.line1} />
              </Col>
              <div className="address-replacement">{i18next.t('components.account.verify.identity.pro.m9')}</div>
            </FormGroup>
            <FormGroup row>
              <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                <input className="form-control" type="text" {...register('line2')} readOnly={readOnly} />
                <ErrorBlock error={errors.line2} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                <input className="form-control" type="text" {...register('line3')} readOnly={readOnly} />
                <ErrorBlock error={errors.line3} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="zipCode" md={4}>
                {i18next.t('components.account.verify.identity.personal.m7')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('zipCode')} readOnly={readOnly} />
                <ErrorBlock error={errors.zipCode} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="city" md={4}>
                {i18next.t('components.account.verify.identity.personal.m8')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('city')} readOnly={readOnly} />
                <ErrorBlock error={errors.city} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label className="optional" for="state" md={4}>
                {i18next.t('components.account.verify.identity.personal.m9')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('state')} readOnly={readOnly} />
                <ErrorBlock error={errors.state} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="country" md={4}>
                {i18next.t('components.account.verify.identity.personal.m10')}
              </Label>
              <Col lg={4} md={5}>
                <CountryDropdown control={control} className="form-control" name="country" readOnly={readOnly} onChange={handleCountryOnChange} />
                <ErrorBlock error={errors.country} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" md={4}>
                {i18next.t('components.account.verify.identity.personal.m11')}
              </Label>
              <Col lg={4} md={5}>
                <PhoneInput control={control} country={countryIso2} name="phone" readOnly={readOnly} />
                <ErrorBlock error={errors.phone} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="siret" md={4}>
                {i18next.t('components.account.verify.identity.pro.m2')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('siret')} readOnly={readOnly} />
                {errors.siret && errors.siret.message !== errors.vatin?.message && <ErrorBlock error={errors.siret} />}
                <div className="siret-or-tva-inline">
                  <FormText>{i18next.t('components.account.verify.identity.m6')}</FormText>
                </div>
              </Col>
              <div className="siret-or-tva">{i18next.t('components.account.verify.identity.m6')}</div>
            </FormGroup>
            <FormGroup row>
              <Label for="vatin" md={4}>
                {i18next.t('components.account.verify.identity.pro.m5')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="hidden" {...register('vatinRegex')} />
                <input className="form-control" type="text" {...register('vatin')} readOnly={readOnly} />
                {vatinConfig && (
                  <div style={{ fontStyle: 'italic', color: '#ccc', fontSize: 12 }}>
                    {i18next.t('account.registration.m1')}: {vatinConfig.sample}
                  </div>
                )}
                {errors.vatin && <ErrorBlock error={errors.vatin} />}
                <div className="siret-or-tva-inline">
                  <FormText>{i18next.t('components.account.verify.identity.m6')}</FormText>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firmDocument" md={4}>
                {i18next.t('components.account.verify.identity.personal.m15')}
              </Label>
              <Col lg={4} md={5}>
                <DocumentSelector
                  control={control}
                  className="form-control"
                  name="firmDocument"
                  readOnly={readOnly}
                  documentUrl={initValues.firmDocumentUrl}
                  onDelete={() => handleResourceDelete({ idDocument: initValues.idfirmDocument })}
                />
                <ErrorBlock error={errors.firmDocument} style={{ marginBottom: 15, paddingTop: 0, marginTop: -10 }} />
              </Col>
            </FormGroup>
            {((watch('firmDocument') || []).filter(doc => doc).length > 0 || initValues.firmDocumentUrl) && (
              <FormGroup className="file-group">
                <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                  <DocumentSelector
                    control={control}
                    className="form-control"
                    name="firmDocument2"
                    readOnly={readOnly}
                    documentUrl={initValues.firmDocumentUrl2}
                    onDelete={() => handleResourceDelete({ idDocument: initValues.idfirmDocument2 })}
                  />
                  <ErrorBlock error={errors.firmDocument2} style={{ marginBottom: 15, paddingTop: 0, marginTop: -10 }} />
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                <FormText>{i18next.t('components.account.verify.identity.pro.m3')}</FormText>
              </Col>
              {watch('firmDocument') && watch('firmDocument2') && (
                <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4 marg marg-t-10">
                  <FormText>{i18next.t('components.account.verify.identity.personal.m17')}</FormText>
                </Col>
              )}
            </FormGroup>
          </div>
        </div>
      </div>

      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h2>{i18next.t('components.account.verify.identity.pro.m7')}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <FormGroup row>
              <Label for="title" md={4}>
                {i18next.t('components.account.verify.identity.personal.m2')}
              </Label>
              <Col lg={4} md={5}>
                <TitleDropdown control={control} className="form-control" name="title" readOnly={readOnly} />
                <ErrorBlock error={errors.title} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firstName" md={4}>
                {i18next.t('components.account.verify.identity.personal.m3')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('firstName')} readOnly={readOnly} />
                <ErrorBlock error={errors.firstName} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastName" md={4}>
                {i18next.t('components.account.verify.identity.personal.m4')}
              </Label>
              <Col lg={4} md={5}>
                <input className="form-control" type="text" {...register('lastName')} readOnly={readOnly} />
                <ErrorBlock error={errors.lastName} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="birthDate" md={4}>
                {i18next.t('components.account.verify.identity.personal.m5')}
              </Label>
              <Col lg={4} md={5}>
                <DateInput control={control} name="birthDate" readOnly={readOnly} setValue={setValue} />
                <ErrorBlock error={errors.birthDate} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="idDocumenttype" md={4}>
                {i18next.t('components.account.verify.identity.personal.m14')}
              </Label>
              <Col lg={4} md={5}>
                <DocumenttypeDropdown control={control} className="form-control" name="idDocumenttype" readOnly={readOnly} service={documenttypeForFormPrivate} />
                <ErrorBlock error={errors.idDocumenttype} />
              </Col>
            </FormGroup>
            <FormGroup className="file-group">
              <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                <DocumentSelector
                  control={control}
                  onDelete={() => handleResourceDelete({ idDocument: initValues.idDocument })}
                  className="form-control"
                  name="idDocument"
                  readOnly={readOnly}
                  documentUrl={initValues.idDocumentUrl}
                />
                <ErrorBlock error={errors.idDocument} style={{ marginBottom: 15, paddingTop: 0, marginTop: -10 }} />
              </Col>
            </FormGroup>
            {((watch('idDocument') || []).filter(doc => doc).length > 0 || initValues.idDocumentUrl) && (
              <FormGroup className="file-group">
                <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                  <DocumentSelector
                    control={control}
                    onDelete={() => handleResourceDelete({ idDocument: initValues.idDocument2 })}
                    className="form-control"
                    name="idDocument2"
                    readOnly={readOnly}
                    documentUrl={initValues.idDocumentUrl2}
                    placeholder={addAnotherDoc}
                  />
                  <ErrorBlock error={errors.idDocument2} style={{ marginBottom: 15, paddingTop: 0, marginTop: -10 }} />
                </Col>
              </FormGroup>
            )}

            <FormGroup row>
              <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
                <FormText>{i18next.t('components.account.verify.identity.pro.m4')}</FormText>
              </Col>
              {watch('idDocument') && (
                <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4 marg marg-t-10">
                  <FormText>{i18next.t('components.account.verify.identity.personal.m17')}</FormText>
                </Col>
              )}
            </FormGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormPro;
