import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getPriceSegmentsData, isPriceSegmentsDataLoading, getPriceSegmentsDisplayedChartTab } from '../../../../redux/selectors/indexes/priceSegments';
import { getFormatter, getFormatOptions, getBarName } from '../../../../utils/indexes/priceSegments';
import { recursiveReducer } from '../../../../utils/indexes/common/rechartsTickFormat';
import { getCurrency, getLang } from '../../../../redux/selectors/preferences';
import { TAB_CA } from '../../../../constants/indexes/priceSegments';
import { CustomYAxisLabel } from '../../common/CustomYAxisLabel';
import { LANGUAGES } from '../../../../constants/preferences';
import Spinner from '../../../common/spinners/Spinner/index';
import { useViewport } from '../../../../hooks/useViewport';

const PriceSegmentsBarChart = ({ intl }) => {
  const { viewportWidth } = useViewport();
  const isDataLoading = useSelector(isPriceSegmentsDataLoading);
  const data = useSelector(getPriceSegmentsData);
  const currency = useSelector(getCurrency);
  const lang = useSelector(getLang);
  const tab = useSelector(getPriceSegmentsDisplayedChartTab);

  if (isDataLoading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }
  if (!data) {
    return <div style={{ height: 400 }} />;
  }

  const sortedData = [...data].sort((a, b) => b.value - a.value);

  const numberOfDigitsReduced = recursiveReducer(sortedData[0]?.value);
  const isBillionsAndLangChinese = numberOfDigitsReduced === 9 && lang === LANGUAGES.CHINESE ? 1 : 0;
  // 3 => 1000 ; 6 => 1000000 ; 9 => 1000000000 (except in chinese we reduce to 8 because they only have a "100 000 000" symbol)
  const divider = 10 ** (numberOfDigitsReduced - isBillionsAndLangChinese);

  const tickFormatter = numberOfDigitsReduced > 0 ? value => intl.formatNumber(value / divider, getFormatOptions({ tab })) : value => intl.formatNumber(value, getFormatOptions({ tab, currency }));

  return (
    <div style={{ paddingTop: 25 }}>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: -35 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={viewportWidth < 1200 ? -45 : 0} textAnchor={viewportWidth < 1200 ? 'end' : 'middle'} fontSize={viewportWidth < 1200 ? 12 : undefined} interval={0} />
          <YAxis
            tickSize={5}
            margin={{ bottom: 5 }}
            tickFormatter={tickFormatter}
            label={numberOfDigitsReduced > 0 ? <CustomYAxisLabel currency={[TAB_CA].includes(tab) ? currency : ''} numberOfDigitsReduced={numberOfDigitsReduced} /> : undefined}
          />
          <Tooltip formatter={getFormatter({ tab, currency })} />
          <Bar name={getBarName(tab)} dataKey="value" fill="rgb(51, 102, 204)" animationDuration={400} animationBegin={35} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default injectIntl(PriceSegmentsBarChart);
