import { isEmpty } from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LinkedinShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon, LinkedinIcon, EmailShareButton, EmailIcon } from 'react-share';
import { IMAGE_SOURCE } from '../../../constants/rails';
import './stylesheet.scss';

const Share = ({ hashtagsAddons = [], title, url, style, className, flat }) => {
  const [hashtags, setHashtags] = useState(['artprice']);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!isEmpty(hashtagsAddons)) setHashtags([...hashtagsAddons]);
  }, [hashtagsAddons]);

  const handleClick = () => {
    setVisible(!visible);
  };

  if (flat)
    return (
      <div className={classNames(['common-share', 'flat', className])} style={style}>
        <div className="share-button">
          <FacebookShareButton url={url || window.location.href} hashtag="#artprice" onClick={handleClick}>
            <FacebookIcon size={35} round />
          </FacebookShareButton>
        </div>
        <div className="share-button">
          <TwitterShareButton url={url || window.location.href} title={title} hashtags={hashtags} via="artpricedotcom" related={['artpricedotcom']} onClick={handleClick}>
            <img alt="X" title="X" src={`${IMAGE_SOURCE}social/x-logo.svg`} style={{ height: 32, marginTop: 2 }} />
          </TwitterShareButton>
        </div>
        <div className="share-button">
          <WhatsappShareButton url={url || window.location.href} title={title} separator=" -> " onClick={handleClick}>
            <WhatsappIcon size={35} round />
          </WhatsappShareButton>
        </div>
        <div className="share-button">
          <LinkedinShareButton url={url || window.location.href} title={title} source="artprice.com" onClick={handleClick}>
            <LinkedinIcon size={35} round />
          </LinkedinShareButton>
        </div>
        <div className="share-button">
          <EmailShareButton url={url || window.location.href} subject={title} body="body" description="description" onClick={handleClick}>
            <EmailIcon size={35} round />
          </EmailShareButton>
        </div>
      </div>
    );

  return (
    <div className={classNames(['common-share', className])} style={style}>
      <button style={{ outline: 'none' }} className="btn" type="button" onClick={handleClick}>
        <i className="fa fa-share-alt" />
      </button>

      {visible && <div className="share-bg" onClick={handleClick} />}

      <div className={`buttons ${visible ? 'visible' : ''}`}>
        <div className="buttons-list">
          <div className="share-button">
            <FacebookShareButton url={url || window.location.href} hashtag="#artprice" onClick={handleClick}>
              <FacebookIcon size={28} round />
            </FacebookShareButton>
          </div>
          <div className="share-button">
            <TwitterShareButton url={url || window.location.href} title={title} hashtags={hashtags} via="artpricedotcom" related={['artpricedotcom']} onClick={handleClick}>
              <img alt="X" title="X" src={`${IMAGE_SOURCE}social/x-logo.svg`} style={{ height: 27 }} />
            </TwitterShareButton>
          </div>
          <div className="share-button">
            <WhatsappShareButton url={url || window.location.href} title={title} separator=" -> " onClick={handleClick}>
              <WhatsappIcon size={28} round />
            </WhatsappShareButton>
          </div>
          <div className="share-button">
            <LinkedinShareButton url={url || window.location.href} title={title} source="artprice.com" onClick={handleClick}>
              <LinkedinIcon size={28} round />
            </LinkedinShareButton>
          </div>
          <div className="share-button">
            <EmailShareButton url={url || window.location.href} subject={title} body="body" description="description" onClick={handleClick}>
              <EmailIcon size={28} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Share;
