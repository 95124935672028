import i18next from 'i18next';
import React, { Suspense, useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set } from '../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU, MENU_FILES } from '../../../constants/auctioneers';
import { ANALYTICS_FROM_AUCTIONEERS_SEND_DATA, AUCTIONEER_WRONG_TYPE, PDF_SOURCE, UPLOADER_FORCED_USER } from '../../../constants/rails';
import { getFtpLogin } from '../../../redux/slices/userContext/selectors';
import './stylesheet.scss';
import { stats as statsSelector } from '../../../redux/selectors/auctioneers/stats';
import { getStats } from '../../../redux/actions/auctioneers/stats';
import { getAgreement } from '../../../redux/selectors/agreements';
import Spinner from '../../common/spinners/Spinner';

const LazyUppy = lazy(() => import(/* webpackChunkName: "lazyuppy" */ './lazy/Uppy'));

const Docs = () => {
  const dispatch = useDispatch();
  const ftpLogin = useSelector(getFtpLogin);
  const lang = i18next.language;
  const agreement = useSelector(state => getAgreement(state, { idagreementtype: 3 }));
  const stats = useSelector(state => statsSelector(state));

  useEffect(() => {
    dispatch(set(ACTIVE_MENU, MENU_FILES));
    dispatch(set(ACTIVE_AAPI_BEACON_FFROM, ANALYTICS_FROM_AUCTIONEERS_SEND_DATA));
    dispatch(getStats());
  }, []);

  return (
    <div className="auctioneers-docs">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{i18next.t('auctioneers.docs.m1')}</h1>
            <p>{i18next.t('auctioneers.docs.m2')}</p>
            <p>
              <a target="guidlines" href={`${PDF_SOURCE}transfert-data-${lang}.pdf`}>
                {i18next.t('auctioneers.docs.m3')}
              </a>
            </p>
            <p>
              <a target="pdf" href={`${PDF_SOURCE}data-artprice.xls`}>
                {i18next.t('auctioneers.docs.m4')}
              </a>
            </p>

            {stats.error === AUCTIONEER_WRONG_TYPE && (
              <div className="no-access">
                <p>{i18next.t('auctioneers.errors.m1')}</p>
                <p>
                  <a href={`/auctioneer/control-center/${agreement?.signedVersion > 0 ? 'contact?key=m10' : 'agreement'}`}>{i18next.t('auctioneers.errors.m2')}</a>
                </p>
              </div>
            )}

            {stats.error !== AUCTIONEER_WRONG_TYPE && (
              <>
                {ftpLogin && (
                  <Suspense fallback={<Spinner />}>
                    <div className="clear drag-drop-area" />
                    <LazyUppy user={UPLOADER_FORCED_USER || ftpLogin} />
                  </Suspense>
                )}
                {!ftpLogin && (
                  <div className="no-access">
                    <div>
                      {i18next.t('auctioneers.docs.m5')} <a href="/auctioneer/control-center/contact?key=m9">{i18next.t('auctioneers.docs.m6')}</a>.
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Docs;
