/* eslint-disable react/no-danger */

import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { classifiedsForCustomer, classifiedsForCustomerAndArtist } from '../../../../services/marketplace/classifieds';
import { getArtist, getStore } from '../../../../redux/selectors/dbEntities';
import './stylesheet.scss';

const ClassifiedsForCustomer = ({ classified }) => {
  const store = useSelector(state => getStore(state, { id: classified?.idstore }));
  const [classifieds, setClassifieds] = useState();
  const [isClassifiedsForArtist, setIsClassifiedsForArtist] = useState(true);
  const artist = useSelector(state => getArtist(state, { id: classified ? classified.idartist : 0 }), shallowEqual);

  useEffect(() => {
    classifiedsForCustomerAndArtist(classified.id).then(dataWithArtist => {
      if (isEmpty(dataWithArtist.items)) {
        classifiedsForCustomer(classified.id).then(data => {
          setClassifieds(data.items);
        });
      } else {
        setIsClassifiedsForArtist(false);
        setClassifieds(dataWithArtist.items);
      }
    });
  }, [classified]);

  if (!store?.customSitename || isEmpty(classifieds || [])) return null;

  return (
    <div className="marketplace-classified-same-artist-and-customer-list">
      <div className="container">
        {isClassifiedsForArtist && artist && (
          <div
            className="header"
            dangerouslySetInnerHTML={{
              __html: i18next
                .t('marketplace.classified.sameartistandcustomerlist.m1')
                .replace('%artistname%', artist.name)
                .replace('%store%', `<a href="/store/${store.sitenameformated}?idartist[]=${artist.id}">${store.sitename}</a>`),
            }}
          />
        )}
        {!isClassifiedsForArtist && (
          <div
            className="header"
            dangerouslySetInnerHTML={{
              __html: i18next.t('marketplace.classified.sameartistandcustomerlist.m2').replace('%store%', `<a href="/store/${store.sitenameformated}">${store.sitename}</a>`),
            }}
          />
        )}
        <div className="classifieds">
          {classifieds?.map(c => (
            <div className="classified" key={c.id}>
              <div className="img-container">
                <a href={c.url}>
                  <img alt={c.title} src={c.imageUrl[0]} />
                </a>
              </div>
              <p>
                <a href={c.url}>{c.title}</a>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClassifiedsForCustomer;
