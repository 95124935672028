import React from 'react';
import i18next from 'i18next';

const BackButton = () => (
  <div
    className="back-button btn btn-primary"
    onClick={() => {
      window.location = `/${i18next.t('routes.marketplace')}/how-to-bid`;
    }}
    title={i18next.t('marketplace.howtobid.faq.m8')}
  >
    <i className="fa fa-arrow-left" />
  </div>
);

export default BackButton;
