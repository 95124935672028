import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const SaleIdauctioneerSelect = () => (
  <Item label={i18next.t('lots.search.filters.inputs.auctioneer.m1')} name="sale_idauctioneer">
    <Select facet="sale_idauctioneer" placeholder={i18next.t('components.search.inputs.auctioneer_search.m3')} />
  </Item>
);

export default SaleIdauctioneerSelect;
