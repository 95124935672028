import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import A18n from '../../../common/A18n';
import Arrow from '../../../common/Arrow';
import { ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME } from '../../../../constants/rails';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { useViewport } from '../../../../hooks/useViewport';
import '../../../common/stylesheets/buttons.scss';
import './stylesheet.scss';

const Faq = () => {
  const { viewportWidth } = useViewport();
  const { from } = useSelector(getSettings);

  return (
    <div className="subscriptions-list-faq block">
      <h2 className="title">
        <div>
          <A18n rsx="homepage.faq.m1" />
        </div>
        <div className="all">
          <a
            href="/contact"
            target="_blank"
            onClick={() =>
              aapiBeacon('click', {
                from,
                op: ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME,
              })
            }
          >
            <A18n rsx="subscriptions.list.faq.m1" />
            <Arrow style={{ marginLeft: 5 }} />
          </a>
        </div>
      </h2>

      <div className="sections">
        <div className="section">
          <div className="title">
            <A18n rsx="subscriptions.list.faq.m2" />
          </div>
          <div className="lnk">
            <a
              href="/contact?active=block1&id=387"
              className={classNames({ 'artp-btn': viewportWidth >= 768 })}
              target="_blank"
              onClick={() =>
                aapiBeacon('click', {
                  from,
                  op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS,
                  section: 387,
                })
              }
            >
              <A18n rsx="subscriptions.list.faq.m3" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="subscriptions.list.faq.m4" />
          </div>
          <div className="lnk">
            <a
              href="/contact?active=block1&id=390"
              className={classNames({ 'artp-btn': viewportWidth >= 768 })}
              target="_blank"
              onClick={() =>
                aapiBeacon('click', {
                  from,
                  op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS,
                  section: 390,
                })
              }
            >
              <A18n rsx="subscriptions.list.faq.m3" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="subscriptions.list.faq.m6" />
          </div>
          <div className="lnk">
            <a
              href="/contact?active=block1&id=393"
              className={classNames({ 'artp-btn': viewportWidth >= 768 })}
              target="_blank"
              onClick={() =>
                aapiBeacon('click', {
                  from,
                  op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS,
                  section: 393,
                })
              }
            >
              <A18n rsx="subscriptions.list.faq.m3" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="subscriptions.list.faq.m7" />
          </div>
          <div className="lnk">
            <a
              href="/contact?active=block5&id=411"
              className={classNames({ 'artp-btn': viewportWidth >= 768 })}
              target="_blank"
              onClick={() =>
                aapiBeacon('click', {
                  from,
                  op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS,
                  section: 411,
                })
              }
            >
              <A18n rsx="subscriptions.list.faq.m3" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
