import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailStatus, isLogged } from '../../../../redux/slices/userContext/selectors';
import { CUSTOMER_EMAIL_STATUS_VALID } from '../../../../constants/rails';
import UnvalidatedEmailDrawer from './drawers/UnvalidatedEmailDrawer';
import { off, on } from '../../../../redux/actions/ui/switches';
import NoAccessDrawer from './drawers/NoAccessDrawer';
import './stylesheet.scss';

const ID_DRAWER = 'id_drawer_reports';

const LoggedLink = ({ slug, reportType, loginRequired, children, target, defaultOpen = false }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const emailStatus = useSelector(getEmailStatus);

  // pas trouvé de moyen de partager la route entre rails et js
  const url = `/artprice-reports/pdf/${reportType}/${slug}.pdf`;
  const locked = loginRequired && (!logged || emailStatus !== CUSTOMER_EMAIL_STATUS_VALID);

  const handleOpenDrawer = () => {
    dispatch(on(ID_DRAWER));
  };

  const handleCloseDrawer = () => {
    dispatch(off(ID_DRAWER));
  };

  useEffect(() => {
    if (defaultOpen) {
      dispatch(on(ID_DRAWER));
    }
  }, []);

  return (
    <div className="report-reports-loggedLink">
      {locked && (
        <>
          {!logged && <NoAccessDrawer drawerId={ID_DRAWER} onClose={handleCloseDrawer} />}
          {logged && emailStatus !== CUSTOMER_EMAIL_STATUS_VALID && <UnvalidatedEmailDrawer drawerId={ID_DRAWER} onClose={handleCloseDrawer} />}
          <a className="link" onClick={handleOpenDrawer}>
            {children}
          </a>
        </>
      )}
      {!locked && (
        <a href={url} target={target} className="link">
          {children}
        </a>
      )}
    </div>
  );
};

export default LoggedLink;
