/* eslint-disable global-require */
module.exports = [
  'artists.artist.header.signatures.m1',
  'artists.artist.header.signatures.m2',
  'artists.artist.header.signatures.m7',
  'artists.artist.header.signatures.m4',
  'artists.artist.header.signatures.m5',
  'artists.artist.header.signatures.m8',
  'artists.artist.header.signatures.m6',
];
