import i18next from 'i18next';
import * as Yup from 'yup';
import { fileSize6M, idDocumentRequired, fileFormat } from '../validationFns';

Yup.setLocale({
  mixed: {
    required: i18next.t('components.account.verify.identity.m1'),
    typeError: i18next.t('components.account.verify.identity.m10'),
    default: i18next.t('components.account.verify.identity.m2'),
  },
});

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required(),
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  birthDate: Yup.date().required(),
  line1: Yup.string().trim().required(),
  line2: Yup.string(),
  line3: Yup.string(),
  zipCode: Yup.string().trim().required(),
  city: Yup.string().trim().required(),
  state: Yup.string(),
  country: Yup.string().required(),
  phone: Yup.string().trim().required(), // FIXME: find regexp
  idDocument: Yup.mixed().test(idDocumentRequired).test(fileSize6M).test(fileFormat),
  idDocument2: Yup.mixed().test(fileSize6M).test(fileFormat),
  idDocumenttype: Yup.string().required(),
});

export default ValidationSchema;
