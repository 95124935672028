import React from 'react';
import Dropdown from '../Dropdown';
import {
  ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_SHOP_HOME,
  ANALYTICS_FROM_HEADER_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_AIL_HOWTO,
} from '../../../../../../constants/rails';
import A18n from '../../../../../common/A18n';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import { a18n } from '../../../../../../utils/a18n';

function aapiWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_PRICING, op });
}

const Pricing = () => (
  <Dropdown
    className="artp-Header-item artp-Header-top-item"
    button={
      <div className="artp-Header-Pricing">
        <A18n rsx="layouts.menu.m16" />
      </div>
    }
    content={
      <ul className="link-list">
        <li>
          <a className="link-list-item" href="/subscription" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_PRICING)}>
            <A18n rsx="layouts.menu.m17" />
          </a>
        </li>
        <li>
          <a className="link-list-item" href="/subscription/store" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING)}>
            <A18n rsx="layouts.menu.m19" />
          </a>
        </li>
        <li>
          <a className="sln-menu-estimate link-list-item" href={`/${a18n('routes.estimate')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME)}>
            <A18n rsx="layouts.menu.m18" />
          </a>
        </li>
        <li>
          <a className="link-list-item" href="/webapp#!/shop/list" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_SHOP_HOME)}>
            <A18n rsx="layouts.menu.m21" />
          </a>
        </li>
        <li>
          <a className="link-list-item" href="/ail" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AIL_HOWTO)}>
            <A18n rsx="layouts.header.m49" />
          </a>
        </li>
      </ul>
    }
  />
);

export default Pricing;
