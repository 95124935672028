import React from 'react';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';

const Links = ({ estimate, hasSubscriptionStore, lastUrl }) => {
  if (estimate)
    return (
      <a id="sln-return" href="/account/estimate" className="artp-btn artp-btn-purple">
        <A18n rsx="payments.ok.links.m1" />
      </a>
    );

  if (lastUrl)
    return (
      <a id="sln-return" href={lastUrl} className="artp-btn artp-btn-purple">
        <A18n rsx="payments.ok.links.m2" />
      </a>
    );

  if (hasSubscriptionStore)
    return (
      <a id="sln-return" href="/marketplace" className="artp-btn artp-btn-purple">
        <A18n rsx="payments.ok.links.m3" />
      </a>
    );

  return (
    <a id="sln-return" href="/account" className="artp-btn artp-btn-purple">
      <A18n rsx="payments.ok.links.m4" />
    </a>
  );
};

export default Links;
