import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { getGeographicalData, getGeographicalDisplayedChartTab, isGeographicalDataLoading } from '../../../../redux/selectors/indexes/geographical';
import { TAB_CA } from '../../../../constants/indexes/geographical';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { CURRENCIES } from '../../../../constants/preferences';
import Spinner from '../../../common/spinners/Spinner/index';
import CustomToolTip from './CustomToolTip';

const Chart = ({ intl }) => {
  const isDataLoading = useSelector(isGeographicalDataLoading);
  const rawData = useSelector(getGeographicalData);
  const [data, setData] = useState([]);
  const currency = useSelector(getCurrency);
  const chartTab = useSelector(getGeographicalDisplayedChartTab);

  useEffect(() => {
    if (rawData) {
      const simplifiedData = [];
      const sumUp = (accumulator, currentEl) => accumulator + currentEl.value;
      const hundredPercent = rawData.reduce(sumUp, 0);
      let eightyPercent = hundredPercent * 0.98;
      const eightPercent = eightyPercent * 0.1;
      const sortedData = [...rawData].sort((el, nextEl) => nextEl.value - el.value);
      const others = { name: i18next.t('indexes.others'), value: 0 };

      for (let i = 0; i < sortedData.length; i += 1) {
        const currentEl = sortedData[i];

        if ((eightyPercent <= 0 && (i < sortedData.length - 1 || others.value > 0)) || (currentEl.value < eightPercent && simplifiedData.length >= 8)) {
          others.value += currentEl.value;
        } else {
          simplifiedData.push(currentEl);
          eightyPercent -= currentEl.value;
        }
      }

      const toReturn = rawData.filter(item => simplifiedData.includes(item)).sort((a, b) => a.name.localeCompare(b.name, i18next.language, { sensitivity: 'base' }));

      if (others.value > 0) {
        toReturn.push(others);
      }

      setData(toReturn);
    }
  }, [rawData]);

  if (isDataLoading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }

  if (!rawData) {
    return <div style={{ height: 400 }} />;
  }

  const CustomizedTick = value => {
    if (chartTab === TAB_CA) {
      if (value / 1000000 >= 1) {
        return `${intl.formatNumber(value / 1000000, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })} m${CURRENCIES[currency].iso1}`;
      }

      if (value / 1000 >= 1) {
        return `${intl.formatNumber(value / 1000, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })} K${CURRENCIES[currency].iso1}`;
      }

      return `${intl.formatNumber(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })} ${CURRENCIES[currency].iso1}`;
    }

    return intl.formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} layout="vertical" barCategoryGap={1} margin={{ top: 5, right: 5, bottom: 5, left: -40 }}>
        <Tooltip content={<CustomToolTip />} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" dataKey="value" tickFormatter={CustomizedTick} />
        <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />
        <Bar barSize={25} dataKey="value" fill="#36c" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(Chart);
