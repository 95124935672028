import isUndefined from 'lodash/isUndefined';
import parseISO from 'date-fns/parseISO';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';

/*
 Convert a string or date to a valid date-fns date
 */
export const convert = date => {
  if (isUndefined(date)) {
    return undefined;
  }

  if (isDate(date)) {
    return date;
  }

  const parsedDate = parseISO(date);
  if (isValid(parsedDate)) {
    return parsedDate;
  }

  return new Date(date);
};
