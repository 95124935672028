import React, { useCallback, useMemo } from 'react';
import i18next from 'i18next';
import ReactSelect from 'react-select';
import { isEmpty, isNil, isPlainObject } from 'lodash';
import { useSelector } from 'react-redux';
import { areDraftFacetsLoading, getAdvancedFacets, getBaseFacets, getDraftFacets, getDraftParams } from '../../../../../redux/slices/search/scoped';
import customStyles from '../../../common/reactSelect/style';

import './stylesheet.scss';
import TechniqueSmallView from '../../../../common/dbEntitiesSmallViews/Technique';
import CategorySmallView from '../../../../common/dbEntitiesSmallViews/Category';
import Auctioneer from '../../../../common/dbEntitiesSmallViews/Auctioneer';
import Country from '../../../../common/dbEntitiesSmallViews/Country';
import ArtistSmallView from '../../../../common/dbEntitiesSmallViews/Artist';

function sortFunc(a, b) {
  return String(a.sort || a.label || a.id).localeCompare(String(b.sort || b.label || b.id));
}

function getSelectedValue(value, options) {
  if (!value) {
    return null;
  }
  if (Array.isArray(value)) {
    if (value.length < 1) {
      return null;
    }
    return value.map(v => getSelectedValue(v, options));
  }
  return options.find(option => option.stringValue === String(value)) ?? null;
}

function getFallbackComponent(facet, value) {
  switch (facet) {
    case 'idtechnique':
      return <TechniqueSmallView id={value} />;
    case 'idcategory':
      return <CategorySmallView id={value} />;
    case 'idauctioneer':
      return <Auctioneer id={value} />;
    case 'idcountry':
      return <Country id={value} />;
    case 'idartist':
      return <ArtistSmallView id={value} />;
    default:
      return `${facet} #${value}`;
  }
}

const Select = ({ className, facet, searchScope, value, onChange, placeholder, isMulti = true, hideCount = false, disabled = false }) => {
  const isLoading = useSelector(state => areDraftFacetsLoading(state, { searchScope }));
  const draftParams = useSelector(state => getDraftParams(state, { searchScope }));
  const baseFacets = useSelector(state => getBaseFacets(state, { searchScope, facet }));
  const advancedFacets = useSelector(state => getAdvancedFacets(state, { searchScope, facet }));
  const draftFacets = useSelector(state => getDraftFacets(state, { searchScope, facet }));

  const options = useMemo(() => {
    const keysToKeep = Object.keys(draftFacets ?? []);
    if (Array.isArray(draftParams?.[facet])) {
      keysToKeep.push(...draftParams[facet]);
    } else if (draftParams?.[facet]) {
      keysToKeep.push(draftParams[facet]);
    }

    // in the first map : we have at least the id for the fallback, and maybe the content of a facet (no label = fallback (fetch))
    return keysToKeep
      .map(facetKey => ({ ...(baseFacets?.[facetKey] || advancedFacets?.[facetKey] || draftFacets?.[facetKey] || {}), id: facetKey }))
      .sort(sortFunc)
      .map(({ id, label, outOfBound }) => ({
        value: id,
        label,
        outOfBound,
        count: draftFacets?.[id]?.count || 0,
        stringValue: String(id), // for selectedValue
      }));
  }, [baseFacets, advancedFacets, draftFacets, draftParams]);

  const formatOptionLabel = useCallback(
    ({ value: v, label, count, outOfBound }) => {
      if (label) {
        return `${label}${hideCount ? '' : ` [${outOfBound ? '+ ' : ''}${count}]`}`;
      }
      return (
        <>
          {getFallbackComponent(facet, v)}
          {!hideCount && ` [${outOfBound ? '+ ' : ''}${count}]`}
        </>
      );
    },
    [facet, hideCount],
  );

  const selectedValue = getSelectedValue(value, options, isMulti);
  // blurInputOnSelect is activated because there is a small problem on ff
  // small problem = when selecting something, you can't 'reclick' on the input but only on the down arrow
  // Check on v5.2.1+ if it is resolved
  return (
    <ReactSelect
      styles={customStyles(0)}
      noOptionsMessage={() => (selectedValue ? i18next.t('search.inputs.select.m2') : i18next.t('search.inputs.select.m1'))}
      classNamePrefix="react-select"
      className={`search-select ${className ?? ''}`.trim()}
      isLoading={isLoading}
      isDisabled={disabled || isLoading}
      options={options}
      isClearable
      isMulti={isMulti}
      placeholder={placeholder}
      value={selectedValue && (Array.isArray(selectedValue) ? selectedValue : [selectedValue])}
      getOptionValue={({ stringValue }) => stringValue}
      formatOptionLabel={formatOptionLabel}
      blurInputOnSelect
      onChange={values => {
        if (Array.isArray(values)) {
          onChange(isEmpty(values) ? undefined : values.map(({ value: v }) => v));
        } else if (isPlainObject(values)) {
          onChange(values.value);
        } else {
          onChange(isNil(values) ? undefined : values);
        }
      }}
    />
  );
};

export default Select;
