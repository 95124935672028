/* eslint-disable global-require */
module.exports = [
  'components.myartprice.settings.sections.m3',
  'components.myartprice.settings.sections.m4',
  'components.myartprice.settings.sections.m5',
  'components.myartprice.settings.sections.m6',
  'components.myartprice.settings.sections.m7',
  'components.myartprice.settings.sections.m8',
  'components.myartprice.settings.sections.m9',
  'components.myartprice.settings.sections.m10',
];
