import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import classNames from 'classnames';
import CommonCountryDropdown from '../../../common/inputs/CountryDropdown';
import ErrorBlock from '../../../common/ErrorBlock';
import A18n from '../../../common/A18n';

const CountryDropdown = ({ control, errors, requiredValue, handleCountryChange }) => (
  <FormGroup row>
    <Label for="idcountry" sm={4} className={classNames({ required: requiredValue })}>
      <A18n rsx="marketplace.reporting.countrydropdown.m1" />
    </Label>
    <Col sm={5}>
      <CommonCountryDropdown
        control={control}
        width="100%"
        className="form-control"
        name="idcountry"
        onChange={handleCountryChange}
        rules={{
          required: {
            value: requiredValue,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
        }}
      />
      <ErrorBlock error={errors.idcountry} />
    </Col>
  </FormGroup>
);

export default CountryDropdown;
