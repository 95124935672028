import React from 'react';
import { pickBy, identity, isPlainObject, isEmpty, flattenDeep } from 'lodash';
import { a18n, getOptions, DEFAULT_OPTIONS } from '../../../utils/a18n';

// we only set the "renderAsComponents" option to true when there is a component in the replace regexp replacements
function determineRenderAsComponentsOption(replace) {
  return flattenDeep(replace).some(elem => typeof elem === 'object');
}

/*
replace: array of tuple ([[regexp, str], [regexp2, str2], ...])
 */
const A18n = ({ rsx, mode, replace = DEFAULT_OPTIONS.replace, link = DEFAULT_OPTIONS.link, trustHtml = false, ...otherProps }) => {
  const options = getOptions(pickBy({ mode, replace, link, renderAsComponents: determineRenderAsComponentsOption(replace) }, identity));

  let children = a18n(rsx, options);
  if (trustHtml) {
    if (Array.isArray(children)) {
      children = children.map((subchild, idx) => {
        if (typeof subchild === 'object') {
          return subchild;
        }
        // eslint-disable-next-line react/no-danger,react/no-array-index-key
        return <span key={`rsx_${rsx}_${idx}`} dangerouslySetInnerHTML={{ __html: subchild }} />;
      });
    } else {
      // eslint-disable-next-line react/no-danger
      children = <span dangerouslySetInnerHTML={{ __html: children }} />;
    }
  }

  if (options.link) {
    return (
      <a className="gs" href={`https://site-bo.backoffice.artprice.io/translate/translate/search?query={"translate":{"rsxkey":{"eq":"${rsx}"}}}`} target="_blank" rel="noreferrer" {...otherProps}>
        {children}
      </a>
    );
  }

  if (isPlainObject(otherProps) && !isEmpty(otherProps)) {
    return <span {...otherProps}>{children}</span>;
  }

  return children;
};

export default A18n;
