import { internalAxios } from '../../../utils/axios/internal';

export const getDefaults = async () => {
  const response = await internalAxios.get('/api/marketplace/howto/get_defaults.json');
  return response.data;
};

export const getAll = async () => {
  const response = await internalAxios.get('/api/marketplace/howto/get_all.json');
  return response.data;
};

export const legals = async () => {
  const response = await internalAxios.get('/api/marketplace/howto/legals.json');
  return response.data;
};
