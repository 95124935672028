import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areThemesLoading, getThemes } from '../../../redux/selectors/subscriptions/physicproducts';
import { fetchPhysicproducts } from '../../../redux/actions/subscriptions/physicproduct';
import { updateSettings } from '../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../redux/selectors/subscriptions/settings';
import WarningsPurchaseNotAvailable from './warnings/PurchaseNotAvailable';
import { SUBSCRIPTION } from '../Purchase/common/constants';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_CARD, ANALYTICS_SECTION_SUBSCRIPTIONS_ESTIMATE, ANALYTICS_SECTION_SUBSCRIPTIONS_FAQ, THEME_STORE } from '../../../constants/rails';
import WarningModale from './warnings/WarningModale';
import { THEMES_ORDER } from './common/constants';
import Identification from './Identification';
import Promotion from './Promotion';
import Estimate from './Estimate';
import Contact from './Contact';
import Header from './Header';
import Params from './Params';
import Cards from './Cards';
import Table from './Table';
import Faq from './Faq';
import DOMView from '../../common/observers/DOMView';

const List = () => {
  const dispatch = useDispatch();
  const { filter, quantity, promocode, period, view, reload, from } = useSelector(getSettings);
  const themesAvailableForClient = useSelector(getThemes);
  const loading = useSelector(areThemesLoading);
  const [themes, setThemes] = useState([]);
  const [iso3, setIso3] = useState('eur');

  useEffect(() => {
    dispatch(
      fetchPhysicproducts({
        filter,
        promocode,
        quantity,
      }),
    );
  }, [filter, promocode, period, quantity]);

  useEffect(() => {
    if (!loading && themesAvailableForClient) {
      const internalThemes = [];

      dispatch(
        updateSettings({
          data: {
            hasAuctioneerDiscount: themesAvailableForClient.has_auctioneer_discount,
            hasLoyaltyDiscount: themesAvailableForClient.has_loyalty_discount,
            hasOnedayRefund: themesAvailableForClient.has_oneday_refund,
            nbYearsCustomerLoyalty: themesAvailableForClient.nb_years_customer_loyalty,
          },
        }),
      );

      if (isEmpty(themesAvailableForClient.error)) {
        if (view === 'upgrade' && !themesAvailableForClient.upgrade && reload < 3) {
          dispatch(updateSettings({ data: { quantity: quantity + 1, reload: reload + 1 } }));
        } else {
          THEMES_ORDER.forEach(idtheme => {
            if (filter === SUBSCRIPTION && idtheme === THEME_STORE) return;

            const internalTheme = themesAvailableForClient.new?.products.filter(t => t.idtheme === idtheme)[0];
            const internalUpgrade = themesAvailableForClient.upgrade?.products.filter(t => t.idtheme === idtheme)[0];

            // new physicproduct props to simplify other components
            if (internalTheme)
              internalThemes.push({
                ...internalTheme,
                promotions: {},
                product: internalTheme?.physicproducts.find(p => p.original_nbdays === period),
                upgrade: internalUpgrade?.physicproducts.find(p => p.original_nbdays === period),
                ...{ active: true },
              });
          });

          setIso3(themesAvailableForClient.customerCurrency.iso3.toLowerCase());
          setThemes(internalThemes);
        }
      }
    }
  }, [loading, themesAvailableForClient, period]);

  return (
    <div className="subscriptions-list">
      <WarningsPurchaseNotAvailable />
      <Promotion position="top" />
      <div className="container">
        <div className="list-header">
          <Header />
        </div>
        <Params />
        <Cards themes={themes} iso3={iso3} />
        <Contact />
        <div id="subscription-table">
          <DOMView from={from} section={ANALYTICS_SECTION_SUBSCRIPTIONS_CARD} selector="#subscription-table" />
        </div>
        <Table themes={themes} iso3={iso3} />
      </div>
      <div id="subscription-estimate">
        <DOMView from={from} section={ANALYTICS_SECTION_SUBSCRIPTIONS_ESTIMATE} selector="#subscription-estimate" />
      </div>
      <Estimate />
      <div className="container">
        <div id="subscription-faq">
          <DOMView from={from} section={ANALYTICS_SECTION_SUBSCRIPTIONS_FAQ} selector="#subscription-faq" />
        </div>
        <Faq />
        <Identification />
        <WarningModale />
      </div>
      <Promotion />
    </div>
  );
};

export default List;
