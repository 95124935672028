import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ARTISTS_OFFICIAL_COUNT, AUCTIONEERS_OFFICIAL_COUNT } from '../../../constants/rails';
import { get } from '../../../redux/slices/userContext/selectors';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Header = () => {
  const { idcustomer } = useSelector(get);
  const intl = useIntl();

  return (
    <div className={classNames({ co: idcustomer, 'homepage-header': true })}>
      {!idcustomer && (
        <div className="container">
          <h1>
            <A18n rsx="homepage.header.m1" replace={[['%count%', intl.formatNumber(ARTISTS_OFFICIAL_COUNT)]]} />
          </h1>
          <p>
            <A18n rsx="homepage.header.m2" replace={[['%count%', intl.formatNumber(AUCTIONEERS_OFFICIAL_COUNT)]]} />
          </p>
        </div>
      )}
    </div>
  );
};
export default Header;
