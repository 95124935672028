import i18next from 'i18next';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import CountryDropdown from '../../../../common/inputs/CountryDropdown';
import TitleDropdown from '../../../../common/inputs/TitleDropdown';
import { getCountry } from '../../../../../services/wallet/country';
import A18n from '../../../../common/A18n';
import Input from '../common/Input';
import Tvaintra from './Tvaintra';
import Phone from './Phone';

const AddressFields = ({ type }) => {
  const { setValue, defaultValues, watch, control } = useFormContext();
  const { verifiedAt, idprofessionaltype } = useSelector(getUserContext);
  const [firm, setFirm] = useState(defaultValues?.[type].firm);
  const [tvaFormat, setTvaFormat] = useState(type === 'billaddress' ? defaultValues?.billaddress.tvaformatregex : null);
  const [iso2codeCountry, setIso2codeCountry] = useState();
  const lockedForPersonalType = !isUndefined(verifiedAt || undefined);
  const lockedForProfessionalType = !isUndefined(verifiedAt || undefined) && idprofessionaltype === 1;

  // UseEffect to change state depending on form values
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === `${type}.firm`) setFirm(values[type].firm);
      if (name === `${type}.idcountry`) {
        getCountry({ id: values[type].idcountry }).then(({ tvaformatregex, iso2code }) => {
          setIso2codeCountry(iso2code);

          if (type !== 'billaddress') return;

          if (tvaformatregex) {
            setValue('billaddress.tvaformatregex', tvaformatregex);
            setTvaFormat(tvaformatregex);
          } else {
            setValue('billaddress.tvaformatregex', null);
            setTvaFormat(null);
          }
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {type === 'billaddress' && (
        <>
          <h3>
            <A18n rsx="payments.informations.form.fields.m1" />
          </h3>
          {verifiedAt && (
            <div className="verified">
              <A18n rsx="payments.informations.form.fields.m13" trustHtml />
            </div>
          )}
        </>
      )}
      {type === 'shipaddress' && (
        <h3>
          <A18n rsx="payments.informations.form.fields.m2" />
        </h3>
      )}
      <div className="group">
        <Input locked={lockedForPersonalType} name={`${type}.idtitle`}>
          <TitleDropdown control={control} name={`${type}.idtitle`} readOnly={lockedForPersonalType} />
        </Input>
        <Input locked={lockedForPersonalType} name={`${type}.firstname`} placeholder={i18next.t('payments.informations.form.fields.m4')} />
        <Input locked={lockedForPersonalType} name={`${type}.lastname`} placeholder={i18next.t('payments.informations.form.fields.m5')} />
      </div>

      {(!lockedForPersonalType || lockedForProfessionalType) && (
        <div className="group">
          <Input locked={lockedForProfessionalType} name={`${type}.firm`} placeholder={i18next.t('payments.informations.form.fields.m6')} />
        </div>
      )}

      <div className="group" style={{ marginTop: 30 }}>
        <Input locked={lockedForProfessionalType} name={`${type}.line1`} placeholder={i18next.t('payments.informations.form.fields.m7')} />
      </div>
      <div className="group">
        <Input locked={lockedForProfessionalType} name={`${type}.line2`} placeholder={i18next.t('payments.informations.form.fields.m12')} />
      </div>
      <div className="group">
        <Input locked={lockedForProfessionalType} name={`${type}.postcode`} placeholder={i18next.t('payments.informations.form.fields.m8')} />
        <Input locked={lockedForProfessionalType} name={`${type}.city`} placeholder={i18next.t('payments.informations.form.fields.m9')} />
      </div>
      <div className="group">
        <Input locked={lockedForProfessionalType} name={`${type}.state`} placeholder={i18next.t('payments.informations.form.fields.m11')} />
      </div>
      <div className="group">
        <Input locked={lockedForPersonalType} name={`${type}.idcountry`}>
          <CountryDropdown name={`${type}.idcountry`} control={control} readOnly={lockedForPersonalType} placeholder={i18next.t('payments.informations.form.fields.m10')} />
        </Input>
      </div>
      <div className="group" style={{ marginTop: 30 }}>
        <Phone type={type} locked={lockedForProfessionalType} iso2Code={iso2codeCountry} />
        {type === 'billaddress' && firm && tvaFormat && <Tvaintra locked={lockedForProfessionalType} tvaFormat={tvaFormat} />}
      </div>
    </>
  );
};
export default AddressFields;
