import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import React from 'react';
import { clearSendingStatus } from '../../../../redux/actions/auctioneers/contact';
import Modal from '../../../common/ui/Modal';

import './stylesheet.scss';

const ContactModale = ({ open }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Modal className="auctioneer-contact-message-sent" title={i18next.t('auctioneers.contact.messagesent.m2')} open={open} onClose={() => dispatch(clearSendingStatus())}>
        <p>{i18next.t('auctioneers.contact.messagesent.m1')}</p>
        <div className="bottom">
          <button className="btn btn-primary" type="button" onClick={() => dispatch(clearSendingStatus())}>
            {i18next.t('auctioneers.contact.messagesent.m3')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactModale;
