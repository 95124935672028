import 'intersection-observer'; // polyfill
import { useCallback, useEffect, useRef, useState } from 'react';

// From https://stackoverflow.com/questions/75211601/check-if-element-is-outside-window-react
export const useOnScreen = (rootMargin = '0px') => {
  const observer = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

  // disconnect observer on unmount
  useEffect(() => () => observer.current?.disconnect(), []);

  const observeCallbackRef = useCallback(
    element => {
      // init observer if one doesn't exist
      if (!observer.current)
        observer.current = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          { rootMargin },
        );

      // observe an element
      if (element) observer.current.observe(element);
    },
    [rootMargin],
  );

  return [isIntersecting, observeCallbackRef];
};
