import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import Store from '..';

const Header = ({ idstore, displayEmptyTabs = false }) => (
  <ReduxProvider>
    <Store idstore={idstore} displayEmptyTabs={displayEmptyTabs} />
  </ReduxProvider>
);

export default Header;
