import React from 'react';
import { useCookies } from 'react-cookie';
import './stylesheet.scss';

const Breadcrumb = () => {
  const [{ navigation }] = useCookies(['navigation']);

  if (!navigation) return null;

  return (
    <div className="breadcrumb">
      <div className="container">
        <nav>
          <ol>
            {navigation?.map(({ title, url }) => (
              <li key={url}>
                <a href={url}>{title}</a>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumb;
