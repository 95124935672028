import React from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import ErrorBlock from '../ErrorBlock';

const Input = ({ name, locked, placeholder, children, className }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={classNames({ cell: true, locked, warning: !isEmpty(get(errors, name)), [className]: !!className })} title={placeholder}>
      {children}
      {!children && <input type="text" {...register(name)} placeholder={placeholder} disabled={locked} />}
      <ErrorBlock error={get(errors, name)} />
    </div>
  );
};
export default Input;
