import React from 'react';
import { get } from 'lodash/object';
import { isArray, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUrlFacets as getFacetsSelector, getCurrentUrlParams as getInitialParamsSelector } from '../../../../../redux/selectors/search';
import { getOverflownParams } from '../../../../../redux/selectors/search/ui';
import { endOfMouseHoverSelectedParam, startOfMouseHoverSelectedParam } from '../../../../../redux/actions/marketplace/searchbars/ui';
import { cleanPathname, objectToQueryString } from '../../../../../utils/http';
import './stylesheet.scss';
import { applyCurrentNewParams } from '../../../../../redux/actions/search';

const Facet = ({ redirect, identifier, facetName }) => {
  const dispatch = useDispatch();
  const initialParams = useSelector(getInitialParamsSelector);
  const facets = useSelector(getFacetsSelector);
  const overflownParams = useSelector(getOverflownParams);

  const deleteParam = (param, children) => {
    let params = { ...initialParams };

    if (children) {
      children.forEach(child => {
        if (facets[child]) {
          Object.entries(facets[child]).forEach(([idFacet, element]) => {
            if (parseInt(element.idParent, 10) === parseInt(param, 10)) {
              if (params[child]) {
                if (isArray(params[child])) {
                  params[child] = params[child].filter(idtoFilter => parseInt(idtoFilter, 10) !== parseInt(idFacet, 10));
                } else {
                  delete params[child];
                }
              }
            }
          });
        }
      });
    }

    if (isArray(params[facetName])) {
      params[facetName] = params[facetName].filter(paramToFilter => paramToFilter !== param);
    } else {
      params = omit(params, [facetName]);
    }

    if (redirect) {
      window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString(params)}`;
    } else {
      dispatch(applyCurrentNewParams({ params }));
    }
  };

  const handleMouseEnter = (id, children) => {
    const overflownToAdd = {};
    overflownToAdd[`${facetName}-${id}`] = 'overflew';

    if (children) {
      children.forEach(child => {
        if (facets[child]) {
          Object.entries(facets[child]).forEach(([idFacet, element]) => {
            if (parseInt(element.idParent, 10) === parseInt(id, 10)) {
              if (initialParams[child]) {
                overflownToAdd[`${child}-${idFacet}`] = 'hoverflew';
              }
            }
          });
        }
      });
    }

    dispatch(startOfMouseHoverSelectedParam({ ...overflownParams, ...overflownToAdd }));
  };

  const facet = get(facets, `${facetName}.${parseInt(identifier, 10)}`);

  if (!facet) return null;

  return (
    <span className="search-common-selected-params-facet">
      <a
        href="#"
        id={`${facetName}-${identifier}`}
        className={overflownParams[`${facetName}-${identifier}`]}
        onMouseEnter={() => handleMouseEnter(identifier, facet.children)}
        onMouseLeave={() => dispatch(endOfMouseHoverSelectedParam())}
        onClick={() => deleteParam(identifier, facet.children)}
      >
        {facet.label}
        {overflownParams[`${facetName}-${identifier}`] && <i className="fa fa-trash-o" />}
        {!overflownParams[`${facetName}-${identifier}`] && <i className="fa fa-times" />}
      </a>
    </span>
  );
};

export default Facet;
