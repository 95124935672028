import React, { useState } from 'react';
import { useViewport } from '../../../../hooks/useViewport';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Filters = ({ children, style }) => {
  const [displayFilters, setDisplayFilters] = useState(false);
  const { viewportWidth } = useViewport();

  if (viewportWidth >= 768) {
    return (
      <div className="indexes-common-filters" style={style}>
        <div className="filters">{children}</div>
      </div>
    );
  }

  return (
    <div className="indexes-common-filters with-openner" style={style}>
      <div className="display-selector">
        <span className="btn btn-primary" onClick={() => setDisplayFilters(!displayFilters)}>
          <i className={`fa fa-filter ${displayFilters ? 'open' : ''}`} aria-hidden="true" title={<A18n rsx="indexes.common.filters.m1" />} />
        </span>
      </div>

      <div className={`filters ${displayFilters ? 'open' : ''}`}>{children}</div>
    </div>
  );
};

export default Filters;
