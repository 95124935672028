import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { on as openDrawer } from '../../../../../../redux/actions/ui/switches';
import Sort from '../../../../common/Sort';
import ClosingDropDown from '../../../../common/ClosingDropDown';
import DisplayedCurrencies from '../../../../filters/DisplayedCurrencies';
import {
  AUCTIONEER_SALE_LOTS,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_MARKETPLACE,
} from '../../../../../../constants/search/scope';
import PerPage from '../../../../filters/PerPage';
import Freshness from '../../../../filters/Freshness';
import {
  LOTS as LOTS_SORTS,
  MARKETPLACE_DEFAULT,
  MYARTPRICE_LOTS_AUCTIONED_OFF as MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS,
  MYARTPRICE_LOTS_FUTURE as MYARTPRICE_LOTS_FUTURE_SORTS,
} from '../../../../../../constants/search/sorts';
import AddToListButton from '../../../../../account/Lotslists/AddToListButton';
import { getCurrentResultsData } from '../../../../../../redux/selectors/search/results';

const FilterMobile = ({ currentScope, infiniteScroll, redirect }) => {
  const dispatch = useDispatch();
  const results = useSelector(getCurrentResultsData);

  const mapScopeToSortOptions = () => {
    let sortOptions;
    switch (currentScope) {
      // Artist follow
      case MYARTPRICE_LOTS_AUCTIONED_OFF:
        sortOptions = MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS;
        break;
      case MYARTPRICE_LOTS_FUTURE_NEW:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_FUTURE_ALL:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_MARKETPLACE:
        sortOptions = MARKETPLACE_DEFAULT;
        break;
      // Lot follow
      case MYARTPRICE_LOTS_FOLLOW_FUTURE:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_FOLLOW_PAST:
        sortOptions = MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS;
        break;
      case MYARTPRICE_LOTS_FOLLOW_MARKETPLACE:
        sortOptions = MARKETPLACE_DEFAULT;
        break;
      default:
        sortOptions = LOTS_SORTS;
        break;
    }
    return sortOptions;
  };

  const sortOptions = mapScopeToSortOptions();

  return (
    <>
      <div className="filters-mobile">
        {/* Filters */}
        <button id="filter" type="button" onClick={() => dispatch(openDrawer('myartprice-vertical-bar'))}>
          {i18next.t('component.myartprice.lot.m9')} <i className="fa fa-caret-down" />
        </button>

        <div className="flex-filler" />

        {/* Sort */}
        <Sort options={sortOptions} redirect={redirect} right />

        {/* Display Preferences */}
        <ClosingDropDown
          right
          title={i18next.t('marketplace.searchbars.classifieds.bar.m7')}
          icon="cogs"
          displayIconDesktop
          render={() => (
            <>
              <DisplayedCurrencies />
              {![AUCTIONEER_SALE_LOTS].includes(currentScope) && !infiniteScroll && <PerPage />}
              {[MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW].includes(currentScope) && <Freshness />}
            </>
          )}
        />
      </div>
      {[MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_AUCTIONED_OFF].includes(currentScope) && (
        <div className="filters-mobile">
          <AddToListButton ids={results?.idlot || []} newSelectionKey="myartprice" className="myartprice-addtolist-button" />
        </div>
      )}
    </>
  );
};

export default FilterMobile;
