import { LOCALE, YOUTUBE, INDEXES, SEARCHES, ARTPRICE } from '../../../constants/howto';

export default {
  [INDEXES]: {
    videos: {
      en: { ref: 'rqwvI4zTaFQ', format: YOUTUBE, duration: "1'51" },
      it: { ref: 'LTA3QuTmEoY', format: YOUTUBE, duration: "1'51" },
      es: { ref: '6P4FLfZDgKQ', format: YOUTUBE, duration: "1'51" },
      de: { ref: '04j9HJua3e4', format: YOUTUBE, duration: "1'51" },
      fr: { ref: 'DaWOUZtgYNo', format: YOUTUBE, duration: "1'51" },
      zh: { ref: '/img/indexes-howto-zh.mp4', format: LOCALE, duration: "1'51" },
    },
    openLabelRsxKey: 'artists._indices.m12',
    displayIcon: true,
  },
  [ARTPRICE]: {
    videos: {
      en: { ref: 'Id_9iN-2gGA', format: YOUTUBE },
      it: { ref: 'ZyAwuzADYI0', format: YOUTUBE },
      es: { ref: '2VevWecj8ZU', format: YOUTUBE },
      de: { ref: 'MjCYv2CjKtY', format: YOUTUBE },
      fr: { ref: '8RubvmqLNWo', format: YOUTUBE },
      zh: { ref: '/img/artprice-zh-voice.mp4', format: LOCALE },
    },
    openLabelRsxKey: 'common.artprice_menu.html.m2',
    displayIcon: true,
  },
  [SEARCHES]: {
    videos: {
      en: { ref: '14ZIoDruPMU', format: YOUTUBE },
      es: { ref: 'Ikx_6XALTLQ', format: YOUTUBE },
      de: { ref: 'xqiYHFtlby4', format: YOUTUBE },
      fr: { ref: 'e2ODNz6CHsY', format: YOUTUBE },
      it: { ref: 'Auq0FoZUFfU', format: YOUTUBE },
      zh: { ref: '/img/search-howto-zh.mp4', format: LOCALE },
    },
    openLabelRsxKey: 'howto.config.m1',
    displayIcon: true,
  },
};
