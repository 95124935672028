import i18next from 'i18next';
import React from 'react';
import { SHORTCUT_LOTS_FOLLOW_FUTURE, SHORTCUT_LOTS_FOLLOW_MARKETPLACE, SHORTCUT_LOTS_FOLLOW_PAST } from '../../../../../../../constants/myartprice';
import { Shortcut, ShortcutLink } from '../Shortcut';

const NewDataLots = ({ currentElement, handleSectionClick }) => (
  <div className="shortcuts">
    <div className="links" style={{ maxHeight: 22 + 3 * 24 }}>
      {(currentElement === SHORTCUT_LOTS_FOLLOW_FUTURE && <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m3')} />) || (
        <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m3')} onClick={() => handleSectionClick(SHORTCUT_LOTS_FOLLOW_FUTURE)} to="/myartprice/lots/futures" />
      )}

      {(currentElement === SHORTCUT_LOTS_FOLLOW_PAST && <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m4')} />) || (
        <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m4')} onClick={() => handleSectionClick(SHORTCUT_LOTS_FOLLOW_PAST)} to="/myartprice/lots/auctioned-off" />
      )}

      {(currentElement === SHORTCUT_LOTS_FOLLOW_MARKETPLACE && <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m5')} />) || (
        <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m5')} onClick={() => handleSectionClick(SHORTCUT_LOTS_FOLLOW_MARKETPLACE)} to="/myartprice/lots/marketplace" />
      )}
    </div>
  </div>
);

export default NewDataLots;
