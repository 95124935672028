import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { progress } from '../../../../../redux/selectors/auctioneers/sales';
import Arrow from './Arrow';

const ProgressBar = ({ idsale }) => {
  const { online, manual, progressBar = false, datas, integration } = useSelector(state => progress(state, { id: idsale }));

  if (!progressBar) {
    return null;
  }

  if (manual) {
    return (
      <div className="auctioneers-future-sales-sale-progress-bar">
        <Arrow first title={i18next.t('auctioneers.futuresales.sale.progressbar.m1')} />
        <Arrow title={i18next.t('auctioneers.futuresales.sale.progressbar.m6')} comming={!online} />
      </div>
    );
  }

  return (
    <div className="auctioneers-future-sales-sale-progress-bar">
      <Arrow first title={i18next.t('auctioneers.futuresales.sale.progressbar.m1')} />

      {datas && <Arrow title={i18next.t('auctioneers.futuresales.sale.progressbar.m2')} />}
      {!datas && <Arrow title={i18next.t('auctioneers.futuresales.sale.progressbar.m3')} comming />}

      {integration && <Arrow title={i18next.t('auctioneers.futuresales.sale.progressbar.m4')} />}
      {!integration && <Arrow title={i18next.t('auctioneers.futuresales.sale.progressbar.m5')} comming />}

      {online && <Arrow last title={i18next.t('auctioneers.futuresales.sale.progressbar.m6')} />}
      {!online && <Arrow last title={i18next.t('auctioneers.futuresales.sale.progressbar.m7')} comming />}
    </div>
  );
};

export default ProgressBar;
