import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUrlParams as getInitialParamsSelector } from '../../../../../redux/selectors/search';
import { getOverflownParams } from '../../../../../redux/selectors/search/ui';
import { endOfMouseHoverSelectedParam, startOfMouseHoverSelectedParam } from '../../../../../redux/actions/marketplace/searchbars/ui';
import { cleanPathname, objectToQueryString } from '../../../../../utils/http';
import './stylesheet.scss';
import { applyCurrentNewParams } from '../../../../../redux/actions/search';

const Param = ({ params, redirect = true }) => {
  const dispatch = useDispatch();
  const initialParams = useSelector(getInitialParamsSelector);
  const overflownParams = useSelector(getOverflownParams);

  const deleteParams = () => {
    const newParams = { ...initialParams };
    params.paramsToDelete.map(p => delete newParams[p]);

    if (redirect) {
      window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString(newParams)}`;
    } else {
      dispatch(applyCurrentNewParams({ params: newParams }));
    }
  };

  const handleMouseEnter = id => {
    const overflownToAdd = {};
    overflownToAdd[id] = 'overflew';
    dispatch(startOfMouseHoverSelectedParam({ ...overflownParams, ...overflownToAdd }));
  };

  return (
    <span className="search-common-selected-params-param">
      <a href="#" id={params.id} className={overflownParams[`${params.id}`]} onMouseEnter={() => handleMouseEnter(params.id)} onMouseLeave={() => dispatch(endOfMouseHoverSelectedParam())} onClick={() => deleteParams()}>
        {params.display(initialParams)}
        {overflownParams[`${params.id}`] && <i className="fa fa-trash-o" />}
        {!overflownParams[`${params.id}`] && <i className="fa fa-times" />}
      </a>
    </span>
  );
};

export default Param;
