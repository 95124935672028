import React from 'react';
import { useSelector } from 'react-redux';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { getMarketplaceAwaitingOfferSelection } from '../../../../../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, ANALYTICS_FROM_HEADER_NOTIFICATIONS } from '../../../../../../../../../constants/rails';
import { a18n } from '../../../../../../../../../utils/a18n';

const AwaitingOfferSelection = () => {
  const awaitingOffetSelection = useSelector(getMarketplaceAwaitingOfferSelection);

  return awaitingOffetSelection.map(idclassified => (
    <Notification
      key={idclassified}
      element={<A18n rsx="component.header.notifications.marketplace.m15" replace={[['%idclassified%', idclassified]]} />}
      link={`/${a18n('routes.marketplace')}/${idclassified}`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, idclassified })}
    />
  ));
};

export default AwaitingOfferSelection;
