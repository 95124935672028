/* eslint-disable global-require */
module.exports = [
  'marketplace.howtobid.faq.fees.m1',
  'marketplace.howtobid.faq.fees.m2',
  'marketplace.howtobid.faq.fees.m3',
  'marketplace.howtobid.faq.fees.m4',
  'marketplace.howtobid.faq.fees.m5',
  'marketplace.howtobid.faq.fees.m6',
  'marketplace.howtobid.faq.fees.m7',
  'marketplace.howtobid.faq.fees.m8',
  'marketplace.howtobid.faq.fees.m9',
  'marketplace.howtobid.faq.fees.m10',
  'marketplace.howtobid.faq.fees.m11',
  'marketplace.howtobid.faq.fees.m12',
  'marketplace.howtobid.faq.fees.m13',
];
