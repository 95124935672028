/* eslint-disable */

// Support component names relative to this directory
// Any file containing by "lazy" (or "Lazy") in its path will be excluded from the context : which means dynamic import will work for this file
const componentRequireContext = require.context('components', true, /^((?![lL]azy).)*$/);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

if (typeof window !== 'undefined') {
  if (!window.artprice) {
    window.artprice = {};
  }
  window.artprice.mountReactComponents = ReactRailsUJS.mountComponents.bind(ReactRailsUJS);
}
