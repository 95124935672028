import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const CountrySelect = () => (
  <Item label={i18next.t('search.helpers.items.country.select.m1')} name="idcountry">
    <Select facet="idcountry" placeholder={i18next.t('search.helpers.items.country.select.m2')} />
  </Item>
);

export default CountrySelect;
