import i18next from 'i18next';
import AnimateHeight from 'react-animate-height';
import React, { useEffect, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../../redux/slices/userContext/selectors';
import { fetchSummary } from '../../../redux/actions/myartprice/datas';
import { getArtists } from '../../../redux/selectors/myartprice/datas';
import {
  ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS,
  ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS,
  ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_MARKETPLACE,
  ANALYTICS_FROM_HOMEPAGE,
  ANALYTICS_SECTION_MYARTPRICE_FUTURE_LOTS,
  ANALYTICS_SECTION_MYARTPRICE_PAST_LOTS,
  IMAGE_SOURCE,
} from '../../../constants/rails';
import A18n from '../../common/A18n';
import './stylesheet.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';

const summaryDetailsInitialState = { loaded: false, total: 0, lots: { future: 0, past: 0, marketplace: 0 }, artists: { future: 0, past: 0, marketplace: 0 } };

const Myartprice = () => {
  const intl = useIntl();
  const [height, setHeight] = useState(0);
  const dispatch = useDispatch();
  const artists = useSelector(getArtists);
  const [summary, setSummary] = useState(summaryDetailsInitialState);
  const { idcustomer } = useSelector(get);

  useEffect(() => {
    setSummary(
      Object.values(artists || []).reduce(
        (result, artist) => ({
          loaded: true,
          lots: {
            future: result.lots.future + (artist.new_future || 0),
            past: result.lots.past + (artist.new_past || 0),
            marketplace: result.lots.marketplace + (artist.new_marketplace || 0),
          },
          artists: {
            future: result.artists.future + (artist.new_future > 0 ? 1 : 0),
            past: result.artists.past + (artist.new_past > 0 ? 1 : 0),
            marketplace: result.artists.marketplace + (artist.new_marketplace > 0 ? 1 : 0),
          },
          total: result.total + (artist.new_future || 0) + (artist.new_past || 0) + (artist.new_marketplace || 0),
        }),
        summaryDetailsInitialState,
      ),
    );
  }, [artists]);

  useEffect(() => {
    if (idcustomer) {
      dispatch(fetchSummary());
      setHeight(82);
    }
  }, [idcustomer]);

  return (
    <div className="homepage-myartprice">
      <AnimateHeight id="homepage-myartprice" duration={1000} height={height}>
        <div className="myartprices">
          <div className="myartprice title">
            <A18n rsx="homepage.myartprice.m1" />
          </div>
          <div className="myartprice fav">
            <a href="/myartprice/artists/" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS })}>
              <div className="icon">
                <img className="myartprice-ico" src={`${IMAGE_SOURCE}hp/myartprice.svg`} alt={i18next.t('homepage.myartprice.m1')} />
              </div>
            </a>
            {summary.loaded && (
              <div className="count">
                <FormattedNumber value={artists.length} />
              </div>
            )}
            <div className="txt">
              <a href="/myartprice/artists/" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS })}>
                {(artists.length > 1 || artists.length === 0) && <A18n rsx="homepage.myartprice.m2" />}
                {artists.length === 1 && <A18n rsx="homepage.myartprice.m14" />}
              </a>
              <div className="sub-txt">
                {summary.loaded && (
                  <span>
                    {summary.total > 1 && <A18n rsx="homepage.myartprice.m3" replace={[['%count%', intl.formatNumber(summary.total)]]} />}
                    {summary.total === 1 && <A18n rsx="homepage.myartprice.m4" />}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="myartprice">
            <a
              href="/myartprice/artists/lots-futures/new"
              onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS, section: ANALYTICS_SECTION_MYARTPRICE_FUTURE_LOTS })}
            >
              <div className="icon">
                <img src={`${IMAGE_SOURCE}hp/fs.svg`} alt={i18next.t('homepage.myartprice.m15')} />
              </div>
            </a>
            {summary.loaded && (
              <div className="count">
                <FormattedNumber value={summary.lots.future} />
              </div>
            )}
            <div className="txt">
              <a
                href="/myartprice/artists/lots-futures/new"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS, section: ANALYTICS_SECTION_MYARTPRICE_FUTURE_LOTS })}
              >
                {summary.loaded && (
                  <>
                    {summary.lots.future === 1 && <A18n rsx="homepage.myartprice.m15" />}
                    {(summary.lots.future > 1 || summary.lots.future === 0) && <A18n rsx="homepage.myartprice.m5" />}
                  </>
                )}
                {!summary.loaded && <A18n rsx="homepage.myartprice.m5" />}
              </a>
              <div className="sub-txt">
                {summary.loaded && (
                  <span>
                    {summary.artists.future > 1 && <A18n rsx="homepage.myartprice.m6" replace={[['%count%', intl.formatNumber(summary.artists.future)]]} />}
                    {summary.artists.future === 1 && <A18n rsx="homepage.myartprice.m7" />}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="myartprice">
            <a
              href="/myartprice/artists/lots-auctioned-off"
              onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS, section: ANALYTICS_SECTION_MYARTPRICE_PAST_LOTS })}
            >
              <div className="icon">
                <img src={`${IMAGE_SOURCE}hp/ps.svg`} alt={i18next.t('homepage.myartprice.m8')} />
              </div>
            </a>
            {summary.loaded && (
              <div className="count">
                <FormattedNumber value={summary.lots.past} />
              </div>
            )}
            <div className="txt">
              <a
                href="/myartprice/artists/lots-auctioned-off"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS, section: ANALYTICS_SECTION_MYARTPRICE_PAST_LOTS })}
              >
                {summary.loaded && (
                  <>
                    {(summary.lots.past > 1 || summary.lots.past === 0) && <A18n rsx="homepage.myartprice.m8" />}
                    {summary.lots.past === 1 && <A18n rsx="homepage.myartprice.m16" />}
                  </>
                )}
                {!summary.loaded && <A18n rsx="homepage.myartprice.m8" />}
              </a>
              <div className="sub-txt">
                {summary.loaded && (
                  <span>
                    {summary.artists.past > 1 && <A18n rsx="homepage.myartprice.m6" replace={[['%count%', intl.formatNumber(summary.artists.past)]]} />}
                    {summary.artists.past === 1 && <A18n rsx="homepage.myartprice.m7" />}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="myartprice">
            <a href="/myartprice/artists/marketplace" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_MARKETPLACE })}>
              <div className="icon">
                <img src={`${IMAGE_SOURCE}hp/marketplace.svg`} alt={i18next.t('homepage.myartprice.m9')} />
              </div>
            </a>
            {summary.loaded && (
              <div className="count">
                <FormattedNumber value={summary.lots.marketplace} />
              </div>
            )}
            <div className="txt">
              <a href="/myartprice/artists/marketplace" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_MARKETPLACE })}>
                {summary.loaded && (
                  <>
                    {(summary.lots.marketplace > 1 || summary.lots.marketplace === 0) && <A18n rsx="homepage.myartprice.m9" />}
                    {summary.lots.marketplace === 1 && <A18n rsx="homepage.myartprice.m17" />}
                  </>
                )}
                {!summary.loaded && <A18n rsx="homepage.myartprice.m9" />}
              </a>
              <div className="sub-txt">
                {summary.loaded && (
                  <span>
                    {summary.artists.marketplace > 1 && <A18n rsx="homepage.myartprice.m6" replace={[['%count%', intl.formatNumber(summary.artists.marketplace)]]} />}
                    {summary.artists.marketplace === 1 && <A18n rsx="homepage.myartprice.m7" />}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default Myartprice;
