import React from 'react';
import i18next from 'i18next';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getItemsPerpage } from '../../../../redux/selectors/preferences';
import { getCurrentPage } from '../../../../redux/selectors/search/info';
import { getLot } from '../../../../redux/selectors/dbEntities';
import { fetchCurrentResults } from '../../../../redux/actions/search/results';
import './stylesheet.scss';

const ButtonsNavigation = ({ idlot, currentResultsData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const back = new URLSearchParams(window.location.search).get('back');
  const ipp = useSelector(getItemsPerpage);
  const currentPage = useSelector(getCurrentPage);
  const currentIndex = currentResultsData.idlot.findIndex(idlotresult => idlotresult === Number.parseInt(idlot, 10));
  const prevIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  const startOfPage = prevIndex < 0;
  const endOfPage = nextIndex === currentResultsData.idlot.length;
  const startOfResults = currentPage === 1 && startOfPage;
  const endOfResults = currentPage === currentResultsData.totalPages && endOfPage;
  const prevLot = useSelector(state => getLot(state, { id: currentResultsData.idlot[prevIndex] }));
  const nextLot = useSelector(state => getLot(state, { id: currentResultsData.idlot[nextIndex] }));

  const onPrevClick = () => {
    if (startOfPage) {
      dispatch(fetchCurrentResults({ params: { p: currentPage - 1, ipp } })).then(() => {
        const { id, crypto } = getLot(store.getState(), { id: _.last(currentResultsData.idlot) });
        navigate(`./../../${id}/${crypto}?back=${back}`);
      });
    } else {
      navigate(`./../../${prevLot.id}/${prevLot.crypto}?back=${back}`);
    }
  };

  const onNextClick = () => {
    if (endOfPage) {
      dispatch(fetchCurrentResults({ params: { p: currentPage + 1, ipp } })).then(() => {
        const { id, crypto } = getLot(store.getState(), { id: _.head(currentResultsData.idlot) });
        navigate(`./../../${id}/${crypto}?back=${back}`);
      });
    } else {
      navigate(`./../../${nextLot.id}/${nextLot.crypto}?back=${back}`);
    }
  };

  return (
    <div className="buttons-navigation">
      <button disabled={startOfResults} type="button" className="prev" onClick={() => onPrevClick()}>
        <i className="fa fa-angle-left marg marg-r-5" />
        {/* Previous Lot */}
        <span className="button-label">{i18next.t('components.myartprice.common.navigation.m1')}</span>
      </button>
      &nbsp;
      <button disabled={endOfResults} type="button" className="next" onClick={() => onNextClick()}>
        {/* Next Lot */}
        <span className="button-label">{i18next.t('components.myartprice.common.navigation.m2')}</span>
        <i className="fa fa-angle-right marg marg-l-5" />
      </button>
    </div>
  );
};

export default ButtonsNavigation;
