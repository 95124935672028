import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, IMAGE_SOURCE } from '../../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../../../redux/slices/artist';
import '../../../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../../../common/A18n';

const NotPushCard = ({ direction = 'vertical' }) => {
  const artist = useSelector(getArtist);

  return (
    <div className={`lot fake ${direction}`}>
      <div className="fake-bg" />
      {direction === 'vertical' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email.png`} alt="email-alert" />}
      {direction === 'horizontal' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email-no-fs.png`} alt="email-alert" />}
      <div className="fake-container">
        <p className="title">
          <A18n rsx="artists.artist.lots.future.m20" trustHtml replace={[['%artist%', artist.name]]} />
        </p>
        <p className="content">
          <A18n rsx="artists.artist.lots.future.m11" trustHtml />
        </p>
        <a
          className="artp-btn artp-btn-artist"
          href="/myartprice/settings/artists"
          onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, idartist: artist.id })}
        >
          <A18n rsx="artists.artist.lots.future.m12" />
        </a>
      </div>
    </div>
  );
};

export default NotPushCard;
