import React from 'react';
import Record from './Record';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Records = ({ records }) => {
  if (!records) return null;

  return (
    <div className="ails-landingpage-records">
      <h2>
        <A18n rsx="ails.landingpage.records.m1" />
      </h2>
      <div className="records">
        {records?.map(record => (
          <Record ail={record} key={record.id} />
        ))}
      </div>
    </div>
  );
};

export default Records;
