import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_COLLABORATIONS } from '../../../../constants/rails';
import { getArtist, getCollaborations } from '../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import Scroll from '../common/Scroll';
import './stylesheet.scss';

const Collaborations = () => {
  const collaborations = useSelector(getCollaborations);
  const artist = useSelector(getArtist);
  const { collaborationsRef, hideLeftMenu } = useContext(UiContext);

  if (isEmpty(collaborations)) return null;

  return (
    <div className={`artists-artist-collaborations ${hideLeftMenu ? 'block-no-menu' : 'block'}`} ref={collaborationsRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.collaboration.m1" />
        </h2>
        {!isEmpty(artist.groupMembers) && (
          <p className="sub-h2">
            <A18n rsx="artists.artist.collaboration.m5" replace={[['%nb%', artist.groupMembers.length]]} />
          </p>
        )}
        {isEmpty(artist.groupMembers) && (
          <p className="sub-h2">
            {collaborations.length > 1 && (
              <A18n
                rsx="artists.artist.collaboration.m2"
                replace={[
                  ['%nb%', collaborations.length],
                  ['%artist%', artist.name],
                ]}
              />
            )}
            {collaborations.length === 1 && <A18n rsx="artists.artist.collaboration.m3" replace={[['%artist%', artist.name]]} />}
          </p>
        )}
      </div>
      <Scroll className="collaborations" aapiBeaconSection={ANALYTICS_SECTION_ARTIST_COLLABORATIONS}>
        {collaborations.map(a => (
          <a
            href={a.url}
            key={a.url}
            onClick={() => aapiBeacon('click', { idartist: artist.id, from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL, section: ANALYTICS_SECTION_ARTIST_COLLABORATIONS })}
          >
            <div className="collaboration">
              <div>{a.name}</div>
              <div>
                {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.collaboration.m4" />}
                {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.collaboration.m6" />}
              </div>
            </div>
          </a>
        ))}
      </Scroll>
    </div>
  );
};

export default Collaborations;
