import i18next from 'i18next';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, Label, Button, Col } from 'reactstrap';
import HtmlEditor from '../../../../common/HtmlEditor';
import { datasForReactSelect as storetypesForReactSelect } from '../../../../../services/wallet/storetype';
import { save as saveStoreAction } from '../../../../../redux/actions/marketplace/stores';
import { isOn as isOnSelector } from '../../../../../redux/selectors/ui/switches';
import { getIdprofessionaltype } from '../../../../../redux/slices/userContext/selectors';
import customStyles from '../../../../../utils/styles/reactSelectStyle';
import { sitenameExists } from '../../../../../services/wallet/store';
import { useViewport } from '../../../../../hooks/useViewport';
import ErrorBlock from '../../../../common/ErrorBlock';
import schema from './schema';
import Preview from '../Preview';

import './stylesheet.scss';

const StoreForm = ({ store, width }) => {
  const dispatch = useDispatch();
  const [storeTypes, setStoreTypes] = useState([]);
  const saveInProgress = useSelector(state => isOnSelector(state, { id: `save-store-${store.id}` }));
  const idprofessionaltype = useSelector(getIdprofessionaltype);
  const { viewportWidth } = useViewport();
  const { register, handleSubmit, setError, clearErrors, control, formState, getValues, reset, setValue } = useForm({
    defaultValues: {
      sitename: store.sitename,
      presentation: store.presentation,
      news: store.news,
      storeview: {
        value: store.idstoreview,
        label: store.storeview,
      },
      storetype: {
        value: store.idstoretype,
        label: store.storetype,
      },
      url: store.url,
      horaire: store.horaire,
    },
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const { isSubmitting } = formState;

  useEffect(() => {
    storetypesForReactSelect().then(datas => {
      setStoreTypes(datas);
    });
  }, []);

  const handleSitenameBlur = async value => {
    const { exists } = await sitenameExists(store.id, value);

    if (exists) {
      setError(
        'sitename',
        {
          type: 'manual',
          message: i18next.t('api.wallet.store.save.m1'),
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors('sitename');
    }
  };

  const storeviewsOptions = [
    { value: 1, label: i18next.t('marketplace.stores.edit.form.information.m13') },
    { value: 2, label: i18next.t('marketplace.stores.edit.form.information.m14') },
    { value: 3, label: i18next.t('marketplace.stores.edit.form.information.m15') },
    { value: 4, label: i18next.t('marketplace.stores.edit.form.information.m16') },
  ];

  const handleCancel = () => {
    reset();
    setValue('news', store.news);
    setValue('news', store.presentation);
  };

  const setTemporaryStore = () => {
    const formValues = getValues();
    const temporaryStore = { ...store, ...formValues };

    if (formValues.storeview) {
      temporaryStore.idstoreview = formValues.storeview.value;
      temporaryStore.storeview = formValues.storeview.label;
    }
    if (formValues.storetype) {
      temporaryStore.idstoretype = formValues.storetype.value;
      temporaryStore.storetype = formValues.storetype.label;
    }

    return temporaryStore;
  };

  return (
    <form onSubmit={handleSubmit(values => dispatch(saveStoreAction(store.id, values)))}>
      <FormGroup row>
        <Label for="Sitename" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m1')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="sitename" {...register('sitename')} onBlur={e => handleSitenameBlur(e.target.value)} />
          <ErrorBlock error={errors.sitename} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="storetype" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m2')}
        </Label>
        <Col sm={10}>
          {idprofessionaltype && (
            <Controller
              control={control}
              name="storetype"
              render={({ field }) => <Select classNamePrefix="react-select" styles={customStyles()} options={storeTypes} placeholder={i18next.t('marketplace.stores.edit.form.information.m3')} {...field} />}
            />
          )}
          {!idprofessionaltype && (
            <div>
              <p>
                <i className="fa fa-warning" />
                {i18next.t('marketplace.stores.edit.form.information.m17')}
              </p>
              <p>
                <a href="/account/confirm" className="btn btn-primary">
                  {i18next.t('marketplace.stores.edit.form.information.m18')}
                </a>
              </p>
            </div>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="news" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m6')}
        </Label>
        <Col sm={10}>
          <Controller name="news" control={control} render={({ field }) => <HtmlEditor {...field} />} />
          <ErrorBlock error={errors.news} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="presentation" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m7')}
        </Label>
        <Col sm={10}>
          <Controller name="presentation" control={control} render={({ field }) => <HtmlEditor {...field} />} />
          <ErrorBlock error={errors.presentation} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="url" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m8')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="sitename" {...register('url')} />
          <ErrorBlock error={errors.url} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="horaire" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m9')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('horaire')} />
          <ErrorBlock error={errors.horaire} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="storeview" sm={2}>
          {i18next.t('marketplace.stores.edit.form.information.m10')}
        </Label>
        <Col sm={10}>
          <Controller
            name="storeview"
            control={control}
            render={({ field }) => (
              <Select classNamePrefix="react-select" styles={customStyles({ viewportWidth, width })} options={storeviewsOptions} placeholder={i18next.t('marketplace.stores.edit.form.information.m11')} {...field} />
            )}
          />
        </Col>
      </FormGroup>

      <div style={{ display: 'flex', flexFlow: 'row', marginTop: 30 }}>
        <div style={{ flex: 1 }}>
          <Preview setTemporaryStore={setTemporaryStore} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button className="btn btn-default marg marg-r-5" type="button" disabled={isSubmitting} onClick={handleCancel}>
            {i18next.t('marketplace.stores.edit.m1')}
          </Button>
          <Button className="btn btn-info" type="submit" color="primary" disabled={isSubmitting}>
            {saveInProgress && <i className="fa fa-spin fa-spinner" />}
            {!saveInProgress && i18next.t('marketplace.stores.edit.form.information.m12')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default StoreForm;
