import i18next from 'i18next';
import React from 'react';
import Item from '../../../Search/Item';
import Checkbox from '../../../inputs/Checkbox';

const Sold = () => (
  <Item name="sold">
    <Checkbox label={i18next.t('lots.search.form.sold.m1')} />
  </Item>
);

export default Sold;
