import React from 'react';
import { useSelector } from 'react-redux';
import { MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';

const Module = ({ idmodule, themes, checked, label, children, forceUncheck, value = '✓' }) => {
  const { idcustomer } = useSelector(getUserContext);
  const { requiredIdmodule } = useSelector(getSettings);

  return (
    <tr>
      <td>
        {label}

        {!label && (
          <>
            {idmodule === MODULE_PASTSALE && <A18n rsx="subscriptions.list.table.module.m1" />}
            {idmodule === MODULE_FUTURESALE && <A18n rsx="subscriptions.list.table.module.m2" />}
            {idmodule === MODULE_IMAGE && <A18n rsx="subscriptions.list.table.module.m3" />}
            {idmodule === MODULE_INDEXES && <A18n rsx="subscriptions.list.table.module.m4" />}
            {idmodule === MODULE_INDICATOR && <A18n rsx="subscriptions.list.table.module.m5" />}
            {idmodule === MODULE_EXPORT_PDF && <A18n rsx="subscriptions.list.table.module.m6" />}
            {idmodule === MODULE_STORE && <A18n rsx="subscriptions.list.table.module.m7" />}
          </>
        )}
        {children}
      </td>
      {!idcustomer && !requiredIdmodule && <td className="essential">{checked && <span>{typeof value === 'function' ? value() : value}</span>}</td>}
      {themes.map(theme => {
        if (!forceUncheck && (checked || theme.module.find(id => id === idmodule))) {
          return (
            <td className={theme.theme_ref} key={`${idmodule}-${theme.theme_ref}-${label || 'db'}-1`}>
              {typeof value === 'function' ? value(theme) : value}
            </td>
          );
        }

        return (
          <td className={theme.theme_ref} key={`${idmodule}-${theme.theme_ref}-${label || 'db'}-0`}>
            &nbsp;
          </td>
        );
      })}
    </tr>
  );
};
export default Module;
