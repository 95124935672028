/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedNumber } from 'react-intl';
import { getSummaryData, isSummaryDataLoading } from '../../../redux/selectors/indexes/summary';
import { IMAGE_SOURCE } from '../../../constants/rails';
import { summaryDataAction } from '../../../redux/actions/indexes/summary';
import { getArtistName } from '../../../redux/selectors/indexes/artist';
import { getCurrency } from '../../../redux/selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';
import Spinner from '../../common/spinners/Spinner';
import Information from './Information';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Summary = ({ intl }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const data = useSelector(getSummaryData);
  const loading = useSelector(isSummaryDataLoading);
  const artistName = useSelector(getArtistName);
  const [offset, setOffset] = useState(132);

  useEffect(() => {
    const headerElement = document.getElementById('artp-header');
    if (headerElement) {
      setOffset(headerElement.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    dispatch(summaryDataAction());
  }, [currency]);

  const { worldRanking, canViewIndexes = false, mainMedium, hasIndexes, lastYear, mainPriceSegment, priceIndexRate, annualPerformance, geographicalDistribution } = data || {};

  const scrollTo = anchorTo => {
    const link = document.getElementById(anchorTo);
    if (link) {
      window.scrollTo({
        top: link.getBoundingClientRect().top - offset,
        behavior: 'smooth',
      });
    }
  };

  if (loading) {
    return (
      <div className="artist-index-summary" style={{ height: 440 }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="artist-index-summary">
      <h2 className="digest">
        {canViewIndexes && (
          <A18n
            rsx="indexes.summary.m1"
            replace={[
              ['%artist%', artistName],
              ['%year%', lastYear],
            ]}
          />
        )}
        {!canViewIndexes && <A18n rsx="indexes.summary.m11" replace={[['%artist%', artistName]]} />}
      </h2>

      <div className="blocks">
        <div className="block" onClick={() => scrollTo('chrono')}>
          <span className="block-label">
            <A18n rsx="indexes.summary.m12" />
          </span>
          <span className=" block-data">
            <Information condition={canViewIndexes}>
              <FormattedNumber value={annualPerformance?.value} style="currency" currency={CURRENCIES[currency].iso3ForIntl} minimumFractionDigits={0} />
              <img alt="variation" src={`${IMAGE_SOURCE}indexes-${annualPerformance?.arrow}.svg`} />
            </Information>
          </span>
        </div>
        <div className="block" onClick={() => scrollTo('rank')}>
          <div className="block-label">
            <A18n rsx="indexes.summary.m3" />
          </div>
          <div className="block-data">
            <Information condition={canViewIndexes}>
              <FormattedNumber value={worldRanking?.value} />
              {worldRanking?.arrow !== 'new' && (
                <>
                  {worldRanking?.arrow === 'stable' && <img alt="variation" src={`${IMAGE_SOURCE}indexes-up.svg`} style={{ transform: 'rotate(90deg)' }} />}
                  {worldRanking?.arrow !== 'stable' && <img alt="variation" src={`${IMAGE_SOURCE}indexes-${worldRanking?.arrow}.svg`} />}
                </>
              )}
            </Information>
          </div>
        </div>
        {hasIndexes && (
          <div className="block" onClick={() => scrollTo('indexes')}>
            <span className="block-label">
              <A18n rsx="indexes.summary.m4" />
            </span>
            <span className="block-data">
              <Information condition={canViewIndexes}>
                {priceIndexRate > 0 && '+'}
                <FormattedNumber value={priceIndexRate / 100.0} style="percent" maximumFractionDigits={1} />
                {priceIndexRate > 0 && <img alt="up" src={`${IMAGE_SOURCE}indexes-up.svg`} />}
                {priceIndexRate < 0 && <img alt="down" src={`${IMAGE_SOURCE}indexes-down.svg`} />}
              </Information>
            </span>
          </div>
        )}
        <div className="block" onClick={() => scrollTo('geo')}>
          <span className="block-label">
            <A18n rsx="indexes.summary.m5" />
          </span>
          <span className=" block-data">
            <Information condition={canViewIndexes}>
              {geographicalDistribution ? (
                <A18n
                  rsx="indexes.summary.m8"
                  replace={[
                    ['%country%', geographicalDistribution?.country],
                    ['%percent%', intl.formatNumber(geographicalDistribution?.rate, { style: 'percent', maximumFractionDigits: 1 })],
                  ]}
                />
              ) : (
                '-'
              )}
            </Information>
          </span>
        </div>
        <div className="block" onClick={() => scrollTo('price-segments')}>
          <span className="block-label">
            <A18n rsx="indexes.summary.m6" />
          </span>
          <span className="block-data">
            <Information condition={canViewIndexes}>
              {mainPriceSegment ? (
                <A18n
                  rsx={mainPriceSegment?.name.includes(' - ') ? 'indexes.summary.m9' : 'indexes.summary.m13'}
                  replace={[
                    ['%segment%', mainPriceSegment?.name],
                    ['%percent%', intl.formatNumber(mainPriceSegment?.value, { style: 'percent', maximumFractionDigits: 1 })],
                  ]}
                />
              ) : (
                '-'
              )}
            </Information>
          </span>
        </div>
        <div className="block" onClick={() => scrollTo('mediums')}>
          <span className="block-label">
            <A18n rsx="indexes.summary.m7" />
          </span>
          <span className=" block-data">
            <Information condition={canViewIndexes}>
              {mainMedium ? (
                <A18n
                  rsx="indexes.summary.m10"
                  replace={[
                    ['%medium%', mainMedium.name],
                    ['%percent%', intl.formatNumber(mainMedium?.value, { style: 'percent', maximumFractionDigits: 1 })],
                  ]}
                />
              ) : (
                '-'
              )}
            </Information>
          </span>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Summary);
