import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatforms } from '../../../services/subscriptions/stripe';
import { getParams, updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import Spinner from '../../common/spinners/Spinner';
import CartSummary from '../common/CartSummary';
import Accordion from '../../common/Accordion';
import { a18n } from '../../../utils/a18n';
import A18n from '../../common/A18n';
import Offline from './Offline';
import Paypal from './Paypal';
import Stripe from './Stripe';
import './stylesheet.scss';
import Help from '../common/Help';

const Platforms = ({ hideSummary }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { logged, idcustomer } = useSelector(getUserContext);
  const { image, idphysicproducts, type, promocode, idestimates, newUser, idphysicsale } = useSelector(getParams);
  const [stripeSubscriptionOptions, setStripeSubscriptionOptions] = useState();
  const [items, setItems] = useState([]);

  const onError = useCallback(
    e => {
      setError(e);
    },
    [setError],
  );

  useEffect(() => {
    if (!logged && idcustomer) {
      window.location = '/identity';
    }
  }, [logged, idcustomer]);

  // Set step for the progressbar
  useEffect(() => {
    dispatch(updateNavigationStep(newUser ? 3 : 2));
  }, []);

  useEffect(() => {
    const params = { type, promocode, image, idphysicproducts, idestimates, idphysicsale };

    if (!isEmpty(idphysicproducts) || idphysicsale) {
      setLoading(true);

      // retrieve platforms information from params set in the outlet context
      // those params are set in the  component Payments/Layout and initialized from the backend
      getPlatforms(params)
        .then(response => {
          const stripeSubscription = response.platforms.find(platform => platform.id === 'stripe_subscription');

          if (stripeSubscription) {
            setStripeSubscriptionOptions({ stripeSubscriptionCheckoutSessionId: stripeSubscription.parameters.checkout_session.sessionId });
          } else {
            const innerItems = [];

            const stripeIntent = response.platforms.find(platform => platform.id === 'stripe_intent');
            if (stripeIntent) {
              innerItems.push({
                key: 'stripe',
                title: a18n('payments.platforms.m1'),
                children: (
                  <Stripe
                    idpayment={response.idpayment}
                    displayedPaymentMethod={stripeIntent.extra.payment_method_types}
                    options={{
                      checkoutFormOptions: {
                        clientSecret: stripeIntent.parameters.intent_client_secret,
                      },
                    }}
                    onError={onError}
                  />
                ),
              });
            }

            const paypalOptions = response.platforms.find(platform => platform.id === 'paypal');
            if (paypalOptions) {
              innerItems.push({
                key: 'paypal',
                title: a18n('payments.platforms.m2'),
                children: <Paypal idphysicsale={response.idphysicsale} options={paypalOptions} />,
              });
            }

            if (response.offline_allowed) {
              // paiement par virement
              innerItems.push({ key: 'transfer', title: a18n('payments.platforms.m3'), children: <Offline idpayment={response.idpayment} idphysicsale={response.idphysicsale} mode="transfer" /> });

              // paiement par cb offline
              innerItems.push({
                key: 'cboffline',
                title: a18n('payments.platforms.m4'),
                children: <Offline idphysicsale={response.idphysicsale} mode="cb" idpayment={response.idpayment} />,
              });
            }

            setItems(innerItems);
          }
        })
        .catch(e => {
          console.error(e);
          // redirect to subscription list
          window.location = `/subscription?needs_login=true`;
        })
        .finally(() => setLoading(false));
    }
  }, [type, promocode, image, idphysicproducts, idphysicsale, onError]);

  if (stripeSubscriptionOptions) return <Stripe options={stripeSubscriptionOptions} />;

  if (loading) {
    return (
      <div style={{ marginTop: 60 }}>
        <Spinner faSize={4} />
      </div>
    );
  }

  return (
    <div className="payments-platforms payments-grid-layout">
      <div className="bg" />
      <div className="container">
        <div className="container-grid">
          <div className="left">
            <h1>
              <A18n rsx="payments.platforms.m5" />
            </h1>
            {error && (
              <div className="payment-error">
                <h3 className="error-title">
                  <i className="fa fa-exclamation-triangle" /> <A18n rsx="payments.platforms.m6" />
                </h3>
                <div className="error-message">
                  {error?.code ? `[${error.code}] ` : ''}
                  {error?.message}
                </div>
                <Help />
              </div>
            )}
            <Accordion items={items} />
          </div>
          <div className="right">{!hideSummary && <CartSummary />}</div>
        </div>
      </div>
    </div>
  );
};

export default Platforms;
