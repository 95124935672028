/* eslint-disable global-require */
module.exports = [
  ...require('./Input/i18n'),
  ...require('./Articles/i18n'),
  ...require('./Artists/i18n'),
  ...require('./Classifieds/i18n'),
  ...require('./Lots/i18n'),
  ...require('./Reports/i18n'),
  ...require('./SectionHeader/i18n'),
];
