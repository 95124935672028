import React from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOT, ANALYTICS_SECTION_ARTIST_PAST_LOT } from '../../../../../../constants/rails';
import Reproduction from '../../Reproduction';
import A18n from '../../../../../common/A18n';
import Lock from '../../../common/Lock';

const Lot = ({ lot, handleOpenDrawer, displayLock, style = {} }) => (
  <a
    href={lot.url}
    onClick={e =>
      handleOpenDrawer({
        section: ANALYTICS_SECTION_ARTIST_PAST_LOT,
        op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOT,
        e,
      })
    }
    key={lot.url}
  >
    <div className="lot" style={style}>
      <div className="tag">
        <div className="dt">{lot.saleDtStart}</div>
        {lot.saleCountry && <div className="country">{lot.saleCountry}</div>}
      </div>
      <Reproduction lot={lot} />
      <div>
        <p className="title" style={{ fontWeight: 600 }}>
          {lot.title}
        </p>
        <p>{lot.category}</p>
        {displayLock && !lot.fakeImage && (
          <div className="lock" style={{ marginTop: 15 }}>
            <Lock stroke="#b4b2ab" style={{ marginRight: 5 }} />
            <span>
              <A18n rsx="artists.artist.lots.future.m13" />
            </span>
          </div>
        )}
      </div>
    </div>
  </a>
);

export default Lot;
