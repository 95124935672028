import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORE, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORES, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { getStores } from '../../../redux/slices/homepage';
import { a18n } from '../../../utils/a18n';
import Arrow from '../../common/Arrow';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Stores = () => {
  const stores = useSelector(getStores);

  if (isEmpty(stores)) return null;

  return (
    <div className="homepage-stores block">
      <div className="container">
        <h2 className="title">
          <A18n rsx="homepage.stores.m1" />
          <div className="all">
            <a className="marketplace" href={`/${a18n('routes.marketplace')}/store`} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORES })}>
              <A18n rsx="homepage.stores.m2" />
              <Arrow style={{ marginLeft: 5 }} />
            </a>
          </div>
        </h2>

        <div className="data three-cols">
          {stores.map(store => (
            <a key={store.id} href={store.url} onClick={() => aapiBeacon('click', { op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORE, from: ANALYTICS_FROM_HOMEPAGE, idstore: store.id })}>
              <div className="store">
                <div className="l0">
                  <div className="l0-0" style={{ backgroundImage: `url("${store.imagesUrl[0]}")` }}>
                    <div className="l0-0-bg" />
                    <div className="inititial">{store.sitename[0]}</div>
                    <div className="underline" />
                  </div>

                  <div className="l0-1">
                    {store.imagesUrl.map(imageUrl => (
                      <div key={imageUrl} style={{ backgroundImage: `url("${imageUrl}")` }} />
                    ))}
                  </div>
                </div>
                <div className="l1">
                  <div className="l1-0">{store.sitename}</div>
                  <div className="l1-1">{store.location}</div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Stores;
