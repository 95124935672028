import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { off as closeDrawerAction } from '../../../redux/actions/ui/switches';
import { isOpen as isOpenSelector } from '../../../redux/selectors/ui/switches';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../constants/rails';
import { onClient, onServer } from '../../../utils/ssr';

let rootElem = null;
function getRootElem() {
  if (onServer()) {
    return null;
  }

  if (!rootElem) {
    rootElem = document.getElementById('drawer-root');
  }
  return rootElem;
}

const stopPropagation = event => event.stopPropagation();

const Drawer = ({ className, isOpen, side = 'left', render, onClose }) => {
  const [visibility, setVisibility] = useState(isOpen); // visibility is used to manage animation & rendering of underlying components

  useLayoutEffect(() => {
    if (isOpen) {
      // We have to delay the second class to let the translate animation run
      // 16 is an arbitrary value
      const timeoutId = setTimeout(() => setVisibility(true), 16);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  const handlers = useSwipeable({
    onSwipedLeft: onClose,
  });

  const portalRoot = getRootElem();
  if (!portalRoot) {
    return null;
  }

  return createPortal(
    <div role="presentation" className={`common-drawer-container drawer-black-bg ${isOpen ? 'background-visible' : 'background-hidden'} ${visibility ? 'background-solid' : 'background-passthrough'}`} onClick={onClose}>
      <div
        role="presentation"
        className={`${className ?? ''} artp-drawer common-drawer ${side} ${isOpen ? 'drawer-visible' : 'drawer-hidden'}`.trim()}
        onTransitionEnd={e => {
          if (e.propertyName === 'transform' && !isOpen) {
            setVisibility(false);
          }
        }}
        {...handlers}
        onClick={stopPropagation}
      >
        <div className="close" onClick={onClose}>
          <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
        </div>
        {visibility && render()}
      </div>
    </div>,
    portalRoot,
  );
};

// Drawer overlay to prevent hydration problem in SSR
// on server : the component will always render null
// on the client : it will render null at first then the drawer after the useEffect (-> this will prevent hydration errors)
const SSRRobustDrawer = ({ className, isOpen, side = 'left', render, onClose }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (onClient()) {
      setShow(true);
    }
  }, []);

  if (show) {
    return <Drawer className={className} isOpen={isOpen} side={side} render={render} onClose={onClose} />;
  }
  return null;
};

const ReduxDrawer = ({ id, render, side = 'left', onClose, className }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => isOpenSelector(state, { id }));
  const closeDrawer = () => {
    dispatch(closeDrawerAction(id));
    onClose?.();
  };

  return <SSRRobustDrawer isOpen={isOpen} onClose={closeDrawer} render={render} side={side} className={className} />;
};

export default ReduxDrawer;
