import React, { useCallback, useContext } from 'react';
import CommonPreferences from '../../../../common/Preferences';
import SearchContext from '../../Search/Context';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const DEFAULT_RSX = {
  title: 'marketplace.searchbars.classifieds.bar.m7',
  perPage: 'lots.search.filters.display.itemsperpage.m1',
};

const Preferences = ({ hiddenFields, className, icon = 'cogs', onChange, right, rsx = DEFAULT_RSX, withOptionalCurrencies }) => {
  const { searchScope } = useContext(SearchContext);
  const internalOnChange = useCallback(
    changes => {
      aapiBeacon('search_pref', { searchscope: searchScope, ...changes });

      if (onChange) {
        onChange(changes);
      }
    },
    [searchScope, onChange],
  );

  return <CommonPreferences hiddenFields={hiddenFields} className={className} onChange={internalOnChange} icon={icon} right={right} rsx={rsx} withOptionalCurrencies={withOptionalCurrencies} />;
};

export default Preferences;
