import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { SEARCH_DIMENSIONS } from '../../../../constants/search';
import './stylesheet.scss';

const Dimensions = ({ value, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleClick = dim => {
    setInternalValue(dim);
    onChange(dim);
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('lots.search.filters.display.dimensions.m1')}</span>
      <div className="search-bar-input btn-group btn-group-sm">
        {SEARCH_DIMENSIONS.map(dim => (
          <a key={dim} className={`btn btn-default ${internalValue === dim ? 'active' : ''}`} onClick={() => handleClick(dim)}>
            {dim}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dimensions;
