import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { getFreshness } from '../../../../redux/selectors/preferences';
import { updateFreshnessPreference } from '../../../../redux/actions/preferences';
import { width as viewportWidthSelector } from '../../../../redux/selectors/ui/viewport';
import customStyles from '../../common/reactSelect/style';
import './stylesheet.scss';
import '../../common/reactSelect/stylesheet.scss';

const options = {
  1: i18next.t('lots.header_search.m12'),
  5: i18next.t('lots.header_search.m13')?.replace('%nb%', '5'), // ? for ssr (if you want this to work correctly in ssr, you'll have to change the way this is done)
  10: i18next.t('lots.header_search.m13')?.replace('%nb%', '10'),
};

const Freshness = ({ className }) => {
  const dispatch = useDispatch();
  const viewportWidth = useSelector(viewportWidthSelector);
  const freshness = useSelector(getFreshness);
  const currentFreshness = { value: freshness, label: options[freshness] };

  const handleClick = value => {
    dispatch(updateFreshnessPreference({ freshness: value }));
  };

  return (
    <div className="artp-input-group">
      <span className="artp-input-label">{i18next.t('search.filters.freshness.m1')}</span>
      <ReactSelect
        noOptionsMessage={() => i18next.t('marketplace.searchbars.common.facetsselect.m1')}
        styles={customStyles(viewportWidth)}
        classNamePrefix="react-select"
        className={`search-common-react-select ${className}`}
        components={makeAnimated()}
        defaultValue={currentFreshness}
        onChange={selectedOption => handleClick(selectedOption.value)}
        options={Object.entries(options).map(([value, label]) => ({ value, label }))}
      />
    </div>
  );
};

export default Freshness;
