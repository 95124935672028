import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useCookies } from 'react-cookie';
import { COOKIE_CONSENT_NAME } from '../../../constants/rails';
import { ALL_ACCEPTED, ALL_REJECTED, hasChosenConsent, setConsent } from '../../../utils/cookieConsent';
import CookieProvider from '../../providers/CookieProvider';

import './stylesheet.scss';

const CookieConsentOverlay = () => {
  const [, updateState] = useState(); // rerender component when pageshow event arises (solves "back button problem" in ios)
  useCookies([COOKIE_CONSENT_NAME]); // rerender component when cookie changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pageShowListener = evt => {
        if (evt.persisted) {
          updateState({ reload: true });
        }
      };
      window.addEventListener('pageshow', pageShowListener, false);
      return () => window.removeEventListener('pageshow', pageShowListener);
    }
  }, []);

  if (hasChosenConsent()) {
    return null;
  }

  return (
    <div className="CookieConsentOverlay">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="text">
              {i18next.t('component.cookie.overlay.m2')}{' '}
              <a href="/block/privacypolicy?modal=true" data-toggle="modal" data-target="#base-modal">
                {i18next.t('home.privacypolicy.m1')}
              </a>
            </div>
            <div className="actions">
              <button className="sln-reject-cookies btn btn-default" type="button" onClick={() => setConsent(ALL_REJECTED)}>
                {i18next.t('component.cookie.overlay.m5')}
              </button>

              <button
                className="btn btn-default"
                type="button"
                onClick={() => {
                  window.location = '/cookies-management';
                }}
              >
                {i18next.t('component.cookie.overlay.m4')}
              </button>

              <button className="sln-accept-cookies btn btn-primary" type="button" onClick={() => setConsent(ALL_ACCEPTED)}>
                {i18next.t('component.cookie.overlay.m3')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProvidedCookieConsentOverlay = () => (
  <CookieProvider>
    <CookieConsentOverlay />
  </CookieProvider>
);

export default ProvidedCookieConsentOverlay;
