import { internalAxios } from '../utils/axios/internal';

export const retrievedForSelect = async helper => {
  const response = await internalAxios.get(`/api/artprice/retrieved_from_helper_for_select?format=json&helper=${helper}`);
  return response.data;
};

export const resetSession = async () => {
  const response = await internalAxios.post(`/api/artprice/rs`);
  return response.data;
};
