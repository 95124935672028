import React from 'react';
import A18n from '../../../../common/A18n';
import EmailStatus from '../../../EmailStatus';

const Locked = ({ close }) => (
  <div className="section">
    <div className="section-title">
      <A18n rsx="tour.emailvalidationneeded.section.title1" />
    </div>
    <div className="section-content" style={{ padding: '0 10%' }}>
      <p>
        <A18n rsx="subscription.payment_ok.m12" trustHtml />
      </p>
      <p>
        <A18n rsx="subscription.payment_ok.m13" />
      </p>
    </div>
    <div className="section-content" style={{ padding: '30px 10% 10px' }}>
      <EmailStatus withWarning={false} backTo="home" onClick={close} />
    </div>
  </div>
);

export default Locked;
