import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updatePreferences, fetchCategories } from '../../../../redux/actions/myartprice/settings';
import { getCategories } from '../../../../redux/selectors/myartprice';
import { SHORTCUT_SETTINGS_CATEGORIES } from '../../../../constants/myartprice';
import Checkbox from '../../../common/ui/Checkbox';
import '../../common/styles/myartpriceSettings.scss';

const SettingsCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories, shallowEqual) || [];

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const handleSwitch = (category, v) => {
    dispatch(updatePreferences(SHORTCUT_SETTINGS_CATEGORIES, v, { idcategory: category.id }));
  };

  return (
    <div className="myartprice-settings myartprice-flipswitches col-xs-12">
      <div className="info">{i18next.t('components.myartprice.settings.categories.m1')}</div>
      <div className="list">
        {categories.map(category => (
          <div className="artist" key={`myartprice#settings#category#${category.id}`}>
            <Checkbox light name={`category#${category.id}`} label={category.label} checked={category.include_in_push} onChange={value => handleSwitch(category, value)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsCategories;
