/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */

import React, { useState } from 'react';
import Section from './Section';
import Spinner from '../../common/spinners/Spinner';
import BackToTopButton from '../../common/BackToTopButton';
import { queryStringToObject } from '../../../utils/http';
import './stylesheet.scss';

const Mobile = ({ faq, dataloading }) => {
  const queryStringParams = queryStringToObject(window.location.search);
  const [activeFaq, setActiveFaq] = useState(queryStringParams.active || 'block1');

  const handleFaqClick = (key, index) => {
    if (activeFaq === key) {
      setActiveFaq(null);
    } else {
      setActiveFaq(key);
      const e = document.getElementById(key);

      if (e) {
        window.scroll({
          top: index * e.getBoundingClientRect().height + document.getElementsByClassName('groups')[0].offsetTop + 62, // 62px is the height of the header
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className="faq-mobile">
      {dataloading && <Spinner />}
      {!dataloading && (
        <div className="groups">
          {Object.entries(faq).map(([key, entry], index) => (
            <div className="group" key={key}>
              <div id={key} className={activeFaq === key ? 'active' : ''} onClick={() => handleFaqClick(key, index)}>
                {entry.title}
                <i className="fa fa-caret-right" />
              </div>
              {activeFaq === key && <Section id={entry.items.length === 1 ? entry.items[0].id : null} faq={entry || []} />}
            </div>
          ))}
        </div>
      )}
      <div className="back-to-top-container">
        <BackToTopButton top="marketplace-how-to-bid-faq" forceHandleResize={!dataloading && activeFaq} />
      </div>
    </div>
  );
};

export default Mobile;
