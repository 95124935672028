import React from 'react';

const Lock = ({ stroke = '#BBBAB3', style = {}, className, height = 21, width = 22 }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 5, ...style }} className={className}>
    <rect x="0.5" y="0.5" width={21} height={20} rx={10} fill="transparent" />
    <path
      d="M11.0001 11.3409C11.1263 11.3409 11.2286 11.2493 11.2286 11.1363C11.2286 11.0233 11.1263 10.9318 11.0001 10.9318C10.8738 10.9318 10.7715 11.0233 10.7715 11.1363C10.7715 11.2493 10.8738 11.3409 11.0001 11.3409Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path d="M11 11.3409V12.8873" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M15.5716 8.47729H6.42871V15.4318H15.5716V8.47729Z" stroke={stroke} strokeMiterlimit="10" />
    <path d="M14.1998 8.47733V6.63642C14.1998 5.0696 12.8284 3.56824 10.9998 3.56824C9.17123 3.56824 7.7998 5.0696 7.7998 6.63642V8.47733" stroke={stroke} strokeMiterlimit="10" />
    <rect x="0.5" y="0.5" width={21} height={20} rx={10} stroke={stroke} />
  </svg>
);

export default Lock;
