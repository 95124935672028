/* eslint-disable global-require */
module.exports = [
  'component.myartprice.marketplace.m1',
  'component.myartprice.marketplace.m2',
  'component.myartprice.marketplace.m3',
  'component.myartprice.marketplace.m6',
  'component.myartprice.marketplace.m7',
  'artprice.Lib.Navigbarartprice.m_home',
  'artprice.lib.myartprice.artists.m13',
  'layouts.header.m16',
  'layouts.header.m43',
];
