/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */

import React, { useEffect, useState, useRef } from 'react';
import BackToTopButton from '../../common/BackToTopButton';
import { objectToQueryString, queryStringToObject } from '../../../utils/http';
import Spinner from '../../common/spinners/Spinner';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const faqScroll = id => {
  const e = document.getElementById(`answer-${id}`);

  if (e) {
    window.scroll({
      top: e.getBoundingClientRect().top + window.scrollY - 132, // 132px is the height of the header
      behavior: 'smooth',
    });
  }
};

const Desktop = ({ faq, dataloading }) => {
  const ulRef = useRef();
  const queryStringParams = queryStringToObject(window.location.search);
  const [activeFaq, setActiveFaq] = useState(queryStringParams.active || 'block1');
  const [activeAnswer, setActiveAnswer] = useState(queryStringParams.id ? parseInt(queryStringParams?.id, 10) : null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      faqScroll(activeAnswer);
    });

    if (ulRef?.current) {
      resizeObserver.observe(ulRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ulRef?.current, activeAnswer]);

  useEffect(() => {
    if (activeFaq) {
      faqScroll(activeAnswer);
    }
  }, [dataloading]);

  useEffect(() => {
    if (queryStringParams.active !== activeFaq) {
      window.history.replaceState(window.history.state, '', `?${objectToQueryString({ active: activeFaq })}`);
    } else {
      window.history.replaceState(window.history.state, '', `?${objectToQueryString({ active: activeFaq, id: activeAnswer })}`);
    }
  }, [activeAnswer, activeFaq]);

  const handleAnswerClick = item => {
    if (item.redirect) {
      window.open(item.redirect, '_blank');
    } else if (faq[activeFaq].items.length > 1) {
      setActiveAnswer(activeAnswer === item.id ? null : item.id);
    }
  };

  const handleFaqClick = item => {
    setActiveFaq(item);

    window.scroll({ top: 50, behavior: 'smooth' });

    if (faq[item].items.length === 1) {
      setActiveAnswer(faq[item].items[0].id);
    }
  };

  if (!faq && !dataloading) return null;

  return (
    <>
      <div className="faq-desktop">
        {dataloading && <Spinner />}
        {!dataloading && (
          <>
            <div className="left">
              <p>
                <A18n rsx="marketplace.howtobid.faq.m2" />
              </p>
              <ul>
                {Object.entries(faq).map(([key, entry]) => (
                  <li className={activeFaq === key ? 'active' : ''} key={key} onClick={() => handleFaqClick(key)}>
                    {entry.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className="right">
              <ul ref={ulRef}>
                {(faq[activeFaq] || []).items.map(faqItem => (
                  <li id={`answer-${faqItem.id}`} key={faqItem.id || faqItem.redirect} className={activeAnswer === faqItem.id ? 'active' : ''} onClick={() => handleAnswerClick(faqItem)}>
                    <p className="faq-title">{faqItem.title}</p>
                    <p onClick={e => e.stopPropagation()} className="faq-item" dangerouslySetInnerHTML={{ __html: faqItem.text }} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="back-to-top-container">
        <BackToTopButton top="marketplace-how-to-bid-faq" forceHandleResize={!dataloading && activeFaq} />
      </div>
    </>
  );
};

export default Desktop;
