import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../../redux/slices/artist';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';
import Lock from '../../common/Lock';
import './stylesheet.scss';

const Reproduction = ({ lot }) => {
  const artist = useSelector(getArtist);

  return (
    <div className="img-container">
      {lot.fakeImage && (
        <div className="fake-img">
          <p className="lock">
            <Lock stroke="#fff" />
          </p>
          <p>
            <A18n rsx="artists.artist.lots.reproduction.m1" />
          </p>
        </div>
      )}

      {lot.imageUrl && <img src={lot.imageUrl} alt={`${artist.name} - ${lot.title}`} title={`${artist.name} - ${lot.title}`} />}

      {!lot.imageUrl && (
        <div className="no-img">
          <img className="no-img" src={`${IMAGE_SOURCE}/no-access/img-non-dispo.svg`} alt={`${artist.name} - ${lot.title}`} title={`${artist.name} - ${lot.title}`} />
          <p>
            <A18n rsx="artists.artist.lots.reproduction.m2" />
          </p>
        </div>
      )}
    </div>
  );
};

export default Reproduction;
