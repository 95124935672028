import React, { useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import Document from './Document';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Documents = ({ control, errors, requiredValue, reporterType }) => {
  const [documents, setDocuments] = useState(['document1']);

  return (
    <div className="marketplace-reporting-documents">
      <h2>
        <A18n rsx="marketplace.reporting.documents.m1" /> {requiredValue ? '*' : ''}
        {documents.length < 5 && (
          <button
            type="button"
            onClick={() => {
              setDocuments([...documents, `document${documents.length + 1}`]);
            }}
          >
            <A18n rsx="marketplace.reporting.documents.m3" />
          </button>
        )}
      </h2>

      <p className="legend">
        {reporterType === 1 && <A18n rsx="marketplace.reporting.documents.m4" />}
        {reporterType === 2 && <A18n rsx="marketplace.reporting.documents.m5" />}
      </p>

      {documents.map((document, idx) => (
        <FormGroup key={document}>
          <Label sm={4}>
            <A18n rsx="marketplace.reporting.documents.m2" replace={[['%nb%', idx + 1]]} />
          </Label>
          <Col sm={5}>
            <Document name={document} errors={errors} control={control} requiredValue={requiredValue && idx === 0} />
          </Col>
        </FormGroup>
      ))}
    </div>
  );
};
export default Documents;
