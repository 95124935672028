import React from 'react';
import i18Next from 'i18next';
import { useDispatch } from 'react-redux';
import { clear as clearSelection, setAddAndCreateButtonsVisibility } from '../../../../../redux/slices/account/lotslists';
import './stylesheets.scss';

const Clear = () => {
  const dispatch = useDispatch();

  const clear = () => {
    dispatch(clearSelection());
    dispatch(setAddAndCreateButtonsVisibility(false));
  };

  return (
    <button title={i18Next.t('search.bars.common.closingdropdown.m1')} type="button" onClick={clear} className="lots-lists-button account-lots-lists-add-to-list-button-clear">
      <i className="fa fa-times" /> <span className="title">{i18Next.t('search.bars.common.closingdropdown.m1')}</span>
    </button>
  );
};

export default Clear;
