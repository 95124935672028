import React from 'react';
import i18next from 'i18next';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import Follow from '../../../artists/Follow';
import './stylesheet.scss';

const Description = ({ classified, artist, className }) => (
  <div className={`classified-description ${className || ''}`}>
    <div className="artist centered-on-mobile">
      <Artist id={classified.idartist} />
      <div className="artist-dates">
        {artist?.dates && <span>{artist.dates}</span>} <Follow idartist={classified.idartist} />
      </div>
    </div>
    <h1 className="title centered-on-mobile">
      {classified.title || i18next.t('marketplace.show.html.m17')}
      {classified.year && <span className="dates">({classified.year})</span>}
    </h1>
  </div>
);

export default Description;
