/* eslint-disable global-require */
module.exports = [
  ...require('./Empty/i18n'),
  ...require('./SellAd/i18n'),
  'artists.artist.marketplace.classifieds.m1',
  'artists.artist.marketplace.classifieds.m2',
  'artists.artist.marketplace.classifieds.m3',
  'artists.artist.marketplace.classifieds.m4',
  'artists.artist.marketplace.classifieds.m5',
  'artists.artist.marketplace.classifieds.m6',
  'artists.artist.marketplace.classifieds.m7',
];
