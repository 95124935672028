import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './stylesheets.scss';

const Images = ({ images = [] }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const next = () => {
    if (imageIndex === images.length - 1) {
      setImageIndex(0);
    } else {
      setImageIndex(imageIndex + 1);
    }
  };

  const previous = () => {
    if (imageIndex === 0) {
      setImageIndex(images.length - 1);
    } else {
      setImageIndex(imageIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: previous,
    onSwipedRight: next,
  });

  const image = images[imageIndex];
  return (
    <div className="marketplace-store-header-store-images">
      <div className="image">
        {image && (
          <div {...handlers}>
            <img className="shadow" alt={image.imageIndex} src={image.image_large_url} />
          </div>
        )}
      </div>
      {images.length > 1 && (
        <div className="controls">
          <div className="left">
            <button type="button" onClick={previous}>
              <i className="fa fa-arrow-circle-left" />
            </button>
          </div>
          <div className="right">
            <button type="button" onClick={next}>
              <i className="fa fa-arrow-circle-right" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Images;
