import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../../constants/indexes/common';

const PieComponent = ({ intl, data }) => {
  const [simplifiedData, setSimplifiedData] = useState([]);

  useEffect(() => {
    if (data) {
      const sumUp = (accumulator, currentEl) => accumulator + currentEl.value;
      const hundredPercent = data.reduce(sumUp, 0);
      let eightyPercent = hundredPercent * 0.8;
      const eightPercent = eightyPercent * 0.1;

      const sortedData = [...data].sort((el, nextEl) => nextEl.value - el.value);
      const customData = [];
      const others = { name: i18next.t('indexes.others'), value: 0 };

      for (let i = 0; i < sortedData.length; i += 1) {
        const currentEl = sortedData[i];

        if ((eightyPercent <= 0 && (i < sortedData.length - 1 || others.value > 0)) || (currentEl.value < eightPercent && customData.length >= 5)) {
          others.value += currentEl.value;
        } else {
          customData.push(currentEl);
          eightyPercent -= currentEl.value;
        }
      }

      const toReturn = data.filter(item => customData.includes(item));

      if (others.value > 0) toReturn.push(others);

      setSimplifiedData(toReturn);
    }
  }, [data]);

  const renderLabel = properties => {
    const { cx, cy, midAngle, innerRadius, outerRadius, fill, percent, value, name } = properties;

    if (!percent > 0) return null;

    const RADIAN = Math.PI / 180;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 3) * cos;
    const sy = cy + (outerRadius + 3) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const percentRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const percentX = cx + -10 * Math.sign(Math.cos(-midAngle * RADIAN)) + percentRadius * Math.cos(-midAngle * RADIAN);
    const percentY = cy + percentRadius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" className="hide-in-mobile" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" className="hide-in-mobile" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={percent * 100 < 8 ? ey : ey + 5} textAnchor={textAnchor} fill="#333" className="hide-in-mobile">
          {`${name}: ${intl.formatNumber(value)}`}
        </text>
        {percent * 100 < 8 && (
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#888" className="hide-in-mobile">
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        )}
        {percent * 100 >= 8 && (
          <text x={percentX} y={percentY} fill="#fff" textAnchor={percentX > cx ? 'start' : 'end'} dominantBaseline="central" style={{ zIndex: 15 }}>
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        )}
      </g>
    );
  };

  if (simplifiedData.length === 0) return null;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart width={400} height={400}>
        <Pie startAngle={90} endAngle={-270} data={simplifiedData} dataKey="value" nameKey="name" outerRadius={145} fill="rgb(51, 102, 204)" animationDuration={250} labelLine={false} label={renderLabel}>
          {simplifiedData.map((entry, index) => (
            <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(PieComponent);
