import React, { Component } from 'react';
import i18next from 'i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import graphStyle from '../graphStyle';
import { internalAxios } from '../../../../../utils/axios/internal';

const CustomTooltip = datas => {
  const { label, payload } = datas;
  return (
    <div style={graphStyle.customTooltip}>
      <div style={graphStyle.customTooltipLabel}>{label}</div>
      <div style={graphStyle.customTooltipDesc}>
        {i18next.t('estimate.show.m14')}: {get(payload, '[0].value')}
      </div>
    </div>
  );
};

class Prices extends Component {
  constructor(props) {
    super(props);

    this.state = { graph: props.datas };
  }

  componentDidMount() {
    const { datas, idestimate } = this.props;
    if (datas.length === 0) {
      internalAxios.get(`/api/wallet/estimate/graph_prices_repartition/${idestimate}`).then(({ data }) => {
        this.setState({ graph: data });
      });
    }
  }

  render() {
    const { width, height } = this.props;
    const { graph } = this.state;

    if (!graph) {
      return <div>loading .....</div>;
    }

    return (
      <BarChart layout="vertical" data={graph} height={height || 380} width={width} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
        <CartesianGrid horizontal={false} fill="white" />
        <XAxis axisLine={false} type="number" tick={{ fontSize: 10, fontFamily: 'Open Sans', fill: '#000' }} />
        <YAxis reversed dataKey="label" type="category" width={140} tick={{ fontSize: 10, fontFamily: 'Open Sans', fill: '#000' }} />
        <Tooltip content={<CustomTooltip />} active />
        <Bar dataKey="value">
          {graph.map(entry => (
            <Cell key={entry.name} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    );
  }
}

Prices.propTypes = {
  idestimate: PropTypes.number.isRequired,
  datas: PropTypes.array.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.number,
};

Prices.defaultProps = {
  height: undefined,
};

export default Prices;
