import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import BasicDropdown from '../../../common/inputs/BasicDropdown';
import ErrorBlock from '../../../common/ErrorBlock';
import A18n from '../../../common/A18n';

const DropDown = ({ options, control, requiredValue, name, errors, label }) => (
  <FormGroup row>
    <Label for={name} sm={4} className={classNames({ required: requiredValue })}>
      {label}
    </Label>
    <Col sm={5}>
      <BasicDropdown
        name={name}
        width="100%"
        options={options}
        control={control}
        className="form-control"
        placeholder={<A18n rsx="marketplace.reporting.dropdown.m1" />}
        rules={{
          required: {
            value: requiredValue,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
        }}
      />
      <ErrorBlock error={errors[name]} />
    </Col>
  </FormGroup>
);

export default DropDown;
