import React from 'react';
import './stylesheets.scss';

const HighlightedTitle = ({ title, tag: Tag, className, containerStyle, contentStyle }) => (
  <div className={`highlighted-title ${className} `} style={containerStyle}>
    <Tag className="highlighted-title-content" style={contentStyle}>
      {title}
    </Tag>
  </div>
);

export default HighlightedTitle;
