import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import FreeDemo from '..';

const WithReduxProvider = () => (
  <ReduxProvider>
    <FreeDemo />
  </ReduxProvider>
);

export default WithReduxProvider;
