import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import SearchBar from '..';

const ProvidedSearchBar = ({ locale = 'en', searchScope, baseParams }) => (
  <IntlProvider locale={locale}>
    <ReduxProvider>
      <SearchBar searchScope={searchScope} baseParams={baseParams} />
    </ReduxProvider>
  </IntlProvider>
);

export default ProvidedSearchBar;
