import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { SEARCH_ITEMS_PER_PAGE } from '../../../../constants/rails';

import './stylesheet.scss';

const PerPage = ({ value, onChange, label = i18next.t('lots.search.filters.display.itemsperpage.m1') }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleClick = itemsCount => {
    setInternalValue(itemsCount);
    onChange(itemsCount);
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{label}</span>
      <div className="search-bar-input btn-group btn-group-sm">
        {SEARCH_ITEMS_PER_PAGE.map(itemsCount => (
          <a key={itemsCount} className={`btn btn-default ${parseInt(internalValue, 10) === parseInt(itemsCount, 10) ? 'active' : ''}`} onClick={() => handleClick(itemsCount)}>
            {itemsCount}
          </a>
        ))}
      </div>
    </div>
  );
};

export default PerPage;
