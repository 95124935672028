/* eslint-disable global-require */
module.exports = [
  'lot.show.no_access.m9',
  'myartprice.common.lot.m1',
  'myartprice.common.lot.m2',
  'sales.tile.sale.m2',
  'sales.tile.sale.m3',
  'sales.tile.sale.m4',
  'sale_helper.sale_date_status.m1',
  'sale_helper.sale_date_status.m2',
];
