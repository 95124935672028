/* eslint-disable global-require */
module.exports = [
  ...require('./Store/i18n'),
  'subscriptions.purchase.list.theme-details-modale.data.m1',
  'subscriptions.purchase.list.theme-details-modale.data.m2',
  'subscriptions.purchase.list.theme-details-modale.data.m3',
  'subscriptions.purchase.list.theme-details-modale.data.m4',
  'subscriptions.purchase.list.theme-details-modale.data.m5',
  'subscriptions.purchase.list.theme-details-modale.data.m6',
  'subscriptions.purchase.list.theme-details-modale.data.m7',
  'subscriptions.purchase.list.theme-details-modale.data.m8',
  'subscriptions.purchase.list.theme-details-modale.data.m9',
  'subscriptions.purchase.list.theme-details-modale.data.m10',
  'subscriptions.purchase.list.theme-details-modale.data.m11',
  'subscriptions.purchase.list.theme-details-modale.data.m12',
  'subscriptions.purchase.list.theme-details-modale.data.m13',
  'subscriptions.purchase.list.theme-details-modale.data.m14',
  'subscriptions.purchase.list.theme-details-modale.data.m15',
  'subscriptions.purchase.list.theme-details-modale.data.m16',
];
