import React from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { IMAGE_SOURCE } from '../../../../../../../constants/rails';

import '../common.scss';
import './stylesheet.scss';

const FolderMobile = ({ title, children, selected, onOpen, onClose, link = undefined, className }) => {
  const closeHandlers = useSwipeable({
    onSwipedLeft: onClose,
  });

  if (link) {
    return (
      <a className={classNames('artp-Header-Menu-Folder folder-mobile link', className, { selected })} href={link}>
        <div className="folder-title">{title}</div>
      </a>
    );
  }

  return (
    <>
      <div className={classNames('artp-Header-Menu-Folder folder-mobile', className, { selected })}>
        <div
          className="folder-title"
          onClick={() => {
            if (selected) {
              onClose?.();
            } else {
              onOpen?.();
            }
          }}
        >
          {title} <img className="chevron right" src={`${IMAGE_SOURCE}menu/chevron_black.svg`} alt={selected ? '⏶' : '⏷'} />
        </div>
      </div>
      <div className={`folder-content-mobile ${selected ? 'selected' : ''}`} {...closeHandlers}>
        <div className="folder-content-mobile-title">
          <div onClick={onClose}>
            <img className="chevron left" src={`${IMAGE_SOURCE}menu/chevron_black.svg`} alt="⏶" />
          </div>
          <div className="flex-filler" />
          <span style={{ fontWeight: 'bold' }}>{title}</span>
          <div className="flex-filler" />
        </div>
        <ul className="folder-content">{children}</ul>
      </div>
    </>
  );
};

export default FolderMobile;
