import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { IMAGE_SOURCE } from '../../../../../../../constants/rails';

import '../common.scss';
import './stylesheet.scss';

function getChevron(selected) {
  return selected ? 'up' : 'down';
}

const Folder = ({ title, children, selected, onOpen, onClose, link = undefined, className }) => {
  const containerRef = useRef();
  useEffect(() => {
    const handleClickOutside = e => {
      if (containerRef.current?.contains(e.target)) {
        return;
      }
      onClose();
    };

    if (selected) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selected, onClose]);

  if (link) {
    return (
      <a ref={containerRef} className={classNames('artp-Header-Menu-Folder folder-desktop link', className, { selected })} href={link}>
        <div className="folder-title">
          {title}
          <img className="chevron right" src={`${IMAGE_SOURCE}menu/chevron_black.svg`} alt=">" />
        </div>
      </a>
    );
  }

  return (
    <div ref={containerRef} className={classNames('artp-Header-Menu-Folder folder-desktop', className, { selected })} onMouseLeave={() => onClose?.()}>
      <div
        className="folder-title"
        onClick={() => {
          if (selected) {
            onClose?.();
          } else {
            onOpen?.();
          }
        }}
        onMouseEnter={() => onOpen?.()}
      >
        {title}
        <img className={`chevron ${getChevron(selected)}`} src={`${IMAGE_SOURCE}menu/chevron_black.svg`} alt={selected ? '⏶' : '⏷'} />
      </div>
      <ul className="folder-content">{children}</ul>
    </div>
  );
};

export default Folder;
