import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import './stylesheet.scss';

const ViewFormat = ({ value, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleClick = format => {
    setInternalValue(format);
    onChange(format);
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('lots.search.filters.display.view.m1')}</span>
      <div className="search-bar-input btn-group btn-group-sm">
        <a key="square" className={`btn btn-default ${internalValue === 'square' ? 'active' : ''}`} onClick={() => handleClick('square')}>
          <i className="fa fa-th-large" />
        </a>
        <a key="tile" className={`btn btn-default ${internalValue === 'tile' ? 'active' : ''}`} onClick={() => handleClick('tile')}>
          <i className="fa fa-th-list" />
        </a>
      </div>
    </div>
  );
};

export default ViewFormat;
