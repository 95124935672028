import i18Next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLots, getCurrentSelectionKey, initializeWithKey } from '../../../../../redux/slices/account/lotslists';
import Create from '../Create';
import NoAccess from '../NoAccess';
import { FORBIDDEN, HIDDEN, useLotslistsAccess } from '../../../../../hooks/lotslists/useLotslistsAccess';

const StandaloneCreate = ({ ids, newSelectionKey }) => {
  const dispatch = useDispatch();
  const currentSelectionKey = useSelector(getCurrentSelectionKey);
  const accessType = useLotslistsAccess();

  useEffect(() => {
    dispatch(initializeWithKey({ newSelectionKey }));
    dispatch(addLots({ ids }));
  }, [newSelectionKey]);

  if (accessType === HIDDEN || !currentSelectionKey) return null;
  if (accessType === FORBIDDEN) return <NoAccess />;

  return <Create className="standalone" label={i18Next.t('components.lotslists.create.m9')} />;
};

export default StandaloneCreate;
