/* eslint-disable global-require */
module.exports = [
  ...require('./NoAccess/i18n'),
  'artists.artist.filters.drawer.m1',
  'artists.artist.filters.drawer.m2',
  'artists.artist.filters.drawer.m3',
  'artists.artist.filters.drawer.m4',
  'artists.artist.filters.drawer.m5',
  'artists.artist.filters.drawer.m6',
  'artists.artist.filters.drawer.m7',
  'artists.artist.filters.drawer.m8',
  'artists.artist.filters.drawer.m9',
  'artists.artist.filters.drawer.m10',
  'artists.artist.filters.drawer.m11',
  'artists.artist.filters.drawer.m12',
  'artists.artist.filters.drawer.m13',
  'artists.artist.filters.drawer.m14',
];
