/* eslint-disable global-require */
module.exports = [
  ...require('./Ail/i18n'),
  'artists.artist.lots.future.m1',
  'artists.artist.lots.future.m2',
  'artists.artist.lots.future.m3',
  'artists.artist.lots.future.m4',
  'artists.artist.lots.future.m5',
  'artists.artist.lots.future.m6',
  'artists.artist.lots.future.m7',
  'artists.artist.lots.future.m8',
  'artists.artist.lots.future.m9',
  'artists.artist.lots.future.m10',
  'artists.artist.lots.future.m11',
  'artists.artist.lots.future.m12',
  'artists.artist.lots.future.m13',
  'artists.artist.lots.future.m14',
  'artists.artist.lots.future.m15',
  'artists.artist.lots.future.m16',
  'artists.artist.lots.future.m17',
  'artists.artist.lots.future.m18',
  'artists.artist.lots.future.m19',
  'artists.artist.lots.future.m20',
  'artists.artist.lots.future.m21',
  'artists.artist.lots.future.m22',
];
