import React from 'react';

/*

This component is used to fix some part of the website that explodes when doing automatic translation
The error will look like : 
NotFoundError
Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.

See :
https://stackoverflow.com/questions/74626687/next-js-on-ios-failed-to-execute-insertbefore-on-node-notfounderror-insert
https://stackoverflow.com/questions/50784302/uncaught-notfounderror-failed-to-execute-insertbefore-on-node

 */
const NoTranslate = ({ children }) => (
  <div className="notranslate" translate="no">
    {children}
  </div>
);

export default NoTranslate;
