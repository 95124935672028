import { useSelector } from 'react-redux';
import {
  ANALYTICS_CLICK_OPERATION_NB_USERS,
  ANALYTICS_CLICK_OPERATION_SELECT_PRODUCT,
  ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_DISABLE_IMAGES,
  ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_ENABLE_IMAGES,
  ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_PERIOD,
} from '../../../../constants/rails';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { aapiBeacon } from '../../../../services/analytics/aapi';

export default function useAnalytics({ section }) {
  const { filter, quantity, from } = useSelector(getSettings);
  const list = filter === 'subscription' ? 'Subscriptions' : 'Store-Subscriptions';

  const addProductTocart = ({ category, product }) => {
    const productForGA = {
      name: product.reference,
      id: product.idp,
      price: product.price.eur,
      brand: 'Artprice.com',
      category,
      variant: `period-${product.nbmonths}`,
      quantity,
    };

    window.dataLayer.push({
      event: 'detail',
      ecommerce: {
        detail: {
          actionField: {
            list,
          },
          products: [productForGA],
        },
      },
    });

    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list },
          products: [{ ...productForGA, position: product.position }],
        },
      },
    });

    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [product],
        },
      },
    });

    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_SELECT_PRODUCT, section, slug: product?.reference });
  };

  const toggleImage = ({ checked, slug }) => {
    aapiBeacon('click', { from, op: checked ? ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_DISABLE_IMAGES : ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_ENABLE_IMAGES, section, slug });
  };

  const changeQuantity = slug => {
    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_NB_USERS, section, slug });
  };

  const changePeriod = slug => {
    aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_PERIOD, section, slug });
  };

  return { toggleImage, addProductTocart, changeQuantity, changePeriod };
}
