import { get } from '../../selectors/ui/dictionary';
import { set } from '../ui/dictionary';
import { AGREEMENT_ACCEPTING } from '../../../constants/auctioneers';
import { accept as acceptService } from '../../../services/auctioneers/agreement';
import { fetch as fetchAgreement } from '../agreements';

export const accept = () => async (dispatch, getState) => {
  const state = getState();

  if (get(state, { id: AGREEMENT_ACCEPTING })) return;
  try {
    dispatch(set(AGREEMENT_ACCEPTING, true));
    await acceptService();
    dispatch(fetchAgreement({ force: true, idagreementtype: 3 }));
    dispatch(set(AGREEMENT_ACCEPTING, false));
  } catch (error) {
    dispatch(set(AGREEMENT_ACCEPTING, false));
  }
};
