/* eslint-disable global-require */
module.exports = [
  'lots.search.form.keyword.m3',
  'artists.search.form.keyword.m3',
  'artists.search.form.keyword.m4',
  'lots.search.form.keyword.m2',
  'components.search.helpers.items.artist_birth_year.m1',
  'components.search.helpers.items.artist_death_year.m1',
  'lots.search.filters.inputs.saledate.m1',
  'search.helpers.items.favorite.artists.m1',
  'subscription.index.m27',
  'artprice.lib.fsresultctrl.m36',
  'artprice.web.pages.classifieds.decorative.details.m18',
  'lots.search.form.sold.m1',
  'lots.search.form.size.m2',
  'lots.search.form.size.m1',
  'lots.search.form.size.m3',
  'marketplace.searchbars.classifieds.bar.m9',
  'lots.show.m12',
  'search.drawers.lot.m1',
  'filters.dates.m1',
  'lots.search.form.keyword.m2',
  'javascript.application.js.m0',
];
