/* eslint-disable global-require */
module.exports = [
  ...require('./AdvantagesEscrow/i18n'),
  ...require('./AdvantagesArtprice/i18n'),
  ...require('./AuctionProcess/i18n'),
  ...require('./EscrowChecks/i18n'),
  ...require('./Header/i18n'),
  ...require('./Protection/i18n'),
  ...require('./TransactionProcess/i18n'),
  ...require('./Faq/i18n'),
];
