import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { getArticles, getArtist, getCategories, getClassifieds, getCollaborations, getKnowledge, getLotsFuture, getLotsPast, getStores, getTop10 } from '../../../../redux/slices/artist';
import { ANALYTICS_CLICK_OPERATION_ARTIST_MENU, ANALYTICS_FROM_ARTIST } from '../../../../constants/rails';
import { getArtistLotsSectionOrder } from '../../../../redux/selectors/preferences';
import { OVERLAP_BOTTOM, OVERLAP_TOP } from '../common/constants';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { CHROME, getBrowser } from '../../../../utils/browser';
import { UiContext } from '../common/UiProvider';
import { scrollTo } from '../common/utils';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Menu = () => {
  const [activeRef, setActiveRef] = useState();
  const categories = useSelector(getCategories);
  const artist = useSelector(getArtist);
  const stores = useSelector(getStores);
  const classifieds = useSelector(getClassifieds);
  const articles = useSelector(getArticles);
  const collaborations = useSelector(getCollaborations);
  const knowledge = useSelector(getKnowledge);
  const top10 = useSelector(getTop10);
  const lotsFuture = useSelector(getLotsFuture);
  const lotsPast = useSelector(getLotsPast);
  const artistLotsSectionOrder = useSelector(getArtistLotsSectionOrder);

  const { lotsFutureRef, lotsPastRef, marketplaceRef, indexesRef, articlesRef, collaborationsRef, recommendationsRef, menuRef } = useContext(UiContext);

  const [browser, setBrowser] = useState(0);
  const [futureLotsCount, setFutureLotsCount] = useState(0);
  const [pastLotsCount, setPastLotsCount] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) setActiveRef(entry.target);
        });
      },
      {
        rootMargin: `${OVERLAP_TOP * -1}px 0px ${(window.innerHeight - OVERLAP_BOTTOM) * -1}px 0px`,
      },
    );

    const lotsFutureId = lotsFutureRef?.current;
    if (lotsFutureId) observer.observe(lotsFutureId);

    const lotsPastId = lotsPastRef?.current;
    if (lotsPastId) observer.observe(lotsPastId);

    const marketplaceId = marketplaceRef?.current;
    if (marketplaceId) observer.observe(marketplaceId);

    const indexesId = indexesRef?.current;
    if (indexesId) observer.observe(indexesId);

    const articlesId = articlesRef?.current;
    if (articlesId) observer.observe(articlesId);

    const collaborationsId = collaborationsRef?.current;
    if (collaborationsId) observer.observe(collaborationsId);

    const recommendationsId = recommendationsRef?.current;
    if (recommendationsId) observer.observe(recommendationsId);

    return () => {
      observer.disconnect();
    };
  }, [lotsFutureRef.current, lotsPastRef.current, marketplaceRef.current, indexesRef.current, articlesRef.current, collaborationsRef.current, recommendationsRef.current]);

  useEffect(() => {
    if (categories) {
      setFutureLotsCount((categories?.future?.reduce((acc, c) => acc + c.count, 0) || 0) + lotsFuture.notSignedCount);
      setPastLotsCount((categories?.past?.reduce((acc, c) => acc + c.count, 0) || 0) + lotsPast.notSignedCount);
    }
  }, [categories]);

  useEffect(() => {
    setBrowser(getBrowser());
  }, []);

  const handleClick = selector => {
    scrollTo(selector);
    aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_ARTIST_MENU, idartist: artist.id });
  };

  return (
    <div className="block-left menu step-menu" ref={menuRef} style={browser === CHROME ? { backgroundColor: 'inherit' } : {}}>
      <div className="menu-container">
        {artistLotsSectionOrder < 2 && (
          <>
            <div onClick={() => handleClick(lotsFutureRef)} className={activeRef === lotsFutureRef?.current ? 'active' : ''}>
              <A18n rsx="artists.artist.menu.m1" /> (<FormattedNumber value={futureLotsCount} />)
            </div>
            <div onClick={() => handleClick(lotsPastRef)} className={activeRef === lotsPastRef?.current ? 'active' : ''}>
              <A18n rsx="artists.artist.menu.m2" /> (<FormattedNumber value={pastLotsCount} />)
            </div>
          </>
        )}
        {artistLotsSectionOrder > 1 && (
          <>
            <div onClick={() => handleClick(lotsPastRef)} className={activeRef === lotsPastRef?.current ? 'active' : ''}>
              <A18n rsx="artists.artist.menu.m2" /> (<FormattedNumber value={pastLotsCount} />)
            </div>
            <div onClick={() => handleClick(lotsFutureRef)} className={activeRef === lotsFutureRef?.current ? 'active' : ''}>
              <A18n rsx="artists.artist.menu.m1" /> (<FormattedNumber value={futureLotsCount} />)
            </div>
          </>
        )}
        {artist.macro && (
          <div onClick={() => handleClick(indexesRef)} className={activeRef === indexesRef?.current ? 'active' : ''}>
            <A18n rsx="artists.artist.menu.m4" /> ({artist.index ? 6 : 5})
          </div>
        )}
        <div onClick={() => handleClick(marketplaceRef)} className={activeRef === marketplaceRef?.current ? 'active' : ''}>
          <A18n rsx="artists.artist.menu.m3" /> {(!isEmpty(classifieds?.items) || !isEmpty(stores?.items)) && <> ({(classifieds?.totalCount || 0) + (stores?.totalCount || 0)})</>}
        </div>
        {!isEmpty(articles?.items) && (
          <div onClick={() => handleClick(articlesRef)} className={activeRef === articlesRef?.current ? 'active' : ''}>
            <A18n rsx="artists.artist.menu.m5" /> ({articles.totalCount})
          </div>
        )}
        {!isEmpty(collaborations) && (
          <div onClick={() => handleClick(collaborationsRef)} className={activeRef === collaborationsRef?.current ? 'active' : ''}>
            <A18n rsx="artists.artist.menu.m6" /> ({collaborations.length})
          </div>
        )}
        {(!isEmpty(top10) || !isEmpty(knowledge)) && (
          <div onClick={() => handleClick(recommendationsRef)} className={activeRef === recommendationsRef?.current ? 'active' : ''}>
            <A18n rsx="artists.artist.menu.m7" /> ({isEmpty(knowledge) ? top10.length : knowledge.length})
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
