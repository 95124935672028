import React, { useState } from 'react';
import classNames from 'classnames';

const Accordion = ({ className, items = [], defaultActiveIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  return (
    <div className={classNames(['common-accordion', className])}>
      {items.map((item, idx) => (
        <div
          className={classNames({ 'accordion-item': true, 'accordion-item-active': activeIndex === idx })}
          key={item.key || Math.random() * 10000}
          onClick={() => {
            if (idx !== activeIndex) {
              setActiveIndex(idx);
            }
          }}
        >
          <div className="title">
            <h3>
              {activeIndex !== idx && <i className="fa fa-circle-o" />}
              {activeIndex === idx && <i className="fa fa-dot-circle-o" />}
              {item.title}
            </h3>
          </div>

          <section className="accordion-animation-wrapper">
            <div className="accordion-animation">
              <div className="accordion-transform-wrapper">
                <div className="accordion-content">{item.children}</div>
              </div>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
