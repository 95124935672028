/* eslint-disable global-require */
module.exports = [
  ...require('./Newcomer/i18n'),
  'subscriptions.purchase.header.m1',
  'subscriptions.purchase.header.m2',
  'subscriptions.purchase.header.m3',
  'subscriptions.purchase.header.m4',
  'subscriptions.purchase.header.m5',
  'subscriptions.purchase.header.m6',
  'subscriptions.purchase.header.m7',
  'subscriptions.purchase.header.m8',
  'subscriptions.purchase.header.m9',
  'subscriptions.purchase.header.m10',
  'subscriptions.purchase.header.m11',
  'subscriptions.purchase.header.m12',
];
