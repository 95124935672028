/* eslint-disable max-len */
import React from 'react';

const AddIcon = ({ color = 'black' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.805" height="21.805" viewBox="0 0 21.805 21.805" className="add-button-svg-icon">
    <g id="Groupe_614" transform="translate(-254 -34)" fill={color}>
      <path
        id="Tracé_225"
        d="M273.988,36.726h-5v-.909A1.817,1.817,0,0,0,267.174,34h-8.31a.909.909,0,0,0-.642.266l-3.955,3.955a.909.909,0,0,0-.266.642v12.4a1.817,1.817,0,0,0,1.817,1.817h5v.909a1.817,1.817,0,0,0,1.817,1.817h11.357a1.817,1.817,0,0,0,1.817-1.817V38.543A1.817,1.817,0,0,0,273.988,36.726ZM261.08,40.947a.909.909,0,0,0-.266.642V51.716h-5a.454.454,0,0,1-.454-.454V39.451h2.726a1.363,1.363,0,0,0,1.363-1.363V35.363h7.722a.454.454,0,0,1,.454.454v.909h-1.95a.909.909,0,0,0-.642.266Zm13.362,13.041a.454.454,0,0,1-.454.454H262.631a.454.454,0,0,1-.454-.454V42.177H264.9a1.363,1.363,0,0,0,1.363-1.363V38.088h7.722a.454.454,0,0,1,.454.454Z"
      />
      <g id="Groupe_613" transform="translate(265.133 44.969)">
        <path id="Tracé_227" d="M282.019,58.147v2.846h2.736v.726h-2.736v2.87h-.775v-2.87h-2.736v-.726h2.736V58.147Z" transform="translate(-278.507 -58.147)" />
      </g>
    </g>
  </svg>
);

export default AddIcon;
