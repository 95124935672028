import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { noop, omit } from 'lodash';
import { getIdcustomer, getLang } from '../../../redux/slices/userContext/selectors';
import { getCurrentQueryObject, updateUrlQuery } from '../../../utils/http';
import { MANAGED_LOCALES } from '../../../constants/locales';
import { getLocaleStorage, setLocaleStorage } from '../../../utils/storage';
import ModalTour from '../../common/ModalTour';
import Content from './Content';

import './stylesheet.scss';

const CONFIG_KEY = 'langChangedTourConfig';
const DEFAULT_LOCALE_CONFIG = Object.freeze({ definitivelyClosed: undefined });

const LangChangedModal = () => {
  const [show, setShow] = useState(false);
  const idcustomer = useSelector(getIdcustomer);
  const customerLang = useSelector(getLang);

  useEffect(() => {
    if (idcustomer) {
      const queryObject = getCurrentQueryObject();
      if ('rcl' in queryObject) {
        const newLang = MANAGED_LOCALES[queryObject.rcl];
        updateUrlQuery(omit(queryObject, ['rcl']));
        const { definitivelyClosed } = getLocaleStorage(CONFIG_KEY, DEFAULT_LOCALE_CONFIG);
        if (!definitivelyClosed) {
          if (newLang && newLang !== customerLang) {
            setLocaleStorage(CONFIG_KEY, { definitivelyClosed: new Date() });
            setShow(true);
          }
        }
      }
    }
  }, [idcustomer, customerLang]);

  if (show) {
    return <ModalTour className="AccountLangTour" sections={[<Content />]} onCloseDefinitively={noop} />;
  }
  return null;
};

export default LangChangedModal;
