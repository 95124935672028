/* eslint-disable react/no-danger */

import React from 'react';
import { useSelector } from 'react-redux';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { areThemesInitialized, getThemes } from '../../../../redux/selectors/subscriptions/physicproducts';
import Banner from './Banner';
import './stylesheet.scss';

const Promotion = () => {
  const { view, displayedPromotionId } = useSelector(getSettings);
  const themes = useSelector(getThemes);
  const themesInitialized = useSelector(areThemesInitialized);

  if (!themesInitialized) return null;

  if (!themes[view]?.promotion_from_promocode && !themes[view]?.automatically_applied_promotion) {
    if (displayedPromotionId) {
      return <Banner idpromotion={displayedPromotionId} />;
    }
    return null;
  }

  const { status, description } = themes[view]?.promotion_from_promocode || {};
  const { status: automaticallyAppliedPromotionStatus, description: automaticallyAppliedPromotionDescription } = themes[view]?.automatically_applied_promotion || {};

  if (themes[view].promotion_from_promocode && status === 1) return <div className="subscriptions-purchase-promotion" dangerouslySetInnerHTML={{ __html: description }} />;

  if (automaticallyAppliedPromotionStatus > 1) return null;

  if (!automaticallyAppliedPromotionDescription) return null;

  return <div className="subscriptions-purchase-promotion" dangerouslySetInnerHTML={{ __html: automaticallyAppliedPromotionDescription }} />;
};

export default Promotion;
