import React from 'react';
import './stylesheet.scss';

const Header = ({ title, color, backgroundColor, index }) => (
  <div className="reports-report-summary-header" style={{ border: `solid 1px ${backgroundColor}` }}>
    <div style={{ backgroundColor, color }}>{index}</div>
    <div>{title}</div>
  </div>
);

export default Header;
