import React from 'react';
import './stylesheet.scss';

const Badge = ({ count, style, className }) => {
  if (Number.isInteger(count)) {
    return (
      <div style={style} className={`artp-Header-Badge ${(count === 0 && 'invisible') || ''} ${className ?? ''}`}>
        {count > 99 ? '99+' : count}
      </div>
    );
  }

  return (
    <div style={style} className="artp-Header-Badge">
      {count}
    </div>
  );
};

export default Badge;
