import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const LinkToArtist = ({ classified, artist, className }) => {
  const [routeMarketplace, setRouteMarketplace] = useState('marketplace');

  useEffect(() => {
    setRouteMarketplace(i18next.t('routes.marketplace'));
  }, [i18next.t('routes.marketplace')]);

  if (!artist) return null;

  return (
    <div className={`marketplace-classified-link-to-artist ${className || ''}`}>
      <p>
        <a rel="noreferrer" target="_blank" href={artist.url}>
          <A18n rsx="marketplace.classified.m2" replace={[['%artist%', artist.name]]} />
        </a>
      </p>
      <p>
        <a href={`/${routeMarketplace}/${classified.idartist}/${artist.urlArtistParam}#search-bar`}>
          <A18n rsx="marketplace.classified.m1" />
        </a>
      </p>
    </div>
  );
};

export default LinkToArtist;
