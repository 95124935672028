/* eslint-disable global-require */
module.exports = [
  'component.cookie.management.m1',
  'component.cookie.management.m2',
  'component.cookie.management.m3',
  'component.cookie.management.m4',
  'component.cookie.management.m5',
  'component.cookie.management.m6',
  'component.cookie.management.m7',
  'component.cookie.management.m8',
  'component.cookie.management.m9',
  'component.cookie.management.m10',
];
