import React, { useState } from 'react';
import i18next from 'i18next';
import { Col, FormGroup, FormText, Label } from 'reactstrap';

import { documenttypeForFormPrivate } from '../../../../../services/wallet/customerverificationdocumenttype';
import DocumenttypeDropdown from '../fieldComponents/DocumenttypeDropdown';
import TitleDropdown from '../../../../common/inputs/TitleDropdown';
import DocumentSelector from '../fieldComponents/DocumentSelector';
import CountryDropdown from '../../../../common/inputs/CountryDropdown';
import ErrorBlock from '../../../../common/ErrorBlock';
import PhoneInput from '../../../../common/ui/PhoneInput';
import DateInput from '../../../../common/inputs/DateInput';
import '../stylesheet.scss';

const FormPrivate = ({ initValues, errors = {}, readOnly, register, handleResourceDelete, control, setValue, watch }) => {
  const [countryIso2, setCountryIso2] = useState(initValues.countryIso2?.toLowerCase() || i18next.language);
  const addAnotherDoc = i18next.t('components.account.verify.identity.personal.m16');

  return (
    <>
      <input type="hidden" {...register('idDocumentUrl')} />
      <FormGroup row>
        <Label for="title" md={4}>
          {i18next.t('components.account.verify.identity.personal.m2')}
        </Label>
        <Col lg={4} md={5}>
          <TitleDropdown control={control} className="form-control" name="title" readOnly={readOnly} />
          <ErrorBlock error={errors.title} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="firstName" md={4}>
          {i18next.t('components.account.verify.identity.personal.m3')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('firstName')} readOnly={readOnly} />
          <ErrorBlock error={errors.firstName} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" md={4}>
          {i18next.t('components.account.verify.identity.personal.m4')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('lastName')} readOnly={readOnly} />
          <ErrorBlock error={errors.lastName} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="birthDate" md={4}>
          {i18next.t('components.account.verify.identity.personal.m5')}
        </Label>
        <Col lg={4} md={5}>
          <DateInput control={control} name="birthDate" readOnly={readOnly} setValue={setValue} />
          <ErrorBlock error={errors && errors.birthDate} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="line1" md={4}>
          {i18next.t('components.account.verify.identity.personal.m6')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('line1')} readOnly={readOnly} />
          <ErrorBlock error={errors.line1} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
          <input className="form-control" type="text" {...register('line2')} readOnly={readOnly} />
          <ErrorBlock error={errors.line2} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
          <input className="form-control" type="text" {...register('line3')} readOnly={readOnly} />
          <ErrorBlock error={errors.line3} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="zipCode" md={4}>
          {i18next.t('components.account.verify.identity.personal.m7')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('zipCode')} readOnly={readOnly} />
          <ErrorBlock error={errors.zipCode} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="city" md={4}>
          {i18next.t('components.account.verify.identity.personal.m8')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('city')} readOnly={readOnly} />
          <ErrorBlock error={errors.city} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="optional" for="state" md={4}>
          {i18next.t('components.account.verify.identity.personal.m9')}
        </Label>
        <Col lg={4} md={5}>
          <input className="form-control" type="text" {...register('state')} readOnly={readOnly} />
          <ErrorBlock error={errors.state} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="country" md={4}>
          {i18next.t('components.account.verify.identity.personal.m10')}
        </Label>
        <Col lg={4} md={5}>
          <CountryDropdown control={control} className="form-control" name="country" readOnly={readOnly} onChange={country => setCountryIso2(country.iso2.toLowerCase())} />
          <ErrorBlock error={errors.country} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="phone" md={4}>
          {i18next.t('components.account.verify.identity.personal.m11')}
        </Label>
        <Col lg={4} md={5}>
          <PhoneInput control={control} country={countryIso2} name="phone" readOnly={readOnly} />
          <ErrorBlock error={errors && errors.phone} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="idDocumenttype" md={4}>
          {i18next.t('components.account.verify.identity.personal.m14')}
        </Label>
        <Col lg={4} md={5}>
          <DocumenttypeDropdown control={control} className="form-control" name="idDocumenttype" readOnly={readOnly} service={documenttypeForFormPrivate} />
          <ErrorBlock error={errors && errors.idDocumenttype} />
        </Col>
      </FormGroup>
      <FormGroup className="file-group">
        <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
          <DocumentSelector
            control={control}
            onDelete={() => handleResourceDelete({ idDocument: initValues.idDocument })}
            className="form-control"
            name="idDocument"
            readOnly={readOnly}
            documentUrl={initValues.idDocumentUrl}
          />
          <ErrorBlock error={errors && errors.idDocument} />
        </Col>
      </FormGroup>
      {((watch('idDocument') || []).filter(doc => doc).length > 0 || initValues.idDocumentUrl) && (
        <FormGroup className="file-group">
          <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4">
            <DocumentSelector
              control={control}
              onDelete={() => handleResourceDelete({ idDocument: initValues.idDocument2 })}
              className="form-control"
              name="idDocument2"
              readOnly={readOnly}
              documentUrl={initValues.idDocumentUrl2}
              placeholder={addAnotherDoc}
            />
            <ErrorBlock error={errors && errors.idDocument2} />
          </Col>
        </FormGroup>
      )}
      {watch('idDocument') && (
        <FormGroup row>
          <Col lg={4} md={5} className="col-lg-offset-4 col-md-offset-4 marg marg-t-10">
            <FormText>{i18next.t('components.account.verify.identity.personal.m17')}</FormText>
          </Col>
        </FormGroup>
      )}
    </>
  );
};

export default FormPrivate;
