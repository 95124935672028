import React from 'react';
import { FormattedDate } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_TO_REPORT, ANALYTICS_CLICK_OPERATION_TO_REPORT_SEARCH, ANALYTICS_FROM_SEARCH_UNIVERSAL } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import SectionHeader from '../SectionHeader';
import Skeleton from '../skeletons/Wide';
import A18n from '../../../common/A18n';
import { reportSearchOnKeyword } from '../../../../services/search';
import useCancellable from '../hooks/useCancellable';
import { useUniversalSearch } from '../hooks/useUniversalSearch';

const Reports = ({ term }) => {
  const searchReports = useCancellable((v, signal) =>
    reportSearchOnKeyword({ term: v, signal }).then(data => {
      const buff = data.items.reduce(
        (items, item) =>
          items[item.report_title]
            ? items
            : {
                ...items,
                [item.report_title]: {
                  title: item.report_title,
                  year: item.year,
                  highlights: item.highlights,
                  image: item.thumbnail,
                  url: `${item.page_slug_root}/${item.page_slug}`,
                },
              },
        {},
      );

      return {
        data: Object.values(buff),
        totalCount: data.total_count,
        outOfBounds: false,
      };
    }),
  );
  const { observeCallbackRef, loaded, entities: reports } = useUniversalSearch({ term, searchFunc: searchReports });

  return (
    <div ref={observeCallbackRef}>
      <SectionHeader
        count={reports.totalCount}
        title={<A18n rsx="search.universal.reports.m1" />}
        url={`/reports/search?keyword=${encodeURIComponent(term)}`}
        outOfBounds={reports.outOfBounds}
        op={ANALYTICS_CLICK_OPERATION_TO_REPORT_SEARCH}
      />
      {!loaded && <Skeleton />}
      {loaded && (
        <div className="wide-items-container">
          <div className="wide-items">
            {reports.data.map(report => (
              <div className="wide-item" key={report.title}>
                <div className="cover" style={{ backgroundImage: `url("${report.image}")` }} />

                <div className="data-item">
                  <div className="date">
                    <FormattedDate value={report.year} />
                  </div>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="title-item" dangerouslySetInnerHTML={{ __html: report.title }} />

                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="excerpt" dangerouslySetInnerHTML={{ __html: `... ${report?.highlights?.[0]} ...` }} />

                  <div className="link">
                    <a href={`/artprice-reports/${report.url}`} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op: ANALYTICS_CLICK_OPERATION_TO_REPORT, slug: report.url })}>
                      <A18n rsx="search.universal.reports.m2" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
