/* eslint-disable global-require */
module.exports = [
  'lots.search.form.m1',
  'marketplace.searchbars.classifieds.bar.m8',
  'marketplace.searchbars.classifieds.bar.m9',
  'marketplace.searchbars.classifieds.bar.m10',
  'marketplace.searchbars.classifieds.bar.m11',
  'marketplace.searchbars.classifieds.bar.m12',
  'marketplace.searchbars.classifieds.bar.m13',
  'lots.search.form.keyword.m2',
  'lots.search.form.exactmatch.m1',
  'search.drawers.lot.m1',
];
