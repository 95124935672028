import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { debounce as debounceFunction } from 'lodash';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import customStyles from '../../../common/reactSelect/style';
import { DEFAULT_CURRENCIES, OPTIONAL_CURRENCIES } from '../../../../../constants/preferences';
import { getCurrency } from '../../../../../redux/selectors/preferences';
import { getIntegerValue } from '../../../../../utils/search/inputs';
import './stylesheet.scss';

const OPTIONS = Object.values(DEFAULT_CURRENCIES).map(({ id, iso1 }) => ({ id, value: String(id), label: iso1 }));
const OPTIONAL_OPTIONS = Object.values(OPTIONAL_CURRENCIES).map(({ id, iso1, src }) => ({ id, value: String(id), label: src ? <img alt={iso1} style={{ width: 10 }} src={src} /> : iso1 }));

/*
value is an object : { from:, to:, currency: }
 */
const Prices = ({ className, value = {}, onChange, debounce = 100, withOptionalCurrencies = false }) => {
  const options = [...OPTIONS, ...(withOptionalCurrencies ? OPTIONAL_OPTIONS : [])];
  const [internalValue, setInternalValue] = useState(value);
  const defaultCurrency = useSelector(getCurrency);
  const debouncedOnChange = useMemo(() => debounceFunction(onChange, debounce), [onChange, debounce]);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const currency = useMemo(() => {
    const defaultCurrencyId = Object.values(DEFAULT_CURRENCIES).find(c => c.iso3 === defaultCurrency)?.id;
    const currencyId = String(internalValue.currency ?? defaultCurrencyId);
    return options.find(o => o.value === currencyId) || options[0];
  }, [defaultCurrency, internalValue.currency]);

  return (
    <div className={`search-price ${className ?? ''}`.trim()}>
      <div className="cell first" style={{ width: '40%' }}>
        <input
          type="number"
          className="form-control"
          value={internalValue?.from ?? ''}
          onChange={e => {
            const from = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, from }));
            debouncedOnChange({ from, currency: internalValue.currency || currency.id }); // fallback to always have a currency (even if we did not change it (default value -> intervalValue is still an empty object)
          }}
          placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
        />
      </div>
      <div className="cell" style={{ width: '40%' }}>
        <input
          type="number"
          className="form-control"
          value={internalValue?.to ?? ''}
          onChange={e => {
            const to = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, to }));
            debouncedOnChange({ to, currency: internalValue.currency || currency.id });
          }}
          placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
        />
      </div>
      <div className="cell" style={{ width: '20%' }}>
        <Select
          styles={customStyles(0)}
          className="search-common-react-select"
          classNamePrefix="react-select"
          isSearchable={false}
          options={options}
          value={currency}
          onChange={newCurrency => {
            const newCurrencyValue = newCurrency?.value && Number.parseInt(newCurrency.value, 10);
            setInternalValue(prevValue => ({ ...prevValue, currency: newCurrencyValue }));
            // don't trigger onChange if there are no price set
            if (internalValue?.from || internalValue?.to) {
              debouncedOnChange({ currency: newCurrencyValue });
            }
          }}
        />
      </div>
    </div>
  );
};

export default Prices;
