/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { Map as ReactChartsMap } from '@artprice/react-charts';
import Spinner from '../../spinners/Spinner';

const Map = ({ data, formatter }) => {
  const _isMounted = useRef(true);
  const [geography, setGeography] = useState(null);

  useEffect(() => {
    import(/* webpackChunkName: "worldmap" */ '@artprice/react-charts/dist/world-50m.json').then(module => {
      if (_isMounted.current) {
        setGeography(module.default);
      }
    });

    return () => {
      _isMounted.current = false;
    };
  }, []);

  if (geography) {
    return <ReactChartsMap data={data} formatter={formatter} geography={geography} />;
  }

  return (
    <div style={{ height: 400 }}>
      <Spinner />
    </div>
  );
};

export default Map;
