import React from 'react';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_CONFIRM } from '../../../../../../../../../constants/rails';

const SellerProfileIncomplete = () => (
  <Notification
    element={<A18n rsx="component.header.notifications.marketplace.m13" />}
    link="/account/confirm"
    onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_CONFIRM })}
  />
);

export default SellerProfileIncomplete;
