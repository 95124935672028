import React from 'react';
import i18next from 'i18next';
import { FileSelectorIcon } from '@artprice/react-upload';
import './stylesheet.scss';

const UploadPlaceholder = ({ isImageUploaded, url, mimeType, isDragActive, isDragAccept, isDragReject, text, disabled }) => {
  const classNames = ['account-identity-uploader-placeholder'];
  if (isDragAccept) {
    classNames.push('drag-accept');
  } else if (isDragReject) {
    classNames.push('drag-reject');
  } else if (isDragActive) {
    classNames.push('drag-active');
  } else if (disabled) {
    classNames.push('drag-disabled');
  }
  return (
    <div className={`account-confirm-identity-field-component-document-selector-upload-placeholder ${disabled ? 'disabled' : ''}`}>
      {url && (
        <div className="existing-url">
          {mimeType === 'application/pdf' && <object aria-label={i18next.t('components.account.verify.documentselector.m2')} data={url} height={200} width="100%" />}
          {mimeType !== 'application/pdf' && <img src={url} alt={i18next.t('components.account.verify.documentselector.m2')} />}
          {!disabled && !isImageUploaded && <p className="lnk">{i18next.t('components.account.verify.documentselector.m2')}</p>}
          {!disabled && isImageUploaded && <p className="info">{i18next.t('components.account.verify.documentselector.m3')}</p>}
        </div>
      )}

      {!url && (
        <div className={classNames.join(' ')}>
          <FileSelectorIcon />
          {!disabled && <div className="placeholder-text">{text || i18next.t('components.account.verify.identity.m3')}</div>}
        </div>
      )}
    </div>
  );
};

export default UploadPlaceholder;
