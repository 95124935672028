import React from 'react';
import HighlightedTitle from '../../../common/HighlightedTitle';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const AuctionProcess = () => (
  <div className="auction-process">
    <HighlightedTitle title={<A18n rsx="marketplace.howtobid.auctionprocess.m1" />} tag="h2" />

    <div className="steps">
      <div className="step step1">
        <A18n rsx="marketplace.howtobid.auctionprocess.m2" />
      </div>
      <div className="arrow" />
      <div className="step step2">
        <A18n rsx="marketplace.howtobid.auctionprocess.m3" />
      </div>
      <div className="arrow" />
      <div className="step step3">
        <A18n rsx="marketplace.howtobid.auctionprocess.m4" />
      </div>
      <div className="arrow" />
      <div className="step step4">
        <A18n rsx="marketplace.howtobid.auctionprocess.m5" />
      </div>
    </div>
  </div>
);

export default AuctionProcess;
