import React, { useEffect, useState } from 'react';
import i18next from 'i18next';

import './stylesheet.scss';

const SearchBar = ({ className, placeholder = i18next.t('layouts.menu.m2'), onChange, value }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <form
      className={className}
      onSubmit={e => {
        e.preventDefault(); // Needed to stop the form from refreshing the page
        onChange(internalValue);
      }}
    >
      <div className="artp-search-bar artp-input-group">
        <input type="text" value={internalValue ?? ''} placeholder={placeholder} onChange={e => setInternalValue(String(e.target.value) || undefined)} />
        <button type="submit" className="btn">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
