import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import './stylesheet.scss';

const Table = ({ data, currency }) => {
  let previousIndicator = null;
  return (
    <table className="table table-stripped artdbweb-lot-Indicator-Table">
      <thead>
        <tr>
          <th>{i18next.t('artprice.mystore.advert.info.m19')}</th>
          <th className="text-right">
            {i18next.t('lots.indicator.table.m1')} ({currency})
          </th>
          <th className="text-right">{i18next.t('artprice.lib.result.indexresult.m30')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ year, indicator }) => {
          const rate = previousIndicator !== null && (((indicator - previousIndicator) * 100) / previousIndicator).toFixed(2);
          let rateClass = '';
          if (rate > 0) {
            rateClass = 'positive';
          } else if (rate < 0) {
            rateClass = 'negative';
          }
          previousIndicator = indicator;
          return (
            <tr key={year}>
              <td>{year}</td>
              <td className="text-right">{indicator}</td>
              <td className={`text-right ${rateClass}`}>{indicator && rate && `${rate}%`}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  currency: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired,
      indicator: PropTypes.number,
    }),
  ).isRequired,
};

export default Table;
