import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import i18next from 'i18next';
import { Controller } from 'react-hook-form';
import customStyles from '../../../../../../utils/styles/reactSelectStyle';
import { useViewport } from '../../../../../../hooks/useViewport';

const DocumenttypeDropdown = ({ control, name, service, readOnly, width }) => {
  const [documenttypes, setDocumenttypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    service().then(datas => {
      setDocumenttypes(datas.map(({ id, label }) => ({ value: id, label })));
      setIsLoading(false);
    });
  }, []);

  const handleChange = (value, onChange) => {
    onChange(value.value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          styles={customStyles({ width, viewportWidth })}
          classNamePrefix="react-select"
          options={documenttypes}
          isLoading={isLoading}
          readOnly={isLoading}
          isSearchable={false}
          isDisabled={readOnly}
          placeholder={i18next.t('components.account.verify.identity.m4')}
          loadingMessage={() => i18next.t('components.account.verify.identity.m5')}
          value={documenttypes.find(documenttype => documenttype.value === value)}
          onChange={v => handleChange(v, onChange)}
        />
      )}
    />
  );
};

export default DocumenttypeDropdown;
