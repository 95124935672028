import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';

const Tab = ({ active, count, label, loading, outOfBOund, url }) => {
  const [internalSpinning, setInternalSpinning] = useState();

  const redirect = () => {
    if (count > 0 && !active) {
      setInternalSpinning(true);
      window.location = url;
    }
  };

  return (
    <div className={`tab ${active ? 'active' : ''} ${count === 0 ? 'disabled' : ''}`} onClick={redirect}>
      <div>{label}</div>

      <div className="badge-count">
        {!loading && !internalSpinning && (
          <>
            {outOfBOund && '+ '}
            {count && <FormattedNumber value={count} />}
          </>
        )}
        {(loading || internalSpinning) && <i className="fa fa-spin fa-spinner" />}
      </div>
    </div>
  );
};

export default Tab;
