import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { isElement } from 'lodash';
import ReduxProvider from '../../../../../providers/ReduxProvider';
import StandaloneCreate from '../index';

const InternalProvidedCreate = ({ ids, newSelectionKey }) => (
  <ReduxProvider>
    <StandaloneCreate ids={ids} newSelectionKey={newSelectionKey} />
  </ReduxProvider>
);

const UNKNOWN = 'UNKNOWN'; // used to specify that the querySelector has not yet been executed (UNKNOWN is not falsy, and not an elem, so it will return null)
const ProvidedCreate = ({ ids, newSelectionKey, portalSelector }) => {
  const [elem, setElem] = useState(UNKNOWN);

  useEffect(() => {
    let timeoutId;
    if (portalSelector) {
      const found = window.document.querySelector(portalSelector);
      if (found) {
        setElem(found);
      } else {
        // waiting for angular for exemple
        timeoutId = setTimeout(() => {
          setElem(window.document.querySelector(portalSelector));
        }, 100);
      }
    }

    return () => window.clearTimeout(timeoutId);
  }, [portalSelector]);

  if (!portalSelector || !elem) {
    return <InternalProvidedCreate ids={ids} newSelectionKey={newSelectionKey} />;
  }

  if (isElement(elem)) {
    return createPortal(<InternalProvidedCreate ids={ids} newSelectionKey={newSelectionKey} />, elem);
  }

  return null;
};

export default ProvidedCreate;
