import React from 'react';
import { typeformsCallBack } from '../constants';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Contact = ({ lang }) => (
  <div className="ails-info-contact">
    <a className="btn btn-default" href={`https://artprice.typeform.com/to/${typeformsCallBack[lang] || typeformsCallBack.default}`} target="typeforms">
      <A18n rsx="ails.info.m78" />
    </a>
  </div>
);

export default Contact;
