import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useViewport } from '../../../../../../hooks/useViewport';

import './stylesheet.scss';

/* modes */
const NORMAL = 'normal';
const LEFT = 'left';
const RIGHT = 'right';

const Dropdown = ({ button, content, className }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(NORMAL);
  const { viewportWidth } = useViewport();
  const containerRef = useRef();
  const dropdownRef = useRef();

  const { top, height, width, left } = useMemo(() => {
    const boundingClientRect = containerRef.current?.getBoundingClientRect();
    if (boundingClientRect) {
      return {
        top: boundingClientRect.top,
        height: boundingClientRect.height,
        width: boundingClientRect.width,
        left: boundingClientRect.left,
      };
    }
    return { top: 0, height: 0, width: 0, left: 0 };
  }, [containerRef.current, viewportWidth]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (containerRef.current?.contains(e.target)) {
        return;
      }
      setOpen(false);
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [open]);

  useEffect(() => {
    if (open) {
      const containerBoundingClientRect = containerRef.current?.getBoundingClientRect();
      const dropdownBoundingClientRect = dropdownRef.current?.getBoundingClientRect();
      if (containerBoundingClientRect && dropdownBoundingClientRect) {
        if (containerBoundingClientRect.left + containerBoundingClientRect.width / 2 + dropdownBoundingClientRect.width / 2 > viewportWidth) {
          setMode(LEFT);
          return;
        }
        if (containerBoundingClientRect.left + containerBoundingClientRect.width / 2 - dropdownBoundingClientRect.width / 2 < 0) {
          setMode(RIGHT);
          return;
        }
      }
      setMode(NORMAL);
    }
  }, [open, viewportWidth]);

  // Custom settings for mobile
  const mobile = viewportWidth < 768;
  const dropdownTopOffset = mobile ? 1 : 4;
  return (
    <div
      className={classNames('artp-Header-Dropdown-container', className)}
      ref={containerRef}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      {mobile && open && <div className="mobile-opaque-background" onClick={() => setOpen(false)} style={{ top: top + height + dropdownTopOffset }} />}
      <div
        className="artp-Header-Dropdown-button"
        onMouseEnter={() => {
          setOpen(true);
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {button}
      </div>
      {!mobile && open && <div className="desktop-transparent-filler" />}
      {open && (
        <div className={`artp-Header-Dropdown ${mode}`} ref={dropdownRef} style={{ top: height + dropdownTopOffset, '--dpdwn-container-width': `${width}px`, '--dpdwn-container-left': `${left}px` }}>
          <div className="content">{content}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
