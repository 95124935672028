import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import SearchBar from '..';

const ProvidedSearchBar = ({ searchScope, baseParams }) => (
  <ReduxProvider>
    <SearchBar searchScope={searchScope} baseParams={baseParams} />
  </ReduxProvider>
);

export default ProvidedSearchBar;
