import React from 'react';
import i18next from 'i18next';
import AsyncSelect from 'react-select/async';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Button, Col } from 'reactstrap';
import { datasForReactSelect as countriesForReactSelect } from '../../../../../services/wallet/country';
import { isOn as isOnSelector } from '../../../../../redux/selectors/ui/switches';
import { save } from '../../../../../redux/actions/marketplace/stores';
import ErrorBlock from '../../../../common/ErrorBlock';
import schema from './schema';
import Preview from '../Preview';

const StoreForm = ({ store }) => {
  const dispatch = useDispatch();
  const saveInProgress = useSelector(state => isOnSelector(state, { id: `save-store-${store.id}` }));

  const defaultValues = {
    firm: store.contact.firm,
    email: store.contact.email,
    addressline1: store.contact.addressline1,
    addressline2: store.contact.addressline2,
    country: {
      value: store.contact.idcountry,
      label: store.contact.country,
    },
    city: store.contact.city,
    state: store.contact.state,
    cp: store.contact.cp,
    phone: store.contact.phone,
    fax: store.contact.fax,
  };

  const { reset, register, handleSubmit, control, formState, getValues } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const { isSubmitting } = formState;

  const onSubmit = values => {
    dispatch(save(store.id, values));
  };

  const setTemporaryStore = () => {
    const formValues = getValues();
    const temporaryStore = { ...store, ...{ contact: { ...formValues } } };

    if (formValues.country) {
      temporaryStore.contact.idcountry = formValues.country.value;
      temporaryStore.contact.country = formValues.country.label;
    }

    return temporaryStore;
  };

  const handleCancel = e => {
    e.preventDefault();
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for="email" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m1')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="email" {...register('email')} />
          <ErrorBlock error={errors.email} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="firm" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m2')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('firm')} />
          <ErrorBlock error={errors.firm} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressLine1" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m3')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('addressline1')} />
          <ErrorBlock error={errors.addressline1} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressline2" sm={2}>
          &nbsp;
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('addressline2')} />
          <ErrorBlock error={errors.addressline2} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="cp" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m4')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('cp')} />
          <ErrorBlock error={errors.cp} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="city" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m5')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('city')} />
          <ErrorBlock error={errors.city} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="state" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m6')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('state')} />
          <ErrorBlock error={errors && errors.state} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="country" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m7')}
        </Label>
        <Col sm={10}>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <AsyncSelect
                placeholder={i18next.t('marketplace.stores.edit.form.contact.m8')}
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue.length > 0) {
                    return i18next.t('marketplace.stores.edit.form.contact.m9');
                  }
                  return i18next.t('marketplace.stores.edit.form.contact.m10');
                }}
                defaultOptions
                loadOptions={async term => {
                  const countries = await countriesForReactSelect({ term });
                  return countries.map(({ value: v, label }) => ({ value: v, label }));
                }}
                {...field}
              />
            )}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="phone" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m11')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('phone')} />
          <ErrorBlock error={errors.phone} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="fax" sm={2}>
          {i18next.t('marketplace.stores.edit.form.contact.m12')}
        </Label>
        <Col sm={10}>
          <input className="form-control" type="text" {...register('fax')} />
          <ErrorBlock error={errors.fax} />
        </Col>
      </FormGroup>

      <div style={{ display: 'flex', flexFlow: 'row', marginTop: 30 }}>
        <div style={{ flex: 1 }}>
          <Preview setTemporaryStore={setTemporaryStore} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button className="btn btn-default marg marg-r-5" type="button" disabled={isSubmitting} onClick={handleCancel}>
            {i18next.t('marketplace.stores.edit.m1')}
          </Button>

          <Button type="submit" color="primary" disabled={isSubmitting}>
            {saveInProgress && <i className="fa fa-spin fa-spinner" />}
            {!saveInProgress && i18next.t('marketplace.stores.edit.form.contact.m13')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default StoreForm;
