import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { PERIODS, PERIODS_STORE } from '../constants';
import './stylesheet.scss';
import useAnalytics from '../useAnalytics';

const Period = ({ section }) => {
  const dispatch = useDispatch();
  const { filter, period: selectedPeriod, view } = useSelector(getSettings);
  const { changePeriod } = useAnalytics({ section });
  const [periods, setPeriods] = useState(PERIODS);

  useEffect(() => {
    if (filter === 'store') {
      setPeriods(PERIODS_STORE);
    } else {
      setPeriods(PERIODS);
    }
  }, []);

  const handleChange = ({ period }) => {
    changePeriod(period);
    dispatch(updateSettings({ data: { period, withoutImagePriceProduct: [] } }));
  };

  if (view === 'upgrade') return null;

  return (
    <div className="subscriptions-list-common-period">
      {periods.map(p => {
        if (filter === 'store' && p.period === 1) return null;

        return (
          <button key={p.label} type="button" onClick={() => handleChange({ period: p.period })} className={classNames({ active: selectedPeriod === p.period })}>
            {p.label}
          </button>
        );
      })}
    </div>
  );
};

export default Period;
