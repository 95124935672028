import { FormattedNumber, useIntl } from 'react-intl';
import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import A18n from '../../../../../common/A18n';
import Duration from './Duration';
import { getUserContext } from '../../../../../../redux/selectors/userContext';

const SubscriptionCartLine = ({ line, currency, showFirstline = false }) => {
  const intl = useIntl();
  const { lastSubscription } = useSelector(getUserContext);

  const { product, product_quantity: productQuantity = 0 } = line;
  const total = get(product, `prices.${currency}`, 0) * productQuantity;

  return (
    <div className="row cart">
      <ul>
        {showFirstline && (
          <li className="first-line">
            <span>{product.title}</span>
            <span className="flex-1" />
            <span>
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={total} style="currency" currency={currency} />
            </span>
          </li>
        )}
        <li>
          &#x2714; {productQuantity > 1 && <A18n rsx="payments.common.cartsummary.m1" replace={[['%nb%', productQuantity]]} />}
          {productQuantity === 1 && <A18n rsx="payments.common.cartsummary.m2" />}
        </li>
        <Duration product={product} />
        {product.dtstart && product.duration > 1 && (
          <li>
            &#x2714;{' '}
            {lastSubscription && (
              <A18n
                rsx="payments.common.cartsummary.m5"
                replace={[
                  ['%dtstart%', intl.formatDate(product.dtstart)],
                  ['%dtend%', intl.formatDate(new Date(product.dtexpire))],
                ]}
              />
            )}
            {!lastSubscription && <A18n rsx="payments.common.cartsummary.m6" replace={[['%dt%', intl.formatDate(product.dtexpire)]]} />}
          </li>
        )}
      </ul>
    </div>
  );
};

export default SubscriptionCartLine;
