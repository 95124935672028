import React from 'react';
import CookieProvider from '../../providers/CookieProvider';
import Breadcrumb from '..';

const WithReduxProvider = () => (
  <CookieProvider>
    <Breadcrumb />
  </CookieProvider>
);

export default WithReduxProvider;
