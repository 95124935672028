import React, { useState } from 'react';
import { ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, ANALYTICS_FROM_AIL_LANDINGPAGE } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import Contact from './Contact';
import Terms from './Terms';
import A18n from '../../../common/A18n';

const Header = ({ data }) => {
  const { logo, image, aillandingpage, aillandingpagedetails } = data;
  const [displayContact, setDisplayContact] = useState(false);

  const handleClick = () => {
    if (!displayContact) {
      aapiBeacon('click', { from: ANALYTICS_FROM_AIL_LANDINGPAGE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS });
    }

    setDisplayContact(!displayContact);
  };

  return (
    <div className="ails-landingpage-header">
      <div className="c0-1">
        <img src={logo} alt="logo" />
      </div>
      <div className="c0-2">
        {!displayContact && (
          <a onClick={handleClick} className="btn btn-default">
            <A18n rsx="ails.landingpage.header.m1" />
          </a>
        )}
        {displayContact && (
          <a className="btn btn-default" onClick={handleClick}>
            <A18n rsx="ails.landingpage.header.m2" />
          </a>
        )}
      </div>
      <div className="c1">
        <img src={image} alt={aillandingpage.name} />
      </div>
      <div className="c2">
        {!displayContact && <Terms aillandingpagedetails={aillandingpagedetails} />}
        {displayContact && <Contact aillandingpage={aillandingpage} />}
      </div>
      <div className="c3">
        <h1>{aillandingpage.name}</h1>
      </div>
    </div>
  );
};

export default Header;
