import React from 'react';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '../../../providers/ReduxProvider';
import Info from '..';

const ProvidedList = ({ locale, data }) => (
  <ReduxProvider>
    <IntlProvider locale={locale}>
      <Info data={data} />
    </IntlProvider>
  </ReduxProvider>
);

export default ProvidedList;
