import i18Next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddAndCreateButtonsVisibility, getCurrentSelection, getCurrentSelectionKey, initializeWithKey, setAddAndCreateButtonsVisibility } from '../../../../redux/slices/account/lotslists';
import SelectAll from './SelectAll';
import '../stylesheet-common.scss';
import NoAccess from './NoAccess';
import Create from './Create';
import Clear from './Clear';
import './stylesheets.scss';
import AddIcon from './common/AddIcon';
import { useLotslistsAccess, HIDDEN, FORBIDDEN } from '../../../../hooks/lotslists/useLotslistsAccess';

const AddToListButton = ({ ids, newSelectionKey, className }) => {
  const dispatch = useDispatch();
  const currentSelectionKey = useSelector(getCurrentSelectionKey);
  const addAndCreateButtonsVisibility = useSelector(getAddAndCreateButtonsVisibility);
  const selection = useSelector(getCurrentSelection);
  const accessType = useLotslistsAccess();

  useEffect(() => {
    if (selection?.length > 0 && newSelectionKey === currentSelectionKey) dispatch(setAddAndCreateButtonsVisibility(true));
  }, [selection?.length]);

  useEffect(() => {
    dispatch(initializeWithKey({ newSelectionKey }));
  }, [newSelectionKey]);

  if (accessType === HIDDEN || !currentSelectionKey) return null;

  if (accessType === FORBIDDEN) return <NoAccess className={className} />;

  if (addAndCreateButtonsVisibility)
    return (
      <div className={`account-lots-lists-add-to-list-action-buttons ${className ?? ''}`}>
        <div style={{ display: 'flex' }}>
          <SelectAll ids={ids} style={{ marginRight: 10 }} />
          <Create style={{ marginRight: 10 }} disabled={selection?.length === 0} />
        </div>
        <Clear />
      </div>
    );

  return (
    <div className={`account-lots-lists-add-to-list-button ${className ?? ''}`}>
      <button type="button" className="lots-lists-button" onClick={() => dispatch(setAddAndCreateButtonsVisibility(true))}>
        <AddIcon />
        <span style={{ paddingLeft: 5 }}>{i18Next.t('components.lotslists.create.m9')}</span>
      </button>
    </div>
  );
};

export default AddToListButton;
