/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState } from 'react';
import Carousel from '../../../common/Carousel';
import './stylesheet.scss';
import Zoom from './Zoom';

const settings = {
  fade: false,
  dots: true,
  className: 'marketplace-classified-images',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Images = ({ classified }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [zoomUrl, setZoomUrl] = useState();
  const [images, setImages] = useState([]);
  const [fullImages, setFullImages] = useState([]);

  useEffect(() => {
    if (Array.isArray(classified.imageUrl)) {
      setImages([...classified.imageUrl]);
      setFullImages([...classified.fullImageUrl]);
    } else {
      setImages([classified.imageUrl]);
      setFullImages([classified.fullImageUrl]);
    }
  }, [classified]);

  const handleClick = fullImageUrl => {
    setZoomUrl(fullImageUrl);
    setIsOpen(true);
  };

  const handleClose = () => {
    setZoomUrl(undefined);
    setIsOpen(false);
  };

  return (
    <div>
      <Zoom url={zoomUrl} isOpen={isOpen} onClose={handleClose} />
      <Carousel
        settings={settings}
        slides={images.map((m, i) => (
          <img style={{ cusrsor: 'pointer' }} key={images[i]} onClick={() => handleClick(fullImages[i])} src={images[i]} title={classified.imgTitle} alt={classified.imgAlt} />
        ))}
      />
    </div>
  );
};

export default Images;
