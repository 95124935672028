import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_FROM_HOMEPAGE } from '../../../../constants/rails';
import { getMegaBanAils } from '../../../../redux/slices/homepage';

const Megaban = () => {
  const ail = useSelector(getMegaBanAils);

  if (!ail) return null;

  return (
    <div className="containe block" style={{ textAlign: 'center' }}>
      <a target="megaban" href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_HOMEPAGE}`} key={ail.id}>
        <img alt={ail.title} src={ail.images.desktop} className="radius" style={{ maxWidth: '100%' }} />
        <img alt={ail.title} src={ail.images.mobile} className="radius" style={{ maxWidth: '100%' }} />
      </a>
    </div>
  );
};

export default Megaban;
