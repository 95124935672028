import React from 'react';

import './stylesheet.scss';

const HorizontalBar = React.forwardRef(({ children, className = '', ...otherProps }, ref) => (
  <div className={`horizontal-bar ${className || ''}`.trim()} ref={ref} {...otherProps}>
    {children}
  </div>
));

export default HorizontalBar;
