/* eslint-disable global-require */
module.exports = [
  'components.account.verify.identity.m1',
  'components.account.verify.identity.m2',
  'components.account.verify.identity.m3',
  'components.account.verify.identity.m4',
  'components.account.verify.identity.m5',
  'components.account.verify.identity.m6',
  'components.account.verify.identity.m7',
  'components.account.verify.identity.m9',
  'components.account.verify.identity.m10',
  'components.account.verify.identity.personal.m1',
  'components.account.verify.identity.personal.m2',
  'components.account.verify.identity.personal.m3',
  'components.account.verify.identity.personal.m4',
  'components.account.verify.identity.personal.m5',
  'components.account.verify.identity.personal.m6',
  'components.account.verify.identity.personal.m7',
  'components.account.verify.identity.personal.m8',
  'components.account.verify.identity.personal.m9',
  'components.account.verify.identity.personal.m10',
  'components.account.verify.identity.personal.m11',
  'components.account.verify.identity.personal.m13',
  'components.account.verify.identity.personal.m14',
  'components.account.verify.identity.personal.m16',
  'javascripts.locales.m22',
  'javascripts.locales.m4',
  ...require('./FormPro/i18n'),
  ...require('./fieldComponents/i18n'),
];
