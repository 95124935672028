import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_SEARCH, ANALYTICS_FROM_SEARCH_UNIVERSAL, IMAGE_SOURCE } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import SectionHeader from '../SectionHeader';
import Skeleton from '../skeletons/Square';
import A18n from '../../../common/A18n';
import { classifiedSearchOnKeyword } from '../../../../services/search';
import { useUniversalSearch } from '../hooks/useUniversalSearch';
import useCancellable from '../hooks/useCancellable';

function aapiWrapper(idclassified) {
  aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op: ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, idclassified });
}

const Classifieds = ({ term }) => {
  const searchClassifieds = useCancellable((v, signal) => classifiedSearchOnKeyword({ term: v, signal }));
  const { observeCallbackRef, loaded, entities: classifieds } = useUniversalSearch({ term, searchFunc: searchClassifieds });

  return (
    <div ref={observeCallbackRef}>
      <SectionHeader
        count={classifieds.totalCount}
        title={<A18n rsx="search.universal.classifieds.m1" />}
        url={`/${i18next.t('routes.marketplace')}?sort=best_match&terms=${encodeURIComponent(term)}`}
        outOfBounds={classifieds.outOfBounds}
        op={ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_SEARCH}
      />
      {!loaded && <Skeleton />}
      {loaded && (
        <div className="items-container">
          <div className="items">
            {classifieds.data.map(classified => (
              <div key={classified.id} className="item" title={classified.title}>
                <div className="r0">
                  <a href={classified.url} className={classNames({ underline: !classified.images?.[0] })} onClick={() => aapiWrapper(classified.id)}>
                    <div
                      className={classNames({ picto: classified.images?.[0], 'fake-picto': !classified.images?.[0] })}
                      style={{ backgroundImage: `url("${classified.images?.[0] || `${IMAGE_SOURCE}artist/lot-bg-${(classified.id % 5) + 1}.png`}")` }}
                    />
                  </a>
                </div>
                <div className="r1">
                  <a href={classified.url} onClick={() => aapiWrapper(classified.id)}>
                    {classified.title}
                  </a>
                </div>
                <div className="r2">
                  <a href={classified.url} onClick={() => aapiWrapper(classified.id)}>
                    par {classified.artist}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Classifieds;
