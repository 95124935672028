import i18next from 'i18next';
import { Button } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get as fetchStore } from '../../../../redux/actions/marketplace/stores';
import { getStore, getErrorForStore } from '../../../../redux/selectors/marketplace/stores';
import SocialNetworkSharing from '../../../common/Share';
import Informations from './Informations';
import A18n from '../../../common/A18n';
import StoreFollow from '../Follow';
import Images from './Images';
import './stylesheets.scss';

const Header = ({ store: storeProvided, idstore, displayEmptyTabs }) => {
  const dispatch = useDispatch();
  const [store, setStore] = useState(storeProvided);
  const error = useSelector(state => getErrorForStore(state, { idstore }));
  const storeFromState = useSelector(state => getStore(state, { idstore }));

  useEffect(() => {
    if (idstore && !storeProvided) {
      dispatch(fetchStore(idstore));
    }
  }, [idstore]);

  useEffect(() => {
    if (storeFromState) {
      setStore(storeFromState);
    }
  }, [storeFromState]);

  if (!store) {
    return null;
  }

  return (
    <div className="marketplace-store-header-store">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 back-button">
            <Button className="btn btn-default" onClick={() => window.history.back()} style={{ width: 'fit-content', marginBottom: 15 }}>
              <A18n rsx="component.myartprice.lots.detail.m1" />
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="store-content">
              <div className="header">
                <h1>
                  <span>{store.sitename}</span>
                  {store.idprofessionaltype && (
                    <span>
                      {store.idprofessionaltype === 1 && <span className="small">({i18next.t('components.account.confirm.m6')})</span>}
                      {store.idprofessionaltype === 2 && <span className="small">({i18next.t('components.account.confirm.m5')})</span>}
                    </span>
                  )}
                  <StoreFollow idstore={store.id} noLabel size="lg" />
                  <SocialNetworkSharing hashtagsAddons={['artprice', 'store', store.sitename]} />
                </h1>
                <p>
                  {error && error.status === 401 && (
                    <span className="error">
                      <i className="fa fa-exclamation-triangle" />
                      {i18next.t('marketplace.stores.header.store.m1')}
                    </span>
                  )}
                </p>
              </div>
              <div className="body">
                <div className="images">
                  <Images images={store.images.filter(image => image.image_large_url)} />
                </div>
                <div className="infos">
                  <Informations store={store} displayEmptyTabs={displayEmptyTabs} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
