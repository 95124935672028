import i18next from 'i18next';
import * as Yup from 'yup';
import { fileSize6M, idDocumentRequired, firmDocumentRequired, fileFormat } from '../validationFns';

Yup.setLocale({
  mixed: {
    required: i18next.t('components.account.verify.identity.m1'),
    default: i18next.t('components.account.verify.identity.m2'),
    notType: i18next.t('components.account.verify.identity.m10'),
  },
});

function siretOrVatinRequired() {
  const { siret, vatin } = this.parent;
  return !(!siret && !vatin);
}

function vatinRegexValidation() {
  const { vatinRegex, vatin } = this.parent;

  if (vatinRegex && vatin) {
    return vatin.match(vatinRegex) !== null;
  }

  return true;
}

const ValidationSchema = Yup.object().shape({
  company: Yup.string().trim().required(),
  line1: Yup.string().trim().required(),
  zipCode: Yup.string().trim().required(),
  city: Yup.string().trim().required(),
  state: Yup.string().trim(),
  country: Yup.string().required(),
  phone: Yup.string().trim().required(), // FIXME: find regexp
  siret: Yup.string().test({ test: siretOrVatinRequired, message: i18next.t('components.account.verify.identity.m6') }),
  vatin: Yup.string()
    .test({ test: siretOrVatinRequired, message: i18next.t('components.account.verify.identity.m6') })
    .test({ test: vatinRegexValidation, message: i18next.t('components.account.verify.identity.m10') }),
  vatinRegex: Yup.string(),
  vatinInitialString: Yup.string(),
  idDocument: Yup.mixed().test(idDocumentRequired).test(fileSize6M).test(fileFormat),
  idDocument2: Yup.mixed().test(fileSize6M).test(fileFormat),
  idDocumenttype: Yup.string().required(),
  title: Yup.string().required(),
  firstName: Yup.string().trim().required(),
  idDocumentUrl: Yup.string(),
  firmDocumentUrl: Yup.string(),
  lastName: Yup.string().trim().required(),
  birthDate: Yup.date().required(),
  firmDocument: Yup.mixed().test(firmDocumentRequired).test(fileSize6M).test(fileFormat),
  firmDocument2: Yup.mixed().test(fileSize6M).test(fileFormat),
});

export default ValidationSchema;
