import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayedPeriod, getPeriods } from '../../../../../redux/selectors/marketplace/stores/stats';
import { choosePeriod, fetchBestClassifieds } from '../../../../../redux/actions/marketplace/stores/stats';
import { formatFns } from '../../../../../utils/dates/format';

import './stylesheet.scss';

const PeriodSelector = () => {
  const dispatch = useDispatch();
  const period = useSelector(getDisplayedPeriod);
  const periods = useSelector(getPeriods);

  useEffect(() => {
    if (period) {
      dispatch(
        fetchBestClassifieds({
          startDate: period.start,
          endDate: period.end,
        }),
      );
    }
  }, [period]);

  if (!period) {
    return null;
  }

  return (
    <div className="store-Stats-PeriodSelector">
      {Array.isArray(periods) && (
        <select
          className="store-Stats-PeriodSelector-select"
          onChange={e => {
            const [start, end] = e.target.value.split('#');
            dispatch(
              choosePeriod({
                start,
                end,
              }),
            );
          }}
          value={`${period.start}#${period.end}`}
        >
          {[...periods].reverse().map(({ start, end }) => {
            const sameYear = new Date(start).getFullYear() === new Date(end).getFullYear();
            const formatStart = sameYear ? 'dd MMM' : 'dd MMM yyyy';

            return (
              <option key={`${start}#${end}`} value={`${start}#${end}`}>
                {`
                  ${i18next.t('events.list.filters.dates.from')}
                  ${formatFns(start, formatStart)}
                  ${i18next.t('marketplace.auction._show.html.m53')}
                  ${formatFns(end, 'dd MMM yyyy')}
                `}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default PeriodSelector;
