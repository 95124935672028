import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from './constants';
import { get } from '../../../redux/selectors/ui/dictionary';
import { set } from '../../../redux/actions/ui/dictionary';
import { IMAGE_SOURCE } from '../../../constants/rails';
import IsUserExists from './IsUserExists';
import Modal from '../ui/Modal';
import Login from './Login';
import './stylesheet.scss';

const LoginOrRegistrationModal = ({ children, onUserNotExists, onLogin, titleLogin, titleCheckUser }) => {
  const openIdentification = useSelector(state => get(state, { id: LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT }));
  const dispatch = useDispatch();
  const [diplayPassword, setDiplayPassword] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onUserExists = email => {
    setDiplayPassword(true);
    setUserEmail(email);
  };

  if (!openIdentification) return null;

  return (
    <Modal className="subscriptions-list-identification common-stylesheets-forms" onClose={() => dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, false))} open={openIdentification}>
      <div className={classNames({ 'subscriptions-list-identification-content': true, 'two-cols': !!children })}>
        <div className="close" onClick={() => dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, false))}>
          <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
        </div>
        <div className="top">
          <h1>
            {!diplayPassword && titleCheckUser}
            {diplayPassword && titleLogin}
          </h1>
        </div>
        <div className="left">
          {!diplayPassword && <IsUserExists onExists={onUserExists} onNotExists={onUserNotExists} email={userEmail} />}
          {diplayPassword && <Login email={userEmail} onSuccess={onLogin} />}
        </div>
        {children && <div className="right">{children}</div>}
      </div>
    </Modal>
  );
};

export default LoginOrRegistrationModal;
