import React from 'react';
import { ANALYTICS_FROM_SEARCH_UNIVERSAL } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { useViewport } from '../../../../hooks/useViewport';
import Arrow from '../../../common/Arrow';
import A18n from '../../../common/A18n';
import Count from './Count';
import './stylesheet.scss';

const SectionHeader = ({ count, url, title, outOfBounds, op }) => {
  const { viewportWidth } = useViewport();
  const handleClick = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op });
  };

  return (
    <div className="search-universale-section-header">
      <h3>
        <div className="title">
          <a href={url} className="no-underline" onClick={handleClick}>
            {title}
          </a>
          {viewportWidth >= 992 && <Count onClick={handleClick} count={count} url={url} outOfBounds={outOfBounds} />}
        </div>
        {viewportWidth < 992 && <Count onClick={handleClick} count={count} url={url} outOfBounds={outOfBounds} />}
        {count > 0 && (
          <div className="all">
            <a href={url} className="no-underline" onClick={handleClick}>
              <A18n rsx="search.universal.sectionheader.m4" />
              <Arrow style={{ marginLeft: 5 }} />
            </a>
          </div>
        )}
      </h3>
    </div>
  );
};

export default SectionHeader;
