import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Expositions from '../index';

const ProvidedExpositions = ({ id }) => (
  <ReduxProvider>
    <Expositions id={id} />
  </ReduxProvider>
);

export default ProvidedExpositions;
