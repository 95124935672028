import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areThemesLoading } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import Spinner from '../../../../common/spinners/Spinner';
import { QUANTITY_DOWN, QUANTITY_UP } from '../constants';
import A18n from '../../../../common/A18n';
import useAnalytics from '../useAnalytics';
import './stylesheet.scss';

const Users = ({ section }) => {
  const { quantity } = useSelector(getSettings);
  const dispatch = useDispatch();
  const loading = useSelector(areThemesLoading);
  const { changeQuantity } = useAnalytics({ section });

  return (
    <div className="subscriptions-list-common-users">
      <A18n rsx="subscriptions.list.common.users.m1" />
      <div className="group">
        <button
          type="button"
          disabled={loading || quantity <= 1}
          onClick={() => {
            changeQuantity(QUANTITY_DOWN);
            dispatch(updateSettings({ data: { quantity: quantity - 1 } }));
          }}
        >
          -
        </button>
        <div className="quantity">
          {loading && <Spinner faSize={1} />}
          {!loading && <span>{quantity}</span>}
        </div>
        <button
          type="button"
          disabled={loading || quantity >= 10}
          onClick={() => {
            changeQuantity(QUANTITY_UP);
            dispatch(updateSettings({ data: { quantity: quantity + 1 } }));
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default Users;
