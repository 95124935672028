/* eslint-disable global-require */
module.exports = [
  'auctioneers.contact.subjects.m1',
  'auctioneers.contact.subjects.m2',
  'auctioneers.contact.subjects.m3',
  'auctioneers.contact.subjects.m4',
  'auctioneers.contact.subjects.m5',
  'auctioneers.contact.subjects.m6',
  'auctioneers.contact.subjects.m7',
  'auctioneers.contact.subjects.m8',
  'auctioneers.contact.subjects.m9',
  'auctioneers.contact.subjects.m10',
];
