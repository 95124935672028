import React from 'react';
import DevInfoContent from './Content';

const DevInfo = () => {
  if (!process.env.RAILS_ENV || process.env.RAILS_ENV === 'production') {
    return null;
  }

  return <DevInfoContent />;
};

export default DevInfo;
