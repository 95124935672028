import React from 'react';
import './stylesheet.scss';

const Thumbnails = ({ children, src }) => (
  <div className="homepage-reports-thumbnail radius" style={{ backgroundImage: `url("${src}")` }}>
    <div className="bg" />
    {children}
  </div>
);

export default Thumbnails;
