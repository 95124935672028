import i18next from 'i18next';
import React from 'react';
import Item from '../../../Search/Item';
import Checkbox from '../../../inputs/Checkbox';

const FavoriteArtists = () => (
  <Item name="fav">
    <Checkbox label={i18next.t('search.helpers.items.favorite.artists.m1')} />
  </Item>
);

export default FavoriteArtists;
