import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useViewport } from '../../../../hooks/useViewport';
import MobileMenuContainer from '../Bottom/Menu/MobileContainer';

const Container = ({ locale, className, id, children }) => {
  const { viewportWidth } = useViewport();
  const [isMobile, setIsMobile] = useState(false);
  const [height100, setHeight100] = useState(false); // height100 needed to open the menu in mobile
  useEffect(() => {
    if (viewportWidth !== undefined) {
      setIsMobile(viewportWidth < 768);
    }
  }, [viewportWidth]);

  return (
    <div id={id} className={classNames(className, { 'mobile-header': isMobile, 'height-100': height100 })}>
      {children}
      {isMobile && <MobileMenuContainer setHeight100={setHeight100} height100={height100} locale={locale} />}
    </div>
  );
};

export default Container;
