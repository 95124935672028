export const typeformsMediaKit = {
  fr: 'u2C4kFPg',
  en: 'lXsXULwz',
  es: 'KAnr4AtJ',
  zh: 'bptSBGHg',
  it: 'Pq65niF8',
  de: 'DCZLZswy',
};

export const typeformsCallBack = {
  fr: 'KVAqjJqn',
  default: 'NfkE4oOt',
};
