/* eslint-disable global-require */
module.exports = [
  'marketplace.stores.edit.form.images.image.cropper.m1',
  'marketplace.stores.edit.form.images.image.cropper.m2',
  'marketplace.stores.edit.form.images.image.cropper.m3',
  'marketplace.stores.edit.form.images.image.cropper.m4',
  'marketplace.stores.edit.form.images.image.cropper.m5',
  'marketplace.stores.edit.form.images.image.cropper.m6',
  'marketplace.stores.edit.form.images.image.cropper.m7',
];
