import { useEffect, useState } from 'react';
import { useOnScreen } from '../../../../hooks/useOnScreen';

const DEFAULT_STATE = Object.freeze({ outOfBounds: false, totalCount: -1, data: Object.freeze([]) });

/* 
Custom hook to factorize the different universal search block process
(trigger search on visibility, manage the entities, manage the loaded state)
    term: string
    searchFunc: function that returns a response of the following shape : { outOfBounds, totalCount, data }
 */
export function useUniversalSearch({ term, searchFunc }) {
  const [isVisible, observeCallbackRef] = useOnScreen();
  const [loaded, setLoaded] = useState(false);
  const [entities, setEntities] = useState(DEFAULT_STATE);

  useEffect(() => {
    setLoaded(false);
    setEntities(DEFAULT_STATE);
  }, [term]);

  useEffect(() => {
    if (!loaded && term && isVisible) {
      searchFunc(term).then(data => {
        // if data is undefined, it means the call was cancelled
        if (data) {
          setEntities(data);
          setLoaded(true);
        }
      });
    }
  }, [term, isVisible, loaded]);

  return {
    observeCallbackRef,
    loaded,
    entities,
  };
}
