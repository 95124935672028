import React from 'react';
import i18next from 'i18next';
import Select from '../../../inputs/Select';
import Item from '../../../Search/Item';

const IdentificationsSelect = () => (
  <Item label={i18next.t('lots.search.filters.inputs.lotartistassociationtype.m1')} name="idlotartistassociationtype">
    <Select placeholder={i18next.t('lots.search.filters.inputs.lotartistassociationtype.m2')} facet="idlotartistassociationtype" />
  </Item>
);

export default IdentificationsSelect;
