/* eslint-disable global-require */
module.exports = [
  'estimates.info.m1',
  'estimates.info.m2',
  'estimates.info.m3',
  'estimates.info.m4',
  'estimates.info.m5',
  'estimates.info.m6',
  'estimates.info.m7',
  'estimates.info.m8',
  'estimates.info.m9',
  'estimates.info.m10',
  'estimates.info.m11',
  'estimates.info.m12',
  'estimates.info.m13',
  'estimates.info.m14',
  'estimates.info.m15',
  'estimates.info.m16',
  'estimates.info.m17',
  'estimates.info.m18',
  'estimates.info.m19',
  'estimates.info.m20',
  'estimates.info.m21',
  'estimates.info.m22',
  'estimates.info.m23',
  'estimates.info.m24',
  'estimates.info.m25',
  'estimates.info.m26',
  'estimates.info.m27',
  'estimates.info.m28',
  'estimates.info.m29',
  'estimates.info.m30',
  'estimates.info.m31',
  'estimates.info.m32',
  'estimates.info.m33',
  'estimates.info.m34',
  'estimates.info.m35',
  'estimates.info.m36',
  'estimates.info.m37',
  'estimates.info.m38',
  'estimates.info.m39',
  'estimates.info.m40',
  'estimates.info.m41',
  ...require('./PurchaseNotAvailable/i18n'),
];
