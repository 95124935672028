import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import HtmlEditor from '../../../../common/HtmlEditor';
import { dispatchCreateMessage } from '../../../../../redux/actions/marketplace/classifieds/messages';
import A18n from '../../../../common/A18n';

const Editor = ({ classified, idcustomer }) => {
  const dispatch = useDispatch();
  const isClassifiedOwner = idcustomer === classified.idcustomer;
  const [message, setMessage] = useState(isClassifiedOwner ? <A18n rsx="marketplace.free.show.contact.m12" /> : '');

  return (
    <div id="editor">
      <HtmlEditor onChange={setMessage} value={message} />

      <br />

      {/* send button */}
      <button type="button" className="btn btn-info" disabled={isClassifiedOwner} onClick={() => dispatch(dispatchCreateMessage({ message, idclassified: classified.id, idcustomerInterlocutor: classified.idcustomer }))}>
        <i className="fa fa-send" /> <A18n rsx="marketplace.free.show.contact.m7" />
      </button>

      {/* link to legal info */}
      <a href="/block/marketplace_legals?modal=true" data-toggle="modal" data-target="#base-modal" className="pull-right marg marg-t-5">
        <A18n rsx="footer.marketplace_legals" />
      </a>
    </div>
  );
};

export default Editor;
