/* eslint-disable global-require */
module.exports = [
  ...require('./ArtistSearch/i18n'),
  ...require('./AuctioneerSearch/i18n'),
  ...require('./Dates/i18n'),
  ...require('./Dimensions/i18n'),
  ...require('./Prices/i18n'),
  ...require('./QuickList/i18n'),
  ...require('./SearchBar/i18n'),
  ...require('./Select/i18n'),
  ...require('./Years/i18n'),
];
