import React from 'react';
import Carousel from '../../common/Carousel';
import { aapiBeacon } from '../../../services/analytics/aapi';
import './stylesheets.scss';

const ThumbnailsCarousel = ({ withAil, classNameContainer, shadow = false, data, id, urlLink, settings = {} }) => {
  const divId = `lots-thumbnails-carousel-${id}`;

  const afterChange = newIndex => {
    aapiBeacon('lotimage', { idlot: id, index: newIndex, op: 'list' });
  };

  const carouselSettings = {
    dots: true,
    fade: true,
    className: '',
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange,
    ...settings,
  };

  return (
    <div className={`lots-thumbnails-carousel ${classNameContainer}`} id={divId}>
      <Carousel
        settings={carouselSettings}
        slides={Object.values(data).map(slide => (
          <div className="lots-thumbnails-carousel-slide" key={`${slide.id}-${slide.alt}`}>
            {urlLink && (
              <a href={urlLink}>
                <img className={`${withAil ? 'ail' : ''} ${shadow ? 'shadow' : ''}`} src={slide.urlImage} alt={slide.alt} />
              </a>
            )}

            {!urlLink && <img className={`${withAil ? 'ail' : ''} ${shadow ? 'shadow' : ''}`} src={slide.urlImage} alt={slide.alt} />}
          </div>
        ))}
      />
    </div>
  );
};

export default ThumbnailsCarousel;
