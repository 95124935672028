import React from 'react';
import i18next from 'i18next';
import A18n from '../../../../../../common/A18n';

const styleVisible = { maxHeight: 100, paddingTop: 5 };
const styleHidden = { maxHeight: 0, paddingTop: 0 };

const Store = ({ handleVisibleSection, visibleSection }) => (
  <>
    <div className="module-container">
      <div className="module no-border" style={{ textAlign: 'center', margin: '0 15px', flexFlow: 'column', fontSize: 18 }}>
        <div>
          <A18n
            trustHtml
            rsx="subscriptions.purchase.list.theme-details-modale.marketplace.store.m5"
            replace={[
              ['%strong%', '<strong>'],
              ['%/strong%', '</strong>'],
            ]}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <a href="/subscription" style={{ fontSize: 18, fontWeight: 600, textDecoration: 'none' }}>
            <A18n rsx="subscriptions.purchase.list.theme-details-modale.marketplace.store.m6" />
          </a>
        </div>
      </div>
    </div>
    <div className="module-container">
      <div className="module">
        <div className="module-label" onClick={() => handleVisibleSection(0)}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m1')}
          {visibleSection === 0 && <i className="fa fa-angle-down" />}
          {visibleSection !== 0 && <i className="fa fa-angle-right" />}
        </div>
        <div className="module-details" style={visibleSection === 0 ? styleVisible : styleHidden}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m2')}
        </div>
      </div>
    </div>
    <div className="module-container">
      <div className="module">
        <div className="module-label" onClick={() => handleVisibleSection(1)}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m3')}
          {visibleSection === 1 && <i className="fa fa-angle-down" />}
          {visibleSection !== 1 && <i className="fa fa-angle-right" />}
        </div>
        <div className="module-details" style={visibleSection === 1 ? styleVisible : styleHidden}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m4')}
        </div>
      </div>
    </div>
    <div className="module-container">
      <div className="module">
        <div className="module-label" onClick={() => handleVisibleSection(3)}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m7')}
          {visibleSection === 3 && <i className="fa fa-angle-down" />}
          {visibleSection !== 3 && <i className="fa fa-angle-right" />}
        </div>
        <div className="module-details" style={visibleSection === 3 ? styleVisible : styleHidden}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m8')}
        </div>
      </div>
    </div>
    <div className="module-container">
      <div className="module">
        <div className="module-label" onClick={() => handleVisibleSection(4)}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m9')}
          {visibleSection === 4 && <i className="fa fa-angle-down" />}
          {visibleSection !== 4 && <i className="fa fa-angle-right" />}
        </div>
        <div className="module-details" style={visibleSection === 4 ? styleVisible : styleHidden}>
          {i18next.t('subscriptions.purchase.list.theme-details-modale.marketplace.store.m10')}
        </div>
      </div>
    </div>
  </>
);

export default Store;
