import React from 'react';
import i18next from 'i18next';
import '../../common/reactSelect/stylesheet.scss';
import Search from './Search';
import Facet from './Facet';

const Artist = ({ className, searchOnlyInFavorites = false, withFacets = false, withSearch = false }) => (
  <div className="artp-input-group common-search-filters-select">
    <div className="artp-input-label">{i18next.t('marketplace.searchbars.common.artist.m1')}</div>
    {withFacets && <Facet className={className} />}
    {withSearch && <Search className={className} searchOnlyInFavorites={searchOnlyInFavorites} />}
  </div>
);

export default Artist;
