/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { areThemesUnavailable } from '../../../../../redux/selectors/subscriptions/physicproducts';
import {
  ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION_NEW_COMER,
  ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL,
  IMAGE_SOURCE,
  ANALYTICS_CLICK_OPERATION_TO_CONTACT_ESTIMATE,
} from '../../../../../constants/rails';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { STORE, SUBSCRIPTION } from '../../common/constants';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { a18n } from '../../../../../utils/a18n';
import './stylesheet.scss';
import { aapiBeacon } from '../../../../../services/analytics/aapi';

const Others = () => {
  const dispatch = useDispatch();
  const subscriptionsUnavailable = useSelector(areThemesUnavailable);
  const { filter, newcomer, from } = useSelector(getSettings);

  const handleClick = f => {
    dispatch(
      updateSettings({
        data: {
          filter: f,
        },
      }),
    );

    if (f === SUBSCRIPTION) {
      aapiBeacon('click', { from, op: newcomer ? ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION_NEW_COMER : ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION });

      window.history.replaceState(null, '', `/subscription`);
      window.scrollTo(0, 0);
    } else {
      aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL });
      window.location = `/${a18n('routes.marketplace')}/how-to-sell`;
    }
  };

  return (
    <div className={`subscriptions-free-services-others ${subscriptionsUnavailable ? 'no-data' : ''}`}>
      <div className="bg-left" />
      <div className="bg-right" />

      <div className="container">
        <div className="block">
          <h2
            onClick={() => {
              aapiBeacon('click', { from, op: ANALYTICS_CLICK_OPERATION_TO_CONTACT_ESTIMATE });
              window.open('/contact?active=block1&id=387', '_blank');
            }}
          >
            <img alt="free-registration" src={`${IMAGE_SOURCE}icon-fleche.svg`} />
            {i18next.t('subscriptions.purchase.free-services.others.m1')}
          </h2>
        </div>
        {filter === SUBSCRIPTION && (
          <div className="block">
            <h2 onClick={() => handleClick(STORE)}>
              <img alt="free-registration" src={`${IMAGE_SOURCE}icon-fleche.svg`} />
              {i18next.t('subscriptions.purchase.free-services.others.m3')}
            </h2>
          </div>
        )}
        {filter === STORE && (
          <div className="block">
            <h2 onClick={() => handleClick(SUBSCRIPTION)}>
              <img alt="free-registration" src={`${IMAGE_SOURCE}icon-fleche.svg`} /> {i18next.t('subscriptions.purchase.free-services.others.m5')}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};
export default Others;
