import React, { Component } from 'react';
import { func, shape, string } from 'prop-types';
import { Dropdown, FormGroup, Label, Input, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import i18next from 'i18next';

import { DT_START, DT_EXPIRE } from '../../../../../constants/events';

export const capitalize = label => label && `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

class DatesDropdown extends Component {
  constructor(props) {
    super(props);

    const { dates } = this.props;

    this.state = {
      dropdownOpen: false,
      [DT_START]: dates.from,
      [DT_EXPIRE]: dates.to,
    };
  }

  setDate(dateName, value) {
    this.setState(() => ({ [dateName]: value }));
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { dropdownOpen, [DT_START]: from, [DT_EXPIRE]: to } = this.state;
    const { choose, dates } = this.props;

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="dates-filter">
        <DropdownToggle caret>
          <span>{i18next.t('events.list.filters.dates.title')}</span>
          <i className={`fa fa-caret-down${dropdownOpen ? ' is-open' : ''}`} />
        </DropdownToggle>

        <DropdownMenu right>
          <DropdownItem header>{i18next.t('events.list.filters.dates.dropdownheader')}</DropdownItem>
          <DropdownItem divider />

          <div className="dates-dropdown-content">
            <div className="flex-container">
              <FormGroup>
                <Label for="events-filter-date-start">{i18next.t('events.list.filters.dates.from')}</Label>
                <Input type="date" name="events-filter-date-start" id="events-filter-date-start" defaultValue={dates.from} onChange={e => this.setDate(DT_START, e.target.value)} onClick={e => e.stopPropagation()} />
              </FormGroup>
              <i className="fa fa-arrow-right font-1-5em" />
              <FormGroup>
                <Label for="events-filter-date-expire">{i18next.t('events.list.filters.dates.to')}</Label>
                <Input type="date" name="events-filter-date-expire" id="events-filter-date-expire" defaultValue={dates.to} onChange={e => this.setDate(DT_EXPIRE, e.target.value)} onClick={e => e.stopPropagation()} />
              </FormGroup>
            </div>

            <div className="validation-button">
              <Button color="primary" onClick={() => choose(from, to)}>
                {i18next.t('events.list.filters.dates.confirm')}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

DatesDropdown.defaultProps = {
  choose: () => undefined,
  dates: { from: undefined, to: undefined },
};

DatesDropdown.propTypes = {
  choose: func,
  dates: shape({ from: string, to: string }),
};

export default DatesDropdown;
