import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { includeInPushArtist, unfollowArtist } from '../../../../redux/actions/myartprice/datas';
import { updatePreferences } from '../../../../redux/actions/myartprice/settings';
import { SHORTCUT_SETTINGS_ARTISTS } from '../../../../constants/myartprice';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import { useViewport } from '../../../../hooks/useViewport';
import { a18n } from '../../../../utils/a18n';

const ArtistDatas = ({ artistData, ended, futures, auctions, fixedPrice }) => {
  const dispatch = useDispatch();
  const { viewportWidth } = useViewport();
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [includeInPush, setIncludeInPush] = useState(true);

  const deleteArtistButton = idArtist => (
    <i className="fa fa-trash-o" title={a18n('marketplace.classified.follow.m2')} style={{ cursor: 'pointer', position: 'absolute' }} onClick={() => dispatch(unfollowArtist({ idartists: [idArtist] }))} />
  );

  const handleIncludeInPush = () => {
    dispatch(updatePreferences(SHORTCUT_SETTINGS_ARTISTS, true, { idartist: artistData.id }));
    dispatch(includeInPushArtist({ idartist: artistData.id }));
  };

  useEffect(() => {
    if (artistData) {
      setIncludeInPush(artistData.includeInPush);
    }
  }, [artistData]);

  return (
    <>
      <tr>
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <td className="artist" rowSpan={viewportWidth >= 768 ? 1 : 2} onMouseOver={() => setShowUnfollow(true)} onMouseLeave={() => setShowUnfollow(false)}>
          {!includeInPush && <i title={a18n('myartprice.index.m13')} className="fa fa-bell-slash-o" style={{ marginRight: 5, cursor: 'pointer' }} onClick={handleIncludeInPush} />}
          <Artist id={artistData.id} withLink />
          {showUnfollow && <>&nbsp; {deleteArtistButton(artistData.id)}</>}
        </td>
        {futures && (
          <>
            <td>{artistData.new_future > 0 ? <a href={artistData.url_new_future}>{artistData.new_future}</a> : '-'}</td>
            <td className="hidden-xs">{artistData.hasOwnProperty('future_all') && artistData.future_all > 0 ? <a href={artistData.url_future_all}>{artistData.future_all}</a> : '-'}</td>
          </>
        )}
        {ended && (
          <>
            <td>{artistData.new_past > 0 ? <a href={artistData.url_new_past}>{artistData.new_past}</a> : '-'}</td>
            <td className="hidden-xs">{artistData.hasOwnProperty('past_all') && artistData.past_all > 0 ? <a href={artistData.url_past_all}>{artistData.past_all}</a> : '-'}</td>
          </>
        )}
        {(auctions || fixedPrice) && (
          <>
            <td>{artistData.new_marketplace > 0 ? <a href={artistData.url_new_marketplace}>{artistData.new_marketplace}</a> : '-'}</td>
            <td className="hidden-xs">{artistData.hasOwnProperty('marketplace_all') && artistData.marketplace_all > 0 ? <a href={artistData.url_marketplace_all}>{artistData.marketplace_all}</a> : '-'}</td>
          </>
        )}
      </tr>
      {viewportWidth < 768 && (
        <tr className="total-count">
          <td>{artistData.hasOwnProperty('future_all') && artistData.future_all > 0 ? <a href={artistData.url_future_all}>{artistData.future_all}</a> : '-'}</td>
          <td>{artistData.hasOwnProperty('past_all') && artistData.past_all > 0 ? <a href={artistData.url_past_all}>{artistData.past_all}</a> : '-'}</td>
          <td>{artistData.hasOwnProperty('marketplace_all') && artistData.marketplace_all > 0 ? <a href={artistData.url_marketplace_all}>{artistData.marketplace_all}</a> : '-'}</td>
        </tr>
      )}
    </>
  );
};

export default ArtistDatas;
