/* eslint-disable global-require */
module.exports = [
  'marketplace.stores.edit.form.contact.m1',
  'marketplace.stores.edit.form.contact.m2',
  'marketplace.stores.edit.form.contact.m3',
  'marketplace.stores.edit.form.contact.m4',
  'marketplace.stores.edit.form.contact.m5',
  'marketplace.stores.edit.form.contact.m6',
  'marketplace.stores.edit.form.contact.m7',
  'marketplace.stores.edit.form.contact.m8',
  'marketplace.stores.edit.form.contact.m9',
  'marketplace.stores.edit.form.contact.m10',
  'marketplace.stores.edit.form.contact.m11',
  'marketplace.stores.edit.form.contact.m12',
  'marketplace.stores.edit.form.contact.m13',
  'marketplace.stores.edit.m1',
];
