import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_SIGNATURES, ANALYTICS_FROM_ARTIST, IMAGE_SOURCE, MODULE_IMAGE, MODULE_PASTSALE } from '../../../../../constants/rails';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import { getBannerUrlSml, getCode } from '../../../../../redux/slices/promotion';
import { getArtist } from '../../../../../redux/slices/artist';
import { on } from '../../../../../redux/actions/ui/switches';
import usePriceOneDay from '../../common/usePriceOneDay';
import '../../../../common/stylesheets/buttons.scss';
import Drawer from '../../../../common/Drawer';
import A18n from '../../../../common/A18n';
import Lock from '../../common/Lock';
import './stylesheet.scss';

const ID_DRAWER = 'signature-drawer';

const Signature = () => {
  const dispatch = useDispatch();
  const artist = useSelector(getArtist);
  const promocodecode = useSelector(getCode);
  const artpriceBanners = useSelector(getBannerUrlSml);
  const hasImageAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_IMAGE }));
  const hasPastsaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const logged = useSelector(isLogged);
  const priceOneDay = usePriceOneDay();

  if (artist?.signatures?.count === 0) return <div className="artists-artist-header-signatures" />;

  const openDrawer = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_SIGNATURES, idartist: artist.id });
    dispatch(on(ID_DRAWER));
  };

  return (
    <div className="artists-artist-header-signatures">
      {artist.signatures.items[0] && (
        <div className="signature-container" style={{ cursor: 'pointer' }}>
          <a href="#" onClick={openDrawer}>
            <div className="signature" style={{ backgroundImage: `url(${artist.signatures.items[0]})` }} />
          </a>
        </div>
      )}
      <div className="links">
        <div className="sig">
          {(!logged || !hasImageAccess) && (
            <div className="lock">
              <Lock stroke="#000" />
            </div>
          )}
          <div className="od">
            <a href="#" onClick={openDrawer}>
              <A18n rsx="artists.artist.header.m9" />
            </a>
          </div>
        </div>
      </div>

      <Drawer
        side="right"
        id={ID_DRAWER}
        className={classNames({ 'artists-artist-header-signatures-drawer': true, white: artist.signatures.items.length > 0 })}
        render={() => (
          <>
            <p>
              <img alt="drawer-signature" src={`${IMAGE_SOURCE}artist/icon-signature.svg?t=1`} style={{ width: 119 }} />
            </p>

            <h3>
              <A18n rsx={artist.signatures.items.length > 0 ? 'artists.artist.header.signatures.m6' : 'artists.artist.header.signatures.m4'} replace={[['%artist%', artist.name]]} />
            </h3>
            {artist.signatures.items.length > 0 && (
              <div className="signatures">
                {artist.signatures.items.map(signature => (
                  <div className="item" key={signature}>
                    <img alt="Signature" src={signature} />
                  </div>
                ))}
              </div>
            )}
            {artist.signatures.items.length === 0 && (
              <div className="item">
                {!hasImageAccess && hasPastsaleAccess && !priceOneDay && <A18n rsx="artists.artist.header.signatures.m7" trustHtml />}
                {(!hasPastsaleAccess || !logged) && (
                  <>
                    {priceOneDay && <A18n rsx="artists.artist.header.signatures.m1" replace={[['%price-oneday%', priceOneDay]]} trustHtml />}
                    {!priceOneDay && <A18n rsx="artists.artist.header.signatures.m2" trustHtml />}
                  </>
                )}
                {artpriceBanners && (
                  <p className="marg marg-t-30">
                    {/* eslint-disable-next-line react/no-danger */}
                    <a href={`/subscription?promocode=${promocodecode}`}>
                      <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
                    </a>
                  </p>
                )}
                <p className="account-links">
                  <a className="artp-btn artp-btn-purple sln-link" href={`/subscription${!hasImageAccess && hasPastsaleAccess ? `/upgrade` : ''}?idmodule=12${promocodecode ? `&promocode=${promocodecode}` : ''}`}>
                    {!hasImageAccess && hasPastsaleAccess ? <A18n rsx="artists.artist.header.signatures.m5" /> : <A18n rsx="artists.artist.header.signatures.m8" />}
                  </a>
                  {!logged && (
                    <a className="artp-btn artp-btn-artist" href="/identity/signatures" id="sln_login">
                      <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
                    </a>
                  )}
                </p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};
export default Signature;
