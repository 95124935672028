import React from 'react';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Consignment = ({ consignment }) => {
  if (!consignment) return null;

  return (
    <div className="ails-landingpage-consignment">
      <h2>
        <A18n rsx="ails.landingpage.consignment.m1" />
      </h2>
      <div className="consignment">
        <p>{consignment.title}</p>
        <p>
          {consignment.name}, {consignment.country}
        </p>
        <p>
          <A18n rsx="ails.landingpage.consignment.m3" /> {consignment.dt}
        </p>
        {(consignment.url || consignment.email) && (
          <p>
            <a className="btn btn-default" href={consignment.url ? consignment.url : `mailto:${consignment.email}`}>
              <A18n rsx="ails.landingpage.consignment.m2" />
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default Consignment;
