import React, { useState } from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import Header from '../../Header';
import Modal from '../../../../common/ui/Modal';

const Preview = ({ setTemporaryStore }) => {
  const [previewOpened, setPreviewOpened] = useState(false);

  const handlePreview = event => {
    event.preventDefault();
    setPreviewOpened(true);
  };

  return (
    <>
      <Button type="button" onClick={e => handlePreview(e)} className="btn btn-info marg marg-r-15">
        {i18next.t('component.marketplace.stores.edit.preview.m1')}
      </Button>

      <Modal open={previewOpened} onClose={() => setPreviewOpened(false)}>
        <Header store={() => setTemporaryStore()} />
      </Modal>
    </>
  );
};

export default Preview;
