import React, { useRef, useState } from 'react';
import axios from 'axios';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Lot from './Lot';
import Artist from './Artist';
import Auctioneer from './Auctioneer';
import { fetchResults } from '../../../services/search';
import { update } from '../../../redux/actions/dbEntities';
import { TOTAL_ACCESS_ARTISTS, TOTAL_ACCESS_AUCTIONEERS, TOTAL_ACCESS_LOTS } from '../../../constants/search/scope';

import './stylesheet.scss';

import getConfiguredStore from '../../../redux/store';
import { getArtist, getAuctioneer, getLot, getSale } from '../../../redux/selectors/dbEntities';
import { getCurrency } from '../../../redux/selectors/preferences';
import Pagination from '../../common/Pagination';

/* ********** Export functions ********** */
function escape(str) {
  return str && `"${str.replace(/"/g, '""').replace(/\\t/g, ' ')}"`;
}

async function exportToCsv(data, searchScope) {
  const lines = [];
  const state = getConfiguredStore().getState();
  const currency = getCurrency(state);
  switch (searchScope) {
    case TOTAL_ACCESS_LOTS:
      // header
      lines.push([`price (${currency})`, 'title', 'artist', 'auctioneer', 'date', 'city', 'country']);
      // rows
      data.idlot.forEach(idlot => {
        const lot = getLot(state, { id: idlot });
        const sale = getSale(state, { id: lot.idsale });
        const auctioneer = getAuctioneer(state, { id: sale.idauctioneer });
        const artist = getArtist(state, { id: lot.idartist });
        lines.push([Math.round(lot.totalAccessPriceWithTaxes[currency]), escape(lot.title), escape(artist.name), escape(auctioneer?.name), sale.dt, escape(sale?.city), escape(sale?.country)]);
      });
      break;
    default:
      throw new Error(`Unmanaged scope: ${searchScope}`);
  }

  const csv = lines.map(line => line.join('\u0009')).join('\n');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  const reader = new FileReader();

  return new Promise((res, rej) => {
    reader.onerror = rej;
    reader.onloadend = _ => {
      const encodedURI = encodeURI(reader.result);
      const link = document.createElement('a');
      link.setAttribute('href', encodedURI);
      link.setAttribute('download', 'export.csv');
      link.setAttribute('style', 'display: none; visibility: hidden;');
      document.body.appendChild(link);
      link.click();
      res(csv);
    };
    reader.readAsDataURL(blob);
  });
}
/* ********** ********** */

const List = ({ searchScope, params, onPageClick }) => {
  const refForBackButton = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useDeepCompareEffect(() => {
    setLoading(true);
    setData({});
    fetchResults({ ...params, sort: 'price_desc' }, searchScope, true)
      .then(searchData => {
        const { lots, sales, artists, classifieds, stores, auctioneers, categories, countries, techniques, lotartistassociationtypes, ...rest } = searchData.results;
        // update dataStore with entities
        dispatch(update({ lots, sales, artists, classifieds, stores, auctioneers, categories, countries, techniques, lotartistassociationtypes }));
        setData({ ...rest, page: searchData.page, perPage: searchData.perPage, totalPages: searchData.totalPages, totalCount: searchData.totalCount });
        setLoading(false);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          throw new Error(err);
        }
      });
  }, [params, searchScope]);

  return (
    <Row className="total-access-list">
      {searchScope === TOTAL_ACCESS_LOTS && (
        <button type="button" className="export-button" title="Export to csv (tab delimiter)" disabled={loading} onClick={() => exportToCsv(data, searchScope)}>
          <i className="fa fa-table" /> Csv
        </button>
      )}
      <Col sm={12} className="col">
        {/* Data */}
        <div className="data-container" ref={refForBackButton}>
          {loading && (
            <div className="spinner">
              <i className="fa fa-5x fa-spinner fa-spin" />
            </div>
          )}
          {searchScope === TOTAL_ACCESS_LOTS && (
            <div className="data">
              {data?.idlot?.map(idlot => (
                <Lot key={`lot-${idlot}`} id={idlot} />
              ))}
            </div>
          )}
          {searchScope === TOTAL_ACCESS_ARTISTS && (
            <div className="data">
              {data?.values?.map(({ id, value, count, unsold_rate: unsoldRate, top_hit: topHit }) => (
                <Artist id={id} value={value} count={count} idlot={topHit} unsoldRate={unsoldRate} key={`artist-${id}`} />
              ))}
            </div>
          )}
          {searchScope === TOTAL_ACCESS_AUCTIONEERS && (
            <div className="data">
              {data?.values?.map(({ id, value, count, unsold_rate: unsoldRate, top_hit: topHit }) => (
                <Auctioneer id={id} value={value} count={count} idlot={topHit} unsoldRate={unsoldRate} key={`auctioneer-${id}`} />
              ))}
            </div>
          )}
        </div>

        {/* Pagination */}
        {data?.totalPages > 1 && data?.totalCount > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
            <Pagination className="pagination" perPage={data?.perPage} pagePadding={1} loading={loading} totalCount={data?.totalCount} activePage={data?.page} onClick={onPageClick} />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default List;
