import i18next from 'i18next';
import classNames from 'classnames';
import React, { useState } from 'react';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Login = ({ error: _error, mode = 'free', title, login, h2 }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(() => _error);
  let registrationLink = `/account/registration-${mode}`;
  if (mode !== 'free' && mode !== 'full') {
    console.warn(`mode should be free or full, got ${mode}`);
    registrationLink = '/account/registration-free';
  }

  return (
    <div className="account-login-or-subscribe">
      <div className="bg" />
      <div className="content">
        <div className="title">{title || <A18n rsx="account.login.m1" />}</div>
        <h2>{h2 || <A18n rsx="account.login.m2" />}</h2>
        <div className="header">
          <div>
            <A18n rsx="account.login.m3" />
          </div>
          <div>
            <a className="e2e-registration-link" href={registrationLink}>
              <A18n rsx="account.login.m4" />
            </a>
          </div>
        </div>

        <div className="form">
          <form
            action="/login/login"
            method="post"
            onSubmit={e => {
              if (e.target?.elements?.login && e.target.elements.login.value.trim() === '') {
                setError(i18next.t('account.login.m15'));
                e.preventDefault();
                return false;
              }
              if (e.target?.elements?.pass && e.target.elements.pass.value.trim() === '') {
                setError(i18next.t('account.login.m16'));
                e.preventDefault();
                return false;
              }
              return true;
            }}
          >
            <div>
              <input className="e2e-login-input" type="text" name="login" id="login" defaultValue={login} placeholder={i18next.t('account.login.m10')} autoComplete="username" />
            </div>
            <div>
              <input className="e2e-pwd-input" id="pass" type={showPassword ? 'text' : 'password'} name="pass" placeholder={i18next.t('account.login.m11')} autoComplete="current-password" />
              <i className={classNames({ fa: true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword })} onClick={() => setShowPassword(!showPassword)} />
            </div>
            <div className="forgot">
              <a href="/account/forgot_password">
                <A18n rsx="account.login.m5" />
              </a>
            </div>

            {error && (
              <div className="error">
                <i className="fa fa-exclamation-triangle" /> <span dangerouslySetInnerHTML={{ __html: error }} />
              </div>
            )}

            <div className="login-btn">
              <button className="e2e-login-submit-btn" type="submit">
                <A18n rsx="account.login.m7" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
