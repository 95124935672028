import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useIntl } from 'react-intl';
import { ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH } from '../../../../../constants/rails';
import Lock from '../../common/Lock';
import { useViewport } from '../../../../../hooks/useViewport';

const Graph = ({ artist, handleSeeAllClick, displayLock, ca }) => {
  const { viewportWidth } = useViewport();
  const intl = useIntl();
  const tickFormatter = value =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: 'eur',
      minimumFractionDigits: 0,
    });

  return (
    <div className="graphs">
      <div className="ca">
        <a href={`${artist.urlIndexes}?st=chrono`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH })}>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={ca.data} style={{ cursor: 'pointer' }}>
              <XAxis dataKey="label" />
              <YAxis dataKey="value" tick={!displayLock} width={displayLock ? 20 : 100} tickFormatter={tickFormatter} />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Bar barSize={viewportWidth < 1200 && viewportWidth > 992 ? 25 : 50} dataKey="value" fill="#cac6ba" animationDuration={300} animationBegin={35} />
            </BarChart>
          </ResponsiveContainer>
        </a>

        {displayLock && <Lock stroke="#cac6ba" className="lock graph-lock" height={45} width={45} />}
      </div>
    </div>
  );
};

export default Graph;
