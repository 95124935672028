import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import React, { useRef } from 'react';
import GoogleRecaptcha from 'react-google-invisible-recaptcha';
import { exixts as exixtsService } from '../../../../services/wallet/customer';
import ErrorBlock from '../../ErrorBlock';
import '../../stylesheets/buttons.scss';
import '../../stylesheets/form.scss';
import A18n from '../../A18n';

function onFormError(e) {
  Sentry.captureMessage('[form_confirm_identity] Validation failed', {
    extra: {
      errors: e,
    },
  });
}

const IsUserExists = ({ onExists, onNotExists }) => {
  const refRecaptcha = useRef();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const onCaptchaResolved = async () => {
    const values = getValues();
    const captchaResponse = await refRecaptcha.current?.callbacks.getResponse();
    const formData = new FormData();

    formData.append('g-recaptcha-response', captchaResponse);
    formData.append('q', values.email);

    exixtsService(formData).then(exixts => {
      if (!exixts) {
        if (values.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
          onNotExists(values.email);
        } else {
          setError('email', { type: 'custom', message: i18next.t('javascripts.locales.m6') });
          if (process.env.RAILS_ENV === 'production') refRecaptcha.current.callbacks.reset();
        }
      } else {
        onExists(values.email);
      }
    });
  };

  const onSubmit = async () => {
    if (process.env.RAILS_ENV === 'production') {
      refRecaptcha.current.callbacks.execute();
    } else {
      await onCaptchaResolved();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onFormError)}>
      {process.env.RAILS_ENV === 'production' && <GoogleRecaptcha onResolved={() => onCaptchaResolved()} ref={refRecaptcha} sitekey="6LeTtBgUAAAAAGRkfCoKrDm_o5tKEMBnnJ3OAVqK" />}
      <div className="group">
        <div className="cell">
          <div className="input-button">
            <input className="form-control" type="text" id="customer_login" {...register('email', {})} placeholder={i18next.t('common.loginorregistrationmodal.isuserexists.m2')} />
            <button type="submit" id="sln_submit" className="artp-btn artp-btn-block">
              <A18n rsx="common.loginorregistrationmodal.isuserexists.m3" />
            </button>
          </div>
          <ErrorBlock error={errors.email} />
        </div>
      </div>
      <div className="group">
        <div className="cell" />
      </div>
    </form>
  );
};

export default IsUserExists;
