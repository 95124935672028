import React from 'react';
import classNames from 'classnames';
import { ANALYTICS_CLICK_OPERATION_TO_LOT_DETAIL, ANALYTICS_CLICK_OPERATION_TO_LOT_SEARCH, ANALYTICS_FROM_SEARCH_UNIVERSAL, IMAGE_SOURCE } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import SectionHeader from '../SectionHeader';
import Skeleton from '../skeletons/Square';
import A18n from '../../../common/A18n';
import { lotSearchOnKeyword } from '../../../../services/search';
import { useUniversalSearch } from '../hooks/useUniversalSearch';
import useCancellable from '../hooks/useCancellable';

// overlay over actual aapi to set type and from
function aapiWrapper(idlot) {
  aapiBeacon('click', { from: ANALYTICS_FROM_SEARCH_UNIVERSAL, op: ANALYTICS_CLICK_OPERATION_TO_LOT_DETAIL, idlot });
}

const Lots = ({ term }) => {
  const searchLots = useCancellable((v, signal) => lotSearchOnKeyword({ term: v, signal }));
  const { observeCallbackRef, loaded, entities: lots } = useUniversalSearch({ term, searchFunc: searchLots });

  return (
    <div ref={observeCallbackRef}>
      <SectionHeader
        count={lots.totalCount}
        title={<A18n rsx="search.universal.lots.m1" />}
        url={`/lots/search?keyword=${encodeURIComponent(term)}`}
        outOfBounds={lots.outOfBounds}
        op={ANALYTICS_CLICK_OPERATION_TO_LOT_SEARCH}
      />
      {!loaded && <Skeleton />}
      {loaded && lots.totalCount > 0 && (
        <div className="items-container lots">
          <div className={classNames({ items: true, lots: true })}>
            {lots.data.map(lot => (
              <div key={lot.id} className="item" title={lot.title}>
                <div className="r0">
                  <a href={lot.url} onClick={() => aapiWrapper(lot.id)}>
                    {lot.noImage && <div className="no-image" style={{ backgroundImage: `url("${IMAGE_SOURCE}no-access/img-non-dispo.svg")` }} />}

                    {!lot.noImage && (
                      <div
                        className={classNames({ picto: lot.images?.[0], 'fake-picto': !lot.images?.[0] })}
                        style={{ backgroundImage: `url("${lot.images?.[0] || `${IMAGE_SOURCE}artist/lot-bg-${(lot.id % 5) + 1}.png`}")` }}
                      />
                    )}
                  </a>
                </div>
                <div className="r1">
                  <a href={lot.url} onClick={() => aapiWrapper(lot.id)}>
                    {lot.title}
                  </a>
                </div>
                <div className="r2">
                  <a href={lot.url} onClick={() => aapiWrapper(lot.id)}>
                    par {lot.artist}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Lots;
