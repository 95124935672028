import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { areThemesInitialized, areThemesUnavailable, getThemes } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import Warning from './Warning';
import { getActiveSubscription, getIdaccountrole, getLastSubscription } from '../../../../../redux/slices/userContext/selectors';
import { NEW, UPGRADE } from '../../common/constants';
import { isCancelled, needsAction } from '../../../../../utils/subscriptions/attentionNeeded';

const Background = () => <div style={{ position: 'fixed', height: '100vh', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, zIndex: 1 }} />;

const PurchaseNotAvailable = () => {
  const dispatch = useDispatch();
  const accountRole = useSelector(getIdaccountrole);
  const themes = useSelector(getThemes);
  const themesInitialized = useSelector(areThemesInitialized);
  const themesUnavailable = useSelector(areThemesUnavailable);
  const { view, upgradeAvailable, initialQuantity } = useSelector(getSettings);

  const activeSubscription = useSelector(getActiveSubscription);
  const lastSubscription = useSelector(getLastSubscription);

  const [recurrent, setRecurrent] = useState(false);
  const [attentionNeeded, setAttentionNeeded] = useState();
  const [recurrentSubscriptionType, setRecurrentSubscriptionType] = useState();

  useEffect(() => {
    const subscription = activeSubscription || lastSubscription;

    if (subscription) {
      setRecurrent(subscription.recurrent);
      setAttentionNeeded(subscription.attentionNeeded);
      setRecurrentSubscriptionType(subscription.recurrentSubscriptionType);
    }
  }, [activeSubscription, lastSubscription]);

  const handleRecurrentStripeSubscription = () => {
    window.location = '/account/situation';
  };

  const handleRecurrentPaypalSubscription = () => {
    window.location = '/contact/thanks/345';
  };

  const handleBackToList = () => {
    dispatch(updateSettings({ data: { view: NEW, quantity: initialQuantity } }));
  };

  const handleSubscriptionsUnavailable = () => {
    window.location = '/account/clientmessages?sub_tab=new';
  };

  if (parseInt(accountRole, 10) === 2) {
    return (
      <>
        <Background />
        <Warning
          absolute={false}
          buttonAction={handleBackToList}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m2')}
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m9')}
        />
      </>
    );
  }

  // Vue upgrade mais pas d'upgrade possible
  if (view === UPGRADE && !upgradeAvailable) {
    return (
      <>
        <Background />
        <Warning
          absolute={false}
          displayContact
          buttonAction={handleBackToList}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m2')}
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m1')}
        />
      </>
    );
  }

  // Vue upgrade avec des upgrade possible mais pas dans ces conditions de settings (users le plus souvent)
  if (view === UPGRADE && upgradeAvailable && themesInitialized && !themes.upgrade && themes.new) {
    return (
      <>
        <Background />
        <Warning
          absolute={false}
          displayContact
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m1')}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m2')}
          buttonAction={handleBackToList}
        />
      </>
    );
  }

  // Pas d'abo remonté meme en renew
  if (themesUnavailable) {
    return (
      <>
        <Background />
        <Warning
          absolute={false}
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m4')}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m3')}
          buttonAction={handleSubscriptionsUnavailable}
        />
      </>
    );
  }

  // L'abo en cours doit etre updaté
  if (recurrent && needsAction(attentionNeeded)) {
    if (recurrentSubscriptionType === 'stripe') {
      return (
        <>
          <Background />
          <Warning
            label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m7')}
            buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m8')}
            buttonAction={handleRecurrentStripeSubscription}
          />
        </>
      );
    }

    return (
      <>
        <Background />
        <Warning
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m5')}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m6')}
          buttonAction={handleRecurrentPaypalSubscription}
        />
      </>
    );
  }

  // L'abo en cours est un mensuel
  if (recurrent && !isCancelled(attentionNeeded)) {
    if (recurrentSubscriptionType === 'stripe') {
      return (
        <>
          <Background />
          <Warning
            label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m7')}
            buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m8')}
            buttonAction={handleRecurrentStripeSubscription}
          />
        </>
      );
    }

    return (
      <>
        <Background />
        <Warning
          label={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m5')}
          buttonText={i18next.t('subscriptions.purchase.warnings.purchase-not-available.m6')}
          buttonAction={handleRecurrentPaypalSubscription}
        />
      </>
    );
  }

  return null;
};

export default PurchaseNotAvailable;
