import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';

const i18n = { stats: 'component.store.stats.index.m1', edit: 'marketplace.stores.edit.form.m1' };

const SectionTitle = ({ section }) => (
  <div className={`marketplace-stores-common-section-title ${section}`}>
    <div className="left" />
    <div className="right" />
    <h1>{i18next.t(i18n[section])}</h1>
  </div>
);

export default SectionTitle;
