import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentQueryObject, queryStringToObject, updateUrlQuery } from '../../utils/http';
import { fetchResults } from '../../redux/actions/search/results';
import { initializeContext } from '../../redux/actions/search';
import { usePrevious } from '../usePrevious';
import { getItemsPerpage } from '../../redux/selectors/preferences';

const defaultOptions = {
  cancelPrevious: false,
};

const parseOptions = options => {
  const result = {
    ...defaultOptions,
    ...options,
  };

  if (!result.params) {
    result.params = getCurrentQueryObject();
  }

  return result;
};

export function usePaginatedSearch({ scope, options = defaultOptions }) {
  const { cancelPrevious, params } = parseOptions(options);
  const dispatch = useDispatch();
  const [page, setStatePage] = useState(parseInt(params.p, 10) || 1);
  const perPage = useSelector(getItemsPerpage);
  const previousPerPage = usePrevious(perPage);
  const fetch = ({ forceUpdate = false, updateFacets = false, updateDefaultFacets = false }) => {
    dispatch(fetchResults({ params: { ...params, p: page, ipp: perPage }, searchScope: scope, updateDefaultFacets, updateFacets, cancelPrevious, flush: page === 1, forceUpdate }));
  };

  const setPage = useCallback(
    p => {
      updateUrlQuery({ ...queryStringToObject(window.location.search), ...{ p } });
      setStatePage(parseInt(p, 10));
    },
    [setStatePage],
  );

  useEffect(() => {
    dispatch(
      initializeContext({
        params,
        searchScope: scope,
        infiniteScroll: false,
      }),
    );
  }, [scope]);

  useEffect(() => {
    if (perPage) {
      if (previousPerPage !== perPage && previousPerPage > 1) {
        setPage(1);
      } else {
        fetch({ updateFacets: page === 1 });
      }
    }
  }, [scope, perPage, page, params]);

  // set page from params (or begin with 1)
  useEffect(() => {
    setPage(params.p || 1);
  }, [scope, params]);

  return { page, setPage, fetch };
}
