import { useDispatch, useSelector } from 'react-redux';
import { difference } from 'lodash';
import { addMonths } from 'date-fns';
import { getIdcustomer, getLastSubscription } from '../../../../redux/slices/userContext/selectors';
import { updateSettings } from '../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';

export default function useHasWarnings({ theme, quantity }) {
  const lastSubscription = useSelector(getLastSubscription) || {};
  const idcustomer = useSelector(getIdcustomer);
  const { view } = useSelector(getSettings);
  const { modules = [], dates, usersCount = 0 } = lastSubscription;
  const dispatch = useDispatch();

  return () => {
    if (!idcustomer) return false;

    const missingModules = difference(modules || [], theme?.module || []);
    const addedModules = difference(theme?.module || [], modules || []);

    // Test du cas du downgrade & display modale
    // il faut que la le dernier abo se soit terminer il y a plus de deux mois
    if ((missingModules.length > 0 || usersCount > quantity) && new Date(dates?.dtExpire) > addMonths(new Date(), -12)) {
      dispatch(updateSettings({ data: { displayWarningsModale: true, missingModules, warningForRenewInPlaceOfUpgrade: false } }));
      return true;
    }
    // Test si en renew le client prend un abo supérieur (durée module quantity)
    if (view !== 'upgrade' && new Date(lastSubscription?.dates?.dtExpire) > addMonths(new Date(), 2) && (quantity > usersCount || addedModules.length > 0)) {
      dispatch(updateSettings({ data: { displayWarningsModale: true, missingModules: [], warningForRenewInPlaceOfUpgrade: true } }));
      return true;
    }

    return false;
  };
}
