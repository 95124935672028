import React from 'react';
import i18next from 'i18next';
import './stylesheet.scss';

const UsersCount = ({ idtheme = 0, usersCount, style = {} }) => {
  if (usersCount === 0) return null;

  return (
    <div
      style={style}
      className={`subscriptions-purchase-common-users-count lvl-${idtheme}`}
      title={`${usersCount} ${usersCount === 1 ? i18next.t('subscriptions.purchase.common.userscount.m1') : i18next.t('subscriptions.purchase.common.userscount.m2')}`}
    >
      <i className="fa fa-user-circle" />
      <span className="quantity">{usersCount}</span>
    </div>
  );
};

export default UsersCount;
