import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, shuffle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AILTYPE_LOT, ANALYTICS_SECTION_ARTIST_HEADER, ANALYTICS_FROM_ARTIST } from '../../../../../constants/rails';
import { getAils, getAilsSeen, getArtist, updateAilsSeen } from '../../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ailIsMultiDates } from '../../../../../utils/ail';
import Carousel from '../../../../common/Carousel';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const MainIllustration = ({ className }) => {
  const ailsFromRedux = useSelector(getAils);
  const artist = useSelector(getArtist);
  const dispatch = useDispatch();
  const ailsSeen = useSelector(getAilsSeen);
  const [ails, setAils] = useState();

  useEffect(() => {
    if (!isEmpty(get(ailsFromRedux, AILTYPE_LOT))) {
      setAils(get(ailsFromRedux, AILTYPE_LOT));
    }
  }, [ailsFromRedux]);

  if (ails) {
    const afterChange = slick => {
      if (!ailsSeen.includes(ails[slick].id)) {
        dispatch(updateAilsSeen([ails[slick].id]));
        aapiBeacon('ail_view', { idail: ails[slick].id, from: ANALYTICS_FROM_ARTIST, idartist: artist.id });
      }
    };

    const settings = {
      dots: false,
      fade: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
      afterChange,
    };

    return (
      <div className={`main-illustration ${className} ${ails.length > 1 ? 'multiple' : ''}`}>
        <Carousel
          settings={settings}
          slides={shuffle(ails).map(ail => (
            <div className="item ail" key={`main-illustration-${ail.id}`}>
              <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_ARTIST}&s=${ANALYTICS_SECTION_ARTIST_HEADER}`}>
                <h3>
                  <A18n rsx="artists.artist.header.mainillustration.m1" />
                </h3>
                <div className="repro ail">
                  <img src={ail.images.square} alt={`${artist.name} - ${ail.title}`} title={`${artist.name} - ${ail.title}`} />
                </div>
                <div className="legend">
                  <div className="info">
                    <div className={classNames('lot-title', { short: ailIsMultiDates(ail) })} title={ail.title}>
                      {ail.title}
                    </div>
                    <div className="auctioneer">{ail.auctioneer}</div>
                  </div>
                  <div className={classNames('block-date-ail', { 'two-part': ailIsMultiDates(ail) })}>
                    <div className="subblock-date-ail">
                      <span className="day">{ail.dt_start_part1}</span>
                      <span className="other">{ail.dt_start_part2}</span>
                    </div>
                    {ailIsMultiDates(ail) && (
                      <div className="subblock-date-ail">
                        <div className="separator" />
                        <div className="day">{ail.dt_end_part1}</div>
                        <div className="other">{ail.dt_end_part2}</div>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </div>
          ))}
        />
      </div>
    );
  }

  if (artist.imgBl) return null;

  if (artist.headerImgUrl) {
    return (
      <div className={`main-illustration ${className}`}>
        <div className="item">
          <div className="repro">
            <img src={artist.headerImgUrl} alt={`Main illustration for ${artist.name}`} title={`${artist.name}`} />
          </div>
          <div className="title">{artist.headerImgTitle}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default MainIllustration;
