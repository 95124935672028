import React from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_PRICING_UPGRADE, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_PAST_LOTS, ANALYTICS_SECTION_INDEXES_CARD, IMAGE_SOURCE } from '../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import DOMView from '../../../../../common/observers/DOMView';
import A18n from '../../../../../common/A18n';

const NoIndexAccessCard = ({ artist }) => (
  <div className="lot fake">
    <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_INDEXES_CARD} selector="#indexes-card" idartist={artist.id} />
    <img id="indexes-card" src={`${IMAGE_SOURCE}artist/indexes.png`} alt="indexes" />
    <div className="fake-container">
      <p className="title">
        <A18n rsx="artists.artist.lots.past.m7" trustHtml />
      </p>
      <p className="content">
        {artist.index && <A18n rsx="artists.artist.lots.past.m8" trustHtml />}
        {!artist.index && <A18n rsx="artists.artist.lots.past.m10" trustHtml />}
      </p>
      <a
        className="artp-btn artp-btn-artist"
        onClick={() =>
          aapiBeacon('click', {
            from: ANALYTICS_FROM_ARTIST,
            op: ANALYTICS_CLICK_OPERATION_TO_PRICING_UPGRADE,
            section: ANALYTICS_SECTION_ARTIST_PAST_LOTS,
            idartist: artist.id,
          })
        }
        href="/subscription?upgrade=1"
      >
        <A18n rsx="artists.artist.lots.past.m9" />
      </a>
    </div>
  </div>
);

export default NoIndexAccessCard;
