/* eslint-disable */

import React, { useEffect } from 'react';
import i18next from 'i18next';
import { internalAxios } from '../../../../utils/axios/internal';

const Button = ({ stripIdcustomer }) => {
  const createPortalSession = async () => {
    const { data } = await internalAxios.post('/api/account/stripe_customer_portal/create_portal_session', { id: stripIdcustomer });
    window.location = data.url;
  };

  return (
    <button className={'btn btn-primary pull-right'} id="checkout" onClick={createPortalSession}>
      {i18next.t('account.stripe.customer.protal.button.m1')}
    </button>
  );
};

export default Button;
