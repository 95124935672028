/* eslint-disable global-require */
module.exports = [
  ...require('./Fees/i18n'),
  'marketplace.howtobid.faq.m1',
  'marketplace.howtobid.faq.m2',
  'marketplace.howtobid.faq.m3',
  'marketplace.howtobid.faq.m4',
  'marketplace.howtobid.faq.m5',
  'marketplace.howtobid.faq.m6',
  'marketplace.howtobid.faq.m7',
  'marketplace.howtobid.faq.m8',
  'routes.marketplace_howto',
];
