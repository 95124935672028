import React from 'react';
import { useSelector } from 'react-redux';
import { areThemesUnavailable } from '../../../../redux/selectors/subscriptions/physicproducts';
import Registration from './Registration';
import Others from './Others';
import { getIdcustomer } from '../../../../redux/slices/userContext/selectors';

import './stylesheet.scss';

const FreeServices = () => {
  const subscriptionsUnavailable = useSelector(areThemesUnavailable);
  const idcustomer = useSelector(getIdcustomer);
  return (
    <div className={`subscriptions-free-services ${subscriptionsUnavailable ? 'no-data' : ''}`}>
      {!subscriptionsUnavailable && idcustomer && <Registration />}
      <Others />
    </div>
  );
};

export default FreeServices;
