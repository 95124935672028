import React from 'react';
import { IntlProvider } from 'react-intl';
import Info from '..';

const WithProvider = ({ lang }) => (
  <IntlProvider locale={lang} suppressHydrationWarning>
    <Info lang={lang} />
  </IntlProvider>
);

export default WithProvider;
