import React from 'react';

import { useSelector } from 'react-redux';
import { getExpiredCount } from '../../../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_OFFLINE } from '../../../../../../../../../constants/rails';
import { a18n } from '../../../../../../../../../utils/a18n';

const ExpiredClassifieds = () => {
  const expiredCount = useSelector(getExpiredCount);

  if (expiredCount > 1) {
    return (
      <Notification
        element={<A18n rsx="component.header.notifications.marketplace.m6" replace={[['%count%', expiredCount]]} />}
        link={`/${a18n('routes.marketplace_manage')}/offline`}
        onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_OFFLINE })}
      />
    );
  }
  return (
    <Notification
      element={<A18n rsx="component.header.notifications.marketplace.m5" />}
      link={`/${a18n('routes.marketplace_manage')}/offline`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_OFFLINE })}
    />
  );
};

export default ExpiredClassifieds;
