import React from 'react';
import { func, number } from 'prop-types';

export const capitalize = label => label && `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const CountriesDropdown = ({ pagesCount, choose, currentPage }) => {
  if (!pagesCount || !choose || !currentPage || pagesCount <= 1) return null;

  return (
    <div className="pagination-filter artp-pagination">
      <ul style={{ display: 'block' }}>
        {currentPage > 1 && (
          <li className="page prev">
            {/* eslint-disable-next-line */}
            <a onClick={() => choose(currentPage - 1)}>{'<'}</a>
          </li>
        )}

        {Array(pagesCount)
          .fill(1)
          .map((p, index) => (
            <li key={`page#${index + 1}`} className={`page${currentPage === index + 1 ? ' active' : ''}`}>
              {/* eslint-disable-next-line */}
              <a onClick={() => choose(index + 1)}>{index + 1}</a>
            </li>
          ))}

        {currentPage < pagesCount && (
          <li className="page next">
            {/* eslint-disable-next-line */}
            <a onClick={() => choose(currentPage + 1)}>{'>'}</a>
          </li>
        )}
      </ul>
    </div>
  );
};

CountriesDropdown.propTypes = {
  pagesCount: number.isRequired,
  currentPage: number.isRequired,
  choose: func.isRequired,
};

export default CountriesDropdown;
