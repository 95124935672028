import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { PieChart, Pie, Cell } from 'recharts';
import Spinner from '../../../../../common/spinners/Spinner';
import { getInquiriesStats } from '../../../../../../services/stats/marketplace';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';

import './stylesheet.scss';
import { humanizeDurationFns } from '../../../../../../utils/dates/format';

const Inquiries = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();
  useEffect(() => {
    setLoading(true);
    getInquiriesStats().then(inquiries => {
      setStats(inquiries);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }
  if (!stats) {
    return null;
  }

  const { count, delay, rate } = stats;
  if (count < 1) {
    return null;
  }

  return (
    <div className="Inquiries">
      <PieChart width={160} height={160} className="pull-left">
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="var(--color2)" stopOpacity={1} />
            <stop offset="95%" stopColor="var(--color1)" stopOpacity={1} />
          </linearGradient>
        </defs>
        <text x={80} y={80} textAnchor="middle" dominantBaseline="middle">
          <tspan className="Inquiries-text" x={80} dy="-0.6em">
            {i18next.t('component.store.stats.inquiries.m1')}
          </tspan>
          <tspan className="Inquiries-value" x={80} dy="1.2em">
            {rate && (rate * 100.0).toFixed(2)}%
          </tspan>
        </text>
        <Pie data={[{ value: 1 }]} dataKey="value" cx={75} cy={75} startAngle={90} endAngle={90 - rate * 360} innerRadius={62} outerRadius={76} paddingAngle={5}>
          <Cell fill="url(#color)" />
        </Pie>
      </PieChart>
      <div className="Inquiries-delay">
        <div>
          <img className="Inquiries-delay-picto" alt="" src={`${IMAGE_SOURCE}marketplace/picto-sablier.svg`} />
        </div>
        <div>
          <div className="Inquiries-text">{i18next.t('component.store.stats.inquiries.m2')}</div>
          <div className="Inquiries-value">{humanizeDurationFns(delay)}</div>
        </div>
      </div>
    </div>
  );
};

export default Inquiries;
