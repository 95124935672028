import React from 'react';
import i18next from 'i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updatePreferences } from '../../../../redux/actions/myartprice/settings';
import { getSettings } from '../../../../redux/selectors/myartprice';
import { SHORTCUT_SETTINGS_SECTIONS } from '../../../../constants/myartprice';
import Checkbox from '../../../common/ui/Checkbox';
import '../../common/styles/myartpriceSettings.scss';
import './stylesheet.scss';

const Sections = () => {
  const dispatch = useDispatch();
  const { ended, futures, auctions, fixedPrice } = useSelector(getSettings, shallowEqual) || {};

  const handleSwitch = (section, v) => {
    dispatch(updatePreferences(SHORTCUT_SETTINGS_SECTIONS, v, { section }));
  };

  return (
    <div className="myartprice-settings myartprice-settings-sections myartprice-flipswitches col-xs-12">
      <div className="info">{i18next.t('components.myartprice.settings.sections.m3')}</div>
      <div className="section">
        <Checkbox light label={i18next.t('components.myartprice.common.verticalbar.content.m3')} name="fixedPrice" checked={futures} onChange={newParam => handleSwitch('futures', newParam)} />
      </div>
      <div className="section">
        <Checkbox light name="auction" label={i18next.t('components.myartprice.common.verticalbar.content.m4')} checked={ended} onChange={newParam => handleSwitch('ended', newParam)} />
      </div>
      <div className="section">
        <Checkbox light name="auction" label={i18next.t('components.myartprice.settings.sections.m8')} checked={auctions} onChange={newParam => handleSwitch('auctions', newParam)} />
      </div>
      <div className="section">
        <Checkbox light label={i18next.t('components.myartprice.settings.sections.m10')} name="fixedPrice" checked={fixedPrice} onChange={newParam => handleSwitch('fixedPrice', newParam)} />
      </div>
    </div>
  );
};

export default Sections;
