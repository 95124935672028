import { useSelector } from 'react-redux';
import { getActiveSubscription } from '../../redux/slices/userContext/selectors';
import { MODULE_EXPORT_PDF } from '../../constants/rails';

export const GRANTED = 'GRANTED';
export const FORBIDDEN = 'FORBIDDEN';
export const HIDDEN = 'HIDDEN';

export function useLotslistsAccess() {
  const activeSubscription = useSelector(getActiveSubscription);

  if (activeSubscription?.modules.includes(MODULE_EXPORT_PDF)) {
    return GRANTED;
  }
  return FORBIDDEN;
}
