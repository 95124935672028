import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import StripeCustomerPortal from '..';

const ProvidedStripeCustomerPortal = ({ locale }) => (
  <ReduxProvider>
    <StripeCustomerPortal locale={locale} />
  </ReduxProvider>
);

export default ProvidedStripeCustomerPortal;
