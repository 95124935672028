import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import classNames from 'classnames';
import CommonPhoneInput from '../../../common/ui/PhoneInput';
import ErrorBlock from '../../../common/ErrorBlock';
import A18n from '../../../common/A18n';

const PhoneInput = ({ control, errors, requiredValue, countryIso2 }) => (
  <FormGroup row>
    <Label for="phone" sm={4} className={classNames({ required: requiredValue })}>
      <A18n rsx="marketplace.reporting.phoneinput.m1" />
    </Label>
    <Col sm={5}>
      <CommonPhoneInput
        control={control}
        country={countryIso2}
        name="phone"
        rules={{
          required: {
            value: requiredValue,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
        }}
      />
      <ErrorBlock error={errors && errors.phone} />
    </Col>
  </FormGroup>
);

export default PhoneInput;
