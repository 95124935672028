/* eslint-disable global-require */
module.exports = [
  'account.signinconfirmation.m1',
  'account.signinconfirmation.m2',
  'account.signinconfirmation.m3',
  'account.signinconfirmation.m4',
  'account.signinconfirmation.m5',
  ...require('./FreeDemo/i18n'),
  ...require('./Marketplace/i18n'),
  ...require('./Reports/i18n'),
];
