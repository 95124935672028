/* eslint-disable global-require */
module.exports = [
  ...require('./LineChart/i18n'),
  'indexes._first_chart.m1',
  'indexes._first_chart.m2',
  'indexes._first_chart.m3',
  'indexes._first_chart.m4',
  'indexes._first_chart.m5',
  'indexes._first_chart.m6',
  'indexes._first_chart.m7',
  'indexes._first_chart.m8',
  'indexes._first_chart.m9',
];
