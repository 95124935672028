import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchCountry } from '../../../../redux/actions/dbEntities/country';
import { getCountry } from '../../../../redux/selectors/dbEntities';

const Country = ({ id }) => {
  const dispatch = useDispatch();
  const country = useSelector(state => getCountry(state, { id, lang: i18next.language }), shallowEqual);

  useEffect(() => {
    if (!country) {
      dispatch(fetchCountry({ id }));
    }
  }, []);

  if (!country) return null;

  return country.label;
};

export default Country;
