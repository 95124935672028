/* eslint-disable global-require */
module.exports = [
  'components.account.verify.identity.pro.m1',
  'components.account.verify.identity.pro.m2',
  'components.account.verify.identity.pro.m3',
  'components.account.verify.identity.pro.m4',
  'components.account.verify.identity.pro.m5',
  'components.account.verify.identity.pro.m6',
  'components.account.verify.identity.pro.m7',
  'components.account.verify.identity.pro.m8',
  'components.account.verify.identity.pro.m9',
  'components.account.verify.identity.personal.m14',
  'components.account.verify.identity.personal.m15',
  'components.account.verify.identity.personal.m17',
  'account.registration.m1',
];
