import React from 'react';
import './stylesheet.scss';
import { IntlProvider } from 'react-intl';
import TransactionProcess from './TransactionProcess';
import AdvantagesArtprice from './AdvantagesArtprice';
import AdvantagesEscrow from './AdvantagesEscrow';
import AuctionProcess from './AuctionProcess';
import EscrowChecks from './EscrowChecks';
import Protection from './Protection';
import Header from './Header';
import Faq from './Faq';

const HowToBid = ({ lang = 'en' }) => (
  <IntlProvider locale={lang}>
    <div className="marketplace-how-to-bid">
      <div className="background" />

      <div className="container">
        <Header />
        <AuctionProcess />
        <TransactionProcess />
        <Protection />
        <AdvantagesArtprice />
        <AdvantagesEscrow />
        <EscrowChecks />
        <Faq lang={lang} />
      </div>
    </div>
  </IntlProvider>
);

export default HowToBid;
