import React from 'react';
import { isEqual } from 'lodash';
import useGtm from '../../../hooks/useGtm';
import { EMPTY_OBJECT } from '../../../constants/utils';

const optionsEquality = (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options);

const Gtm = React.memo(({ options = EMPTY_OBJECT }) => {
  useGtm(options);
  return null;
}, optionsEquality);

export default Gtm;
