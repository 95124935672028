import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import ErrorBlock from '../../../common/ErrorBlock';
import DateInput from '../../../common/inputs/DateInput';
import A18n from '../../../common/A18n';

const Date = ({ control, errors }) => (
  <FormGroup row>
    <Label for="dt" sm={4} className="required">
      <A18n rsx="marketplace.reporting.date.m1" />
    </Label>
    <Col sm={5}>
      <DateInput
        name="dt"
        control={control}
        rules={{
          required: <A18n rsx="marketplace.reporting.m27" />,
        }}
      />
      <ErrorBlock error={errors.dt} />
    </Col>
  </FormGroup>
);

export default Date;
