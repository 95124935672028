import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, getCartLoading, getParams, updateParams } from '../../../../../redux/slices/payments';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Promocode = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const loading = useSelector(getCartLoading);
  const { promocode } = useSelector(getParams);
  const { promotion_from_promocode: promotionFromPromocode } = useSelector(getCart);

  const { register, handleSubmit } = useForm({
    defaultValues: { promocode },
  });

  const clear = () => {
    setSearchParams(s => {
      s.set('promocode', null);
      return s;
    });

    dispatch(updateParams({ promocode: '' }));
  };

  const onSubmit = values => {
    // update the search query param to trigger the update of the promocode param in the outlet context
    setSearchParams(s => {
      s.set('promocode', values.promocode);
      return s;
    });

    dispatch(updateParams({ promocode: values.promocode }));
  };

  return (
    <div className="payments-common-cart-summary-promocode common-stylesheets-forms">
      <h4>
        <A18n rsx="payments.common.cartsummary.promocode.m7" />
      </h4>
      <form>
        <div className="group">
          <div className="cell">
            <div className="input-button">
              <input type="text" id="sln_promocode" disabled={loading} {...register('promocode')} />

              {promocode && (
                <button type="button" onClick={clear}>
                  <i className="fa fa-trash-o" />
                </button>
              )}
              <button type="button" onClick={handleSubmit(onSubmit)} id="sln_promocode_btn">
                <A18n rsx="payments.common.cartsummary.promocode.m1" />
              </button>
            </div>
          </div>
        </div>
        {promotionFromPromocode?.status && promotionFromPromocode.status > 1 && (
          <div>
            ⚠ {promotionFromPromocode.status === 4 && <A18n rsx="payments.common.cartsummary.promocode.m2" />}
            {promotionFromPromocode.status === 5 && <A18n rsx="payments.common.cartsummary.promocode.m3" />}
            {promotionFromPromocode.status === 2 && <A18n rsx="payments.common.cartsummary.promocode.m4" />}
            {promotionFromPromocode.status === 3 && <A18n rsx="payments.common.cartsummary.promocode.m5" />}
            {promotionFromPromocode.status === 6 && <A18n rsx="payments.common.cartsummary.promocode.m6" />}
          </div>
        )}
      </form>
    </div>
  );
};

export default Promocode;
