import { FormattedNumber } from 'react-intl';
import React from 'react';
import { get } from 'lodash';

const CartLine = ({ line, currency }) => {
  const { product, component_products: componentProducts = [], product_quantity: productQuantity = 0 } = line;
  const total = get(product, `prices.${currency}`, 0) * productQuantity;

  return (
    <div className="row cart">
      <ul>
        <li className="first-line">
          {productQuantity > 1 && <span className="quantity">{productQuantity}×</span>}
          <span>{product.title}</span>
          <span className="flex-1" />
          {total !== 0.0 && (
            <span>
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={total} style="currency" currency={currency} />
            </span>
          )}
        </li>

        {componentProducts
          .filter(({ title }) => !title.includes(product.title))
          .map(componentProduct => (
            <li key={componentProduct.id} className="component-product">
              • {componentProduct.title}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CartLine;
