import React from 'react';
import i18next from 'i18next';
import { ResponsiveContainer } from 'recharts';
import graphStyle from './graphStyle';
import Prices from './Prices';
import Countries from './Countries';
import Unsold from './Unsold';
import { EMPTY_ARRAY } from '../../../../constants/utils';

const ResponsivenessManager = ({ responsive, children }) => {
  const child = React.Children.only(children);

  if (responsive) {
    return <ResponsiveContainer>{child}</ResponsiveContainer>;
  }
  return React.cloneElement(child, { width: 800, height: 400 }, null);
};

const Graphs = ({ idestimate, chartPricesDatas = EMPTY_ARRAY, chartCountriesDatas = EMPTY_ARRAY, chartUnsoldDatas = EMPTY_ARRAY, pdf = true, responsive = true }) => (
  <div className="row">
    <div className="col-md-12" style={graphStyle.graph}>
      <div style={graphStyle.graphsColumnsTitle}>{i18next.t('estimate.show.m12')}</div>
      <ResponsivenessManager responsive={responsive}>
        <Prices idestimate={idestimate} datas={chartPricesDatas} width="100%" />
      </ResponsivenessManager>
    </div>
    <div className="col-lg-6 col-md-12" style={graphStyle.graph}>
      <div style={graphStyle.graphsTitle}>{i18next.t('estimate.show.m13')}</div>
      <ResponsivenessManager responsive={responsive}>
        <Countries idestimate={idestimate} datas={chartCountriesDatas} width="100%" pdf={pdf} />
      </ResponsivenessManager>
    </div>
    <div className="col-lg-6 col-md-12" style={graphStyle.graph}>
      <div style={graphStyle.graphsTitle}>{i18next.t('indexes._second_chart.m6')}</div>
      <ResponsivenessManager responsive={responsive}>
        <Unsold idestimate={idestimate} datas={chartUnsoldDatas} width="100%" pdf={pdf} />
      </ResponsivenessManager>
    </div>
  </div>
);

export default Graphs;
