/* eslint-disable global-require */
module.exports = [
  'auctioneers.agreement.contactmodale.m1',
  'auctioneers.agreement.contactmodale.m2',
  'auctioneers.agreement.contactmodale.m3',
  'auctioneers.agreement.contactmodale.m4',
  'auctioneers.agreement.contactmodale.m5',
  'auctioneers.agreement.contactmodale.m6',
  'auctioneers.agreement.contactmodale.m7',
  'auctioneers.agreement.contactmodale.m8',
  'auctioneers.agreement.contactmodale.m9',
];
