import React from 'react';

const PagesSequence = ({ iterations, initialCount, onClick, className, label, style }) =>
  Array.from({ length: iterations }).map((_, i) => (
    <div style={style} className={`artp-ui-pagination-cell ${className || ''}`} key={`page${initialCount + i}`} onClick={() => onClick(initialCount + i)}>
      {label || initialCount + i}
    </div>
  ));

export default PagesSequence;
