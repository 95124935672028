import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Informations from './Informations';
import Contact from './Contact';
import Artists from './Artists';
import Images from './Images';
import { get } from '../../../../redux/actions/marketplace/stores';
import { getStore, getStoreLoading } from '../../../../redux/selectors/marketplace/stores';
import SectionTitle from '../common/SectionTitle';
import StoreManagmentMenu from '../../common/ManagmentMenu';
import NoAccess from '../common/NoAccess';
import Spinner from '../../../common/spinners/Spinner';

import './stylesheet.scss';

const StoreForm = ({ idstore, storeManagmentMenuActiveTab = 1 }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const tabLabels = [i18next.t('marketplace.stores.edit.form.m3'), i18next.t('marketplace.stores.edit.form.m4'), i18next.t('marketplace.stores.edit.form.m5'), i18next.t('marketplace.stores.edit.form.m6')];
  const store = useSelector(state => getStore(state, { idstore }));
  const loading = useSelector(state => getStoreLoading(state, { idstore }));

  useEffect(() => {
    dispatch(get(idstore));
  }, []);

  return (
    <div className="marketplace-store-edit-form">
      <StoreManagmentMenu activeTab={storeManagmentMenuActiveTab} />
      <SectionTitle section="edit" />
      {loading && <Spinner className="spinner" />}
      {!loading && isEmpty(store) && (
        <div className="container marg marg-t-30">
          <NoAccess />
        </div>
      )}
      {!loading && !isEmpty(store) && (
        <>
          <div className="tabs">
            <div className="tab" />
            {tabLabels.map((label, index) => (
              <div key={label} role="presentation" className={activeTab === index ? 'tab active' : 'tab'} onClick={() => setActiveTab(index)}>
                {label}
              </div>
            ))}
            <div className="tab" />
          </div>
          <div className="container">
            <div className="content">
              {activeTab === 0 && <Informations store={store} />}
              {activeTab === 1 && <Contact store={store} />}
              {activeTab === 2 && <Artists idstore={idstore} />}
              {activeTab === 3 && <Images idstore={idstore} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StoreForm;
