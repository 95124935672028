/* eslint-disable no-param-reassign */

import React, { useEffect, useState, useRef } from 'react';

export function useHorizontalScroll({ ref }) {
  const divWidthRef = useRef(0);
  const divTotalWidthRef = useRef(0);
  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);
  const [displayNav, setDisplayNav] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // useEffect for Resize
  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        const [{ contentRect, target }] = entries;
        const style = getComputedStyle(ref.current);
        const columGap = style.getPropertyValue('column-gap');

        let gap = 0;
        if (columGap) gap = parseInt(style.getPropertyValue('column-gap').replace('px'), 10) * (target.children.length - 1);
        const totalWidth = Array.from(target.children).reduce((w, e) => e.clientWidth + w, 0) + gap;

        setPages(parseInt(totalWidth / contentRect.width + 1, 10));
        setDisplayNav(contentRect.width < totalWidth);
        divWidthRef.current = contentRect.width;
        divTotalWidthRef.current = totalWidth;
        setDisplayNav(totalWidth > contentRect.width);
      });
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  // useEffect for Scroll
  useEffect(() => {
    if (!ref.current) return;

    const handleScrollEvent = () => {
      if (ref.current.scrollLeft + divWidthRef.current < divTotalWidthRef.current) {
        setRightDisabled(false);
      } else {
        setRightDisabled(true);
      }

      if (ref.current.scrollLeft > 0) {
        setLeftDisabled(false);
      } else {
        setLeftDisabled(true);
      }
    };

    ref.current.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [ref.current]);

  const goTo = page => {
    setCurrentPage(page);
    ref.current.scrollLeft = divWidthRef.current * (page - 1);
  };

  const handleScrollClick = direction => {
    if (direction === 'right') {
      if (ref.current.scrollLeft + divWidthRef.current < divTotalWidthRef.current) {
        setCurrentPage(currentPage + 1);
        ref.current.scrollLeft += divWidthRef.current;
      }
    } else if (ref.current.scrollLeft > 0) {
      setCurrentPage(currentPage - 1);
      ref.current.scrollLeft -= divWidthRef.current;
    }
  };

  return { leftDisabled, rightDisabled, displayNav, handleScrollClick, pages, currentPage, goTo };
}
