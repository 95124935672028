/* eslint-disable global-require */
module.exports = [
  ...require('./Pie/i18n'),
  ...require('./List/i18n'),
  ...require('./BestClassifieds/i18n'),
  ...require('./CustomToolTip/i18n'),
  ...require('./PeriodSelector/i18n'),
  ...require('./YourStats/i18n'),
  ...require('./Views/i18n'),
  'component.store.stats.index.m1',
  'component.store.stats.index.m2',
  'component.store.stats.index.m3',
  'component.store.stats.index.m4',
  'component.store.stats.index.m5',
  'component.store.stats.index.m6',
  'component.store.stats.index.m7',
  'component.store.stats.index.m8',
];
