/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.card.details.data.m1',
  'subscriptions.list.cards.card.details.data.m2',
  'subscriptions.list.cards.card.details.data.m3',
  'subscriptions.list.cards.card.details.data.m4',
  'subscriptions.list.cards.card.details.data.m5',
  'subscriptions.list.cards.card.details.data.m6',
  'subscriptions.list.cards.card.details.data.m7',
  'subscriptions.list.cards.card.details.data.m8',
  'subscriptions.list.cards.card.details.data.m9',
  'subscriptions.list.cards.card.details.data.m10',
  'subscriptions.list.cards.card.details.data.m11',
  'subscriptions.list.cards.card.details.data.m12',
  'subscriptions.list.cards.card.details.data.m13',
  'subscriptions.list.cards.card.details.data.m14',
  'subscriptions.list.cards.card.details.data.m15',
  'subscriptions.list.cards.card.details.data.m16',
];
