/* eslint-disable global-require */
module.exports = [
  'homepage.marketplace.m1',
  'homepage.marketplace.m2',
  'homepage.marketplace.m3',
  'homepage.marketplace.m4',
  'homepage.marketplace.m5',
  'homepage.marketplace.m6',
  'homepage.marketplace.m7',
  'homepage.marketplace.m8',
  'homepage.marketplace.m9',
  'homepage.marketplace.m10',
  'homepage.marketplace.m11',
  'homepage.marketplace.m12',
];
