import React from 'react';
import { useNavigate } from 'react-router';
import { formatFns } from '../../../../../utils/dates/format';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Item = ({ list }) => {
  const navigate = useNavigate();

  return (
    <div className="lots-list-details" onClick={() => navigate(`/account/lots-lists/${list.id}`)}>
      <div className="lots-list-label">{list.label}</div>
      <div className="lots-list-status">
        <A18n rsx="components.lotslists.list.item.m1" replace={[['%date%', formatFns(list.createdAt, 'PPPp')]]} />
        {list.lotsCount >= 1 && (
          <span style={{ marginLeft: 5 }}>({list.lotsCount > 1 ? <A18n rsx="components.lotslists.create.m8" replace={[['%count%', list.lotsCount]]} /> : <A18n rsx="components.lotslists.create.m7" />})</span>
        )}
      </div>
    </div>
  );
};

export default Item;
