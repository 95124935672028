/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.card.m1',
  'subscriptions.list.cards.card.m2',
  'subscriptions.list.cards.card.m3',
  'subscriptions.list.cards.card.m4',
  'subscriptions.list.cards.card.m7',
  'subscriptions.list.cards.card.m8',
  'subscriptions.list.cards.card.m9',
  'subscriptions.list.cards.card.m10',
  'subscriptions.list.cards.card.m11',
  'subscriptions.list.cards.card.m14',
  'subscriptions.list.cards.card.m15',
  'subscriptions.list.cards.card.m16',
  'subscriptions.list.cards.card.m17',
  'subscriptions.list.cards.card.m18',
  'subscriptions.list.cards.card.m19',
  'subscriptions.list.cards.card.m20',
  'subscriptions.list.cards.card.m21',
  'subscriptions.list.cards.card.m22',
  'subscriptions.list.cards.card.m23',
  'subscriptions.list.cards.card.m24',
  ...require('./details/i18n'),
];
