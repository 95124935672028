module.exports = [
  'marketplace.stores.edit.form.information.m1',
  'marketplace.stores.edit.form.information.m2',
  'marketplace.stores.edit.form.information.m3',
  'marketplace.stores.edit.form.information.m4',
  'marketplace.stores.edit.form.information.m5',
  'marketplace.stores.edit.form.information.m6',
  'marketplace.stores.edit.form.information.m7',
  'marketplace.stores.edit.form.information.m8',
  'marketplace.stores.edit.form.information.m9',
  'marketplace.stores.edit.form.information.m10',
  'marketplace.stores.edit.form.information.m11',
  'marketplace.stores.edit.form.information.m12',
  'marketplace.stores.edit.form.information.m13',
  'marketplace.stores.edit.form.information.m14',
  'marketplace.stores.edit.form.information.m15',
  'marketplace.stores.edit.form.information.m16',
  'marketplace.stores.edit.form.information.m17',
  'marketplace.stores.edit.form.information.m18',
  'api.wallet.store.save.m1',
  'marketplace.stores.edit.m1',
];
//
