import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import HtmlEditor from '../../../../../common/HtmlEditor';
import { dispatchCreateMessage } from '../../../../../../redux/actions/marketplace/classifieds/messages';
import A18n from '../../../../../common/A18n';
import Modal from '../../../../../common/ui/Modal';

const ModaleForm = ({ classified, open, setOpen, setOpenLegals }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    dispatch(dispatchCreateMessage({ message, idclassified: classified.id, idcustomerInterlocutor: classified.idcustomer }));
    setOpen(false);
  };

  const handleLegalsClick = e => {
    e.preventDefault();
    setOpen(false);
    setOpenLegals(true);
  };

  return (
    <Modal className="modal-login modal-lg actions-message-modale-form" title={i18next.t('marketplace.classified.actions.message.modaleform.m1')} open={open} onClose={() => setOpen(false)}>
      <ModalBody>
        <HtmlEditor onChange={setMessage} value={message} />
      </ModalBody>

      <ModalFooter>
        <div className="link">
          <a onClick={handleLegalsClick} href="#">
            <A18n rsx="footer.marketplace_legals" />
          </a>
        </div>
        <Button type="button" className="btn btn-primary" onClick={handleSubmit}>
          <A18n rsx="login._modal.html.m5" />
        </Button>
        <Button onClick={() => setOpen(false)} data-dismiss="modal">
          <A18n rsx="login._modal.html.m6" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModaleForm;
