import React from 'react';
import A18n from '../../../../../../../../common/A18n';
import Notification from '../Notification';
import { aapiBeacon } from '../../../../../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_HEADER_NOTIFICATIONS, ANALYTICS_CLICK_OPERATION_TO_STORE_EDIT } from '../../../../../../../../../constants/rails';

const StoreInformationIncomplete = () => (
  <Notification
    element={<A18n rsx="component.header.notifications.store.m2" />}
    link="/marketplace/store/edit"
    onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_NOTIFICATIONS, op: ANALYTICS_CLICK_OPERATION_TO_STORE_EDIT })}
  />
);

export default StoreInformationIncomplete;
