import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { areThemesInitialized, areThemesLoading } from '../../../../redux/selectors/subscriptions/physicproducts';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../redux/selectors/userContext';
import A18n from '../../../common/A18n';
import Skeleton from './Skeleton';
import Members from './Members';
import Card from './Card';
import { useViewport } from '../../../../hooks/useViewport';

const Cards = ({ themes, iso3 }) => {
  const { lastSubscription, idcustomer } = useSelector(getUserContext);
  const { requiredIdmodule } = useSelector(getSettings);
  const themesInitialized = useSelector(areThemesInitialized);
  const loading = useSelector(areThemesLoading);
  const intl = useIntl();
  const { viewportWidth } = useViewport();
  if (isEmpty(themes) && !loading) return null;

  return (
    <div className="subscriptions-list-cards">
      <div className={classNames({ 'cards-container': true, essential: !idcustomer && !requiredIdmodule })}>
        {viewportWidth >= 768 && <Members iso3={iso3} />}
        {loading && <Skeleton />}
        {!loading &&
          themesInitialized &&
          themes.map(theme => (
            <div className="card-container" key={theme.theme_ref}>
              <Card theme={theme} iso3={iso3}>
                {lastSubscription?.theme === theme.theme && new Date(lastSubscription.dates.dtExpire) >= new Date() && (
                  <div className={classNames('active-subscription', theme.theme_ref)}>
                    <div className="expiration">
                      <A18n rsx="subscriptions.list.cards.m9" replace={[['%dt%', intl.formatDate(lastSubscription.dates.dtExpire)]]} />
                    </div>
                    <div className="users">
                      <i className="fa fa-user" /> {lastSubscription.usersCount}
                    </div>
                  </div>
                )}
                {(!lastSubscription || new Date(lastSubscription.dates.dtExpire) < new Date()) && theme.theme_ref === 'premium' && (
                  <div className="active-subscription premium">
                    <div className="expiration">
                      <A18n rsx="subscriptions.list.cards.m10" />
                    </div>
                  </div>
                )}
              </Card>
            </div>
          ))}
        {viewportWidth < 768 && <Members iso3={iso3} />}
      </div>
    </div>
  );
};

export default Cards;
