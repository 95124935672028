import React from 'react';
import i18next from 'i18next';
import { formatFns } from '../../../../../../../utils/dates/format';
import UsersCount from '../../../../common/UsersCount';

const Block = ({ idtheme, cardCount, started, dtStart, dtExpire, duration, usersCount }) => {
  const label = () => {
    const expire = new Date(dtExpire);
    const start = new Date(dtStart);

    if (!started) {
      return i18next.t('subscriptions.purchase.list.theme.subscription.label.m3');
    }

    if (started && expire <= new Date()) {
      return i18next.t('subscriptions.purchase.list.theme.subscription.label.m1').replace('%dtexpire%', formatFns(expire, 'P'));
    }

    if (started && start > new Date()) {
      return i18next.t('subscriptions.purchase.list.theme.subscription.label.m4').replace('%dtstart%', formatFns(start, 'P'));
    }

    return i18next.t('subscriptions.purchase.list.theme.subscription.label.m2').replace('%dtexpire%', formatFns(expire, `P${duration === 1 ? ' p' : ''}`));
  };

  return (
    <div className={`subscriptions-purchase-list-theme-subscription lvl-${idtheme} count-${cardCount}`}>
      {label()}
      {duration > 1 && started && <UsersCount idtheme={idtheme} usersCount={usersCount} />}
    </div>
  );
};

export default Block;
