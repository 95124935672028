import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Area, AreaChart } from 'recharts';
import i18next from 'i18next';
import Spinner from '../../../../common/spinners/Spinner';
import { getViewedClassifieds, isViewedClassifiedsLoading } from '../../../../../redux/selectors/marketplace/stores/stats';
import { useViewport } from '../../../../../hooks/useViewport';
import CustomToolTip from '../CustomToolTip';
import CustomTick from '../CustomTick';

const Views = () => {
  const { viewportWidth } = useViewport();
  const loading = useSelector(isViewedClassifiedsLoading);
  const viewedClassifieds = useSelector(getViewedClassifieds);

  if (loading) {
    return (
      <div style={{ height: 300 }}>
        <Spinner />
      </div>
    );
  }

  if (!viewedClassifieds || !viewportWidth || isEmpty(viewedClassifieds)) {
    return null;
  }

  return (
    <ResponsiveContainer height={300}>
      <AreaChart data={viewedClassifieds} margin={{ right: 40, top: 8 }}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis dataKey="end" height={50} tick={CustomTick} />
        <YAxis />
        <Tooltip content={CustomToolTip} />
        <Area
          isAnimationActive={false}
          strokeWidth={3}
          type="monotone"
          dataKey="count"
          name={i18next.t('component.store.stats.views.m2')}
          stroke="var(--color2)"
          fillOpacity={1}
          fill="rgba(var(--color2-rgb), 0.1)"
          dot={{ stroke: 'var(--color2)', strokeWidth: '3', fill: 'white', r: 5 }}
          activeDot={{ stroke: 'var(--color2)', strokeWidth: '3', fill: 'var(--color2)', r: 5 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Views;
