import React from 'react';
import ReduxProvider from '../../../../providers/ReduxProvider';
import Menu from '../index';

const MenuConnected = () => (
  <ReduxProvider>
    <Menu />
  </ReduxProvider>
);

export default MenuConnected;
