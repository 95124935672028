import React from 'react';
import i18next from 'i18next';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_SELLER_URL, ANALYTICS_FROM_STORE_CONTACT } from '../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import { isLogged } from '../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../common/A18n';
import './stylesheets.scss';

const Contact = ({ store }) => {
  const logged = useSelector(isLogged);

  return (
    <div className="marketplace-store-header-store-informations-contact">
      {!logged && (
        <a href="/identity" className="btn btn-default">
          <i className="fa fa-address-card-o" />
          <A18n rsx="marketplace.free.show.contact.m10" />
        </a>
      )}
      {logged && (
        <>
          {store.contact.firm && (
            <p className="strong">
              {store.contact.firm}
              {store.idstoretype && (
                <span
                  style={{
                    textTransform: 'lowercase',
                    paddingLeft: 5,
                  }}
                >
                  ({i18next.t(`artprice.storetype.m${store.idstoretype}`)})
                </span>
              )}
            </p>
          )}
          <p>
            {store.contact.addressline1 && (
              <>
                {store.contact.addressline1}
                <br />
              </>
            )}
            {store.contact.addressline2 && (
              <>
                {store.contact.addressline2}
                <br />
              </>
            )}
            {store.contact.cp && (
              <>
                {store.contact.cp}
                <br />
              </>
            )}
            {store.contact.city && (
              <>
                {store.contact.city}
                <br />
              </>
            )}
            {store.contact.state && (
              <>
                {store.contact.state}
                <br />
              </>
            )}
            {store.contact.country && store.contact.country.label}
          </p>
          {store.horaire && (
            <p>
              {i18next.t('marketplace.store._contact_store.html.m1')}: {store.horaire}
              <br />
            </p>
          )}
          {store.contact.phone && (
            <p>
              <i className="fa fa-phone" />
              &nbsp;
              {store.contact.phone}
            </p>
          )}
          {store.contact.fax && (
            <p>
              <i className="fa fa-fax" />
              &nbsp;
              {store.contact.fax}
            </p>
          )}

          {store.contact.email && (
            <p>
              <a
                target="mail"
                href={`mailto:${store.contact.email}`}
                onClick={() => {
                  aapiBeacon('click', {
                    idstore: store.id,
                    from: 'store_contact',
                    op: 'to_seller_email',
                  });
                }}
              >
                {store.contact.email}
              </a>
            </p>
          )}
          {store.url && (
            <p>
              <a href={`/redir?ist=${store.id}&f=${ANALYTICS_FROM_STORE_CONTACT}&o=${ANALYTICS_CLICK_OPERATION_TO_SELLER_URL}`} rel="noopener noreferrer" target="_blank" title={store.sitename}>
                {store.url}
              </a>
            </p>
          )}
        </>
      )}
    </div>
  );
};
Contact.propTypes = {
  store: object.isRequired,
};

export default Contact;
