import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPromotion } from '../../../../redux/actions/subscriptions/promotion';
import { getPromotion } from '../../../../redux/selectors/subscriptions/promotions';

const Promotion = ({ id }) => {
  const dispatch = useDispatch();
  const promotion = useSelector(state => getPromotion(state, { id }));

  useEffect(() => {
    if (!promotion) {
      dispatch(fetchPromotion({ id }));
    }
  }, [id]);

  if (!promotion) return null;

  return (
    <a
      href="/subscription"
      id="sln_upgrade"
      onClick={() => {
        window.dataLayer.push({ event: 'promotionClick', ecommerce: { promoClick: { promotions: [{ id, name: promotion.code, position: 'lots-detail' }] } } });
      }}
    >
      <img alt={promotion.code} src={promotion.banners[400].source} />
    </a>
  );
};

export default Promotion;
