import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeographicalDisplayedChartTab, updateGeographicalOptions, fetchGeographicalData } from '../../../redux/actions/indexes/geographical';
import { getGeographicalDisplayedChartTab } from '../../../redux/selectors/indexes/geographical';
import { TAB_CA, TAB_SOLD } from '../../../constants/indexes/geographical';
import { getIdartist, getVisibleYears } from '../../../redux/selectors/indexes/artist';
import { fetchVisibleYearsInCharts } from '../../../redux/actions/indexes/artist';
import { getCurrency } from '../../../redux/selectors/preferences';
import SubscriptionNeeded from '../SubscriptionNeeded';
import CategoriesOptions from '../common/Categories';
import MinPriceOption from '../common/MinPrice';
import MaxPriceOption from '../common/MaxPrice';
import MinYearOption from '../common/MinYear';
import MaxYearOption from '../common/MaxYear';
import Filters from '../common/Filters';
import A18n from '../../common/A18n';
import BarChart from './BarChart';
import './stylesheet.scss';
import Copyright from '../common/Copyright';

const GeographicalChart = () => {
  const dispatch = useDispatch();
  const idartist = useSelector(getIdartist);
  const currency = useSelector(getCurrency);
  const visibleYears = useSelector(getVisibleYears);
  const chartTab = useSelector(getGeographicalDisplayedChartTab);
  const [years, setYears] = useState();

  useEffect(() => {
    if (visibleYears) {
      setYears({ minYear: visibleYears.minYear, maxYear: visibleYears.maxYear });
    }
  }, [visibleYears]);

  const updateDisplayedChartTab = newChartTab => {
    dispatch(updateGeographicalDisplayedChartTab({ chartTab: newChartTab }));
    dispatch(fetchGeographicalData());
  };

  const updateOptions = options => {
    dispatch(updateGeographicalOptions({ options }));
    dispatch(fetchGeographicalData());

    setYears({ minYear: options.minYear ? options.minYear : years.minYear, maxYear: options.maxYear ? options.maxYear : years.maxYear });
  };

  useEffect(() => {
    updateDisplayedChartTab(TAB_CA);
  }, []);

  useEffect(() => {
    dispatch(fetchVisibleYearsInCharts());
    dispatch(fetchGeographicalData());
  }, [idartist, currency]);

  return (
    <>
      <div className="geographic-chart" id="geo">
        <h2>
          <A18n rsx="indexes.summary.m5" />
          {years && (
            <span style={{ marginLeft: 10 }}>
              ({years.minYear}-{years.maxYear})
            </span>
          )}
        </h2>

        <ul className="nav nav-pills">
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_CA)} className={chartTab === TAB_CA ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._second_chart.m4')}</a>
          </li>
          <li role="presentation" onClick={() => updateDisplayedChartTab(TAB_SOLD)} className={chartTab === TAB_SOLD ? 'active' : ''}>
            <a data-toggle="tab">{i18next.t('indexes._second_chart.m5')}</a>
          </li>
        </ul>

        <div style={{ position: 'relative' }}>
          <SubscriptionNeeded section={i18next.t('indexes._second_chart.m1')} />
          <div className="tab-content">
            <BarChart />
            <Filters>
              <div className="filter">
                <CategoriesOptions parentChart="geo" onChange={updateOptions} />
              </div>
              <div className="filter">
                <MinYearOption parentChart="geo" onBlur={updateOptions} />
              </div>
              <div className="filter">
                <MaxYearOption parentChart="geo" onBlur={updateOptions} />
              </div>
              <div className="filter">
                <MinPriceOption parentChart="geo" onBlur={updateOptions} />
              </div>
              <div className="filter">
                <MaxPriceOption parentChart="geo" onBlur={updateOptions} />
              </div>
            </Filters>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default GeographicalChart;
