/* eslint-disable global-require */
module.exports = [
  'components.account.verify.dialog.m1',
  'components.account.verify.dialog.m2',
  'components.account.verify.dialog.m3',
  'components.account.verify.dialog.m4',
  'components.account.verify.dialog.m5',
  'components.account.verify.dialog.m6',
  'components.account.verify.dialog.m7',
  'components.account.confirm.m1',
  'components.account.confirm.m2',
  'components.account.confirm.m3',
  'components.account.confirm.m4',
  'components.account.confirm.m5',
  'components.account.confirm.m6',
  'components.account.confirm.m7',
  'components.account.confirm.m8',
  'components.account.confirm.m9',
  'components.account.confirm.m10',
];
