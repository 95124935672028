import React from 'react';
import i18next from 'i18next';
import CountrySelect from '../../../../../search/v2/helpers/items/CountrySelect';
import DebouncedInput from '../../../../../search/v2/inputs/DebouncedInput';
import ArtistSearch from '../../../../../search/v2/inputs/ArtistSearch';
import Select from '../../../../../search/v2/inputs/Select';
import Item from '../../../../../search/v2/Search/Item';

const Drawer = ({ idartist }) => (
  <div>
    <Item label={i18next.t('marketplace.searchbars.stores.bar.m5')} name="terms">
      <DebouncedInput debounce={250} placeholder={i18next.t('layouts.menu.m2')} />
    </Item>
    <hr />
    <Item label="Artiste" name="idartist">
      <ArtistSearch disabled={!!idartist} />
    </Item>
    <hr />
    <CountrySelect />
    <Item label={i18next.t('marketplace.searchbars.common.professionaltypes.m2')} name="idprofessionaltype">
      <Select facet="idprofessionaltype" placeholder={i18next.t('marketplace.searchbars.common.professionaltypes.m1')} />
    </Item>
    <Item label={i18next.t('marketplace.searchbars.common.storetypes.m1')} name="idstoretype">
      <Select facet="idstoretype" placeholder={i18next.t('marketplace.searchbars.common.storetypes.m2')} />
    </Item>
  </div>
);

export default Drawer;
