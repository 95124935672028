/* eslint-disable global-require */
module.exports = [
  ...require('./Details/i18n'),
  ...require('./Actions/i18n'),
  ...require('./Follow/i18n'),
  ...require('./ClassifiedsForCustomer/i18n'),
  'marketplace.classified.m1',
  'marketplace.classified.m2',
  'marketplace.classified.m3',
  'marketplace.classified.m4',
  'component.myartprice.lots.detail.m1',
];
