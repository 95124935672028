import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from '../redux/selectors/preferences';
import { getSubscriptionLevel, isLogged, getIdcustomer, getIsBot, getIsArtpriceIp } from '../redux/slices/userContext/selectors';

import { GTM_DATA_LAYER_NAME } from '../constants/rails';

export default function useGtm(options = {}) {
  const lang = useSelector(getLang);
  const userSubscription = useSelector(getSubscriptionLevel);
  const userIdentified = useSelector(getIdcustomer);
  const userConnected = useSelector(isLogged);
  const artpriceIp = useSelector(getIsArtpriceIp);
  const bot = useSelector(getIsBot);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        lang,
        env: process.env.RAILS_ENV,
        userIdentified: userIdentified ? 'true' : 'false',
        userConnected: userConnected ? 'true' : 'false',
        userSubscription,
        artpriceIp: artpriceIp ? 'true' : 'false',
        bot: bot ? 'true' : 'false',
        ...options,
      },
      dataLayerName: GTM_DATA_LAYER_NAME,
    };

    TagManager.dataLayer(tagManagerArgs);
  }, [lang, userIdentified, userConnected, userSubscription]);
}
