import React from 'react';
import { useSelector } from 'react-redux';
import A18n from '../../../../../../common/A18n';
import { getSettings } from '../../../../../../../redux/selectors/subscriptions/settings';

const Back = ({ warningsCount }) => {
  const { warningForRenewInPlaceOfUpgrade, missingModules = [], quantity, initialQuantity, image } = useSelector(getSettings);

  if (warningForRenewInPlaceOfUpgrade && warningsCount === 0) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.back.m1" />;
  if (warningsCount === 1) {
    if (!image) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.back.m2" />;
    if (quantity < initialQuantity) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.back.m3" replace={[['%nb%', initialQuantity]]} />;
    if (missingModules.length > 0) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.back.m4" />;
  }

  return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.back.m5" />;
};

export default Back;
