import React from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT, ANALYTICS_SECTION_ARTIST_FUTURE_LOT } from '../../../../../../constants/rails';
import Reproduction from '../../Reproduction';
import Lock from '../../../common/Lock';
import A18n from '../../../../../common/A18n';

const Lot = ({ lot, handleOpenDrawer, displayLock, style = {} }) => (
  <a
    href={lot.url}
    onClick={e =>
      handleOpenDrawer({
        lot,
        section: ANALYTICS_SECTION_ARTIST_FUTURE_LOT,
        op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT,
        e,
      })
    }
    key={`l-${lot.url}`}
  >
    <div className="lot" style={style}>
      <div className="tag">
        <div className="dt">
          {lot.saleDtStart}
          {lot.saleDtEnd && ` - ${lot.saleDtEnd}`}
        </div>
        {lot.saleCountry && <div className="country">{lot.saleCountry}</div>}
      </div>
      <Reproduction lot={lot} />
      <p>{lot.category}</p>
      {lot.lotartistassociationtype && <p>{lot.lotartistassociationtype}</p>}
      {displayLock && !lot.ail && (
        <div className="lock">
          <Lock stroke="#b4b2ab" style={{ marginRight: 5 }} />
          <span>
            <A18n rsx="artists.artist.lots.future.m13" />
          </span>
        </div>
      )}
    </div>
  </a>
);

export default Lot;
