import { get } from 'lodash';
import { internalAxios } from '../../utils/axios/internal';

// eslint-disable-next-line import/prefer-default-export
export const datasForReactSelect = async ({ term }) => {
  const response = await internalAxios.post('/api/wallet/country/datas_for_react_select', { term });
  return get(response, 'data.items', []);
};

export const allWalletCountries = async () => {
  const response = await internalAxios.get('/api/wallet/country/all');
  return get(response, 'data.items', []);
};

export const getCountry = async ({ id }) => {
  const response = await internalAxios.get(`/api/wallet/country/show/${id}.json`);
  return response.data;
};
