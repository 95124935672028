import React, { useState } from 'react';
import { THEME_STORE } from '../../../../../../constants/rails';
import Others from './Others';
import Store from './Store';

const Marketplace = ({ theme }) => {
  const [visibleSection, setVisibleSection] = useState();

  const handleVisibleSection = id => {
    if (visibleSection === id) {
      setVisibleSection(null);
    } else {
      setVisibleSection(id);
    }
  };

  if (theme.idtheme === THEME_STORE) return <Store handleVisibleSection={handleVisibleSection} visibleSection={visibleSection} />;

  return <Others theme={theme} handleVisibleSection={handleVisibleSection} visibleSection={visibleSection} />;
};

export default Marketplace;
