/* eslint-disable global-require */
module.exports = [
  ...require('./NoPict/i18n'),
  'myartprice.common.lot.m1',
  'myartprice.common.lot.m2',
  'sales.tile.sale.m2',
  'sales.tile.sale.m3',
  'sales.tile.sale.m4',
  'sale_helper.sale_date_status.m1',
  'sale_helper.sale_date_status.m2',
  'lots.show.m12',
];
