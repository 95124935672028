import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import Modal from '../../common/ui/Modal';
import './stylesheet.scss';

const ModalLotFollowLimit = ({ open, setOpen }) => (
  <Modal className="modal-lot-follow-limit" title={i18next.t('components.myartprice.followlot.m2')} open={open} onClose={() => setOpen(false)}>
    <div className="text-center pad pad-t-25 col-xs-12 col-sm-12 pad pad-t-10 pad-b-10 font font-18">
      <p>{i18next.t('components.myartprice.followlot.m3')}</p>
    </div>
    <br />
    <div className="col-md-12 text-right pad pad-t-10 pad-b-10">
      {/* Return */}
      <Button onClick={() => setOpen(false)}>{i18next.t('component.myartprice.lots.detail.m1')}</Button>
    </div>
  </Modal>
);

export default ModalLotFollowLimit;
