import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import DependsOnSelect from '../../../inputs/Select/DependsOnSelect';
import A18n from '../../../../../common/A18n';

const DEPENDS_ON_CONFIG = {
  facet: 'idcategory',
  placeholder: <A18n rsx="lots.search.form.technique.m1" />,
};

const TechniqueSelect = () => (
  <Item label={i18next.t('search.helpers.items.technique.select.m1')} name="idtechnique">
    <DependsOnSelect facet="idtechnique" placeholder={i18next.t('search.helpers.items.technique.select.m2')} dependsOn={DEPENDS_ON_CONFIG} />
  </Item>
);

export default TechniqueSelect;
