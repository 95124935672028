import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { on as openDrawer } from '../../../../../redux/actions/ui/switches';
import ClosingDropDown from '../../../common/ClosingDropDown';
import Sort from '../../../common/Sort';
import DisplayedCurrencies from '../../../filters/DisplayedCurrencies';
import PerPage from '../../../filters/PerPage';

import {
  LOTS as LOTS_SORTS,
  MARKETPLACE_DEFAULT,
  MYARTPRICE_LOTS_AUCTIONED_OFF as MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS,
  MYARTPRICE_LOTS_FUTURE as MYARTPRICE_LOTS_FUTURE_SORTS,
} from '../../../../../constants/search/sorts';
import {
  AUCTIONEER_SALE_LOTS,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_MARKETPLACE,
} from '../../../../../constants/search/scope';
import Freshness from '../../../filters/Freshness';
import './stylesheet.scss';
import AddToListButton from '../../../../account/Lotslists/AddToListButton';
import { getCurrentResultsData } from '../../../../../redux/selectors/search/results';

const Filters = ({ currentScope, infiniteScroll, drawerId, redirect, withAddTolistButton }) => {
  const dispatch = useDispatch();
  const results = useSelector(getCurrentResultsData);

  const mapScopeToSortOptions = () => {
    let sortOptions;
    switch (currentScope) {
      // Artist follow
      case MYARTPRICE_LOTS_AUCTIONED_OFF:
        sortOptions = MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS;
        break;
      case MYARTPRICE_LOTS_FUTURE_NEW:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_FUTURE_ALL:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_MARKETPLACE:
        sortOptions = MARKETPLACE_DEFAULT;
        break;
      // Lot follow
      case MYARTPRICE_LOTS_FOLLOW_FUTURE:
        sortOptions = MYARTPRICE_LOTS_FUTURE_SORTS;
        break;
      case MYARTPRICE_LOTS_FOLLOW_PAST:
        sortOptions = MYARTPRICE_LOTS_AUCTIONED_OFF_SORTS;
        break;
      case MYARTPRICE_LOTS_FOLLOW_MARKETPLACE:
        sortOptions = MARKETPLACE_DEFAULT;
        break;
      default:
        sortOptions = LOTS_SORTS;
        break;
    }
    return sortOptions;
  };

  const sortOptions = mapScopeToSortOptions();

  return (
    <div className="filters">
      <div className="filters-container">
        {/* Advances search */}
        <div id="advanced-search" className="common-dropdown common-drop-down-to-validate">
          <div className="title">
            <button type="button" onClick={() => dispatch(openDrawer(drawerId))}>
              <i className="fa fa-search" /> &nbsp;
              {i18next.t('marketplace.searchbars.classifieds.bar.m1')}
            </button>
          </div>
        </div>

        {/* filler */}
        <div style={{ flex: 1 }} />

        {/* Sort */}
        <Sort options={sortOptions} redirect={redirect} right />

        {/* Display Preferences */}
        <ClosingDropDown
          icon="cogs"
          right
          displayIconDesktop
          render={() => (
            <>
              <DisplayedCurrencies />
              {![AUCTIONEER_SALE_LOTS].includes(currentScope) && !infiniteScroll && <PerPage />}
              {[MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_MARKETPLACE].includes(currentScope) && <Freshness />}
            </>
          )}
        />
      </div>
      {withAddTolistButton && (
        <div className="filters-container" style={{ marginTop: 10 }}>
          <AddToListButton ids={results?.idlot || []} newSelectionKey="myartprice" className="myartprice-addtolist-button" />
        </div>
      )}
    </div>
  );
};

export default Filters;
