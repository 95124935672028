import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import CommonTitleDropdown from '../../../common/inputs/TitleDropdown';
import ErrorBlock from '../../../common/ErrorBlock';
import A18n from '../../../common/A18n';

const TitleDropdown = ({ control, errors, requiredValue }) => (
  <FormGroup row>
    <Label for="idtitle" sm={4} className={classNames({ required: requiredValue })}>
      <A18n rsx="marketplace.reporting.titledropdown.m1" />
    </Label>
    <Col sm={5}>
      <CommonTitleDropdown
        control={control}
        width="100%"
        className="form-control"
        name="idtitle"
        rules={{
          required: {
            value: requiredValue,
            message: <A18n rsx="marketplace.reporting.m27" />,
          },
        }}
      />
      <ErrorBlock error={errors.idtitle} />
    </Col>
  </FormGroup>
);

export default TitleDropdown;
