import React from 'react';
import i18next from 'i18next';
import { connect, useSelector } from 'react-redux';
import { getMaxVisibleYear, isSubscriptionNeeded } from '../../../redux/selectors/indexes/artist';
import { getActiveSubscription, isLogged } from '../../../redux/slices/userContext/selectors';
import { shouldRender } from '../../../services/indexes/subscriptionNeeded';
import './stylesheet.scss';
import { MODULE_INDEXES } from '../../../constants/rails';
import { EMPTY_ARRAY } from '../../../constants/utils';

const UnconnectedSubscriptionNeeded = ({ maxYear = 2002, logged = false, subscriptionNeeded = false, othersDisplayConditions = EMPTY_ARRAY }) => {
  const currentSubscription = useSelector(getActiveSubscription);
  if (!shouldRender(subscriptionNeeded, othersDisplayConditions)) {
    return null;
  }

  return (
    <div className="subscription-needed">
      <div className="font font-14 sn-banner">
        <div className="centered-container text-center">
          <p style={{ fontSize: '1.75rem' }}>{i18next.t('indexes.subscription_needed.title').replace('%year%', maxYear)}</p>
          <p>
            <a className="btn btn-flat link ripple-on-hover" href={`/subscription${currentSubscription ? `/upgrade` : ''}?idmodule=${MODULE_INDEXES}`}>
              <i className="fa fa-shopping-cart marg marg-r-5" />
              {i18next.t('indexes.subscription_needed.buy_btn')}
            </a>
            {!logged && (
              <>
                <span className="marg marg-l-5 marg-r-5">{i18next.t('indexes.subscription_needed.or')}</span>
                <a className="btn btn-flat link ripple-on-hover" href="/identity">
                  <i className="fa fa-user marg marg-r-5" />
                  {i18next.t('indexes.subscription_needed.login')}
                </a>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    maxYear: getMaxVisibleYear(state),
    logged: isLogged(state),
    subscriptionNeeded: isSubscriptionNeeded(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedSubscriptionNeeded);
