/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import i18next from 'i18next';
import Select from '../../common/FacetsSelect';

const component = ({ className }) => (
  <div className="artp-input-group">
    <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.shapes.m1')}</span>
    <Select placeholder={i18next.t('marketplace.searchbars.classifieds.bar.shapes.m2')} className={className} facetName="shape" isMulti />
  </div>
);

export default component;
