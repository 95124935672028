/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.header.m1',
  'subscriptions.list.header.m2',
  'subscriptions.list.header.m3',
  'subscriptions.list.header.m4',
  'subscriptions.list.header.m5',
  'subscriptions.list.header.m6',
  'subscriptions.list.header.m7',
  'subscriptions.list.header.m8',
  'subscriptions.list.header.m9',
  'subscriptions.list.header.m10',
  'subscriptions.list.header.m11',
  'subscriptions.list.header.m12',
  'subscriptions.list.header.m13',
  'subscriptions.list.header.m14',
  'subscriptions.list.header.m15',
];
