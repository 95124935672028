/* eslint-disable global-require */
module.exports = [
  'homepage.myartprice.m1',
  'homepage.myartprice.m2',
  'homepage.myartprice.m3',
  'homepage.myartprice.m4',
  'homepage.myartprice.m5',
  'homepage.myartprice.m6',
  'homepage.myartprice.m7',
  'homepage.myartprice.m8',
  'homepage.myartprice.m9',
  'homepage.myartprice.m11',
  'homepage.myartprice.m12',
  'homepage.myartprice.m14',
  'homepage.myartprice.m15',
  'homepage.myartprice.m16',
  'homepage.myartprice.m17',
];
