module.exports = [
  'events.list.filters.countries.title',
  'events.list.filters.countries.dropdownheader',
  'events.list.filters.countries.options.all',
  'events.list.filters.dates.title',
  'events.list.filters.dates.dropdownheader',
  'events.list.filters.dates.from',
  'events.list.filters.dates.to',
  'events.list.filters.dates.confirm',
];
