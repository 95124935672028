import React from 'react';
import i18next from 'i18next';
import { MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE, THEME_STORE } from '../../../../../../../constants/rails';
import A18n from '../../../../../../common/A18n';

const Marketplace = ({ theme }) => {
  if (theme.idtheme === THEME_STORE) {
    return (
      <>
        <div style={{ textAlign: 'center', marginBottom: 20, padding: '0 15px' }}>
          <A18n
            rsx="subscriptions.purchase.list.theme.details.marketplace.m2"
            trustHtml
            replace={[
              ['%strong%', '<strong>'],
              ['%/strong%', '</strong>'],
            ]}
          />
        </div>
        <ul>
          <li>{i18next.t('subscriptions.purchase.list.theme.details.marketplace.m1')}</li>
          <li>{i18next.t('subscriptions.purchase.list.theme.details.marketplace.m3')}</li>
          <li>{i18next.t('subscriptions.purchase.list.theme.details.marketplace.m5')}</li>
          <li>{i18next.t('subscriptions.purchase.list.theme.details.marketplace.m6')}</li>
        </ul>
      </>
    );
  }

  return (
    <ul>
      {theme.module.includes(MODULE_STORE) && <li className="module-store">{i18next.t('subscriptions.purchase.list.theme.details.data.m7')}</li>}
      {theme.module.includes(MODULE_PASTSALE) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m3')}</li>}
      {theme.module.includes(MODULE_FUTURESALE) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m4')}</li>}
      <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m1')}</li>
      <li className={`lvl-${theme.idtheme} ${theme.module.includes(MODULE_IMAGE) || !theme.active ? undefined : 'strike'}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m2')}</li>
      {theme.module.includes(MODULE_INDEXES) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m5')}</li>}
      {theme.module.includes(MODULE_INDICATOR) && <li className={`lvl-${theme.idtheme}`}>{i18next.t('subscriptions.purchase.list.theme.details.data.m6')}</li>}
      {theme.module.includes(MODULE_EXPORT_PDF) && (
        <li className={`lvl-${theme.idtheme}`}>
          {i18next.t('subscriptions.purchase.list.theme.details.data.m8')}
          <i className="marg marg-l-5 fa fa-file-pdf-o" />
        </li>
      )}
    </ul>
  );
};

export default Marketplace;
