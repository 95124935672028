import React, { forwardRef } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getSale } from '../../../../redux/selectors/dbEntities';
import { getCurrentQueryObject } from '../../../../utils/http';

const rsxKeys = [
  'auctioneers.contact.subjects.m1',
  'auctioneers.contact.subjects.m2',
  'auctioneers.contact.subjects.m3',
  'auctioneers.contact.subjects.m5',
  'auctioneers.contact.subjects.m6',
  'auctioneers.contact.subjects.m7',
  'auctioneers.contact.subjects.m9',
  'auctioneers.contact.subjects.m8',
  'auctioneers.contact.subjects.m10',
];

const rsxKeysForFutureSales = ['auctioneers.contact.subjects.m4'];

// eslint-disable-next-line no-unused-vars
const Subjects = forwardRef(({ register, idsale = false, future }, ref) => {
  const { key } = getCurrentQueryObject();
  const sale = useSelector(state => getSale(state, { id: idsale }));

  let defaultValue;
  if (key) {
    defaultValue = i18next.t(`auctioneers.contact.subjects.${key}`);
  }

  const getRsxKeys = () => {
    if (sale && !sale?.isPast) {
      return [...rsxKeys, ...rsxKeysForFutureSales].sort();
    }

    return rsxKeys;
  };

  return (
    <select className="form-control" name="subject" ref={ref} defaultValue={defaultValue}>
      {getRsxKeys().map(rsxKey => (
        <option key={i18next.t(rsxKey)} value={i18next.t(rsxKey)}>
          {i18next.t(rsxKey)}
        </option>
      ))}
    </select>
  );
});

export default Subjects;
