import { isEmpty } from 'lodash';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getArtist, getClassifieds } from '../../../../../redux/slices/artist';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { useOverlap } from '../../../../../hooks/useOverlap';
import '../../../../common/stylesheets/buttons.scss';
import { UiContext } from '../../common/UiProvider';
import A18n from '../../../../common/A18n';
import Scroll from '../../common/Scroll';
import {
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_ARTIST_CLASSIFIEDS,
  ANALYTICS_SECTION_ARTIST_MARKETPLACE_CLASSIFIEDS_LIST,
} from '../../../../../constants/rails';
import Categories from '../Categories';
import SellAd from './SellAd';
import Empty from './Empty';
import './stylesheet.scss';

const Classifieds = ({ menuRef }) => {
  const { hideLeftMenu } = useContext(UiContext);
  const classifieds = useSelector(getClassifieds);
  const artist = useSelector(getArtist);
  const sellAdRef = useRef();

  useOverlap({ className: 'overlapped', overlappingElementRef: hideLeftMenu ? null : menuRef, overlappedElementRef: sellAdRef, extraDeps: [hideLeftMenu] });

  if (isEmpty(classifieds?.items)) return <Empty />;

  return (
    <div className="artists-artist-marketplace-classifieds">
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.marketplace.classifieds.m1" />
        </h2>
        <p className="sub-h2">
          {classifieds.totalCount > 1 && (
            <A18n
              rsx={isEmpty(artist.groupMembers) ? 'artists.artist.marketplace.classifieds.m2' : 'artists.artist.marketplace.classifieds.m6'}
              replace={[
                ['%nb%', classifieds.totalCount],
                ['%artist%', artist.name],
              ]}
            />
          )}
          {classifieds.totalCount === 1 && <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.marketplace.classifieds.m3' : 'artists.artist.marketplace.classifieds.m7'} replace={[['%artist%', artist.name]]} />}
        </p>

        <Categories categories={classifieds.categories} />
      </div>
      <Scroll
        aapiBeaconSection={ANALYTICS_SECTION_ARTIST_MARKETPLACE_CLASSIFIEDS_LIST}
        className="classifieds"
        renderComplement={() => {
          if (classifieds.totalCount === 1) return null;

          return (
            <a
              href={classifieds.url}
              className="artp-btn artp-btn-artist"
              onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_ARTIST_CLASSIFIEDS, idartist: artist.id })}
            >
              {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.marketplace.classifieds.m4" />}
              {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.marketplace.classifieds.m5" />}
            </a>
          );
        }}
      >
        {classifieds.items.map(({ url, id, imgUrl, imgAlt, year, title, category, technique, storeUrl, storeName }) => (
          <a href={url} key={id} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL, idartist: artist.id, idclassified: id })}>
            <div className="classified">
              <div className="img-container">
                <img src={imgUrl} alt={imgAlt} />
              </div>
              <div className="data">
                <p className="title">
                  {title} {year && <span>({year})</span>}
                </p>
                <p>{category}</p>
                <p>{technique}</p>
                {storeUrl && <p className="storename">{storeName}</p>}
              </div>
            </div>
          </a>
        ))}
      </Scroll>
      {!hideLeftMenu && (
        <div className="container">
          <SellAd ref={sellAdRef} />
        </div>
      )}
    </div>
  );
};

export default Classifieds;
