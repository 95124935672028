import React from 'react';
import A18n from '../../../common/A18n';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import './stylesheet.scss';

const Staff = () => (
  <div className="ails-info-staff">
    <h2>
      <A18n rsx="ails.info.m52" />
    </h2>
    <p>
      <A18n rsx="ails.info.m53" />
    </p>

    <img alt="staff" src={`${IMAGE_SOURCE}ail/ail-equipe-1920.png`} />
  </div>
);

export default Staff;
