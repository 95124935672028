import i18next from 'i18next';

export function simplifyData(data, threshold = 0.1) {
  const toKeep = data.filter(({ value }) => value >= threshold);
  const toGather = data.filter(({ value }) => value < threshold);
  const gathered = toGather.reduce(
    (result, { value }) => ({
      ...result,
      value: result.value + value,
    }),
    { name: i18next.t('indexes.others'), value: 0 },
  );

  if (gathered.value === 0) {
    return toKeep;
  }
  return toKeep.concat(gathered);
}
