/* eslint-disable global-require */
module.exports = [
  'marketplace.sellermenu.m2',
  'marketplace.sellermenu.m3',
  'marketplace.sellermenu.m4',
  'marketplace.sellermenu.m5',
  'marketplace.sellermenu.m6',
  'marketplace.sellermenu.m7',
  'marketplace.sellermenu.m9',
  'marketplace.sellermenu.m10',
  'marketplace.sellermenu.m11',
  'marketplace.sellermenu.m13',
  'marketplace.sellermenu.m14',
  'marketplace.sellermenu.m15',
  'routes.marketplace',
  'routes.marketplace_manage',
  'routes.marketplace_info',
  'component.store.stats.index.m1',
  'marketplace.stores.edit.form.m1',
  'common._marketplace_menu.html.m46',
  'marketplace.common.managmentmenu.m1',
  'layouts.header.m21',
];
