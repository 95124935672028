import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import Select from '../../../inputs/Select';

const ArtistSelect = () => (
  <Item label={i18next.t('artprice.fs.fs.m1')} name="idartist">
    <Select facet="idartist" placeholder={i18next.t('javascripts.locales.m1')} />
  </Item>
);

export default ArtistSelect;
