import i18next from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSale } from '../../../redux/selectors/dbEntities';
import './stylesheets.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_FROM_SALE, ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS } from '../../../constants/rails';
import Modal from '../../common/ui/Modal';

const Contacts = ({ id }) => {
  const sale = useSelector(state => getSale(state, { id }));
  const [open, setOpen] = useState(false);

  let contactLink;
  if (sale?.auctioneerRawWww) {
    contactLink = sale.auctioneerRawWww.startsWith('http') ? sale.auctioneerRawWww : `https://${sale.auctioneerRawWww}`;
  }

  if (!sale) return null;

  return (
    <div className="sales-contacts">
      <button
        type="button"
        className="btn btn-default"
        onClick={() => {
          setOpen(true);
          aapiBeacon('click', { from: ANALYTICS_FROM_SALE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, idsale: id });
        }}
      >
        <i className="fa fa-phone" />
        {i18next.t('sales.contacts.m1')}
      </button>

      <Modal open={open} onClose={() => setOpen(false)} title={sale.auctioneerName} className="sales-contacts-modale">
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: sale.auctioneerAddress }} />

        <p>
          <i className="fa fa-phone" />
          {sale.auctioneerPhone}
        </p>
        <p>
          <i className="fa fa-fax" />
          {sale.auctioneerFax}
        </p>
        <p>
          <a href={`mailto:${sale.auctioneerEmail}`}>{sale.auctioneerEmail}</a>
        </p>
        {contactLink && (
          <p>
            <a href={contactLink} rel="noreferrer" target="_blank">
              {contactLink}
            </a>
          </p>
        )}

        <p
          style={{
            textAlign: 'right',
            marginTop: 15,
            marginBottom: 0,
          }}
        >
          <button type="button" className="btn btn-default" onClick={() => setOpen(false)}>
            {i18next.t('auctioneers.contact.messagesent.m3')}
          </button>
        </p>
      </Modal>
    </div>
  );
};

export default Contacts;
