import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist } from '../../../../redux/slices/artist';

const Schemas = () => {
  const artist = useSelector(getArtist);

  return (
    <>
      <meta itemProp="name" content={artist.name} />
      {artist.birthDate && <meta itemProp="birthDate" content={artist.birthDate} />}
      {artist.deathDate && <meta itemProp="deathDate" content={artist.deathDate} />}
      <link id="schemaurl" href={`${window.location.origin}${artist.url}`} itemProp="url" />
    </>
  );
};

export default Schemas;
