import i18next from 'i18next';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from '../../common/LoginOrRegistrationModal/constants';
import { getCustomerCurrency, getPhysicproducts, retrievePhysicproducts } from '../../../redux/slices/estimates';
import LoginOrRegistrationModal from '../../common/LoginOrRegistrationModal';
import { getUserContext } from '../../../redux/selectors/userContext';
import { set } from '../../../redux/actions/ui/dictionary';
import { resetSession } from '../../../services/artprice';
import PurchaseNotAvailable from './PurchaseNotAvailable';
import { PDF_SOURCE } from '../../../constants/rails';
import '../../common/stylesheets/buttons.scss';
import A18n from '../../common/A18n';
import Promotion from './Promotion';
import './stylesheet.scss';

const Info = ({ data: { promocode, unitCount, nbDraftsSelected, nbDraftsUnselected, parent } }) => {
  const dispatch = useDispatch();
  const physicproducts = useSelector(getPhysicproducts);
  const customerCurrency = useSelector(getCustomerCurrency);
  const { logged, idcustomer } = useSelector(getUserContext);
  const intl = useIntl();
  const [displayFaq, setDisplayFaq] = useState(0);
  const [selectedPhysicproduct, setSelectedPhysicproduct] = useState();

  useEffect(() => {
    dispatch(retrievePhysicproducts({ promocode }));
  }, []);

  const handleClick = physicproduct => {
    setSelectedPhysicproduct(physicproduct);

    if (logged) {
      if (physicproduct.nbUnits === 1) {
        window.location = `/estimate/new`;
      } else {
        window.location = `/payments/informations?type=estimate&idphysicproducts[${physicproduct.id}]=1${promocode ? `&promocode=${promocode}` : ''}`;
      }
    } else {
      dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, true));
    }
  };

  const onUserNotExists = async ({ initialEmail, id }) => {
    if (idcustomer) {
      await resetSession();
    }

    window.location = `/payments/informations?type=estimate&idphysicproducts[${id}]=1${promocode ? `&promocode=${promocode}` : ''}${initialEmail ? `&initialEmail=${initialEmail}` : ''}`;
  };

  const onLogin = id => {
    window.location = `/payments/informations?type=estimate&idphysicproducts[${id}]=1${promocode ? `&promocode=${promocode}` : ''}`;
  };

  return (
    <div className="estimates-info">
      <Promotion position="top" promocode={promocode} />
      {parent && <PurchaseNotAvailable />}
      <div className="container">
        <h1>
          <A18n rsx="estimates.info.m1" />
        </h1>
        <p>
          <A18n rsx="estimates.info.m2" />
          <small style={{ marginLeft: 5 }}>
            (<A18n rsx="estimates.info.m3" />)
          </small>
        </p>

        {unitCount > 0 && (
          <p>
            {unitCount > 1 && <A18n rsx="estimates.info.m4" replace={[['%nb%', unitCount]]} />}
            {unitCount === 1 && <A18n rsx="estimates.info.m5" />}
          </p>
        )}

        {nbDraftsSelected > 0 && (
          <p>
            <A18n rsx={nbDraftsSelected > 1 ? 'estimates.info.m6' : 'estimates.info.m8'} replace={[['%nb%', nbDraftsSelected]]} />
            {', '}
            <a href={`/estimate/preview${promocode ? `?promocode=${promocode}` : ''}`}>
              <b>
                <A18n rsx={nbDraftsSelected > 1 ? 'estimates.info.m7' : 'estimates.info.m9'} />
              </b>
            </a>
            .
          </p>
        )}

        {nbDraftsUnselected > 0 && (
          <p>
            <A18n rsx={nbDraftsUnselected === 1 ? 'estimates.info.m10' : 'estimates.info.m11'} replace={[['%nb%', nbDraftsUnselected]]} />
            {', '}
            <a href={`/estimate/preview${promocode ? `?promocode=${promocode}` : ''}`}>
              <b>
                <A18n rsx={nbDraftsUnselected === 1 ? 'estimates.info.m12' : 'estimates.info.m13'} />
              </b>
            </a>
            .
          </p>
        )}

        {unitCount > 0 && (
          <p style={{ marginTop: 30, marginBottom: 30 }}>
            <a className="artp-btn artp-btn-purple sln_estimate_form" href={`/estimate/new${promocode ? `?promocode=${promocode}` : ''}`}>
              <A18n rsx="estimates.info.m14" />
            </a>
          </p>
        )}

        {!isEmpty(physicproducts) && (
          <div className="prices">
            <table>
              <thead>
                <tr>
                  <th>
                    <A18n rsx="estimates.info.m15" />
                  </th>
                  <th>
                    <A18n rsx="estimates.info.m16" />
                  </th>
                  <th className="hidden-xs">
                    <A18n rsx="estimates.info.m17" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {[...physicproducts]
                  .sort((a, b) => a.nbUnits - b.nbUnits)
                  .filter(physicproduct => (unitCount > 0 ? physicproduct.nbUnits > 1 : true))
                  .map(physicproduct => {
                    if (physicproduct.nbUnits === 1 && unitCount > 0) return null;

                    return (
                      <tr key={physicproduct.id}>
                        <td>{physicproduct.nbUnits}</td>
                        <td>
                          {/* eslint-disable-next-line react/style-prop-object */}
                          <FormattedNumber value={physicproduct.prices[customerCurrency.iso3.toLowerCase()]} style="currency" currency={customerCurrency.iso3} />
                        </td>
                        <td className="hidden-xs">
                          {/* eslint-disable-next-line react/style-prop-object */}
                          <FormattedNumber value={physicproduct.prices[customerCurrency.iso3.toLowerCase()] / physicproduct.nbUnits} style="currency" currency={customerCurrency.iso3} />
                        </td>
                        <td>
                          {physicproduct.nbUnits === 1 && (
                            <button
                              className="artp-btn artp-btn-purple"
                              type="button"
                              onClick={() => {
                                window.location = `/estimate/new${promocode ? `?promocode=${promocode}` : ''}`;
                              }}
                            >
                              <A18n rsx="estimates.info.m14" />
                            </button>
                          )}

                          {physicproduct.nbUnits > 1 && (
                            <button className="artp-btn artp-btn-purple" type="button" onClick={() => handleClick(physicproduct)}>
                              <A18n rsx="estimates.info.m18" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <LoginOrRegistrationModal
              onLogin={() => onLogin(selectedPhysicproduct.id)}
              onUserNotExists={initialEmail =>
                onUserNotExists({
                  initialEmail,
                  id: selectedPhysicproduct.id,
                })
              }
              titleLogin={i18next.t('estimates.info.m19')}
              titleCheckUser={i18next.t('estimates.info.m20')}
            />
          </div>
        )}

        <div className="faq">
          <div className="faq-title">
            <A18n rsx="estimates.info.m21" trustHtml />
          </div>

          <div className="faq-sections">
            <div className="faq-headline">
              <div>
                <A18n rsx="estimates.info.m22" trustHtml />
              </div>
              <button type="button" onClick={() => setDisplayFaq(1)} className="artp-btn artp-btn-transparent">
                <A18n rsx="estimates.info.m41" trustHtml />
              </button>
            </div>
            <div className={classNames({ 'faq-content': true, visible: displayFaq === 1 })}>
              <p>
                <A18n rsx="estimates.info.m23" trustHtml />
              </p>
              <ul>
                <li>
                  <A18n rsx="estimates.info.m24" trustHtml />
                </li>
                <li>
                  <A18n rsx="estimates.info.m25" trustHtml />
                </li>
              </ul>

              <p>
                <A18n rsx="estimates.info.m26" trustHtml />
              </p>
              <ul>
                <li>
                  <A18n rsx="estimates.info.m27" trustHtml />
                </li>
                <li>
                  <A18n rsx="estimates.info.m28" trustHtml />
                </li>
              </ul>
              <p>
                <a href={`${PDF_SOURCE}examples_estimates/examples_estimates_${intl.locale}.pdf`} target="_blank" rel="noreferrer">
                  <A18n rsx="estimates.info.m29" trustHtml />
                </a>
              </p>
            </div>

            <div className="faq-headline">
              <div>
                <A18n rsx="estimates.info.m30" />
              </div>
              <button type="button" onClick={() => setDisplayFaq(2)} className="artp-btn artp-btn-transparent">
                <A18n rsx="estimates.info.m41" trustHtml />
              </button>
            </div>
            <div className={classNames({ 'faq-content': true, visible: displayFaq === 2 })}>
              <A18n rsx="estimates.info.m31" trustHtml />
            </div>

            <div className="faq-headline">
              <div>
                <A18n rsx="estimates.info.m32" trustHtml />
              </div>
              <button type="button" onClick={() => setDisplayFaq(3)} className="artp-btn artp-btn-transparent">
                <A18n rsx="estimates.info.m41" trustHtml />
              </button>
            </div>
            <div className={classNames({ 'faq-content': true, visible: displayFaq === 3 })}>
              <ul>
                <li>
                  <A18n rsx="estimates.info.m33" trustHtml />
                </li>
                <li>
                  <A18n rsx="estimates.info.m34" trustHtml />
                </li>
                <li>
                  <A18n rsx="estimates.info.m35" trustHtml />
                </li>
              </ul>
            </div>

            <div className="faq-headline">
              <div>
                <A18n rsx="estimates.info.m36" trustHtml />
              </div>
              <button type="button" onClick={() => setDisplayFaq(4)} className="artp-btn artp-btn-transparent">
                <A18n rsx="estimates.info.m41" trustHtml />
              </button>
            </div>
            <div className={classNames({ 'faq-content': true, visible: displayFaq === 4 })}>
              <A18n rsx="estimates.info.m37" trustHtml />
            </div>

            <div className="faq-headline">
              <div>
                <A18n rsx="estimates.info.m38" trustHtml />
              </div>
              <button type="button" onClick={() => setDisplayFaq(5)} className="artp-btn artp-btn-transparent">
                <A18n rsx="estimates.info.m41" trustHtml />
              </button>
            </div>
            <div className={classNames({ 'faq-content': true, visible: displayFaq === 5 })}>
              <A18n rsx="estimates.info.m39" trustHtml /> <A18n rsx="estimates.info.m40" replace={[['%url%', '/contact/message?t=artpricing']]} trustHtml />
            </div>
          </div>
        </div>
      </div>
      <Promotion />
    </div>
  );
};

export default Info;
