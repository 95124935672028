import React from 'react';
import A18n from '../../common/A18n';
import Arrow from '../../common/Arrow';
import './stylesheet.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import '../../common/stylesheets/buttons.scss';

const Faq = () => (
  <div className="homepage-faq block">
    <div className="container">
      <h2 className="title">
        <A18n rsx="homepage.faq.m1" />
        <div className="all">
          <a href="/contact" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME })}>
            <A18n rsx="homepage.faq.m2" />
            <Arrow style={{ marginLeft: 5 }} />
          </a>
        </div>
      </h2>

      <div className="sections">
        <div className="section">
          <div className="title">
            <A18n rsx="homepage.faq.m3" />
          </div>
          <div className="lnk">
            <a href="contact?active=block1&id=387" className="artp-btn" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, section: 387 })}>
              <A18n rsx="homepage.faq.m4" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="homepage.faq.m5" />
          </div>
          <div className="lnk">
            <a href="/contact?active=block2&id=423" className="artp-btn" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, section: 423 })}>
              <A18n rsx="homepage.faq.m4" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="homepage.faq.m6" />
          </div>
          <div className="lnk">
            <a href="/contact?active=block3&id=648" className="artp-btn" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, section: 648 })}>
              <A18n rsx="homepage.faq.m4" />
            </a>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <A18n rsx="homepage.faq.m7" />
          </div>
          <div className="lnk">
            <a href="/contact?active=block5&id=411" className="artp-btn" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS, section: 411 })}>
              <A18n rsx="homepage.faq.m4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Faq;
