import React, { useCallback } from 'react';
import Observer from '../../Observer';
import { aapiBeacon } from '../../../../services/analytics/aapi';

const intersections = [];

function createUniqKey(payload) {
  return Object.values(payload).sort().join('-');
}

const DOMView = ({ selector, ...aapiParams }) => {
  const handleIntersect = useCallback(
    ({ entries, payload }) => {
      entries.forEach(entry => {
        const uniqKey = createUniqKey(payload);
        if (entry.isIntersecting && !intersections.includes(uniqKey)) {
          aapiBeacon('dom_view', aapiParams);
          intersections.push(uniqKey);
        }
      });
    },
    [createUniqKey(aapiParams)],
  );

  return <Observer selectors={[{ selector, payload: aapiParams }]} handleIntersect={handleIntersect} />;
};

export default DOMView;
