/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.warnings.update-or-renew.m1',
  'subscriptions.purchase.warnings.update-or-renew.m2',
  'subscriptions.purchase.warnings.update-or-renew.m3',
  'subscriptions.purchase.warnings.update-or-renew.m4',
  'subscriptions.purchase.warnings.update-or-renew.m5',
  'subscriptions.purchase.warnings.update-or-renew.m6',
  'subscriptions.purchase.warnings.update-or-renew.m7',
  'subscriptions.purchase.warnings.update-or-renew.m8',
  'subscriptions.purchase.warnings.update-or-renew.m9',
  'subscriptions.purchase.warnings.update-or-renew.m10',
  'subscriptions.purchase.warnings.update-or-renew.m11',
  'subscriptions.purchase.warnings.update-or-renew.m12',
  'subscriptions.purchase.warnings.update-or-renew.m13',
  'subscriptions.purchase.warnings.update-or-renew.m14',
  'subscriptions.purchase.warnings.update-or-renew.m15',
  'subscriptions.purchase.warnings.update-or-renew.m16',
  'subscriptions.purchase.warnings.update-or-renew.m17',
  'subscriptions.purchase.warnings.update-or-renew.m18',
];
