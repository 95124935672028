import { object, string } from 'yup';

const schema = object().shape({
  sitename: string(),
  presentation: string(),
  news: string(),
  storetype: object()
    .shape({
      value: string(),
      label: string(),
    })
    .required(),
  url: string(),
  horaire: string(),
});

export default schema;
