import { Button } from 'reactstrap';
import { IntlProvider } from 'react-intl';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { areClassifiedsError, getArtist, getClassified } from '../../../redux/selectors/dbEntities';
import { getIdcustomer } from '../../../redux/slices/userContext/selectors';
import ClassifiedsForCustomer from './ClassifiedsForCustomer';
import { aapiBeacon } from '../../../services/analytics/aapi';
import SameToSellAnnounce from './SameToSellAnnounce';
import Spinner from '../../common/spinners/Spinner';
import Separator from './common/Separator';
import LinkToArtist from './LinkToArtist';
import Description from './Description';
import A18n from '../../common/A18n';
import Details from './Details';
import Actions from './Actions';
import Images from './Images';
import Share from './Share';
import './stylesheet.scss';

const Classified = ({ id, lang }) => {
  const classified = useSelector(state => getClassified(state, { id }), shallowEqual);
  const artist = useSelector(state => getArtist(state, { id: classified ? classified.idartist : 0 }), shallowEqual);
  const classifiedError = useSelector(areClassifiedsError);
  const idcustomer = useSelector(getIdcustomer) || 0;

  useEffect(() => {
    if (classified) {
      if (classified.idcustomer !== idcustomer) {
        aapiBeacon('classified_view', { idclassified: classified.id, view: 'detail' });
      }
    }
  }, [classified]);

  if (classifiedError) {
    return (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-triangle" />
        {classifiedError.message}
      </div>
    );
  }

  if (!classified) return <Spinner />;

  return (
    <IntlProvider locale={lang}>
      <div className="marketplace-classified">
        <div className="container">
          <div className="cols">
            <Button className="btn btn-default" onClick={() => window.history.back()} style={{ width: 'fit-content', marginBottom: 15 }}>
              <A18n rsx="component.myartprice.lots.detail.m1" />
            </Button>
          </div>
          <div className="cols">
            <div className="col-1">
              <Images classified={classified} />
              <Share classified={classified} />
            </div>
            <div className="col-2">
              <Description className="hidden-xs" classified={classified} artist={artist} />
              <Details classified={classified} />
            </div>
            <div className="col-3">
              <Description className="visible-xs" classified={classified} artist={artist} />
              <LinkToArtist className="hidden-xs" classified={classified} artist={artist} />
              <Actions classified={classified} />
              <SameToSellAnnounce className="hidden-xs" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="visible-xs">
          <Separator style={{ margin: 0 }} />
          <LinkToArtist classified={classified} artist={artist} />
          <SameToSellAnnounce />
          <Separator style={{ margin: 0 }} />
        </div>
        <ClassifiedsForCustomer classified={classified} />
      </div>
    </IntlProvider>
  );
};

export default Classified;
