import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { set as setUiDictionary } from '../../../../../../redux/actions/ui/dictionary';
import { get as getUiDictionary } from '../../../../../../redux/selectors/ui/dictionary';
import { MYARTPRICE_SUMMARY_FILTER, MYARTPRICE_SUMMARY_NEWS_ONLY } from '../../../../../../constants/myartprice';
import Checkbox from '../../../../../common/ui/Checkbox';

import './stylesheet.scss';
// import ArtistAsyncSelect from '../../../../Settings/Artists/ArtistAsyncSelect';

const SummaryArtistsFilter = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [checked, setChecked] = useState(false);
  const isChecked = useSelector(state => getUiDictionary(state, { id: MYARTPRICE_SUMMARY_NEWS_ONLY }));

  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  const handleNewDataOnlyChange = value => {
    dispatch(setUiDictionary(MYARTPRICE_SUMMARY_NEWS_ONLY, value));
  };

  const handleFilterChange = value => {
    setFilter(value);
    dispatch(setUiDictionary(MYARTPRICE_SUMMARY_FILTER, value));
  };

  return (
    <div className="shortcuts">
      <div className="header-settings">{i18next.t('components.myartprice.summary.m1').toUpperCase()}</div>
      <div className="filters">
        <div className="filter" style={{ marginBottom: 10 }}>
          <Checkbox onChange={v => handleNewDataOnlyChange(v)} checked={checked} label={i18next.t('components.myartprice.summary.m2')} />
        </div>
        <div className="filter">
          <div className="input-group">
            <input type="text" className="form-control" placeholder={i18next.t('components.myartprice.summary.m3')} value={filter} onChange={e => handleFilterChange(e.target.value)} />
            <span className="input-group-btn">
              <button className={`btn btn-secondary ${filter === '' && 'disabled'}`} type="button" onClick={() => handleFilterChange('')}>
                <i className="fa fa-times" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryArtistsFilter;
