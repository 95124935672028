import React, { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchArtists, followArtist, unfollowArtist, updateArtists } from '../../../redux/actions/myartprice/datas';
import { isLogged as isLoggedSelector, getIdcustomer, getFavoriteCount, getPushEmail } from '../../../redux/slices/userContext/selectors';
import ButtonFollow from '../../common/ButtonFollow';
import { getArtists, getError } from '../../../redux/selectors/myartprice/datas';
import A18n from '../../common/A18n';
import { update as updateUserContext } from '../../../redux/slices/userContext';
import RecommendationsModale from './RecommendationsModale';
import { updateFollowRecomendationDisplay } from '../../../redux/slices/artist';

const Follow = ({ idartist, size, rsxFavorited = 'components.myartprice.followartist.m3', rsxNotFavorited = 'components.myartprice.followartist.m2', noLabel, render }) => {
  const recommendationModaleUUID = useId();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);
  const favoriteArtists = useSelector(getArtists);
  const favoriteCount = useSelector(getFavoriteCount);
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const [isFavorite, setIsFavorite] = useState(false);
  const error = useSelector(getError);

  useEffect(() => {
    if (idcustomer && isEmpty(favoriteArtists)) {
      dispatch(fetchArtists());
    }
  }, []);

  useEffect(() => {
    setIsFavorite(favoriteArtists.some(fav => fav.id === idartist));
  }, [favoriteArtists, idartist]);

  const handleFollowClick = () => {
    // Pas de client cooké on recommande de créer un compte
    if (!idcustomer) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
      return;
    }

    // client non loggé on recommande de se logguer
    if (!isLogged) {
      window.location = '/identity';
      return;
    }

    // artiste non favoris et client loggé mais pas de push email,
    // on recommande d'activer le push email mais on ajoute l'artiste quand même, pas de return
    if (isLogged && !isPushEmail && !isFavorite) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
    }

    if (idcustomer && favoriteCount === 0) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
    }

    if (!isFavorite) {
      dispatch(updateUserContext({ favoriteCount: favoriteCount + 1 }));
      dispatch(followArtist({ idartist }));
    } else {
      dispatch(unfollowArtist({ idartists: [idartist] }));
      dispatch(updateUserContext({ favoriteCount: favoriteCount - 1 }));
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline', paddingTop: 7 }}>
      <RecommendationsModale uuid={recommendationModaleUUID} />

      {render && render({ isFavorite, handleFollowClick, error })}
      {!render && (
        <>
          <ButtonFollow isFavorite={isFavorite} size={size} handleFollowClick={handleFollowClick} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />
          {error && (
            <div
              style={{
                position: 'absolute',
                backgroundColor: '#fff',
                padding: 15,
                zIndex: 100,
                width: 250,
                border: 'solid 1px #ccc',
                borderRadius: 5,
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              }}
            >
              <p style={{ fontWeight: 'normal' }}>
                <i className="fa fa-exclamation-triangle marg marg-r-5" /> {error}
              </p>
              <p style={{ fontWeight: 'normal' }}>
                <a href="/myartprice/settings/artists">
                  <A18n rsx="components.myartprice.followartist.m6" />
                </a>
              </p>
              <p style={{ textAlign: 'right', margin: 0 }}>
                <button type="button" className="btn btn-primary" onClick={() => dispatch(updateArtists({ error: null }))}>
                  <A18n rsx="components.myartprice.followartist.m5" />
                </button>
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Follow;
