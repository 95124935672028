import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVE_MENU, MENU_FUTURE_SALES, MENU_PAST_SALES, MENU_CONTACT, MENU_CONTRACT, MENU_FILES, MENU_SUBSCRIPTIONS, ACTIVE_AAPI_BEACON_FFROM } from '../../../../constants/auctioneers';
import { getAgreement } from '../../../../redux/selectors/agreements';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { fetch } from '../../../../redux/actions/agreements';
import {
  ANALYTICS_OPERATION_AUCTIONEERS_TO_FUTURE_SALES,
  ANALYTICS_OPERATION_AUCTIONEERS_TO_PAST_SALES,
  ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA,
  ANALYTICS_OPERATION_AUCTIONEERS_TO_AGREEMENT,
  ANALYTICS_OPERATION_AUCTIONEERS_TO_SUBSCRIPTIONS_ADS,
  ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT,
} from '../../../../constants/rails';
import LinkComponent from './Link';
import './stylesheet.scss';

const section = 'auctioneer-center-top-bar';

const TopBar = () => {
  const dispatch = useDispatch();
  const agreement = useSelector(state => getAgreement(state, { idagreementtype: 3 }));
  const activeMenu = useSelector(state => get(state, { id: ACTIVE_MENU }));
  const from = useSelector(state => get(state, { id: ACTIVE_AAPI_BEACON_FFROM }));

  useEffect(() => {
    dispatch(fetch({ idagreementtype: 3 }));
  }, []);

  return (
    <div className="auctioneers-common-top-bar">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="links">
              <LinkComponent active={activeMenu === MENU_FUTURE_SALES} to="/auctioneer/control-center/future-sales" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_FUTURE_SALES }}>
                {i18next.t('auctioneers.common.topbar.m2')}
              </LinkComponent>
              <LinkComponent active={activeMenu === MENU_PAST_SALES} to="/auctioneer/control-center/past-sales" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_PAST_SALES }}>
                {i18next.t('auctioneers.common.topbar.m1')}
              </LinkComponent>
              <LinkComponent active={activeMenu === MENU_FILES} to="/auctioneer/control-center/docs" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA }}>
                {i18next.t('auctioneers.common.topbar.m5')}
              </LinkComponent>
              <LinkComponent active={activeMenu === MENU_CONTRACT} to="/auctioneer/control-center/agreement" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_AGREEMENT }}>
                {i18next.t('auctioneers.common.topbar.m3')}
                {agreement && agreement.signedVersion < agreement.lastVersion && (
                  <span className="badge marg marg-l-5">
                    <i className="fa fa-bell" />
                  </span>
                )}
              </LinkComponent>
              <LinkComponent active={activeMenu === MENU_SUBSCRIPTIONS} to="/auctioneer/control-center/subscription" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SUBSCRIPTIONS_ADS }}>
                {i18next.t('auctioneers.common.topbar.m4')}
              </LinkComponent>
              <LinkComponent active={activeMenu === MENU_CONTACT} to="/auctioneer/control-center/contact" aapiBeaconParams={{ from, section, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT }}>
                {i18next.t('auctioneers.common.topbar.m6')}
              </LinkComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
