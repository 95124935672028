import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThemes, areThemesLoading, areThemesInitialized } from '../../../../redux/selectors/subscriptions/physicproducts';
import { THEME_ADVANCED, THEME_BASICS, THEME_BASICS_STORE, THEME_PROFESSIONAL, THEME_STORE } from '../../../../constants/rails';
import { fetchPhysicproducts } from '../../../../redux/actions/subscriptions/physicproduct';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import ThemeDetailsModale from './ThemeDetailsModale';
import WarningsPurchaseNotAvailable from '../warnings/PurchaseNotAvailable';
import Theme from './Theme';
import Fake from './Fake';
import './stylesheet.scss';
import { NEW, SUBSCRIPTION, UPGRADE } from '../common/constants';

const THEMES_ORDER = [THEME_STORE, THEME_BASICS, THEME_BASICS_STORE, THEME_ADVANCED, THEME_PROFESSIONAL];

const List = () => {
  const dispatch = useDispatch();
  const themesAvailableForClient = useSelector(getThemes);
  const themesInitialized = useSelector(areThemesInitialized);
  const loading = useSelector(areThemesLoading);
  const { view, filter, image, quantity, promocode } = useSelector(getSettings);

  let themes = [];
  if (!loading && themesAvailableForClient) {
    const availableThemes = [];
    const unavailableThemes = [];

    THEMES_ORDER.forEach(idtheme => {
      if (filter === SUBSCRIPTION && idtheme === THEME_STORE) return;

      if (view === NEW && themesAvailableForClient.new) {
        const themeNew = themesAvailableForClient.new.products.filter(t => t.idtheme === idtheme)[0];
        if (themeNew) themes.push({ ...themeNew, ...{ active: true } });
      } else if (themesAvailableForClient.upgrade) {
        const themeUpgrade = themesAvailableForClient.upgrade.products.filter(t => t.idtheme === idtheme)[0];
        if (themeUpgrade) {
          availableThemes.push({ ...themeUpgrade, ...{ active: true } });
        } else {
          const themeNew = themesAvailableForClient.new.products.filter(t => t.idtheme === idtheme)[0];
          if (themeNew) unavailableThemes.push({ ...themeNew, ...{ active: false } });
        }
      }
    });

    if (view === UPGRADE) {
      themes = [...unavailableThemes, ...availableThemes];
    }
  }

  useEffect(() => {
    dispatch(
      fetchPhysicproducts({
        filter,
        image,
        promocode,
        quantity,
      }),
    );
  }, [filter, image, promocode, quantity]);

  const className = ['subscriptions-purchase-list'];
  className.push(view);
  className.push(`cards-${themes.length}`);

  if (themes.length === 0 && !loading) {
    className.push('empty');
  }

  return (
    <div className={className.join(' ')}>
      <WarningsPurchaseNotAvailable />
      <ThemeDetailsModale />
      {loading && (
        <>
          <Fake />
          <Fake />
          <Fake />
        </>
      )}
      {!loading && themesInitialized && (
        <>
          {themes.map(t => (
            <Theme key={t.theme} theme={t} view={view} cardCount={themes.length} />
          ))}
        </>
      )}
    </div>
  );
};

export default List;
