/* eslint-disable global-require */
module.exports = [
  'subscriptions.purchase.free-services.registration.m1',
  'subscriptions.purchase.free-services.registration.m2',
  'subscriptions.purchase.free-services.registration.m3',
  'subscriptions.purchase.free-services.registration.m4',
  'subscriptions.purchase.free-services.registration.m5',
  'subscriptions.purchase.free-services.registration.m6',
  'subscriptions.purchase.free-services.registration.m7',
  'subscriptions.purchase.free-services.registration.m8',
  'subscriptions.purchase.free-services.registration.m9',
  'subscriptions.purchase.free-services.registration.m10',
  'subscriptions.purchase.free-services.registration.m11',
];
