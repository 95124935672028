/* eslint-disable react/style-prop-object */
// eslint-disable react/no-danger

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedNumber, injectIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { ANALYTICS_CLICK_FIGURES, ANALYTICS_CLICK_OPERATION_TO_ARTIST_BIOGRAPHY, ANALYTICS_FROM_ARTIST } from '../../../../constants/rails';
import { getArtist, getSummaryData } from '../../../../redux/slices/artist';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { getIsBot } from '../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import MainIllustration from './MainIllustration';
import { UiContext } from '../common/UiProvider';
import { scrollTo } from '../common/utils';
import A18n from '../../../common/A18n';
import Signatures from './Signatures';
import Follow from '../../Follow';
import Share from './Share';
import './stylesheet.scss';
import Breadcrumb from './Breadcrumb';

const Header = ({ intl }) => {
  const currency = useSelector(getCurrency);
  const [openArtistFocus, setOpenArtistFocus] = useState(false);
  const [splitable, setSplitable] = useState(true);
  const summaryData = useSelector(getSummaryData);
  const artist = useSelector(getArtist);
  const bot = useSelector(getIsBot);
  const { indexesRef } = useContext(UiContext);

  useEffect(() => {
    if (!artist.biblio) setSplitable(false);

    const re = /%next%/;
    if (re.test(artist.biblio)) {
      setSplitable(true);
    } else {
      setSplitable(false);
    }
  }, [artist]);

  const heandleFiguresClick = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_FIGURES, idartist: artist.id });
    scrollTo(indexesRef);
  };

  return (
    <div className="artists-artist-header">
      <div className="container">
        <MainIllustration />
        <div className="artists-artist-header-content">
          <Breadcrumb />
          <div className="title">
            <h1>{artist.name}</h1>
            <Follow idartist={artist.id} noLabel />
            <Share />
          </div>
          <div className="dates">{artist.dates}</div>
          {!isEmpty(artist.aliases) && <div className="aliases">{artist.aliases.join(', ')}</div>}
          <MainIllustration className="hide-for-widescreen" />
          {summaryData?.ca > 0 && (
            <div className="figures" onClick={heandleFiguresClick}>
              <div className="figure rank">
                {bot && (
                  <p className="legend action">
                    <A18n rsx="artists.artist.header.m3" /> (<A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                    ):{' '}
                    <strong>
                      <FormattedNumber value={summaryData.rank} minimumFractionDigits={0} />
                    </strong>
                  </p>
                )}
                {!bot && (
                  <>
                    <div className="legend action">
                      <A18n rsx="artists.artist.header.m3" />
                      <div className="year">
                        <A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                      </div>
                    </div>
                    <div className="number">
                      <FormattedNumber value={summaryData.rank} minimumFractionDigits={0} />
                    </div>
                  </>
                )}
              </div>
              <div className="figure ca">
                {bot && (
                  <p className="legend action">
                    <A18n rsx="artists.artist.header.m2" /> (<A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                    ): <strong>{intl.formatNumber(summaryData.ca, { style: 'currency', currency, notation: 'compact' }).replaceAll('\u00a0', '')}</strong>
                  </p>
                )}
                {!bot && (
                  <>
                    <div className="legend action">
                      <A18n rsx="artists.artist.header.m2" />
                      <div className="year">
                        <A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                      </div>
                    </div>
                    <div className="number">
                      <FormattedNumber notation="compact" value={summaryData.ca} style="currency" currency={currency} />
                    </div>
                  </>
                )}
              </div>
              <div className="figure sales">
                {bot && (
                  <p className="legend action">
                    <A18n rsx="artists.artist.header.m4" /> (<A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                    ): <strong>{summaryData.mainMedium?.name}</strong>
                  </p>
                )}
                {!bot && (
                  <>
                    <div className="legend action">
                      <A18n rsx="artists.artist.header.m4" />
                      <div className="year">
                        <A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                      </div>
                    </div>
                    <div className="number" title={intl.formatNumber(summaryData.mainMedium?.value, { minimumFractionDigits: 0, style: 'percent', maximumFractionDigits: 1 })}>
                      {summaryData.mainMedium?.name}
                    </div>
                  </>
                )}
              </div>
              <div className="figure country">
                {bot && (
                  <p className="legend action">
                    <A18n rsx="artists.artist.header.m5" /> (<A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                    ): <strong>{summaryData.mainCountry}</strong>
                  </p>
                )}
                {!bot && (
                  <>
                    <div className="legend action">
                      <A18n rsx="artists.artist.header.m5" />
                      <div className="year">
                        <A18n rsx="artists.artist.header.m1" replace={[['%year%', summaryData.year]]} />
                      </div>
                    </div>
                    <div className="number">{summaryData.mainCountry}</div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="biography">
            {bot && (
              <>
                <section itemProp="description" dangerouslySetInnerHTML={{ __html: artist.biblio.replace('%ellipsis%', '').replace('%next%', '') }} />
                {artist.bio && (
                  <p>
                    <a href={artist.bio}>
                      <A18n rsx="artists.artist.header.m7" />
                    </a>
                  </p>
                )}
              </>
            )}
            {!bot && (
              <>
                <section className="bio" itemProp="description">
                  <span dangerouslySetInnerHTML={{ __html: artist.biblio.split('%next%')[0].replace('%ellipsis%', !openArtistFocus ? '[...]' : '') }} />
                  {openArtistFocus && <span dangerouslySetInnerHTML={{ __html: artist.biblio.split('%next%')[1] }} />}
                </section>

                {!openArtistFocus && splitable && (
                  <div>
                    <a onClick={() => setOpenArtistFocus(!openArtistFocus)} href="#" className="more">
                      <A18n rsx="artists.artist.header.m6" />
                    </a>
                  </div>
                )}

                {(openArtistFocus || !splitable) && (
                  <div className="links">
                    <div className="bio">
                      {artist.bio && (
                        <p>
                          <a href={artist.bio} onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_BIOGRAPHY, idartist: artist.id })}>
                            <A18n rsx="artists.artist.header.m7" />
                          </a>
                        </p>
                      )}

                      <p>
                        {openArtistFocus && (
                          <a onClick={() => setOpenArtistFocus(!openArtistFocus)} href="#" className="more">
                            <A18n rsx="artists.artist.header.m8" />
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <Signatures />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Header);
