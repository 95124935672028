/* eslint-disable react/no-danger */
import React from 'react';
import { injectIntl } from 'react-intl';

const Price = ({ price, currency, className, intl }) => {
  const priceFormated = intl.formatNumber(price, {
    minimumFractionDigits: Math.trunc(price) === price || currency === 'CNY' ? 0 : 2,
    maximumFractionDigits: Math.trunc(price) === price ? 0 : 2,
  });

  let iso1;
  switch (currency) {
    case 'usd':
      iso1 = '<span class="currencie">$us</span>';
      break;
    case 'eur':
      iso1 = '<span class="currencie">€</span>';
      break;
    case 'cny':
      iso1 = '<span class="currencie">¥</span>';
      break;
    case 'gbp':
      iso1 = '<span class="currencie">£</span>';
      break;
    default:
      iso1 = '';
  }

  let finalPrice = `${priceFormated}${iso1}`;
  if (['zh', 'en'].includes(intl.locale)) finalPrice = `${iso1}${priceFormated}`;

  return <div className={className} dangerouslySetInnerHTML={{ __html: finalPrice }} />;
};

export default injectIntl(Price);
