/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import I18next from 'i18next';
import { Line, LineChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import A18n from '../../common/A18n';
import { getGraphData } from '../../../services/wallet/amci';
import Spinner from '../../common/spinners/Spinner';

const Graph = () => {
  const [period, setPeriod] = useState('week');
  const [type, setType] = useState([9]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    getGraphData({ period, type }, controller)
      .catch(err => console.error(err))
      .then(graphData => {
        try {
          const {
            graph_data: { rows = [] },
          } = graphData;
          const formattedData = rows.map(({ c: [{ v: date }, { v: v1 }, { v: v2 }, { v: v3 } = {}, { v: v4 } = {}] }) => ({
            date,
            v1,
            v2,
            v3,
            v4,
          }));
          setData(formattedData);
        } catch (err) {
          console.error(err);
        }
      })
      .finally(() => setLoading(false));

    return () => controller.abort();
  }, [period, type]);

  return (
    <div className="row">
      <div className="col-xs-6">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 30, right: 5, bottom: 5, left: -5 }}>
            {!loading && (
              <>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="date" angle={-25} tick={{ fontSize: 10 }} tickMargin={10} tickFormatter={v => v.slice(0, -5)} />
                <YAxis type="number" domain={['auto', 'auto']} />
                <Tooltip
                  formatter={(value, name) => {
                    if (name === 'v2' || name === 'v4') {
                      return [value.toFixed(2), I18next.t('component.indexes.average')];
                    }
                    return [undefined, undefined];
                  }}
                />
                <Line name="v1" type="monotone" dataKey="v1" stroke="green" dot={false} strokeWidth={1} />
                <Line name="v2" type="monotone" dataKey="v2" stroke="green" dot={false} strokeWidth={2} />
                <Line name="v3" type="monotone" dataKey="v3" stroke="red" dot={false} strokeWidth={1} />
                <Line name="v4" type="monotone" dataKey="v4" stroke="red" dot={false} strokeWidth={2} />
              </>
            )}
            {loading && (
              <foreignObject width="100%" height="100%">
                <div style={{ width: '100%', height: '100%' }}>
                  <Spinner />
                </div>
              </foreignObject>
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="col-xs-6">
        <h3 dangerouslySetInnerHTML={{ __html: I18next.t('components.amci.m65') }} />

        <select className="form-control" onChange={e => setPeriod(e.target.value)} defaultValue="week">
          <option value="week">
            <A18n rsx="components.amci.m67" />
          </option>
          <option value="month">
            <A18n rsx="components.amci.m68" />
          </option>
          <option value="year">
            <A18n rsx="components.amci.m69" />
          </option>
        </select>

        <h3 dangerouslySetInnerHTML={{ __html: I18next.t('components.amci.m70') }} />

        <div onChange={e => setType(e.target.value.split(',').map(strNumber => Number.parseInt(strNumber, 10)))}>
          <div className="radio">
            <label>
              <input name="type" type="radio" value="9" defaultChecked />
              <A18n rsx="components.amci.m8" />
            </label>
          </div>
          <div className="radio">
            <label>
              <input name="type" type="radio" value="1,5" />
              <A18n rsx="components.amci.m19" />
            </label>
          </div>
          <div className="radio">
            <label>
              <input name="type" type="radio" value="2,6" />
              <A18n rsx="components.amci.m21" />
            </label>
          </div>
          <div className="radio">
            <label>
              <input name="type" type="radio" value="3,7" />
              <A18n rsx="components.amci.m24" />
            </label>
          </div>
          <div className="radio">
            <label>
              <input name="type" type="radio" value="4,8" />
              <A18n rsx="components.amci.m26" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
