import React from 'react';
import StoreFollow from '../../Follow';

const Store = ({ store }) => (
  <div
    className="marketplace-stores-list-store"
    onClick={() => {
      window.location = store.storeUrl;
    }}
  >
    <div className="img">
      <img src={store.imgUrl} alt={store.sitename} />
    </div>
    <h3>
      <a href={store.storeUrl}>{store.sitename}</a>
    </h3>
    <p>
      {store.country} {(store.country && store.city && ', ') || <br />} {store.city}
    </p>
    <StoreFollow idstore={store.id} rsxNotFavorited="marketplace.stores.list.m6" rsxFavorited="marketplace.stores.list.m7" />
  </div>
);

export default Store;
