import React from 'react';
import { useSelector } from 'react-redux';
import { getSettings } from '../../../../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../../../../common/A18n';

const Validate = ({ warningsCount }) => {
  const { warningForRenewInPlaceOfUpgrade, image } = useSelector(getSettings);

  if (warningForRenewInPlaceOfUpgrade && warningsCount === 0) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.validate.m1" />;

  if (!image) return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.validate.m2" />;

  return <A18n rsx="subscriptions.list.warnings.warningmodale.buttonstitles.validate.m3" />;
};

export default Validate;
