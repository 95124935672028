import React from 'react';
import './stylesheet.scss';
import Lot from './Lot';
import A18n from '../../../common/A18n';

const Lots = ({ lots }) => {
  if (!lots) return null;

  return (
    <div className="ails-landingpage-lots">
      <h2>
        <A18n rsx="ails.landingpage.lots.m1" />
      </h2>
      <div className="lots-container">
        {lots?.map(lot => (
          <Lot ail={lot} key={lot.id} />
        ))}
      </div>
    </div>
  );
};

export default Lots;
