/* eslint-disable global-require */

module.exports = [
  'marketplace.searchbars.classifieds.bar.m2',
  'artists.search.form.m2',
  'components.search.helpers.count.m3',
  'components.search.helpers.count.m4',
  'marketplace.searchbars.classifieds.bar.m1',
  'marketplace.searchbars.common.facetsselect.m1',
];
