import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Details from '../../../lots/Show';
import ClosingDropDown from '../../../search/common/ClosingDropDown';
import DisplayedCurrencies from '../../../search/filters/DisplayedCurrencies';
import DisplayedDimensions from '../../../search/filters/DisplayedDimensions';
import CommonHorizontalBar from '../../../common/HorizontalBar';
import BannerNoAccess from '../../../subscriptions/BannerNoAccess';
import ButtonBack from '../../../common/ButtonBack';
import ButtonsNavigation from '../../common/ButtonsNavigation';
import { areCurrentResultsLoading, getCurrentResultsData } from '../../../../redux/selectors/search/results';
import StandaloneCreate from '../../../account/Lotslists/AddToListButton/StandaloneCreate';

import './stylesheet.scss';

const Show = ({ past = false }) => {
  const { idlot, crypto } = useParams();
  const loading = useSelector(areCurrentResultsLoading) || false;
  const currentResultsData = useSelector(getCurrentResultsData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <i className="fa fa-spinner fa-spin fa-5x fa-fw" />
      </div>
    );
  }

  return (
    <div className="myartprice-lot">
      <CommonHorizontalBar className="bar">
        <div className="container">
          <div className="filters-container grid-override">
            <ButtonBack style={{ marginRight: 5 }} />
            {currentResultsData && <ButtonsNavigation idlot={idlot} currentResultsData={currentResultsData} />}
            {past && <StandaloneCreate ids={[idlot]} newSelectionKey={`lot-${idlot}`} />}
            <ClosingDropDown
              className="preferences"
              icon="cogs"
              right
              displayIconDesktop
              render={() => (
                <>
                  <DisplayedCurrencies />
                  <DisplayedDimensions />
                </>
              )}
            />
          </div>
        </div>
      </CommonHorizontalBar>
      <Details id={idlot} crypto={crypto} />
      <BannerNoAccess idlot={idlot} />
    </div>
  );
};

export default Show;
