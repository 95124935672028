import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import Payment from '../index';
import ReduxProvider from '../../providers/ReduxProvider';

const Payments = ({ locale = 'en', cartParams = {} }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <Payment cartParams={cartParams} />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

export default Payments;
