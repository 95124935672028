import React from 'react';
import { ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH, ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';
import Lock from '../../common/Lock';

const Tabs = ({ artist, handleSeeAllClick, displayLock }) => (
  <div className="header">
    <div>
      <a href={`${artist.urlIndexes}?st=chrono`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
        <A18n rsx="artists.artist.indexes.m3" />
      </a>
    </div>
    {artist.index && (
      <div>
        <a href={`${artist.urlIndexes}?st=indexes`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
          <A18n rsx="artists.artist.indexes.m4" />
          {displayLock && <Lock stroke="#999" />}
        </a>
      </div>
    )}
    <div className="hidden-xs">
      <a href={`${artist.urlIndexes}?st=rank`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
        <A18n rsx="artists.artist.header.m3" />
        {displayLock && <Lock stroke="#999" />}
      </a>
    </div>
    <div className="hidden-xs">
      <a href={`${artist.urlIndexes}?st=mediums`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
        <A18n rsx="artists.artist.header.m4" />
        {displayLock && <Lock stroke="#999" />}
      </a>
    </div>
    <div className="hidden-xs">
      <a href={`${artist.urlIndexes}?st=geo`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
        <A18n rsx="artists.artist.header.m5" />
        {displayLock && <Lock stroke="#999" />}
      </a>
    </div>
    <div>
      <a href={`${artist.urlIndexes}`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH })}>
        ...
      </a>
    </div>
  </div>
);

export default Tabs;
