/* eslint-disable global-require */
module.exports = [
  ...require('./CustomYAxisLabel/i18n'),
  ...require('./Filters/i18n'),
  'indexes.advanced_options',
  'indexes._advanced.m1',
  'indexes._advanced.m2',
  'indexes._advanced.m4',
  'indexes._advanced.m6',
  'indexes._advanced.m7',
  'indexes._advanced.m8',
  'indexes._advanced.m9',
  'indexes._advanced.m10',
  'indexes._advanced.m11',
  'indexes._advanced.m15',
];
