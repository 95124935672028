import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

// Sous composant car le UseStripe doit etre encapsulé par un composant Elements
const StripeRedirect = ({ checkoutSessionId }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (checkoutSessionId && stripe) {
      stripe.redirectToCheckout({ sessionId: checkoutSessionId });
    }
  }, [checkoutSessionId, stripe]);

  return null;
};

export default StripeRedirect;
