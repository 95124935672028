import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { UncontrolledAlert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerVerificationStatus, getEmailStatus, getEmail, getIdprofessionaltype } from '../../../../redux/slices/userContext/selectors';
import {
  CUSTOMER_VERIFICATION_STATUS_INPROGRESS,
  CUSTOMER_VERIFICATION_STATUS_NONVERIFIED,
  CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED,
  CUSTOMER_VERIFICATION_STATUS_VALID,
  CUSTOMER_EMAIL_STATUS_VALID,
  CUSTOMER_EMAIL_STATUS_INPROGRESS,
  PROFESSIONAL_TYPE_ID_PRIVATE,
  PROFESSIONAL_TYPE_ID_PROFESSIONAL,
} from '../../../../constants/rails';
import { hasError, isLoading } from '../../../../redux/selectors/ui/asyncStatus';
import { ASYNC_ID, updateIdprofessionaltype } from '../../../../redux/slices/userContext';
import VerificationStatus from './VerificationStatus';
import EmailStatus from '../../EmailStatus';
import Step from './Step';
import { useViewport } from '../../../../hooks/useViewport';
import './stylesheet.scss';
import { ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS, ACCOUNT_CONFIRM_STEP_STATUS_NONVERIFIED, ACCOUNT_CONFIRM_STEP_STATUS_TODO, ACCOUNT_CONFIRM_STEP_STATUS_VALID } from '../../../../constants/account/confirm';

const SELECT_OPTIONS = [
  { value: PROFESSIONAL_TYPE_ID_PRIVATE, label: i18next.t('components.account.confirm.m5') },
  { value: PROFESSIONAL_TYPE_ID_PROFESSIONAL, label: i18next.t('components.account.confirm.m6') },
];

const transformViewportLimit = 768;

const getEmailTextStatus = status => {
  switch (status) {
    case CUSTOMER_VERIFICATION_STATUS_VALID:
      return ACCOUNT_CONFIRM_STEP_STATUS_VALID;
    case CUSTOMER_EMAIL_STATUS_INPROGRESS:
      return ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS;
    default:
      return ACCOUNT_CONFIRM_STEP_STATUS_NONVERIFIED;
  }
};

const getStyle = status => {
  if (status === CUSTOMER_VERIFICATION_STATUS_VALID) {
    return { paddingTop: 10 };
  }

  return {};
};

const getCustomerTextStatus = (status, idprofessionaltype) => {
  switch (status) {
    case CUSTOMER_EMAIL_STATUS_VALID:
      return ACCOUNT_CONFIRM_STEP_STATUS_VALID;
    case CUSTOMER_VERIFICATION_STATUS_INPROGRESS:
      return ACCOUNT_CONFIRM_STEP_STATUS_INPROGRESS;
    default:
      if (idprofessionaltype) {
        return ACCOUNT_CONFIRM_STEP_STATUS_TODO;
      }

      return ACCOUNT_CONFIRM_STEP_STATUS_NONVERIFIED;
  }
};

const getCustomerViewStatus = status => {
  switch (status) {
    case CUSTOMER_VERIFICATION_STATUS_NONVERIFIED:
    case CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED:
      return i18next.t('components.account.verify.dialog.m6');

    case CUSTOMER_VERIFICATION_STATUS_INPROGRESS:
    case CUSTOMER_VERIFICATION_STATUS_VALID:
    default:
      return i18next.t('components.account.confirm.m10');
  }
};

const getIdprofessionaltypeStatus = (idprofessionaltype, emailVerificationStatus) => {
  if (idprofessionaltype) return ACCOUNT_CONFIRM_STEP_STATUS_VALID;
  if (emailVerificationStatus !== ACCOUNT_CONFIRM_STEP_STATUS_VALID) return ACCOUNT_CONFIRM_STEP_STATUS_NONVERIFIED;
  return ACCOUNT_CONFIRM_STEP_STATUS_TODO;
};

const ConfirmAccount = () => {
  const dispatch = useDispatch();
  const email = useSelector(getEmail);
  const location = useLocation();
  const { viewportWidth } = useViewport();
  const idprofessionaltype = useSelector(getIdprofessionaltype);
  const isIdprofessionaltypeSelectLoading = useSelector(state => isLoading(state, { id: ASYNC_ID }));
  const asyncCallFailed = useSelector(state => hasError(state, { id: ASYNC_ID }));
  const customerVerificationStatus = useSelector(getCustomerVerificationStatus);
  const emailVerificationStatus = useSelector(getEmailStatus);

  // we need null, undefined is not working well here
  const selectOption = useMemo(() => SELECT_OPTIONS.find(({ value }) => value === idprofessionaltype) || null, [idprofessionaltype]);

  return (
    <div className="account-confirm-account container">
      <div className="row">
        <div className="col-xs-12">
          <h1>{i18next.t('components.account.verify.dialog.m1')}</h1>
          {location.state?.verifySuccess && <UncontrolledAlert fade={false}>{i18next.t('components.account.verify.dialog.m7')}</UncontrolledAlert>}
          <p>{i18next.t('components.account.verify.dialog.m2')}</p>
          <div className="process">
            <div className="timeline" />

            <div className="group">
              {viewportWidth <= transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={1} status={getEmailTextStatus(emailVerificationStatus)} />
                </div>
              )}
              <div className="group-label">{i18next.t('components.account.verify.dialog.m4')}</div>
              <div className="group-input">{email ? <EmailStatus backTo="confirm" style={getStyle(emailVerificationStatus)} showText /> : <a href="/account/show">{i18next.t('components.account.confirm.m4')}</a>}</div>

              {viewportWidth > transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={1} status={getEmailTextStatus(emailVerificationStatus)} />
                </div>
              )}
            </div>

            <div className="group">
              {viewportWidth <= transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={2} status={getIdprofessionaltypeStatus(idprofessionaltype, emailVerificationStatus)} />
                </div>
              )}
              <div className="group-label">{i18next.t('components.account.verify.dialog.m3')}</div>
              <div className="group-input">
                <Select
                  placeholder={i18next.t('components.account.verify.identity.m4')}
                  value={selectOption}
                  isSearchable={false}
                  options={SELECT_OPTIONS}
                  onChange={({ value }) => dispatch(updateIdprofessionaltype({ idprofessionaltype: value }))}
                  isLoading={isIdprofessionaltypeSelectLoading}
                  /* eslint-disable operator-linebreak,prettier/prettier */
                  isDisabled={
                    (customerVerificationStatus !== CUSTOMER_VERIFICATION_STATUS_NONVERIFIED && customerVerificationStatus !== CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED && idprofessionaltype) ||
                    emailVerificationStatus !== CUSTOMER_EMAIL_STATUS_VALID
                  }
                />
                {asyncCallFailed && (
                  <p className="text-danger">
                    <i className="fa fa-exclamation" /> {i18next.t('components.account.confirm.m7')}
                  </p>
                )}
              </div>
              {viewportWidth > transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={2} status={getIdprofessionaltypeStatus(idprofessionaltype, emailVerificationStatus)} />
                </div>
              )}
            </div>

            <div className="group">
              {viewportWidth <= transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={3} status={getCustomerTextStatus(customerVerificationStatus, idprofessionaltype)} />
                </div>
              )}
              <div className="group-label">{i18next.t('components.account.verify.dialog.m5')}</div>
              <div className="group-input">
                <VerificationStatus loading={isIdprofessionaltypeSelectLoading} idprofessionaltype={idprofessionaltype} customerVerificationStatus={customerVerificationStatus} />
                {idprofessionaltype && emailVerificationStatus === CUSTOMER_EMAIL_STATUS_VALID && (
                  <div>
                    <Link to="/account/confirm/identity">{getCustomerViewStatus(customerVerificationStatus)}</Link>
                  </div>
                )}
              </div>
              {viewportWidth > transformViewportLimit && (
                <div className="group-step">
                  <Step stepNumber={3} status={getCustomerTextStatus(customerVerificationStatus, idprofessionaltype)} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
