/* eslint-disable global-require */
module.exports = [
  'artists.artist.lots.noaccessdrawer.m1',
  'artists.artist.lots.noaccessdrawer.m4',
  'artists.artist.lots.noaccessdrawer.m5',
  'artists.artist.lots.noaccessdrawer.m6',
  'artists.artist.lots.noaccessdrawer.m8',
  'artists.artist.lots.noaccessdrawer.m9',
  'artists.artist.lots.noaccessdrawer.m12',
  'artists.artist.lots.noaccessdrawer.m13',
  'artists.artist.lots.noaccessdrawer.m14',
  'artists.artist.lots.noaccessdrawer.m15',
  'artists.artist.lots.noaccessdrawer.m16',
  'artists.artist.lots.noaccessdrawer.m17',
  'artists.artist.lots.noaccessdrawer.m18',
  'artists.artist.lots.noaccessdrawer.m19',
  ...require('./common/Price/i18n'),
];
